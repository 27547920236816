import { ApiPolicyDownloadResponse } from './api.policy-download.response';
import { PolicyDownloadResponse } from './policy-download.response';

export function mapPolicyDownloadResponse(
    response: ApiPolicyDownloadResponse | null
): PolicyDownloadResponse {
    if (!response) {
        return null;
    }

    return {
        name: response.identifying_name,
        type: response.type,
        data: response.pdf,
    };
}
