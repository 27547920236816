export enum NaturalPartyRoleEnum {
    OccupationalTherapist = 'OccupationalTherapist', // Arbeidsdeskundige
    Advocate = 'Advocate', // Belangenbehartiger
    Driver = 'Driver', // Bestuurder
    ContraExpert = 'ContraExpert', // Contra Expert
    Contact = 'Contact', // Contactpersoon
    Expert = 'Expert', // Expert
    Witness = 'Witness', // Getuige
    DelegatedClaimsAdjuster = 'DelegatedClaimsAdjuster', // Gedelegeerd schaderegelaar
    Caregiver = 'Caregiver', // Hulpverlener
    InjuryLawyer = 'InjuryLawyer', // Letsel advocaat
    InjuryClaimsAdjuster = 'InjuryClaimsAdjuster', // Letsel schaderegelaar
    MedicalAviser = 'MedicalAviser', // Medisch Adviseur
    CoInsured = 'CoInsured', // Medeverzekerde
    MedicalExpert = 'MedicalExpert', // Medisch Expert
    MedicalCareWorker = 'MedicalCareWorker', // Medisch Hulpverlener
    MedicalSpecialist = 'MedicalSpecialist', // Medisch Specialist
    Buyer = 'Buyer', // Opkoper
    GovernmentAgency = 'GovernmentAgency', // Overheidsinstantie
    OtherParty = 'OtherParty', // Overige Partij
    ResourceTaker = 'ResourceTaker', // Regresnemer
    Arithmetic = 'Arithmetic', // Rekenkundige
    DamageRepairer = 'DamageRepairer', // Schadehersteller
    Counterparty = 'Counterparty', // Tegenpartij
    BenefitsAgency = 'BenefitsAgency', // Uitkeringsinstantie
    PolicyHolder = 'PolicyHolder', // Verzekeringsnemer
    Employer = 'Employer', // Werkgever
    HealthInsurer = 'HealthInsurer', // Zorgverzekeraar
}
