import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AddressEffects, ADDRESS_FEATURE, addressReducer } from './store';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(ADDRESS_FEATURE, addressReducer),
        EffectsModule.forFeature([AddressEffects]),
        CommonModule,
    ],
})
export class OutAddressModule {}
