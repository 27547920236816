import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Customer } from '../../interfaces';
import * as fromSales from '../sales.reducers';
import * as fromCustomer from './customer.selectors';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    public currentCustomerData$: Observable<Customer> = this.store$.select(fromCustomer.getPersonalData);

    public isReturningCustomer$: Observable<boolean> = this.store$.select(fromCustomer.isReturningCustomer);

    public relationId$: Observable<number> = this.store$.select(fromCustomer.getRelationId);

    constructor(private store$: Store<fromSales.State>) {}
}
