import { ValidityStatusCodeEnum } from '../../enums';
import {
    ProductBaseInterface,
    ProductBasePermittedCoverageInterface,
    ProductBasePremiumDeterminingFactorInterface,
    ProductBasePurchasedCoverageInterface,
} from '../../interfaces';
import * as fromCoveragePremium from '../coverage-premium/coverage-premium.actions';
import * as fromProduct from './product.actions';

/** @deprecated replaced by InsurancePolicyState */
export interface State {
    ids: number[];
    entities: { [id: string]: ProductBaseInterface };
    selectedId: number;
    loading: boolean;
    loaded: boolean;
    detailLoaded: { [id: string]: boolean };
}

/** @deprecated replaced by INSURANCE_POLICY_INITIAL_STATE */
export const initialProductsState: State = {
    ids: [],
    entities: {},
    selectedId: null,
    loading: false,
    loaded: false,
    detailLoaded: {},
};

/** @deprecated replaced by insurancePolicyReducer  */
export function reducer(
    state: State = initialProductsState,
    action: fromProduct.ProductsActions | fromCoveragePremium.CoveragePremiumActions
): State {
    switch (action.type) {
    case fromProduct.SELECT: {
        return {
            ...state,
            selectedId: action.payload.purchased_product_sequence_number,
        };
    }

    case fromProduct.LOAD_POLICY: {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    }

    case fromProduct.LOAD_POLICY_SUCCESS: {
        const products = action.payload;
        const ids = products.map((product) => product.purchased_product_sequence_number);
        const entities = products.reduce(
            (entities: { [id: string]: ProductBaseInterface }, product: ProductBaseInterface) => {
                return Object.assign(entities, {
                    [product.purchased_product_sequence_number]: product,
                });
            },
            {}
        );

        return {
            ...state,
            ids: [...new Set([...state.ids, ...ids])],
            entities: Object.assign({}, state.entities, entities),
            loading: false,
            loaded: true,
        };
    }

    case fromProduct.LOAD_PURCHASED_PRODUCT_SUCCESS: {
        const product = action.payload;
        const entities = Object.assign({}, state.entities);
        const current = entities[product.purchased_product_sequence_number];

        return {
            ...state,
            entities: Object.assign({}, state.entities, {
                [product.purchased_product_sequence_number]: Object.assign({}, current, product),
            }),
            detailLoaded: {
                ...state.detailLoaded,
                [product.purchased_product_sequence_number]: true,
            },
        };
    }

    case fromProduct.CANCEL_FUTURE_PURCHASED_PRODUCT_SUCCESS: {
        const { purchased_product_sequence_number } = action.payload;
        const ids = state.ids.filter((id) => id !== purchased_product_sequence_number);
        const {
            [purchased_product_sequence_number]: {},
            ...entities
        } = state.entities;

        return Object.assign({}, state, { ids, entities });
    }

    case fromProduct.TERMINATE_ACTIVE_PURCHASED_PRODUCT_SUCCESS: {
        const { purchasedProductSequenceNumber, endDate } = action.payload;

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchasedProductSequenceNumber]: {
                    ...state.entities[purchasedProductSequenceNumber],
                    end_date: endDate,
                    validity_status_code: ValidityStatusCodeEnum.ActiveEnd,
                },
            },
        };
    }

    case fromProduct.REVIVE_TERMINATED_PURCHASED_PRODUCT_SUCCESS: {
        const { purchasedProductSequenceNumber } = action.payload;

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchasedProductSequenceNumber]: {
                    ...state.entities[purchasedProductSequenceNumber],
                    end_date: null,
                    validity_status_code: ValidityStatusCodeEnum.Active,
                },
            },
        };
    }

    case fromProduct.STORE_NON_REGULAR_DRIVER_SUCCESS: {
        const { purchased_product_sequence_number, non_regular_driver } = action.payload;
        const product = state.entities[purchased_product_sequence_number];

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchased_product_sequence_number]: {
                    ...product,
                    non_regular_driver_future: non_regular_driver,
                },
            },
        };
    }

    case fromProduct.CANCEL_FUTURE_NON_REGULAR_DRIVER_SUCCESS: {
        const { purchased_product_sequence_number } = action.payload;
        const product = state.entities[purchased_product_sequence_number];

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchased_product_sequence_number]: {
                    ...product,
                    non_regular_driver_future: null,
                },
            },
        };
    }

    case fromProduct.TERMINATE_ACTIVE_NON_REGULAR_DRIVER_SUCCESS: {
        const { purchased_product_sequence_number, end_date } = action.payload;
        const product = state.entities[purchased_product_sequence_number];
        const non_regular_driver = Object.assign({}, product.non_regular_driver, {
            validity_status_code: 'ACEI',
            end_date,
        });

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchased_product_sequence_number]: {
                    ...product,
                    non_regular_driver,
                },
            },
        };
    }

    case fromProduct.MODIFY_FUTURE_PURCHASED_PRODUCT_START_DATE_SUCCESS: {
        const { purchased_product_sequence_number, start_date } = action.payload;
        const product = state.entities[purchased_product_sequence_number];

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [purchased_product_sequence_number]: {
                        ...product,
                        ceff_datum: start_date,
                        start_date: start_date,
                        coverages_adjustment_start_date: start_date,
                        validity_status_code: 'TOEK',
                    },
                },
            };
        }

    case fromProduct.MODIFY_TERMINATION_DATE_FOR_PRODUCT_END_DATE_SUCCESS: {
        const { purchased_product_sequence_number } = action.payload;
        const { end_date } = action.payload;
        const product = state.entities[purchased_product_sequence_number];

        return {
            ...state,
            entities: {
                ...state.entities,
                [product.purchased_product_sequence_number]: {
                    ...product,
                    end_date,
                },
            },
        };
    }

    case fromCoveragePremium.CHANGE_GENERIC_PURCHASED_COVERAGES_SUCCESS: {
        const { purchased_product_sequence_number, purchased_coverages } = action.payload;
        const product = state.entities[purchased_product_sequence_number];
        const permitted_coverage: ProductBasePermittedCoverageInterface[] = [];

        purchased_coverages.forEach((coverage) => {
            const { purchased_coverage, purchased_coverage_future } = coverage;
            const current_coverage: ProductBasePurchasedCoverageInterface = purchased_coverage
                ? {
                    contract_expiration_date: purchased_coverage.contract_expiration_date,
                    coverage_description: purchased_coverage.coverage_description,
                    coverage_id: purchased_coverage.coverage_id,
                    end_date: purchased_coverage.end_date,
                    first_invoice_date: purchased_coverage.first_invoice_date,
                    invalidated_timestamp: purchased_coverage.invalidated_timestamp,
                    purchased_coverage_sequence_number: purchased_coverage.purchased_coverage_sequence_number,
                    start_date: purchased_coverage.start_date,
                    start_date_created_timestamp: purchased_coverage.start_date_created_timestamp,
                    validity_status_code: purchased_coverage.validity_status_code,
                }
                : null;
            const future_coverage: ProductBasePurchasedCoverageInterface = purchased_coverage_future
                ? {
                    contract_expiration_date: null,
                    coverage_description: coverage.coverage_description,
                    coverage_id: coverage.coverage_id,
                    end_date: purchased_coverage_future.end_date,
                    first_invoice_date: null,
                    invalidated_timestamp: null,
                    purchased_coverage_sequence_number: null,
                    start_date: purchased_coverage_future.start_date,
                    start_date_created_timestamp: null,
                    validity_status_code: purchased_coverage_future.validity_status_code,
                }
                : null;

            permitted_coverage.push({
                adn_coverage_code: null,
                base_coverage_indication: coverage.base_coverage_indication,
                base_coverage_indication_description: coverage.base_coverage_indication_description,
                coverage_description: coverage.coverage_description,
                coverage_id: coverage.coverage_id,
                purchased_coverage: current_coverage,
                purchased_coverage_future: future_coverage,
            });
        });

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchased_product_sequence_number]: {
                    ...product,
                    permitted_coverage,
                    validity_status_code: 'ACWI',
                },
            },
        };
    }

    case fromCoveragePremium.CANCEL_FUTURE_PURCHASED_COVERAGE_SUCCESS: {
        const { purchased_product_sequence_number, purchased_coverages } = action.payload;
        const product = state.entities[purchased_product_sequence_number];
        const permitted_coverage: ProductBasePermittedCoverageInterface[] = [];

        purchased_coverages.forEach((coverage) => {
            const { purchased_coverage, purchased_coverage_future } = coverage;
            const current_coverage: ProductBasePurchasedCoverageInterface = purchased_coverage
                ? {
                    contract_expiration_date: purchased_coverage.contract_expiration_date,
                    coverage_description: purchased_coverage.coverage_description,
                    coverage_id: purchased_coverage.coverage_id,
                    end_date: purchased_coverage.end_date,
                    first_invoice_date: purchased_coverage.first_invoice_date,
                    invalidated_timestamp: purchased_coverage.invalidated_timestamp,
                    purchased_coverage_sequence_number: purchased_coverage.purchased_coverage_sequence_number,
                    start_date: purchased_coverage.start_date,
                    start_date_created_timestamp: purchased_coverage.start_date_created_timestamp,
                    validity_status_code: purchased_coverage.validity_status_code,
                }
                : null;
            const future_coverage: ProductBasePurchasedCoverageInterface = purchased_coverage_future
                ? {
                    contract_expiration_date: null,
                    coverage_description: coverage.coverage_description,
                    coverage_id: coverage.coverage_id,
                    end_date: purchased_coverage_future.end_date,
                    first_invoice_date: null,
                    invalidated_timestamp: null,
                    purchased_coverage_sequence_number: null,
                    start_date: purchased_coverage_future.start_date,
                    start_date_created_timestamp: null,
                    validity_status_code: purchased_coverage_future.validity_status_code,
                }
                : null;

            permitted_coverage.push({
                adn_coverage_code: null,
                base_coverage_indication: coverage.base_coverage_indication,
                base_coverage_indication_description: coverage.base_coverage_indication_description,
                coverage_description: coverage.coverage_description,
                coverage_id: coverage.coverage_id,
                purchased_coverage: current_coverage,
                purchased_coverage_future: future_coverage,
            });
        });

        return {
            ...state,
            entities: {
                ...state.entities,
                [purchased_product_sequence_number]: {
                    ...product,
                    permitted_coverage,
                    validity_status_code: 'ACTI',
                },
            },
        };
    }

    case fromProduct.UPDATE_PREMIUM_DETERMINING_FACTOR_SUCCESS: {
        const {
            purchased_product_sequence_number,
            premium_determining_factor_id,
            premium_determining_factor_value_description,
            start_date,
        } = action.payload;

        const product = state.entities[purchased_product_sequence_number];
        const premiumDeterminingFactors = product.premium_determining_factor;
        const selectedFactorIndex = premiumDeterminingFactors.findIndex(
            (premiumDeterminingFactor) =>
                premiumDeterminingFactor.premium_determining_factor_id === premium_determining_factor_id
        );

        const selectedFactor: ProductBasePremiumDeterminingFactorInterface = {
            ...premiumDeterminingFactors[selectedFactorIndex],
            modifiable_premium_determining_factor_future: {
                ...premiumDeterminingFactors[selectedFactorIndex].modifiable_premium_determining_factor,
                modifiable_premium_determining_factor_sequence_number: null,
                premium_determining_factor_number: null,
                premium_determining_factor_value_description,
                start_date,
                validity_status_code: 'TOEK',
            },
        };

        const entity = Object.assign({}, product, {
            premium_determining_factor: Object.assign([], premiumDeterminingFactors, {
                [selectedFactorIndex]: Object.assign({}, selectedFactor),
            }),
        });

        return {
            ...state,
            entities: Object.assign({}, state.entities, {
                [purchased_product_sequence_number]: entity,
            }),
        };
    }

    case fromProduct.CANCEL_FUTURE_MODIFIABLE_PREMIUM_DETERMINING_FACTOR_SUCCESS: {
        const { purchased_product_sequence_number, premium_determining_factor_id } = action.payload;

        const product = state.entities[purchased_product_sequence_number];
        const premiumDeterminingFactors = product.premium_determining_factor;
        const selectedFactorIndex = premiumDeterminingFactors.findIndex(
            (premiumDeterminingFactor) =>
                premiumDeterminingFactor.premium_determining_factor_id === premium_determining_factor_id
        );

        const selectedFactor: ProductBasePremiumDeterminingFactorInterface = {
            ...premiumDeterminingFactors[selectedFactorIndex],
            modifiable_premium_determining_factor_future: null,
        };

        const entity = Object.assign({}, product, {
            premium_determining_factor: Object.assign([], premiumDeterminingFactors, {
                [selectedFactorIndex]: Object.assign({}, selectedFactor),
            }),
        });

        return {
            ...state,
            entities: Object.assign({}, state.entities, {
                [purchased_product_sequence_number]: entity,
            }),
        };
    }

    case fromProduct.LOAD_ERROR: {
        return {
            ...state,
            loading: false,
        };
    }

    case fromProduct.CLEAR: {
        return initialProductsState;
    }

    default: {
        return state;
    }
    }
}

/** @deprecated replaced by InsurancePolicySelectors  */
export const getIds = (state: State): State['ids'] => state.ids;
/** @deprecated replaced by InsurancePolicySelectors  */
export const getEntities = (state: State): State['entities'] => state.entities;
/** @deprecated replaced by InsurancePolicySelectors  */
export const isLoading = (state: State): State['loading'] => state.loading;
/** @deprecated replaced by InsurancePolicySelectors  */
export const isLoaded = (state: State): State['loaded'] => state.loaded;
/** @deprecated replaced by InsurancePolicySelectors  */
export const getDetailLoaded = (state: State): State['detailLoaded'] => state.detailLoaded;
/** @deprecated replaced by InsurancePolicySelectors  */
export const getSelectedId = (state: State): State['selectedId'] => state.selectedId;
