import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({ providedIn: 'root' })
export class ClaimAppointmentEventService {
    private _getAppointments$: Subject<void>;
    private _saveAppointment$: Subject<void>;
    private _getLocations$: Subject<void>;
    private _getTimeslots$: Subject<void>;

    // Get Appointment
    public getAppointments$(): Observable<void> {
        this._getAppointments$ = new Subject();
        return this._getAppointments$.asObservable();
    }

    public onGetAppointmentsSuccess(): void {
        this._getAppointments$.next();
        this._getAppointments$.complete();
    }

    public onGetAppointmentsError(errors: ErrorInterface[]): void {
        this._getAppointments$.error(errors);
    }

    // Save Appointment
    public saveAppointment$(): Observable<void> {
        this._saveAppointment$ = new Subject();
        return this._saveAppointment$.asObservable();
    }

    public onSaveAppointmentSuccess(): void {
        this._saveAppointment$.next();
        this._saveAppointment$.complete();
    }

    public onSaveAppointmentError(errors: ErrorInterface[]): void {
        this._saveAppointment$.error(errors);
    }

    // Get Locations
    public getLocations$(): Observable<void> {
        this._getLocations$ = new Subject();
        return this._getLocations$.asObservable();
    }

    public onGetLocationsSuccess(): void {
        this._getLocations$.next();
        this._getLocations$.complete();
    }

    public onGetLocationsError(errors: ErrorInterface[]): void {
        this._getLocations$.error(errors);
    }

    // Get Locations
    public getTimeslots$(): Observable<void> {
        this._getTimeslots$ = new Subject();
        return this._getTimeslots$.asObservable();
    }

    public onGetTimeslotsSuccess(): void {
        this._getTimeslots$.next();
        this._getTimeslots$.complete();
    }

    public onGetTimeslotsError(errors: ErrorInterface[]): void {
        this._getTimeslots$.error(errors);
    }
}
