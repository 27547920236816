import { Injectable } from '@angular/core';
import { EnvironmentEnum, ApiUrlResourcesGermanyEnum, ApiUrlResourcesSpainEnum, ApiUrlResourcesNetherlandsEnum } from '../enums';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    public currentEnvironment: EnvironmentEnum;
    public environmentResources = {
        [EnvironmentEnum.Netherlands]: ApiUrlResourcesNetherlandsEnum,
        [EnvironmentEnum.Germany]: ApiUrlResourcesGermanyEnum,
        [EnvironmentEnum.Spain]: ApiUrlResourcesSpainEnum,
    };

    public setCurrentEnvironment(environment: EnvironmentEnum): void {
        this.currentEnvironment = environment;
    }

    public getEnvironmentResourceUrl(resourceUrl: string): string {
        if (!this.currentEnvironment) {
            throw new Error(
                `
                No environment has been set yet,
                you can set it by using setCurrentEnvironment() in outshared-lib/environment.service.ts
                `
            );
        }

        // Checks if the requested URL resource exists for the selected environment
        if (!this.environmentResources[this.currentEnvironment][resourceUrl]) {
            this.throwErrorMissingResourceUrl();
            /* istanbul ignore next */
            return;
        }

        return this.environmentResources[this.currentEnvironment][resourceUrl];
    }

    private throwErrorMissingResourceUrl(): void {
        throw new Error(
            `Cannot find a match for the resource URL for the current environment: ${this.currentEnvironment}`
        );
    }
}
