import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContentElementsInterface, ContentInterface } from '../interfaces';
import { Content, RequestElementAction } from '../store';
import { ContentApiService } from './content.api.service';
import { ContentDataService } from './content.data.service';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    private contentElements: ContentElementsInterface;

    constructor(
        private store$: Store<Content>,
        private apiService: ContentApiService,
        private dataService: ContentDataService
    ) {
        this.dataService
            .getElements$()
            .subscribe((contentElements: ContentElementsInterface) => (this.contentElements = contentElements));
    }

    /**
     * Ease-of-use function to just get from store or retrieve from api if not available
     */
    public getContent$(name: string): Observable<ContentInterface[]> {
        this.requestElementIfUnavailable(name);

        return this.dataService.getElement$(name);
    }

    /**
     * Ease-of-use function to just get from store or retrieve from api if not available
     */
    public getContentById$(name: string, id: string): Observable<ContentInterface> {
        this.requestElementIfUnavailable(name);

        return this.dataService.getElementById$(name, id).pipe(
            catchError((error) => {
                // If someone supplies an ID that doesn't exist, we don't break the other flows, but we notify
                console.error(error);
                return of(null);
            })
        );
    }

    private requestElementIfUnavailable(name: string): void {
        if (!this.contentElements.hasOwnProperty(name)) {
            this.store$.dispatch(new RequestElementAction({ request: { element_name: name }}));
        }
    }
}
