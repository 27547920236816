import { ApiProductBonusComponentResponse, ProductBonusComponentResponseInterface } from '../../interfaces';

export function mapProductBonusComponentResponse(
    response: ApiProductBonusComponentResponse[]
): ProductBonusComponentResponseInterface[] {
    if (!response) {
        return [];
    }

    return response.map((bonusComponent) => ({
        implementedPreventativeMeasure: bonusComponent.implemented_preventative_measure,
        implementedPreventativeMeasureFuture: bonusComponent.implemented_preventative_measure_future,
        bonusComponentDescription: bonusComponent.product_bonus_component_description,
        bonusComponentId: bonusComponent.product_bonus_component_id,
        bonusPoints: bonusComponent.product_bonus_points.bonus_points,
    }));
}
