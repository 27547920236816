import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService, ErrorInterface, ResponseInterface } from '../../core';
import {
    ApiGetInsuranceCompaniesResponseInterface,
    ApiMailQuestionRequest,
    ApiTerminationLetterResponseInterface,
    AttachmentRequestInterface,
    AttachmentResponseInterface,
    CategoryInterface,
    ComplaintRequestInterface,
    CreateEmailRequestInterface,
    DownloadTerminationLetterRequestInterface,
    DownloadTerminationLetterResponseInterface,
    EmailTerminationLetterRequestInterface,
    GetInsuranceCompaniesResponseInterface,
} from '../interfaces';
import {
    mapAddAttachmentResponse,
    mapAttachmentRequest,
    mapCategoriesRequest,
    mapCategoriesResponse,
    mapComplaintRequest,
    mapCreateEmailRequest,
    mapGetInsuranceCompaniesResponse,
    mapInitializeEmailResponse,
    mapSendEmailRequest,
} from '../mappers';
import { mapTerminationLetterRequest, mapTerminationLetterResponse } from '../mappers/termination-letter.mapper';

const RESOURCES = {
    REQUEST_NAVIGATION: 'en-gb/faq-general/request/navigation',
    CREATE_EMAIL: 'en-gb/relation-general/create/email-question',
    ADD_ATTACHMENT: 'en-gb/relation-general/create/email-question-attachment',
    PROVIDE_EMAIL: 'en-gb/relation-general/provide/email-question',
    GET_COMPANIES: 'en-gb/insurance_company-general/request/insurance_companies',
    DOWNLOAD_TERMINATION_LETTER: 'en-gb/relation-general/provide/pdf-letter_of_termination-new_relation',
    EMAIL_TERMINATION_LETTER: 'en-gb/relation-general/provide/email-letter_of_termination-new_relation',
};

@Injectable({
    providedIn: 'root',
})
export class ContactApiService {
    constructor(private apiService: ApiService) {}

    public loadCategories$(contactIndication: boolean): Observable<CategoryInterface[]> {
        const payload = mapCategoriesRequest(contactIndication);

        return this.apiService.post$(RESOURCES.REQUEST_NAVIGATION, payload).pipe(map(mapCategoriesResponse));
    }

    public createEmail$(request: CreateEmailRequestInterface): Observable<number> {
        const apiRequest = mapCreateEmailRequest(request);

        return this.initializeEmail$(apiRequest);
    }

    public sendEmail$(mailQuestionId: number): Observable<number> {
        const payload = mapSendEmailRequest(mailQuestionId);

        return this.apiService.post$(RESOURCES.PROVIDE_EMAIL, payload).pipe(map(() => mailQuestionId));
    }

    public sendComplaint$(request: ComplaintRequestInterface): Observable<number> {
        const payload: ApiMailQuestionRequest = mapComplaintRequest(request);

        return this.initializeEmail$(payload).pipe(
            switchMap((mailQuestionId: number) =>
                this.sendEmail$(mailQuestionId).pipe(catchError((errors: ErrorInterface[]) => throwError(errors)))
            )
        );
    }

    public addAttachment$(request: AttachmentRequestInterface): Observable<AttachmentResponseInterface> {
        const payload = mapAttachmentRequest(request);
        return this.apiService.postResponse$(RESOURCES.ADD_ATTACHMENT, payload).pipe(
            map((response: ResponseInterface) => {
                if (!response.data || !response.data.attachments) {
                    throw response.errors;
                }
                return mapAddAttachmentResponse(response.data);
            }),
            catchError((errors: ErrorInterface[]) => throwError(errors))
        );
    }

    public getInsuranceCompanies$(): Observable<GetInsuranceCompaniesResponseInterface[]> {
        return this.apiService.post$<ApiGetInsuranceCompaniesResponseInterface[]>(RESOURCES.GET_COMPANIES).pipe(
            map((response: ApiGetInsuranceCompaniesResponseInterface[]) => {
                return mapGetInsuranceCompaniesResponse(response);
            })
        );
    }

    public downloadTerminationLetter$(
        request: DownloadTerminationLetterRequestInterface
    ): Observable<DownloadTerminationLetterResponseInterface> {
        const payload = mapTerminationLetterRequest(request);
        return this.apiService.post$(RESOURCES.DOWNLOAD_TERMINATION_LETTER, payload).pipe(
            map((response: ApiTerminationLetterResponseInterface) => {
                return mapTerminationLetterResponse(response);
            }),
            catchError((errors: ErrorInterface[]) => throwError(errors))
        );
    }

    public emailTerminationLetter$(request: EmailTerminationLetterRequestInterface): Observable<boolean> {
        const payload = mapTerminationLetterRequest(request);
        return this.apiService.post$(RESOURCES.EMAIL_TERMINATION_LETTER, payload).pipe(
            map((response: boolean) => response),
            catchError((errors: ErrorInterface[]) => throwError(errors))
        );
    }

    private initializeEmail$(request: ApiMailQuestionRequest): Observable<number> {
        return this.apiService.post$(RESOURCES.CREATE_EMAIL, request).pipe(map(mapInitializeEmailResponse));
    }
}
