import { mapFromSuccessIndication } from '../../core';
import { mapValidityStatusCodeResponse } from '../../insurance-policy';
import { ApiLegalPersonInterface, LegalPartyInterface } from '../interfaces';
import { mapBusinessRelationLegalPartyVersionId } from './business-relation-legal-party-version-id.mapper';
import { mapLegalPartyRoleResponse } from './legal-party-role.mapper';
import { mapLegalPartyTypeCodeResponse } from './legal-party-type-code.mapper';
import { mapPersonRoleResponse } from './person-role.mapper';

export function mapLegalPersonResponse(
    response: ApiLegalPersonInterface[],
    enablePartyCodes?: boolean
): LegalPartyInterface[] {
    if (!response) {
        return [];
    }

    return response.map((person) => ({
        businessRelationId: person.business_relation_id,
        businessRelationLegalPartyVersionId: mapBusinessRelationLegalPartyVersionId(
            person.business_relation_legal_person_version_id,
            enablePartyCodes
        ),
        legalPartyTypeCode: mapLegalPartyTypeCodeResponse(person.lp_type_code, enablePartyCodes),
        legalPartyTypeDescription: person.lp_type_description,
        startDate: person.start_date,
        endDate: person.end_date,
        validityStatusCode: mapValidityStatusCodeResponse(person.validity_status_code),
        terminatedIndication: mapFromSuccessIndication(person.terminated_indication),
        terminatedIndicationDescription: person.terminated_indication_description,
        tradingName: person.trading_name,
        registrationOrganizationId: person.registration_organization_id,
        registrationOrganizationIdDescription: person.registration_organization_id_description,
        registrationEmployeeId: person.registration_employee_id,
        registrationEmployeeIdDescription: person.registration_organization_id_description,
        role: {
            role: mapLegalPartyRoleResponse(person.role.role, enablePartyCodes),
            roleDescription: person.role.rol_omschrijving,
            contact: mapPersonRoleResponse(person.role.contact_person),
            coInsured: mapPersonRoleResponse(person.role.co_insured),
            driver: mapPersonRoleResponse(person.role.driver),
            witness: mapPersonRoleResponse(person.role.witness),
            counterparty: mapPersonRoleResponse(person.role.counterparty),
            other: mapPersonRoleResponse(person.role.other_party),
        },
        roleDescription: person.rol_omschrijving,
    }));
}
