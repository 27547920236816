import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
    API_CONFIGURATION,
    ApiConfigurationInterface,
    ApiVersionService,
    NotificationInterface,
    RequestResponse,
    RequestService,
} from '../../core';
import { AcceptanceRequestInterface } from '../interfaces';
import { acceptanceClassicMapper } from '../mappers/acceptance-classic.mapper';
import { AuthenticationDataService } from '../../authentication';
import { LabelEnum } from '../../core/enums/label.enum';

const RESOURCES = {
    VALIDATE_ELEMENTS: 'en-gb/form-general/validate/elements',
    VALIDATE_ELEMENTS_EXISTINGRELATION: 'en-gb/form-general/validate/existingrelation-elements',
    VALIDATE_ELEMENTS_CLASSIC: 'en-gb/form-general/validate/eligibility_criteria',
};

@Injectable({
    providedIn: 'root',
})
export class AcceptanceService {
    constructor(
        private requestService: RequestService,
        private apiVersionService: ApiVersionService,
        private authenticationDataService: AuthenticationDataService,
        @Inject(API_CONFIGURATION) private configuration: ApiConfigurationInterface
    ) {}

    // TODO: Move this to Validation Module?
    public validate(
        payload: AcceptanceRequestInterface
    ): Observable<{ acceptance_success: string; notifications: NotificationInterface[] }> {
        if (this.apiVersionService.isClassic()) {
            return this.validateClassic$(payload);
        }

        return this.validateInternational$(payload);
    }

    private validateInternational$(payload: AcceptanceRequestInterface): Observable<any> {
        const payloadInternational = {
            container: payload.container,
            webservice_id: payload.webservice_id,
            acceptance_validation_type_code: payload.acceptance_validation_type_code,
        };

        return this.authenticationDataService.isLoggedIn$().pipe(
            take(1),
            switchMap((isLoggedIn: boolean) =>
                this.requestService
                    .post(
                        isLoggedIn && this.configuration.label === LabelEnum.ISDE
                            ? RESOURCES.VALIDATE_ELEMENTS_EXISTINGRELATION
                            : RESOURCES.VALIDATE_ELEMENTS,
                        payloadInternational
                    )
                    .pipe(map((response: RequestResponse) => this.getResponse(response)))
            )
        );
    }

    private validateClassic$(payload: AcceptanceRequestInterface): Observable<any> {
        const payloadClassic = {
            product_id: payload.product_id,
            validation_step_code: 'POST',
            elements: acceptanceClassicMapper(payload.container),
        };

        return this.requestService
            .post(RESOURCES.VALIDATE_ELEMENTS_CLASSIC, payloadClassic)
            .pipe(map((response: RequestResponse) => this.getResponse(response)));
    }

    private getResponse(response): any {
        if (!response) {
            throw [];
        }

        if (response.data.acceptance_success === 'N') {
            throw response.messages;
        }

        return response.data;
    }
}
