import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    GetClaimCompensationRequestInterface,
    GetClaimCompensationResponseInterface,
    GetClaimCoverageRequestInterface,
    GetClaimCoverageResponseInterface,
    SaveClaimCoverageRequestInterface,
    SaveClaimCoverageResponseInterface,
} from '../interfaces';

export const getClaimCompensation = createAction(
    '[claim coverage] get claim compensation',
    props<{ request: GetClaimCompensationRequestInterface }>()
);

export const getClaimCompensationSuccess = createAction(
    '[claim coverage] get claim compensation success',
    props<{ response: GetClaimCompensationResponseInterface }>()
);

export const getClaimCompensationError = createAction(
    '[claim coverage] get claim compensation error',
    props<{ errors: ErrorInterface[] }>()
);

export const getClaimCoverage = createAction(
    '[claim coverage] get claim coverage',
    props<{ request: GetClaimCoverageRequestInterface }>()
);

export const getClaimCoverageSuccess = createAction(
    '[claim coverage] get claim coverage success',
    props<{ response: GetClaimCoverageResponseInterface }>()
);

export const getClaimCoverageError = createAction(
    '[claim coverage] get claim coverage error',
    props<{ errors: ErrorInterface[] }>()
);

export const saveClaimCoverage = createAction(
    '[claim coverage] save claim coverage',
    props<{ request: SaveClaimCoverageRequestInterface }>()
);

export const saveClaimCoverageSuccess = createAction(
    '[claim coverage] save claim coverage success',
    props<{ response: SaveClaimCoverageResponseInterface }>()
);

export const saveClaimCoverageError = createAction(
    '[claim coverage] save claim coverage error',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimCoverage = createAction('[claim coverage] reset claim coverage');
