import { ValidationActions, ValidationActionType } from './validation.actions';
import { INITIAL_VALIDATION_STATE, ValidationState } from './validation.state';

export class ValidationReducers {
    public static reducer(
        state: ValidationState = INITIAL_VALIDATION_STATE,
        action: ValidationActionType
    ): ValidationState {
        switch (action.type) {
        case ValidationActions.ValidateElementsSuccess:
            return {
                ...state,
                validationElements: Object.assign({}, state.validationElements, { [action.payload.name]: []}),
            };

        case ValidationActions.ValidateElementsError:
            return {
                ...state,
                validationElements: Object.assign({}, state.validationElements, {
                    [action.payload.name]: action.payload.errors,
                }),
            };

        case ValidationActions.ValidateElements:
        default:
            return state;
        }
    }
}
