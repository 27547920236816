import { mapFormatCode, mapMandatoryCode } from '../../core';
import { ApiSpecificationGroupSpecInterface, SpecificationGroupSpecInterface } from '../interfaces';
import { mapSpecificationContentResponse } from './specification-content.mapper';
import { mapSpecificationLabelsResponse } from './specification-labels.mapper';

export function mapSpecificationGroupSpecResponse(
    response: ApiSpecificationGroupSpecInterface[]
): SpecificationGroupSpecInterface[] {
    return response.map((item: ApiSpecificationGroupSpecInterface) => ({
        content: mapSpecificationContentResponse(item.content),
        disabled: item.disabled,
        elementContent: item.element_content,
        elementDependence: item.element_dependence,
        elementDescription: item.element_description,
        elementId: item.element_id,
        elementName: item.element_name,
        formatCode: mapFormatCode(item.format_code),
        formatDescription: item.format_description,
        information: item.information,
        labels: mapSpecificationLabelsResponse(item.labels),
        mandatoryOptionalCode: mapMandatoryCode(item.mandatory_optional_code),
        mandatoryOptionalDescription: item.mandatory_optional_description,
        order: item.order,
        visible: item.visible,
    }));
}
