import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Coverage, CoveragePeriod, Insurance, Premium } from '../../interfaces';
import { SalesStorageService } from '../../services/sales-storage.service';
import { CalculationService } from '../calculation/calculation.service';
import { ProgressService } from '../progress/progress.service';
import * as coverageActions from './coverage.actions';
import { InsuranceService } from '../insurance/insurance.service';

@Injectable()
export class CoverageEffects {
    updateReceipt$: Observable<Action> = createEffect(() =>
        this.actions$.pipe(
            ofType(coverageActions.UPDATE_RECEIPT),
            withLatestFrom(
                this.calculationService.calculation$,
                this.progressService.productId$,
                this.insuranceService.currentInsuranceData$
            ),
            filter(([action, calculation]) => !!calculation),
            map(
                ([action, calculation, productId, insurance]: [
                    coverageActions.UpdateReceipt,
                    Premium,
                    string,
                    Insurance
                ]) => {
                    const chosenPeriod = calculation?.product?.invoice_periods?.find(
                        (coveragePeriod: CoveragePeriod) =>
                            coveragePeriod.invoice_period_months_number === insurance.premium_period_amount
                    );

                    const xSellCoverages = (calculation.product.xsell || []).reduce(
                        (acc, curr) => [...acc, ...curr.coverages],
                        []
                    );

                    const filteredBaseCoverages = this.filterCoverages(
                        chosenPeriod ? chosenPeriod.base_coverages : calculation.product.base_coverages,
                        action.payload.coverage
                    );

                    const filteredAddictionCoverages = this.filterCoverages(
                        chosenPeriod ? chosenPeriod.additional_coverage : calculation.product.additional_coverage,
                        action.payload.coverage
                    );
                    const filteredXSellCoverages = this.filterCoverages(xSellCoverages, action.payload.coverage);

                    const coverage = {
                        productId,
                        modules: action.payload.coverage,
                        coverage: [...filteredBaseCoverages, ...filteredAddictionCoverages, ...filteredXSellCoverages],
                    };

                    this.storageService.getCoverage().then((coverages) => {
                        this.storageService.setItem('coverages', {
                            ...coverages,
                            [productId]: coverage,
                        });
                    });

                    return new coverageActions.UpdateReceiptSuccess(coverage);
                }
            )
        )
    );

    constructor(
        private actions$: Actions,
        private calculationService: CalculationService,
        private progressService: ProgressService,
        private storageService: SalesStorageService,
        private insuranceService: InsuranceService
    ) {}

    private filterCoverages(coverages: Coverage[] = [], picked: string[]): Coverage[] {
        if (!coverages) {
            return [];
        }

        return coverages.filter((coverage: Coverage) => picked.includes(coverage.coverage_id));
    }
}
