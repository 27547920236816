import { mapSequenceNumber } from '../../core';
import { ApiReloadClaimObjectDetailsRequestInterface, ReloadClaimObjectDetailsRequestInterface } from '../interfaces';

export function mapReloadClaimObjectDetailsRequest(
    request: ReloadClaimObjectDetailsRequestInterface
): ApiReloadClaimObjectDetailsRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        claim_object: {
            claim_object_id: request.claimObjectId,
        },
        element: {
            element_name: request.elementName,
            element_content: request.elementContent,
        },
    };
}
