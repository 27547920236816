import { ApiUpdateEmailAddressRequest } from '../../account-recovery/interfaces/api.update-email-address.request';
import { UpdateEmailAddressRequestInterface } from '../interfaces/update-email-address-request.interface';
import { EmailTypeCodeEnum } from '../enums/email-type-code.enum';
import { ApiEmailTypeCodeEnum } from '../enums/api-email-type-code.enum';

export function mapUpdateEmailAddressRequest(
    request: UpdateEmailAddressRequestInterface
): ApiUpdateEmailAddressRequest {
    return {
        email_address: request.emailAddress,
        email_address_type_code: mapTypeCodeRequest(request.emailAddressTypeCode),
    };
}

function mapTypeCodeRequest(request: EmailTypeCodeEnum): ApiEmailTypeCodeEnum {
    switch (request) {
        case EmailTypeCodeEnum.Primary:
            return ApiEmailTypeCodeEnum.Primary;
        case EmailTypeCodeEnum.Secondary:
            return ApiEmailTypeCodeEnum.Secondary;
    }
}
