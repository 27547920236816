import { ApiMailQuestionRequest, ApiMailQuestionResponse, CreateEmailRequestInterface } from '../interfaces';

export function mapCreateEmailRequest(request: CreateEmailRequestInterface): ApiMailQuestionRequest {
    return {
        mail_question: {
            mail_question_id: request.mailQuestionId,
            email_address: request.email,
        },
        mail_question_input: {
            category_code: request.categoryCode,
            subject_code: request.subjectCode,
            question: request.question,
        },
    };
}

export function mapInitializeEmailResponse(response: ApiMailQuestionResponse): number {
    return response.mail_question_id;
}
