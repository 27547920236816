import { mapFromSuccessIndication } from '../../../core/mappers';
import { ApiProductBicycleResponse, ProductBicycleResponseInterface } from '../../interfaces';

export function mapProductBicycleResponse(response: ApiProductBicycleResponse): ProductBicycleResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        bicycleBrand: response.bicycle_brand,
        bicycleBrandDescription: response.bicycle_brand_description,
        bicycleKeyNumber: response.bicycle_key_number,
        bicycleModel: response.bicycle_model,
        bicycleTypeDescription: response.fiets_type_omschrijving,
        catalogValueAmount: response.catalog_value_amount,
        framenumber: response.framenumber,
        newIndication: mapFromSuccessIndication(response.new_indication),
        purchaseDate: response.purchase_date,
        purchaseValueAmount: response.purchase_value_amount,
    };
}
