import { mapFromSuccessIndication, mapSequenceNumber } from '../../../core';
import { ApiPolicyResponse, ApiProductResponse, PolicyResponseInterface, ProductInterface } from '../../interfaces';
import { mapProductBicycleResponse } from '../product/product-bicycle.mapper';
import { mapProductCarResponse } from '../product/product-car.mapper';
import { mapProductCaravanResponse } from '../product/product-caravan.mapper';
import { mapProductHomeResponse } from '../product/product-home.mapper';
import { mapProductIdResponse } from '../product/product-id.mapper';
import { mapProductMopedResponse } from '../product/product-moped.mapper';
import { mapProductMotorcycleResponse } from '../product/product-motorcycle.mapper';
import { mapProductPetResponse } from '../product/product-pet.mapper';
import { mapProductRelatedVehicleResponse } from '../product/product-related-vehicle.mapper';
import { mapProductSubstitutedByVehicleResponse } from '../product/product-substituted-vehicle.mapper';
import { mapValidityStatusCodeResponse } from '../product/validity-status-code.mapper';

function mapProduct(response: ApiProductResponse): ProductInterface {
    return {
        bicycle: mapProductBicycleResponse(response.bicycle),
        car: mapProductCarResponse(response.auto),
        caravan: mapProductCaravanResponse(response.caravan),
        conditionAgreementIndication: mapFromSuccessIndication(response.condition_agreement_indication),
        conditionAgreementIndicationDescription: response.condition_agreement_indication_description,
        electronicProductAgreementIndication: mapFromSuccessIndication(response.electronic_policy_agreement_indication),
        electronicPolicyAgreementIndicationDescription: response.electronic_policy_agreement_indication_description,
        endDate: response.end_date,
        home: mapProductHomeResponse(response.home),
        moped: mapProductMopedResponse(response.limited_speed_motorcycle),
        motorcycle: mapProductMotorcycleResponse(response.motorcycle),
        pet: mapProductPetResponse(response.pet),
        productDescription: response.product_description,
        relatedVehicle: mapProductRelatedVehicleResponse(response.related_vehicle),
        singleTravel: response.single_travel,
        startDate: response.start_date,
        substitutedVehicle: mapProductSubstitutedByVehicleResponse(response.substituted_by_vehicle),
        validityStatusCode: mapValidityStatusCodeResponse(response.validity_status_code),
    };
}

export function mapPolicyResponse(response: ApiPolicyResponse): PolicyResponseInterface {
    const purchasedProducts = response.purchased_products;

    if (purchasedProducts.length < 1) {
        return {
            purchasedProducts: [],
        };
    }

    return {
        purchasedProducts: purchasedProducts.map((product) => ({
            productId: mapProductIdResponse(product.product_id),
            productSequenceNumber: mapSequenceNumber(product.purchased_product_sequence_number),
            product: mapProduct(product),
        })),
    };
}