/* eslint-disable @angular-eslint/component-selector */
import { Component, Inject } from '@angular/core';
import { NewModalRef } from '../new-modal-ref';
import { NEW_MODAL_DATA } from '../new-modal-utilities';
import { DefaultModalData } from './default-modal-data';

@Component({
    selector: 'out-default-modal',
    templateUrl: './default-modal.component.html',
})
export class DefaultModalComponent {
    constructor(
        public modalRef: NewModalRef<DefaultModalComponent>,
        @Inject(NEW_MODAL_DATA) public data: DefaultModalData
    ) {}

    public onDeclineClick(): void {
        this.modalRef.close();
    }
}
