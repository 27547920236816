import { ApiUpdatePrimaryEmailRequest } from '../interfaces';
import { UpdatePrimaryEmailInterface } from '../interfaces/update-primary-email.interface';

export function mapUpdatePrimaryEmailRequest(request: UpdatePrimaryEmailInterface): ApiUpdatePrimaryEmailRequest {
    return {
        primary_email_address: {
            email_address: request.email.trim(),
        },
        current_password: request.currentPassword.trim(),
    };
}
