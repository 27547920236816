import { mapFromGenderCode, mapPhone, mapSequenceNumber } from '../../core/mappers';
import { ApiSaveWitnessRequestInterface, SaveWitnessRequestInterface } from '../interfaces';

export function mapSaveWitnessRequest(request: SaveWitnessRequestInterface): ApiSaveWitnessRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.claimSequenceNumber),
        },
        witness: {
            gender_code: mapFromGenderCode(request.witness.genderCode),
            pre_nominal_letters: request.witness.preNominalLetters,
            initials: request.witness.initials,
            prefix_lastname: request.witness.prefixLastname,
            first_name: request.witness.firstName,
            surname: request.witness.surname,
            birthdate: request.witness.birthdate,
            email_address: request.witness.emailAddress,
            telephone_country_code: request.witness.telephoneCountryCode,
            telephone_number: mapPhone(request.witness.telephoneNumber),
            external_reference_id: request.witness.externalReferenceId,
            soort_extern_referentie_code: request.witness.typeExternalReferenenceCode,
            postal_code: request.witness.postalCode,
            civic_number: request.witness.civicNumber,
            civic_number_suffix: request.witness.civicNumberSuffix,
            country_id: request.witness.countryId,
            street_name: request.witness.streetName,
            city: request.witness.city,
            province_code: request.witness.provinceCode,
            post_office_box: request.witness.postOfficeBox,
            additional_addressing_explanation: request.witness.additionalAddressingExplanation,
        },
    };
}
