import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({ providedIn: 'root' })
export class ClaimCategoriesEventService {
    private _allClaimCategories$: Subject<void>;

    public allClaimCategories$(): Observable<void> {
        this._allClaimCategories$ = new Subject();
        return this._allClaimCategories$.asObservable();
    }

    public onAllClaimCategoriesSuccess(): void {
        this._allClaimCategories$.next();
        this._allClaimCategories$.complete();
    }

    public onAllClaimCategoriesError(errors: ErrorInterface[]): void {
        this._allClaimCategories$.error(errors);
    }
}
