/** @deprecated move to module where its needed, but please don't rely on these ID's they are instance specific */
export const VEHICLE = {
    /**
     * @description 11258-12 - Your %value% is already insured. You can change or terminate your current insurance by
     * clickingthis insurance and selecting ‘cancel’ or ‘change’. If you want to take out the insurance again, you must
     * first cancel the current insurance.
     */
    duplicate: '11258-12',
    /**
     * @description 11258-14 - U heeft al een verzekering voor deze auto afgesloten die nog niet is ingegaan. U kunt de
     * ingangsdatum van deze verzekering aanpassen. Wilt u toch een nieuwe verzekering sluiten? Annuleer dan eerst de al
     * afgesloten verzekering en sluit daarna de nieuwe verzekering af.
     */
    duplicateFuture: '11258-14',
};
