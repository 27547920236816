import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API_CONFIGURATION } from '../configuration';
import { ApiConfigurationInterface, ApiResponse, ResponseInterface } from '../interfaces';
import { mapRawErrorResponse, mapRawResponse, mapResponseToGeneric } from '../mappers';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        withCredentials: true,
    };

    constructor(
        private client: HttpClient,
        @Inject(API_CONFIGURATION) private configuration: ApiConfigurationInterface
    ) {}

    public get$<T>(resource: string): Observable<T> {
        return this.getResponse$(resource).pipe(
            map(ApiService.throwErrors),
            map((response: ResponseInterface) => mapResponseToGeneric<T>(response))
        );
    }

    public post$<T>(resource: string, payload: any = {}): Observable<T> {
        return this.postResponse$(resource, payload).pipe(
            map(ApiService.throwErrors),
            map((response: ResponseInterface) => mapResponseToGeneric<T>(response))
        );
    }

     /**
     * Use with caution!
     * Post and skip errorhandling
     */
    public postWithoutErrorHandling$<T>(resource: string, payload: any = {}): Observable<T> {
        return this.postResponse$(resource, payload).pipe(
            map((response: ResponseInterface) => mapResponseToGeneric<T>(response))
        );
    }

    /**
     * Returns always a mapped response, never an error. Error is based statusCode.
     */
    public getResponse$(resource: string): Observable<ResponseInterface> {
        return this.rawGetResponse$(resource).pipe(
            map(mapRawResponse),
            catchError((error: HttpErrorResponse) => of(mapRawErrorResponse(error)))
        );
    }

    /**
     * Returns always a mapped response, never an error. Error is based statusCode.
     */
    public postResponse$(resource: string, payload: any = {}): Observable<ResponseInterface> {
        return this.rawPostResponse$(resource, payload).pipe(
            map(mapRawResponse),
            catchError((error: HttpErrorResponse) => of(mapRawErrorResponse(error)))
        );
    }

    /**
     * Returns HttpResponse in the next() or HttpErrorResponse in error() and then completes the observable.
     */
    public rawGetResponse$(resource: string): Observable<HttpResponse<ApiResponse>> {
        const url = this.getUrl(resource);

        return this.client.get<ApiResponse>(url, { ...this.httpOptions, observe: 'response' });
    }

    /**
     * Returns HttpResponse in the next() or HttpErrorResponse in error() and then completes the observable.
     */
    public rawPostResponse$(resource: string, payload: any = {}): Observable<HttpResponse<ApiResponse>> {
        const url = this.getUrl(resource);

        return this.client.post<ApiResponse>(url, payload, { ...this.httpOptions, observe: 'response' });
    }

    public static throwErrors(response: ResponseInterface): ResponseInterface {
        if (response.data === false || response.data === null || !response.successIndication) {
            throw response.errors;
        }
        return response;
    }

    private getUrl(resource: string): string {
        return `${this.configuration.apiUrl}/${resource}`;
    }
}
