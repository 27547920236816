import { ExhibitInterface, ExhibitsButtonInterface, ExhibitsSubmitInterface } from '../interfaces';

export const CLAIM_EXHIBITS_FEATURE = 'claimExhibits';

export interface ClaimExhibitsState {
    exhibits: ExhibitInterface[];
    submit: ExhibitsSubmitInterface;
    buttons: ExhibitsButtonInterface[];
}

export const CLAIM_EXHIBITS_INITIAL_STATE: ClaimExhibitsState = {
    exhibits: [],
    submit: null,
    buttons: [],
};
