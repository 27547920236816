// Backend does not have records of all status codes in
// the database. The validity status codes below are
// the known validity status codes.
export enum ApiValidityStatusCodeEnum {
    All = 'ALLE',
    Active = 'ACTI',
    ActiveFuture = 'ACTO',
    ActiveEnded = 'ACEI',
    ActiveChange = 'ACWI',
    Future = 'TOEK',
    Transfer = 'OSTP',
    Disbarred = 'NEVT', // Disbarred not ended
    Ended = 'EIND',
}
