export enum ApiClaimProcessIdEnum {
    AgreeOnClaimEffects = 'A-EFF',
    CoverageIndication = 'D-IND',
    OpenActionsOnAppointments = 'O-AFS',
    OpenActionsOnSubject = 'O-BTR',
    OpenActionsOnEvidence = 'O-BWS',
    ViewOfAppointments = 'W-AFS',
    ViewOfParties = 'W-BTR',
    ViewOfDocuments = 'W-BWS',
}
