import { mapFromGenderCode, mapRelationCodeRequest, mapSequenceNumber } from '../../../core/mappers';
import { AddRegularDriverRequestInterface, ApiAddRegularDriverRequest } from '../../interfaces';

export function mapAddRegularDriverRequest(request: AddRegularDriverRequestInterface): ApiAddRegularDriverRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
        non_regular_driver: {
            start_date: request.startDate,
            relation_code: mapRelationCodeRequest(request.relationCode),
            gender_code: mapFromGenderCode(request.genderCode),
            initials: request.initials,
            first_name: null,
            surname: request.surname,
            middle_name_initials: request.middleNameInitials,
            postal_code: null,
            civic_number: null,
            civic_number_suffix: null,
            country_id: null,
            birthdate: request.birthdate,
            email_address: null,
        },
    };
}
