import { ApiDeleteExhibitRequestInterface, DeleteExhibitRequestInterface } from '../interfaces';

export function mapDeleteExhibitRequest(request: DeleteExhibitRequestInterface): ApiDeleteExhibitRequestInterface {
    return {
        case: {
            case_sequence_number: request.caseSequenceNumber,
        },
        upload_item: {
            upload_envelope_item_sequence_number: request.uploadEnvelopeItemSequenceNumber,
            upload_envelope_sequence_number: request.uploadEnvelopeSequenceNumber,
        },
    };
}
