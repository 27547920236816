import { createAction, props, union } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { AggregateInterface, AggregateRequestInterface } from '../interfaces';

export enum AggregateActionsEnum {
    GetAggregate = '[Reviews] Get Aggregate',
    GetAggregateSuccess = '[Reviews] Get Aggregate Success',
    GetAggregateError = '[Reviews] Get Aggregate Error',
}

export const getAggregateAction = createAction(AggregateActionsEnum.GetAggregate, props<AggregateRequestInterface>());

export const getAggregateSuccessAction = createAction(
    AggregateActionsEnum.GetAggregateSuccess,
    props<AggregateInterface>()
);

export const getAggregateErrorAction = createAction(
    AggregateActionsEnum.GetAggregateError,
    props<{ errors: ErrorInterface[] }>()
);

const aggregatesActionsUnion = union({
    getAggregateAction,
    getAggregateSuccessAction,
    getAggregateErrorAction,
});

export type AggregateActions = typeof aggregatesActionsUnion;
