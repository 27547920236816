import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';
import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class InternationalMotorInsuranceCardEventService {
    private _loadPdfInternationalMotorInsuranceCard$: Subject<PdfInternationalMotorInsuranceCardInterface>;
    private _loadPdfNotInsuredInternationalMotorInsuranceCard$: Subject<PdfInternationalMotorInsuranceCardInterface>;
    private _loadEmailNotInsuredInternationalMotorInsuranceCard$: Subject<string>;
    private _loadEmailInternationalMotorInsuranceCard$: Subject<string>;

    public loadPdfInternationalMotorInsuranceCard$(): Observable<PdfInternationalMotorInsuranceCardInterface> {
        this._loadPdfInternationalMotorInsuranceCard$ = new Subject<PdfInternationalMotorInsuranceCardInterface>();
        return this._loadPdfInternationalMotorInsuranceCard$.asObservable();
    }

    public onLoadPdfInternationalMotorInsuranceCardSuccess$(card: PdfInternationalMotorInsuranceCardInterface): void {
        this._loadPdfInternationalMotorInsuranceCard$.next(card);
        this._loadPdfInternationalMotorInsuranceCard$.complete();
    }

    public onLoadPdfInternationalMotorInsuranceCarError$(errors: ErrorInterface[]): void {
        this._loadPdfInternationalMotorInsuranceCard$.error(errors);
    }

    public loadPdfNotInsuredInternationalMotorInsuranceCard$(): Observable<PdfInternationalMotorInsuranceCardInterface> {
        this._loadPdfNotInsuredInternationalMotorInsuranceCard$ =
            new Subject<PdfInternationalMotorInsuranceCardInterface>();
        return this._loadPdfNotInsuredInternationalMotorInsuranceCard$.asObservable();
    }

    public onLoadPdfNotInsuredInternationalMotorInsuranceCardSuccess$(
        card: PdfInternationalMotorInsuranceCardInterface
    ): void {
        this._loadPdfNotInsuredInternationalMotorInsuranceCard$.next(card);
        this._loadPdfNotInsuredInternationalMotorInsuranceCard$.complete();
    }

    public onLoadPdfNotInsuredInternationalMotorInsuranceCarError$(errors: ErrorInterface[]): void {
        this._loadPdfNotInsuredInternationalMotorInsuranceCard$.error(errors);
    }

    public loadEmailInternationalMotorInsuranceCard$(): Observable<string> {
        this._loadEmailInternationalMotorInsuranceCard$ = new Subject<string>();
        return this._loadEmailInternationalMotorInsuranceCard$.asObservable();
    }

    public onLoadEmailInternationalMotorInsuranceCardSuccess$(message: string): void {
        this._loadEmailInternationalMotorInsuranceCard$.next(message);
        this._loadEmailInternationalMotorInsuranceCard$.complete();
    }

    public onLoadEmailInternationalMotorInsuranceCarError$(errors: ErrorInterface[]): void {
        this._loadEmailInternationalMotorInsuranceCard$.error(errors);
    }

    public loadEmailNotInsuredInternationalMotorInsuranceCard$(): Observable<string> {
        this._loadEmailNotInsuredInternationalMotorInsuranceCard$ = new Subject<string>();
        return this._loadEmailNotInsuredInternationalMotorInsuranceCard$.asObservable();
    }

    public onLoadEmailNotInsuredInternationalMotorInsuranceCardSuccess$(message: string): void {
        this._loadEmailNotInsuredInternationalMotorInsuranceCard$.next(message);
        this._loadEmailNotInsuredInternationalMotorInsuranceCard$.complete();
    }

    public onLoadEmailNotInsuredInternationalMotorInsuranceCarError$(errors: ErrorInterface[]): void {
        this._loadEmailNotInsuredInternationalMotorInsuranceCard$.error(errors);
    }
}
