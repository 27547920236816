export enum ApiV1LegalPartyRoleEnum {
    OccupationalTherapist = 'ARBE', // Arbeidsdeskundige
    Advocate = 'BELA', // Belangenbehartiger
    Driver = 'IDVR', // Bestuurder
    ContraExpert = 'CEXP', // Contra Expert
    Contact = 'ACPN', // Contactpersoon
    Expert = 'EXPE', // Expert
    Witness = 'WTNS', // Getuige
    DelegatedClaimsAdjuster = 'GSCH', // Gedelegeerd schaderegelaar
    Caregiver = 'HULP', // Hulpverlener
    InjuryLawyer = 'LADV', // Letsel advocaat
    InjuryClaimsAdjuster = 'LSCH', // Letsel schaderegelaar
    MedicalAviser = 'MADV', // Medisch Adviseur
    CoInsured = 'MEDE', // Medeverzekerde
    MedicalExpert = 'MEXP', // Medisch Expert
    MedicalCareWorker = 'MHUL', // Medisch Hulpverlener
    MedicalSpecialist = 'MSPE', // Medisch Specialist
    Buyer = 'OPKO', // Opkoper
    GovernmentAgency = 'OVEH', // Overheidsinstantie
    OtherParty = 'OVEP', // Overige Partij
    ResourceTaker = 'REGR', // Regresnemer
    Arithmetic = 'REKE', // Rekenkundige
    DamageRepairer = 'SHER', // Schadehersteller
    Counterparty = 'TEGE', // Tegenpartij
    BenefitsAgency = 'UITK', // Uitkeringsinstantie
    PolicyHolder = 'VERZ', // Verzekeringsnemer
    Employer = 'WERK', // Werkgever
    HealthInsurer = 'ZORG', // Zorgverzekeraar
}
