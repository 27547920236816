import { ClaimCoverageInterface, ClaimCompensationInterface } from '../interfaces';

export interface ClaimCoverageState {
    claimCompensation: ClaimCompensationInterface;
    claimCoverage: ClaimCoverageInterface;
}

export const CLAIM_COVERAGE_FEATURE = 'claimCoverage';

export const INITIAL_CLAIM_COVERAGE_STATE: ClaimCoverageState = {
    claimCompensation: null,
    claimCoverage: null,
};
