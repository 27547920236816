import { ApiReviveTerminatedPurchasedProductRequest,
} from '../interfaces/product/api.revive-terminated-purchased-product.request';

/** @deprecated use OutInsurancePolicyModule store */
export function mapReviveTerminatedActivePurchasedProductRequest(
    purchasedProductSequenceNumber: number
): ApiReviveTerminatedPurchasedProductRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: purchasedProductSequenceNumber,
        },
    };
}
