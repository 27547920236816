import { ApiGenderCodeEnum, GenderCodeEnum } from '../enums';

export function mapGenderCode(response: ApiGenderCodeEnum): GenderCodeEnum | null {
    switch (response) {
        case ApiGenderCodeEnum.Female:
            return GenderCodeEnum.Female;
        case ApiGenderCodeEnum.Male:
            return GenderCodeEnum.Male;
        case ApiGenderCodeEnum.Neutral:
            return GenderCodeEnum.Neutral;
    }
}

export function mapFromGenderCode(request: GenderCodeEnum): ApiGenderCodeEnum | null {
    switch (request) {
        case GenderCodeEnum.Female:
            return ApiGenderCodeEnum.Female;
        case GenderCodeEnum.Male:
            return ApiGenderCodeEnum.Male;
        case GenderCodeEnum.Neutral:
            return ApiGenderCodeEnum.Neutral;
    }
}
