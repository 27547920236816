import { RecoveryMethodEnum } from '../enums';
import {
    ApiRecoveryMethodResponse,
    ApiRecoveryMethodsRequest,
    ApiRecoveryMethodsResponse,
    RecoveryMethodInterface,
    RecoveryMethodsRequestInterface,
    RecoveryMethodsResponseInterface,
} from '../interfaces';

export function mapRecoveryMethodsRequest(request: RecoveryMethodsRequestInterface): ApiRecoveryMethodsRequest {
    return {
        relation: {
            birthdate: request.birthdate,
            primary_email_address: {
                email_address: request.email,
            },
        },
    };
}

export function mapRecoveryTypeEnum(typeCode: string): RecoveryMethodEnum {
    switch (typeCode) {
    case 'SECU': {
        return RecoveryMethodEnum.RecoveryEmail;
    }
    case 'MOBI': {
        return RecoveryMethodEnum.Phone;
    }
    case 'PRIM':
    default: {
        return RecoveryMethodEnum.PrimaryEmail;
    }
    }
}

export function mapRecoveryMethod(
    response: ApiRecoveryMethodResponse,
    isPhone: boolean = false
): RecoveryMethodInterface {
    const recoveryMethod: RecoveryMethodInterface = {
        typeCode: isPhone
            ? mapRecoveryTypeEnum(response.telephone_number_type_code)
            : mapRecoveryTypeEnum(response.email_address_type_code),
        recipient: isPhone ? response.telephone_number : response.email_address,
        startDate: new Date(response.start_date),
    };

    if (response.end_date) {
        recoveryMethod.endDate = new Date(response.end_date);
    }
    if (response.validity_status_code) {
        recoveryMethod.validityStatusCode = response.validity_status_code;
    }

    return recoveryMethod;
}

export function mapRecoveryMethodsResponse(apiResponse: ApiRecoveryMethodsResponse): RecoveryMethodsResponseInterface {
    const response: RecoveryMethodsResponseInterface = {};

    if (!!apiResponse.relation.primary_email_address) {
        response.primaryEmail = mapRecoveryMethod(apiResponse.relation.primary_email_address);
    }
    if (!!apiResponse.relation.recovery_email_address) {
        response.recoveryEmail = mapRecoveryMethod(apiResponse.relation.recovery_email_address);
    }
    if (!!apiResponse.relation.mobile_telephone_number) {
        response.phone = mapRecoveryMethod(apiResponse.relation.mobile_telephone_number, true);
    }

    return response;
}
