import { createSelector } from '@ngrx/store';
import { getSalesState } from '../sales.reducers';
import * as fromCustomer from './customer.reducer';

export const getCustomerState = createSelector(getSalesState, (state) => state.customer);

export const getData = createSelector(getCustomerState, fromCustomer.getData);
export const getPersonalData = createSelector(getData, (data) => {
    return data;
});
export const getErrors = createSelector(getCustomerState, fromCustomer.getErrors);
export const isReturningCustomer = createSelector(getCustomerState, fromCustomer.isReturningCustomer);

export const getRelationId = createSelector(getPersonalData, (customer) => {
    return customer ? customer.relation_id : null;
});
