import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimCoverageEffects } from './store/claim-coverage.effects';
import { claimCoverageReducer } from './store/claim-coverage.reducer';
import { CLAIM_COVERAGE_FEATURE } from './store/claim-coverage.state';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(CLAIM_COVERAGE_FEATURE, claimCoverageReducer),
        EffectsModule.forFeature([ClaimCoverageEffects]),
    ],
})
export class OutClaimCoverageModule {}
