import { LoginTokenRequestInterface, ApiLoginTokenRequest } from '../interfaces';
import { LoginTokenTypeCodeEnum, ApiLoginTokenTypeCodeEnum } from '../enums';

export function mapLoginTokenRequest(request: LoginTokenRequestInterface): ApiLoginTokenRequest {
    return {
        email_address: request.emailAddress,
        email_address_type_code: mapTokenTypeCodeRequest(request.emailAddressTypeCode),
    };
}

function mapTokenTypeCodeRequest(request: LoginTokenTypeCodeEnum): ApiLoginTokenTypeCodeEnum {
    switch (request) {
        case LoginTokenTypeCodeEnum.Primary:
            return ApiLoginTokenTypeCodeEnum.Primary;
        case LoginTokenTypeCodeEnum.Secondary:
            return ApiLoginTokenTypeCodeEnum.Secondary;
    }
}
