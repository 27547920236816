import { mapFromSuccessIndication } from '../../core';
import { mapCoverageIdResponse } from '../../insurance-policy';
import { ApiClaimCoverageCoveredResponseInterface, ClaimCoverageCoveredInterface } from '../interfaces';

export function mapCoveredResponse(
    response: ApiClaimCoverageCoveredResponseInterface[]
): ClaimCoverageCoveredInterface[] {
    if (!response) {
        return [];
    }

    return response.map((claimCoverageResponse) => ({
        claimCoverageNoteCodeDescription: claimCoverageResponse.claim_coverage_note_code_description,
        claimObjectDescription: claimCoverageResponse.claim_object_description,
        coverageDescription: claimCoverageResponse.coverage_description,
        coverageId: mapCoverageIdResponse(claimCoverageResponse.coverage_id),
        coveredIndication: mapFromSuccessIndication(claimCoverageResponse.covered_indication),
        coveredIndicationDescription: claimCoverageResponse.covered_indication_description,
    }));
}
