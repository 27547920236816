import {
    ApiClaimStepResponseInterface,
    ApiClaimStepsRequestInterface,
    ApiClaimStepsResponseInterface,
    ClaimStepResponseInterface,
    ClaimStepsRequestInterface,
} from '../interfaces';
import { mapPositionProcedureExplanationCodeResponse } from './position-procedure-explanation-code.mapper';
import { mapProcedureTypeCodeResponse } from './procedure-type-code.mapper';

export function mapClaimStepsRequest(response: ClaimStepsRequestInterface): ApiClaimStepsRequestInterface {
    return {
        case: {
            case_sequence_number: response.caseSequenceNumber,
        },
    };
}

export function mapClaimStepResponse(response: ApiClaimStepResponseInterface): ClaimStepResponseInterface {
    return {
        procedureTypeCodeDescription: response.procedure_type_code_description,
        procedureTypeDescription: response.procedure_type_description,
        procedureExplanationCodeDescription: response.procedure_explanation_code_description,
        procedureExplanationDescription: response.procedure_explanation_description,
        procedureTypeCode: mapProcedureTypeCodeResponse(response.procedure_type_code),
        positionProcedureExplanationDescription: response.positie_procedure_uitleg_omschrijving,
        positionProcedureExplanationCodeDescription: response.positie_procedure_uitleg_code_omschrijving,
        positionProcedureExplanationCode: mapPositionProcedureExplanationCodeResponse(
            response.positie_procedure_uitleg_code
        ),
        order: response.order,
    };
}

export function mapClaimStepsResponse(response: ApiClaimStepsResponseInterface): ClaimStepResponseInterface[] {
    return response.next_steps.map(mapClaimStepResponse);
}
