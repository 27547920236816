import { createSelector } from '@ngrx/store';
import { getSalesState } from '../sales.reducers';
import { Modal, UserChoice } from './vehicle-replace.enums';
import * as fromCarReplacement from './vehicle-replace.reducer';

// Get state
export const getCarReplacementState = createSelector(getSalesState, (state) => state.vehicleReplace);

// Create selectors
export const getCurrent = createSelector(getCarReplacementState, fromCarReplacement.getCurrent);
export const getErrors = createSelector(getCarReplacementState, fromCarReplacement.getErrors);
export const getModal = createSelector(getCarReplacementState, fromCarReplacement.getModal);
export const getUserChoice = createSelector(getCarReplacementState, fromCarReplacement.getUserChoice);
export const isLoading = createSelector(getCarReplacementState, fromCarReplacement.isLoading);

// Combined selectors
export const showModal = createSelector(getModal, getUserChoice, (modal, userChoice) => {
    return modal === Modal.Yes && userChoice === UserChoice.Initial;
});
