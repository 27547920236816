export enum ApiUrlResourcesGermanyEnum {
    // Claim Funnel
    ClaimCategories = 'en-gb/damage-damage_report/request/kind_of_loss-collection',
    RequestClaimSpecification = 'en-gb/damage-damage_report/store/specification-kind_of_loss',
    ClaimObjects = 'en-gb/damage-report/provide/claim_objects',
    ClaimObjectDetails = 'en-gb/damage-damage_report/request/specificatie-schadeobject',
    SaveClaimObject = 'en-gb/damage-damage_report/store/specificatie-schadeobject',
    RemoveClaimObject = 'en-gb/damage-damage_report/delete/suffered_damage',
    ClaimCoverage = 'en-gb/damage-damage_report/bepalen/claim_coverage',
    ClaimCoverageCustomerResponse = 'en-gb/damage-damage_report/store/customerresponse',
    GetParties = 'en-gb/claim-case/request/parties',
    SaveWitness = 'en-gb/damage-report/store/witness',
    SaveContact = 'en-gb/damage-report/store/contactperson',
    SaveCounterParty = 'en-gb/damage-report/store/counterparty',
    ClaimLoadExhibits = 'en-gb/claim-case/request/exhibits',
    ClaimSaveExhibits = 'en-gb/schade-correspondentie/upload/exhibit',
    ClaimDeleteExhibits = 'en-gb/schade-correspondentie/delete/exhibit',
    ClaimSubmitExhibits = 'en-gb/schade-correspondentie/submit/exhibit',
    GetClaimNotification = 'en-gb/damage-correspondence/request/case-notification',
    SaveClaimNotification = 'en-gb/damage-correspondence/modify/case-notification',
}
