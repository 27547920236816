import { mapSequenceNumber } from '../../core';
import { ApiSaveClaimObjectResponseInterface } from '../interfaces/api.save-claim-object-response.interface';
import { SaveClaimObjectResponseInterface } from '../interfaces/save-claim-object-response.interface';

export function mapSaveClaimObjectReponse(
    response: ApiSaveClaimObjectResponseInterface
): SaveClaimObjectResponseInterface {
    return {
        savedClaimObjects: [
            {
                claimObjectId: response.claim_object_id,
                sustainedDamageSequenceNumber: mapSequenceNumber(response.sustained_damage_sequence_number),
            },
        ],
    };
}
