import { mapSequenceNumber } from '../../../core/mappers';
import { ApiProductDetailsRequest, ProductDetailsRequestInterface } from '../../interfaces';

export function mapProductDetailsRequest(request: ProductDetailsRequestInterface): ApiProductDetailsRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
