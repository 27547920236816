import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { DamageFreeYearsImpactRequestInterface, DamageFreeYearsImpactResponseInterface } from '../interfaces';

export const damageFreeYearsImpact = createAction(
    '[Damage free years impact] damage free years impact',
    props<{ request: DamageFreeYearsImpactRequestInterface }>()
);

export const damageFreeYearsImpactSuccess = createAction(
    '[Damage free years impact] damage free years impact success',
    props<{ response: DamageFreeYearsImpactResponseInterface }>()
);

export const damageFreeYearsImpactFailure = createAction(
    '[Damage free years impact] damage free years impact failure',
    props<{ errors: ErrorInterface[] }>()
);
