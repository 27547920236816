import { ApiUpdateTemporaryPasswordRequest, UpdateTemporaryPasswordRequestInterface } from '../interfaces';

export function mapUpdateTemporaryPasswordRequest(
    request: UpdateTemporaryPasswordRequestInterface
): ApiUpdateTemporaryPasswordRequest {
    return {
        current_password: request.temporaryPassword.trim(),
        new_password: request.newPassword.trim(),
    };
}
