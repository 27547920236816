import { Action } from '@ngrx/store';
import { type } from '../../../core';
import { Coverage } from '../../interfaces';

export const CHECK_COVERAGE = type('[coverage] Check');
export const CHECK_COVERAGE_SUCCESS = type('[coverage] Check success');
export const CHECK_COVERAGE_ERROR = type('[coverage] Check error');

export const UPDATE_RECEIPT = type('[receipt] Update');
export const UPDATE_RECEIPT_SUCCESS = type('[receipt] Update success');

export class CheckCoverage implements Action {
    readonly type = CHECK_COVERAGE;

    constructor(public payload: { productId: string, validationStepCode?: string, coverage: string[] }) {}
}

export class UpdateReceipt implements Action {
    readonly type = UPDATE_RECEIPT;

    constructor(public payload: { productId: string, coverage: string[] }) {}
}

export class UpdateReceiptSuccess implements Action {
    readonly type = UPDATE_RECEIPT_SUCCESS;

    constructor(public payload: { productId: string, modules: string[], coverage: Coverage[] }) {}
}

export type CoverageActionUnion
    = CheckCoverage
    | UpdateReceipt
    | UpdateReceiptSuccess;
