import { windowProvider } from '../../../core/configuration/window.provider';
import { DatalayerCookieStorage, StorageService } from './datalayer-cookie-storage';

export const provideDatalayer = () => {
    return [
        /**
         *  Create local DatalayerCookieStorage provider for datalayer.
         *  We try to limit the dependencies for the datalayer to
         *  make it more generic, easy to implement and prevent circular dependencies.
         */
        { provide: StorageService, useClass: DatalayerCookieStorage },
        windowProvider
    ];
};
