import { ApiSubmitExhibitsRequestInterface, SubmitExhibitsRequestInterface } from '../interfaces';

export function mapSubmitExhibitsRequest(request: SubmitExhibitsRequestInterface): ApiSubmitExhibitsRequestInterface {
    return {
        case: {
            case_sequence_number: request.caseSequenceNumber,
        },
        upload_item: {
            upload_envelope_sequence_number: request.uploadEnvelopeSequenceNumber,
        },
    };
}
