import { createReducer, on } from '@ngrx/store';
import { getTestimonialsAction, getTestimonialsSuccessAction } from './testimonials.actions';
import { INITIAL_TESTIMONIALS_STATE, TestimonialsState } from './testimonials.state';

export const testimonialsReducers = createReducer(
    INITIAL_TESTIMONIALS_STATE,

    on(
        getTestimonialsAction,
        (state): TestimonialsState => ({
            ...state,
            testimonials: [...state.testimonials],
        })
    ),

    on(getTestimonialsSuccessAction, (state, response): TestimonialsState => {
        return {
            ...state,
            ...response,
        };
    })
);
