import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimObjectsEffects } from './store/claim-objects.effects';
import { claimObjectsReducer } from './store/claim-objects.reducers';
import { CLAIM_OBJECTS_FEATURE } from './store/claim-objects.state';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_OBJECTS_FEATURE, claimObjectsReducer),
        EffectsModule.forFeature([ClaimObjectsEffects]),
    ],
})
export class OutClaimObjectsModule {}
