import { mapEnumApiRequest, mapFromGenderCode, mapPhone, mapSequenceNumber } from '../../core/mappers';
import { ApiNaturalPartyOrLegalPartyCodeEnum, NaturalPartyOrLegalPartyCodeEnum } from '../enums';
import {
    ApiSaveCounterpartyRequestInterface,
    ApiSaveLegalPersonRequestInterface,
    ApiSaveNaturalPersonRequestInterface,
    LegalPersonInterface,
    NaturalPersonInterface,
    SaveCounterpartyRequestInterface,
} from '../interfaces';

function mapNaturalPersonRequest(request: NaturalPersonInterface): ApiSaveNaturalPersonRequestInterface {
    if (!request) {
        return null;
    }

    return {
        gender_code: mapFromGenderCode(request.genderCode),
        initials: request.initials,
        prefix_lastname: request.preNominalLetters,
        surname: request.surname,
        email_address: request.emailAddress,
        telephone_country_code: request.telephoneCountryCode,
        telephone_number: mapPhone(request.telephoneNumber),
        postal_code: request.postalCode,
        civic_number: request.civicNumber,
        civic_number_suffix: request.civicNumberSuffix,
        country_id: request.countryId,
        street_name: request.streetName,
        city: request.city,
    };
}

function mapLegalPersonRequest(request: LegalPersonInterface): ApiSaveLegalPersonRequestInterface {
    if (!request) {
        return null;
    }

    return {
        trading_name: request.tradingName,
        email_address: request.emailAddress,
        telephone_country_code: request.telephoneCountryCode,
        telephone_number: mapPhone(request.telephoneNumber),
        postal_code: request.postalCode,
        civic_number: request.civicNumber,
        civic_number_suffix: request.civicNumberSuffix,
        country_id: request.countryId,
        city: request.city,
        street_name: request.streetName,
    };
}

export function mapSaveCounterpartyRequest(
    request: SaveCounterpartyRequestInterface
): ApiSaveCounterpartyRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.claimSequenceNumber),
        },
        counterparty: {
            np_or_lp_code: mapEnumApiRequest(
                request.counterparty.npOrLpCode,
                ApiNaturalPartyOrLegalPartyCodeEnum,
                NaturalPartyOrLegalPartyCodeEnum
            ),
            natural_person:
                request.counterparty.npOrLpCode === NaturalPartyOrLegalPartyCodeEnum.NaturalParty
                    ? mapNaturalPersonRequest(request.counterparty.naturalPerson)
                    : null,
            legal_person:
                request.counterparty.npOrLpCode === NaturalPartyOrLegalPartyCodeEnum.LegalParty
                    ? mapLegalPersonRequest(request.counterparty.legalPerson)
                    : null,
        },
    };
}
