import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    AddCoverageRequestInterface,
    AddCoverageResponseInterface,
    AddPremiumDeterminingFactorRequestInterface,
    AddPremiumDeterminingFactorResponseInterface,
    AddRegularDriverRequestInterface,
    AddRegularDriverResponseInterface,
    AllCoveragesRequestInterface,
    AllCoveragesResponseInterface,
    AllProductDetailsRequestInterface,
    AllProductDetailsResponseInterface,
    CancelAddedCoverageRequestInterface,
    CancelAddedCoverageResponseInterface,
    CancelAddedPremiumDeterminingFactorRequestInterface,
    CancelAddedPremiumDeterminingFactorResponseInterface,
    CancelAddedProductRequestInterface,
    CancelAddedProductResponseInterface,
    CancelAddedRegularDriverRequestInterface,
    CancelAddedRegularDriverResponseInterface,
    CoverageRequestInterface,
    CoverageResponseInterface,
    EndDateProductRequestInterface,
    EndDateProductResponseInterface,
    EndProductRequestInterface,
    EndProductResponseInterface,
    EndRegularDriverRequestInterface,
    EndRegularDriverResponseInterface,
    PolicyResponseInterface,
    ProductDetailsRequestInterface,
    ProductDetailsResponseInterface,
    ProductRequestInterface,
    ProductResponseInterface,
    ReviveProductRequestInterface,
    ReviveProductResponseInterface,
    StartDateProductRequestInterface,
    StartDateProductResponseInterface,
} from '../interfaces';

export const product = createAction('[insurance policy] get product', props<{ request: ProductRequestInterface }>());
export const productSuccess = createAction(
    '[insurance policy] get product success',
    props<{ response: ProductResponseInterface }>()
);
export const productFailure = createAction(
    '[insurance policy] get product failure',
    props<{ errors: ErrorInterface[] }>()
);

export const policy = createAction('[insurance policy] get policy');
export const policySuccess = createAction(
    '[insurance policy] get policy success',
    props<{ response: PolicyResponseInterface }>()
);
export const policyFailure = createAction(
    '[insurance policy] get policy failure',
    props<{ errors: ErrorInterface[] }>()
);

export const productDetails = createAction(
    '[insurance policy] get policy details',
    props<{ request: ProductDetailsRequestInterface }>()
);
export const productDetailsSuccess = createAction(
    '[insurance policy] get policy details success',
    props<{ response: ProductDetailsResponseInterface }>()
);
export const productDetailsFailure = createAction(
    '[insurance policy] get policy details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const allProductDetails = createAction(
    '[insurance policy] get all policy details',
    props<{ request: AllProductDetailsRequestInterface }>()
);
export const allProductDetailsSuccess = createAction(
    '[insurance policy] get all policy details success',
    props<{ response: AllProductDetailsResponseInterface }>()
);
export const allProductDetailsFailure = createAction(
    '[insurance policy] get all policy details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const allCoverages = createAction(
    '[insurance policy] all coverage details',
    props<{ request: AllCoveragesRequestInterface }>()
);
export const allCoveragesSuccess = createAction(
    '[insurance policy] all coverage details success',
    props<{ response: AllCoveragesResponseInterface }>()
);
export const allCoveragesFailure = createAction(
    '[insurance policy] all coverage details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const coverage = createAction(
    '[insurance policy] coverage details',
    props<{ request: CoverageRequestInterface }>()
);
export const coverageSuccess = createAction(
    '[insurance policy] coverage details success',
    props<{ response: CoverageResponseInterface }>()
);
export const coverageFailure = createAction(
    '[insurance policy] coverage details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const reviveProduct = createAction(
    '[insurance policy] revive policy',
    props<{ request: ReviveProductRequestInterface }>()
);
export const reviveProductSuccess = createAction(
    '[insurance policy] revive policy success',
    props<{ response: ReviveProductResponseInterface }>()
);
export const reviveProductFailure = createAction(
    '[insurance policy] revive policy failure',
    props<{ errors: ErrorInterface[] }>()
);

export const startDateProduct = createAction(
    '[insurance policy] start date-policy',
    props<{ request: StartDateProductRequestInterface }>()
);
export const startDateProductSuccess = createAction(
    '[insurance policy] start date-policy success',
    props<{ response: StartDateProductResponseInterface }>()
);
export const startDateProductFailure = createAction(
    '[insurance policy] start date-policy failure',
    props<{ errors: ErrorInterface[] }>()
);

export const endProduct = createAction(
    '[insurance policy] end policy',
    props<{ request: EndProductRequestInterface }>()
);
export const endProductSuccess = createAction(
    '[insurance policy] end policy success',
    props<{ response: EndProductResponseInterface }>()
);
export const endProductFailure = createAction(
    '[insurance policy] end policy failure',
    props<{ errors: ErrorInterface[] }>()
);

export const endDateProduct = createAction(
    '[insurance policy] end date-policy',
    props<{ request: EndDateProductRequestInterface }>()
);
export const endDateProductSuccess = createAction(
    '[insurance policy] end date-policy success',
    props<{ response: EndDateProductResponseInterface }>()
);
export const endDateProductFailure = createAction(
    '[insurance policy] end date-policy failure',
    props<{ errors: ErrorInterface[] }>()
);

export const cancelAddedProduct = createAction(
    '[insurance policy] cancel add-policy',
    props<{ request: CancelAddedProductRequestInterface }>()
);
export const cancelAddedProductSuccess = createAction(
    '[insurance policy] cancel add-policy success',
    props<{ response: CancelAddedProductResponseInterface }>()
);
export const cancelAddedProductFailure = createAction(
    '[insurance policy] cancel add-policy failure',
    props<{ errors: ErrorInterface[] }>()
);

export const addPremiumDeterminingFactor = createAction(
    '[insurance policy] add factor',
    props<{ request: AddPremiumDeterminingFactorRequestInterface }>()
);
export const addPremiumDeterminingFactorSuccess = createAction(
    '[insurance policy] add factor success',
    props<{ response: AddPremiumDeterminingFactorResponseInterface }>()
);
export const addPremiumDeterminingFactorFailure = createAction(
    '[insurance policy] add factor failure',
    props<{ errors: ErrorInterface[] }>()
);

export const cancelAddedPremiumDeterminingFactor = createAction(
    '[insurance policy] cancel add-premium-determining-factor',
    props<{ request: CancelAddedPremiumDeterminingFactorRequestInterface }>()
);
export const cancelAddedPremiumDeterminingFactorSuccess = createAction(
    '[insurance policy] cancel add-premium-determining-factor success',
    props<{ response: CancelAddedPremiumDeterminingFactorResponseInterface }>()
);
export const cancelAddedPremiumDeterminingFactorFailure = createAction(
    '[insurance policy] cancel add-premium-determining-factor failure',
    props<{ errors: ErrorInterface[] }>()
);

export const addRegularDriver = createAction(
    '[insurance policy] add driver',
    props<{ request: AddRegularDriverRequestInterface }>()
);
export const addRegularDriverSuccess = createAction(
    '[insurance policy] add driver success',
    props<{ response: AddRegularDriverResponseInterface }>()
);
export const addRegularDriverFailure = createAction(
    '[insurance policy] add driver failure',
    props<{ errors: ErrorInterface[] }>()
);

export const endRegularDriver = createAction(
    '[insurance policy] end driver',
    props<{ request: EndRegularDriverRequestInterface }>()
);
export const endRegularDriverSuccess = createAction(
    '[insurance policy] end driver success',
    props<{ response: EndRegularDriverResponseInterface }>()
);
export const endRegularDriverFailure = createAction(
    '[insurance policy] end driver failure',
    props<{ errors: ErrorInterface[] }>()
);

export const cancelAddedRegularDriver = createAction(
    '[insurance policy] cancel add-regular-driver',
    props<{ request: CancelAddedRegularDriverRequestInterface }>()
);
export const cancelAddedRegularDriverSuccess = createAction(
    '[insurance policy] cancel add-regular-driver success',
    props<{ response: CancelAddedRegularDriverResponseInterface }>()
);
export const cancelAddedRegularDriverFailure = createAction(
    '[insurance policy] cancel add-regular-driver failure',
    props<{ errors: ErrorInterface[] }>()
);

export const addCoverage = createAction(
    '[insurance policy] add coverage',
    props<{ request: AddCoverageRequestInterface }>()
);
export const addCoverageSuccess = createAction(
    '[insurance policy] add coverage success',
    props<{ response: AddCoverageResponseInterface }>()
);
export const addCoverageFailure = createAction(
    '[insurance policy] add coverage failure',
    props<{ errors: ErrorInterface[] }>()
);

export const cancelAddedCoverage = createAction(
    '[insurance policy] cancel add-coverage',
    props<{ request: CancelAddedCoverageRequestInterface }>()
);
export const cancelAddedCoverageSuccess = createAction(
    '[insurance policy] cancel add-coverage success',
    props<{ response: CancelAddedCoverageResponseInterface }>()
);
export const cancelAddedCoverageFailure = createAction(
    '[insurance policy] cancel add-coverage failure',
    props<{ errors: ErrorInterface[] }>()
);
