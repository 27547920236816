export function mapEnumApiResponse<ReturnType>(
    response: unknown,
    apiEnum: unknown,
    responseEnum: unknown
): ReturnType | null {
    const enumKey = Object.keys(apiEnum).find((key) => response === apiEnum[key]);
    return enumKey ? responseEnum[enumKey] : response;
}

export function mapEnumApiRequest<ReturnType>(
    request: unknown,
    apiEnum: unknown,
    requestEnum: unknown
): ReturnType | null {
    const enumKey = Object.keys(requestEnum).find((key) => request === requestEnum[key]);
    return enumKey ? apiEnum[enumKey] : request;
}
