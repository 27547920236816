import { ViewportScroller } from '@angular/common';
import { ActivationEnd, ChildActivationEnd, Router, Scroll } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @deprecated Should be moved to app or utility library
 * Added istanbul ignore next to this function to exclude it from coverage calculations
 *
 * preserves the scroll position from before navigating. This overrules the 'scrollPositionRestoration: 'enabled''
 * but is only applied as long as the subscription is alive.
 *
 * !! do not forget to unsubscribe !!
 *
 * @param router - angular router
 * @param viewportScroller - a scroll position manager.
 */
export function preserveScrollPositionOnNavigation(router: Router, viewportScroller: ViewportScroller, window: Window): Subscription {
    const position: [number, number] = [0, 0];
    return router.events
        .pipe(
            filter((e: any): e is Scroll | ChildActivationEnd | ActivationEnd => {
                return e instanceof Scroll || e instanceof ChildActivationEnd || e instanceof ActivationEnd;
            })
        )
        .subscribe((e: Scroll | ChildActivationEnd | ActivationEnd) => {
            // The subscription gets fired on multiple events,
            // therefor it is necessary to only change the x and y if it is not 0
            if (!(e instanceof Scroll)) {
                if (window.scrollX !== 0) {
                    position[0] = window.scrollX;
                }
                if (window.scrollY !== 0) {
                    position[1] = window.scrollY;
                }
            }
            if (e instanceof Scroll) {
                if (e.position) {
                    // backward navigation takes the previous known position
                    viewportScroller.scrollToPosition(e.position);
                } else if (e.anchor) {
                    // anchor navigation navigates to anchor
                    viewportScroller.scrollToAnchor(e.anchor);
                } else {
                    // forward navigation keeps position it had
                    viewportScroller.scrollToPosition(position);
                }
            }
        });
}
