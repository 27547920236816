import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COUNTRIES_FEATURE, CountriesState } from './countries.state';

export class CountriesSelectors {
    public static getState = createFeatureSelector<CountriesState>(COUNTRIES_FEATURE);
    public static getCountries = createSelector(
        CountriesSelectors.getState,
        (state: CountriesState) => state.countries
    );
}
