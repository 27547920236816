/* eslint-disable @typescript-eslint/naming-convention */
import { createSelector } from '@ngrx/store';
import { CoveragePremiumInterface, CoveragePremiumProductInterface } from '../../interfaces';
import * as fromProduct from '../product/product.actions';
import * as fromCoveragePremium from './coverage-premium.actions';

/** @deprecated use InsurancePolicyState */
export interface State {
    ids: number[];
    entities: { [id: string]: CoveragePremiumInterface };
    selectedId: number;
    loading: boolean;
    loaded: boolean;
}

/** @deprecated use InsurancePolicyState */
export const initialState: State = {
    ids: [],
    entities: {},
    selectedId: null,
    loading: false,
    loaded: false,
};

/** @deprecated use insurancePolicyReducer */
export function reducer(
    state: State = initialState,
    action: fromCoveragePremium.CoveragePremiumActions | fromProduct.ProductsActions
): State {
    switch (action.type) {
        case fromCoveragePremium.SELECT: {
            return {
                ...state,
                selectedId: action.payload.purchased_product_sequence_number,
            };
        }

        case fromCoveragePremium.LOAD: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }

        case fromCoveragePremium.LOAD_SUCCESS: {
            const { coverages, purchased_product_sequence_number } = action.payload;

            if (!coverages) {
                return {
                    ...state,
                    loading: false,
                };
            }

            const entity: CoveragePremiumInterface = Object.assign({}, coverages, {
                purchased_product_sequence_number,
            });

            return {
                ...state,
                ids: [...new Set([...state.ids, purchased_product_sequence_number])],
                entities: {
                    ...state.entities,
                    [purchased_product_sequence_number]: entity,
                },
                loading: false,
                loaded: true,
            };
        }

        case fromCoveragePremium.CHANGE_GENERIC_PURCHASED_COVERAGES_SUCCESS: {
            const { purchased_product_sequence_number, start_date, purchased_coverages } = action.payload;
            const coverage = state.entities[purchased_product_sequence_number];
            const baseCoverages = purchased_coverages.filter(
                (purchasedCoverage) => purchasedCoverage.base_coverage_indication === 'J'
            );
            const additionalCoverage = purchased_coverages.filter(
                (purchasedCoverage) => purchasedCoverage.base_coverage_indication !== 'J'
            );

            const invoicePeriods = coverage?.product?.invoice_periods?.map((invoicePeriod) =>
                invoicePeriod?.invoice_period_months_number === coverage?.product.purchased_invoice_period_months_number
                    ? {
                          ...invoicePeriod,
                          base_coverages: baseCoverages,
                          additional_coverage: additionalCoverage,
                      }
                    : invoicePeriod
            );

            const product: CoveragePremiumProductInterface = {
                ...coverage.product,
                invoice_periods: invoicePeriods,
                base_coverages: baseCoverages,
                additional_coverage: additionalCoverage,
                coverages_adjustment_present_indication: 'J',
                coverages_adjustment_start_date: start_date,
            };

            return Object.assign({}, state, {
                entities: Object.assign({}, state.entities, {
                    [purchased_product_sequence_number]: Object.assign({}, coverage, { product }),
                }),
            });
        }

        case fromCoveragePremium.CANCEL_FUTURE_PURCHASED_COVERAGE_SUCCESS: {
            const { purchased_product_sequence_number, purchased_coverages } = action.payload;
            const coverage = state.entities[purchased_product_sequence_number];
            const baseCoverages = purchased_coverages.filter(
                (purchasedCoverage) => purchasedCoverage.base_coverage_indication === 'J'
            );
            const additionalCoverage = purchased_coverages.filter(
                (purchasedCoverage) => purchasedCoverage.base_coverage_indication !== 'J'
            );
            const product: CoveragePremiumProductInterface = {
                ...coverage.product,
                base_coverages: baseCoverages,
                additional_coverage: additionalCoverage,
                coverages_adjustment_present_indication: 'N',
                coverages_adjustment_start_date: null,
            };

            return Object.assign({}, state, {
                entities: Object.assign({}, state.entities, {
                    [purchased_product_sequence_number]: Object.assign({}, coverage, { product }),
                }),
            });
        }

        case fromCoveragePremium.CLEAR: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State): State['entities'] => state.entities;
export const getIds = (state: State): State['ids'] => state.ids;
export const getSelectedId = (state: State): State['selectedId'] => state.selectedId;
export const getSelected = createSelector(getEntities, getSelectedId, (entities, selectedId) => entities[selectedId]);
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map((id) => entities[id]));
