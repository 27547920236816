import { createFeatureSelector, createSelector, DefaultProjectorFn } from '@ngrx/store';
import { ExhibitInterface } from '../interfaces';
import { ClaimExhibitsState, CLAIM_EXHIBITS_FEATURE } from './claim-exhibits.state';

export class ClaimExhibitsSelectors {
    public static getExhibitsState = createFeatureSelector<ClaimExhibitsState>(CLAIM_EXHIBITS_FEATURE);
    public static getState = createSelector(
        ClaimExhibitsSelectors.getExhibitsState,
        (state: ClaimExhibitsState) => state
    );

    public static getExhibits = createSelector(
        ClaimExhibitsSelectors.getExhibitsState,
        (state: ClaimExhibitsState) => state.exhibits
    );

    public static getButtons = createSelector(
        ClaimExhibitsSelectors.getExhibitsState,
        (state: ClaimExhibitsState) => state.buttons
    );

    public static getSubmit = createSelector(
        ClaimExhibitsSelectors.getExhibitsState,
        (state: ClaimExhibitsState) => state.submit
    );

    public static getExhibit = (documentId: string): DefaultProjectorFn<ExhibitInterface> =>
        createSelector(ClaimExhibitsSelectors.getExhibitsState, (state: ClaimExhibitsState) => {
            return state.exhibits.find((exhibit) => exhibit.documentId === documentId);
        });
}
