import { NotificationInterface } from '../../../core';
import { StackDiscountDescription } from '../../interfaces';
import * as stackDiscountActions from './stack-discount.actions';

export interface State {
    stack_discount: StackDiscountDescription;
    errors: NotificationInterface[];
}

const initialState: State = {
    stack_discount: null,
    errors: [],
};

export function reducer(state: State = initialState, action: stackDiscountActions.StackDiscountActionUnion): State {
    switch (action.type) {
    case stackDiscountActions.SAVE_STACK_DISCOUNT: {
        return {
            ...state,
            stack_discount: action.payload.stack_discount,
        };
    }

    default: {
        return {
            ...state,
        };
    }
    }
}

export const getStackDiscount = (state: State): StackDiscountDescription => state.stack_discount;
export const getErrors = (state: State): State['errors'] => state.errors;
