import { createReducer, on } from '@ngrx/store';
import * as ClaimsCategoriesActions from './claim-categories.actions';
import { ClaimCategoriesState, INITIAL_CLAIM_CATEGORIES_STATE } from './claim-categories.state';

export const claimCategoriesReducer = createReducer(
    INITIAL_CLAIM_CATEGORIES_STATE,
    on(
        ClaimsCategoriesActions.allClaimCategoriesSuccess,
        (state, { response }): ClaimCategoriesState => ({
            ...state,
            claimCategories: response.allClaimCategories$.claimCategories,
            purchasedProducts: response.policy$.purchasedProducts,
        })
    ),
    on(ClaimsCategoriesActions.resetClaimCategories, (): ClaimCategoriesState => INITIAL_CLAIM_CATEGORIES_STATE)
);
