import { Action } from '@ngrx/store';
import { type } from '../../../core';
import { ScenarioInterface } from '../../interfaces';

export const LOAD = type('[scenarios selector service] Load');
export const LOAD_SUCCESS = type('[scenarios selector service] Load success');
export const LOAD_ERROR = type('[scenarios selector service] Load error');
export const CLEAR = type('[scenarios selector service] Clear');

export class Load implements Action {
    public readonly type = LOAD;
}

export class LoadSuccess implements Action {
    public readonly type = LOAD_SUCCESS;

    constructor(public payload: { scenarios: ScenarioInterface[] }) {}
}

export class LoadError implements Action {
    public readonly type = LOAD_ERROR;
}

export class Clear implements Action {
    public readonly type = CLEAR;
}

export type Actions = Load | LoadSuccess | LoadError | Clear;
