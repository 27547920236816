import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimAppointmentState, CLAIM_APPOINTMENT_FEATURE } from './claim-appointment.state';

export class ClaimAppointmentSelectors {
    public static getClaimAppointmentState = createFeatureSelector<ClaimAppointmentState>(CLAIM_APPOINTMENT_FEATURE);

    public static getState = createSelector(
        ClaimAppointmentSelectors.getClaimAppointmentState,
        (state: ClaimAppointmentState) => state
    );

    public static getAppointments = createSelector(
        ClaimAppointmentSelectors.getState,
        (state: ClaimAppointmentState) => state.appointments
    );

    public static getLocations = createSelector(
        ClaimAppointmentSelectors.getState,
        (state: ClaimAppointmentState) => state.locations
    );

    public static getTimeslots = createSelector(
        ClaimAppointmentSelectors.getState,
        (state: ClaimAppointmentState) => state.timeslots
    );
}
