import { mapSuccessIndication } from '../../core/mappers';
import { ApiSaveClaimNotificationPayloadInterface, SaveClaimNotificationPayloadInterface } from '../interfaces';

export function saveClaimNotificationPayloadMapper(
    payload: SaveClaimNotificationPayloadInterface
): ApiSaveClaimNotificationPayloadInterface {
    return {
        notificatie: {
            case_sequence_number: payload.caseSequenceNumber,
            email_notificatie_indicatie: mapSuccessIndication(payload.emailNotificationIndication),
            sms_notificatie_indicatie: mapSuccessIndication(payload.smsNotificationIndication),
        },
    };
}
