import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    GetClaimObjectDetailsRequestInterface,
    GetClaimObjectsRequestInterface,
    ReloadClaimObjectDetailsRequestInterface,
    RemoveClaimObjectRequestInterface,
    RemoveClaimObjectResponseInterface,
    SaveClaimObjectRequestInterface,
    SaveClaimObjectResponseInterface,
    GetClaimObjectDetailsResponseInterface,
    GetClaimObjectsResponseInterface,
    ReloadClaimObjectDetailsResponseInterface,
    UpdateClaimObjectDetailsRequestInterface,
    UpdateClaimObjectDetailsResponseInterface,
} from '../interfaces';

export const getClaimObjects = createAction(
    '[claim objects] get claim objects',
    props<{ request: GetClaimObjectsRequestInterface }>()
);

export const getClaimObjectsSuccess = createAction(
    '[claim objects] get claim objects success',
    props<{ response: GetClaimObjectsResponseInterface }>()
);

export const getClaimObjectsFailure = createAction(
    '[claim objects] get claim objects failure',
    props<{ errors: ErrorInterface[] }>()
);

export const getClaimObjectDetails = createAction(
    '[claim objects] get claim object details',
    props<{ request: GetClaimObjectDetailsRequestInterface }>()
);

export const getClaimObjectDetailsSuccess = createAction(
    '[claim objects] get claim object details success',
    props<{ response: GetClaimObjectDetailsResponseInterface }>()
);

export const getClaimObjectDetailsFailure = createAction(
    '[claim objects] get claim object details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const updateClaimObjectDetails = createAction(
    '[claim objects] update claim object details',
    props<{ request: UpdateClaimObjectDetailsRequestInterface }>()
);

export const updateClaimObjectDetailsSuccess = createAction(
    '[claim objects] update claim object details success',
    props<{ response: UpdateClaimObjectDetailsResponseInterface }>()
);

export const updateClaimObjectDetailsFailure = createAction(
    '[claim objects] update claim object details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const reloadClaimObjectDetails = createAction(
    '[claim objects] reload claim object details',
    props<{ request: ReloadClaimObjectDetailsRequestInterface }>()
);

export const reloadClaimObjectDetailsSuccess = createAction(
    '[claim objects] reload claim object details success',
    props<{ response: ReloadClaimObjectDetailsResponseInterface }>()
);

export const reloadClaimObjectDetailsFailure = createAction(
    '[claim objects] reload claim object details failure',
    props<{ errors: ErrorInterface[] }>()
);

export const saveClaimObject = createAction(
    '[claim objects] save claim object',
    props<{ request: SaveClaimObjectRequestInterface }>()
);

export const saveClaimObjectSuccess = createAction(
    '[claim objects] save claim object success',
    props<{ response: SaveClaimObjectResponseInterface }>()
);

export const saveClaimObjectFailure = createAction(
    '[claim objects] save claim object failure',
    props<{ errors: ErrorInterface[] }>()
);

export const removeClaimObject = createAction(
    '[claim objects] remove claim object',
    props<{ request: RemoveClaimObjectRequestInterface }>()
);

export const removeClaimObjectSuccess = createAction(
    '[claim objects] remove claim object success',
    props<{ response: RemoveClaimObjectResponseInterface }>()
);

export const removeClaimObjectFailure = createAction(
    '[claim objects] remove claim object failure',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimObjects = createAction('[claim objects] reset claim objects');
