import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ExhibitInterface, ExhibitsButtonInterface, ExhibitsSubmitInterface } from '../interfaces';
import { ClaimExhibitsSelectors } from '../store/claim-exhibits.selectors';
import { ClaimExhibitsState } from '../store/claim-exhibits.state';

@Injectable({
    providedIn: 'root',
})
export class ClaimExhibitsDataService {
    constructor(private store$: Store<ClaimExhibitsState>) {}

    public getState$(): Observable<ClaimExhibitsState> {
        return this.store$.select(ClaimExhibitsSelectors.getState);
    }

    public getExhibits$(): Observable<ExhibitInterface[]> {
        return this.store$.select(ClaimExhibitsSelectors.getExhibits);
    }

    public getButtons$(): Observable<ExhibitsButtonInterface[]> {
        return this.store$.select(ClaimExhibitsSelectors.getButtons);
    }

    public getSubmit$(): Observable<ExhibitsSubmitInterface> {
        return this.store$.select(ClaimExhibitsSelectors.getSubmit);
    }

    public getExhibit$(documentId: string): Observable<ExhibitInterface> {
        return this.store$.select(ClaimExhibitsSelectors.getExhibit(documentId));
    }
}
