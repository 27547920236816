import { createReducer, on } from '@ngrx/store';
import { mergeSpecificationUtility } from '../utilities';
import * as ClaimSpecificationActions from './claim-specification.actions';
import { ClaimSpecificationState, INITIAL_CLAIM_SPECIFICATION_STATE } from './claim-specification.state';

export const claimSpecificationReducer = createReducer(
    INITIAL_CLAIM_SPECIFICATION_STATE,
    on(
        ClaimSpecificationActions.getSpecificationSuccess,
        (state, { response }): ClaimSpecificationState => ({
            ...state,
            specification: response.specification,
        })
    ),
    on(
        ClaimSpecificationActions.resumeSpecificationSuccess,
        (state, { response }): ClaimSpecificationState => ({
            ...state,
            specification: response.specification,
        })
    ),
    on(
        ClaimSpecificationActions.updateSpecificationSuccess,
        (state, { response }): ClaimSpecificationState => ({
            ...state,
            specification: mergeSpecificationUtility(response.specification, state.specification),
        })
    ),
    on(
        ClaimSpecificationActions.removeSpecificationSuccess,
        (state): ClaimSpecificationState => ({
            ...state,
            specification: null,
        })
    ),
    on(
        ClaimSpecificationActions.resetClaimSpecification,
        (): ClaimSpecificationState => INITIAL_CLAIM_SPECIFICATION_STATE
    )
);
