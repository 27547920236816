import { Action } from '@ngrx/store';
import { NotificationInterface, type } from '../../../core';
import { MotorVehiclesToReplace, PurchasedProductReplacement } from '../../interfaces';
import { UserChoice } from './vehicle-replace.enums';

export const VALIDATE = type('[Autoverzekering Detail] Validate Vehicle Replace');
export const VALIDATE_TRUE = type('[Vehicle Replace API] Validate True');
export const VALIDATE_FALSE = type('[Vehicle Replace API] Validate False');
export const LOAD_ERROR = type('[Vehicle Replace API] Load Error');
export const SET_USER_CHOICE = type('[Autoverzekering Detail] Set User Choice');
export const CHOOSE_REPLACE_YES = type('[Replacement Modal] Choose Replace Yes');
export const CHOOSE_REPLACE_NO = type('[Replacement Modal] Choose Replace No');
export const RESET_VEHICLE_REPLACEMENT = type('[Vehicle Replacement] Reset');

export class Validate implements Action {
    public readonly type = VALIDATE;

    constructor(public payload: { licensePlate: string; noClaimsYearsAmount: string | number }) {}
}

export class ValidateTrue implements Action {
    public readonly type = VALIDATE_TRUE;

    constructor(public payload: { response: MotorVehiclesToReplace }) {}
}

export class ValidateFalse implements Action {
    public readonly type = VALIDATE_FALSE;
}

export class LoadError implements Action {
    public readonly type = LOAD_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class SetUserChoice implements Action {
    public readonly type = SET_USER_CHOICE;

    constructor(public payload: { choice: UserChoice; replacement?: PurchasedProductReplacement }) {}
}

export class ChooseReplaceYes implements Action {
    public readonly type = CHOOSE_REPLACE_YES;

    constructor(public payload: { replacement: PurchasedProductReplacement }) {}
}

export class ChooseReplaceNo implements Action {
    public readonly type = CHOOSE_REPLACE_NO;

    constructor(public payload?: any) {}
}

export class ResetVehicleReplacement implements Action {
    public readonly type = RESET_VEHICLE_REPLACEMENT;
}

export type VehicleReplaceActionUnion =
    | Validate
    | ValidateTrue
    | ValidateFalse
    | LoadError
    | SetUserChoice
    | ChooseReplaceYes
    | ChooseReplaceNo
    | ResetVehicleReplacement;
