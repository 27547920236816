import { Action } from '@ngrx/store';
import { type } from '../../../core';
import {
    NonRegularDriverPropsInterface,
    ProductBaseInterface,
    TerminateActivePurchasedProductRequestInterface,
    TerminateActivePurchasedProductResponseInterface,
} from '../../interfaces';
import { CreateNonRegularDriverRequestInterface } from "../../interfaces/product/create-non-regular-driver.request.interface";

/** @deprecated replaced by insurance-policy.actions.ts */
export const SELECT = type('[product selector service] select');
/** @deprecated replaced by insurance-policy.actions.ts */
export const LOAD_ERROR = type('[product selector service] load error');
/** @deprecated replaced by insurance-policy.actions.ts */
export const CLEAR = type('[product selector service] clear product store');
/** @deprecated replaced by insurance-policy.actions.ts */
export const LOAD_POLICY = type('[product selector service] Load policy');
/** @deprecated replaced by insurance-policy.actions.ts */
export const LOAD_POLICY_SUCCESS = type('[product selector service] Load policy Success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const LOAD_PURCHASED_PRODUCT = type('[product selector service] Load Product Detail');
/** @deprecated replaced by insurance-policy.actions.ts */
export const LOAD_PURCHASED_PRODUCT_SUCCESS = type('[product selector service] Load Product Detail Success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const CANCEL_FUTURE_PURCHASED_PRODUCT = type('[product selector service] cancel future purchased product');
/** @deprecated replaced by insurance-policy.actions.ts */
export const CANCEL_FUTURE_PURCHASED_PRODUCT_SUCCESS = type(
    '[product selector service] cancel future purchased product success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const TERMINATE_ACTIVE_PURCHASED_PRODUCT = type('[product selector service] terminate active purchased product');
/** @deprecated replaced by insurance-policy.actions.ts */
export const TERMINATE_ACTIVE_PURCHASED_PRODUCT_SUCCESS = type(
    '[product selector service] terminate active purchased product success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const MODIFY_FUTURE_PURCHASED_PRODUCT_START_DATE = type(
    '[product selector service] modify future purchased product start date'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const MODIFY_FUTURE_PURCHASED_PRODUCT_START_DATE_SUCCESS = type(
    '[product selector service] modify future purchased product start date success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const MODIFY_TERMINATION_DATE_FOR_PRODUCT_END_DATE = type(
    '[product selector service] modify new termination date for future purchased product end date'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const MODIFY_TERMINATION_DATE_FOR_PRODUCT_END_DATE_SUCCESS = type(
    '[product selector service] modify new termination date for future purchased product end date success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const REVIVE_TERMINATED_PURCHASED_PRODUCT = type(
    '[product selector service] revive terminated purchased product'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const REVIVE_TERMINATED_PURCHASED_PRODUCT_SUCCESS = type(
    '[product selector service] revive terminated purchased product success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const CREATE_NON_REGULAR_DRIVER = type('[product selector service] create non regular driver');
/** @deprecated replaced by insurance-policy.actions.ts */
export const CREATE_NON_REGULAR_DRIVER_SUCCESS = type('[product selector service] create non regular driver success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const STORE_NON_REGULAR_DRIVER = type('[product selector service] store non regular driver');
/** @deprecated replaced by insurance-policy.actions.ts */
export const STORE_NON_REGULAR_DRIVER_SUCCESS = type('[product selector service] store non regular driver success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const TERMINATE_ACTIVE_NON_REGULAR_DRIVER = type('[product selector service] remove driver');
/** @deprecated replaced by insurance-policy.actions.ts */
export const TERMINATE_ACTIVE_NON_REGULAR_DRIVER_SUCCESS = type('[product selector service] remove driver success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const CANCEL_FUTURE_NON_REGULAR_DRIVER = type('[product selector service] cancel driver');
/** @deprecated replaced by insurance-policy.actions.ts */
export const CANCEL_FUTURE_NON_REGULAR_DRIVER_SUCCESS = type('[product selector service] cancel driver success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const REQUEST_NON_REGULAR_DRIVER = type('[product selector service] request non regular driver');
/** @deprecated replaced by insurance-policy.actions.ts */
export const REQUEST_NON_REGULAR_DRIVER_SUCCESS = type('[product selector service] request non regular driver success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const UPDATE_PREMIUM_DETERMINING_FACTOR_SUCCESS = type(
    '[product selector service] update premium determining factor success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const CANCEL_FUTURE_MODIFIABLE_PREMIUM_DETERMINING_FACTOR = type(
    '[product selector service] cancel future modifiable premium determining factor'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const CANCEL_FUTURE_MODIFIABLE_PREMIUM_DETERMINING_FACTOR_SUCCESS = type(
    '[product selector service] cancel future modifiable premium determining factor success'
);
/** @deprecated replaced by insurance-policy.actions.ts */
export const DOWNLOAD_POLICY = type('[product selector service] download policy');
/** @deprecated replaced by insurance-policy.actions.ts */
export const DOWNLOAD_POLICY_SUCCESS = type('[product selector service] download policy success');
/** @deprecated replaced by insurance-policy.actions.ts */
export const DOWNLOAD_POLICY_ERROR = type('[product selector service] download policy error');
/** @deprecated replaced by insurance-policy.actions.ts */
export class Select implements Action {
    public readonly type = SELECT;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class LoadError implements Action {
    public readonly type = LOAD_ERROR;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class Clear implements Action {
    public readonly type = CLEAR;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class LoadPolicy implements Action {
    public readonly type = LOAD_POLICY;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class LoadPolicySuccess implements Action {
    public readonly type = LOAD_POLICY_SUCCESS;

    constructor(public payload: ProductBaseInterface[]) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class LoadPurchasedProduct implements Action {
    public readonly type = LOAD_PURCHASED_PRODUCT;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class LoadPurchasedProductSuccess implements Action {
    public readonly type = LOAD_PURCHASED_PRODUCT_SUCCESS;

    constructor(public payload: ProductBaseInterface) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CancelFuturePurchasedProduct implements Action {
    public readonly type = CANCEL_FUTURE_PURCHASED_PRODUCT;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CancelFuturePurchasedProductSuccess implements Action {
    public readonly type = CANCEL_FUTURE_PURCHASED_PRODUCT_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class TerminateActivePurchasedProduct implements Action {
    public readonly type = TERMINATE_ACTIVE_PURCHASED_PRODUCT;

    constructor(public payload: TerminateActivePurchasedProductRequestInterface) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class TerminateActivePurchasedProductSuccess implements Action {
    public readonly type = TERMINATE_ACTIVE_PURCHASED_PRODUCT_SUCCESS;

    constructor(
        public payload: {
            response: TerminateActivePurchasedProductResponseInterface;
            endDate: string;
            purchasedProductSequenceNumber: number;
        }
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class ReviveTerminatedPurchasedProduct implements Action {
    public readonly type = REVIVE_TERMINATED_PURCHASED_PRODUCT;

    constructor(public payload: { purchasedProductSequenceNumber: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class ReviveTerminatedPurchasedProductSuccess implements Action {
    public readonly type = REVIVE_TERMINATED_PURCHASED_PRODUCT_SUCCESS;

    constructor(public payload: { result: string; purchasedProductSequenceNumber: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class ModifyFuturePurchasedProductStartDate implements Action {
    public readonly type = MODIFY_FUTURE_PURCHASED_PRODUCT_START_DATE;

    constructor(public payload: { purchased_product_sequence_number: number; start_date: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class ModifyFuturePurchasedProductStartDateSuccess implements Action {
    public readonly type = MODIFY_FUTURE_PURCHASED_PRODUCT_START_DATE_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number; start_date: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class ModifyTerminationDateForProductEndDate implements Action {
    public readonly type = MODIFY_TERMINATION_DATE_FOR_PRODUCT_END_DATE;

    constructor(public payload: { purchased_product_sequence_number: number; end_date: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class ModifyTerminationDateForProductEndDateSuccess implements Action {
    public readonly type = MODIFY_TERMINATION_DATE_FOR_PRODUCT_END_DATE_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number; end_date: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class RequestNonRegularDriver implements Action {
    public readonly type = REQUEST_NON_REGULAR_DRIVER;

    constructor(
        public payload: {
            purchasedProductSequenceNumber: number;
        }
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class RequestNonRegularDriverSuccess implements Action {
    public readonly type = REQUEST_NON_REGULAR_DRIVER_SUCCESS;

    constructor(public payload: any) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CreateNonRegularDriver implements Action {
    public readonly type = CREATE_NON_REGULAR_DRIVER;

    constructor(
        public payload: CreateNonRegularDriverRequestInterface
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CreateNonRegularDriverSuccess implements Action {
    public readonly type = CREATE_NON_REGULAR_DRIVER_SUCCESS;

    constructor(
        public payload: CreateNonRegularDriverRequestInterface
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class StoreNonRegularDriver implements Action {
    public readonly type = STORE_NON_REGULAR_DRIVER;

    constructor(
        public payload: {
            purchased_product_sequence_number: number;
            non_regular_driver: NonRegularDriverPropsInterface;
        }
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class StoreNonRegularDriverSuccess implements Action {
    public readonly type = STORE_NON_REGULAR_DRIVER_SUCCESS;

    constructor(
        public payload: {
            purchased_product_sequence_number: number;
            non_regular_driver: NonRegularDriverPropsInterface;
        }
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class TerminateActiveNonRegularDriver implements Action {
    public readonly type = TERMINATE_ACTIVE_NON_REGULAR_DRIVER;

    constructor(public payload: { purchased_product_sequence_number: number; end_date: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class TerminateActiveNonRegularDriverSuccess implements Action {
    public readonly type = TERMINATE_ACTIVE_NON_REGULAR_DRIVER_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number; end_date: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CancelFutureNonRegularDriver implements Action {
    public readonly type = CANCEL_FUTURE_NON_REGULAR_DRIVER;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CancelFutureNonRegularDriverSuccess implements Action {
    public readonly type = CANCEL_FUTURE_NON_REGULAR_DRIVER_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class UpdatePremiumDeterminingFactorSuccess implements Action {
    public readonly type = UPDATE_PREMIUM_DETERMINING_FACTOR_SUCCESS;

    constructor(
        public payload: {
            purchased_product_sequence_number: number;
            premium_determining_factor_id: string;
            premium_determining_factor_value_description: string;
            start_date: string;
        }
    ) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CancelFutureModifiablePremiumDeterminingFactor implements Action {
    public readonly type = CANCEL_FUTURE_MODIFIABLE_PREMIUM_DETERMINING_FACTOR;

    constructor(public payload: { purchased_product_sequence_number: number; premium_determining_factor_id: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class CancelFutureModifiablePremiumDeterminingFactorSuccess implements Action {
    public readonly type = CANCEL_FUTURE_MODIFIABLE_PREMIUM_DETERMINING_FACTOR_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number; premium_determining_factor_id: string }) {}
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class DownloadPolicy implements Action {
    public readonly type = DOWNLOAD_POLICY;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class DownloadPolicySuccess implements Action {
    public readonly type = DOWNLOAD_POLICY_SUCCESS;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export class DownloadPolicyError implements Action {
    public readonly type = DOWNLOAD_POLICY_ERROR;
}
/** @deprecated replaced by insurance-policy.actions.ts */
export type ProductsActions =
    | Select
    | LoadError
    | Clear
    | LoadPolicy
    | LoadPolicySuccess
    | LoadPurchasedProduct
    | LoadPurchasedProductSuccess
    | CancelFuturePurchasedProduct
    | CancelFuturePurchasedProductSuccess
    | TerminateActivePurchasedProduct
    | TerminateActivePurchasedProductSuccess
    | ReviveTerminatedPurchasedProduct
    | ReviveTerminatedPurchasedProductSuccess
    | ModifyFuturePurchasedProductStartDate
    | ModifyFuturePurchasedProductStartDateSuccess
    | ModifyTerminationDateForProductEndDate
    | ModifyTerminationDateForProductEndDateSuccess
    | CreateNonRegularDriver
    | CreateNonRegularDriverSuccess
    | TerminateActiveNonRegularDriver
    | TerminateActiveNonRegularDriverSuccess
    | CancelFutureNonRegularDriver
    | CancelFutureNonRegularDriverSuccess
    | UpdatePremiumDeterminingFactorSuccess
    | CancelFutureModifiablePremiumDeterminingFactor
    | CancelFutureModifiablePremiumDeterminingFactorSuccess
    | DownloadPolicy
    | DownloadPolicySuccess
    | DownloadPolicyError
    | RequestNonRegularDriver
    | RequestNonRegularDriverSuccess
    | StoreNonRegularDriver
    | StoreNonRegularDriverSuccess;
