import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { ClaimStepResponseInterface, ClaimStepsRequestInterface } from '../interfaces';

export const loadClaimSteps = createAction(
    '[Claim Steps] Load Claim Steps',
    props<{ request: ClaimStepsRequestInterface }>()
);

export const loadClaimStepsSuccess = createAction(
    '[Claim Steps] Load Claim Steps Success',
    props<{ claimSteps: ClaimStepResponseInterface[] }>()
);
export const loadClaimStepsError = createAction(
    '[Claim Steps] Load Claim Steps Error',
    props<{ errors: ErrorInterface[] }>()
);
export const clearClaimSteps = createAction('[Claim Steps] Clear Claim Steps');
