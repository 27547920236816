<a modal-close-btn>X</a>
<h2 [innerHTML]="data.title" modal-title></h2>
<div [innerHTML]="data.content" modal-content></div>
<div *ngIf="data.actions" alignment="between" modal-actions>
    <button (click)="onDeclineClick()" *ngIf="data.actions.decline" class="btn btn--secondary">
        {{ data.actions.decline }}
    </button>
    <button *ngIf="data.actions.accept" [modal-close]="true" cdkFocusInitial class="btn btn--primary">
        {{ data.actions.accept }}
    </button>
</div>
