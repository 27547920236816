import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService } from '../../core';
import {
    ApiGetClaimCompensationResponseInterface,
    ApiGetClaimCoverageRequestInterface,
    ApiGetClaimCoverageResponseInterface,
    ApiSaveClaimCoverageRequestInterface,
    ApiSaveClaimCoverageResponseInterface,
    GetClaimCompensationRequestInterface,
    GetClaimCompensationResponseInterface,
    GetClaimCoverageRequestInterface,
    GetClaimCoverageResponseInterface,
    SaveClaimCoverageRequestInterface,
    SaveClaimCoverageResponseInterface,
} from '../interfaces';
import {
    mapGetClaimCoverageRequest,
    mapGetClaimCoverageResponse,
    mapSaveClaimCoverageRequest,
    mapSaveClaimCoverageResponse,
    mapGetClaimCompensationRequest,
    mapGetClaimCompensationResponse,
    mapGetClaimCompensationError$,
} from '../mappers';

@Injectable({
    providedIn: 'root',
})
export class ClaimCoverageApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getClaimCompensation$(
        request: GetClaimCompensationRequestInterface
    ): Observable<GetClaimCompensationResponseInterface> {
        const mappedRequest = mapGetClaimCompensationRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimCompensation);

        return this.apiService
            .post$<ApiGetClaimCompensationResponseInterface>(resource, mappedRequest)
            .pipe(map(mapGetClaimCompensationResponse), catchError(mapGetClaimCompensationError$));
    }

    public getClaimCoverage$(request: GetClaimCoverageRequestInterface): Observable<GetClaimCoverageResponseInterface> {
        const mappedRequest: ApiGetClaimCoverageRequestInterface = mapGetClaimCoverageRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimCoverage);

        return this.apiService
            .post$<ApiGetClaimCoverageResponseInterface>(resource, mappedRequest)
            .pipe(map(mapGetClaimCoverageResponse));
    }

    public saveClaimCoverage$(
        request: SaveClaimCoverageRequestInterface
    ): Observable<SaveClaimCoverageResponseInterface> {
        const mappedRequest: ApiSaveClaimCoverageRequestInterface = mapSaveClaimCoverageRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(
            ApiUrlResourcesEnum.ClaimCoverageCustomerResponse
        );

        return this.apiService
            .post$<ApiSaveClaimCoverageResponseInterface>(resource, mappedRequest)
            .pipe(map(mapSaveClaimCoverageResponse));
    }
}
