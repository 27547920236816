<div class="carousel">
    <div class="carousel__container">
        <div #carousel (swipeleft)="swipe($event)" (swiperight)="swipe($event)" class="carousel__inner">
            <ng-content select="[items]"></ng-content>
        </div>
    </div>

    <div *ngIf="showControls" class="carousel__controls">
        <button (click)="prev()" aria-label="Previous" class="carousel__button carousel__button--prev">
            <ng-content select="[buttonPrev]"></ng-content>
        </button>
        <button (click)="next()" aria-label="Next" class="carousel__button carousel__button--next">
            <ng-content select="[buttonNext]"></ng-content>
        </button>
    </div>
</div>

<ul *ngIf="showNavigation" class="carousel__navigation" [class.carousel__navigation--mobile]="isMobile">
    <li *ngFor="let item of navigation">
        <a
            (click)="handleClick(item.id)"
            [ngClass]="{ 'carousel__dot--active': item.active }"
            class="carousel__dot"
            href="#"
            title="Step {{ item.id }}"
        ></a>
    </li>
</ul>
