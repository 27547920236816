import { mapFromSuccessIndication, mapSequenceNumber } from '../../core';
import { mapCoverageIdResponse } from '../../insurance-policy';
import {
    ApiGetAppointmentsAdditionalElementReponseInterface,
    ApiGetAppointmentsClaimResponseInterface,
    ApiGetAppointmentsEventReponseInterface,
    ApiGetAppointmentsPlanningReponseInterface,
    ApiGetAppointmentsReponseInterface,
    ApiGetAppointmentsRequestInterface,
    ApiGetAppointmentsSustainedDamageResponseInterface,
    GetAppointmentAdditionalElementReponseInterface,
    GetAppointmentClaimResponseInterface,
    GetAppointmentEventReponseInterface,
    GetAppointmentPlanningReponseInterface,
    GetAppointmentsReponseInterface,
    GetAppointmentsRequestInterface,
    GetAppointmentSustainedDamageResponseInterface,
} from '../interfaces/get-appointment.interface';

function mapAdditionalElements(
    response: ApiGetAppointmentsAdditionalElementReponseInterface[]
): GetAppointmentAdditionalElementReponseInterface[] {
    if (!response) {
        return [];
    }

    return response.map((responseElement) => ({
        elementContent: responseElement.element_content,
        elementName: responseElement.element_name,
        label: responseElement.label,
    }));
}

function mapEventResponse(response: ApiGetAppointmentsEventReponseInterface): GetAppointmentEventReponseInterface {
    if (!response) {
        return null;
    }

    return {
        damageRepairEventSequenceNumber: mapSequenceNumber(response.damage_repair_event_sequence_number),
        damageRepairEventCode: response.damage_repair_event_code,
        damageRepairEventDescription: response.damage_repair_event_description,
        damageRepairEventCodeDescription: response.damage_repair_event_code_description,
        damageRepairEventDate: response.damage_repair_event_date,
        damageRepairEventDateDescription: response.damage_repair_event_date_description,
        damageRepairEventTime: response.damage_repair_event_time,
        damageRepairEventTimeDescription: response.damage_repair_event_time_description,
        businessRelationId: response.business_relation_id,
        name: response.name,
        postalCode: response.postal_code,
        countryId: response.country_id,
        countryDescription: response.country_description,
        streetName: response.street_name,
        city: response.city,
        civicNumber: response.civic_number,
        civicNumberSuffix: response.civic_number_suffix,
        provinceCode: response.provice_description,
        provinceDescription: response.provice_description,
        mailbox: response.mailbox,
        additionalAdressingExplanation: response.additional_adressing_explenation,
        emailAddress: response.email_address,
        telephoneNumber: response.telephone_number,
    };
}

function mapPlanningResponse(
    response: ApiGetAppointmentsPlanningReponseInterface
): GetAppointmentPlanningReponseInterface {
    if (!response) {
        return null;
    }

    return {
        locationsIntication: mapFromSuccessIndication(response.locaties_indicatie),
        productsIntication: mapFromSuccessIndication(response.producten_indicatie),
        distanceIndication: mapFromSuccessIndication(response.afstand_indicatie),
        gpsIndication: mapFromSuccessIndication(response.gps_indicatie),
        realtimePlanningIndication: mapFromSuccessIndication(response.realtime_planning_indicatie),
        plannableTimeslotsAmount: response.aantal_planbare_tijdsloten,
        additionalElements: mapAdditionalElements(response.aanvullende_elementen),
    };
}

function mapSustainedDamage(
    response: ApiGetAppointmentsSustainedDamageResponseInterface[]
): GetAppointmentSustainedDamageResponseInterface[] {
    if (!response) {
        return [];
    }

    return response.map((sustainedDamageResponse) => ({
        claimObjectId: sustainedDamageResponse.claim_object_id,
        claimObjectDescription: sustainedDamageResponse.claim_object_description,
        claimObjectSynonymId: sustainedDamageResponse.claim_object_synonym_id,
        claimObjectSynonymDescription: sustainedDamageResponse.claim_object_synonym_description,
        description: sustainedDamageResponse.description,
        sustainedDamageSequenceNumber: mapSequenceNumber(sustainedDamageResponse.sustained_damage_sequence_number),
        specificationId: sustainedDamageResponse.specification_id,
        claimCoverageIndication: mapFromSuccessIndication(sustainedDamageResponse.claim_coverage_indication),
        claimCoverageIndicationDescription: sustainedDamageResponse.dekking_indicatie_omschrijving,
    }));
}

function mapClaimResponse(
    response: ApiGetAppointmentsClaimResponseInterface[]
): GetAppointmentClaimResponseInterface[] {
    if (!response) {
        return [];
    }

    return response.map((claimResponse) => ({
        coverageId: mapCoverageIdResponse(claimResponse.coverage_id),
        coverageDescription: claimResponse.coverage_description,
        claimSequenceNumber: mapSequenceNumber(claimResponse.claim_sequence_number),
        startDatePurchasedCoverage: claimResponse.start_date_purchased_coverage,
        sustainedDamage: mapSustainedDamage(claimResponse.sustained_damage),
    }));
}

export function mapGetAppointmentsRequest(
    request: GetAppointmentsRequestInterface
): ApiGetAppointmentsRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
    };
}

export function mapGetAppointmentsResponse(
    response: ApiGetAppointmentsReponseInterface
): GetAppointmentsReponseInterface {
    if (!response.appointments) {
        return {
            appointments: [],
        };
    }

    return {
        appointments: response.appointments.map((appointmentResponse) => ({
            networkId: appointmentResponse.network_id,
            networkDescription: appointmentResponse.network_description,
            networkIdDescription: appointmentResponse.network_id_description,
            btnMakeAppointment: appointmentResponse.btn_make_appointment,
            btnOwnRepairer: appointmentResponse.btn_own_repairer,
            event: mapEventResponse(appointmentResponse.event),
            planning: mapPlanningResponse(appointmentResponse.planning),
            claim: mapClaimResponse(appointmentResponse.claim),
        })),
    };
}
