import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClaimStepResponseInterface } from '../interfaces';
import { ClaimStepsSelectors, ClaimStepsState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class ClaimStepsDataService {
    constructor(private store$: Store<ClaimStepsState>) {}

    public getState$(): Observable<ClaimStepsState> {
        return this.store$.select(ClaimStepsSelectors.getState);
    }

    public getClaimSteps$(): Observable<ClaimStepResponseInterface[]> {
        return this.store$.select(ClaimStepsSelectors.getClaimSteps);
    }
}
