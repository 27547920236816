import { mapEnumApiResponse } from '../../core';
import {
    ApiBusinessRelationNaturalPartyVersionIdEnum,
    ApiV1BusinessRelationNaturalPartyVersionIdEnum,
    BusinessRelationNaturalPartyVersionIdEnum,
} from '../enums';

export function mapBusinessRelationNaturalPartyVersionId(
    response: ApiBusinessRelationNaturalPartyVersionIdEnum,
    enablePartyCodes?: boolean
): BusinessRelationNaturalPartyVersionIdEnum | null {
    const partyRoleEnumType = enablePartyCodes
        ? ApiV1BusinessRelationNaturalPartyVersionIdEnum
        : ApiBusinessRelationNaturalPartyVersionIdEnum;
    return mapEnumApiResponse<BusinessRelationNaturalPartyVersionIdEnum>(
        response,
        partyRoleEnumType,
        BusinessRelationNaturalPartyVersionIdEnum
    );
}
