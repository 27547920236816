import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InsurancePolicyEffects, insurancePolicyReducer, INSURANCE_POLICY_FEATURE } from './store';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(INSURANCE_POLICY_FEATURE, insurancePolicyReducer),
        EffectsModule.forFeature([InsurancePolicyEffects]),
    ],
})
export class OutInsurancePolicyModule {}
