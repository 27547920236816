/* istanbul ignore file */
import { isPlatformServer } from '@angular/common';
import { FactoryProvider, InjectionToken, PLATFORM_ID } from '@angular/core';

/** Added istanbul ignore file to the first line of this file to avoid this file in coverage calculations **/
// Duplicated this windowMock from @inshared/shared/util => windowMock.
const mockWindow: Partial<Window> = {
    open: (url?: string | URL, target?: string, features?: string): null => {
        return null;
    },
    location: {
        origin: '',
        href: '',
        ancestorOrigins: undefined,
        hash: '',
        host: '',
        hostname: '',
        pathname: '',
        port: '',
        protocol: '',
        search: '',
        assign: (url: string | URL): void => {
            return null;
        },
        reload: (): void => {
            return null;
        },
        replace: function (url: string | URL): void {
            return null;
        },
    },
};

/** @deprecated use version from: `@inshared/shared/util/` */
export const WINDOW = new InjectionToken<Window | Partial<Window>>('window');

/** @deprecated use version from: `@inshared/shared/util/` */
function getWindow(platformId: string): Window | Partial<Window> {
    return isPlatformServer(platformId) ? mockWindow : window;
}

/** @deprecated use version from: `@inshared/shared/util/` */
export const windowProvider: FactoryProvider = {
    provide: WINDOW,
    useFactory: getWindow,
    deps: [PLATFORM_ID],
};
