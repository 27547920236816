export interface PaymentProcess {
    paymentProcess: PaymentProcessState;
}

export interface PaymentProcessState {
    paymentResponse: {
        paymentSequenceNumber: number;
        externalTransactionId: string;
        externalReferenceId: string;
        internalTransactionId: string;
    };
    paymentRequest: {
        relationId: number;
        amount: string;
    };
}

export const INITIAL_PAYMENT_PROCESS_STATE: PaymentProcessState = {
    paymentResponse: {
        paymentSequenceNumber: -1,
        externalTransactionId: '',
        externalReferenceId: '',
        internalTransactionId: '',
    },
    paymentRequest: {
        amount: '',
        relationId: -1,
    },
};

export const PAYMENT_PROCESS_FEATURE = 'paymentProcess';
