import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AggregateRequestInterface } from '../interfaces';
import { getAggregateAction } from '../store/aggregate.actions';
import { Aggregate } from '../store/aggregate.state';
import { AggregateEventService } from './aggregate.event.service';

@Injectable({
    providedIn: 'root',
})
export class AggregateActionService {
    constructor(private store$: Store<Aggregate>, private aggregateEventService: AggregateEventService) {}

    public getAggregate$(request: AggregateRequestInterface): Observable<void> {
        this.store$.dispatch(getAggregateAction(request));
        return this.aggregateEventService.getAggregate$();
    }
}
