import { AcceptanceValidationTypeEnum } from '../../acceptance';
import {
    ClassicValidateElementRequest,
    ClassicValidateElementsRequest,
    InternationalValidateElementsRequest,
    ValidateElementRequestInterface,
    ValidateMultipleElementRequestInterface,
} from '../interfaces';

export function mapValidateMultipleElementsRequest(
    request: ValidateMultipleElementRequestInterface
): InternationalValidateElementsRequest {
    return {
        container: [
            {
                element: request.elements?.map((element: { name: string; value: string }) => {
                    return {
                        element_name: element.name,
                        value: element.value,
                    };
                }),
                context_element_name: request.context,
            },
        ],
        webservice_id: request.webserviceId,
        acceptance_validation_type_code: request.acceptanceType ?? AcceptanceValidationTypeEnum.None,
    };
}

export function mapValidateElementsRequest(
    request: ValidateElementRequestInterface
): InternationalValidateElementsRequest {
    return mapValidateMultipleElementsRequest({
        elements: [{ name: request.name, value: request.value }],
        webserviceId: request.webserviceId,
        context: request.context,
        acceptanceType: request.acceptanceType,
    });
}

export function mapClassicValidateElementsRequest(
    request: ValidateElementRequestInterface
): ClassicValidateElementsRequest {
    const element: ClassicValidateElementRequest = {
        element_name: request.name,
        value: request.value,
        webservice_id: request.webserviceId,
    };
    return { elements: [element]};
}
