import { ApiExhibitsButtonInterface, ExhibitsButtonInterface } from '../interfaces';
import { mapButtonStatus } from './button-status.mapper';

export function mapExhibitsButtonsResponse(response: ApiExhibitsButtonInterface[]): ExhibitsButtonInterface[] {
    return response.map((button: ApiExhibitsButtonInterface) => {
        return {
            buttonName: button.button_name,
            buttonStatus: mapButtonStatus(button.button_status),
        };
    });
}
