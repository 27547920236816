import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ContentElementRequestInterface } from '../interfaces';
import { Content, RequestElementAction } from '../store';
import { ContentEventService } from './content.event.service';

@Injectable({
    providedIn: 'root',
})
export class ContentActionService {
    constructor(private store$: Store<Content>, private eventService: ContentEventService) {}

    public requestContentElement$(request: ContentElementRequestInterface): Observable<void> {
        this.store$.dispatch(new RequestElementAction({ request }));

        return this.eventService.getContentElement$(request.element_name);
    }
}
