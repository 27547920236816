import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ErrorInterface } from '../../core';
import { ValidationElementsInterface } from '../interfaces';
import { ValidationSelectors } from '../store/validation.selectors';
import { Validation } from '../store/validation.state';

@Injectable({
    providedIn: 'root',
})
export class ValidationDataService {
    constructor(private store$: Store<Validation>) {}

    public getElements$(): Observable<ValidationElementsInterface> {
        return this.store$.select((ValidationSelectors.getValidationElements));
    }

    public getErrors$(elementName: string): Observable<ErrorInterface[]> {
        return this.store$.select((ValidationSelectors.getElement(elementName)));
    }
}
