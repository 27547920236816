import { createFeatureSelector, createSelector, DefaultProjectorFn } from '@ngrx/store';
import { ClaimObjectDetailsInterface, ClaimObjectElementInterface } from '../interfaces';
import { ClaimObjectsState, CLAIM_OBJECTS_FEATURE } from './claim-objects.state';

export class ClaimObjectsSelectors {
    public static getClaimObjectsState = createFeatureSelector<ClaimObjectsState>(CLAIM_OBJECTS_FEATURE);

    public static getState = createSelector(
        ClaimObjectsSelectors.getClaimObjectsState,
        (state: ClaimObjectsState) => state
    );

    public static getClaimObjects = createSelector(
        ClaimObjectsSelectors.getClaimObjectsState,
        (state: ClaimObjectsState) => state.claimObjects
    );

    public static getClaimObjectDetails = (
        claimObjectId: string
    ): DefaultProjectorFn<ClaimObjectDetailsInterface | null> =>
        createSelector(ClaimObjectsSelectors.getClaimObjectsState, (state: ClaimObjectsState) => {
            const foundClaimObjectDetails = state.claimObjectDetails.find(
                (claimObjectDetails) => claimObjectDetails.claimObjectId === claimObjectId
            );

            if (!foundClaimObjectDetails) {
                return null;
            }

            return foundClaimObjectDetails;
        });

    public static getClaimObjectElements = (claimObjectId: string): DefaultProjectorFn<ClaimObjectElementInterface[]> =>
        createSelector(ClaimObjectsSelectors.getClaimObjectsState, (state: ClaimObjectsState) => {
            return state.claimObjectElements.filter(
                (claimObjectElement) => claimObjectElement.claimObjectId === claimObjectId
            );
        });

    public static getSavedClaimObjects = createSelector(
        ClaimObjectsSelectors.getClaimObjectsState,
        (state: ClaimObjectsState) => state.savedClaimObjects
    );
}
