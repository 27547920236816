export enum BusinessRelationLegalPartyVersionIdEnum {
    Alarm = 'Alarm', // Alarm centrale
    LegalAidInsurer = 'LegalAidInsurer', // Rechtsbijstand assuradeur
    Insurer = 'Insurer', // Assuradeur/Maatschappij
    CareInsurer = 'CareInsurer', // Zorg assuradeur
    PartInsharedConcern = 'PartInsharedConcern', // Onderdeel InShared Concern
    DistributionPartner = 'DistributionPartner', // Distributie partner
    ExternalHiring = 'ExternalHiring', // Externe inhuur
    ExpertiseAgency = 'ExpertiseAgency', // Expertisebureau
    InjuryClaimsSettelmentAgency = 'InjuryClaimsSettelmentAgency', // Letsel schaderegelingsbureau
    Brand = 'Brand', // Merk
    Buyer = 'Buyer', // Opkoper
    DamageRepairCompany = 'DamageRepairCompany', // Schadeherstelbedrijf
    ClaimSettlementOffice = 'ClaimSettlementOffice', // Assurantiekantoor/Schaderegelingsbureau
    Counterparty = 'Counterparty', // TEGP
    Supervisor = 'Supervisor', // Toezichthouder
    PowerOfAttorny = 'PowerOfAttorny', // Volmacht
}
