import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CountriesState } from '../store';
import * as countriesActions from '../store/countries.actions';
import { CountriesEventService } from './countries.event.service';

@Injectable({
    providedIn: 'root',
})
export class CountriesActionService {
    constructor(private store$: Store<CountriesState>, private countriesEventService: CountriesEventService) {}

    public getCountries$(): Observable<void> {
        this.store$.dispatch(countriesActions.getCountries());
        return this.countriesEventService.getCountries$();
    }

    public clearCountries(): void {
        this.store$.dispatch(countriesActions.clearCountries());
    }
}
