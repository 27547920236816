import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService } from '../../core';
import {
    ApiGetPartiesRequestInterface,
    ApiGetPartiesResponseInterface,
    ApiSaveContactRequestInterface,
    ApiSaveContactResponseInterface,
    ApiSaveCounterpartyRequestInterface,
    ApiSaveCounterpartyResponseInterface,
    ApiSaveWitnessRequestInterface,
    ApiSaveWitnessResponseInterface,
    GetPartiesRequestInterface,
    GetPartiesResponseInterface,
    SaveContactRequestInterface,
    SaveContactResponseInterface,
    SaveCounterpartyRequestInterface,
    SaveCounterpartyResponseInterface,
    SaveWitnessRequestInterface,
    SaveWitnessResponseInterface,
} from '../interfaces';
import {
    mapGetPartiesRequest,
    mapGetPartiesResponse,
    mapSaveContactRequest,
    mapSaveCounterpartyRequest,
    mapSaveWitnessRequest,
} from '../mappers';

@Injectable({ providedIn: 'root' })
export class ClaimPartiesApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getParties$(request: GetPartiesRequestInterface): Observable<GetPartiesResponseInterface> {
        const mappedRequest: ApiGetPartiesRequestInterface = mapGetPartiesRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.GetParties);

        return this.apiService
            .post$<ApiGetPartiesResponseInterface>(resource, mappedRequest)
            .pipe(
                map((response) =>
                    mapGetPartiesResponse(response, request.caseSequenceNumber, request?.enablePartyCodes)
                )
            );
    }

    public saveWitness$(request: SaveWitnessRequestInterface): Observable<SaveWitnessResponseInterface> {
        const mappedRequest: ApiSaveWitnessRequestInterface = mapSaveWitnessRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.SaveWitness);

        return this.apiService.post$<ApiSaveWitnessResponseInterface>(resource, mappedRequest).pipe(
            switchMap(() =>
                this.getParties$({
                    caseSequenceNumber: request.claimSequenceNumber,
                    enablePartyCodes: request?.enablePartyCodes,
                })
            )
        );
    }

    public saveContact$(request: SaveContactRequestInterface): Observable<SaveContactResponseInterface> {
        const mappedRequest: ApiSaveContactRequestInterface = mapSaveContactRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.SaveContact);

        return this.apiService.post$<ApiSaveContactResponseInterface>(resource, mappedRequest).pipe(
            switchMap(() =>
                this.getParties$({
                    caseSequenceNumber: request.claimSequenceNumber,
                    enablePartyCodes: request?.enablePartyCodes,
                })
            )
        );
    }

    public saveCounterparty$(request: SaveCounterpartyRequestInterface): Observable<SaveCounterpartyResponseInterface> {
        const mappedRequest: ApiSaveCounterpartyRequestInterface = mapSaveCounterpartyRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.SaveCounterParty);

        return this.apiService.post$<ApiSaveCounterpartyResponseInterface>(resource, mappedRequest).pipe(
            switchMap(() =>
                this.getParties$({
                    caseSequenceNumber: request.claimSequenceNumber,
                    enablePartyCodes: request?.enablePartyCodes,
                })
            )
        );
    }
}
