import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { PolicyProductIdEnum, PolicyScenarioCodeEnum } from '../../insurance-policy';
import { LoadProductScenarioResponseInterface } from '../interfaces';
import { ProductScenariosState, PRODUCT_SCENARIO_FEATURE } from './product-scenarios.state';

export class ProductScenariosSelectors {
    public static getProductScenariosState = createFeatureSelector<ProductScenariosState>(PRODUCT_SCENARIO_FEATURE);

    public static getProductScenarios = createSelector(
        ProductScenariosSelectors.getProductScenariosState,
        (state: ProductScenariosState) => state.scenarios
    );

    public static getProductScenarioCodes = createSelector(
        ProductScenariosSelectors.getProductScenariosState,
        (state: ProductScenariosState) => state.scenarios.map((scenario) => scenario.scenarioCode)
    );

    public static getProductScenarioByCode: (
        scenarioCode: PolicyScenarioCodeEnum
    ) => MemoizedSelector<object, LoadProductScenarioResponseInterface[]> = (scenarioCode: PolicyScenarioCodeEnum) =>
            createSelector(ProductScenariosSelectors.getProductScenariosState, (state: ProductScenariosState) =>
                state.scenarios.filter((scenario) => scenario.scenarioCode === scenarioCode)
            );

    public static getProductScenarioByProductId: (
        productId: PolicyProductIdEnum
    ) => MemoizedSelector<object, LoadProductScenarioResponseInterface[]> = (productId: PolicyProductIdEnum) =>
            createSelector(ProductScenariosSelectors.getProductScenariosState, (state: ProductScenariosState) =>
                state.scenarios.filter((scenario) => scenario.productId === productId)
            );
}
