import { ApiCreateResidentialAddressResponseInterface, CreateResidentialAddressResponseInterface } from '../interfaces';
import { PolicyProductIdEnum } from '../../insurance-policy';

export function createResidentialaddressResponseMapper(
    request: ApiCreateResidentialAddressResponseInterface
): CreateResidentialAddressResponseInterface {
    return {
        isHomeInsuredIndication: request.woonhuis_verzekerd_indicatie_bool,
        purchasedProducts: request.purchased_products.map(
            (product) => {
                return {
                    purchasedProductSequenceNumber: product.purchased_product_sequence_number,
                    productId: product.product_id as PolicyProductIdEnum,
                    productDescription: product.product_description,
                    licensePlate: product.license_plate,
                    VIN: product.VIN,
                    motorVehicleMake: product.motor_vehicle_make,
                    motorVehicleBrandDescription: product.motor_vehicle_brand_description,
                    motorVehicleType: product.motor_vehicle_type,
                    motorVehicleTypeDescription: product.motor_vehicle_type_description,
                    evbNumber: product.evb_number,
                };
            }
        )
    };
}
