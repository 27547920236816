import { TestimonialInterface } from '../interfaces';

export interface Testimonials {
    testimonials: TestimonialsState;
}

export interface TestimonialsState {
    testimonials: TestimonialInterface[];
    totalNumberOfRows?: number;
    rowNumberFrom?: number;
    rowNumber?: number;
    rowNumberDescription?: string | null;
    type?: string;
}

export const INITIAL_TESTIMONIALS_STATE: TestimonialsState = {
    testimonials: [],
};

export const TESTIMONIALS_FEATURE = 'testimonials';
