export enum Modal {
    Initial = 'INITIAL',
    Yes = 'YES',
    No = 'NO',
}

export enum UserChoice {
    Initial = 'INITIAL',
    Yes = 'YES',
    No = 'NO',
}
