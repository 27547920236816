import { FetchAddressResponseInterface } from '../interfaces';

export interface AddressState {
    address: FetchAddressResponseInterface;
}

export const ADDRESS_FEATURE = 'address';

export const INITIAL_ADDRESS_STATE: AddressState = {
    address: null,
};
