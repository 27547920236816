import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    DeleteExhibitRequestInterface,
    SaveExhibitRequestInterface,
    SubmitExhibitsRequestInterface,
    LoadExhibitsRequestInterface,
} from '../interfaces';
import * as ClaimExhibitsActions from '../store/claim-exhibits.actions';
import { ClaimExhibitsState } from '../store/claim-exhibits.state';
import { ClaimExhibitsEventService } from './claim-exhibits.event.service';

@Injectable({
    providedIn: 'root',
})
export class ClaimExhibitsActionService {
    constructor(
        private store$: Store<ClaimExhibitsState>,
        private claimExhibitsEventService: ClaimExhibitsEventService
    ) {}

    public loadExhibits$(request: LoadExhibitsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimExhibitsActions.loadExhibits({ request }));
        return this.claimExhibitsEventService.loadExhibits$();
    }

    public saveExhibit$(request: SaveExhibitRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimExhibitsActions.saveExhibit({ request }));
        return this.claimExhibitsEventService.saveExhibit$();
    }

    public deleteExhibit$(request: DeleteExhibitRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimExhibitsActions.deleteExhibit({ request }));
        return this.claimExhibitsEventService.deleteExhibit$();
    }

    public submitExhibits$(request: SubmitExhibitsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimExhibitsActions.submitExhibits({ request }));
        return this.claimExhibitsEventService.submitExhibits$();
    }

    public resetClaimExhibits(): void {
        this.store$.dispatch(ClaimExhibitsActions.resetClaimExhibits());
    }
}
