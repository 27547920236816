import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FetchAddressResponseInterface } from '../interfaces';
import { AddressSelectors, AddressState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class AddressDataService {
    constructor(private store$: Store<AddressState>) {}

    public getAddress$(): Observable<FetchAddressResponseInterface> {
        return this.store$.select(AddressSelectors.getAddress);
    }
}
