import { ApiClaimObjectContentInterface, ClaimObjectContentInterface } from '../interfaces';

export function mapClaimObjectContent(response: ApiClaimObjectContentInterface[]): ClaimObjectContentInterface[] {
    if (!response) {
        return [];
    }

    return response.map((content: ApiClaimObjectContentInterface) => ({
        contentDescription: content.content_description,
        contentId: content.content_id,
        contentSpecification: content.inhoud_specificatie,
    }));
}
