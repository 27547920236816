import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AddressApiService } from '../services/address.api.service';
import { AddressEventService } from '../services/address.event.service';
import * as AddressActions from './address.actions';

@Injectable()
export class AddressEffects {
    public fetchAddress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AddressActions.fetchAddress),
            switchMap(({ request }) =>
                this.addressApiService.fetchAddress$(request).pipe(
                    map((address) => AddressActions.fetchAddressSuccess({ address })),
                    catchError((errors) => of(AddressActions.fetchAddressError({ errors })))
                )
            )
        );
    });

    public fetchAddressError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AddressActions.fetchAddressError),
                tap(({ errors }) => this.addressEventService.onFetchAddressError(errors))
            );
        },
        { dispatch: false }
    );

    public fetchAddressSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(AddressActions.fetchAddressSuccess),
                tap(() => this.addressEventService.onFetchAddressSuccess())
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private addressApiService: AddressApiService,
        private addressEventService: AddressEventService
    ) {}
}
