import { LoadProductScenarioResponseInterface } from '../interfaces/load-product-scenario-response.interface';

export interface ProductScenariosState {
    scenarios: LoadProductScenarioResponseInterface[];
}

export const PRODUCT_SCENARIO_FEATURE = 'productScenarios';

export const INITIAL_PRODUCT_SCENARIO_STATE: ProductScenariosState = {
    scenarios: [],
};
