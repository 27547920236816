import { ContentActions, ContentActionType } from './content.actions';
import { ContentState, INITIAL_CONTENT_STATE } from './content.state';

export class ContentReducers {
    public static reducer(state: ContentState = INITIAL_CONTENT_STATE, action: ContentActionType): ContentState {
        switch (action.type) {
        case ContentActions.RequestElementSuccess:
            return {
                ...state,
                contentElements: {
                    ...state.contentElements,
                    ...{ [action.payload.name]: action.payload.contentElements },
                },
            };

        case ContentActions.RequestElementError:
            return {
                ...state,
                contentElements: {
                    ...state.contentElements,
                    ...{ [action.payload.name]: []},
                },
            };

        case ContentActions.RequestElement:
        default:
            return state;
        }
    }
}
