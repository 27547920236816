import { mapSequenceNumber } from '../../../core/mappers';
import {
    ApiCancelAddedProductRequest,
    CancelAddedProductRequestInterface,
    CancelAddedProductResponseInterface,
} from '../../interfaces';

export function mapCancelAddedProductRequest(
    request: CancelAddedProductRequestInterface
): ApiCancelAddedProductRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}

// After the added product is cancelled the product needs to be removed
// that's why we only map the initial request to an response. We only need
// productSequenceNumber in the reducer to remove the product after success.
export function mapCancelAddedProductResponse(
    request: CancelAddedProductRequestInterface
): CancelAddedProductResponseInterface {
    return {
        productSequenceNumber: request.productSequenceNumber,
    };
}
