export enum FormatCodeEnum {
    Alphanumeric = 'Alpha',
    Boolean = 'Boolean',
    Checkbox = 'Checkbox',
    Codelist = 'Code',
    Date = 'Date',
    LicensePlate = 'License plate',
    Numeric = 'Numeric',
    PostalCode = 'Postal code',
    Time = 'Time',
    TimeStamp = 'Timestamp',
    Text = 'Text',
    Valuta = 'Valuta',
}
