import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AggregateInterface } from '../interfaces';
import { AGGREGATE_FEATURE, AggregateState } from './aggregate.state';

export class AggregateSelectors {
    public static getAggregateState = createFeatureSelector<AggregateState>(AGGREGATE_FEATURE);

    // Only for testing
    public static getState = createSelector(AggregateSelectors.getAggregateState, (state: AggregateState) => state);

    public static getAggregate = createSelector(
        AggregateSelectors.getAggregateState,
        (state: AggregateState) => state.aggregates
    );

    public static getAggregateById = (id: string): MemoizedSelector<object, AggregateInterface[]> =>
        createSelector(AggregateSelectors.getAggregate, (aggregates) =>
            aggregates.filter((aggregateEntity) => aggregateEntity.key.includes(id))
        );
}
