import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class ValidationEventService {
    private _validations: { [elementName: string]: Subject<void> } = {};

    public getValidation$(name: string): Observable<any> {
        this._validations[name] = new Subject();

        return this._validations[name].asObservable();
    }

    public onValidateSuccess(name: string): void {
        if (this._validations.hasOwnProperty(name)) {
            this._validations[name].next();
            this._validations[name].complete();
        }
    }

    public onValidateError(name: string, errors: ErrorInterface[]): void {
        if (this._validations.hasOwnProperty(name)) {
            this._validations[name].error(errors);
        }
    }
}
