import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimPartiesState, CLAIM_PARTIES_FEATURE } from './claim-parties.state';

export class ClaimPartiesSelectors {
    private static getClaimPartiesState = createFeatureSelector<ClaimPartiesState>(CLAIM_PARTIES_FEATURE);

    public static getState = createSelector(
        ClaimPartiesSelectors.getClaimPartiesState,
        (state: ClaimPartiesState) => state
    );

    public static getLegalParties = createSelector(
        ClaimPartiesSelectors.getClaimPartiesState,
        (state: ClaimPartiesState) => {
            const response = state.parties?.legalParties;
            return response ?? [];
        }
    );

    public static getNaturalParties = createSelector(
        ClaimPartiesSelectors.getClaimPartiesState,
        (state: ClaimPartiesState) => {
            const response = state.parties?.naturalParties;
            return response ?? [];
        }
    );

    public static relatedPartyTypes = createSelector(
        ClaimPartiesSelectors.getClaimPartiesState,
        (state: ClaimPartiesState) => {
            const response = state.parties?.relatedPartyTypes;
            return response ?? [];
        }
    );
}
