import { Action } from '@ngrx/store';
import { type } from '../../../core';
import { Customer, Insurance } from '../../interfaces';

export const SAVE_PERSONAL_DATA = type('[Account Page] Save Personal Data');
export const STORE_CUSTOMER_DATA = type('[Salesfunnel] Store Customer Data');
export const NEW_CUSTOMER = type('[Account Page] New Customer');
export const RETURNING_CUSTOMER = type('[Account Page] Returning Customer');

// "authenticated" should be removed here once we've moved authentication to the outshared-lib
export class SavePersonalData implements Action {
    public readonly type = SAVE_PERSONAL_DATA;

    constructor(
        public payload: {
            customer?: Customer;
            insurance?: Insurance;
            validationStepCode?: string;
            authenticated?: boolean;
        }
    ) { }
}

export class StoreCustomerData implements Action {
    public readonly type = STORE_CUSTOMER_DATA;

    constructor(public payload: { customer: Partial<Customer> }) { }
}

export class NewCustomer implements Action {
    public readonly type = NEW_CUSTOMER;
}

export class ReturningCustomer implements Action {
    public readonly type = RETURNING_CUSTOMER;
}

export type CustomerActionUnion = SavePersonalData | StoreCustomerData | NewCustomer | ReturningCustomer;
