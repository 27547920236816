import { createReducer, on } from '@ngrx/store';
import * as DamageFreeYearsImpactActions from '../store/damage-free-years-impact.actions';
import { DAMAGE_FREE_YEARS_IMPACT_INITIAL_STATE } from './damage-free-years-impact.state';

export const damageFreeYearsImpactReducer = createReducer(
    DAMAGE_FREE_YEARS_IMPACT_INITIAL_STATE,
    on(DamageFreeYearsImpactActions.damageFreeYearsImpactSuccess, (state, { response }) => ({
        ...state,
        damageFreeYearsImpact: response,
    }))
);
