import { mapSequenceNumber } from '../../../core/mappers';
import { ApiStartDateProductRequest, StartDateProductRequestInterface } from '../../interfaces';

export function mapStartDateProductRequest(request: StartDateProductRequestInterface): ApiStartDateProductRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
            start_date: request.startDate,
        },
    };
}
