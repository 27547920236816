import { RelationInterface } from '../../relation';
import { ApiAuthenticationStatusEnum } from '../enums';

const LEGACY_DISCRIMINATOR = 'session_duration';

export interface LegacyApiSessionResponse {
    continuous_login_device?: string;
    continuous_login_token?: string;
    relation?: RelationInterface;
    relation_id?: number;
    session_duration: number;
    soap_session_id: string;
    status: string;
    status_code: ApiAuthenticationStatusEnum;
    status_description: string;
    type: string;
}

export interface ApiSessionResponse {
    continuous_login_device?: string;
    continuous_login_token?: string;
    relation?: RelationInterface;
    relation_id?: number;
    expiry_duration: number;
    soap_session_id: string;
    status_code: ApiAuthenticationStatusEnum;
    description: string;
    type: string;
}

export function isLegacySessionResponse(
    response: LegacyApiSessionResponse | ApiSessionResponse
): response is LegacyApiSessionResponse {
    return response.hasOwnProperty(LEGACY_DISCRIMINATOR);
}
