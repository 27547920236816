import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromCoveragePremium from './store/coverage-premium/coverage-premium.reducer';
import * as fromProduct from './store/product/product.reducer';
import * as fromScenarios from './store/scenarios/scenarios.reducer';

/** @deprecated use InsurancePolicyState */
export interface State {
    myZone: MyZoneState;
}

/** @deprecated use InsurancePolicyState */
export interface MyZoneState {
    product: fromProduct.State;
    coveragePremium: fromCoveragePremium.State;
    scenarios: fromScenarios.State;
}

/** @deprecated use insurancePolicyReducer  */
export const reducers: ActionReducerMap<MyZoneState> = {
    product: fromProduct.reducer,
    coveragePremium: fromCoveragePremium.reducer,
    scenarios: fromScenarios.reducer,
};

/** @deprecated use INSURANCE_POLICY_FEATURE */
export const getMyZoneState = createFeatureSelector<MyZoneState>('myZone');
