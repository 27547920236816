import { Action } from '@ngrx/store';
import { NotificationInterface, type } from '../../../core';
import { Address, AddressData } from '../../interfaces';

export const GET_ADDRESS_DATA = type('[Salesfunnel] Get Address Data');
export const GET_ADDRESS_DATA_SUCCESS = type('[Salesfunnel] Get Address Data Success');
export const GET_ADDRESS_DATA_ERROR = type('[Salesfunnel] Get Address Data Error');

export class GetAddressData implements Action {
    public readonly type = GET_ADDRESS_DATA;

    constructor(public payload: Address) {}
}

export class GetAddressDataSuccess implements Action {
    public readonly type = GET_ADDRESS_DATA_SUCCESS;

    constructor(public payload: { result: AddressData; address: Address }) {}
}

export class GetAddressDataError implements Action {
    public readonly type = GET_ADDRESS_DATA_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export type AddressActionUnion = GetAddressData | GetAddressDataSuccess | GetAddressDataError;
