import { mapSequenceNumber } from '../../../core/mappers';
import { ApiReviveProductRequest, ReviveProductRequestInterface } from '../../interfaces';

export function mapReviveProductRequest(request: ReviveProductRequestInterface): ApiReviveProductRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
