import { mapFromSuccessIndication, mapSequenceNumber } from '../../../core';
import { ApiProductDetailsCaravanResponse, ProductDetailsCaravanResponseInterface } from '../../interfaces';
import { mapProductBonusComponentResponse } from '../product/product-bonus-component.mapper';
import { mapProductIdResponse } from '../product/product-id.mapper';
import { mapProductPremiumDeterminingFactorResponse } from '../product/product-premium-determining-factor.mapper';
import { mapPreventativeMeasureResponse } from '../product/product-preventative-measure.mapper';

export function mapProductDetailsCaravanResponse(
    response: ApiProductDetailsCaravanResponse
): ProductDetailsCaravanResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        productId: mapProductIdResponse(response.product_id),
        productSequenceNumber: mapSequenceNumber(response.purchased_product_sequence_number),
        productDetails: {
            bonusComponent: mapProductBonusComponentResponse(response.product_bonus_component),
            coveragesAdjustmentPresentIndication: mapFromSuccessIndication(
                response.coverages_adjustment_present_indication
            ),
            coveragesAdjustmentStartDate: response.coverages_adjustment_start_date,
            premiumDeterminingFactor: mapProductPremiumDeterminingFactorResponse(response.premium_determining_factor),
            preventativeMeasure: mapPreventativeMeasureResponse(response.permitted_preventative_measure),
        },
    };
}
