import { createSelector } from '@ngrx/store';
import { getProductId } from '../progress/progress.selectors';
import { getSalesState } from '../sales.reducers';
import * as fromInsurance from './insurance.reducer';

export const getInsuranceState = createSelector(getSalesState, (state) => state.insurance);
export const getInsuranceEntities = createSelector(getInsuranceState, fromInsurance.getEntities);
export const getInsuranceIds = createSelector(getInsuranceState, fromInsurance.getIds);
export const isLoading = createSelector(getInsuranceState, fromInsurance.isLoading);
export const getErrors = createSelector(getInsuranceState, fromInsurance.getErrors);

export const getInsuranceCollection = createSelector(getInsuranceIds, getInsuranceEntities, (ids, entities) =>
    ids.map((id) => entities[id])
);

export const getCurrentInsuranceData = createSelector(getInsuranceEntities, getProductId, (entities, productId) => {
    return entities[productId] || null;
});

export const getInsuranceCampaignCode = createSelector(getInsuranceEntities, getProductId, (entities, productId) => {
    return entities[productId]?.campaign_code || null;
});
