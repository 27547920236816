import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INSURANCE_INFO_CHECK_FEATURE, InsuranceInfoCheckState } from './insurance-info-check.state';

export class InsuranceInfoCheckSelectors {
    public static getInsuranceInfoCheckState = createFeatureSelector(INSURANCE_INFO_CHECK_FEATURE);

    public static getState = createSelector(
        InsuranceInfoCheckSelectors.getInsuranceInfoCheckState,
        (state: InsuranceInfoCheckState) => state
    );

    public static getInsuranceInfoCheck = createSelector(
        InsuranceInfoCheckSelectors.getInsuranceInfoCheckState,
        (state: InsuranceInfoCheckState) => state.insuranceInfoCheck
    );
}
