import { createReducer, on } from '@ngrx/store';
import * as ClaimPartiesActions from './claim-parties.actions';
import { ClaimPartiesState, INITIAL_CLAIM_PARTIES_STATE } from './claim-parties.state';

export const claimPartiesReducer = createReducer(
    INITIAL_CLAIM_PARTIES_STATE,
    on(
        ClaimPartiesActions.getPartiesSuccess,
        (state, { response }): ClaimPartiesState => ({
            ...state,
            parties: response.parties,
        })
    ),
    on(
        ClaimPartiesActions.saveWitnessSuccess,
        (state, { response }): ClaimPartiesState => ({
            ...state,
            parties: response.parties,
        })
    ),
    on(
        ClaimPartiesActions.saveCounterpartySuccess,
        (state, { response }): ClaimPartiesState => ({
            ...state,
            parties: response.parties,
        })
    ),
    on(
        ClaimPartiesActions.saveContactSuccess,
        (state, { response }): ClaimPartiesState => ({
            ...state,
            parties: response.parties,
        })
    ),
    on(ClaimPartiesActions.resetClaimParties, (): ClaimPartiesState => INITIAL_CLAIM_PARTIES_STATE)
);
