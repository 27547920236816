import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    AttachmentRequestInterface,
    CategoryInterface,
    ComplaintRequestInterface,
    CreateEmailRequestInterface,
    DownloadTerminationLetterRequestInterface,
    DownloadTerminationLetterResponseInterface,
    EmailTerminationLetterRequestInterface,
    GetInsuranceCompaniesResponseInterface,
} from '../interfaces';

export const createEmail = createAction('[Contact] Create email', props<{ request: CreateEmailRequestInterface }>());
export const createEmailSuccess = createAction('[Contact] Create email success', props<{ mailQuestionId: number }>());
export const createEmailError = createAction('[Contact] Create email error', props<{ errors: ErrorInterface[] }>());

export const loadCategories = createAction('[Contact] Load categories', props<{ contactIndication: boolean }>());
export const loadCategoriesSuccess = createAction(
    '[Contact] Load categories success',
    props<{ categories: CategoryInterface[] }>()
);
export const loadCategoriesError = createAction(
    '[Contact] Load categories error',
    props<{ errors: ErrorInterface[] }>()
);

export const sendEmail = createAction('[Contact] Send email', props<{ mailQuestionId: number }>());

export const sendEmailSuccess = createAction('[Contact] Send email success');

export const sendEmailError = createAction('[Contact] Send email error', props<{ errors: ErrorInterface[] }>());

export const sendComplaint = createAction('[Contact] Send complaint', props<{ request: ComplaintRequestInterface }>());

export const sendComplaintSuccess = createAction('[Contact] Send complaint success');

export const sendComplaintError = createAction('[Contact] Send complaint error', props<{ errors: ErrorInterface[] }>());

export const addAttachment = createAction('[Contact] Add attachment', props<{ request: AttachmentRequestInterface }>());

export const addAttachmentSuccess = createAction('[Contact] Add attachment success');

export const addAttachmentError = createAction('[Contact] Add attachment error', props<{ errors: ErrorInterface[] }>());

export const getInsuranceCompanies = createAction('[Contact] Get companies');

export const getInsuranceCompaniesSuccess = createAction(
    '[Contact] Get companies success',
    props<{ response: GetInsuranceCompaniesResponseInterface[] }>()
);

export const getInsuranceCompaniesError = createAction(
    '[Contact] Get companies error',
    props<{ errors: ErrorInterface[] }>()
);

export const downloadTerminationLetter = createAction(
    '[Contact] Download termination letter',
    props<{ request: DownloadTerminationLetterRequestInterface }>()
);

export const downloadTerminationLetterSuccess = createAction(
    '[Contact] Download termination letter success',
    props<{ response: DownloadTerminationLetterResponseInterface }>()
);

export const downloadTerminationLetterError = createAction(
    '[Contact] Download termination letter error',
    props<{ errors: ErrorInterface[] }>()
);

export const emailTerminationLetter = createAction(
    '[Contact] Email termination letter',
    props<{ request: EmailTerminationLetterRequestInterface }>()
);

export const emailTerminationLetterSuccess = createAction('[Contact] Email termination letter success');

export const emailTerminationLetterError = createAction(
    '[Contact] Email termination letter error',
    props<{ errors: ErrorInterface[] }>()
);
