import { ApiClaimObjectDetailsInterface, ClaimObjectDetailsInterface } from '../interfaces';
import { mapClaimObjectSpecification } from './claim-object-specification.mapper';

export function mapClaimObjectDetails(
    response: ApiClaimObjectDetailsInterface[],
    claimObjectId: string
): ClaimObjectDetailsInterface[] {
    const claimObjectDetails = response[0];

    if (!response) {
        return [];
    }

    return [
        {
            claimObjectSpecifications: mapClaimObjectSpecification(claimObjectDetails.specification),
            claimObjectCode: claimObjectDetails.specification_group_code,
            claimObjectDescription: claimObjectDetails.specification_group_description,
            claimObjectVisible: claimObjectDetails.visible,
            claimObjectId: claimObjectId,
        },
    ];
}
