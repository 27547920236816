import {
    TerminateActivePurchasedProductRequestInterface,
    TerminateActivePurchasedProductResponseInterface,
} from '../interfaces';
import { ApiTerminateActivePurchasedProductRequest } from '../interfaces/product/api.terminate-active-purchased-product.request';
import { ApiTerminateActivePurchasedProductResponse } from '../interfaces/product/api.terminate-active-purchased-product.response';
import { mapTerminateReasonRequest } from './terminate-reason.mapper';

/** @deprecated use OutInsurancePolicyModule store */
export function mapTerminateActivePurchasedProductRequest(
    request: TerminateActivePurchasedProductRequestInterface
): ApiTerminateActivePurchasedProductRequest {
    return {
        purchased_product: {
            end_date: request.endDate,
            purchased_product_sequence_number: request.purchasedProductSequenceNumber,
        },
        purchased_product_termination_reason: {
            reason_code: request.reasonCode ? mapTerminateReasonRequest(request.reasonCode) : null,
            next_insurance_company_id: request.nextInsuranceCompanyId ?? null,
            next_insurance_company_external_reference_id: request.nextInsuranceCompanyExternalReferenceId ?? null,
        },
    };
}

/** @deprecated use OutInsurancePolicyModule store */
export function mapTerminateActivePurchasedProductResponse(
    response: ApiTerminateActivePurchasedProductResponse
): TerminateActivePurchasedProductResponseInterface {
    return {
        success: response.success,
        terminationQuestions: response.termination_questions.map((question) => ({
            questionElementId: question.question_element_id,
            questionDescription: question.question_description,
            order: question.order,
            formatCode: question.format_code,
            formatDescription: question.format_description,
            answers: question.answers.map((answer) => ({
                answerCode: answer.answer_code,
                answerDescription: answer.answer_description,
            })),
        })),
    };
}
