import { mapFromSuccessIndication } from '../../../core';
import { ApiProductHomeResponse, ProductHomeResponseInterface } from '../../interfaces';

export function mapProductHomeResponse(response: ApiProductHomeResponse): ProductHomeResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        streetName: response.address.street_name,
        city: response.address.city,
        buildYear: response.build_year,
        civicNumber: response.civic_number,
        civicNumberSuffix: response.civic_number_suffix,
        countryDescription: response.country_description,
        countryId: response.country_id,
        postalCode: response.postal_code,
        recreationalHomeIndication: mapFromSuccessIndication(response.recreational_home_indication),
        rentalHomeIndication: mapFromSuccessIndication(response.rental_indication),
        rentalIndicationDescription: response.rental_indication_description,
        strawRoofingIndication: mapFromSuccessIndication(response.straw_roofing_indication),
        strawRoofingIndicationDescription: response.straw_roofing_indication_description,
        tenantOwnerCode: response.tenant_owner_code,
        tenantOwnerDescription: response.tenant_owner_description,
    };
}
