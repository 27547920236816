import { Action } from '@ngrx/store';
import { type } from '../../../core';

export const LICENSE_PLATE_CHANGED = type('[License Plate Validator] License Plate Changed');

export class LicensePlateChanged implements Action {
    public readonly type = LICENSE_PLATE_CHANGED;

    constructor(public payload: { licensePlate: string }) {}
}

export type VehicleUnion = LicensePlateChanged;
