import { ApiModifiableCodeEnum, ModifiableCodeEnum } from '../enums/';

export function mapModifiableCode(response: ApiModifiableCodeEnum): ModifiableCodeEnum {
    if (!response) {
        return null;
    }

    switch (response) {
        case ApiModifiableCodeEnum.System:
            return ModifiableCodeEnum.System;
        case ApiModifiableCodeEnum.Client:
            return ModifiableCodeEnum.Client;
    }
}
