import { ApiExhibitsDocumentInterface, ExhibitsDocumentInterface } from '../interfaces';
import { mapExhibitsButtonsResponse } from './exhibits-button.mapper';

export function mapExhibitsDocumentResponse(response: ApiExhibitsDocumentInterface[]): ExhibitsDocumentInterface[] {
    return response.map((document: ApiExhibitsDocumentInterface) => {
        return {
            cynodocsDocumentId: document.cynodocs_document_id,
            pgDocumentDate: document.pg_document_date,
            pgDocumentFilename: document.pg_document_filename,
            btnDeleteUpload: document.btn_delete_upload,
            status: document.status,
            buttons: mapExhibitsButtonsResponse(document.buttons),
            uploadEnvelopeItemSequenceNumber: document.upload_envelope_item_sequence_number,
            uploadEnvelopeSequenceNumber: document.upload_envelope_sequence_number,
        };
    });
}
