import { ResponseInterface } from '../../core';
import { DownloadFileInterface } from '../interfaces/product/dowload-file.interface';

/** @deprecated use OutInsurancePolicyModule store */
export function PolicyFileDownloadMapper(policyFileDownload: ResponseInterface): DownloadFileInterface {
    return {
        name: policyFileDownload.data.file_name,
        pdf: policyFileDownload.data.data_base64,
        type: policyFileDownload.data.bestandsextensie_mimetype,
    };
}
