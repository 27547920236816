import { ApiGetClaimNotificationInterface } from '../interfaces/api-get-claim-notification.interface';
import { ClaimNotificationInterface } from '../interfaces/claim-notification.interface';

export function getClaimNotificationMapper(apiResponse: ApiGetClaimNotificationInterface): ClaimNotificationInterface {
    return {
        caseSequenceNumber: apiResponse.case_sequence_number,
        caseSequenceNumberDescription: apiResponse.case_sequence_number_description,
        emailNotificationIndication: Boolean(apiResponse.email_notificatie_indicatie === 'J'),
        emailNotificationIndicationDescription: apiResponse.email_notificatie_indicatie_omschrijving,
        relationId: apiResponse.relation_id,
        smsNotificationIndication: Boolean(apiResponse.sms_notificatie_indicatie === 'J'),
        smsNotificationIndicationDescription: apiResponse.sms_notificatie_indicatie_omschrijving,
    };
}
