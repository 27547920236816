import { PurchasedProductInterface } from '../interfaces/purchased-product.interface';

export interface InsurancePolicyState {
    selectedId: string;
    purchasedProducts: PurchasedProductInterface[];
}

export const INSURANCE_POLICY_INITIAL_STATE: InsurancePolicyState = {
    selectedId: '',
    purchasedProducts: [],
};

export const INSURANCE_POLICY_FEATURE = 'insurancePolicy';
