import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsContentComponent } from './tabs-content';
import { TabsHeadComponent } from './tabs-head';
import { TabsTitleComponent } from './tabs-title';
import { TabsComponent } from './tabs.component';

@NgModule({
    declarations: [TabsComponent, TabsHeadComponent, TabsContentComponent, TabsTitleComponent],
    imports: [CommonModule],
    exports: [TabsComponent, TabsHeadComponent, TabsContentComponent, TabsTitleComponent],
})
export class TabsModule {}
