import { ApiLivechatStatusInterface } from '../interfaces/api';
import { LivechatStatusInterface } from '../interfaces';

export function mapLivechatStatusResponse(data: ApiLivechatStatusInterface): LivechatStatusInterface {
    return {
        availableAgents: data.available_agents,
        availableCapacity: data.available_capacity,
        isAvailable: data.is_available,
        willClose: new Date(data.will_close),
        willOpen: new Date(data.will_open),
    };
}
