import {
    ApiProductPermittedPreventativeMeasureResponse,
    ProductPreventativeMeasureResponseInterface,
} from '../../interfaces';
import { mapBonusComponentIdResponse } from './bonus-component-id.mapper';

export function mapPreventativeMeasureResponse(
    response: ApiProductPermittedPreventativeMeasureResponse[]
): ProductPreventativeMeasureResponseInterface[] {
    if (!response) {
        return [];
    }

    return response.map((preventativeMeasure) => ({
        implementedPreventativeMeasure: preventativeMeasure.implemented_preventative_measure,
        implementedPreventativeMeasureFuture: preventativeMeasure.implemented_preventative_measure_future,
        bonusComponentDescription: preventativeMeasure.product_bonus_component_description,
        bonusComponentId: mapBonusComponentIdResponse(preventativeMeasure.product_bonus_component_id),
        bonusPoints: preventativeMeasure.product_bonus_points.bonus_points,
    }));
}
