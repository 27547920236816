import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ResponseInterface } from '../../core';
import { CredentialsInterface, LoginRequestInterface, LoginTokenRequestInterface } from '../interfaces';
import {
    mapAuthenticationResponse,
    mapLoginRequest,
    mapSessionResponse,
    mapTokenRequest,
    mapValidateCredentialsResponse,
    mapLoginTokenRequest,
    mapValidateEmailAddressRequest,
} from '../mappers';

const RESOURCES = {
    LOGIN: 'en-gb/relation-authorization/validate/account',
    LOGIN_TOKEN: 'en-gb/relation-authorization/provide/logintoken',
    VALIDATE_EMAIL_ADDRESS: 'en-gb/relation-authorization/validate/emailaddress',
    LOGOUT: 'en-gb/relation-authorization/log_out/account',
    REQUEST_SESSION: 'en-gb/relation-authorization/request/session',
    REQUEST_SESSION_WITH_TOKEN: 'en-gb/relation-authorization/validate/doorlopend-inlogtoken',
};

@Injectable({
    providedIn: 'root',
})
export class AuthenticationApiService {
    constructor(private apiService: ApiService) {}

    public extendSession$(): Observable<ResponseInterface> {
        return this.apiService
            .postResponse$(RESOURCES.REQUEST_SESSION)
            .pipe(map(mapAuthenticationResponse), map(mapSessionResponse));
    }

    public extendSessionWithToken$(token: string): Observable<ResponseInterface> {
        const payload = mapTokenRequest(token);

        return this.apiService
            .postResponse$(RESOURCES.REQUEST_SESSION_WITH_TOKEN, payload)
            .pipe(map(mapAuthenticationResponse), map(mapSessionResponse));
    }

    public login$(loginRequest: LoginRequestInterface): Observable<ResponseInterface> {
        const payload = mapLoginRequest(loginRequest);

        return this.apiService
            .postResponse$(RESOURCES.LOGIN, payload)
            .pipe(map(mapAuthenticationResponse), map(mapSessionResponse));
    }

    public validateCredentials$(request: CredentialsInterface): Observable<void> {
        const payload = mapLoginRequest(request);

        return this.apiService
            .postResponse$(RESOURCES.LOGIN, payload)
            .pipe(map(mapAuthenticationResponse), map(mapValidateCredentialsResponse));
    }

    public validateEmailAddress$(code: string): Observable<void> {
        const payload = mapValidateEmailAddressRequest(code);

        return this.apiService.post$(RESOURCES.VALIDATE_EMAIL_ADDRESS, payload);
    }

    public provideLoginToken$(request: LoginTokenRequestInterface): Observable<void> {
        const payload = mapLoginTokenRequest(request);

        return this.apiService.post$(RESOURCES.LOGIN_TOKEN, payload);
    }

    public logout$(): Observable<void> {
        return this.apiService.post$<void>(RESOURCES.LOGOUT);
    }
}
