export interface Contact {
    contact: ContactState;
}

export interface ContactState {
    email?: string;
    question?: string;
    mailQuestionId?: number;
    categoryCode?: string;
    subjectCode?: string;
}

export const INITIAL_CONTACT_STATE: ContactState = {};

export const CONTACT_FEATURE = 'contact';
