export enum CoverageIdEnum {
    Liability = 'Liability', // AVP
    CarLegalLiability = 'Car Legal Liability', // WA
    CarLimitedCasco = 'Car Limited Casco', // BC
    CarLimitedCascoExtra = 'Car Limited Casco Extra', // BCE
    CarAllrisk = 'Car Allrisk', // CA
    CarDamageInsuranceOccupants = 'Car Damage Insurance Occupants', // ASVI
    CarRoadsideAssistanceNetherlands = 'Car Roadside Assistance Netherlands', // PHNL
    CarRoadsideAssistanceEurope = 'Car Roadside Assistance Europe', // PHEU
    CarRedressLegalAid = 'Car Redress Legal Aid', // AVHS
    CarDirectClaimsHandling = 'Car Direct Claims Handling', // DISA
    MotorDirectClaimsHandling = 'Motor Direct Claims Handling', // DISM
    MopedLegalLiability = 'Moped Legal Liability', // BRWA
    MopedLimitedCasco = 'Moped Limited Casco', // BRBC
    MopedAllrisk = 'Moped Allrisk', // BRCA
    CaravanAllriskBenelux = 'Caravan Allrisk Benelux and Germany', // ALRN
    CaravanAllriskEurope = 'Caravan Allrisk Europe', // ALRE
    CaravanHouseholdEffects = 'Caravan Household Effects', // CINB
    CaravanExtra = 'Caravan Extra\'s', // ACCS
    CaravanHailBenelux = 'Caravan Hail Benelux and Germany', // HGLN
    CaravanHailEurope = 'Caravan Hail Europe', // HGLE
    PetAccident = 'Pet and Accidents', // BZDO
    PetInsurance = 'Pet Insurance',
    PetCare50Percent = 'Pet Care 50%', // ADGZ
    PetCare100Percent = 'Pet Care 100%', // ADCZ
    PetExtraCoverage = 'Pet Extra Coverage', // ADCZ
    PetCancerTreatment = 'Pet Cancer Treatment', // ADCZ
    PetTeeth = 'Pet Teeth', // ADCZ
    PetCrem = 'Pet Crem', // CREM HEIN
    BicycleTheft = 'Bicycle Theft', // FDIE
    BicycleAllrisk = 'Bicycle Allrisk', // FALL
    BicycleHomeDeliveryService = 'Bicycle Home Delivery Service', // FTBP
    MotorLimitedCasco = 'Motor Limted Casco', // MWA
    MotorInStorage = 'Motor in Storage', // MTOP
    MotorAllrisk = 'Motor Allrisk', // MCA
    MotorDamageInsuranceOpputants = 'Motor Damage Insurance Occupants', // MSVO
    MotorRedressLegalAid = 'Motor Redress Legal Aid', // MVHS
    MotorAccidentPermanentInjury = 'Motor Accident Permanent Injury', // INVL
    MotorAccidentDeath = 'Motor Accident Death', // OVRL
    TravelEurope = 'Travel Europe', // EURB
    TravelWorld = 'Travel World', // WERB
    TravelVehicleAssistance = 'Travel Vehicle Assistance', // VHLP
    TravelCancellation = 'Travel Canellation', // ANNU
    TravelMedicalCostEurope = 'Travel Medical Cost Europe', // GENE
    TravelMedicalCostWorld = 'Travel Medical Cost World', // GENW
    HomeHouseholdEffectStandard = 'Home Household Effect Standard', // INBL
    HomeBuildingStandard = 'Home Building Standard', // OPST
    HomeHouseholdEffectsComplete = 'Home Household Effects Complete', // IBAR
    HomeBuildingComplete = 'Home Building Complete', // OSAR
    HomeGlass = 'Home Glass', // GLAS
    HomeMobileElectronics = 'Home Mobile Electronics', // MBEL
    HomeOwnerTenantInterest = 'Home Owner Tenant Interest', // HUAP
    HomeOwnerTenantInterestComplete = 'Home Owner Tenant Interest Complete', // HAAR
    LegalAssistanceConsumerAndLiving = 'Legal Assistance Consumer And Living', // CENW
    LegalAssistanceWorkAndIncome = 'Legal Assistance Work And Income', // WENI
    LegalAssistanceFamilyAndWealth = 'Legal Assistance Family And Wealth', // FENV
    LegalAssistanceAccidentAndTraffic = 'Legal Assistance Accident And Traffic', // ORB
}
