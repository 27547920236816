import { ApiGetSpecificationRequestInterface, GetSpecificationRequestInterface } from '../interfaces';

export function mapGetSpecificationRequest(
    request: GetSpecificationRequestInterface
): ApiGetSpecificationRequestInterface {
    return {
        kind_of_loss: {
            kind_of_loss_id: request.damageTypeId,
        },
    };
}
