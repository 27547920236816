import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import {
    AllCoveragesRequestInterface,
    AllProductDetailsRequestInterface,
    PurchasedProductInterface,
} from '../interfaces';
import { InsurancePolicyActionService } from './insurance-policy.action.service';
import { InsurancePolicyDataService } from './insurance-policy.data.service';

export enum InsurancePolicyLoadingStrategyEnum {
    Fast = 'Fast loading',
    Default = 'Default loading',
    Slow = 'Slow loading',
}

@Injectable({ providedIn: 'root' })
export class InsurancePolicyLoadingService {
    constructor(
        private insurancePolicyActionService: InsurancePolicyActionService,
        private insurancePolicyDataService: InsurancePolicyDataService
    ) {}

    public getStrategy$(strategy: InsurancePolicyLoadingStrategyEnum): Observable<void> {
        switch (strategy) {
            case InsurancePolicyLoadingStrategyEnum.Fast:
                return this.fastLoading$();
            case InsurancePolicyLoadingStrategyEnum.Default:
                return this.defaultLoading$();
            case InsurancePolicyLoadingStrategyEnum.Slow:
                return this.slowLoading$();
        }
    }

    private fastLoading$(): Observable<void> {
        return this.insurancePolicyActionService.policy$();
    }

    private defaultLoading$(): Observable<void> {
        return this.insurancePolicyActionService.policy$().pipe(
            withLatestFrom(this.insurancePolicyDataService.getPurchasedProducts$()),
            switchMap(([, products]: [void, PurchasedProductInterface[]]) => this.allCoverages$(products))
        );
    }

    private slowLoading$(): Observable<void> {
        return this.insurancePolicyActionService.policy$().pipe(
            withLatestFrom(this.insurancePolicyDataService.getPurchasedProducts$()),
            switchMap(([, products]: [void, PurchasedProductInterface[]]) => this.allCoverages$(products)),
            withLatestFrom(this.insurancePolicyDataService.getPurchasedProducts$()),
            switchMap(([, products]: [void, PurchasedProductInterface[]]) => this.allProductDetails$(products))
        );
    }

    private allCoverages$(purchasedProducts: PurchasedProductInterface[]): Observable<void> {
        if (!purchasedProducts?.length) {
            return of(null);
        }

        const request: AllCoveragesRequestInterface = {
            request: purchasedProducts.map((product) => ({
                productSequenceNumber: product.productSequenceNumber,
            })),
        };

        return this.insurancePolicyActionService.allCoverages$(request);
    }

    private allProductDetails$(purchasedProducts: PurchasedProductInterface[]): Observable<void> {
        if (!purchasedProducts?.length) {
            return of(null);
        }

        const request: AllProductDetailsRequestInterface = {
            request: purchasedProducts.map((product) => ({
                productSequenceNumber: product.productSequenceNumber,
                productId: product.productId,
            })),
        };

        return this.insurancePolicyActionService.allProductDetails$(request);
    }
}
