import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FamilyCompositionInterface, RelationInterface } from '../interfaces';
import { RelationSelectors } from '../store/relation.selectors';
import { Relation, RelationState } from '../store/relation.state';

@Injectable({
    providedIn: 'root',
})
export class RelationDataService {
    constructor(private store$: Store<Relation>) {}

    public getState$(): Observable<RelationState> {
        return this.store$.select((RelationSelectors.getState));
    }

    public getRelation$(): Observable<RelationInterface> {
        return this.store$.select((RelationSelectors.getRelation));
    }

    public getFutureFamilyComposition$(): Observable<FamilyCompositionInterface> {
        return this.store$.select(RelationSelectors.getRelation).pipe(

            filter((state) => !!state),
            map((state) => state.family_composition_future)
        );
    }
}
