import { CountryInterface } from '../interfaces/country.interface';

export interface CountriesState {
    countries: CountryInterface[];
}

export const COUNTRIES_FEATURE = 'countries';

export const INITIAL_COUNTRIES_STATE: CountriesState = {
    countries: [],
};
