import { ApiClaimCoverageProcedureExplanationResponseInterface } from '../interfaces/api.claim-coverage-procedure-explanation-response.interface';
import { ClaimCoverageProcedureExplanationResponseInterface } from '../interfaces/claim-coverage-procedure-explanation-response.interface';

export function mapProcedureExplanationResponse(
    response: ApiClaimCoverageProcedureExplanationResponseInterface[]
): ClaimCoverageProcedureExplanationResponseInterface[] {
    if (!response) {
        return [];
    }

    return response.map((responseItem) => ({
        order: responseItem.order,
        positionProcedureExplanationCode: responseItem.positie_procedure_uitleg_code,
        positionProcedureExplanationDescription: responseItem.positie_procedure_uitleg_omschrijving,
        procedureExplanationCode: responseItem.procedure_explanation_code,
        procedureExplanationDescription: responseItem.procedure_explanation_description,
        procedureTypeCode: responseItem.procedure_type_code,
        procedureTypeDescription: responseItem.procedure_type_description,
    }));
}
