import { mapFromSuccessIndication } from '../../core';
import { ApiDamageTypeResponse, DamageTypeInterface } from '../interfaces';

export function mapDamageTypesResponse(response: ApiDamageTypeResponse[]): DamageTypeInterface[] {
    const damageTypes: DamageTypeInterface[] = [];

    if (!response) {
        return damageTypes;
    }

    response.forEach((response) =>
        damageTypes.push({
            damageTypeId: response.kind_of_loss_id,
            damageTypeDescription: response.kind_of_loss_description,
            visibleIndication: mapFromSuccessIndication(response.visible_indication),
            visibleIndicationDescription: response.visible_indication_description,
            information: response.information,
        })
    );

    return damageTypes;
}
