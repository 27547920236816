/** Incomplete: We only note the relevant and most common codes */
export enum HttpStatusEnum {
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    Unauthorized = 401, // Not authenticated (we don't know who you are)
    Forbidden = 403, // Not authorized (we know who you are but you're not allowed in)
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    TimeOut = 408,
    IAmATeapot = 418,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
}
