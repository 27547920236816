/**
 * @deprecated - use insurances enums
 */
export enum ProductNameEnum {
    AANS = 'aansprakelijkheid',
    BROM = 'bromfiets',
    CVAN = 'caravan',
    DIER = 'huisdier',
    FIET = 'fiets',
    ONGV = 'ongevallen',
    MOTR = 'motor',
    RB = 'rechtsbijstand',
    WOON = 'woonhuis',
    AUTO = 'auto',
    REIS = 'reis',
}
