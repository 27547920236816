import { createReducer, on } from '@ngrx/store';
import * as InsuranceInfoCheckActions from './insurance-info-check.actions';
import { INSURANCE_INFO_CHECK_INITIAL_STATE } from './insurance-info-check.state';

export const insuranceInfoCheckReducer = createReducer(
    INSURANCE_INFO_CHECK_INITIAL_STATE,
    on(InsuranceInfoCheckActions.getInsuranceInfoCheckSuccess, (state, { response }) => ({
        ...state,
        insuranceInfoCheck: response,
    })),
    on(InsuranceInfoCheckActions.clearInsuranceInfoCheckInfo, (state) => ({
        ...state,
        insuranceInfoCheck: null,
    }))
);
