import { ValidationElementsInterface } from '../interfaces';

export interface Validation {
    validation: ValidationState;
}

export interface ValidationState {
    validationElements: ValidationElementsInterface;
}

export const INITIAL_VALIDATION_STATE: ValidationState = {
    validationElements: {},
};

export const VALIDATION_FEATURE = 'validation';
