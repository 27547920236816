import { mapSequenceNumber } from '../../../core/mappers';
import { ApiEndRegularDriverRequest, EndRegularDriverRequestInterface } from '../../interfaces';

export function mapEndRegularDriverRequest(request: EndRegularDriverRequestInterface): ApiEndRegularDriverRequest {
    return {
        non_regular_driver: {
            end_date: request.endDate,
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
