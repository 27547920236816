import { Action } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { ProductEnGbInterface } from '../../my-zone';
import {
    CreateBankDetailsInterface,
    CreateDutchMailingAddressRequestInterface,
    CreateMailingAddressRequestInterface,
    CreateRelationRecoveryDataRequestInterface,
    CreateResidentialAddressInterface,
    CreateResidentialAddressResponseInterface,
    EditAccountCredentialsInterface,
    GetPremiumModificationInterface,
    ModifyFamilyCompositionInterface,
    RelationInterface,
    SaveOptOutInterface, StoreResidentialAddressInterface,
    TerminateActiveMailingAddressRequestInterface,
    TokenRelationOptOutInterface,
    UpdatePasswordRequestInterface,
    UpdatePersonalDetailsInterface,
} from '../interfaces';

/* Consider updating this file to new eslint standards */

export enum RelationActionEnum {
    UpdateRelation = '[Relation] Update relation',
    UpdateRelationSuccess = '[Relation] Update relation success',
    UpdateRelationError = '[Relation] Update relation error',

    GetRelation = '[Relation] Get Relation',
    RequestRelation = '[Relation] Request Relation',
    RelationAvailable = '[Relation] Get Relation Success',
    RequestRelationError = '[Relation] Get Relation Error',

    UpdatePassword = '[Relation] Update password',
    UpdatePasswordSuccess = '[Relation] Update password success',
    UpdatePasswordError = '[Relation] Update password error',

    SaveOptOut = '[Relation] Save opt-out',
    SaveOptOutSuccess = '[Relation] Save opt-out success',
    SaveOptOutError = '[Relation] Save opt-out error',

    UpdatePrimaryEmail = '[Relation] Update primary email',
    UpdatePrimaryEmailSuccess = '[Relation] Update primary email success',
    UpdatePrimaryEmailError = '[Relation] Update primary email error',

    AddMailingAddress = '[Relation] Add mailing address',
    AddMailingAddressSuccess = '[Relation] Add mailing address success',
    AddMailingAddressError = '[Relation] Add mailing address error',

    AddDutchMailingAddress = '[Relation] Add dutch mailing address',
    AddDutchMailingAddressSuccess = '[Relation] Add dutch mailing address success',
    AddDutchMailingAddressError = '[Relation] Add dutch mailing address error',

    CancelFutureMailingAddress = '[Relation] Cancel future mailing address',
    CancelFutureMailingAddressSuccess = '[Relation] Cancel future mailing address success',
    CancelFutureMailingAddressError = '[Relation] Cancel future mailing address error',

    TerminateActiveMailingAddress = '[Relation] Terminate active mailing address',
    TerminateActiveMailingAddressSuccess = '[Relation] Terminate active mailing address success',
    TerminateActiveMailingAddressError = '[Relation] Terminate active mailing address error',

    ReviveTerminatedMailingAddress = '[Relation] Revive terminated mailing address',
    ReviveTerminatedMailingAddressSuccess = '[Relation] Revive terminated mailing address success',
    ReviveTerminatedMailingAddressError = '[Relation] Revive terminated mailing address error',

    UpdateFamilyComposition = '[Relation] Update family composition',
    UpdateFamilyCompositionSuccess = '[Relation] Update family composition success',
    UpdateFamilyCompositionError = '[Relation] Update family composition error',

    UpdatePersonalDetails = '[Relation] Update personal details',
    UpdatePersonalDetailsSuccess = '[Relation] Update personal details success',
    UpdatePersonalDetailsError = '[Relation] Update personal details error',

    SaveEmailPreferences = '[Relation] Save email preferences',
    SaveEmailPreferencesSuccess = '[Relation] Save email preferences success',
    SaveEmailPreferencesError = '[Relation] Save email preferences error',

    CreateBankDetails = '[Relation] create bank details',
    CreateBankDetailsSuccess = '[Relation] create bank details success',
    CreateBankDetailsError = '[Relation] create bank details error',

    ModifyFamilyComposition = '[Relation] modify family composition',
    ModifyFamilyCompositionSuccess = '[Relation] modify family composition success',
    ModifyFamilyCompositionError = '[Relation] modify family composition error',

    CancelFamilyComposition = '[Relation] cancel family composition',
    CancelFamilyCompositionSuccess = '[Relation] cancel family composition success',
    CancelFamilyCompositionError = '[Relation] cancel family composition error',

    PremiumModificationPurchasedProducts = '[Relation] get premium purchased products',
    PremiumModificationPurchasedProductsSuccess = '[Relation] get premium purchased products success',
    PremiumModificationPurchasedProductsError = '[Relation] get premium purchased products error',

    EditAccountCredentials = '[Relation] edit account credentials',
    EditAccountCredentialsSuccess = '[Relation] edit account credentials success',
    EditAccountCredentialsError = '[Relation] edit account credentials error',

    CreateResidentialAddress = '[Relation] create residential address',
    CreateResidentialAddressSuccess = '[Relation] create residential address success',
    CreateResidentialAddressError = '[Relation] create residential address error',

    StoreResidentialAddress = '[Relation] store residential address',
    StoreResidentialAddressSuccess = '[Relation] store residential address success',
    StoreResidentialAddressError = '[Relation] store residential address error',

    CancelResidentialAddress = '[Relation] cancel residential address',
    CancelResidentialAddressSuccess = '[Relation] cancel residential address success',
    CancelResidentialAddressError = '[Relation] cancel residential address error',

    SaveTokenRelationOptOut = '[Relation] Save token relation opt out',
    SaveTokenRelationOptOutSuccess = '[Relation] Save token relation opt out success',
    SaveTokenRelationOptOutError = '[Relation] Save token relation opt out error',

    CreateRelationRecoveryData = '[Relation] Create relation recovery data',
    CreateRelationRecoveryDataSuccess = '[Relation] Create relation recovery data success',
    CreateRelationRecoveryDataError = '[Relation] Create relation recovery data error',

    Reset = '[Relation] Reset',
}

export class GetRelationAction implements Action {
    public readonly type = RelationActionEnum.GetRelation;

    // Sometimes we already have the relation (coming from authentication module)
    public constructor(public payload: { relation?: RelationInterface }) {}
}

export class RequestRelationAction implements Action {
    public readonly type = RelationActionEnum.RequestRelation;
}

export class RequestRelationError implements Action {
    public readonly type = RelationActionEnum.RequestRelationError;
}

export class RelationAvailableAction implements Action {
    public readonly type = RelationActionEnum.RelationAvailable;

    constructor(public payload: { relation: RelationInterface }) {}
}

export class UpdateRelationAction implements Action {
    public readonly type = RelationActionEnum.UpdateRelation;

    constructor(public payload: { relation: RelationInterface }) {}
}

export class UpdateRelationSuccessAction implements Action {
    public readonly type = RelationActionEnum.UpdateRelationSuccess;

    constructor(public payload: { relation: RelationInterface }) {}
}

export class UpdateRelationErrorAction implements Action {
    public readonly type = RelationActionEnum.UpdateRelationError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class UpdatePasswordAction implements Action {
    public readonly type = RelationActionEnum.UpdatePassword;

    constructor(public payload: { request: UpdatePasswordRequestInterface }) {}
}

export class UpdatePasswordSuccessAction implements Action {
    public readonly type = RelationActionEnum.UpdatePasswordSuccess;
}

export class UpdatePasswordErrorAction implements Action {
    public readonly type = RelationActionEnum.UpdatePasswordError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class UpdatePrimaryEmailAction implements Action {
    public readonly type = RelationActionEnum.UpdatePrimaryEmail;

    constructor(public payload: { email: string; currentPassword: string }) {}
}

export class UpdatePrimaryEmailSuccessAction implements Action {
    public readonly type = RelationActionEnum.UpdatePrimaryEmailSuccess;

    constructor(public payload: { email: string }) {}
}

export class UpdatePrimaryEmailErrorAction implements Action {
    public readonly type = RelationActionEnum.UpdatePrimaryEmailError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class AddMailingAddressAction implements Action {
    public readonly type = RelationActionEnum.AddMailingAddress;

    constructor(public payload: { request: CreateMailingAddressRequestInterface }) {}
}

export class AddMailingAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.AddMailingAddressSuccess;
}

export class AddMailingAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.AddMailingAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class AddDutchMailingAddressAction implements Action {
    public readonly type = RelationActionEnum.AddDutchMailingAddress;

    constructor(public payload: { request: CreateDutchMailingAddressRequestInterface }) {}
}

export class AddDutchMailingAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.AddDutchMailingAddressSuccess;
}

export class AddDutchMailingAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.AddDutchMailingAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class CancelFutureMailingAddressAction implements Action {
    public readonly type = RelationActionEnum.CancelFutureMailingAddress;
}

export class CancelFutureMailingAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.CancelFutureMailingAddressSuccess;
}

export class CancelFutureMailingAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.CancelFutureMailingAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class TerminateActiveMailingAddressAction implements Action {
    public readonly type = RelationActionEnum.TerminateActiveMailingAddress;

    constructor(public payload: { request: TerminateActiveMailingAddressRequestInterface }) {}
}

export class TerminateActiveMailingAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.TerminateActiveMailingAddressSuccess;
}

export class TerminateActiveMailingAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.TerminateActiveMailingAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class ReviveTerminatedMailingAddressAction implements Action {
    public readonly type = RelationActionEnum.ReviveTerminatedMailingAddress;
}

export class ReviveTerminatedMailingAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.ReviveTerminatedMailingAddressSuccess;
}

export class ReviveTerminatedMailingAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.ReviveTerminatedMailingAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class UpdateFamilyCompositionAction implements Action {
    public readonly type = RelationActionEnum.UpdateFamilyComposition;

    constructor(public payload: { request: ModifyFamilyCompositionInterface }) {}
}

export class UpdateFamilyCompositionSuccessAction implements Action {
    public readonly type = RelationActionEnum.UpdateFamilyCompositionSuccess;
}

export class UpdateFamilyCompositionErrorAction implements Action {
    public readonly type = RelationActionEnum.UpdateFamilyCompositionError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class UpdatePersonalDetailsAction implements Action {
    public readonly type = RelationActionEnum.UpdatePersonalDetails;

    constructor(public payload: { request: UpdatePersonalDetailsInterface }) {}
}

export class UpdatePersonalDetailsSuccessAction implements Action {
    public readonly type = RelationActionEnum.UpdatePersonalDetailsSuccess;
}

export class UpdatePersonalDetailsErrorAction implements Action {
    public readonly type = RelationActionEnum.UpdatePersonalDetailsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class RelationResetAction implements Action {
    public readonly type = RelationActionEnum.Reset;
}

export class SaveOptOutAction implements Action {
    public readonly type = RelationActionEnum.SaveOptOut;

    constructor(public payload: { request: SaveOptOutInterface }) {}
}

export class SaveOptOutSuccessAction implements Action {
    public readonly type = RelationActionEnum.SaveOptOutSuccess;

    constructor(public payload: { optOut: boolean }) {}
}

export class SaveOptOutErrorAction implements Action {
    public readonly type = RelationActionEnum.SaveOptOutError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class CreateBankDetailsAction implements Action {
    public readonly type = RelationActionEnum.CreateBankDetails;

    constructor(public payload: { request: CreateBankDetailsInterface }) {}
}

export class CreateBankDetailsSuccessAction implements Action {
    public readonly type = RelationActionEnum.CreateBankDetailsSuccess;
}

export class CreateBankDetailsErrorAction implements Action {
    public readonly type = RelationActionEnum.CreateBankDetailsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class ModifyFamilyCompositionAction implements Action {
    public readonly type = RelationActionEnum.ModifyFamilyComposition;

    constructor(public payload: { request: ModifyFamilyCompositionInterface }) {}
}

export class ModifyFamilyCompositionSuccessAction implements Action {
    public readonly type = RelationActionEnum.ModifyFamilyCompositionSuccess;

    constructor(public payload: { notifications: ErrorInterface[] }) {}
}

export class ModifyFamilyCompositionErrorAction implements Action {
    public readonly type = RelationActionEnum.ModifyFamilyCompositionError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class CancelFamilyCompositionAction implements Action {
    public readonly type = RelationActionEnum.CancelFamilyComposition;
}

export class CancelFamilyCompositionSuccessAction implements Action {
    public readonly type = RelationActionEnum.CancelFamilyCompositionSuccess;
}

export class CancelFamilyCompositionErrorAction implements Action {
    public readonly type = RelationActionEnum.CancelFamilyCompositionError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class EditAccountCredentialsAction implements Action {
    public readonly type = RelationActionEnum.EditAccountCredentials;

    constructor(public payload: { request: EditAccountCredentialsInterface }) {}
}

export class EditAccountCredentialsSuccessAction implements Action {
    public readonly type = RelationActionEnum.EditAccountCredentialsSuccess;
}

export class EditAccountCredentialsErrorAction implements Action {
    public readonly type = RelationActionEnum.EditAccountCredentialsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class PremiumModificationPurchasedProductsAction implements Action {
    public readonly type = RelationActionEnum.PremiumModificationPurchasedProducts;

    constructor(public payload: { request: GetPremiumModificationInterface }) {}
}

export class PremiumModificationPurchasedProductsSuccessAction implements Action {
    public readonly type = RelationActionEnum.PremiumModificationPurchasedProductsSuccess;

    constructor(public payload: { purchasedProducts: ProductEnGbInterface[] }) {}
}

export class PremiumModificationPurchasedProductsErrorAction implements Action {
    public readonly type = RelationActionEnum.PremiumModificationPurchasedProductsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class CreateResidentialAddressAction implements Action {
    public readonly type = RelationActionEnum.CreateResidentialAddress;

    constructor(public payload: { request: CreateResidentialAddressInterface }) {}
}

export class CreateResidentialAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.CreateResidentialAddressSuccess;

    constructor(public payload: { response: CreateResidentialAddressResponseInterface }) {}
}

export class CreateResidentialAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.CreateResidentialAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class StoreResidentialAddressAction implements Action {
    public readonly type = RelationActionEnum.StoreResidentialAddress;

    constructor(public payload: { request: StoreResidentialAddressInterface }) {}
}

export class StoreResidentialAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.StoreResidentialAddressSuccess;
}

export class StoreResidentialAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.StoreResidentialAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class CancelResidentialAddressAction implements Action {
    public readonly type = RelationActionEnum.CancelResidentialAddress;
}

export class CancelResidentialAddressSuccessAction implements Action {
    public readonly type = RelationActionEnum.CancelResidentialAddressSuccess;
}

export class CancelResidentialAddressErrorAction implements Action {
    public readonly type = RelationActionEnum.CancelResidentialAddressError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class SaveTokenRelationOptOutAction implements Action {
    public readonly type = RelationActionEnum.SaveTokenRelationOptOut;

    constructor(public payload: { request: TokenRelationOptOutInterface }) {}
}

export class SaveTokenRelationOptOutSuccessAction implements Action {
    public readonly type = RelationActionEnum.SaveTokenRelationOptOutSuccess;
}

export class SaveTokenRelationOptOutErrorAction implements Action {
    public readonly type = RelationActionEnum.SaveTokenRelationOptOutError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class CreateRelationRecoveryDataAction implements Action {
    public readonly type = RelationActionEnum.CreateRelationRecoveryData;

    constructor(public payload: CreateRelationRecoveryDataRequestInterface) {}
}

export class CreateRelationRecoveryDataSuccessAction implements Action {
    public readonly type = RelationActionEnum.CreateRelationRecoveryDataSuccess;

    constructor(public payload: RelationInterface) {}
}

export class CreateRelationRecoveryDataErrorAction implements Action {
    public readonly type = RelationActionEnum.CreateRelationRecoveryDataError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export type RelationActionType =
    | GetRelationAction
    | RelationAvailableAction
    | RequestRelationAction
    | RequestRelationError
    | UpdatePasswordAction
    | UpdatePasswordSuccessAction
    | UpdatePasswordErrorAction
    | UpdatePrimaryEmailAction
    | UpdatePrimaryEmailSuccessAction
    | UpdatePrimaryEmailErrorAction
    | UpdateRelationAction
    | UpdateRelationSuccessAction
    | UpdateRelationErrorAction
    | AddMailingAddressAction
    | AddMailingAddressSuccessAction
    | AddMailingAddressErrorAction
    | CancelFutureMailingAddressAction
    | CancelFutureMailingAddressSuccessAction
    | CancelFutureMailingAddressErrorAction
    | RelationResetAction
    | UpdatePersonalDetailsAction
    | SaveOptOutAction
    | SaveOptOutSuccessAction
    | SaveOptOutErrorAction
    | CreateBankDetailsAction
    | CreateBankDetailsSuccessAction
    | CreateBankDetailsErrorAction
    | ModifyFamilyCompositionAction
    | ModifyFamilyCompositionSuccessAction
    | ModifyFamilyCompositionErrorAction
    | CancelFamilyCompositionAction
    | CancelFamilyCompositionSuccessAction
    | CancelFamilyCompositionErrorAction
    | PremiumModificationPurchasedProductsAction
    | PremiumModificationPurchasedProductsSuccessAction
    | PremiumModificationPurchasedProductsErrorAction
    | EditAccountCredentialsAction
    | EditAccountCredentialsSuccessAction
    | EditAccountCredentialsErrorAction
    | CreateResidentialAddressAction
    | CreateResidentialAddressSuccessAction
    | CreateResidentialAddressErrorAction
    | CancelResidentialAddressAction
    | CancelResidentialAddressSuccessAction
    | CancelResidentialAddressErrorAction
    | SaveTokenRelationOptOutAction
    | SaveTokenRelationOptOutSuccessAction
    | SaveTokenRelationOptOutErrorAction
    | CreateRelationRecoveryDataAction
    | CreateRelationRecoveryDataSuccessAction
    | CreateRelationRecoveryDataErrorAction;
