import { ChatbotFaqList } from '../interfaces';
import { ApiChatbotAnswerInterface, ApiChatbotFaqInterface, ApiChatbotResponseInterface } from '../interfaces/api';

export function mapFaqListResponse(response: ApiChatbotResponseInterface): ChatbotFaqList {
    const resultList = response.outputs[0].outputParts.find(
        (outputPart: ApiChatbotAnswerInterface) => outputPart.type === 'relatedFAQs'
    )?.faQs;
    return {
        interactionId: response.interactionId,
        sessionId: response.sessionId,
        questions:
            resultList?.map((item: ApiChatbotFaqInterface) => ({
                id: item.id,
                question: item.question,
            })) || [],
    };
}
