import { ApiValidityStatusCodeEnum, PolicyValidityStatusCodeEnum } from '../../enums';

export function mapValidityStatusCodeResponse(response: ApiValidityStatusCodeEnum): PolicyValidityStatusCodeEnum {
    if (!response) {
        return PolicyValidityStatusCodeEnum.Unknown;
    }

    switch (response) {
    case ApiValidityStatusCodeEnum.All:
        return PolicyValidityStatusCodeEnum.All;
    case ApiValidityStatusCodeEnum.Active:
        return PolicyValidityStatusCodeEnum.Active;
    case ApiValidityStatusCodeEnum.ActiveEnded:
        return PolicyValidityStatusCodeEnum.ActiveEnded;
    case ApiValidityStatusCodeEnum.ActiveChange:
        return PolicyValidityStatusCodeEnum.ActiveChange;
    case ApiValidityStatusCodeEnum.Future:
        return PolicyValidityStatusCodeEnum.Future;
    case ApiValidityStatusCodeEnum.Transfer:
        return PolicyValidityStatusCodeEnum.Transfer;
    case ApiValidityStatusCodeEnum.Disbarred:
        return PolicyValidityStatusCodeEnum.Disbarred;
    case ApiValidityStatusCodeEnum.Ended:
        return PolicyValidityStatusCodeEnum.Ended;
    default:
        return PolicyValidityStatusCodeEnum.Unknown;
    }
}

export function mapValidityStatusCodeRequest(response: PolicyValidityStatusCodeEnum): ApiValidityStatusCodeEnum | null {
    if (!response) {
        return null;
    }

    switch (response) {
    case PolicyValidityStatusCodeEnum.All:
        return ApiValidityStatusCodeEnum.All;
    case PolicyValidityStatusCodeEnum.Active:
        return ApiValidityStatusCodeEnum.Active;
    case PolicyValidityStatusCodeEnum.ActiveEnded:
        return ApiValidityStatusCodeEnum.ActiveEnded;
    case PolicyValidityStatusCodeEnum.ActiveChange:
        return ApiValidityStatusCodeEnum.ActiveChange;
    case PolicyValidityStatusCodeEnum.Future:
        return ApiValidityStatusCodeEnum.Future;
    case PolicyValidityStatusCodeEnum.Transfer:
        return ApiValidityStatusCodeEnum.Transfer;
    case PolicyValidityStatusCodeEnum.Disbarred:
        return ApiValidityStatusCodeEnum.Disbarred;
    case PolicyValidityStatusCodeEnum.Ended:
        return ApiValidityStatusCodeEnum.Ended;
    }
}
