import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationInterface, RequestResponse, RequestService } from '../../../core';
import {
    Coverage,
    Customer,
    Insurance,
    NewRelationPayloadUnion,
    ReturningRelationPayloadUnion,
    StackDiscountDescription,
} from '../../interfaces';
import * as fromSales from '../sales.reducers';
import { SaveStackDiscount } from '../stack-discount/stack-discount.actions';
import { EligibilityService } from './eligibility.service';
import {
    newRelationBicycleRequest,
    newRelationCarRequest,
    returningRelationBicycleRequest,
    returningRelationCarRequest,
} from './helpers';
import * as fromInsurance from './insurance.selectors';

type PayloadUnion = NewRelationPayloadUnion | ReturningRelationPayloadUnion;

interface InsuranceInput {
    coverages?: Coverage[];
    insurance?: Insurance;
    customer?: Customer;
    productId: string;
}

interface ValidateEligibilityInput extends InsuranceInput {
    validationStepCode: string;
    index: number;
    isReturningCustomer: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class InsuranceService {
    public currentInsuranceData$: Observable<Insurance> = this.store$.
        select((fromInsurance.getCurrentInsuranceData)
        );

    public campaignCode$: Observable<string> = this.store$.select((fromInsurance.getInsuranceCampaignCode));

    public loading$: Observable<boolean> = this.store$.select((fromInsurance.isLoading));

    public errors$: Observable<NotificationInterface[]> = this.store$.select((fromInsurance.getErrors));

    constructor(
        private requestService: RequestService,
        private store$: Store<fromSales.State>,
        private eligibilityService: EligibilityService
    ) {}

    public validateEligibility({
        coverages = [],
        insurance,
        customer,
        productId,
        validationStepCode,
        index,
        isReturningCustomer,
    }: ValidateEligibilityInput): Observable<RequestResponse> {
        const elements = this.eligibilityService.getElements(productId, index, {
            coverages,
            insurance,
            customer,
            isReturningCustomer,
        });

        return this.requestService.post('en-gb/form-general/validate/eligibility_criteria', {
            product_id: productId,
            validation_step_code: validationStepCode,
            elements,
        });
    }

    public requestInsurance({
        coverages = [],
        insurance,
        customer,
        productId,
    }: InsuranceInput): Observable<RequestResponse> {
        switch (productId) {
        case 'AUTO': {
            return this.requestInsuranceHandler(newRelationCarRequest(coverages, insurance, customer));
        }
        case 'FIET': {
            return this.requestInsuranceHandler(newRelationBicycleRequest(coverages, insurance, customer));
        }
        }
    }

    public requestExtraInsurance({
        coverages = [],
        insurance,
        customer,
        productId,
    }: InsuranceInput): Observable<RequestResponse> {
        switch (productId) {
        case 'AUTO': {
            return this.requestInsuranceHandler(returningRelationCarRequest(coverages, insurance, customer));
        }
        case 'FIET': {
            return this.requestInsuranceHandler(returningRelationBicycleRequest(coverages, insurance, customer));
        }
        }
    }

    private requestInsuranceHandler({
        url,
        payload,
    }: {
        url: string;
        payload: PayloadUnion;
    }): Observable<RequestResponse> {
        return this.requestService.post(url, payload).pipe(
            map((response) => {
                if (response.data.success === false) {
                    throw response.messages;
                }
                if (response.data.stack_discount) {
                    this.saveStackDiscountData(response.data.stack_discount);
                }
                return response;
            })
        );
    }

    private saveStackDiscountData(stackDiscount: StackDiscountDescription): void {
        this.store$.dispatch(new SaveStackDiscount({ stack_discount: stackDiscount }));
    }
}
