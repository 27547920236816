import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetClaimProcessStepsRequestInterface, GetClaimProcessStepsResponseInterface } from '../interfaces';
import { ClaimActionsApiService } from './claim-actions.api.service';

@Injectable({
    providedIn: 'root',
})
export class ClaimActionsActionService {
    constructor(private claimActionsApiService: ClaimActionsApiService) {}

    public getClaimProcessSteps$(
        request: GetClaimProcessStepsRequestInterface
    ): Observable<GetClaimProcessStepsResponseInterface> {
        return this.claimActionsApiService.getClaimProcessSteps$(request);
    }
}
