import { Inject, Injectable, Optional } from '@angular/core';
import { RELATION_CONFIGURATION } from '../configuration';
import { LegacyUserInterface, RelationConfigurationInterface, RelationInterface } from '../interfaces';
import { mapToLegacyUser } from '../mappers';

@Injectable({
    providedIn: 'root',
})
export class LegacyRelationStorageService {
    constructor(@Optional() @Inject(RELATION_CONFIGURATION) private configuration: RelationConfigurationInterface) {}

    /** @deprecated Legacy for angular.js */
    public hasLegacyUser(): boolean {
        if (!this.configuration) {
            return false;
        }
        const legacyUserJson = localStorage.getItem(this.configuration.userLegacyLocalStorageKey);

        return legacyUserJson !== null;
    }

    /** @deprecated Legacy for angular.js */
    public storeLegacyUser(relation: RelationInterface): void {
        if (!this.configuration) {
            return;
        }
        const user: LegacyUserInterface = mapToLegacyUser(relation);

        localStorage.setItem(this.configuration.userLegacyLocalStorageKey, JSON.stringify(user));

        if (this.configuration.sessionLegacyLocalStorageKey) {
            const session = {
                authenticated: true,
                loaded: true,
                loading: false,
                retrieved: false,
                user: user,
            };
            localStorage.setItem(this.configuration.sessionLegacyLocalStorageKey, JSON.stringify(session));
        }
    }

    public clear(): void {
        if (!this.configuration) {
            return;
        }
        localStorage.removeItem(this.configuration.userLegacyLocalStorageKey);
    }
}
