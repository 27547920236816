import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimNotificationEffects } from './store/claim-notification.effects';
import { ClaimNotificationReducers } from './store/claim-notification.reducers';
import { CLAIM_NOTIFICATION_FEATURE } from './store/claim-notification.state';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_NOTIFICATION_FEATURE, ClaimNotificationReducers.reducer),
        EffectsModule.forFeature([ClaimNotificationEffects]),
    ],
})
export class OutClaimNotificationModule {}
