import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class ClaimCoverageEventService {
    private _getClaimCompensation$: Subject<void>;
    private _getClaimCoverage$: Subject<void>;
    private _saveClaimCoverage$: Subject<void>;

    public getClaimCompensation$(): Observable<void> {
        this._getClaimCompensation$ = new Subject<void>();
        return this._getClaimCompensation$.asObservable();
    }

    public onGetClaimCompensationSuccess(): void {
        this._getClaimCompensation$.next();
        this._getClaimCompensation$.complete();
    }

    public onGetClaimCompensationError(errors: ErrorInterface[]): void {
        this._getClaimCompensation$.error(errors);
    }

    public getClaimCoverage$(): Observable<void> {
        this._getClaimCoverage$ = new Subject<void>();
        return this._getClaimCoverage$.asObservable();
    }

    public onGetClaimCoverageSuccess(): void {
        this._getClaimCoverage$.next();
        this._getClaimCoverage$.complete();
    }

    public onGetClaimCoverageError(errors: ErrorInterface[]): void {
        this._getClaimCoverage$.error(errors);
    }

    public saveClaimCoverage$(): Observable<void> {
        this._saveClaimCoverage$ = new Subject<void>();
        return this._saveClaimCoverage$.asObservable();
    }

    public onSaveClaimCoverageSuccess(): void {
        this._saveClaimCoverage$.next();
        this._saveClaimCoverage$.complete();
    }

    public onSaveClaimCoverageError(errors: ErrorInterface[]): void {
        this._saveClaimCoverage$.error(errors);
    }
}
