import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CHATBOT_CONFIGURATION } from '../configuration';
import {
    ChatbotAnswerInterface,
    ChatbotFaqList,
    ChatbotConfigurationInterface,
    LivechatStatusInterface,
} from '../interfaces';
import { ApiChatbotRequestInterface } from '../interfaces/api';
import { ChatbotFeedbackInterface } from '../interfaces/chatbot-feedback.interface';
import {
    mapFaqAnswerRequest,
    mapFaqListRequest,
    mapFaqListResponse,
    mapFeedbackRequest,
    mapQuestionRequest,
    mapQuestionResponse,
} from '../mappers';
import { mapFeedbackResponse } from '../mappers/feedback.response.mapper';
import { OutLivechatService } from './livechat.service';

@Injectable()
export class OutChatbotService {
    constructor(
        private http: HttpClient,
        private livechatService: OutLivechatService,
        @Inject(CHATBOT_CONFIGURATION) private configuration: ChatbotConfigurationInterface
    ) {}

    public askQuestion$(question: string, sessionId?: string, dialogPath?: string): Observable<ChatbotAnswerInterface> {
        const url: string = `${this.configuration.baseUrl}/ask`;
        const request: Partial<ApiChatbotRequestInterface> = mapQuestionRequest(
            this.configuration,
            question,
            sessionId,
            dialogPath
        );

        return this.http.get(url, { params: new HttpParams({ fromObject: request }) }).pipe(
            map(mapQuestionResponse),
            switchMap((answer: ChatbotAnswerInterface): Observable<ChatbotAnswerInterface> => {
                // This should be split whenever both NL/DE both use livechat
                if (answer.escalation?.showChat || answer.escalation?.showChatDE) {
                    return this.livechatService.getStatus$().pipe(
                        map((status: LivechatStatusInterface): ChatbotAnswerInterface => {
                            return {
                                ...answer,
                                escalation: {
                                    ...answer.escalation,
                                    showChat: status && status.isAvailable && status.availableAgents > 0,
                                    showChatDE: status && status.isAvailable && status.availableAgents > 0,

                                },
                            } as ChatbotAnswerInterface;
                        })
                    );
                }
                return of(answer);
            })
        );
    }

    public getFaqList$(
        sessionId: string | undefined,
        classificationId: number,
        classificationName: string,
        step: number
    ): Observable<ChatbotFaqList> {
        const url: string = `${this.configuration.baseUrl}/event/Escalation_${classificationName}_Funnelstep${step}`;
        const request: Partial<ApiChatbotRequestInterface> = mapFaqListRequest(
            this.configuration,
            classificationId,
            classificationName,
            step,
            sessionId,
            'test'
        );
        return this.http.get(url, { params: new HttpParams({ fromObject: request }) }).pipe(map(mapFaqListResponse));
    }

    public getFaqAnswer$(faqId: number, sessionId?: string): Observable<ChatbotAnswerInterface> {
        const url: string = `${this.configuration.baseUrl}/faqs/${faqId}/output`;
        const request: Partial<ApiChatbotRequestInterface> = mapFaqAnswerRequest(this.configuration, faqId, sessionId);
        return this.http.get(url, { params: new HttpParams({ fromObject: request }) }).pipe(map(mapQuestionResponse));
    }

    public sendFeedback$(
        sessionId: string,
        interactionId: string,
        score: number,
        feedback?: string,
        q?: string
    ): Observable<ChatbotFeedbackInterface> {
        const url = `${this.configuration.baseUrl}/feedback`;
        const request: Partial<ApiChatbotRequestInterface> = mapFeedbackRequest(
            this.configuration,
            sessionId,
            interactionId,
            score,
            feedback,
            q
        );
        return this.http.get(url, { params: new HttpParams({ fromObject: request }) }).pipe(map(mapFeedbackResponse));
    }
}
