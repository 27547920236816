export enum ApiPremiumDeterminingFactorIdEnum {
    DamageFreeYears = 'SVJR', // Schadevrije jaren;
    BonusMalusStep = 'BMTR', // BM trede
    KilometerPerYearCar = 'KILA', // Kilometers per jaar auto
    PostalcodeRateMoped = 'PTBR', // Postcode tarief bromfiets
    FuelMoped = 'BRSB', // Brandstof Bromfiets
    HailroofIndication = 'HDAK', // Hageldak indicatie
    KilometerPerYearMotor = 'KILM', // Kilometers per jaar
    Content = 'INHD', // Inhoud
    Strawroofing = 'RIET', // Dak met riet
    MonumentCode = 'MONC', // Monument code
    BuildYear = 'BWJR', // Bouwjaar
    OwnRisk = 'EIGR', // eigen risico
    HomeType = 'TWHS', // Type woonhuis
    UserSurface = 'GOPP', // Gebruiksoppervlakte
    Security = 'BVLG', // Beveiliging
}
