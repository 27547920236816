import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class ClaimStepsEventService {
    private _loadClaimSteps$: Subject<void>;

    public loadClaimSteps$(): Observable<void> {
        this._loadClaimSteps$ = new Subject();
        return this._loadClaimSteps$.asObservable();
    }

    public onLoadClaimStepsSuccess(): void {
        this._loadClaimSteps$.next();
        this._loadClaimSteps$.complete();
    }

    public onLoadClaimStepsError(errors: ErrorInterface[]): void {
        this._loadClaimSteps$.error(errors);
    }
}
