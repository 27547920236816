import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpStatusEnum } from '../../core';
import { Authentication, SessionExpiredAction } from '../store';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(private store$: Store<Authentication>) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === HttpStatusEnum.Unauthorized || error.status === HttpStatusEnum.Forbidden) {
                    this.store$.dispatch(new SessionExpiredAction());
                }
                return throwError(() => error);
            })
        );
    }
}
