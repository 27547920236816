import { Injectable } from '@angular/core';

/**
 * @deprecated this is a local storage service for the datalayer service.
 */
@Injectable({
    providedIn: 'root'
})
export class DatalayerLocalStorage implements Storage {
    [index: number]: string;
    [key: string]: any;

    length: number;

    constructor() { }

    public clear(): void {
        localStorage.clear();
    }

    getItem(key: string): string {
        return localStorage.getItem(key);
    }

    key(index: number): string {
        return localStorage.key(index);
    }

    removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    setItem(key: string, value: any): void {
        localStorage.setItem(key, value);
    }
}
