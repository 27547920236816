import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OutAcceptanceModule } from '../acceptance';
import { OutCoreModule } from '../core';
import { CalculationEffects } from './store/calculation/calculation.effects';
import { CoverageEffects } from './store/coverage/coverage.effects';
import { CustomerEffects } from './store/customer/customer.effects';
import { InsuranceEffects } from './store/insurance/insurance.effects';
import { salesReducers } from './store/sales.reducers';
import { VehicleReplaceEffects } from './store/vehicle-replace/vehicle-replace.effects';

// TODO : WIDL-446 : Refactor to new style

@NgModule({
    imports: [
        CommonModule,
        OutAcceptanceModule,
        OutCoreModule,
        StoreModule.forFeature('sales', salesReducers),
        EffectsModule.forFeature([
            VehicleReplaceEffects,
            InsuranceEffects,
            CalculationEffects,
            CoverageEffects,
            CustomerEffects,
        ]),
    ],
})
export class OutSalesModule {}
