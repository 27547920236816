import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Step } from '../../interfaces';
import * as insuranceActions from '../insurance/insurance.actions';
import * as progressActions from './progress.actions';

export interface State {
    productId: string;
    steps: Step[];
    current: number;
    routes: string[];
    routeIndex: number;
}

const initialState: State = {
    productId: null,
    steps: [],
    current: 0,
    routes: [],
    routeIndex: 0,
};

const stepReducer = (url: string) => {
    return (previousValue: number, step: Step): number => {
        if (step.url.indexOf(url) > -1) {
            return +step.step;
        }
        return previousValue;
    };
};

const removeParams = (url: string): string => {
    return url.split(/[;?()#]/)[0];
};

export function reducer(
    state: State = initialState,
    action: progressActions.ProgressActionsUnion | RouterNavigatedAction | insuranceActions.InsuranceActionUnion
): State {
    switch (action.type) {
        case progressActions.INIT: {
            const payload = action.payload;
            const currentUrl: string = removeParams(payload.url);
            let routeIndex: number = 0;

            const routes = payload.steps.reduce(
                (previousValue: string[], step: Step) => [...previousValue, ...step.url],
                []
            );

            if (currentUrl) {
                routeIndex = routes.indexOf(currentUrl);
            }

            return {
                ...state,
                productId: payload.productId,
                steps: payload.steps,
                current: payload.current,
                routes,
                routeIndex,
            };
        }

        case ROUTER_NAVIGATED: {
            const currentUrl = removeParams(action.payload.event.url);

            const steps = state.steps;
            const current = steps.reduce(stepReducer(currentUrl), -1);

            if (current > -1) {
                return {
                    ...state,
                    current,
                    routeIndex: state.routes.indexOf(currentUrl),
                };
            }

            return {
                ...state,
            };
        }

        case insuranceActions.CLEAR_INSURANCE_DATA: {
            return {
                ...initialState,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

export const getProductId = (state: State): State['productId'] => state.productId;
export const getSteps = (state: State): State['steps'] => state.steps;
export const getCurrent = (state: State): State['current'] => state.current;
export const getRoutes = (state: State): State['routes'] => state.routes;
export const getRouteIndex = (state: State): State['routeIndex'] => state.routeIndex;
