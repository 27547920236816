import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CountriesEffects, COUNTRIES_FEATURE, countriesReducer } from './store';

@NgModule({
    declarations: [],
    imports: [
        StoreModule.forFeature(COUNTRIES_FEATURE, countriesReducer),
        EffectsModule.forFeature([CountriesEffects]),
        CommonModule,
    ],
})
export class OutCountriesModule {}
