import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AggregateInterface } from '../interfaces';
import { AggregateSelectors } from '../store/aggregate.selectors';
import { Aggregate, AggregateState } from '../store/aggregate.state';

@Injectable({
    providedIn: 'root',
})
export class AggregateDataService {
    constructor(private store$: Store<Aggregate>) {}

    public getState$(): Observable<AggregateState> {
        return this.store$.select(AggregateSelectors.getState);
    }

    public getAggregate$(): Observable<AggregateInterface[]> {
        return this.store$.select(AggregateSelectors.getAggregate);
    }

    public getAggregateById$(id: string): Observable<AggregateInterface[]> {
        return this.store$.select(AggregateSelectors.getAggregateById(id));
    }
}
