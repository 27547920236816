import { mapSequenceNumber } from '../../../core';
import {
    ApiCancelAddedPremiumDeterminingFactorRequest,
    CancelAddedPremiumDeterminingFactorRequestInterface,
} from '../../interfaces';

export function mapCancelAddedPremiumDeterminingFactorRequest(
    request: CancelAddedPremiumDeterminingFactorRequestInterface
): ApiCancelAddedPremiumDeterminingFactorRequest {
    return {
        modifiable_premium_determining_factor: {
            premium_determining_factor_id: request.factorId,
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
