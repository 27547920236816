import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClaimAppointmentInterface, ClaimLocationInterface, ClaimTimeslotInterface } from '../interfaces';
import { ClaimAppointmentSelectors } from '../store/claim-appointment.selectors';
import { ClaimAppointmentState } from '../store/claim-appointment.state';

@Injectable({ providedIn: 'root' })
export class ClaimAppointmentDataService {
    constructor(private readonly store$: Store) {}

    public getState$(): Observable<ClaimAppointmentState> {
        return this.store$.select(ClaimAppointmentSelectors.getState);
    }

    public appointments$(): Observable<ClaimAppointmentInterface[]> {
        return this.store$.select(ClaimAppointmentSelectors.getAppointments);
    }

    public locations$(): Observable<ClaimLocationInterface[]> {
        return this.store$.select(ClaimAppointmentSelectors.getLocations);
    }

    public timeslots$(): Observable<ClaimTimeslotInterface[]> {
        return this.store$.select(ClaimAppointmentSelectors.getTimeslots);
    }
}
