import { Customer, Insurance } from '../../../interfaces';

export const newRelationPayload = (insurance: Insurance, customer: Customer) => {
    return {
        general: {
            gender_code: customer.gender_code,
            initials: customer.initials,
            middle_name_initials: customer.middle_name_initials,
            surname: customer.surname,
            birthdate: customer.birthdate,
        },
        landline_or_mobile_telephone_number: {
            telephone_number: customer.telephone_number,
        },
        primary_email_address: {
            email_address: customer.email_address,
        },
        relation_login: {
            password: customer.password,
        },
        bank_mandate: {
            account_holder_name: customer.account_holder_name,
            iban_bank_account_number: customer.iban_bank_account_number,
            direct_debit_agreement_indication: customer.direct_debit_agreement_indication,
        },
        residential_address: {
            postal_code: customer.postal_code,
            civic_number: customer.civic_number,
            civic_number_suffix: customer.civic_number_suffix,
        },
        indications: {
            criminal_record_indication: insurance.criminal_record_indication ? 'N' : 'J',
            insurance_denied_indication: insurance.insurance_denied_indication ? 'N' : 'J',
            condition_agreement_indication: insurance.condition_agreement_indication ? 'J' : 'N',
            opt_out_indication: customer.opt_out_indication ? 'J' : 'N',
            electronic_policy_agreement_indication: insurance.electronic_policy_agreement_indication ? 'J' : 'N',
        },
    };
};

export const nonRegularDriver = (insurance: Insurance) => {
    return {
        relation_code: insurance.relation_code,
        gender_code: insurance.non_regular_driver.gender_code,
        initials: insurance.non_regular_driver.initials,
        middle_name_initials: insurance.non_regular_driver.middle_name_initials,
        surname: insurance.non_regular_driver.surname,
        birthdate: insurance.non_regular_driver.birthdate,
    };
};

export const switchServiceIndication = (insurance: Insurance): string => {
    return insurance.start_date === '9999-12-31' ? 'J' : insurance.switch_service_indication;
};
