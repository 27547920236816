import { createSelector } from '@ngrx/store';
import { CoveragePremiumInterface } from '../../interfaces';
import { getMyZoneState, MyZoneState } from '../../my-zone';
import * as fromCoveragePremium from './coverage-premium.reducer';

/** @deprecated use InsurancePolicyState */
export const getCoveragePremiumState = createSelector(getMyZoneState, (state: MyZoneState) => state.coveragePremium);
/** @deprecated use InsurancePolicyState */
export const getAllCoverages = createSelector(getCoveragePremiumState, fromCoveragePremium.getAll);
/** @deprecated use InsurancePolicyState */
export const getSelected = createSelector(getCoveragePremiumState, fromCoveragePremium.getSelected);
/** @deprecated use InsurancePolicyState */
export const getTotal = createSelector(getAllCoverages, (coverages) => {
    return coverages.reduce((accumulator: number, coveragePremium: CoveragePremiumInterface) => {
        if (!coveragePremium.product.total_premium_including_discount) {
            return accumulator;
        }

        return accumulator + coveragePremium.product.total_premium_including_discount.premium_including_premium_tax;
    }, 0);
});
