import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { AuthenticationStatusEnum } from '../enums';
import { CredentialsInterface } from '../interfaces';
import { Authentication, AuthenticationState, AUTHENTICATION_FEATURE } from './authentication.state';

export class AuthenticationSelectors {
    /** Feature state selector */
    public static getAuthenticationState = createFeatureSelector<AuthenticationState>(AUTHENTICATION_FEATURE);

    /** Exposed for demo only */
    public static getState = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state
    );

    /** Exposed selectors */
    public static getStatus = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.status
    );

    public static isLoggedIn = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.status === AuthenticationStatusEnum.LoggedIn
    );

    public static sessionExpired = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.status === AuthenticationStatusEnum.SessionExpired
    );

    public static initializationPending = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.initializationPending
    );

    public static loginPending = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.loginPending
    );

    public static logoutPending = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.logoutPending
    );

    public static getCredentials: Selector<Authentication, CredentialsInterface> = createSelector(
        AuthenticationSelectors.getAuthenticationState,
        (state: AuthenticationState) => state.credentials
    );
}
