import { ApiExhibitInterface, ExhibitInterface } from '../interfaces';
import { mapExhibitsButtonsResponse } from './exhibits-button.mapper';
import { mapExhibitsDocumentResponse } from './exhibits-document.mapper';

export function mapExhibitsResponse(response: ApiExhibitInterface[]): ExhibitInterface[] {
    return response.map((exhibit: ApiExhibitInterface) => {
        return {
            additionalDocumentaryEvidenceCode: exhibit.additional_documentary_evidence_code,
            additionalDocumentaryEvidenceCodeDescription: exhibit.additional_documentary_evidence_code_description,
            additionalDocumentaryEvidenceDescription: exhibit.additional_documentary_evidence_description,
            buttons: mapExhibitsButtonsResponse(exhibit.buttons),
            caseSequenceNumber: exhibit.case_sequence_number,
            caseSequenceNumberDescription: exhibit.case_sequence_number_description,
            claimObjectDescription: exhibit.claim_object_description,
            claimObjectId: exhibit.claim_object_id,
            consultedEmployeeId: exhibit.consulted_employee_id,
            consultedOrganizationId: exhibit.consulted_organization_id,
            consultedTimestamp: exhibit.consulted_timestamp,
            createdTimestamp: exhibit.created_timestamp,
            documents: mapExhibitsDocumentResponse(exhibit.document),
            documentDescription: exhibit.document_description,
            documentId: exhibit.document_id,
            employeeId: exhibit.employee_id,
            exhibitClaimObjectId: exhibit.claim_object_id,
            exhibitSequenceNumber: exhibit.exhibit_sequence_number,
            exhibitSustainedDamageSequenceNumber: exhibit.exhibit_sustained_damage_sequence_number,
            exhibitTypeCode: exhibit.exhibit_type_code,
            exhibitTypeDescription: exhibit.exhibit_type_description,
            invalidatedEmployeeDescription: exhibit.invalidated_employee_description,
            invalidatedEmployeeId: exhibit.invalidated_employee_id,
            invalidatedOrganizationId: exhibit.invalidated_organization_id,
            invalidatedReasonCode: exhibit.invalidated_reason_code,
            invalidatedReasonDescription: exhibit.invalidated_reason_description,
            invalidatedTimestamp: exhibit.invalidated_timestamp,
            organizationDescription: exhibit.organization_description,
            organizationId: exhibit.organization_id,
            pgDescription: exhibit.pg_description,
            pgExhibit: exhibit.pg_exhibit,
            pgEvidenceDescription: exhibit.pg_bewijsstuk_omschrijving,
            pgStatusCode: exhibit.pg_status_code,
            pgStatusCodeDescription: exhibit.pg_status_code_description,
            relationId: exhibit.relation_id,
        };
    });
}
