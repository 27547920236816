/* eslint-disable rxjs/finnish */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API_CONFIGURATION } from '../configuration';
import { ApiErrorLevelEnum } from '../enums';
import {
    ApiConfigurationInterface,
    ApiError,
    ApiLegacyError,
    ApiResponse,
    NotificationInterface,
    RequestResponse,
} from '../interfaces';
import { mapLegacyErrors } from '../mappers';

/** @deprecated refactor to new ApiService */
@Injectable({
    providedIn: 'root',
})
export class RequestService {
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
        withCredentials: true,
    };

    constructor(
        private http: HttpClient,
        @Inject(API_CONFIGURATION) private configuration: ApiConfigurationInterface
    ) { }

    public post(url: string, payload?: any): Observable<RequestResponse> {
        return this.http.post(this.createUrl(url), payload, this.httpOptions).pipe(
            map((body: ApiResponse) => this.extractData(body)),
            catchError((response: HttpErrorResponse) => this.handleError(response))
        );
    }

    public notifications(url: string, payload?: any): Observable<RequestResponse> {
        return this.http.post(this.createUrl(url), payload, this.httpOptions).pipe(
            map((body: ApiResponse) => this.extractNotifications(body)),
            catchError((response: HttpErrorResponse) => this.handleError(response))
        );
    }

    public createError(messageCode: string, messageContent: string = 'Unknown error'): NotificationInterface[] {
        return [
            {
                message_content: messageContent,
                message_code: messageCode,
                item: 'uncaught_exception',
                notification_type: ApiErrorLevelEnum.Error as string,
            },
        ];
    }

    public download(url: string, payload?: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        return this.http.post('/documenten-json/' + url, payload, httpOptions).pipe(
            map(this.extractData),
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    private extractData(body): RequestResponse {
        /* istanbul ignore next */
        if (
            body.result === false ||
            body.result === null ||
            body.result?.success === false ||
            body.result?.successful_indication === 'N'
        ) {
            throw this.mapErrors(body.notifications);
        }

        return {
            data: body.result ? body.result : false,
            messages: this.mapErrors(body.notifications),
        };
    }

    private extractNotifications(body): RequestResponse {
        return {
            data: body.result ? body.result : false,
            messages: this.mapErrors(body.notifications),
        };
    }

    private handleError(response: HttpErrorResponse): Observable<never> {
        if (response === null) {
            return throwError('');
        }

        if (!response.status) {
            return throwError(response);
        }

        if (response.status !== 200) {
            return throwError(this.mapErrors(response.error.notifications));
        }

        return throwError(response);
    }

    private createUrl(url: string): string {
        return `${this.configuration.apiUrl}/${url}`;
    }

    private mapErrors(notifications?: (ApiError | ApiLegacyError)[]): NotificationInterface[] {
        if (!notifications) {
            return null;
        }
        return mapLegacyErrors(notifications);
    }
}
