export enum RelatedPartyTypeEnum {
    Contact = 'Contact',
    Counterparty = 'Counterparty',
    Witness = 'Witness',
    CoInsured = 'Co Insured',
    Driver = 'Driver',
    Other = 'Other',
}

export enum ApiRelatedPartyTypeEnum {
    Contact = 'contact_person',
    Counterparty = 'counterparty',
    Witness = 'witnesses',
    CoInsured = 'co_insured',
    Driver = 'driver',
    Other = 'other',
}
