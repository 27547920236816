import {
    ClaimObjectDetailsInterface,
    ClaimObjectElementInterface,
    ClaimObjectInterface,
    SavedClaimObjectInterface,
} from '../interfaces';

export interface ClaimObjects {
    claimObjects: ClaimObjectsState;
}

export interface ClaimObjectsState {
    claimObjects: ClaimObjectInterface[];
    claimObjectDetails: ClaimObjectDetailsInterface[];
    claimObjectElements: ClaimObjectElementInterface[];
    savedClaimObjects: SavedClaimObjectInterface[];
}

export const INITIAL_CLAIM_OBJECTS_STATE: ClaimObjectsState = {
    claimObjects: [],
    claimObjectDetails: [],
    claimObjectElements: [],
    savedClaimObjects: [],
};

export const CLAIM_OBJECTS_FEATURE = 'claimObjects';
