/* eslint-disable @angular-eslint/directive-selector */
import { AfterViewInit, Directive, ElementRef, HostBinding, OnInit, Optional } from '@angular/core';
import { NewModalRef } from './new-modal-ref';
import { getModalRef } from './new-modal-utilities';
import { NewModal } from './new-modal.service';

/**
 * Scrollable content container of a dialog.
 */
@Directive({
    selector: '[modal-content], modal-content, [modalContent]',
})
export class NewModalContentDirective implements OnInit, AfterViewInit {
    @HostBinding('class') public _class = 'modal__content';

    constructor(
        @Optional() private _modalRef: NewModalRef<any>,
        private _elementRef: ElementRef,
        private _modal: NewModal
    ) {}

    public ngOnInit(): void {
        if (!this._modalRef) {
            this._modalRef = getModalRef(this._elementRef, this._modal);
        }
    }

    public ngAfterViewInit(): void {
        if (this._modalRef) {
            if (this._modalRef._containerInstance._config.fullScreen) {
                (this._elementRef.nativeElement as HTMLDivElement).classList.add('full-screen');
            }
        }
    }
}
