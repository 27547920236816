export enum ClaimCategoryEnum {
    Liability = 'Liability',
    Car = 'Car',
    Moped = 'Moped',
    Caravan = 'Caravan',
    Bicycle = 'Bicycle',
    Pet = 'Pet',
    Motorcycle = 'Motor',
    Accidents = 'Accident',
    LegalAssistance = 'Legal Council',
    Travel = 'Travel',
    Home = 'Home',
}
