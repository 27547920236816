import { Injectable, inject } from '@angular/core';
import { CookieConsent, NotificationInterface } from '../../../core';
import { ApiDatalayerInterface } from '../../interfaces';
import { DatalayerConfigInterface } from './datalayer-config.interface';
import { DatalayerDataInterface } from './datalayer-data.interface';
import { DatalayerApiService } from './datalayer.api.service';
import { DatalayerDeprecatedService } from './datalayer.deprecated.service';
import { PolicyProductInterface } from './policy-product.interface';

/**
 * Abstract version for DatalayerService.
 *
 * It acts as a facade for datalayer.api.service and datalayer.service. Use this to replace:
 * - app/core/analytics/datalayer.service.ts
 * - shared/util-analytics/datalayer.service.ts
 * - outshared-lib/datalayer.api.service.ts
 *
 * Add provideDatalayer() to the providers array if you start using it.
 */
@Injectable({
    providedIn: 'root',
})
export class DatalayerService {
    private dataLayerDeprecatedService = inject(DatalayerDeprecatedService);
    private datalayerApiService = inject(DatalayerApiService);

    /**
     * Initializes the deprecated datalayer service
     * @param props DatalayerConfigInterface
     */
    public init(props?: DatalayerConfigInterface): void {
        this.dataLayerDeprecatedService.init(props);
    }

    /**
     * Initializes the datalayerApiService
     * @param props DatalayerConfigInterface
     */
    public initApiService(props?: DatalayerConfigInterface): void {
        this.datalayerApiService.init(props);
    }

    /**
     * Get cookie id using deprecated datalayer service
     * @returns string
     */
    public getCookieId(): string {
        return this.dataLayerDeprecatedService.getCookieId();
    }

    /**
     * Track partner using deprecated datalayer service
     * @param allProducts PolicyProductInterface[]
     */
    public trackPartner(allProducts: PolicyProductInterface[]): void {
        this.dataLayerDeprecatedService.trackPartner(allProducts);
    }

    /**
     * Send data using deprecated datalayer service
     * @param data DatalayerDataInterface
     */
    public send(data: DatalayerDataInterface): void {
        this.dataLayerDeprecatedService.send(data);
    }

    /**
     * Send notifications to datalayer using deprecated datalayer service
     * @param data NotificationInterface[]
     */
    public sendNotificationsToDatalayer(data: NotificationInterface[]): void {
        this.dataLayerDeprecatedService.sendNotificationsToDatalayer(data);
    }

    /**
     * Set cookieConsent using datalayer api service
     * @param cookieConsent CookieConsent
     */
    public setCookieConsent(cookieConsent: CookieConsent) {
        this.datalayerApiService.setCookieConsent(cookieConsent);
    }

    /**
     * Get datalayer using datalayer api service
     * @returns ApiDatalayerInterface[]
     */
    public getDatalayer(): ApiDatalayerInterface[] {
        return this.datalayerApiService.getDatalayer();
    }
}
