import { AuthenticationStatusEnum } from '../enums';
import { getAuthenticationStatus } from '../mappers';
import { AuthenticationActionEnum, AuthenticationActionType } from './authentication.actions';
import { AuthenticationState, INITIAL_AUTHENTICATION_STATE } from './authentication.state';

export class AuthenticationReducers {
    public static reducer(
        state: AuthenticationState = INITIAL_AUTHENTICATION_STATE,
        action: AuthenticationActionType
    ): AuthenticationState {
        switch (action.type) {
            case AuthenticationActionEnum.Initialize:
                return {
                    ...state,
                    initializationPending: true,
                };

            case AuthenticationActionEnum.Login:
                return {
                    ...state,
                    loginPending: true,
                    sessionDurationInSeconds: null,
                    sessionStart: null,
                    token: null,
                    credentials: {
                        email: action.payload.request.email,
                        password: action.payload.request.password,
                    },
                };

            case AuthenticationActionEnum.LoginSuccess:
                return {
                    ...state,
                    ...action?.payload?.session,
                    status: AuthenticationStatusEnum.LoggedIn,
                    credentials: null,
                };

            case AuthenticationActionEnum.TemporaryPasswordLoginSuccess:
            case AuthenticationActionEnum.TemporaryPasswordSession:
                return {
                    ...state,
                    loginPending: false,
                    status: AuthenticationStatusEnum.TemporaryPassword,
                };

            case AuthenticationActionEnum.GeneratedAccountLoginSuccess:
            case AuthenticationActionEnum.GeneratedAccountSession:
                return {
                    ...state,
                    loginPending: false,
                    status: AuthenticationStatusEnum.GeneratedAccount,
                };

            case AuthenticationActionEnum.ChangedEmailLoginSuccess:
            case AuthenticationActionEnum.ChangedEmailSession:
                return {
                    ...state,
                    loginPending: false,
                    status: AuthenticationStatusEnum.ChangeEmail,
                };

            case AuthenticationActionEnum.LoginError:
                return {
                    ...state,
                    loginPending: false,
                    credentials: null,
                    status: getAuthenticationStatus(action.payload.errors),
                };

            case AuthenticationActionEnum.AuthenticatedSession:
                return {
                    ...state,
                    status: AuthenticationStatusEnum.LoggedIn,
                };
            case AuthenticationActionEnum.SessionAvailable:
                return {
                    ...state,
                    initializationPending: false,
                    loginPending: true, // Keep the button in pending state, we will redirect the user to another page
                    sessionStart: action.payload.session.sessionStart,
                    sessionDurationInSeconds: action.payload.session.sessionDurationInSeconds,
                    credentials: null,
                };
            case AuthenticationActionEnum.TemporarySessionAvailable:
                return {
                    ...state,
                    initializationPending: false,
                    loginPending: false,
                    sessionStart: action.payload.session.sessionStart,
                    sessionDurationInSeconds: action.payload.session.sessionDurationInSeconds,
                    credentials: null,
                };

            case AuthenticationActionEnum.SessionTerminated:
                return INITIAL_AUTHENTICATION_STATE;

            case AuthenticationActionEnum.SessionUnavailable:
                return {
                    ...state,
                    initializationPending: false,
                };

            case AuthenticationActionEnum.SessionExpired:
            case AuthenticationActionEnum.LoadSessionError:
                return {
                    ...state,
                    initializationPending: false,
                    logoutPending: false,
                    loginPending: false,
                    status: AuthenticationStatusEnum.SessionExpired,
                };

            case AuthenticationActionEnum.Logout:
                return {
                    ...state,
                    logoutPending: true,
                };

            case AuthenticationActionEnum.LogoutSuccess:
            case AuthenticationActionEnum.LogoutError:
                return {
                    ...state,
                    status: AuthenticationStatusEnum.LoggedOut,
                    logoutPending: false,
                };

            default:
                return state;
        }
    }
}
