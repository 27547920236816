import { mapSequenceNumber } from '../../../core/mappers';
import { ApiEndDateProductRequest, EndDateProductRequestInterface } from '../../interfaces';

export function mapEndDateProductRequest(request: EndDateProductRequestInterface): ApiEndDateProductRequest {
    return {
        purchased_product: {
            end_date: request.endDate,
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
