import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class ClaimExhibitsEventService {
    private _loadExhibits$: Subject<void>;
    private _saveExhibit$: Subject<void>;
    private _deleteExhibit$: Subject<void>;
    private _submitExhibits$: Subject<void>;

    public loadExhibits$(): Observable<void> {
        this._loadExhibits$ = new Subject<void>();
        return this._loadExhibits$.asObservable();
    }

    public onLoadExhibitsSuccess(): void {
        this._loadExhibits$.next();
        this._loadExhibits$.complete();
    }

    public onLoadExhibitsError(errors: ErrorInterface[]): void {
        this._loadExhibits$.error(errors);
    }

    public saveExhibit$(): Observable<void> {
        this._saveExhibit$ = new Subject<void>();
        return this._saveExhibit$.asObservable();
    }

    public onSaveExhibitSuccess(): void {
        this._saveExhibit$.next();
        this._saveExhibit$.complete();
    }

    public onSaveExhibitError(errors: ErrorInterface[]): void {
        this._saveExhibit$.error(errors);
    }

    public deleteExhibit$(): Observable<void> {
        this._deleteExhibit$ = new Subject<void>();
        return this._deleteExhibit$.asObservable();
    }

    public onDeleteExhibitSuccess(): void {
        this._deleteExhibit$.next();
        this._deleteExhibit$.complete();
    }

    public onDeleteExhibitError(errors: ErrorInterface[]): void {
        this._deleteExhibit$.error(errors);
    }

    public submitExhibits$(): Observable<void> {
        this._submitExhibits$ = new Subject<void>();
        return this._submitExhibits$.asObservable();
    }

    public onSubmitExhibitsSuccess(): void {
        this._submitExhibits$.next();
        this._submitExhibits$.complete();
    }

    public onSubmitExhibitsError(errors: ErrorInterface[]): void {
        this._submitExhibits$.error(errors);
    }
}
