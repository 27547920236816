/* eslint-disable max-len */
import { Action } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';

export enum InternationalMotorInsuranceCardActionsEnum {
    LoadEmailInternationalMotorInsuranceCard = '[International Motor Insurance Card] Load E-mail Insurance Card',
    LoadEmailInternationalMotorInsuranceCardSuccess = '[International Motor Insurance Card] Load E-mail Insurance Card Success',
    LoadEmailInternationalMotorInsuranceCardError = '[International Motor Insurance Card] Load E-mail Insurance Card Error',

    LoadEmailNotInsuredInternationalMotorInsuranceCard = '[International Motor Insurance Card] Load not insured E-mail Insurance Card',
    LoadEmailNotInsuredInternationalMotorInsuranceCardSuccess = '[International Motor Insurance Card] Load not insured E-mail Insurance Card Success',
    LoadEmailNotInsuredInternationalMotorInsuranceCardError = '[International Motor Insurance Card] Load not insured E-mail Insurance Card Error',

    LoadPdfInternationalMotorInsuranceCard = '[International Motor Insurance Card] Load PDF Insurance Card',
    LoadPdfInternationalMotorInsuranceCardSuccess = '[International Motor Insurance Card] Load PDF Insurance Card Success',
    LoadPdfInternationalMotorInsuranceCardError = '[International Motor Insurance Card] Load PDF Insurance Card Error',

    LoadPdfNotInsuredInternationalMotorInsuranceCard = '[International Motor Insurance Card] Load not insured PDF Insurance Card',
    LoadPdfNotInsuredInternationalMotorInsuranceCardSuccess = '[International Motor Insurance Card] Load not insured PDF Insurance Card Success',
    LoadPdfNotInsuredInternationalMotorInsuranceCardError = '[International Motor Insurance Card] Load not insured PDF Insurance Card Error',

    ResetInternationalMotorInsuranceCard = '[International Motor Insurance Card] Reset Insurance Card',
}

export class LoadEmailInternationalMotorInsuranceCardAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCard;

    constructor(public payload: { productSequenceNumber: number }) {}
}

export class LoadEmailInternationalMotorInsuranceCardSuccessAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCardSuccess;

    constructor(public payload: { email: string }) {}
}

export class LoadEmailInternationalMotorInsuranceCardErrorAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCardError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class LoadEmailNotInsuredInternationalMotorInsuranceCardAction implements Action {
    public readonly type =
        InternationalMotorInsuranceCardActionsEnum.LoadEmailNotInsuredInternationalMotorInsuranceCard;

    constructor(public payload: { productSequenceNumber: number; caravanBrand: string; licensePlate: string }) {}
}

export class LoadEmailNotInsuredInternationalMotorInsuranceCardSuccessAction implements Action {
    public readonly type =
        InternationalMotorInsuranceCardActionsEnum.LoadEmailNotInsuredInternationalMotorInsuranceCardSuccess;

    constructor(public payload: { email: string }) {}
}

export class LoadEmailNotInsuredInternationalMotorInsuranceCardErrorAction implements Action {
    public readonly type =
        InternationalMotorInsuranceCardActionsEnum.LoadEmailNotInsuredInternationalMotorInsuranceCardError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class LoadPdfInternationalMotorInsuranceCardAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCard;

    constructor(public payload: { productSequenceNumber: number }) {}
}

export class LoadPdfInternationalMotorInsuranceCardSuccessAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCardSuccess;

    constructor(public payload: { pdf: PdfInternationalMotorInsuranceCardInterface }) {}
}

export class LoadPdfInternationalMotorInsuranceCardErrorAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCardError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class LoadPdfNotInsuredInternationalMotorInsuranceCardAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCard;

    constructor(public payload: { productSequenceNumber: number; caravanBrand: string; licensePlate: string }) {}
}

export class LoadPdfNotInsuredInternationalMotorInsuranceCardSuccessAction implements Action {
    public readonly type =
        InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCardSuccess;

    constructor(public payload: { pdf: PdfInternationalMotorInsuranceCardInterface }) {}
}

export class LoadPdfNotInsuredInternationalMotorInsuranceCardErrorAction implements Action {
    public readonly type =
        InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCardError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class ResetInternationalMotorInsuranceCardAction implements Action {
    public readonly type = InternationalMotorInsuranceCardActionsEnum.ResetInternationalMotorInsuranceCard;
}

export type Actions =
    | LoadEmailInternationalMotorInsuranceCardAction
    | LoadEmailInternationalMotorInsuranceCardSuccessAction
    | LoadEmailInternationalMotorInsuranceCardErrorAction
    | LoadPdfInternationalMotorInsuranceCardAction
    | LoadPdfInternationalMotorInsuranceCardSuccessAction
    | LoadPdfInternationalMotorInsuranceCardErrorAction
    | LoadPdfNotInsuredInternationalMotorInsuranceCardAction
    | LoadPdfNotInsuredInternationalMotorInsuranceCardSuccessAction
    | ResetInternationalMotorInsuranceCardAction;
