import { createFeatureSelector, createSelector, DefaultProjectorFn, Selector } from '@ngrx/store';
import { ClaimNotificationInterface } from '../interfaces';
import { ClaimNotification, ClaimNotificationState, CLAIM_NOTIFICATION_FEATURE } from './claim-notification.state';

export class ClaimNotificationSelectors {
    private static getClaimNotificationState =
        createFeatureSelector<ClaimNotificationState>(CLAIM_NOTIFICATION_FEATURE);

    public static getState = createSelector(
        ClaimNotificationSelectors.getClaimNotificationState,
        (state: ClaimNotificationState) => state
    );

    public static getNotifications: Selector<ClaimNotification, ClaimNotificationInterface[]> = createSelector(
        ClaimNotificationSelectors.getClaimNotificationState,
        (state: ClaimNotificationState) => state.notifications
    );

    public static getNotification = (caseSequenceNumber: number): DefaultProjectorFn<ClaimNotificationInterface> =>
        createSelector(
            ClaimNotificationSelectors.getNotifications,
            (claimNotifications: ClaimNotificationInterface[]) =>
                claimNotifications.find(
                    (claimNoticiation) => claimNoticiation.caseSequenceNumber === caseSequenceNumber
                )
        );
}
