import { createReducer, on } from '@ngrx/store';
import * as productScenarioActions from './product-scenarios.actions';
import { INITIAL_PRODUCT_SCENARIO_STATE, ProductScenariosState } from './product-scenarios.state';

export const productScenariosReducer = createReducer(
    INITIAL_PRODUCT_SCENARIO_STATE,
    on(
        productScenarioActions.loadProductScenariosSuccess,
        (state, { scenarios }): ProductScenariosState => ({
            ...state,
            scenarios,
        })
    ),
    on(
        productScenarioActions.clearProductScenarios,
        (state): ProductScenariosState => ({
            ...state,
            scenarios: null,
        })
    )
);
