import { mapSequenceNumber } from '../../../core';
import { ApiProductSubstitutedByVehicleResponse, ProductSubstitutedVehicleResponseInterface } from '../../interfaces';

export function mapProductSubstitutedByVehicleResponse(
    response: ApiProductSubstitutedByVehicleResponse
): ProductSubstitutedVehicleResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        productSequenceNumber: mapSequenceNumber(response.purchased_product_sequence_number),
        licensePlate: response.license_plate,
        vehicleReplacementTypeCode: response.vehicle_replacement_type_code,
        vehicleReplacementTypeDescription: response.vehicle_replacement_type_description,
    };
}
