import {
    UpdateClaimObjectDetailsResponseInterface,
    ApiUpdateClaimObjectDetailsResponseInterface,
} from '../interfaces';
import { mapClaimObjectDetails } from './claim-object-details.mapper';

export function mapUpdateClaimObjectDetailsResponse(
    response: ApiUpdateClaimObjectDetailsResponseInterface[],
    claimObjectId: string
): UpdateClaimObjectDetailsResponseInterface {
    return {
        claimObjectDetails: mapClaimObjectDetails(response, claimObjectId),
    };
}
