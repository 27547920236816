import { RelationActionEnum, RelationActionType } from './relation.actions';
import { INITIAL_RELATION_STATE, RelationState } from './relation.state';

export class RelationReducers {
    public static reducer(state: RelationState = INITIAL_RELATION_STATE, action: RelationActionType): RelationState {
        switch (action.type) {
            case RelationActionEnum.RelationAvailable:
                return {
                    ...state,
                    relation: action.payload.relation,
                };

            case RelationActionEnum.UpdateRelationSuccess:
                return {
                    ...state,
                    relation: {
                        ...state.relation,
                        ...action.payload.relation,
                    },
                };

            case RelationActionEnum.UpdatePrimaryEmailSuccess:
                return {
                    ...state,
                    relation: {
                        ...state.relation,
                        primary_email_address: {
                            ...state.relation.primary_email_address,
                            email_address: action.payload.email,
                        },
                    },
                };

            case RelationActionEnum.UpdatePasswordSuccess:
                return {
                    ...state,
                    relation: {
                        ...state.relation,
                        password_updated: true,
                    },
                };

            case RelationActionEnum.SaveOptOutSuccess:
                return {
                    ...state,
                    relation: {
                        ...state.relation,
                        opt_out_timestamp: action.payload.optOut ? new Date().toDateString() : null,
                    },
                };

            case RelationActionEnum.CancelFutureMailingAddressSuccess:
                return {
                    ...state,
                    relation: {
                        ...state.relation,
                        mailing_address_future: null,
                    },
                };

            case RelationActionEnum.CreateRelationRecoveryDataSuccess:
                return {
                    ...state,
                    relation: {
                        ...state.relation,
                        mobile_telephone_number: action.payload.mobile_telephone_number,
                    },
                };

            case RelationActionEnum.Reset:
                return INITIAL_RELATION_STATE;

            default:
                return state;
        }
    }
}
