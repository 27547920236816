import { ApiSpecificationContentInterface, SpecificationContentInterface } from '../interfaces';

export function mapSpecificationContentResponse(
    response: ApiSpecificationContentInterface[]
): SpecificationContentInterface[] {
    if (!response) {
        return [];
    }

    return response.map((contentItem: ApiSpecificationContentInterface) => {
        return {
            contentId: contentItem.content_id,
            contentDescription: contentItem.content_description,
        };
    });
}
