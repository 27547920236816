import { FetchAddressRequestInterface, FetchAddressResponseInterface } from '../interfaces';
import { ApiFetchAddressRequestInterface } from '../interfaces/api-fetch-address-request.interface';
import { ApiFetchAddressResponseInterface } from '../interfaces/api-fetch-address-response.interface';

export function mapFetchAddressRequest(request: FetchAddressRequestInterface): ApiFetchAddressRequestInterface {
    return {
        address: {
            civic_number: request.civicNumber,
            postal_code: request.postalCode,
        },
    };
}

export function mapFetchAddressResponse(response: ApiFetchAddressResponseInterface): FetchAddressResponseInterface {
    return {
        city: response.city,
        streetName: response.street_name,
    };
}
