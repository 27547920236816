import { ApiPositionProcedureExplanationCodeEnum, PositionProcedureExplanationCodeEnum } from '../enums';

export function mapPositionProcedureExplanationCodeResponse(
    procedureExplanationCode: ApiPositionProcedureExplanationCodeEnum
): PositionProcedureExplanationCodeEnum {
    switch (procedureExplanationCode) {
        case ApiPositionProcedureExplanationCodeEnum.ConsequencesForAnnualBonus:
            return PositionProcedureExplanationCodeEnum.ConsequencesForAnnualBonus;
        case ApiPositionProcedureExplanationCodeEnum.ConsequencesForClaimFreeYearsBMLadderAndPremium:
            return PositionProcedureExplanationCodeEnum.ConsequencesForClaimFreeYearsBMLadderAndPremium;
        case ApiPositionProcedureExplanationCodeEnum.RecoverExcess:
            return PositionProcedureExplanationCodeEnum.RecoverExcess;
        case ApiPositionProcedureExplanationCodeEnum.NextStepsIfThereIsCover:
            return PositionProcedureExplanationCodeEnum.NextStepsIfThereIsCover;
        case ApiPositionProcedureExplanationCodeEnum.NextStepsIfThereIsNoCover:
            return PositionProcedureExplanationCodeEnum.NextStepsIfThereIsNoCover;
    }
}

export function mapPositionProcedureExplanationCodeRequest(
    procedureTypeCode: ApiPositionProcedureExplanationCodeEnum
): PositionProcedureExplanationCodeEnum {
    switch (procedureTypeCode) {
        case ApiPositionProcedureExplanationCodeEnum.ConsequencesForAnnualBonus:
            return PositionProcedureExplanationCodeEnum.ConsequencesForAnnualBonus;
        case ApiPositionProcedureExplanationCodeEnum.ConsequencesForClaimFreeYearsBMLadderAndPremium:
            return PositionProcedureExplanationCodeEnum.ConsequencesForClaimFreeYearsBMLadderAndPremium;
        case ApiPositionProcedureExplanationCodeEnum.RecoverExcess:
            return PositionProcedureExplanationCodeEnum.RecoverExcess;
        case ApiPositionProcedureExplanationCodeEnum.NextStepsIfThereIsCover:
            return PositionProcedureExplanationCodeEnum.NextStepsIfThereIsCover;
        case ApiPositionProcedureExplanationCodeEnum.NextStepsIfThereIsNoCover:
            return PositionProcedureExplanationCodeEnum.NextStepsIfThereIsNoCover;
    }
}
