import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InternationalMotorInsuranceCardEffects } from './store/international-motor-insurance-card.effects';
import { internationalMotorInsuranceCardReducer } from './store/international-motor-insurance-card.reducer';
import { INTERNATIONAL_MOTOR_INSURANCE_CARD_FEATURE } from './store/international-motor-insurance-card.state';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(INTERNATIONAL_MOTOR_INSURANCE_CARD_FEATURE, internationalMotorInsuranceCardReducer),
        EffectsModule.forFeature([InternationalMotorInsuranceCardEffects]),
    ],
})
export class OutInternationalMotorInsuranceCardModule {}
