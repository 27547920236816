export enum ClaimProcessIdEnum {
    AgreeOnClaimEffects = 'Agree On Claim Effects',
    CoverageIndication = 'Coverage Indication',
    OpenActionsOnAppointments = 'Open Actions On Appointments',
    OpenActionsOnParties = 'Open Actions On Subject',
    OpenActionsOnDocuments = 'Open Actions On Evidence',
    ViewOfAppointments = 'View Of Appointments',
    ViewOfParties = 'View Of Data Subject',
    ViewOfDocuments = 'View Of Evidence',
}
