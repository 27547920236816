import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClaimNotificationInterface } from '../interfaces/claim-notification.interface';
import { ClaimNotificationSelectors } from '../store/claim-notification.selectors';
import { ClaimNotification, ClaimNotificationState } from '../store/claim-notification.state';

@Injectable({ providedIn: 'root' })
export class ClaimNotificationDataService {
    constructor(private store$: Store<ClaimNotification>) {}

    public getState$(): Observable<ClaimNotificationState> {
        return this.store$.select(ClaimNotificationSelectors.getState);
    }

    public getNotifications$(): Observable<ClaimNotificationInterface[]> {
        return this.store$.select(ClaimNotificationSelectors.getNotifications);
    }

    public getNotification$(caseSequenceNumber: number): Observable<ClaimNotificationInterface> {
        return this.store$.select(ClaimNotificationSelectors.getNotification(caseSequenceNumber));
    }
}
