export enum ProcedureTypeCodeEnum {
    ProcedureDamageAssessment = 'Procedure Damage Assessment',
    ProcedureDamageRepair = 'Procedure Damage Repair',
    ProcedureReplacementTransport = 'Procedure Replacement Transport',
    ProcedurePremium = 'Procedure Premium',
    LegalAdvice = 'Legal Advice',
    NotifyStichtingVerzekeringsbureauVoertuigcriminaliteit = 'Notify Stichting Verzekeringsbureau Voertuigcriminaliteit',
    SummaryAndConfirmation = 'Summary And Confirmation',
    ClaimsHandling = 'Claims Handling',
    NoExpert = 'No Expert',
    AppointmentDamageAssessment = 'Appointment Damage Assessment',
    GreenCard = 'Green Card',
    AppointmentDamageRepair = 'Appointment Damage Repair',
    ReplacementTransport = 'Replacement Transport',
    StichtingVBV = 'Stichting VBV',
    TerminationPremium = 'Termination Premium',
    AppraiseRepairman = 'Appraise Repairman',
    ExpertPresent = 'Expert Present',
    KeysAndRegistrationPapers = 'Keys And Registration Papers',
    WaitingPeriod = 'Waiting Period',
    TotalLoss = 'Total Loss',
    DailyAllowance = 'Daily Allowance',
    ExpertiseReport = 'Expertise Report',
    RepairBill = 'Repair Bill',
    OwnRepairer = 'Own Repairer',
    RecoverFromCounterparty = 'Recover From Counterparty',
    CounterpartyResponseTime = 'Counterparty Response Time',
    RecoverFromGuaranteeInstitution = 'Recover From Guarantee Institution',
    ClaimsSettlementCounterparty = 'Claims Settlement Counterparty',
    DamageAmount = 'Damage Amount',
    Payment = 'Payment',
    Send = 'Send',
    PaymentExcess = 'Payment Excess',
    RepairReady = 'Repair Ready',
    NotRepairable = 'Not Repairable',
    MoneyForDevice = 'Money For Device',
    ReplaceDevice = 'Replace Device',
    DamageFolder = 'Damage Folder',
    AnnualBonus = 'Annual Bonus',
    ExcessRecovery = 'Excess Recovery',
    ClaimFreeYearsAndBMLadder = 'Claim Free Years And BM Ladder',
    AffordableRepairViaFixico = 'Affordable Repair Via Fixico',
    PhotosDamage = 'Photos Damage',
    ThreeQuotationsWithoutObligationWithinTwentyFourHours = 'Three Quotations Without Obligation Within TwentyFour Hours',
    MakeYourChoice = 'Make Your Choice',
    ProcedureClaimProcessing = 'Procedure Claim Processing',
    KeysAndDocuments = 'Keys And Documents',
    ProcedureBonusMalusConsequences = 'Procedure Bonus Malus Consequences',
    GuaranteeFundProcedure = 'Guarantee Fund Procedure',
}
