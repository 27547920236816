import { createFeatureSelector, createSelector, DefaultProjectorFn } from '@ngrx/store';
import { ApiProductIdEnum, mapProductIdRequest } from '../../insurance-policy';
import { ClaimCategoryEnum } from '../enums';
import { ClaimCategoryInterface, DamageTypeInterface } from '../interfaces';
import { mapClaimCategoryIdRequest } from '../mappers';
import { ClaimCategoriesState, CLAIM_CATEGORIES_FEATURE } from './claim-categories.state';

export class ClaimCategoriesSelectors {
    public static getClaimCategoriesState = createFeatureSelector<ClaimCategoriesState>(CLAIM_CATEGORIES_FEATURE);

    public static getState = createSelector(
        ClaimCategoriesSelectors.getClaimCategoriesState,
        (state: ClaimCategoriesState) => state
    );

    public static getClaimCategories = createSelector(
        ClaimCategoriesSelectors.getClaimCategoriesState,
        (state: ClaimCategoriesState) => {
            // filter claimCategories by purchasedProducts to get the active products of the user
            const filteredClaimCategories = state.claimCategories.filter((claimCategory) =>
                state.purchasedProducts.some(
                    (purchasedProduct) =>
                        mapProductIdRequest(purchasedProduct.productId) ===
                        (mapClaimCategoryIdRequest(claimCategory.claimCategoryId) as unknown as ApiProductIdEnum)
                )
            );

            // make sure active products are in front of the array
            const combined = [...filteredClaimCategories, ...state.claimCategories];

            // removed duplicates, keeping the first occurence to keep the order of active products in front
            return combined.filter((item, pos) => combined.indexOf(item) === pos);
        }
    );

    public static getClaimCategory = (claimCategoryId: ClaimCategoryEnum): DefaultProjectorFn<ClaimCategoryInterface> =>
        createSelector(ClaimCategoriesSelectors.getClaimCategories, (claimCategories: ClaimCategoryInterface[]) =>
            claimCategories.find((claimCategory) => claimCategory.claimCategoryId === claimCategoryId)
        );

    public static getDamageTypes = (claimCategoryId: ClaimCategoryEnum): DefaultProjectorFn<DamageTypeInterface[]> =>
        createSelector(ClaimCategoriesSelectors.getClaimCategories, (claimCategories: ClaimCategoryInterface[]) => {
            const claimCategory = claimCategories.find(
                (claimCategory) => claimCategory.claimCategoryId === claimCategoryId
            );

            return claimCategory?.damageTypes ?? null;
        });
}
