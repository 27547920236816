import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { RelationInterface } from '../interfaces';
import { Relation, RelationState, RELATION_FEATURE } from './relation.state';

export class RelationSelectors {
    public static getRelationState = createFeatureSelector<RelationState>(RELATION_FEATURE);

    // Only for Demo Application
    public static getState = createSelector(RelationSelectors.getRelationState, (state: RelationState) => state);

    public static getRelation: Selector<Relation, RelationInterface> = createSelector(
        RelationSelectors.getRelationState,
        (state: RelationState) => state.relation
    );
}
