import { GetAggregateApiRequestInterface, AggregateRequestInterface } from '../interfaces';

export function mapAggregateRequest(request: AggregateRequestInterface): GetAggregateApiRequestInterface {
    const { ids } = request;
    return {
        vragenset: ids.map((id: string) => {
            return { questionset_id: id };
        }),
    };
}
