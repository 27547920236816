import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { ModalPosition, ModalRole } from './new-modal-config';

@Injectable({
    providedIn: 'root',
})
export class NewModalDefaultOptions<D = any> {
    public viewContainerRef?: ViewContainerRef;
    public id?: string;
    public role?: ModalRole = 'dialog';

    public paneElClass?: string | string[] = '';

    public hasBackdrop?: boolean = true;

    public backdropClass?: string = '';

    public disableClose?: boolean = false;

    public width?: string = '';

    public height?: string = '';

    public minWidth?: string | number;

    public minHeight?: string | number;

    public maxWidth: string | number = 750;

    public maxHeight: string | number = '100vh';

    public position?: ModalPosition;

    public direction?: Direction;

    public data?: D | null = null;

    public ariaDescribedBy?: string | null = null;

    /** ID of the element that labels the dialog. */
    public ariaLabelledBy?: string | null = null;

    /** Aria label to assign to the dialog element. */
    public ariaLabel?: string | null = null;

    /** Whether the dialog should focus the first focusable element on open. */
    public autoFocus?: boolean = true;

    /**
     * Whether the dialog should restore focus to the
     * previously-focused element, after it's closed.
     */
    public restoreFocus?: boolean = true;

    /** Scroll strategy to be used for the dialog. */
    public scrollStrategy?: ScrollStrategy;

    /**
     * Whether the dialog should close when the user goes backwards/forwards in history.
     * Note that this usually doesn't include clicking on links (unless the user is using
     * the `HashLocationStrategy`).
     */
    public closeOnNavigation?: boolean = true;

    /** Alternate `ComponentFactoryResolver` to use when resolving the associated component. */
    public componentFactoryResolver?: ComponentFactoryResolver;

    public fullScreen?: boolean = false;
}
