import { mapSequenceNumber } from '../../core';
import { ApiGetClaimObjectDetailsRequestInterface, GetClaimObjectDetailsRequestInterface } from '../interfaces';

export function mapGetClaimObjectDetailsRequest(
    request: GetClaimObjectDetailsRequestInterface
): ApiGetClaimObjectDetailsRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        claim_object: {
            claim_object_id: request.claimObjectId,
        },
    };
}
