import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiService } from '../../core';
import { LivechatStatusInterface } from '../interfaces';
import { mapLivechatStatusResponse } from '../mappers';

const RESOURCES: { [key: string]: string } = {
    LIVECHAT_STATUS: 'en-gb/cloudcrm-livechat/request/availability',
};

@Injectable()
export class OutLivechatService {
    constructor(private apiService: ApiService) {}

    public getStatus$(): Observable<LivechatStatusInterface> {
        return this.apiService.post$(RESOURCES.LIVECHAT_STATUS, {}).pipe(
            timeout(5000),
            map(mapLivechatStatusResponse),
            catchError(() => of(null))
        );
    }
}
