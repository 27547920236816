export enum ApiUrlResourcesEnum {
    // Claim Funnel
    ClaimCategories = 'ClaimCategories',
    RequestClaimSpecification = 'RequestClaimSpecification',
    RemoveSpecification = 'RemoveSpecification',
    ClaimObjects = 'ClaimObjects',
    ClaimObjectDetails = 'ClaimObjectDetails',
    SaveClaimObject = 'SaveClaimObject',
    RemoveClaimObject = 'RemoveClaimObject',
    ClaimCompensation = 'ClaimCompensation',
    ClaimCoverage = 'ClaimCoverage',
    ClaimCoverageCustomerResponse = 'ClaimCoverageCustomerResponse',
    ClaimOverview = 'ClaimOverview',
    ClaimProcessSteps = 'ClaimProcessSteps',
    GetParties = 'GetParties',
    SaveWitness = 'SaveWitness',
    SaveContact = 'SaveContact',
    SaveCounterParty = 'SaveCounterParty',
    ClaimLoadExhibits = 'ClaimLoadExhibits',
    ClaimSaveExhibits = 'ClaimSaveExhibits',
    ClaimDeleteExhibits = 'ClaimDeleteExhibits',
    ClaimSubmitExhibits = 'ClaimSubmitExhibits',
    GetClaimNotification = 'GetClaimNotification',
    SaveClaimNotification = 'SaveClaimNotification',
    GetAppointments = 'GetAppointments',
    SaveAppointment = 'SaveAppointment',
    GetLocations = 'GetLocations',
    GetTimeslots = 'GetTimeslots',
}
