import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultModalComponent } from './default-modal-component';
import { NewModalActionsDirective } from './new-modal-actions.directive';
import { NewModalCloseBtnDirective } from './new-modal-close-btn.directive';
import { NewModalCloseDirective } from './new-modal-close.directive';
import { NewModalContainer } from './new-modal-container';
import { NewModalContentDirective } from './new-modal-content.directive';
import { NewModalDefaultOptions } from './new-modal-default-options';
import { NewModalTitleDirective } from './new-modal-title.directive';
import { NEW_MODAL_DEFAULT_OPTIONS, NEW_MODAL_SCROLL_STRATEGY_PROVIDER } from './new-modal-utilities';
import { NewModal } from './new-modal.service';

@NgModule({
    imports: [OverlayModule, PortalModule, A11yModule, CommonModule],
    exports: [
        NewModalContainer,
        NewModalCloseDirective,
        NewModalTitleDirective,
        NewModalContentDirective,
        NewModalActionsDirective,
        NewModalCloseBtnDirective,
        A11yModule,
    ],
    declarations: [
        NewModalContainer,
        NewModalCloseDirective,
        NewModalTitleDirective,
        NewModalContentDirective,
        NewModalActionsDirective,
        NewModalCloseBtnDirective,
        DefaultModalComponent,
    ],
    providers: [
        NewModal,
        NEW_MODAL_SCROLL_STRATEGY_PROVIDER,
        { provide: NEW_MODAL_DEFAULT_OPTIONS, useClass: NewModalDefaultOptions },
    ],
})
export class NewModalModule {}
