import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, ResponseInterface } from '../../core';
import { ProductEnGbInterface } from '../../my-zone';
import { ApiProductEnGbInterface } from '../../my-zone/interfaces/product/api-product-en-gb.interface';
import { purchasedProductsMapper } from '../../my-zone/mappers/purchased-products.mapper';
import {
    ApiCreateBankDetailsRequest,
    ApiCreateDutchMailingAddressRequest,
    ApiCreateMailingAddressRequest,
    ApiRelationResponse,
    ApiTerminateActiveMailingAddressRequest,
    ApiUpdateOptOutRequest,
    ApiUpdatePasswordRequest,
    ApiUpdatePersonalDetailsPayloadInterface,
    ApiUpdatePrimaryEmailRequest,
    CreateBankDetailsInterface,
    CreateDutchMailingAddressRequestInterface,
    CreateMailingAddressRequestInterface,
    CreateRelationRecoveryDataRequestInterface,
    CreateResidentialAddressInterface, CreateResidentialAddressResponseInterface,
    EditAccountCredentialsInterface,
    GetPremiumModificationInterface,
    ModifyFamilyCompositionInterface,
    RelationInterface, StoreResidentialAddressInterface,
    TerminateActiveMailingAddressRequestInterface,
    TokenRelationOptOutInterface,
    UpdatePasswordRequestInterface,
    UpdatePersonalDetailsInterface,
    UpdatePrimaryEmailInterface,
} from '../interfaces';
import {
    mapCreateBankDetailsRequest,
    mapCreateDutchMailingAddressRequest,
    mapCreateMailingAddressRequest,
    mapCreateRelationRecoveryDataRequest,
    mapOptOutRequest,
    mapRelationResponse,
    mapTerminateActiveMailingAddressRequest,
    mapUpdateFamilyCompositionRequest,
    mapUpdatePasswordRequest,
    mapUpdatePersonalDetails,
    mapUpdatePrimaryEmailRequest,
} from '../mappers';
import { createResidentialaddressResponseMapper } from '../mappers/create-residential-address.mapper';
import {
    ApiCreateResidentialAddressResponseInterface
} from '../interfaces/api.create-residential-address-response.interface';

const RESOURCES = {
    REQUEST_RELATION: 'en-gb/relation-general/request/relation_data',
    MODIFY_OPT_OUT: 'en-gb/relation-contact/modify/relation-opt_out',
    MODIFY_CREDENTIALS: 'en-gb/relation-authorization/modify/account-credentials',
    MODIFY_RELATION: 'en-gb/relation-general/modify/relation_data',
    CREATE_MAILING_ADDRESS: 'en-gb/relation-contact/create/mailingaddress',
    CREATE_DUTCH_MAILING_ADDRESS: 'en-gb/relation-contact/store/mailing_address',
    CANCEL_FUTURE_MAILING_ADDRESS: 'en-gb/relation-contact/cancel/future_mailing_address',
    TERMINATE_ACTIVE_MAILING_ADDRESS: 'en-gb/relation-contact/terminate/active-mailing_address',
    REVIVE_TERMINATED_MAILING_ADDRESS: 'en-gb/relation-contact/revive/terminated-mailing_address',
    UPDATE_FAMILY_COMPOSITION: '/en-gb/relation-general/modify/family_composition',
    CANCEL_FUTURE_FAMILY_COMPOSITION: '', // TODO : Implement
    CREATE_BANK_DETAILS: 'en-gb/relation-bank/store/bank_details',
    MODIFY_FAMILY_COMPOSITION: 'en-gb/relation-general/modify/family_composition',
    CANCEL_FAMILY_COMPOSITION: 'en-gb/relation-general/cancel/future-family_composition',
    REQUEST_PURCHASED_PRODUCTS: 'en-gb/insurance-product/request/premium_modification-purchased_products',
    EDIT_ACCOUNT_CREDENTIALS: 'en-gb/relation-authorization/modify/account-credentials',
    CREATE_RESIDENTIAL_ADDRESS: 'en-gb/v2/relation-contact/create/residentialaddress',
    STORE_RESIDENTIAL_ADDRESS: 'en-gb/relation-contact/store/residentialaddress',
    CANCEL_RESIDENTIAL_ADDRESS: 'en-gb/relation-contact/cancel/future-residential_address',
    TOKEN_RELATION_OPT_OUT: 'en-gb/relation-contact/modify/token-relation-opt_out',
    CREATE_RELATION_RECOVERY_DATA: 'en-gb/relation-contact/store/relation-recovery_data',
};

@Injectable({
    providedIn: 'root',
})
export class RelationApiService {
    constructor(private apiService: ApiService) {}

    /* Consider updating this file to new eslint standards */

    public getRelation$(): Observable<RelationInterface> {
        return this.apiService.post$<ApiRelationResponse>(RESOURCES.REQUEST_RELATION).pipe(map(mapRelationResponse));
    }

    public updateOptOut$(optOut: boolean): Observable<boolean> {
        const payload: ApiUpdateOptOutRequest = mapOptOutRequest(optOut);

        return this.apiService.post$<void>(RESOURCES.MODIFY_OPT_OUT, payload).pipe(map(() => optOut));
    }

    public updatePrimaryEmail$(request: UpdatePrimaryEmailInterface): Observable<string> {
        const payload: ApiUpdatePrimaryEmailRequest = mapUpdatePrimaryEmailRequest(request);

        return this.apiService.post$<void>(RESOURCES.MODIFY_CREDENTIALS, payload).pipe(map(() => request.email));
    }

    public updateRelation$(relation: RelationInterface): Observable<RelationInterface> {
        const payload = { relation };

        return this.apiService.post$<void>(RESOURCES.MODIFY_RELATION, payload).pipe(switchMap(this.getRelation$));
    }

    public updatePersonalDetails$(payload: UpdatePersonalDetailsInterface): Observable<void> {
        const request: ApiUpdatePersonalDetailsPayloadInterface = mapUpdatePersonalDetails(payload);

        return this.apiService.post$<void>(RESOURCES.MODIFY_RELATION, request);
    }

    public updatePassword$(request: UpdatePasswordRequestInterface): Observable<void> {
        const payload: ApiUpdatePasswordRequest = mapUpdatePasswordRequest(request);

        return this.apiService.post$<void>(RESOURCES.MODIFY_CREDENTIALS, payload);
    }

    public createMailingAddress$(request: CreateMailingAddressRequestInterface): Observable<void> {
        const payload: ApiCreateMailingAddressRequest = mapCreateMailingAddressRequest(request);

        return this.apiService.post$<void>(RESOURCES.CREATE_MAILING_ADDRESS, payload);
    }

    public createDutchMailingAddress$(request: CreateDutchMailingAddressRequestInterface): Observable<void> {
        const payload: ApiCreateDutchMailingAddressRequest = mapCreateDutchMailingAddressRequest(request);

        return this.apiService.post$<void>(RESOURCES.CREATE_DUTCH_MAILING_ADDRESS, payload);
    }

    public cancelFutureMailingAddress$(): Observable<void> {
        return this.apiService.post$<void>(RESOURCES.CANCEL_FUTURE_MAILING_ADDRESS);
    }

    public terminateActiveMailingAddress$(request: TerminateActiveMailingAddressRequestInterface): Observable<void> {
        const payload: ApiTerminateActiveMailingAddressRequest = mapTerminateActiveMailingAddressRequest(request);

        return this.apiService.post$<void>(RESOURCES.TERMINATE_ACTIVE_MAILING_ADDRESS, payload);
    }

    public reviveTerminatedMailingAddress$(): Observable<void> {
        return this.apiService.post$<void>(RESOURCES.REVIVE_TERMINATED_MAILING_ADDRESS);
    }

    public updateFamilyComposition$(request: ModifyFamilyCompositionInterface): Observable<void> {
        const payload = mapUpdateFamilyCompositionRequest(request);

        return this.apiService.post$<void>(RESOURCES.UPDATE_FAMILY_COMPOSITION, payload);
    }

    public createBankDetails$(request: CreateBankDetailsInterface): Observable<void> {
        const payload: ApiCreateBankDetailsRequest = mapCreateBankDetailsRequest(request);

        return this.apiService.post$<void>(RESOURCES.CREATE_BANK_DETAILS, payload);
    }

    public modifyFamilyComposition$(request: ModifyFamilyCompositionInterface): Observable<ResponseInterface> {
        const payload = {
            family_composition: {
                family_composition_code: request.familyCompositionCode,
                start_date: request.startDate,
            },
        };

        return this.apiService
            .postResponse$(RESOURCES.MODIFY_FAMILY_COMPOSITION, payload)
            .pipe(map(ApiService.throwErrors));
    }

    public cancelFamilyComposition$(): Observable<void> {
        return this.apiService.post$<void>(RESOURCES.CANCEL_FAMILY_COMPOSITION);
    }

    public getPremiumModificationPurchasedProducts$(
        request: GetPremiumModificationInterface
    ): Observable<ProductEnGbInterface[]> {
        const payload = {
            reference_date: request.referenceDate,
            purchased_product: { purchased_product_sequence_number: request.sequenceNumber },
        };
        return this.apiService
            .post$(RESOURCES.REQUEST_PURCHASED_PRODUCTS, payload)
            .pipe(map((result: ApiProductEnGbInterface) => purchasedProductsMapper(result)));
    }

    public editAccountCredentials$(request: EditAccountCredentialsInterface): Observable<void> {
        const payload = {
            current_password: request.currentPassword.trim(),
            new_password: request.newPassword.trim(),
            primary_email_address: {
                email_address: request.emailAddress.trim(),
            },
        };
        return this.apiService.post$<void>(RESOURCES.EDIT_ACCOUNT_CREDENTIALS, payload);
    }

    public createResidentialAddress$(request: CreateResidentialAddressInterface): Observable<CreateResidentialAddressResponseInterface> {
        const payload = {
            start_date: request.startDate,
            international_address_id: request.internationalAddressId,
        };
        return this.apiService
            .post$(RESOURCES.CREATE_RESIDENTIAL_ADDRESS, payload)
            .pipe(
                map((result: ApiCreateResidentialAddressResponseInterface) =>
                    createResidentialaddressResponseMapper(result))
            );
    }

    public storeResidentialAddress$(request: StoreResidentialAddressInterface): Observable<void> {
        const payload = {
            residential_address: {
                start_date: request.startDate,
                postal_code: request.postalCode,
                civic_number: request.civicNumber,
                civic_number_suffix: request.civicNumberSuffix || null,
            },
        };
        return this.apiService.post$<void>(RESOURCES.STORE_RESIDENTIAL_ADDRESS, payload);
    }

    public cancelResidentialAddress$(): Observable<void> {
        return this.apiService.post$<void>(RESOURCES.CANCEL_RESIDENTIAL_ADDRESS);
    }

    public tokenRelationOptOut$(request: TokenRelationOptOutInterface): Observable<void> {
        const payload = {
            identification: {
                opt_out_id: request.optOutId,
                relation_id: request.relationId,
            },
        };
        return this.apiService.post$<void>(RESOURCES.TOKEN_RELATION_OPT_OUT, payload);
    }

    public createRelationRecoveryData$(
        request: CreateRelationRecoveryDataRequestInterface
    ): Observable<RelationInterface> {
        const payload = mapCreateRelationRecoveryDataRequest(request);
        return this.apiService
            .post$<string>(RESOURCES.CREATE_RELATION_RECOVERY_DATA, payload)
            .pipe(switchMap(() => this.getRelation$()));
    }
}
