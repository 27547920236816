import { mapFromSuccessIndication, mapSequenceNumber } from '../../core';
import { ApiRelatedPartyTypeEnum } from '../enums';
import { ApiRelatedPartiesInterface, RelatedPartyTypeInterface } from '../interfaces';
import { mapRelatedPartyTypeResponse } from './related-party-type.mapper';

export function mapRelatedPartyTypesResponse(
    response: ApiRelatedPartiesInterface,
    caseSequenceNumber: number
): RelatedPartyTypeInterface[] {
    const relatedParties: RelatedPartyTypeInterface[] = [];
    const keys = Object.keys(response) as ApiRelatedPartyTypeEnum[];

    keys.forEach((key) =>
        relatedParties.push({
            relatedPartyType: mapRelatedPartyTypeResponse(key),
            singleAllowed: mapFromSuccessIndication(response[key].addition_allowed_indication),
            multipleAllowed: mapFromSuccessIndication(response[key].multiple_allowed_indication),
            caseSequenceNumber: mapSequenceNumber(caseSequenceNumber),
        })
    );

    return relatedParties;
}
