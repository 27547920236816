import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';

export const INTERNATIONAL_MOTOR_INSURANCE_CARD_FEATURE = 'internationalMotorInsuranceCard';

export interface InternationalMotorInsuranceCardState {
    pdfInternationalMotorInsuranceCard: PdfInternationalMotorInsuranceCardInterface;
    emailInternationalMotorInsuranceCard: string;
    selectedId: number;
    caravanBrand: string;
    licensePlate: string;
}

export const INTERNATIONAL_MOTOR_INSURANCE_CARD_INITIAL_STATE: InternationalMotorInsuranceCardState = {
    pdfInternationalMotorInsuranceCard: null,
    emailInternationalMotorInsuranceCard: '',
    selectedId: 0,
    caravanBrand: '',
    licensePlate: '',
};
