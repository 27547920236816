import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ClaimCoverageApiService } from '../services/claim-coverage.api.service';
import { ClaimCoverageEventService } from '../services/claim-coverage.event.service';
import * as ClaimCoverageActions from './claim-coverage.actions';

@Injectable({
    providedIn: 'root',
})
export class ClaimCoverageEffects {
    // get claim coverage pet
    public getClaimCompensation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimCoverageActions.getClaimCompensation),
            exhaustMap(({ request }) =>
                this.claimCoverageApiService.getClaimCompensation$(request).pipe(
                    map((response) => ClaimCoverageActions.getClaimCompensationSuccess({ response })),
                    catchError((errors) => of(ClaimCoverageActions.getClaimCompensationError({ errors })))
                )
            )
        );
    });

    public getClaimCompensationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimCoverageActions.getClaimCompensationSuccess),
                tap(() => this.claimCoverageEventService.onGetClaimCompensationSuccess())
            );
        },
        { dispatch: false }
    );

    public getClaimCompensationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimCoverageActions.getClaimCompensationError),
                tap(({ errors }) => this.claimCoverageEventService.onGetClaimCompensationError(errors))
            );
        },
        { dispatch: false }
    );

    // get claim coverage
    public getClaimCoverage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimCoverageActions.getClaimCoverage),
            exhaustMap(({ request }) =>
                this.claimCoverageApiService.getClaimCoverage$(request).pipe(
                    map((response) => ClaimCoverageActions.getClaimCoverageSuccess({ response })),
                    catchError((errors) => of(ClaimCoverageActions.getClaimCoverageError({ errors })))
                )
            )
        );
    });

    public getClaimCoverageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimCoverageActions.getClaimCoverageSuccess),
                tap(() => this.claimCoverageEventService.onGetClaimCoverageSuccess())
            );
        },
        { dispatch: false }
    );

    public getClaimCoverageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimCoverageActions.getClaimCoverageError),
                tap(({ errors }) => this.claimCoverageEventService.onGetClaimCoverageError(errors))
            );
        },
        { dispatch: false }
    );

    // save claim coverage
    public saveClaimCoverage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimCoverageActions.saveClaimCoverage),
            exhaustMap(({ request }) =>
                this.claimCoverageApiService.saveClaimCoverage$(request).pipe(
                    map((response) => ClaimCoverageActions.saveClaimCoverageSuccess({ response })),
                    catchError((errors) => of(ClaimCoverageActions.saveClaimCoverageError({ errors })))
                )
            )
        );
    });

    public saveClaimCoverageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimCoverageActions.saveClaimCoverageSuccess),
                tap(() => this.claimCoverageEventService.onSaveClaimCoverageSuccess())
            );
        },
        { dispatch: false }
    );

    public saveClaimCoverageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimCoverageActions.saveClaimCoverageError),
                tap(({ errors }) => this.claimCoverageEventService.onSaveClaimCoverageError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private claimCoverageApiService: ClaimCoverageApiService,
        private claimCoverageEventService: ClaimCoverageEventService
    ) {}
}
