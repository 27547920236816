import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class AccountRecoveryEventService {
    private _recoveryMethods$: Subject<void>;
    private _sendPassword$: Subject<ErrorInterface[]>;
    private _updateTemporaryPassword$: Subject<void>;
    private _updatePassword$: Subject<void>;
    private _updateEmail$: Subject<void>;
    private _forgotEmail$: Subject<ErrorInterface[]>;
    private _validateGeneratedAccount$: Subject<void>;
    private _completeGeneratedAccount$: Subject<void>;

    public getRecoveryMethods$(): Observable<void> {
        this._recoveryMethods$ = new Subject();

        return this._recoveryMethods$.asObservable();
    }

    public onRecoveryMethodsSuccess(): void {
        this._recoveryMethods$.next();
        this._recoveryMethods$.complete();
    }

    public onRecoveryMethodsError(errors: ErrorInterface[]): void {
        this._recoveryMethods$.error(errors);
    }

    public getSendPassword$(): Observable<ErrorInterface[]> {
        this._sendPassword$ = new Subject();

        return this._sendPassword$.asObservable();
    }

    public onSendPasswordSuccess(notifications: ErrorInterface[]): void {
        this._sendPassword$.next(notifications);
        this._sendPassword$.complete();
    }

    public onSendPasswordError(errors: ErrorInterface[]): void {
        this._sendPassword$.error(errors);
    }

    public getUpdateTemporaryPassword$(): Observable<void> {
        this._updateTemporaryPassword$ = new Subject();

        return this._updateTemporaryPassword$.asObservable();
    }

    public onUpdateTemporaryPasswordSuccess(): void {
        this._updateTemporaryPassword$.next();
        this._updateTemporaryPassword$.complete();
    }

    public onUpdateTemporaryPasswordError(errors: ErrorInterface[]): void {
        this._updateTemporaryPassword$.error(errors);
    }

    public getForgotEmail$(): Observable<ErrorInterface[]> {
        this._forgotEmail$ = new Subject();

        return this._forgotEmail$.asObservable();
    }

    public onForgotEmailSuccess(notifications: ErrorInterface[]): void {
        this._forgotEmail$.next(notifications);
        this._forgotEmail$.complete();
    }

    public onForgotEmailError(errors: ErrorInterface[]): void {
        this._forgotEmail$.error(errors);
    }

    public getValidateGeneratedAccount$(): Observable<void> {
        this._validateGeneratedAccount$ = new Subject();

        return this._validateGeneratedAccount$.asObservable();
    }

    public onValidateGeneratedAccountSuccess(): void {
        this._validateGeneratedAccount$.next();
        this._validateGeneratedAccount$.complete();
    }

    public onValidateGeneratedAccountError(errors: ErrorInterface[]): void {
        this._validateGeneratedAccount$.error(errors);
    }

    public getUpdatePassword$(): Observable<void> {
        this._updatePassword$ = new Subject();

        return this._updatePassword$.asObservable();
    }

    public onUpdatePasswordSuccess(): void {
        this._updatePassword$.next();
        this._updatePassword$.complete();
    }

    public onUpdatePasswordError(errors: ErrorInterface[]): void {
        this._updatePassword$.error(errors);
    }

    public getUpdateEmail$(): Observable<void> {
        this._updateEmail$ = new Subject();

        return this._updateEmail$.asObservable();
    }

    public onUpdateEmailSuccess(): void {
        this._updateEmail$.next();
        this._updateEmail$.complete();
    }

    public onUpdateEmailError(errors: ErrorInterface[]): void {
        this._updateEmail$.error(errors);
    }

    public getCompleteGeneratedAccount$(): Observable<void> {
        this._completeGeneratedAccount$ = new Subject();

        return this._completeGeneratedAccount$.asObservable();
    }

    public onCompleteGeneratedAccountSuccess(): void {
        this._completeGeneratedAccount$.next();
        this._completeGeneratedAccount$.complete();
    }

    public onCompleteGeneratedAccountError(errors: ErrorInterface[]): void {
        this._completeGeneratedAccount$.error(errors);
    }
}
