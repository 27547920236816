import { Coverage, CoveragePremium } from '../interfaces';

export const filterCoverages = (coverages: Coverage[] = [], picked: string[]): Coverage[] => {
    if (!coverages) {
        return [];
    }

    return coverages.filter((coverage) => picked.includes(coverage.coverage_id));
};

export const addPremiums = (currentTotal: number, coveragePremium: CoveragePremium): number => {
    const premiumTotal = currentTotal + (coveragePremium ? coveragePremium.premium_including_premium_tax : 0);
    const floatingPointString = parseFloat(premiumTotal.toString()).toFixed(2);

    return +floatingPointString;
};

export const addCollectivityDiscounts = (currentTotal: number, coveragePremium: CoveragePremium): number => {
    const premiumTotal = currentTotal + (coveragePremium ? coveragePremium.premium_including_premium_tax : 0);
    const floatingPointString = parseFloat(premiumTotal.toString()).toFixed(2);

    return +floatingPointString;
};
