import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';
import { ProductEnGbInterface } from '../../my-zone';
import { CreateResidentialAddressResponseInterface } from '../interfaces';
import { CreateResidentialAddressSuccessAction } from '../store/relation.actions';

@Injectable({
    providedIn: 'root',
})
export class RelationEventService {
    private _updatePassword$: Subject<void>;
    private _saveOptOut$: Subject<void>;
    private _updateEmail$: Subject<void>;
    private _updateRelation$: Subject<void>;
    private _createMailingAddress$: Subject<void>;
    private _createDutchMailingAddress$: Subject<void>;
    private _cancelFutureMailingAddress$: Subject<void>;
    private _terminateActiveMailingAddress$: Subject<void>;
    private _reviveTerminatedMailingAddress$: Subject<void>;
    private _updateFamilyComposition$: Subject<void>;
    private _updatePersonalDetails$: Subject<void>;
    private _saveEmailPreferences$: Subject<void>;
    private _createBankDetails$: Subject<void>;
    private _modifyFamilyComposition$: Subject<ErrorInterface[]>;
    private _cancelFamilyComposition$: Subject<void>;
    private _premiumModificationPurchasedProducts$: Subject<ProductEnGbInterface[]>;
    private _editAccountCredentials$: Subject<void>;
    private _createResidentialAddress$: Subject<CreateResidentialAddressResponseInterface>;
    private _storeResidentialAddress$: Subject<void>;
    private _cancelResidentialAddress$: Subject<void>;
    private _saveTokenRelationOptOut$: Subject<void>;
    private _createRelationRecoveryData$: Subject<string>;

    public getUpdatePassword$(): Observable<void> {
        this._updatePassword$ = new Subject<void>();

        return this._updatePassword$.asObservable();
    }

    public onUpdatePasswordSuccess(): void {
        this._updatePassword$.next();
        this._updatePassword$.complete();
    }

    public onUpdatePasswordError(errors: ErrorInterface[]): void {
        this._updatePassword$.error(errors);
    }

    public getSaveOptOut$(): Observable<void> {
        this._saveOptOut$ = new Subject<void>();

        return this._saveOptOut$.asObservable();
    }

    public onSaveOptOutSuccess(): void {
        this._saveOptOut$.next();
        this._saveOptOut$.complete();
    }

    public onSaveOptOutError(errors: ErrorInterface[]): void {
        this._saveOptOut$.error(errors);
    }

    public getUpdatePrimaryEmail$(): Observable<void> {
        this._updateEmail$ = new Subject<void>();

        return this._updateEmail$.asObservable();
    }

    public onUpdatePrimaryEmailSuccess(): void {
        this._updateEmail$.next();
        this._updateEmail$.complete();
    }

    public onUpdatePrimaryEmailError(errors: ErrorInterface[]): void {
        this._updateEmail$.error(errors);
    }

    public getUpdateRelation$(): Observable<void> {
        this._updateRelation$ = new Subject<void>();

        return this._updateRelation$.asObservable();
    }

    public onUpdateRelationSuccess(): void {
        this._updateRelation$.next();
        this._updateRelation$.complete();
    }

    public onUpdateRelationError(errors: ErrorInterface[]): void {
        this._updateRelation$.error(errors);
    }

    public getCreateMailingAddress$(): Observable<void> {
        this._createMailingAddress$ = new Subject<void>();

        return this._createMailingAddress$.asObservable();
    }

    public onCreateMailingAddressSuccess(): void {
        this._createMailingAddress$.next();
        this._createMailingAddress$.complete();
    }

    public onCreateMailingAddressError(errors: ErrorInterface[]): void {
        this._createMailingAddress$.error(errors);
    }

    public getCreateDutchMailingAddress$(): Observable<void> {
        this._createDutchMailingAddress$ = new Subject<void>();

        return this._createDutchMailingAddress$.asObservable();
    }

    public onCreateDutchMailingAddressSuccess(): void {
        this._createDutchMailingAddress$.next();
        this._createDutchMailingAddress$.complete();
    }

    public onCreateDutchMailingAddressError(errors: ErrorInterface[]): void {
        this._createDutchMailingAddress$.error(errors);
    }

    public getCancelFutureMailingAddress$(): Observable<void> {
        this._cancelFutureMailingAddress$ = new Subject<void>();

        return this._cancelFutureMailingAddress$.asObservable();
    }

    public onCancelFutureMailingAddressSuccess(): void {
        this._cancelFutureMailingAddress$.next();
        this._cancelFutureMailingAddress$.complete();
    }

    public onCancelFutureMailingAddressError(errors: ErrorInterface[]): void {
        this._cancelFutureMailingAddress$.error(errors);
    }

    public getTerminateActiveMailingAddress$(): Observable<void> {
        this._terminateActiveMailingAddress$ = new Subject<void>();

        return this._terminateActiveMailingAddress$.asObservable();
    }

    public onTerminateActiveMailingAddressSuccess(): void {
        this._terminateActiveMailingAddress$.next();
        this._terminateActiveMailingAddress$.complete();
    }

    public onTerminateActiveMailingAddressError(errors: ErrorInterface[]): void {
        this._terminateActiveMailingAddress$.error(errors);
    }

    public getReviveTerminatedMailingAddress$(): Observable<void> {
        this._reviveTerminatedMailingAddress$ = new Subject<void>();

        return this._reviveTerminatedMailingAddress$.asObservable();
    }

    public onReviveTerminatedMailingAddressSuccess(): void {
        this._reviveTerminatedMailingAddress$.next();
        this._reviveTerminatedMailingAddress$.complete();
    }

    public onReviveTerminatedMailingAddressError(errors: ErrorInterface[]): void {
        this._reviveTerminatedMailingAddress$.error(errors);
    }

    public getUpdateFamilyComposition$(): Observable<void> {
        this._updateFamilyComposition$ = new Subject();

        return this._updateFamilyComposition$.asObservable();
    }

    public onUpdateFamilyCompositionSuccess(): void {
        this._updateFamilyComposition$.next();
        this._updateFamilyComposition$.complete();
    }

    public onUpdateFamilyCompositionError(errors: ErrorInterface[]): void {
        this._updateFamilyComposition$.error(errors);
    }

    public updatePersonalDetails$(): Observable<void> {
        this._updatePersonalDetails$ = new Subject();

        return this._updatePersonalDetails$.asObservable();
    }

    public onUpdatePersonalDetailsSuccess$(): void {
        this._updatePersonalDetails$.next();
        this._updatePersonalDetails$.complete();
    }

    public onUpdatePersonalDetailsError$(errors: ErrorInterface[]): void {
        this._updatePersonalDetails$.error(errors);
    }

    public saveEmailPreferences$(): Observable<void> {
        this._saveEmailPreferences$ = new Subject();
        return this._saveEmailPreferences$.asObservable();
    }

    public saveEmailPreferencesSuccess$(): void {
        this._saveEmailPreferences$.next();
        this._saveEmailPreferences$.complete();
    }

    public saveEmailPreferencesError$(errors: ErrorInterface[]): void {
        this._saveEmailPreferences$.error(errors);
    }

    public createBankDetails$(): Observable<void> {
        this._createBankDetails$ = new Subject<void>();

        return this._createBankDetails$.asObservable();
    }

    public createBankDetailsSuccess$(): void {
        this._createBankDetails$.next();
        this._createBankDetails$.complete();
    }

    public createBankDetailsError$(errors: ErrorInterface[]): void {
        this._createBankDetails$.error(errors);
    }

    public modifyFamilyComposition$(): Observable<ErrorInterface[]> {
        this._modifyFamilyComposition$ = new Subject<ErrorInterface[]>();

        return this._modifyFamilyComposition$.asObservable();
    }

    public modifyFamilyCompositionSuccess$(notifications: ErrorInterface[]): void {
        this._modifyFamilyComposition$.next(notifications);
        this._modifyFamilyComposition$.complete();
    }

    public modifyFamilyCompositionError$(errors: ErrorInterface[]): void {
        this._modifyFamilyComposition$.error(errors);
    }

    public cancelFamilyComposition$(): Observable<void> {
        this._cancelFamilyComposition$ = new Subject<void>();

        return this._cancelFamilyComposition$.asObservable();
    }

    public cancelFamilyCompositionSuccess$(): void {
        this._cancelFamilyComposition$.next();
        this._cancelFamilyComposition$.complete();
    }

    public cancelFamilyCompositionError$(errors: ErrorInterface[]): void {
        this._cancelFamilyComposition$.error(errors);
    }

    public premiumModificationPurchasedProducts$(): Observable<ProductEnGbInterface[]> {
        this._premiumModificationPurchasedProducts$ = new Subject<ProductEnGbInterface[]>();

        return this._premiumModificationPurchasedProducts$.asObservable();
    }

    public premiumModificationPurchasedProductsSuccess$(purchasedProducts: ProductEnGbInterface[]): void {
        this._premiumModificationPurchasedProducts$.next(purchasedProducts);
        this._premiumModificationPurchasedProducts$.complete();
    }

    public premiumModificationPurchasedProductsError$(errors: ErrorInterface[]): void {
        this._premiumModificationPurchasedProducts$.error(errors);
    }

    public editAccountCredentials$(): Observable<void> {
        this._editAccountCredentials$ = new Subject<void>();

        return this._editAccountCredentials$.asObservable();
    }

    public editAccountCredentialsSuccess$(): void {
        this._editAccountCredentials$.next();
        this._editAccountCredentials$.complete();
    }

    public editAccountCredentialsError$(errors: ErrorInterface[]): void {
        this._editAccountCredentials$.error(errors);
    }

    public createResidentialAddress$(): Observable<CreateResidentialAddressResponseInterface> {
        this._createResidentialAddress$ = new Subject<CreateResidentialAddressResponseInterface>();

        return this._createResidentialAddress$.asObservable();
    }

    public createResidentialAddressSuccess$(action: CreateResidentialAddressSuccessAction): void {
        this._createResidentialAddress$.next(action.payload.response);
        this._createResidentialAddress$.complete();
    }

    public createResidentialAddressError$(errors: ErrorInterface[]): void {
        this._createResidentialAddress$.error(errors);
    }

    public storeResidentialAddress$(): Observable<void> {
        this._storeResidentialAddress$ = new Subject<void>();

        return this._storeResidentialAddress$.asObservable();
    }

    public storeResidentialAddressSuccess$(): void {
        this._storeResidentialAddress$.next();
        this._storeResidentialAddress$.complete();
    }

    public storeResidentialAddressError$(errors: ErrorInterface[]): void {
        this._storeResidentialAddress$.error(errors);
    }

    public cancelResidentialAddress$(): Observable<void> {
        this._cancelResidentialAddress$ = new Subject<void>();

        return this._cancelResidentialAddress$.asObservable();
    }

    public cancelResidentialAddressSuccess$(): void {
        this._cancelResidentialAddress$.next();
        this._cancelResidentialAddress$.complete();
    }

    public cancelResidentialAddressError$(errors: ErrorInterface[]): void {
        this._cancelResidentialAddress$.error(errors);
    }

    public saveTokenRelationOptOut$(): Observable<void> {
        this._saveTokenRelationOptOut$ = new Subject();
        return this._saveTokenRelationOptOut$.asObservable();
    }

    public saveTokenRelationOptOutSuccess$(): void {
        this._saveTokenRelationOptOut$.next();
        this._saveTokenRelationOptOut$.complete();
    }

    public saveTokenRelationOptOutError$(errors: ErrorInterface[]): void {
        this._saveTokenRelationOptOut$.error(errors);
    }

    public createRelationRecoveryData$(): Observable<string> {
        this._createRelationRecoveryData$ = new Subject();
        return this._createRelationRecoveryData$.asObservable();
    }

    public createRelationRecoveryDataSuccess$(result: string): void {
        this._createRelationRecoveryData$.next(result);
        this._createRelationRecoveryData$.complete();
    }

    public createRelationRecoveryDataError$(errors: ErrorInterface[]): void {
        this._createRelationRecoveryData$.error(errors);
    }
}
