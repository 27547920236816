import { mapFromSuccessIndication, mapGenderCode } from '../../../core/mappers';
import { ApiProductPetResponse, ProductPetResponseInterface } from '../../interfaces';

export function mapProductPetResponse(response: ApiProductPetResponse): ProductPetResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        birthdate: response.birthdate,
        breedDescription: response.breed_description,
        breedId: response.breed_id,
        castratedSterilizedIndication: mapFromSuccessIndication(response.castrated_sterilized_indication),
        castratedSterilizedIndicationDescription: response.castrated_sterilized_indication_description,
        chipNumber: response.chip_number,
        cleanBillOfHealthIndication: mapFromSuccessIndication(response.clean_bill_of_health_indication),
        cleanBillOfHealthIndicationDescription: response.clean_bill_of_health_indication_description,
        complaintsAilmentsIndication: mapFromSuccessIndication(response.complaints_ailments_indication),
        complaintsAilmentsIndicationDescription: response.complaints_ailments_indication_description,
        dateOfBirthDescription: response.date_of_birth_description,
        fullGrownWeight: response.full_grown_weight,
        fullGrownWeightDescription: response.full_grown_weight_description,
        genderCode: mapGenderCode(response.gender_code),
        genderDescription: response.gender_description,
        medicationIndication: mapFromSuccessIndication(response.medication_indication),
        medicationIndicationDescription: response.medication_indication_description,
        petDescription: response.pet_description,
        petCode: response.pet_code,
        petName: response.pet_name,
        purebredIndication: mapFromSuccessIndication(response.purebred_indication),
        purebredIndicationDescription: response.purebred_indication_description,
        vaccinationIndication: mapFromSuccessIndication(response.vaccination_indication),
        vaccinationIndicationDescription: response.vaccination_indication_description,
        veterinarianCity: response.veterinarian_city,
        veterinarianName: response.veterinarian_name,
    };
}
