import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OutCoreModule } from '../core';
import { ValidationEffects } from './store/validation.effects';
import { ValidationReducers } from './store/validation.reducers';
import { VALIDATION_FEATURE } from './store/validation.state';

@NgModule({
    imports: [
        StoreModule.forFeature(VALIDATION_FEATURE, ValidationReducers.reducer),
        EffectsModule.forFeature([ValidationEffects]),
        OutCoreModule,
    ],
})
export class OutValidationModule {}
