import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GetAppointmentsRequestInterface } from '../interfaces';
import { GetLocationsRequestInterface } from '../interfaces/get-locations.interface';
import { GetTimeslotsRequestInterface } from '../interfaces/get-timeslots.interface';
import { SaveAppointmentRequestInterface } from '../interfaces/save-appointment.interface';
import * as ClaimAppointmentActions from '../store/claim-appointment.actions';
import { ClaimAppointmentState } from '../store/claim-appointment.state';
import { ClaimAppointmentEventService } from './claim-appointment.event.service';

@Injectable({ providedIn: 'root' })
export class ClaimAppointmentActionService {
    constructor(
        private store$: Store<ClaimAppointmentState>,
        private claimAppointmentEventService: ClaimAppointmentEventService
    ) {}

    public getAppointments$(request: GetAppointmentsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimAppointmentActions.getAppointments({ request }));
        return this.claimAppointmentEventService.getAppointments$();
    }

    public saveAppointment$(request: SaveAppointmentRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimAppointmentActions.saveAppointment({ request }));
        return this.claimAppointmentEventService.saveAppointment$();
    }

    public getLocations$(request: GetLocationsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimAppointmentActions.getLocations({ request }));
        return this.claimAppointmentEventService.getLocations$();
    }

    public getTimeslots$(request: GetTimeslotsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimAppointmentActions.getTimeslots({ request }));
        return this.claimAppointmentEventService.getTimeslots$();
    }

    public resetClaimAppointment(): void {
        this.store$.dispatch(ClaimAppointmentActions.resetClaimAppointment());
    }
}
