/**
 *  Helper class for Carousel
 */
export class CarouselBase {
    public calculateDefaultOffset(index: number, children: HTMLCollection): number {
        let offset = 0;

        for (let i = 0; i < index; i++) {
            const child = children[i] as HTMLElement;
            offset = offset + child.offsetWidth;
        }
        return offset;
    }

    public calculateLastChildOffsetRight(children: HTMLCollection, offsetWidth: number): number {
        let innerOffsetWidth = 0;
        let offset;

        for (const child of Array.from(children)) {
            innerOffsetWidth = innerOffsetWidth + (child as HTMLElement).offsetWidth;
        }
        offset = innerOffsetWidth - offsetWidth;

        return offset < 0 ? 0 : offset;
    }

    public calculateLastChildOffsetLeft(children: HTMLCollection): number {
        let offset = 0;

        for (let i = 0; i < children.length - 1; i++) {
            const child = children[i] as HTMLElement;
            offset = offset + child.offsetWidth;
        }
        return offset;
    }

    public calculateLastChildOffsetToRight(children: HTMLCollection, offsetWidth: number): number {
        let innerOffsetWidth = 0;

        for (const child of Array.from(children)) {
            innerOffsetWidth = innerOffsetWidth + (child as HTMLElement).offsetWidth;
        }
        return offsetWidth - innerOffsetWidth;
    }

    /** Als ze allemaal in beeld zijn */
    /** Tot het moment dat de laatste niet meer in beeld is */

    /** Wanneer zijn ze allemaal te zien? */
    /** Als met het volgende item het laatste item uit beeld gaat */
    public allVisibleInView(offsetWidth: number, children: HTMLCollection): boolean {
        let offset = 0;

        for (let i = 0; i < children.length - 1; i++) {
            const child = children[i] as HTMLElement;
            offset = offset + child.offsetWidth;
        }
        return offset <= offsetWidth;
    }

    public predictPreviousPossibleIndex(children: HTMLCollection, offsetWidth: number): number {
        for (let i = children.length - 1; i > 0; i--) {
            if (!this.isNextItemLastToShow(i, children, offsetWidth)) {
                return i;
            }
        }
        return 0;
    }

    /** Als met de volgende offset het laatste item uit beeld gaat */
    public isNextItemLastToShow(index: number, children: HTMLCollection, offsetWidth: number): boolean {
        const offset = this.calculateDefaultOffset(index, children);
        let total = 0;

        for (const child of Array.from(children)) {
            total = total + (child as HTMLElement).offsetWidth;
        }

        return total - offset <= offsetWidth;
    }

    public isLastItem(index: number, children: HTMLCollection): boolean {
        return index === children.length - 1;
    }

    public isFirstItem(index: number): boolean {
        return index === 0;
    }

    public isCenterItem(index: number, children: HTMLCollection): boolean {
        return !this.isFirstItem(index) && !this.isLastItem(index, children);
    }
}
