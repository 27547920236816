import { AcceptanceContainerInterface } from '../interfaces';

function isObject(obj: unknown): boolean {
    return typeof obj === 'object' && obj !== null;
}

function hasNestedValues(obj: object): boolean {
    return Object.values(obj).some((val: any) => isObject(val));
}

function arrayReducer(prev: any[], curr: { [key: string]: any }): { element_name: string; value: string }[] {
    const entry = Object.entries(curr);
    return [...prev, ...entry.map(([a, b]) => ({ element_name: a, value: b }))];
}

function reduceToContainers(currentValue, [key, value]): AcceptanceContainerInterface[] {
    if (!value) {
        return [...currentValue];
    }

    if (Array.isArray(value)) {
        const arrayValues = value.reduce(arrayReducer, []).map((element: { element_name: string; value: string }) => ({
            element: [element],
            context_element_naam: key,
        }));
        return [...currentValue, ...arrayValues];
    }

    if (isObject(value)) {
        if (hasNestedValues(value)) {
            return [
                ...currentValue,
                {
                    container: payloadToContainers(value),
                    context_element_name: key,
                    element: null,
                },
            ];
        }
        return [
            ...currentValue,
            {
                container: null,
                context_element_name: key,
                element: payloadToContainers(value),
            },
        ];
    }

    return [
        ...currentValue,
        {
            element_name: key,
            value,
        },
    ];
}

export function payloadToContainers(payload: { [key: string]: any }): AcceptanceContainerInterface[] {
    return Object.entries(payload).reduce(reduceToContainers, []);
}
