import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorInterface } from '../../core';
import { ContentInterface } from '../interfaces';
import { ContentApiService } from '../services/content.api.service';
import { ContentEventService } from '../services/content.event.service';
import {
    ContentActions,
    RequestElementAction,
    RequestElementErrorAction,
    RequestElementSuccessAction,
} from './content.actions';

@Injectable()
export class ContentEffects {
    public requestContentElements$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(ContentActions.RequestElement),
            mergeMap((action: RequestElementAction) =>
                this.apiService.requestContentElements$(action.payload.request).pipe(
                    map(
                        (contentElements: ContentInterface[]) =>
                            new RequestElementSuccessAction({
                                name: action.payload.request.element_name,
                                contentElements,
                            })
                    ),
                    catchError((errors: ErrorInterface[]) =>
                        of(
                            new RequestElementErrorAction({
                                name: action.payload.request.element_name,
                                errors,
                            })
                        )
                    )
                )
            )
        );
    }
    );

    public requestContentElementsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ContentActions.RequestElementSuccess),
                map((action: RequestElementSuccessAction) => this.eventService.onRequestSuccess(action.payload.name))
            );
        },
        { dispatch: false }
    );

    public requestContentElementsError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ContentActions.RequestElementError),
                map((action: RequestElementErrorAction) => {
                    return this.eventService.onRequestError(action.payload.name, action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private apiService: ContentApiService,
        private eventService: ContentEventService
    ) {}
}
