import { AggregateInterface } from '../interfaces';

export interface Aggregate {
    aggregates: AggregateState;
}

export interface AggregateState {
    aggregates: AggregateInterface[];
}

export const INITIAL_AGGREGATE_STATE: AggregateState = {
    aggregates: [],
};

export const AGGREGATE_FEATURE = 'aggregate';
