import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ErrorInterface } from '../../core';
import {
    CategoryInterface,
    DownloadTerminationLetterResponseInterface,
    GetInsuranceCompaniesResponseInterface,
} from '../interfaces';
import { ContactApiService } from '../services/contact.api.service';
import { ContactEventService } from '../services/contact.event.service';
import * as ContactActions from './contact.actions';

@Injectable()
export class ContactEffects {
    // Create e-mail
    public createEmail$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.createEmail),
        switchMap(({ request }) =>
            this.apiService.createEmail$(request).pipe(
                map((mailQuestionId: number) => ContactActions.createEmailSuccess({ mailQuestionId })),
                catchError((errors: ErrorInterface[]) => of(ContactActions.createEmailError({ errors })))
            )
        )
    ); }
    );

    public createEmailSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.createEmailSuccess),
            tap(() => this.eventService.onCreateEmailSuccess())
        ); },
        { dispatch: false }
    );

    public createEmailError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.createEmailError),
            tap(({ errors }) => this.eventService.onCreateEmailError(errors))
        ); },
        { dispatch: false }
    );

    // Load categories
    public loadCategories$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.loadCategories),
        switchMap(({ contactIndication }) =>
            this.apiService.loadCategories$(contactIndication).pipe(
                map((categories: CategoryInterface[]) => ContactActions.loadCategoriesSuccess({ categories })),
                catchError((errors: ErrorInterface[]) => of(ContactActions.loadCategoriesError({ errors })))
            )
        )
    ); }
    );

    public loadCategoriesSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.loadCategoriesSuccess),
            tap(() => this.eventService.onLoadCategoriesSuccess())
        ); },
        { dispatch: false }
    );

    public loadCategoriesError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.loadCategoriesError),
            tap(({ errors }) => this.eventService.onLoadCategoriesError(errors))
        ); },
        { dispatch: false }
    );

    // Send e-mail
    public sendEmail$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.sendEmail),
        switchMap(({ mailQuestionId }) =>
            this.apiService.sendEmail$(mailQuestionId).pipe(
                map(() => ContactActions.sendEmailSuccess()),
                catchError((errors: ErrorInterface[]) => of(ContactActions.sendEmailError({ errors })))
            )
        )
    ); }
    );

    public sendEmailSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.sendEmailSuccess),
            tap(() => this.eventService.onSendEmailSuccess())
        ); },
        { dispatch: false }
    );

    public sendEmailError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.sendEmailError),
            tap(({ errors }) => this.eventService.onSendEmailError(errors))
        ); },
        { dispatch: false }
    );

    // Send complaint
    public sendComplaint$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.sendComplaint),
        switchMap(({ request }) =>
            this.apiService.sendComplaint$(request).pipe(
                map(() => ContactActions.sendComplaintSuccess()),
                catchError((errors: ErrorInterface[]) => of(ContactActions.sendComplaintError({ errors })))
            )
        )
    ); }
    );

    public sendComplaintSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.sendComplaintSuccess),
            tap(() => this.eventService.onSendComplaintSuccess())
        ); },
        { dispatch: false }
    );

    public sendComplaintError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.sendComplaintError),
            tap(({ errors }) => this.eventService.onSendComplaintError(errors))
        ); },
        { dispatch: false }
    );

    // Add attachment
    public addAttachment$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.addAttachment),
        mergeMap(({ request }) =>
            this.apiService.addAttachment$(request).pipe(
                map(() => ContactActions.addAttachmentSuccess()),
                catchError((errors: ErrorInterface[]) => of(ContactActions.addAttachmentError({ errors })))
            )
        )
    ); }
    );

    public addAttachmentSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.addAttachmentSuccess),
            tap(() => this.eventService.onAddAttachmentSuccess())
        ); },
        { dispatch: false }
    );

    public addAttachmentError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.addAttachmentError),
            tap(({ errors }) => this.eventService.onAddAttachmentError(errors))
        ); },
        { dispatch: false }
    );

    // Create company
    public getInsuranceCompanies$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.getInsuranceCompanies),
        switchMap(() =>
            this.apiService.getInsuranceCompanies$().pipe(
                map((response: GetInsuranceCompaniesResponseInterface[]) =>
                    ContactActions.getInsuranceCompaniesSuccess({ response })
                ),
                catchError((errors: ErrorInterface[]) => of(ContactActions.getInsuranceCompaniesError({ errors })))
            )
        )
    ); }
    );

    public getInsuranceCompaniesSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.getInsuranceCompaniesSuccess),
            tap(({ response }) => this.eventService.onGetInsuranceCompaniesSuccess(response))
        ); },
        { dispatch: false }
    );

    public getInsuranceCompaniesError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.getInsuranceCompaniesError),
            tap(({ errors }) => this.eventService.onGetInsuranceCompaniesError(errors))
        ); },
        { dispatch: false }
    );

    // Download termination letter
    public downloadTerminationLetter$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.downloadTerminationLetter),
        switchMap(({ request }) =>
            this.apiService.downloadTerminationLetter$(request).pipe(
                map((response: DownloadTerminationLetterResponseInterface) =>
                    ContactActions.downloadTerminationLetterSuccess({ response })
                ),
                catchError((errors: ErrorInterface[]) =>
                    of(ContactActions.downloadTerminationLetterError({ errors }))
                )
            )
        )
    ); }
    );

    public downloadTerminationLetterSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.downloadTerminationLetterSuccess),
            tap(({ response }) => this.eventService.onDownloadTerminationLetterSuccess(response))
        ); },
        { dispatch: false }
    );

    public downloadTerminationLetterError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.downloadTerminationLetterError),
            tap(({ errors }) => this.eventService.onDownloadTerminationLetterError(errors))
        ); },
        { dispatch: false }
    );

    // Email termination letter
    public emailTerminationLetter$ = createEffect(() =>
    { return this.action$.pipe(
        ofType(ContactActions.emailTerminationLetter),
        switchMap(({ request }) =>
            this.apiService.emailTerminationLetter$(request).pipe(
                map(() => ContactActions.emailTerminationLetterSuccess()),
                catchError((errors: ErrorInterface[]) => of(ContactActions.emailTerminationLetterError({ errors })))
            )
        )
    ); }
    );

    public emailTerminationLetterSuccess$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.emailTerminationLetterSuccess),
            tap(() => this.eventService.onEmailTerminationLetterSuccess())
        ); },
        { dispatch: false }
    );

    public emailTerminationLetterError$ = createEffect(
        () =>
        { return this.action$.pipe(
            ofType(ContactActions.emailTerminationLetterError),
            tap(({ errors }) => this.eventService.onEmailTerminationLetterError(errors))
        ); },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private apiService: ContactApiService,
        private eventService: ContactEventService
    ) {}
}
