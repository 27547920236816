import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimExhibitsEffects } from './store/claim-exhibits.effects';
import { claimExhibitsReducer } from './store/claim-exhibits.reducer';
import { CLAIM_EXHIBITS_FEATURE } from './store/claim-exhibits.state';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(CLAIM_EXHIBITS_FEATURE, claimExhibitsReducer),
        EffectsModule.forFeature([ClaimExhibitsEffects]),
    ],
})
export class OutClaimExhibitsModule {}
