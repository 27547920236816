import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClaimCategoryEnum } from '../enums';
import { ClaimCategoryInterface, DamageTypeInterface } from '../interfaces';
import { ClaimCategoriesSelectors } from '../store/claim-categories.selectors';
import { ClaimCategoriesState } from '../store/claim-categories.state';

@Injectable({ providedIn: 'root' })
export class ClaimCategoriesDataService {
    constructor(private readonly store$: Store) {}

    public getState$(): Observable<ClaimCategoriesState> {
        return this.store$.select(ClaimCategoriesSelectors.getState);
    }

    public getClaimCategories$(): Observable<ClaimCategoryInterface[]> {
        return this.store$.select(ClaimCategoriesSelectors.getClaimCategories);
    }

    public getClaimCategory$(claimCategoryId: ClaimCategoryEnum): Observable<ClaimCategoryInterface> {
        return this.store$.select(ClaimCategoriesSelectors.getClaimCategory(claimCategoryId));
    }

    public getDamageTypes$(claimCategoryId: ClaimCategoryEnum): Observable<DamageTypeInterface[]> {
        return this.store$.select(ClaimCategoriesSelectors.getDamageTypes(claimCategoryId));
    }
}
