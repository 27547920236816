import { mapFromSuccessIndication, mapSequenceNumber } from '../../../core';
import { ApiProductDetailsMotorcycleResponse, ProductDetailsMotorcycleResponseInterface } from '../../interfaces';
import { mapProductBonusComponentResponse } from '../product/product-bonus-component.mapper';
import { mapProductIdResponse } from '../product/product-id.mapper';
import { mapProductPremiumDeterminingFactorResponse } from '../product/product-premium-determining-factor.mapper';
import { mapPreventativeMeasureResponse } from '../product/product-preventative-measure.mapper';
import { mapProductRegularDriverResponse } from '../product/product-regular-driver.mapper';

export function mapProductDetailsMotorCycleResponse(
    response: ApiProductDetailsMotorcycleResponse
): ProductDetailsMotorcycleResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        productId: mapProductIdResponse(response.product_id),
        productSequenceNumber: mapSequenceNumber(response.purchased_product_sequence_number),
        productDetails: {
            bonusComponent: mapProductBonusComponentResponse(response.product_bonus_component),
            ceffDatum: response.ceff_datum,
            coveragesAdjustmentPresentIndication: mapFromSuccessIndication(
                response.coverages_adjustment_present_indication
            ),
            coveragesAdjustmentStartDate: response.coverages_adjustment_start_date,
            regularDriver: mapProductRegularDriverResponse(response.non_regular_driver),
            regularDriverFuture: mapProductRegularDriverResponse(response.non_regular_driver_future),
            regularDriverHistory: mapProductRegularDriverResponse(response.non_regular_driver_history),
            premiumDeterminingFactor: mapProductPremiumDeterminingFactorResponse(response.premium_determining_factor),
            preventativeMeasure: mapPreventativeMeasureResponse(response.permitted_preventative_measure),
        },
    };
}
