import { mapSequenceNumber } from '../../core';
import {
    ApiUpdateSpecificationRequestInterface,
    ApiUpdateSpecificationResponseInterface,
    UpdateSpecificationRequestInterface,
    UpdateSpecificationResponseInterface,
} from '../interfaces';
import { mapSpecificationResponse } from './specification.mapper';

export function mapUpdateSpecificationRequest(
    request: UpdateSpecificationRequestInterface
): ApiUpdateSpecificationRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        element: {
            element_name: request.elementName,
            element_content: request.elementContent,
        },
        kind_of_loss: {
            kind_of_loss_id: request.damageTypeId,
        },
    };
}

export function mapUpdateSpecificationResponse(
    response: ApiUpdateSpecificationResponseInterface
): UpdateSpecificationResponseInterface {
    return {
        specification: mapSpecificationResponse(response.data),
    };
}
