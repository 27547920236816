import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ClaimPartiesApiService } from '../services/claim-parties.api.service';
import { ClaimPartiesEventService } from '../services/claim-parties.event.service';
import * as ClaimPartiesActions from './claim-parties.actions';

@Injectable({ providedIn: 'root' })
export class ClaimPartiesEffects {
    // Get Parties
    public getParties$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimPartiesActions.getParties),
            exhaustMap(({ request }) =>
                this.claimPartiesApiService.getParties$(request).pipe(
                    map((response) => ClaimPartiesActions.getPartiesSuccess({ response })),
                    catchError((errors) => of(ClaimPartiesActions.getPartiesError({ errors })))
                )
            )
        );
    });

    public getPartiesSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.getPartiesSuccess),
                tap(() => this.claimPartiesEventService.onGetPartiesSuccess$())
            );
        },
        { dispatch: false }
    );

    public getPartiesError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.getPartiesError),
                tap(({ errors }) => this.claimPartiesEventService.onGetPartiesError$(errors))
            );
        },
        { dispatch: false }
    );

    // Save Witness
    public saveWitness$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimPartiesActions.saveWitness),
            exhaustMap(({ request }) =>
                this.claimPartiesApiService.saveWitness$(request).pipe(
                    map((response) => ClaimPartiesActions.saveWitnessSuccess({ response })),
                    catchError((errors) => of(ClaimPartiesActions.saveWitnessError({ errors })))
                )
            )
        );
    });

    public saveWitnessSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.saveWitnessSuccess),
                tap(() => this.claimPartiesEventService.onSaveWitnessSuccess$())
            );
        },
        { dispatch: false }
    );

    public saveWitnessError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.saveWitnessError),
                tap(({ errors }) => this.claimPartiesEventService.onSaveWitnessError$(errors))
            );
        },
        { dispatch: false }
    );

    // Save Contact
    public saveContact$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimPartiesActions.saveContact),
            exhaustMap(({ request }) =>
                this.claimPartiesApiService.saveContact$(request).pipe(
                    map((response) => ClaimPartiesActions.saveContactSuccess({ response })),
                    catchError((errors) => of(ClaimPartiesActions.saveContactError({ errors })))
                )
            )
        );
    });

    public saveContactSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.saveContactSuccess),
                tap(() => this.claimPartiesEventService.onSaveContactSuccess$())
            );
        },
        { dispatch: false }
    );

    public saveContactError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.saveContactError),
                tap(({ errors }) => this.claimPartiesEventService.onSaveContactError$(errors))
            );
        },
        { dispatch: false }
    );

    // Save Counterparty
    public saveCounterparty$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimPartiesActions.saveCounterparty),
            exhaustMap(({ request }) =>
                this.claimPartiesApiService.saveCounterparty$(request).pipe(
                    map((response) => ClaimPartiesActions.saveCounterpartySuccess({ response })),
                    catchError((errors) => of(ClaimPartiesActions.saveCounterpartyError({ errors })))
                )
            )
        );
    });

    public saveCounterpartySuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.saveCounterpartySuccess),
                tap(() => this.claimPartiesEventService.onSaveCounterpartySuccess$())
            );
        },
        { dispatch: false }
    );

    public saveCounterpartyError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimPartiesActions.saveCounterpartyError),
                tap(({ errors }) => this.claimPartiesEventService.onSaveCounterpartyError$(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private claimPartiesApiService: ClaimPartiesApiService,
        private claimPartiesEventService: ClaimPartiesEventService
    ) {}
}
