import { Component } from '@angular/core';

@Component({
    selector: 'out-carousel-item',
    template: `
        <div class="carousel__item">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./carousel-item.component.scss'],
})
export class CarouselItemComponent {}
