import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgForage } from 'ngforage';
import { defer, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';


/**
 * For returning the void Observables we use switchMap() + of() for consistency
 * We could have used map( ()=> {} ) but empty functions look suspicious.
 *
 * On set & remove we return a void observable, but it's not necessary to subscribe.
 * It would be possible to create such a deferred Observable by using defer() operator.
 */
@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(@Inject(PLATFORM_ID) private platformId: any, private ngForage: NgForage) {}

    public get$<T>(key: string, defaultValue?: T): Observable<T> {
        if (isPlatformServer(this.platformId)) {
            return of(defaultValue);
        }

        return defer(() =>
            from(this.ngForage.getItem<T>(key)).pipe(
                map((value: any) => {
                    if (value === null) {
                        throw value;
                    }
                    return value;
                })
            )
        );
    }

    /** Not necessary to subscribe, only use response when you need to wait on operation */
    public set$(key: string, data: any): Observable<void> {
        if (isPlatformServer(this.platformId)) {
            return of(null);
        }
        return from(this.ngForage.setItem(key, data)).pipe(switchMap(() => of(null)));
    }

    /** Not necessary to subscribe, only use response when you need to wait on operation */
    public remove$(key: string): Observable<void> {
        if (isPlatformServer(this.platformId)) {
            return of(null);
        }

        return from(this.ngForage.removeItem(key)).pipe(switchMap(() => of(null)));
    }
}
