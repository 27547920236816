import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TestimonialInterface } from '../interfaces';
import { TESTIMONIALS_FEATURE, TestimonialsState } from './testimonials.state';

export class TestimonialsSelectors {
    public static getTestimonialsState = createFeatureSelector<TestimonialsState>(TESTIMONIALS_FEATURE);

    // Only for testing
    public static getState = createSelector(
        TestimonialsSelectors.getTestimonialsState,
        (state: TestimonialsState) => state
    );

    public static getTestimonials = createSelector(
        TestimonialsSelectors.getTestimonialsState,
        (state: TestimonialsState) => state
    );

    public static getTestimonialsById = (id: string): MemoizedSelector<object, TestimonialInterface[]> =>
        createSelector(TestimonialsSelectors.getTestimonials, (state) =>
            state.testimonials.filter((testimonial) => testimonial.questionsetIds.includes(id))
        );
}
