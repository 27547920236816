import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FetchAddressRequestInterface } from '../interfaces';
import { AddressState } from '../store';
import * as addressActions from '../store/address.actions';
import { AddressEventService } from './address.event.service';

@Injectable({
    providedIn: 'root',
})
export class AddressActionService {
    constructor(private store$: Store<AddressState>, private addressEventService: AddressEventService) {}

    public fetchAddress$(request: FetchAddressRequestInterface): Observable<void> {
        this.store$.dispatch(addressActions.fetchAddress({ request }));
        return this.addressEventService.fetchAddress$();
    }

    public clearAddress(): void {
        this.store$.dispatch(addressActions.clearAddress());
    }
}
