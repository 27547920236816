import { ApiFormatCodeEnum, FormatCodeEnum } from '../enums';

export function mapFormatCode(response: ApiFormatCodeEnum): FormatCodeEnum | null {
    if (!response) {
        return null;
    }

    switch (response) {
        case ApiFormatCodeEnum.Alphanumeric:
            return FormatCodeEnum.Alphanumeric;
        case ApiFormatCodeEnum.Boolean:
            return FormatCodeEnum.Boolean;
        case ApiFormatCodeEnum.Checkbox:
            return FormatCodeEnum.Checkbox;
        case ApiFormatCodeEnum.Codelist:
            return FormatCodeEnum.Codelist;
        case ApiFormatCodeEnum.Date:
            return FormatCodeEnum.Date;
        case ApiFormatCodeEnum.LicensePlate:
            return FormatCodeEnum.LicensePlate;
        case ApiFormatCodeEnum.Numeric:
            return FormatCodeEnum.Numeric;
        case ApiFormatCodeEnum.PostalCode:
            return FormatCodeEnum.PostalCode;
        case ApiFormatCodeEnum.Time:
            return FormatCodeEnum.Time;
        case ApiFormatCodeEnum.TimeStamp:
            return FormatCodeEnum.TimeStamp;
        case ApiFormatCodeEnum.Text:
            return FormatCodeEnum.Text;
        case ApiFormatCodeEnum.Valuta:
            return FormatCodeEnum.Valuta;
    }
}
