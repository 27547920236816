import { ApiProductIdEnum, PolicyProductIdEnum } from '../../enums';

export function mapProductIdRequest(request: PolicyProductIdEnum): ApiProductIdEnum  {
    if (!request) {
        return null;
    }

    switch (request) {
        case PolicyProductIdEnum.Liability:
            return ApiProductIdEnum.Liability;
        case PolicyProductIdEnum.Car:
            return ApiProductIdEnum.Car;
        case PolicyProductIdEnum.Moped:
            return ApiProductIdEnum.Moped;
        case PolicyProductIdEnum.Scooter:
            return ApiProductIdEnum.Scooter;
        case PolicyProductIdEnum.Caravan:
            return ApiProductIdEnum.Caravan;
        case PolicyProductIdEnum.Bicycle:
            return ApiProductIdEnum.Bicycle;
        case PolicyProductIdEnum.Pet:
            return ApiProductIdEnum.Pet;
        case PolicyProductIdEnum.Motorcycle:
            return ApiProductIdEnum.Motorcycle;
        case PolicyProductIdEnum.Accidents:
            return ApiProductIdEnum.Accidents;
        case PolicyProductIdEnum.LegalAssistance:
            return ApiProductIdEnum.LegalAssistance;
        case PolicyProductIdEnum.Travel:
            return ApiProductIdEnum.Travel;
        case PolicyProductIdEnum.Home:
            return ApiProductIdEnum.Home;
        case PolicyProductIdEnum.Furniture:
            return ApiProductIdEnum.Furniture;
        case PolicyProductIdEnum.Building:
            return ApiProductIdEnum.Building;
    }
}

export function mapProductIdResponse(response: ApiProductIdEnum): PolicyProductIdEnum {
    if (!response) {
        return null;
    }

    switch (response) {
    case ApiProductIdEnum.Liability:
        return PolicyProductIdEnum.Liability;
    case ApiProductIdEnum.Car:
        return PolicyProductIdEnum.Car;
    case ApiProductIdEnum.Moped:
        return PolicyProductIdEnum.Moped;
    case ApiProductIdEnum.Scooter:
        return PolicyProductIdEnum.Scooter;
    case ApiProductIdEnum.Caravan:
        return PolicyProductIdEnum.Caravan;
    case ApiProductIdEnum.Bicycle:
        return PolicyProductIdEnum.Bicycle;
    case ApiProductIdEnum.Pet:
        return PolicyProductIdEnum.Pet;
    case ApiProductIdEnum.Motorcycle:
        return PolicyProductIdEnum.Motorcycle;
    case ApiProductIdEnum.Accidents:
        return PolicyProductIdEnum.Accidents;
    case ApiProductIdEnum.LegalAssistance:
        return PolicyProductIdEnum.LegalAssistance;
    case ApiProductIdEnum.Travel:
        return PolicyProductIdEnum.Travel;
    case ApiProductIdEnum.Home:
        return PolicyProductIdEnum.Home;
    case ApiProductIdEnum.Furniture:
        return PolicyProductIdEnum.Furniture;
    case ApiProductIdEnum.Building:
        return PolicyProductIdEnum.Building;
    }
}
