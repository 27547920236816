import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class InsurancePolicyEventService {
    private _policy$: Subject<void>;
    private _product$: Subject<void>;
    private _productDetails$: Subject<void>;
    private _allProductDetails$: Subject<void>;
    private _coverage$: Subject<void>;
    private _allCoverages$: Subject<void>;
    private _reviveProduct$: Subject<void>;
    private _startDateProduct$: Subject<void>;
    private _endProduct$: Subject<void>;
    private _endDateProduct$: Subject<void>;
    private _cancelAddedProduct$: Subject<void>;
    private _addPremiumDeterminingFactor$: Subject<void>;
    private _cancelAddedPremiumDeterminingFactor$: Subject<void>;
    private _addRegularDriver$: Subject<void>;
    private _endRegularDriver$: Subject<void>;
    private _cancelAddedRegularDriver$: Subject<void>;
    private _addCoverage$: Subject<void>;
    private _cancelAddedCoverage$: Subject<void>;

    public product$(): Observable<void> {
        this._product$ = new Subject();
        return this._product$.asObservable();
    }

    public onProductSuccess(): void {
        this._product$.next();
        this._product$.complete();
    }

    public onProductError(errors: ErrorInterface[]): void {
        this._product$.error(errors);
    }

    public policy$(): Observable<void> {
        this._policy$ = new Subject();
        return this._policy$.asObservable();
    }

    public onPolicySuccess(): void {
        this._policy$.next();
        this._policy$.complete();
    }

    public onPolicyError(errors: ErrorInterface[]): void {
        this._policy$.error(errors);
    }

    public productDetails$(): Observable<void> {
        this._productDetails$ = new Subject();
        return this._productDetails$.asObservable();
    }

    public onProductDetailsSuccess(): void {
        this._productDetails$.next();
        this._productDetails$.complete();
    }

    public onProductDetailsError(errors: ErrorInterface[]): void {
        this._productDetails$.error(errors);
    }

    public allProductDetails$(): Observable<void> {
        this._allProductDetails$ = new Subject();
        return this._allProductDetails$.asObservable();
    }

    public onAllProductDetailsSuccess(): void {
        this._allProductDetails$.next();
        this._allProductDetails$.complete();
    }

    public onAllProductDetailsError(errors: ErrorInterface[]): void {
        this._allProductDetails$.error(errors);
    }

    public coverage$(): Observable<void> {
        this._coverage$ = new Subject();
        return this._coverage$.asObservable();
    }

    public onCoverageSuccess(): void {
        this._coverage$.next();
        this._coverage$.complete();
    }

    public onCoverageError(errors: ErrorInterface[]): void {
        this._coverage$.error(errors);
    }

    public allCoverages$(): Observable<void> {
        this._allCoverages$ = new Subject();
        return this._allCoverages$.asObservable();
    }

    public onAllCoveragesSuccess(): void {
        this._allCoverages$.next();
        this._allCoverages$.complete();
    }

    public onAllCoveragesError(errors: ErrorInterface[]): void {
        this._allCoverages$.error(errors);
    }

    public reviveProduct$(): Observable<void> {
        this._reviveProduct$ = new Subject();
        return this._reviveProduct$.asObservable();
    }

    public onReviveProductSuccess(): void {
        this._reviveProduct$.next();
        this._reviveProduct$.complete();
    }

    public onReviveProductError(errors: ErrorInterface[]): void {
        this._reviveProduct$.error(errors);
    }

    public startDateProduct$(): Observable<void> {
        this._startDateProduct$ = new Subject();
        return this._startDateProduct$.asObservable();
    }

    public onStartDateProductSuccess(): void {
        this._startDateProduct$.next();
        this._startDateProduct$.complete();
    }

    public onStartDateProductError(errors: ErrorInterface[]): void {
        this._startDateProduct$.error(errors);
    }

    public endProduct$(): Observable<void> {
        this._endProduct$ = new Subject();
        return this._endProduct$.asObservable();
    }

    public onEndProductSuccess(): void {
        this._endProduct$.next();
        this._endProduct$.complete();
    }

    public onEndProductError(errors: ErrorInterface[]): void {
        this._endProduct$.error(errors);
    }

    public endDateProduct$(): Observable<void> {
        this._endDateProduct$ = new Subject();
        return this._endDateProduct$.asObservable();
    }

    public onEndDateProductSuccess(): void {
        this._endDateProduct$.next();
        this._endDateProduct$.complete();
    }

    public onEndDateProductError(errors: ErrorInterface[]): void {
        this._endDateProduct$.error(errors);
    }

    public cancelAddedProduct$(): Observable<void> {
        this._cancelAddedProduct$ = new Subject();
        return this._cancelAddedProduct$.asObservable();
    }

    public onCancelAddedProductSuccess(): void {
        this._cancelAddedProduct$.next();
        this._cancelAddedProduct$.complete();
    }

    public onCancelAddedProductError(errors: ErrorInterface[]): void {
        this._cancelAddedProduct$.error(errors);
    }

    public addPremiumDeterminingFactor$(): Observable<void> {
        this._addPremiumDeterminingFactor$ = new Subject();
        return this._addPremiumDeterminingFactor$.asObservable();
    }

    public onAddPremiumDeterminingFactorSuccess(): void {
        this._addPremiumDeterminingFactor$.next();
        this._addPremiumDeterminingFactor$.complete();
    }

    public onAddPremiumDeterminingFactorError(errors: ErrorInterface[]): void {
        this._addPremiumDeterminingFactor$.error(errors);
    }

    public cancelAddedPremiumDeterminingFactor$(): Observable<void> {
        this._cancelAddedPremiumDeterminingFactor$ = new Subject();
        return this._cancelAddedPremiumDeterminingFactor$.asObservable();
    }

    public onCancelAddedPremiumDeterminingFactorSuccess(): void {
        this._cancelAddedPremiumDeterminingFactor$.next();
        this._cancelAddedPremiumDeterminingFactor$.complete();
    }

    public onCancelAddedPremiumDeterminingFactorError(errors: ErrorInterface[]): void {
        this._cancelAddedPremiumDeterminingFactor$.error(errors);
    }

    public addRegularDriver$(): Observable<void> {
        this._addRegularDriver$ = new Subject();
        return this._addRegularDriver$.asObservable();
    }

    public onAddRegularDriverSuccess(): void {
        this._addRegularDriver$.next();
        this._addRegularDriver$.complete();
    }

    public onAddRegularDriverError(errors: ErrorInterface[]): void {
        this._addRegularDriver$.error(errors);
    }

    public endRegularDriver$(): Observable<void> {
        this._endRegularDriver$ = new Subject();
        return this._endRegularDriver$.asObservable();
    }

    public onEndRegularDriverSuccess(): void {
        this._endRegularDriver$.next();
        this._endRegularDriver$.complete();
    }

    public onEndRegularDriverError(errors: ErrorInterface[]): void {
        this._endRegularDriver$.error(errors);
    }

    public cancelAddedRegularDriver$(): Observable<void> {
        this._cancelAddedRegularDriver$ = new Subject();
        return this._cancelAddedRegularDriver$.asObservable();
    }

    public onCancelAddedRegularDriverSuccess(): void {
        this._cancelAddedRegularDriver$.next();
        this._cancelAddedRegularDriver$.complete();
    }

    public onCancelAddedRegularDriverError(errors: ErrorInterface[]): void {
        this._cancelAddedRegularDriver$.error(errors);
    }

    public addCoverage$(): Observable<void> {
        this._addCoverage$ = new Subject();
        return this._addCoverage$.asObservable();
    }

    public onAddCoverageSuccess(): void {
        this._addCoverage$.next();
        this._addCoverage$.complete();
    }

    public onAddCoverageError(errors: ErrorInterface[]): void {
        this._addCoverage$.error(errors);
    }

    public cancelAddedCoverage$(): Observable<void> {
        this._cancelAddedCoverage$ = new Subject();
        return this._cancelAddedCoverage$.asObservable();
    }

    public onCancelAddedCoverageSuccess(): void {
        this._cancelAddedCoverage$.next();
        this._cancelAddedCoverage$.complete();
    }

    public onCancelAddedCoverageError(errors: ErrorInterface[]): void {
        this._cancelAddedCoverage$.error(errors);
    }
}
