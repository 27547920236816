import { mapEnumApiResponse } from '../../core';
import {
    ApiBusinessRelationLegalPartyVersionIdEnum,
    ApiV1BusinessRelationLegalPartyVersionIdEnum,
    BusinessRelationLegalPartyVersionIdEnum,
} from '../enums';

export function mapBusinessRelationLegalPartyVersionId(
    response: ApiBusinessRelationLegalPartyVersionIdEnum,
    enablePartyCodes?: boolean
): BusinessRelationLegalPartyVersionIdEnum {
    const partyRoleEnumType = enablePartyCodes
        ? ApiV1BusinessRelationLegalPartyVersionIdEnum
        : ApiBusinessRelationLegalPartyVersionIdEnum;
    return mapEnumApiResponse(response, partyRoleEnumType, BusinessRelationLegalPartyVersionIdEnum);
}
