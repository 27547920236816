import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { contactReducer } from './store';
import { ContactEffects } from './store/contact.effects';
import { CONTACT_FEATURE } from './store/contact.state';

@NgModule({
    imports: [StoreModule.forFeature(CONTACT_FEATURE, contactReducer), EffectsModule.forFeature([ContactEffects])],
})
export class OutContactModule {}
