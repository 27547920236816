import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ActiveModal {
    public close(result?: any): void {
        // FIXME: whats this?
    }

    public dismiss(reason?: any): void {
        // FIXME: whats this?
    }
}
