import { ApiClaimCoverageHandlingResponseInterface } from '../interfaces/api.claim-coverage-handling-response.interface';
import { ClaimCoverageHandlingResponseInterface } from '../interfaces/claim-coverage-handling-response.interface';

export function mapHandlingResponse(
    response: ApiClaimCoverageHandlingResponseInterface
): ClaimCoverageHandlingResponseInterface {
    return {
        handlingCode: response.handle_code,
        handlingDescription: response.handling_description,
        note: response.note,
    };
}
