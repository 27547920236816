import { Action } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { ContentElementRequestInterface, ContentInterface } from '../interfaces';

export enum ContentActions {
    RequestElement = '[Content] Request element',
    RequestElementSuccess = '[Content] Request element success',
    RequestElementError = '[Content] Request element error',
}

export class RequestElementAction implements Action {
    public readonly type = ContentActions.RequestElement;

    constructor(public payload: { request: ContentElementRequestInterface }) {}
}

export class RequestElementSuccessAction implements Action {
    public readonly type = ContentActions.RequestElementSuccess;

    constructor(public payload: { name: string; contentElements: ContentInterface[] }) {}
}

export class RequestElementErrorAction implements Action {
    public readonly type = ContentActions.RequestElementError;

    constructor(public payload: { name: string; errors: ErrorInterface[] }) {}
}

export type ContentActionType = RequestElementAction | RequestElementSuccessAction | RequestElementErrorAction;
