import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DateStruct } from '../interfaces';
import 'dayjs/locale/nl'; // TODO : Make international

// private interface
interface DateCalculationObject {
    date: dayjs.ConfigType;
    unit: dayjs.ManipulateType;
    format: string;
    value: number;
    locale: string;
}

interface Locale {
    locale: string;
}

@Injectable({
    providedIn: 'root',
})
export class DateService {
    private unitTypesArray = ['millisecond', 'second', 'minute', 'hour', 'day', 'month', 'year', 'date'];
    private unitTypeShortsArray = ['d', 'M', 'y', 'h', 'm', 's', 'ms'];

    public date(date?: string | number | Date | dayjs.Dayjs): dayjs.Dayjs {
        /* cannot create a scenario in the unit test where the package is not present, excluding next line from coverage statistics */
        /* istanbul ignore next */
        if (!dayjs) {
            return;
        }
        dayjs.locale('nl-nl');
        dayjs.extend(customParseFormat);
        dayjs.extend(advancedFormat);
        dayjs.extend(localizedFormat);

        return dayjs(date);
    }

    /* predefined specific functions */

    /**
     * CAUTION
     * You want to prevent using this.
     * In most cases you do not need to use this.
     */
    public now(): dayjs.Dayjs {
        return this.date();
    }

    /**
     * CAUTION
     * You want to prevent using this.
     * In most cases you do not need to use this.
     */
    public dateAsObject(date: dayjs.ConfigType | DateStruct): dayjs.Dayjs {
        return this.date(this.convertNgbDateStruct(date));
    }

    public getStartDate(): DateStruct {
        return {
            year: this.date().year(),
            month: this.date().month() + 1,
            day: this.date().day(),
        };
    }

    public nowAsString(format: string = 'YYYY-MM-DD'): string {
        return this.date().format(format);
    }

    public fromString(date: string, format: string = 'YYYY-MM-DD'): string {
        return this.date(date).format(format);
    }

    public nowAsNgbDate(): DateStruct {
        return {
            year: this.date().year(),
            month: this.date().month() + 1,
            day: this.date().day(),
        };
    }

    /* calculations */

    public add(...args): string {
        const dateCalculationObject = this.handleDateCalculationArgs(args);
        const date = this.date(dateCalculationObject.date);
        if (dateCalculationObject.locale) {
            return date
                .locale(dateCalculationObject.locale)
                .add(dateCalculationObject.value, dateCalculationObject.unit)
                .format(dateCalculationObject.format);
        }
        return date.add(dateCalculationObject.value, dateCalculationObject.unit).format(dateCalculationObject.format);
    }

    public subtract(...args): string {
        const dateCalculationObject = this.handleDateCalculationArgs(args);
        const date = this.date(dateCalculationObject.date);
        if (dateCalculationObject.locale) {
            return date
                .locale(dateCalculationObject.locale)
                .subtract(dateCalculationObject.value, dateCalculationObject.unit)
                .format(dateCalculationObject.format);
        }
        return date
            .subtract(dateCalculationObject.value, dateCalculationObject.unit)
            .format(dateCalculationObject.format);
    }

    /* checks */

    public isAfter(
        toCheck: dayjs.ConfigType | DateStruct,
        after: dayjs.ConfigType | DateStruct = this.date(),
        unit?: dayjs.OpUnitType
    ): boolean {
        toCheck = this.convertNgbDateStruct(toCheck);
        after = this.convertNgbDateStruct(after);
        return this.date(toCheck).isAfter(after, unit ? unit : undefined);
    }

    public isBefore(
        toCheck: dayjs.ConfigType | DateStruct,
        before: dayjs.ConfigType | DateStruct = this.date(),
        unit?: dayjs.OpUnitType
    ): boolean {
        toCheck = this.convertNgbDateStruct(toCheck);
        before = this.convertNgbDateStruct(before);

        return this.date(toCheck).isBefore(this.date(before), unit ? unit : undefined);
    }

    public isSame(
        toCheck: dayjs.ConfigType | DateStruct,
        same: dayjs.ConfigType | DateStruct = this.date(),
        unit?: dayjs.OpUnitType
    ): boolean {
        toCheck = this.convertNgbDateStruct(toCheck);
        same = this.convertNgbDateStruct(same);
        return this.date(toCheck).isSame(same, unit ? unit : undefined);
    }

    public isValid(value: dayjs.ConfigType | DateStruct): boolean {
        value = this.convertNgbDateStruct(value);
        return this.date(value).isValid();
    }

    /* display functions */

    public format(
        date: dayjs.ConfigType | DateStruct = this.date(),
        format: string = 'YYYY-MM-DD',
        locale?: string
    ): string {
        date = this.convertNgbDateStruct(date);

        if (locale) {
            return this.date(date).locale(locale).format(format);
        }
        return this.date(date).format(format);
    }

    public year(date: dayjs.ConfigType | DateStruct = this.date()): number {
        if (this.isNgbDateStruct(date)) {
            return this.date(this.dateStructToString(date)).year();
        }
        return this.date(date).year();
    }

    public month(date: dayjs.ConfigType | DateStruct = this.date()): number {
        if (this.isNgbDateStruct(date)) {
            return this.date(this.dateStructToString(date)).month() + 1;
        }
        return this.date(date).month() + 1;
    }

    public day(date: dayjs.ConfigType | DateStruct = this.date()): number {
        if (this.isNgbDateStruct(date)) {
            return this.date(this.dateStructToString(date)).date();
        }
        return this.date(date).date();
    }

    public toNgbDateStruct(date?: dayjs.ConfigType): DateStruct {
        return {
            year: this.year(date ? date : undefined),
            month: this.month(date ? date : undefined),
            day: this.day(date ? date : undefined),
        };
    }

    public fromNgbDateToString(date: DateStruct, format: string = 'YYYY-MM-DD'): string {
        return this.format(`${date.year}-${date.month}-${date.day}`, format);
    }

    public calculateAge(
        date: { year: number; month?: number; day?: number },
        compareDate?: { year: number; month: number; day: number }
    ): number {
        const currentMonth = compareDate ? compareDate.month : this.month();
        const currentYear = compareDate ? compareDate.year : this.year();
        const currentDay = compareDate ? compareDate.day : this.day();

        let age = currentYear - date.year;

        if (!date.month) {
            date.month = 1;
        }
        if (!date.day) {
            date.day = 1;
        }

        const mdif = currentMonth - date.month;
        const ddif = currentDay - date.day;

        if (mdif === 0) {
            if (ddif < 0) {
                return --age;
            }
        }
        if (mdif < 0) {
            return --age;
        }
        return age;
    }

    /* internal functions */

    private isConfigType(arg): arg is dayjs.ConfigType {
        return this.date(arg).isValid();
    }

    private isUnitType(arg): arg is dayjs.ManipulateType {
        return this.unitTypesArray.includes(arg) || this.isUnitTypeShort(arg);
    }

    private isUnitTypeShort(arg): arg is dayjs.UnitTypeShort {
        return this.unitTypeShortsArray.includes(arg);
    }

    private isLocale(arg): arg is Locale {
        return !!arg.locale;
    }

    private isNgbDateStruct(arg): arg is DateStruct {
        return !!((arg as DateStruct).year && !(arg as dayjs.Dayjs).format);
    }

    private convertNgbDateStruct(arg): dayjs.ConfigType {
        return this.isNgbDateStruct(arg) ? this.dateStructToString(arg) : arg;
    }

    private dateStructToString(struct: DateStruct): string {
        return `${struct.year}-${struct.month}-${struct.day}`;
    }

    private handleDateCalculationArgs(args): DateCalculationObject {
        let date;
        let format = 'YYYY-MM-DD';
        let value = 0;
        let unit = 'day' as dayjs.ManipulateType;
        let locale;

        args.forEach((arg) => {
            if (this.isUnitType(arg)) {
                unit = arg;
            } else if (typeof arg === 'number') {
                value = arg;
            } else if (this.isConfigType(arg)) {
                date = arg;
            } else if (this.isNgbDateStruct(arg)) {
                date = this.dateStructToString(arg);
            } else if (this.isLocale(arg)) {
                locale = arg.locale;
            } else if (typeof arg === 'string') {
                format = arg;
            } else {
                date = this.date();
            }
        });
        // noinspection JSUnusedAssignment
        return {
            date,
            unit,
            value,
            format,
            locale,
        };
    }
}
