import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CountriesApiService } from '../services/countries.api.service';
import { CountriesEventService } from '../services/countries.event.service';
import * as CountriesActions from './countries.actions';

@Injectable()
export class CountriesEffects {
    public getCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CountriesActions.getCountries),
            switchMap(() =>
                this.countriesApiService.getCountries$().pipe(
                    map(({ countries }) => CountriesActions.getCountriesSuccess({ countries })),
                    catchError((errors) => of(CountriesActions.getCountriesError({ errors })))
                )
            )
        );
    });

    public getCountriesError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CountriesActions.getCountriesError),
                tap(({ errors }) => this.countriesEventService.onGetCountriesError(errors))
            );
        },
        { dispatch: false }
    );

    public getCountriesSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CountriesActions.getCountriesSuccess),
                tap(() => this.countriesEventService.onGetCountriesSuccess())
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private countriesApiService: CountriesApiService,
        private countriesEventService: CountriesEventService
    ) {}
}
