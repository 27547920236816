import { Action } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    ClaimNotificationInterface,
    GetClaimNotificationPayloadInterface,
    SaveClaimNotificationPayloadInterface,
} from '../interfaces';

export enum ClaimNotificationActionEnum {
    GetClaimNotification = '[Claim Notification] Get Claim Notification',
    GetClaimNotificationSuccess = '[Claim Notification] Get Claim Notification Success',
    GetClaimNotificationError = '[Claim Notification] Get Claim Notification Error',

    SaveClaimNotification = '[Claim Notification] Save Claim Notification',
    SaveClaimNotificationSuccess = '[Claim Notification] Save Claim Notification Success',
    SaveClaimNotificationError = '[Claim Notification] Save Claim Notification Error',

    ResetClaimNotification = '[Claim Notification] Reset',
}

export class GetClaimNotificationAction implements Action {
    public readonly type = ClaimNotificationActionEnum.GetClaimNotification;
    constructor(public payload: GetClaimNotificationPayloadInterface) {}
}

export class GetClaimNotificationSuccessAction implements Action {
    public readonly type = ClaimNotificationActionEnum.GetClaimNotificationSuccess;
    constructor(public payload: { response: ClaimNotificationInterface }) {}
}

export class GetClaimNotificationErrorAction implements Action {
    public readonly type = ClaimNotificationActionEnum.GetClaimNotificationError;
    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class SaveClaimNotificationAction implements Action {
    public readonly type = ClaimNotificationActionEnum.SaveClaimNotification;
    constructor(public payload: SaveClaimNotificationPayloadInterface) {}
}

export class SaveClaimNotificationSuccessAction implements Action {
    public readonly type = ClaimNotificationActionEnum.SaveClaimNotificationSuccess;
    constructor(public payload: { response: ClaimNotificationInterface }) {}
}

export class SaveClaimNotificationErrorAction implements Action {
    public readonly type = ClaimNotificationActionEnum.SaveClaimNotificationError;
    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class ResetClaimNotificationAction implements Action {
    public readonly type = ClaimNotificationActionEnum.ResetClaimNotification;
}

export type ClaimNotificationActionType =
    | GetClaimNotificationAction
    | GetClaimNotificationSuccessAction
    | GetClaimNotificationErrorAction
    | SaveClaimNotificationAction
    | SaveClaimNotificationSuccessAction
    | SaveClaimNotificationErrorAction
    | ResetClaimNotificationAction;
