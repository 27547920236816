export enum ApiClaimCategoryEnum {
    Liability = 'AANS',
    Car = 'AUTO',
    Moped = 'BROM',
    Caravan = 'CVAN',
    Bicycle = 'FIET',
    Pet = 'DIER',
    Motorcycle = 'MOTR',
    Accidents = 'ONGV',
    LegalAssistance = 'RB',
    Travel = 'REIS',
    Home = 'WOON',
}
