import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, exhaustMap, tap, mergeMap } from 'rxjs/operators';
import { ClaimSpecificationApiService } from '../services/claim-specification.api.service';
import { ClaimSpecificationEventService } from '../services/claim-specification.event.service';
import * as ClaimSpecificationActions from './claim-specification.actions';

@Injectable({
    providedIn: 'root',
})
export class ClaimSpecificationEffects {
    // get specification
    public getSpecification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimSpecificationActions.getSpecification),
            exhaustMap(({ request }) =>
                this.claimsSpecificationApiService.getSpecification$(request).pipe(
                    map((response) => ClaimSpecificationActions.getSpecificationSuccess({ response })),
                    catchError((errors) => of(ClaimSpecificationActions.getSpecificationFailure({ errors })))
                )
            )
        );
    });

    public getSpecificationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.getSpecificationSuccess),
                tap(({ response }) => this.claimsSpecificationEventService.onGetSpecificationSuccess(response))
            );
        },
        { dispatch: false }
    );

    public getSpecificationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.getSpecificationFailure),
                tap(({ errors }) => this.claimsSpecificationEventService.onGetSpecificationError(errors))
            );
        },
        { dispatch: false }
    );

    // resume specification
    public resumeSpecification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimSpecificationActions.resumeSpecification),
            exhaustMap(({ request }) =>
                this.claimsSpecificationApiService.resumeSpecification$(request).pipe(
                    map((response) => ClaimSpecificationActions.resumeSpecificationSuccess({ response })),
                    catchError((errors) => of(ClaimSpecificationActions.resumeSpecificationFailure({ errors })))
                )
            )
        );
    });

    public resumeSpecificationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.resumeSpecificationSuccess),
                tap(() => this.claimsSpecificationEventService.onResumeSpecificationSuccess())
            );
        },
        { dispatch: false }
    );

    public resumeSpecificationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.resumeSpecificationFailure),
                tap(({ errors }) => this.claimsSpecificationEventService.onResumeSpecificationError(errors))
            );
        },
        { dispatch: false }
    );

    // update specification
    public updateSpecification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimSpecificationActions.updateSpecification),
            mergeMap(({ request }) =>
                this.claimsSpecificationApiService.updateSpecification$(request).pipe(
                    map((response) => ClaimSpecificationActions.updateSpecificationSuccess({ response })),
                    catchError((errors) => of(ClaimSpecificationActions.updateSpecificationFailure({ errors })))
                )
            )
        );
    });

    public updateSpecificationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.updateSpecificationSuccess),
                tap(() => this.claimsSpecificationEventService.onUpdateSpecificationSuccess())
            );
        },
        { dispatch: false }
    );

    public updateSpecificationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.updateSpecificationFailure),
                tap(({ errors }) => this.claimsSpecificationEventService.onUpdateSpecificationError(errors))
            );
        },
        { dispatch: false }
    );

    // remove specification
    public removeSpecification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimSpecificationActions.removeSpecification),
            mergeMap(({ request }) =>
                this.claimsSpecificationApiService.removeSpecification$(request).pipe(
                    map((response) => ClaimSpecificationActions.removeSpecificationSuccess({ response })),
                    catchError((errors) => of(ClaimSpecificationActions.removeSpecificationFailure({ errors })))
                )
            )
        );
    });

    public removeSpecificationSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.removeSpecificationSuccess),
                tap(() => this.claimsSpecificationEventService.onRemoveSpecificationSuccess())
            );
        },
        { dispatch: false }
    );

    public removeSpecificationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimSpecificationActions.removeSpecificationFailure),
                tap(({ errors }) => this.claimsSpecificationEventService.onRemoveSpecificationError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private claimsSpecificationApiService: ClaimSpecificationApiService,
        private claimsSpecificationEventService: ClaimSpecificationEventService
    ) {}
}
