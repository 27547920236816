import { PurchasedProductInterface } from '../../insurance-policy';
import { ClaimCategoryInterface } from '../interfaces';

export interface ClaimCategories {
    claimCategories: ClaimCategoriesState;
}

export interface ClaimCategoriesState {
    claimCategories: ClaimCategoryInterface[];
    purchasedProducts: PurchasedProductInterface[];
}

export const INITIAL_CLAIM_CATEGORIES_STATE: ClaimCategoriesState = {
    claimCategories: [],
    purchasedProducts: [],
};

export const CLAIM_CATEGORIES_FEATURE = 'claimCategories';
