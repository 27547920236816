import { mapSequenceNumber } from '../../../core';
import { ApiCancelAddedRegularDriverRequest, CancelAddedRegularDriverRequestInterface } from '../../interfaces';

export function mapCancelAddedRegularDriverRequest(
    request: CancelAddedRegularDriverRequestInterface
): ApiCancelAddedRegularDriverRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
