import { mapSuccessIndication } from '../../core';
import {
    ApiCompleteGeneratedAccountRequest,
    ApiCompleteGeneratedAccountResponse,
    CompleteGeneratedAccountRequestInterface,
    CompleteGeneratedAccountResponseInterface,
} from '../interfaces';

export function mapCompleteGeneratedAccountRequest(
    request: CompleteGeneratedAccountRequestInterface,
): ApiCompleteGeneratedAccountRequest {
    return {
        current_password: request.temporaryPassword,
        new_password: request.newPassword,
        recovery_email_address: {
            email_address: request?.recoveryEmail || null,
        },
        mobile_telephone_number: {
            telephone_number: request.phone,
        },
        opt_out_indication: mapSuccessIndication(request.optOut),
    };
}

export function mapCompleteGeneratedAccountResponse(response: ApiCompleteGeneratedAccountResponse): CompleteGeneratedAccountResponseInterface {
    return {
        oneCentPaymentRequired: response.one_cent_payment_required,
        passwordChangedSuccessfully: response.password_changed_successfully,
        redirectUrl: response.redirect_url,
    };
}
