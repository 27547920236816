import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import {
    AggregateInterface,
    ApiAggregateResponseInterface,
    AggregateRequestInterface,
} from '../interfaces';
import { mapAggregateRequest } from '../mappers/aggregate.request.mapper';
import { mapAggregateResponse } from '../mappers/aggregate.response.mapper';

const RESOURCES = {
    GET_AGGREGATE: 'en-gb/reviews-general/request/aggregate',
};

@Injectable({
    providedIn: 'root',
})
export class AggregateApiService {
    public constructor(private apiService: ApiService) {}

    public getAggregate$(payload: AggregateRequestInterface): Observable<AggregateInterface> {
        return this.apiService
            .post$<ApiAggregateResponseInterface>(RESOURCES.GET_AGGREGATE, mapAggregateRequest(payload))
            .pipe(map((response) => mapAggregateResponse(payload, response)));
    }
}
