import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationActionEnum } from '../../../authentication/store';
import { SalesStorageService } from '../../services/sales-storage.service';
import {
    ChooseReplaceNo,
    ChooseReplaceYes,
    CHOOSE_REPLACE_NO,
    CHOOSE_REPLACE_YES,
    ResetVehicleReplacement,
} from './vehicle-replace.actions';

@Injectable({
    providedIn: 'root',
})
export class VehicleReplaceEffects {
    public chooseReplaceYes$: Observable<ChooseReplaceYes> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CHOOSE_REPLACE_YES),
                tap((action: ChooseReplaceYes) => {
                    this.storageService.setItem('vehicleReplaceChoice', action.payload.replacement);
                })
            );
        },
        { dispatch: false }
    );

    // WINS-3862: nog even uit omdat het niet 100% goed werkt na refresh
    // @Effect() init$: Observable<Action> = this.init;
    public chooseReplaceNo$: Observable<ChooseReplaceNo> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(CHOOSE_REPLACE_NO),
                tap((action: ChooseReplaceNo) => this.storageService.setItem('vehicleReplaceChoice', false))
            );
        },
        { dispatch: false }
    );

    // WINS-3862: nog even uit omdat het niet 100% goed werkt na refresh
    // @Effect() initChoice$: Observable<Action> = this.initChoice;
    public resetVehicleReplacement$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthenticationActionEnum.Logout),
            map(() => new ResetVehicleReplacement())
        );
    }
    );

    constructor(
        private actions$: Actions,
        private storageService: SalesStorageService
    ) { }
}
