import { ApiGetClaimCoverageResponseInterface, GetClaimCoverageResponseInterface } from '../interfaces';
import { mapClaimCoverageResponse } from './claim-coverage.mapper';

export function mapGetClaimCoverageResponse(
    response: ApiGetClaimCoverageResponseInterface
): GetClaimCoverageResponseInterface {
    return {
        claimCoverage: mapClaimCoverageResponse(response),
    };
}
