import { createReducer, on } from '@ngrx/store';
import * as addressActions from './address.actions';
import { AddressState, INITIAL_ADDRESS_STATE } from './address.state';

export const addressReducer = createReducer(
    INITIAL_ADDRESS_STATE,
    on(
        addressActions.fetchAddressSuccess,
        (state, { address }): AddressState => ({
            ...state,
            address,
        })
    ),
    on(
        addressActions.clearAddress,
        (state): AddressState => ({
            ...state,
            address: null,
        })
    )
);
