import { mapSequenceNumber } from '../../core';
import {
    ApiGetPartiesRequestInterface,
    ApiGetPartiesResponseInterface,
    GetPartiesRequestInterface,
    GetPartiesResponseInterface,
} from '../interfaces';
import { mapLegalPersonResponse } from './legal-person.mapper';
import { mapNaturalPersonResponse } from './natural-person.mapper';
import { mapRelatedPartyTypesResponse } from './related-party-types.mapper';

export function mapGetPartiesRequest(request: GetPartiesRequestInterface): ApiGetPartiesRequestInterface {
    return {
        key: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
    };
}

export function mapGetPartiesResponse(
    response: ApiGetPartiesResponseInterface,
    caseSequenceNumber: number,
    enablePartyCodes?: boolean
): GetPartiesResponseInterface {
    return {
        parties: {
            legalParties: mapLegalPersonResponse(response.legal_person, enablePartyCodes),
            naturalParties: mapNaturalPersonResponse(response.natural_person, enablePartyCodes),
            relatedPartyTypes: mapRelatedPartyTypesResponse(response.related_party, caseSequenceNumber),
        },
    };
}
