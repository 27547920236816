import { mapFromSuccessIndication } from '../../core';
import { ApiGetCountriesResponseInterface, GetCountriesResponseInterface } from '../interfaces';

export function mapGetCountriesResponse(response: ApiGetCountriesResponseInterface): GetCountriesResponseInterface {
    return {
        countries: response.country.map((apiCountry) => ({
            countryId: apiCountry.country_id,
            countryDescription: apiCountry.country_description,
            telephoneCountryCode: apiCountry.telephone_country_code,
            internationalLicensePlateCountryCode: apiCountry.international_license_plate_country_code,
            internationalMotorInsuranceCardIndication: mapFromSuccessIndication(
                apiCountry.international_motor_insurance_card_indication
            ),
            internationalMotorInsuranceCardIndicationDescription:
                apiCountry.international_motor_insurance_card_indication_description,
            memberEuropeanUnionIndication: mapFromSuccessIndication(apiCountry.member_european_union_indication),
            memberEuropeanUnionIndicationDescription: apiCountry.member_european_union_indication_description,
            motorvehicleOffice: apiCountry.motorvehicle_office,
            organizationId: apiCountry.organization_id,
            organizationDescription: apiCountry.organization_description,
            employeeId: apiCountry.employee_id,
            createdTimestamp: apiCountry.created_timestamp,
        })),
    };
}
