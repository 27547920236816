import { createReducer, on } from '@ngrx/store';
import { getAggregateAction, getAggregateSuccessAction } from './aggregate.actions';
import { AggregateState, INITIAL_AGGREGATE_STATE } from './aggregate.state';

export const aggregateReducers = createReducer(
    INITIAL_AGGREGATE_STATE,
    on(
        getAggregateAction,
        (state): AggregateState => ({
            ...state,
            aggregates: [...state.aggregates],
        })
    ),

    on(getAggregateSuccessAction, (state, action): AggregateState => {
        const hasState = state.aggregates.some((aggregate) => aggregate.key === action.key);

        const aggregates = hasState
            ? state.aggregates.map((aggregate) =>
                aggregate.key === action.key ? { ...aggregate, ...action } : aggregate
            )
            : [...state.aggregates, action];

        return {
            ...state,
            aggregates,
        };
    })
);
