import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class CountriesEventService {
    private _getCountries$: Subject<void>;

    public getCountries$(): Observable<void> {
        this._getCountries$ = new Subject();
        return this._getCountries$.asObservable();
    }

    public onGetCountriesSuccess(): void {
        this._getCountries$.next();
        this._getCountries$.complete();
    }

    public onGetCountriesError(errors: ErrorInterface[]): void {
        this._getCountries$.error(errors);
    }
}
