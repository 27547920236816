import { Action } from '@ngrx/store';
import { NotificationInterface, type } from '../../../core';
import { Customer, Indications, Insurance } from '../../interfaces';
import { PaymentPeriodEnum } from '../../enums';

export const SAVE_CAR_INSURANCE_DATA = type('[Car Salesfunnel] Save Data');
export const SAVE_BICYCLE_INSURANCE_DATA = type('[Bicycle Salesfunnel] Save Data');
export const SAVE_HOME_INSURANCE_DATA = type('[Home Salesfunnel] Save Data');
export const SAVE_CARAVAN_INSURANCE_DATA = type('[Caravan Salesfunnel] Save Data');
export const SAVE_INSURANCE_DATA_SUCCESS = type('[Eligibility API] Save Data Success');
export const SAVE_INSURANCE_DATA_ERROR = type('[Eligibility API] Save Data Error');
export const PREFILL_CAR_INSURANCE_DATA = type('[Car Salesfunnel] Prefill Data');
export const STORE_INSURANCE_DATA = type('[Salesfunnel] Store Insurance Data');
export const TOGGLE_PAYMENT_PERIOD = type('[Salesfunnel] Toggle payment period');
export const CLEAR_INSURANCE_DATA = type('[Salesfunnel] Clear Insurance Data');
export const SET_INSURANCE_CAMPAIGN_CODE = type('[Salesfunnel] Set Campaign Code');
export const CLEAR_INSURANCE_CAMPAIGN_CODE = type('[Salesfunnel] Clear Campaign Code');
export const REQUEST_INSURANCE = type('[Salesfunnel] Request Insurance');
export const REQUEST_INSURANCE_SUCCESS = type('[Insurance API] Request Insurance Success');
export const INSURE_CAR = type('[Car Salesfunnel] Insure');
export const INSURE_EXTRA_CAR = type('[Car Salesfunnel] Insure Extra Car');
export const INSURE_BICYCLE = type('[Bicycle Salesfunnel] Insure');
export const INSURE_EXTRA_BICYCLE = type('[Bicycle Salesfunnel] Insure Extra Bicycle');
export const INSURE_SUCCESS = type('[Insurance API] Insurance Success');
export const INSURE_ERROR = type('[Insurance API] Insurance Error');
export const VALIDATE_ACCEPTANCE = type('[Salesfunnel] Validate Acceptance');
export const VALIDATE_ELIGIBILITY_SUCCESS = type('[Eligibility API] Validation Success');
export const VALIDATE_ELIGIBILITY_ERROR = type('[Eligibility API] Validation Error');
export const RESET_ERRORS = type('[Salesfunnel] Reset Errors');

class Insure implements Action {
    public readonly type: string; // TODO : initialize value (invalid action dispatched)

    constructor(public payload: { indications?: Indications }) {}
}

export class RequestInsurance extends Insure {
    public readonly type = REQUEST_INSURANCE;
}

export class RequestInsuranceSuccess implements Action {
    public readonly type = REQUEST_INSURANCE_SUCCESS;

    constructor(
        public payload: {
            productId: string;
            insurance: Insurance;
            customer: Customer;
            indications: Indications;
            coverages: { coverage_id: string }[];
            relation?: {
                relation_id: number;
                evb_number?: string;
            };
            token?: {
                token: string;
            };
        }
    ) {}
}

export class InsureCar extends Insure {
    public readonly type = INSURE_CAR;
}

export class InsureExtraCar extends Insure {
    public readonly type = INSURE_EXTRA_CAR;
}

export class InsureBicycle extends Insure {
    public readonly type = INSURE_BICYCLE;
}

export class InsureExtraBicycle extends Insure {
    public readonly type = INSURE_EXTRA_BICYCLE;
}

export class InsuranceSuccess implements Action {
    public readonly type = INSURE_SUCCESS;

    constructor(
        public payload: {
            productId: string;
            customer?: Customer;
            insurance?: Insurance;
            relation?: { relation_id: number; evb_number?: string };
            token?: { token: string };
        }
    ) {}
}

export class InsuranceError implements Action {
    public readonly type = INSURE_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

class ValidateEligibility implements Action {
    public readonly type: string; // TODO : initialize value (invalid action dispatched)

    constructor(
        public payload: {
            productId: string;
            customer?: Customer;
            insurance?: Insurance;
            validationStepCode?: string;
        }
    ) {}
}

export class ValidateAcceptance implements Action {
    public readonly type = VALIDATE_ACCEPTANCE;
}

export class SaveCarInsuranceData extends ValidateEligibility {
    public readonly type = SAVE_CAR_INSURANCE_DATA;
}

export class SaveBicycleInsuranceData extends ValidateEligibility {
    public readonly type = SAVE_BICYCLE_INSURANCE_DATA;
}

export class SaveHomeInsuranceData extends ValidateEligibility {
    public readonly type = SAVE_HOME_INSURANCE_DATA;
}

export class SaveCaravanInsuranceData extends ValidateEligibility {
    public readonly type = SAVE_CARAVAN_INSURANCE_DATA;
}

export class PrefillCarInsuranceData implements Action {
    public readonly type = PREFILL_CAR_INSURANCE_DATA;

    constructor(public payload: { productId: string; customer?: Partial<Customer>; insurance?: Partial<Insurance> }) {}
}

export class StoreInsuranceData implements Action {
    public readonly type = STORE_INSURANCE_DATA;

    constructor(public payload: { productId: string; insurance: Partial<Insurance> }) {}
}

export class ClearInsuranceData implements Action {
    public readonly type = CLEAR_INSURANCE_DATA;
}

export class SetInsuranceCampaignCode implements Action {
    public readonly type = SET_INSURANCE_CAMPAIGN_CODE;
    constructor(public payload: { productId: string; campaignCode: string }) {}
}

export class ClearInsuranceCampaignCode implements Action {
    public readonly type = CLEAR_INSURANCE_CAMPAIGN_CODE;
    constructor(public payload: { productId: string }) {}
}

export class TogglePaymentPeriod implements Action {
    public readonly type = TOGGLE_PAYMENT_PERIOD;
    constructor(public payload: { productId: string; paymentPeriod: PaymentPeriodEnum }) {}
}

export class ValidateEligibilitySuccess implements Action {
    public readonly type = VALIDATE_ELIGIBILITY_SUCCESS;

    constructor(
        public payload: {
            productId: string;
            customer?: Customer;
            insurance?: Insurance;
            authenticated?: boolean;
            validationStepCode?: string;
        }
    ) {}
}

export class ValidateEligibilityError implements Action {
    public readonly type = VALIDATE_ELIGIBILITY_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class ResetErrors implements Action {
    public readonly type = RESET_ERRORS;
}

export type InsuranceActionUnion =
    | SaveCarInsuranceData
    | SaveBicycleInsuranceData
    | SaveCaravanInsuranceData
    | SaveHomeInsuranceData
    | RequestInsurance
    | RequestInsuranceSuccess
    | InsureCar
    | InsureExtraCar
    | InsureBicycle
    | InsureExtraBicycle
    | InsuranceSuccess
    | InsuranceError
    | PrefillCarInsuranceData
    | StoreInsuranceData
    | ClearInsuranceData
    | TogglePaymentPeriod
    | SetInsuranceCampaignCode
    | ClearInsuranceCampaignCode
    | ValidateAcceptance
    | ValidateEligibilitySuccess
    | ValidateEligibilityError
    | ResetErrors;
