import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimSpecificationEffects } from './store/claim-specification.effects';
import { claimSpecificationReducer } from './store/claim-specification.reducer';
import { CLAIM_SPECIFICATION_FEATURE } from './store/claim-specification.state';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_SPECIFICATION_FEATURE, claimSpecificationReducer),
        EffectsModule.forFeature([ClaimSpecificationEffects]),
    ],
})
export class OutClaimSpecificationModule {}
