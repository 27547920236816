import { createReducer, on } from '@ngrx/store';
import * as ClaimCoverageActions from './claim-coverage.actions';
import { ClaimCoverageState, INITIAL_CLAIM_COVERAGE_STATE } from './claim-coverage.state';

export const claimCoverageReducer = createReducer(
    INITIAL_CLAIM_COVERAGE_STATE,
    on(
        ClaimCoverageActions.getClaimCompensationSuccess,
        (state, { response }): ClaimCoverageState => ({
            ...state,
            claimCompensation: response.claimCompensation,
        })
    ),
    on(
        ClaimCoverageActions.getClaimCoverageSuccess,
        (state, { response }): ClaimCoverageState => ({
            ...state,
            claimCoverage: response.claimCoverage,
        })
    ),
    on(ClaimCoverageActions.resetClaimCoverage, (): ClaimCoverageState => INITIAL_CLAIM_COVERAGE_STATE)
);
