import { createSelector } from '@ngrx/store';
import { Coverage, CoveragePeriod } from '../interfaces';
import { getCurrent } from './calculation/calculation.selectors';
import { getSelectedModules } from './coverage/coverage.selectors';
import * as fromInsurance from './insurance/insurance.selectors';
import { addCollectivityDiscounts, addPremiums, filterCoverages } from './sales.helpers';
import { selectStackDiscount } from './stack-discount/stack-discount.selectors';
import * as fromVehicleReplace from './vehicle-replace/vehicle-replace.selectors';
import { getCurrentInsuranceData } from './insurance/insurance.selectors';

export const selectReceipt = createSelector(
    getSelectedModules,
    getCurrent,
    getCurrentInsuranceData,
    (modules, calculation, insurance): Coverage[] => {
        if (modules.length === 0 || calculation === null) {
            return [];
        }

        const chosenPeriod = calculation?.product?.invoice_periods?.find(
            (coveragePeriod: CoveragePeriod) =>
                coveragePeriod.invoice_period_months_number === insurance.premium_period_amount
        );

        // Get xSell Coverages
        const xSellCoverages = (calculation.product.xsell || []).reduce((acc, curr) => [...acc, ...curr.coverages], []);

        // Filter coverages from modules if coverage is 'picked'
        const filteredBaseCoverages = filterCoverages(
            chosenPeriod ? chosenPeriod.base_coverages : calculation.product.base_coverages,
            modules
        );
        const filteredAdditionalCoverages = filterCoverages(
            chosenPeriod ? chosenPeriod.additional_coverage : calculation.product.additional_coverage,
            modules
        );
        const filteredXSellCoverages = filterCoverages(xSellCoverages, modules);

        return [...filteredBaseCoverages, ...filteredAdditionalCoverages, ...filteredXSellCoverages];
    }
);

export const selectTotalsExcludingDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.premium);
    }, 0);
});

export const selectTotalYearlyPayment = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.premium_without_factor);
    }, 0);
});

export const selectTotalYearlyPaymentInlcudingDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.premium_including_discount);
    }, 0);
});

export const selectDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.discount);
    }, 0);
});

export const getTotalsIncludingDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.premium_including_discount);
    }, 0);
});

export const getTotalsIncludingStackDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return currentValue.premium_including_stack_discount
            ? addPremiums(accumulator, currentValue.premium_including_stack_discount[0])
            : null;
    }, 0);
});

export const getTotalStackDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return currentValue.stack_discount ? addPremiums(accumulator, currentValue.stack_discount[0]) : null;
    }, 0);
});

export const getTotalStackDiscounts = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue, index) => {
        const totals = [];

        currentValue.stack_discount.forEach((stackDiscount) => {
            totals.push(addPremiums(accumulator[index], stackDiscount));
        });

        return totals;
    }, []);
});

export const getTotalCollectivityDiscount = createSelector(selectReceipt, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addCollectivityDiscounts(accumulator, currentValue.collectivity_discount);
    }, 0);
});

export const getAccountCosts = createSelector(getCurrent, (calculation) => {
    if (!calculation) {
        return 0;
    }
    return (calculation.account_kosten * (100 + calculation.premium_tax)) / 100;
});

export const stackDiscount = createSelector(selectStackDiscount, (stackDiscountState) => {
    return stackDiscountState?.stack_discount;
});

export const getProductReplacement = createSelector(
    fromInsurance.getCurrentInsuranceData,
    fromVehicleReplace.getCurrent,
    (insurance, motorVehiclesToReplace) => {
        if (!motorVehiclesToReplace) {
            return null;
        }

        if (!insurance || !insurance.hasOwnProperty('related_vehicle_purchased_product_sequence_number')) {
            return null;
        }

        if (motorVehiclesToReplace.purchased_product_replacement === null) {
            return null;
        }

        const purchasedProductSequenceNumber = insurance.related_vehicle_purchased_product_sequence_number;
        const purchasedProductReplacement = motorVehiclesToReplace.purchased_product_replacement.find(
            (product) => product.purchased_product_sequence_number === purchasedProductSequenceNumber
        );

        return purchasedProductReplacement ? purchasedProductReplacement : null;
    }
);
