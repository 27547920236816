import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';
import { GetSpecificationResponseInterface } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ClaimSpecificationEventService {
    private _getSpecification$: Subject<GetSpecificationResponseInterface>;
    private _resumeSpecification$: Subject<void>;
    private _updateSpecification$: Subject<void>;
    private _removeSpecification$: Subject<void>;

    public getSpecification$(): Observable<GetSpecificationResponseInterface> {
        this._getSpecification$ = new Subject<GetSpecificationResponseInterface>();
        return this._getSpecification$.asObservable();
    }

    public onGetSpecificationSuccess(response: GetSpecificationResponseInterface): void {
        this._getSpecification$.next(response);
        this._getSpecification$.complete();
    }

    public onGetSpecificationError(errors: ErrorInterface[]): void {
        this._getSpecification$.error(errors);
    }

    public resumeSpecification$(): Observable<void> {
        this._resumeSpecification$ = new Subject<void>();
        return this._resumeSpecification$.asObservable();
    }

    public onResumeSpecificationSuccess(): void {
        this._resumeSpecification$.next();
        this._resumeSpecification$.complete();
    }

    public onResumeSpecificationError(errors: ErrorInterface[]): void {
        this._resumeSpecification$.error(errors);
    }

    public updateSpecification$(): Observable<void> {
        this._updateSpecification$ = new Subject();
        return this._updateSpecification$.asObservable();
    }

    public onUpdateSpecificationSuccess(): void {
        this._updateSpecification$.next();
        this._updateSpecification$.complete();
    }

    public onUpdateSpecificationError(errors: ErrorInterface[]): void {
        this._updateSpecification$.error(errors);
    }

    public removeSpecification$(): Observable<void> {
        this._removeSpecification$ = new Subject();
        return this._removeSpecification$.asObservable();
    }

    public onRemoveSpecificationSuccess(): void {
        this._removeSpecification$.next();
        this._removeSpecification$.complete();
    }

    public onRemoveSpecificationError(errors: ErrorInterface[]): void {
        this._removeSpecification$.error(errors);
    }
}
