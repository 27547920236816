import {
    Coverage,
    Customer,
    Insurance,
    NewRelationBicyclePayload,
    Payload,
    ReturningRelationBicyclePayload,
} from '../../../interfaces';
import { newRelationPayload, nonRegularDriver, switchServiceIndication } from './insurance-payload.helper';

export const newRelationBicycleRequest = (
    coverages: Coverage[] = [],
    insurance: Insurance,
    customer: Customer
): Payload<NewRelationBicyclePayload> => {
    const payload: NewRelationBicyclePayload = {
        relation: newRelationPayload(insurance, customer),
        product_bonus_components: null,
        coverages: coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })),
        premium_factor: {
            family_composition_code: customer.family_composition_code,
            start_date: insurance.start_date,
            campaign_code: insurance.campaign_code,
            partner_id: insurance.partner_id,
            partner_component_code: insurance.partner_component_code,
            external_reference_id: insurance.external_reference_id,
        },
        object: {
            bicycle_key_number: insurance.bicycle_key_number,
            framenumber: insurance.framenumber,
            bicycle_brand: insurance.bicycle_brand,
            bicycle_model: insurance.bicycle_model,
            purchase_value_amount: insurance.purchase_value_amount,
            purchase_date: insurance.purchase_date,
            new_indication: insurance.new_indication,
        },
        indications: {
            switch_service_indication: switchServiceIndication(insurance),
        },
        codes: {
            in_verleden_aantal_gemelde_schades_code: insurance.in_verleden_aantal_gemelde_schades_code,
        },
    };

    if (['PRTR', 'KIND'].includes(insurance.relation_code)) {
        payload.non_regular_driver = nonRegularDriver(insurance);
    }

    if (customer.recovery_email_address) {
        payload.relation.recovery_email_address = {
            email_address: customer.recovery_email_address,
        };
    }

    return {
        url: 'en-gb/insurance-product/store/newrelation-product-bicycle',
        payload,
    };
};

export const returningRelationBicycleRequest = (
    coverages: Coverage[] = [],
    insurance: Insurance,
    customer: Customer
): Payload<ReturningRelationBicyclePayload> => {
    const payload: ReturningRelationBicyclePayload = {
        relation: {
            general: {
                birthdate: customer.birthdate,
            },
            residential_address: {
                postal_code: customer.postal_code,
            },
            indications: {
                criminal_record_indication: insurance.criminal_record_indication ? 'N' : 'J',
                insurance_denied_indication: insurance.insurance_denied_indication ? 'N' : 'J',
                condition_agreement_indication: insurance.condition_agreement_indication ? 'J' : 'N',
                electronic_policy_agreement_indication: insurance.electronic_policy_agreement_indication ? 'J' : 'N',
            },
        },
        non_regular_driver_transfer_indication: null,
        coverages: coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })),
        premium_factor: {
            family_composition_code: customer.family_composition_code,
            start_date: insurance.start_date,
            campaign_code: insurance.campaign_code,
            partner_id: insurance.partner_id,
            partner_component_code: insurance.partner_component_code,
            external_reference_id: insurance.external_reference_id,
        },
        object: {
            bicycle_key_number: insurance.bicycle_key_number,
            framenumber: insurance.framenumber,
            bicycle_brand: insurance.bicycle_brand,
            bicycle_model: insurance.bicycle_model,
            purchase_value_amount: insurance.purchase_value_amount,
            purchase_date: insurance.purchase_date,
            new_indication: insurance.new_indication,
        },
        indications: {
            switch_service_indication: switchServiceIndication(insurance),
        },
        codes: {
            in_verleden_aantal_gemelde_schades_code: insurance.in_verleden_aantal_gemelde_schades_code,
        },
    };

    if (
        ['PRTR', 'KIND'].includes(insurance.relation_code) &&
        insurance.non_regular_driver_transfer_indication !== 'J'
    ) {
        payload.non_regular_driver = nonRegularDriver(insurance);
    }

    return {
        url: 'en-gb/insurance-product/store/existingrelation-product-bicycle',
        payload,
    };
};
