import {
    ChatbotElementInterface,
    ChatbotElementPartInterface,
    ChatbotElementPartType,
    ChatbotElementType,
    ChatbotLinkInterface,
    ChatbotVideoInterface,
} from '../interfaces';

export class ChatbotAnswerParseService {
    protected static lineSplitPattern: RegExp = /\r?\n/;
    protected static unorderedListPattern: RegExp = /^\s?[*](?=[^*])\s?(.*)/g; // starts with * but not **
    protected static orderedListPattern: RegExp = /^\s?\d+\.\s?(.*)/g; // starts with 1.

    static textDecorationReplacements = [
        {
            regExp: /\*\*([^*]+)\*\*/g,
            replace: '<b>$1</b>',
        },
        {
            regExp: /_([^_]+)_/g,
            replace: '<i>$1</i>',
        },
    ];

    private dialogPath: string;

    constructor(private links: ChatbotLinkInterface[], private videos: ChatbotVideoInterface[]) {}

    /**
     *
     */
    public parseAnswer(text: string, dialogPath: string): ChatbotElementInterface[] {
        this.dialogPath = dialogPath;
        const lines = ChatbotAnswerParseService.splitIntoLines(text);
        const elements = this.createElements(lines);
        const elementsGroupedByUl = ChatbotAnswerParseService.groupElements(elements, 'unorderedList');
        return ChatbotAnswerParseService.groupElements(elementsGroupedByUl, 'orderedList');
    }

    private static splitIntoLines(answerText: string): string[] {
        return answerText
            .split(ChatbotAnswerParseService.lineSplitPattern)
            .map((value: string) => value.trim())
            .filter((part: string) => part.length);
    }

    /**
     * Creates elements based on an array of lines. Each line may contain a reference to a dialogoption, link or video.
     * @param lines Lines to be converted to elements
     * @private
     */
    private createElements(lines: string[]): ChatbotElementInterface[] {
        return lines.map((line: string) => {
            const unorderedListItem = ChatbotAnswerParseService.parseListItem(
                line,
                ChatbotAnswerParseService.unorderedListPattern
            );
            const orderedListItem = ChatbotAnswerParseService.parseListItem(
                line,
                ChatbotAnswerParseService.orderedListPattern
            );
            const paragraph = (line || '').trim().replace(/\\./g, '.');

            if (unorderedListItem) {
                return {
                    type: 'unorderedList',
                    parts: this.parseReplacements(unorderedListItem),
                };
            } else if (orderedListItem) {
                return {
                    type: 'orderedList',
                    parts: this.parseReplacements(orderedListItem),
                };
            } else {
                return {
                    type: 'paragraph',
                    parts: this.parseReplacements(paragraph),
                };
            }
        });
    }

    /**
     * Matches the text against an regExp pattern
     * @param text
     * @param pattern
     * @private
     */
    private static parseListItem(text: string, pattern: RegExp): string {
        const match = new RegExp(pattern).exec(text) || [];
        return match[1]?.trim();
    }

    private static groupElements(
        elements: ChatbotElementInterface[],
        type: ChatbotElementType
    ): ChatbotElementInterface[] {
        return elements.reduce((previousValue, currentValue, currentIndex, array) => {
            if (currentIndex && currentValue.type === type && array[currentIndex - 1].type === type) {
                previousValue[previousValue.length - 1].parts.push(currentValue.parts[0]);
            } else {
                previousValue.push(currentValue);
            }
            return previousValue;
        }, []);
    }

    /**
     * Looks for %{...} occurrences in the text and transforms the input to an array with dialogoption-, link- or video data
     * @param text Text to parce
     **/
    private parseReplacements(text: string): ChatbotElementPartInterface[] {
        const splitPattern = /(%{\w+\([^}]+\)})/g;
        const pattern = /%{(\w+)\(([^}]+)\)}/g;

        return text
            .split(splitPattern)
            .filter((part: string) => part.length)
            .map((part: string) => {
                if (part.startsWith('%')) {
                    const match = new RegExp(pattern).exec(part) || [];
                    const type = match[1];
                    const id = match[2];
                    const retvalue: ChatbotElementPartInterface = {
                        type: type.toLowerCase() as unknown as ChatbotElementPartType,
                        data: id,
                    };
                    switch (type) {
                        case 'Link':
                            retvalue.data = this.links.find((link: ChatbotLinkInterface) => link.id === Number(id));
                            break;
                        case 'Video':
                            retvalue.data = this.videos.find((link: ChatbotVideoInterface) => link.id === Number(id));
                            break;
                        case 'DialogOption':
                            retvalue.data = {
                                text: id,
                                path: this.dialogPath,
                            };
                            break;
                    }
                    return retvalue;
                } else {
                    return {
                        type: 'text',
                        data: this.replaceTextDecorations(part),
                    };
                }
            });
    }

    private replaceTextDecorations(text: string): string {
        if (!text) {
            return '';
        }
        return ChatbotAnswerParseService.textDecorationReplacements.reduce(
            (prev, curr): string => prev.replace(curr.regExp, curr.replace),
            text
        );
    }
}
