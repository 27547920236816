import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IndicationEnum, NotificationInterface } from '../../../core';
import { ProductIdEnum } from '../../enums';
import {
    NonRegularDriverPropsInterface,
    ProductBaseInterface,
    TerminateActivePurchasedProductRequestInterface,
    TerminateActivePurchasedProductResponseInterface,
} from '../../interfaces';
import * as fromMyZone from '../../my-zone';
import * as productActions from './product.actions';
import { ProductService } from './product.service';
import { CreateNonRegularDriverRequestInterface } from "../../interfaces/product/create-non-regular-driver.request.interface";

/** @deprecated use InsurancePolicyActionService */
@Injectable({
    providedIn: 'root',
})
export class ProductActionService {
    constructor(private store$: Store<fromMyZone.State>, private productService: ProductService) {}

    /** @deprecated use InsurancePolicyActionService */
    public loadPolicy(): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.LoadPolicy());

        return this.productService.loadPolicy({ present_history_indication: false }).pipe(
            tap((policy) => this.store$.dispatch(new productActions.LoadPolicySuccess(policy))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public downloadPolicy(indication: IndicationEnum): Observable<any> {
        this.store$.dispatch(new productActions.DownloadPolicy());

        return this.productService.downloadPolicy(indication).pipe(
            tap(() => this.store$.dispatch(new productActions.DownloadPolicySuccess())),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.DownloadPolicyError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public loadPurchasedProduct$(props: {
        product_id: ProductIdEnum;
        purchased_product_sequence_number: number;
    }): Observable<ProductBaseInterface | NotificationInterface[]> {
        this.store$.dispatch(new productActions.LoadPurchasedProduct());

        return this.productService.loadPurchasedProduct(props).pipe(
            tap((purchasedProduct) => {
                this.store$.dispatch(new productActions.LoadPurchasedProductSuccess(purchasedProduct));
            }),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFuturePurchasedProduct(props: {
        purchased_product_sequence_number: number;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.CancelFuturePurchasedProduct(props));

        return this.productService.cancelFuturePurchasedProduct(props).pipe(
            tap(() => this.store$.dispatch(new productActions.CancelFuturePurchasedProductSuccess(props))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public terminateActivePurchasedProduct(
        request: TerminateActivePurchasedProductRequestInterface
    ): Observable<TerminateActivePurchasedProductResponseInterface> {
        this.store$.dispatch(new productActions.TerminateActivePurchasedProduct(request));

        return this.productService.terminateActivePurchasedProduct(request).pipe(
            tap((response: TerminateActivePurchasedProductResponseInterface) =>
                this.store$.dispatch(
                    new productActions.TerminateActivePurchasedProductSuccess({
                        response,
                        purchasedProductSequenceNumber: request.purchasedProductSequenceNumber,
                        endDate: request.endDate,
                    })
                )
            ),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public reviveTerminatedPurchasedProduct(purchasedProductSequenceNumber: number): Observable<string> {
        this.store$.dispatch(new productActions.ReviveTerminatedPurchasedProduct({ purchasedProductSequenceNumber }));
        return this.productService.reviveTerminatedPurchasedProduct(purchasedProductSequenceNumber).pipe(
            tap((result) =>
                this.store$.dispatch(
                    new productActions.ReviveTerminatedPurchasedProductSuccess({
                        result,
                        purchasedProductSequenceNumber,
                    })
                )
            ),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public createNonRegularDriver$(request: CreateNonRegularDriverRequestInterface): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.CreateNonRegularDriver(request));

        return this.productService.createNonRegularDriver(request).pipe(
            tap(() => this.store$.dispatch(new productActions.CreateNonRegularDriverSuccess(request))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public storeNonRegularDriver(props: {
        purchased_product_sequence_number: number;
        non_regular_driver: NonRegularDriverPropsInterface;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.StoreNonRegularDriver(props));

        return this.productService.storeNonRegularDriver(props).pipe(
            tap(() => this.store$.dispatch(new productActions.StoreNonRegularDriverSuccess(props))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public requestNonRegularDriver$(props: {
        purchasedProductSequenceNumber: number;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.RequestNonRegularDriver(props));

        return this.productService.requestNonRegularDriver(props).pipe(
            tap(() => this.store$.dispatch(new productActions.RequestNonRegularDriverSuccess(props))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public terminateActiveNonRegularDriver$(props: {
        purchased_product_sequence_number: number;
        end_date: string;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.TerminateActiveNonRegularDriver(props));

        return this.productService.terminateActiveNonRegularDriver(props).pipe(
            tap(() => this.store$.dispatch(new productActions.TerminateActiveNonRegularDriverSuccess(props))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFutureNonRegularDriver$(props: {
        purchased_product_sequence_number: number;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.CancelFutureNonRegularDriver(props));

        return this.productService.cancelFutureNonRegularDriver(props).pipe(
            tap(() => {
                this.store$.dispatch(new productActions.CancelFutureNonRegularDriverSuccess(props));
            }),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public modifyFuturePurchasedProductStartDate(props: {
        purchased_product_sequence_number: number;
        start_date: string;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.ModifyFuturePurchasedProductStartDate(props));

        return this.productService.modifyFuturePurchasedProductStartDate(props).pipe(
            tap(() => this.store$.dispatch(new productActions.ModifyFuturePurchasedProductStartDateSuccess(props))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public modifyTerminationDateForProductEndDate(props: {
        purchased_product_sequence_number: number;
        end_date: string;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.ModifyTerminationDateForProductEndDate(props));

        return this.productService.modifyTerminationDateForProductEndDate(props).pipe(
            tap(() => this.store$.dispatch(new productActions.ModifyTerminationDateForProductEndDateSuccess(props))),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFutureModifiablePremiumDeterminingFactor(props: {
        purchased_product_sequence_number: number;
        premium_determining_factor_id: string;
    }): Observable<{ result: boolean } | NotificationInterface[]> {
        this.store$.dispatch(new productActions.CancelFutureModifiablePremiumDeterminingFactor(props));

        return this.productService.cancelFutureModifiablePremiumDeterminingFactor(props).pipe(
            tap(() => {
                this.store$.dispatch(new productActions.CancelFutureModifiablePremiumDeterminingFactorSuccess(props));
            }),
            map(() => ({ result: true })),
            catchError((notifications) => {
                this.store$.dispatch(new productActions.LoadError());
                return throwError(notifications);
            })
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public clear(): void {
        this.store$.dispatch(new productActions.Clear());
    }

    /** @deprecated use InsurancePolicyActionService */
    public select(purchasedProductSequenceNumber: number): void {
        this.store$.dispatch(
            new productActions.Select({ purchased_product_sequence_number: purchasedProductSequenceNumber })
        );
    }
}
