import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthenticationEffects, AuthenticationReducers, AUTHENTICATION_FEATURE } from './store';

@NgModule({
    imports: [
        StoreModule.forFeature(AUTHENTICATION_FEATURE, AuthenticationReducers.reducer),
        EffectsModule.forFeature([AuthenticationEffects]),
    ],
})
export class OutAuthenticationModule {}
