import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CHATBOT_CONFIGURATION } from './configuration';
import { ChatbotConfigurationInterface } from './interfaces';
import { OutChatbotService, OutLivechatService } from './services';

@NgModule({
    imports: [CommonModule],
    providers: [OutChatbotService, OutLivechatService],
})
export class OutChatbotModule {
    public static forRoot(chatbotConfiguration: ChatbotConfigurationInterface): ModuleWithProviders<OutChatbotModule> {
        return {
            ngModule: OutChatbotModule,
            providers: [
                {
                    provide: CHATBOT_CONFIGURATION,
                    useValue: chatbotConfiguration,
                },
            ],
        };
    }
}
