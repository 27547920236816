import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DAMAGE_FREE_YEARS_IMPACT_FEATURE, DamageFreeYearsImpactState } from './damage-free-years-impact.state';

export class DamageFreeYearsImpactSelectors {
    public static getDamageFreeYearsState = createFeatureSelector(DAMAGE_FREE_YEARS_IMPACT_FEATURE);

    public static getState = createSelector(
        DamageFreeYearsImpactSelectors.getDamageFreeYearsState,
        (state: DamageFreeYearsImpactState) => state
    );

    public static getDamageFreeYearsImpact = createSelector(
        DamageFreeYearsImpactSelectors.getDamageFreeYearsState,
        (state: DamageFreeYearsImpactState) => state.damageFreeYearsImpact
    );
}
