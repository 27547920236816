import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromAddress from './address/address.reducer';
import * as fromCalculation from './calculation/calculation.reducer';
import * as fromCoverage from './coverage/coverage.reducer';
import * as fromCustomer from './customer/customer.reducer';
import * as fromInsurance from './insurance/insurance.reducer';
import * as fromProgress from './progress/progress.reducer';
import * as fromStackDiscount from './stack-discount/stack-discount.reducer';
import * as fromVehicleReplace from './vehicle-replace/vehicle-replace.reducer';

export interface State {
    sales: SalesState;
}

export interface SalesState {
    insurance: fromInsurance.State;
    calculation: fromCalculation.State;
    progress: fromProgress.State;
    coverage: fromCoverage.State;
    customer: fromCustomer.State;
    address: fromAddress.State;
    vehicleReplace: fromVehicleReplace.State;
    stackDiscount: fromStackDiscount.State;
}

export const salesReducers: ActionReducerMap<SalesState> = {
    insurance: fromInsurance.reducer,
    calculation: fromCalculation.reducer,
    progress: fromProgress.reducer,
    coverage: fromCoverage.reducer,
    customer: fromCustomer.reducer,
    address: fromAddress.reducer,
    vehicleReplace: fromVehicleReplace.reducer,
    stackDiscount: fromStackDiscount.reducer,
};

export const getSalesState = createFeatureSelector<SalesState>('sales');
