import { ApiPremiumDeterminingFactorIdEnum, FactorIdEnum } from '../enums';

export function mapFactorId(response: ApiPremiumDeterminingFactorIdEnum): FactorIdEnum {
    switch (response) {
    case ApiPremiumDeterminingFactorIdEnum.DamageFreeYears: // Schadevrije jaren;
        return FactorIdEnum.DamageFreeYears;
    case ApiPremiumDeterminingFactorIdEnum.BonusMalusStep: // BM trede
        return FactorIdEnum.BonusMalusStep;
    case ApiPremiumDeterminingFactorIdEnum.KilometerPerYearCar: // Kilometers per jaar
        return FactorIdEnum.KilometerPerYearCar;
    case ApiPremiumDeterminingFactorIdEnum.PostalcodeRateMoped: // Postcode tarief bromfiets
        return FactorIdEnum.PostalcodeRateMoped;
    case ApiPremiumDeterminingFactorIdEnum.FuelMoped: // Brandstof Bromfiets
        return FactorIdEnum.FuelMoped;
    case ApiPremiumDeterminingFactorIdEnum.HailroofIndication: // Hageldak indicatie
        return FactorIdEnum.HailroofIndication;
    case ApiPremiumDeterminingFactorIdEnum.KilometerPerYearMotor: // Kilometers per jaar
        return FactorIdEnum.KilometerPerYearMotor;
    case ApiPremiumDeterminingFactorIdEnum.Content: // Inhoud
        return FactorIdEnum.Content;
    case ApiPremiumDeterminingFactorIdEnum.Strawroofing: // Dak met riet
        return FactorIdEnum.Strawroofing;
    case ApiPremiumDeterminingFactorIdEnum.MonumentCode: // Monument code
        return FactorIdEnum.MonumentCode;
    case ApiPremiumDeterminingFactorIdEnum.BuildYear: // Bouwjaar
        return FactorIdEnum.BuildYear;
    case ApiPremiumDeterminingFactorIdEnum.OwnRisk: // eigen risico
        return FactorIdEnum.OwnRisk;
    case ApiPremiumDeterminingFactorIdEnum.HomeType: // Type woonhuis
        return FactorIdEnum.HomeType;
    case ApiPremiumDeterminingFactorIdEnum.UserSurface: // Gebruiksoppervlakte
        return FactorIdEnum.UserSurface;
    case ApiPremiumDeterminingFactorIdEnum.Security: // Beveiliging
        return FactorIdEnum.Security;
    }
}
