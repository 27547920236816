import { mapEnumApiResponse } from '../../core';
import {
    ApiNaturalPartyTypeEnumCodeEnum,
    ApiV1NaturalPartyTypeEnumCodeEnum,
    NaturalPartyTypeEnumCodeEnum,
} from '../enums';

export function mapNaturalPartyTypeCodeResponse(
    response: ApiNaturalPartyTypeEnumCodeEnum,
    enablePartyCodes?: boolean
): NaturalPartyTypeEnumCodeEnum {
    const partyRoleEnumType = enablePartyCodes ? ApiV1NaturalPartyTypeEnumCodeEnum : ApiNaturalPartyTypeEnumCodeEnum;
    return mapEnumApiResponse<NaturalPartyTypeEnumCodeEnum>(response, partyRoleEnumType, NaturalPartyTypeEnumCodeEnum);
}
