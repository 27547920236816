import { PositionProcedureExplanationCodeEnum, ProcedureTypeCodeEnum } from '../enums';
import { ClaimStepResponseInterface } from '../interfaces';

export const CLAIM_STEPS_MOCKS: ClaimStepResponseInterface[] = [
    {
        procedureTypeCode: ProcedureTypeCodeEnum.AnnualBonus,
        procedureTypeDescription: 'A tyoe description',
        procedureTypeCodeDescription: 'A code description',
        procedureExplanationDescription: 'An explanation description',
        procedureExplanationCodeDescription: 'An explanation code description',
        positionProcedureExplanationCode: PositionProcedureExplanationCodeEnum.RecoverExcess,
        positionProcedureExplanationDescription: 'A procedure explanation description',
        positionProcedureExplanationCodeDescription: 'A procedure explanation code description',
        order: '1',
    },
];
