import { mapPhone } from '../../core/mappers';
import { ApiUpdatePersonalDetailsPayloadInterface, UpdatePersonalDetailsInterface } from '../interfaces';

export function mapUpdatePersonalDetails(
    payload: UpdatePersonalDetailsInterface
): ApiUpdatePersonalDetailsPayloadInterface {
    return {
        relation: {
            gender_code: payload.genderCode,
            mobile_telephone_number: {
                telephone_number: mapPhone(payload.mobileTelephoneNumber.telephoneNumber),
            },
            landline_telephone_number:
                payload?.landlineTelephoneNumber?.landlinePhoneNumber != null
                    ? {
                          telephone_number: mapPhone(payload.landlineTelephoneNumber.landlinePhoneNumber),
                      }
                    : {
                          telephone_number: null,
                      },
        },
    };
}
