import { ApiErrorLevelEnum, NotificationInterface } from '../../../core';
import { Premium } from '../../interfaces';
import * as insuranceActions from '../insurance/insurance.actions';
import * as vehicleReplaceActions from '../vehicle-replace/vehicle-replace.actions';
import * as vehicleActions from '../vehicle/vehicle.actions';
import * as calculationActions from './calculation.actions';

const errorFilter = (errorLevel: string) => {
    return (value: NotificationInterface): boolean => value.notification_type === errorLevel;
};

export interface State {
    current: Premium;
    history: Premium[];
    campaignCode: string;
    loading: boolean;
    errors: NotificationInterface[];
    notifications: NotificationInterface[];
}

const initialState: State = {
    current: null,
    history: [],
    campaignCode: null,
    loading: false,
    errors: [],
    notifications: [],
};

export function reducer(
    state: State = initialState,
    action:
        | calculationActions.CalculationActionUnion
        | insuranceActions.InsuranceActionUnion
        | vehicleActions.VehicleUnion
        | vehicleReplaceActions.VehicleReplaceActionUnion
): State {
    switch (action.type) {
        // for now replacement works as a reset
        case vehicleReplaceActions.CHOOSE_REPLACE_YES: {
            return {
                ...state,
                current: null,
                loading: false,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.CALCULATE:
        case calculationActions.CALCULATE_CAR_PREMIUM:
        case calculationActions.CALCULATE_HOME_PREMIUM:
        case calculationActions.CALCULATE_BICYCLE_PREMIUM: {
            return {
                ...state,
                current: null,
                loading: true,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.UPDATE: {
            return {
                ...state,
                loading: true,
                errors: [],
                notifications: [],
            };
        }

        case vehicleActions.LICENSE_PLATE_CHANGED: {
            return {
                ...state,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.CALCULATE_SUCCESS:
        case calculationActions.CALCULATE_PREMIUM_SUCCESS:
        case calculationActions.PREMIUM_UPDATED_SUCCESS:
        case calculationActions.UPDATE_SUCCESS: {
            const current = action.payload.premium;
            const history = action.payload.premium;

            return {
                ...state,
                current,
                history: [...state.history, history],
                loading: false,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.RESET_CALCULATE_ERRORS: {
            return {
                ...state,
                errors: [],
            };
        }

        case calculationActions.CAMPAIGN_CODE_CHANGED: {
            return {
                ...state,
                loading: true,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.CAMPAIGN_CODE_CHANGED_SUCCESS: {
            const current = action.payload.premium;
            const history = action.payload.premium;
            const campaignCode = action.payload.insurance.campaign_code;

            return {
                ...state,
                current,
                history: [...state.history, history],
                campaignCode,
                loading: false,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.CALCULATE_CAMPAIGNCODE_ERROR:
        case calculationActions.UPDATE_CAMPAIGNCODE_ERROR: {
            const errors = action.payload.errors.filter(errorFilter(ApiErrorLevelEnum.Error as string));
            const notifications = action.payload.errors.filter(errorFilter(ApiErrorLevelEnum.Info));

            return {
                ...state,
                campaignCode: null,
                loading: false,
                errors,
                notifications,
            };
        }

        case calculationActions.CAMPAIGN_CODE_CHANGED_ERROR:
        case calculationActions.CALCULATE_PREMIUM_ERROR:
        case calculationActions.CALCULATE_ERROR:
        case calculationActions.PREMIUM_UPDATED_ERROR:
        case calculationActions.EMAIL_FAILED:
        case calculationActions.UPDATE_ERROR: {
            const errors = action.payload.errors.filter(errorFilter(ApiErrorLevelEnum.Error as string));
            const notifications = action.payload.errors.filter(errorFilter(ApiErrorLevelEnum.Info));

            return {
                ...state,
                loading: false,
                errors,
                notifications,
            };
        }

        case calculationActions.EMAIL_CAR_PREMIUM:
        case calculationActions.EMAIL_HOME_PREMIUM:
        case calculationActions.EMAIL_BICYCLE_PREMIUM: {
            return {
                ...state,
                loading: true,
                errors: [],
                notifications: [],
            };
        }

        case calculationActions.EMAIL_SENT: {
            return {
                ...state,
                loading: false,
            };
        }

        case calculationActions.RESET_CALCULATION:
        case insuranceActions.INSURE_SUCCESS:
        case insuranceActions.CLEAR_INSURANCE_DATA:
        case insuranceActions.REQUEST_INSURANCE_SUCCESS: {
            return {
                ...initialState,
            };
        }

        default: {
            return state;
        }
    }
}

export const getCurrent = (state: State): State['current'] => state.current;
export const getCampaignCode = (state: State): State['campaignCode'] => state.campaignCode;
export const isLoading = (state: State): State['loading'] => state.loading;
export const getErrors = (state: State): State['errors'] => state.errors;
export const getNotifications = (state: State): State['notifications'] => state.notifications;
