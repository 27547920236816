import { AccountRecoveryActions, AccountRecoveryActionType } from './account-recovery.actions';
import { AccountRecoveryState, INITIAL_ACCOUNT_RECOVERY_STATE } from './account-recovery.state';

export class AccountRecoveryReducers {
    public static reducer(
        state: AccountRecoveryState = INITIAL_ACCOUNT_RECOVERY_STATE,
        action: AccountRecoveryActionType
    ): AccountRecoveryState {
        switch (action.type) {
            /** Recovery Methods */
            case AccountRecoveryActions.RecoveryMethods:
                return {
                    ...state,
                    personalInformation: action.payload.request,
                };

            case AccountRecoveryActions.RecoveryMethodsSuccess:
                return {
                    ...state,
                    recoveryMethods: action.payload.response,
                };

            case AccountRecoveryActions.RecoveryMethodsError:
                return {
                    ...state,
                    recoveryMethods: null,
                };

            /** Send Password */
            case AccountRecoveryActions.SendPasswordSuccess:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        birthdate: null,
                    },
                };

            /** Update Temporary Password */
            case AccountRecoveryActions.UpdateTemporaryPassword:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        newPassword: action.payload.request.newPassword,
                    },
                };
            case AccountRecoveryActions.UpdateTemporaryPasswordSuccess:
                return {
                    ...state,
                    recoveryMethods: null,
                    personalInformation: {
                        ...state.personalInformation,
                        password: state.personalInformation.newPassword,
                        newPassword: null,
                    },
                };
            case AccountRecoveryActions.UpdateTemporaryPasswordError:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        newPassword: null,
                    },
                };

            /** Complete Generated Account */
            case AccountRecoveryActions.CompleteGeneratedAccount:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        newPassword: action.payload.request.newPassword,
                    },
                };

            case AccountRecoveryActions.CompleteGeneratedAccountSuccess:
                return {
                    ...state,
                    completedAccount: action.payload,
                    personalInformation: {
                        ...state.personalInformation,
                        password: state.personalInformation.newPassword,
                        newPassword: null,
                    },
                };

            case AccountRecoveryActions.CompleteGeneratedAccountError:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        newPassword: null,
                    },
                };

            /** Credentials */
            case AccountRecoveryActions.SetCredentials:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        email: action.payload.credentials.email,
                        password: action.payload.credentials.password,
                    },
                };

            /** Reset */
            case AccountRecoveryActions.AccountRecoveryReset:
                return {
                    ...state,
                    personalInformation: null,
                    recoveryMethods: null,
                };

            /** Default and actions without state changes */
            case AccountRecoveryActions.ValidateGeneratedAccountSuccess:
                return {
                    ...state,
                    personalInformation: {
                        ...state.personalInformation,
                        phoneNumber: action.payload?.response?.relation?.mobile_telephone_number?.telephone_number,
                    },
                };

            case AccountRecoveryActions.ValidateGeneratedAccountError:
            case AccountRecoveryActions.SendPassword:
            case AccountRecoveryActions.SendPasswordError:
            default:
                return state;
        }
    }
}
