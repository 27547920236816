import { mapSuccessIndication } from '../../core';
import { RecoveryMethodEnum } from '../enums';
import { ApiSendPasswordRequest, SendPasswordRequestInterface } from '../interfaces';

export function mapSendPasswordRequest(request: SendPasswordRequestInterface): ApiSendPasswordRequest {
    return {
        relation: {
            birthdate: request.birthdate,
            primary_email_address: {
                email_address: request.email,
            },
            primary_email_address_indication: mapSuccessIndication(
                request.recoveryMethod === RecoveryMethodEnum.PrimaryEmail
            ),
            recovery_email_address_indication: mapSuccessIndication(
                request.recoveryMethod === RecoveryMethodEnum.RecoveryEmail
            ),
            sms_indication: mapSuccessIndication(request.recoveryMethod === RecoveryMethodEnum.Phone),
        },
    };
}
