import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIGURATION, ApiConfigurationInterface, ApiService, ApiVersionService } from '../../core';
import { ValidateElementRequestInterface, ValidateMultipleElementRequestInterface } from '../interfaces';
import {
    mapClassicValidateElementsRequest,
    mapValidateElementsRequest,
    mapValidateMultipleElementsRequest,
} from '../mappers';
import { AuthenticationDataService } from '../../authentication';
import { switchMap, take } from 'rxjs/operators';
import { LabelEnum } from '../../core/enums/label.enum';

const RESOURCES = {
    VALIDATE_ELEMENTS: 'en-gb/form-general/validate/elements',
    VALIDATE_ELEMENTS_EXISTINGRELATION: 'en-gb/form-general/validate/existingrelation-elements',
};

@Injectable({
    providedIn: 'root',
})
export class ValidationApiService {
    constructor(
        private apiService: ApiService,
        private apiVersionService: ApiVersionService,
        private authenticationDataService: AuthenticationDataService,
        @Inject(API_CONFIGURATION) private configuration: ApiConfigurationInterface
    ) {}

    public validate$(request: ValidateElementRequestInterface): Observable<void> {
        const payload = this.apiVersionService.isClassic()
            ? mapClassicValidateElementsRequest(request)
            : mapValidateElementsRequest(request);

        return this.authenticationDataService.isLoggedIn$().pipe(
            take(1),
            switchMap((isLoggedIn: boolean) =>
                this.apiService.post$<void>(
                    isLoggedIn && this.configuration.label === LabelEnum.ISDE
                        ? RESOURCES.VALIDATE_ELEMENTS_EXISTINGRELATION
                        : RESOURCES.VALIDATE_ELEMENTS,
                    payload
                )
            )
        );
    }

    public validateMultiple$(request: ValidateMultipleElementRequestInterface): Observable<void> {
        const payload = mapValidateMultipleElementsRequest(request);
        return this.authenticationDataService.isLoggedIn$().pipe(
            take(1),
            switchMap((isLoggedIn: boolean) =>
                this.apiService.post$<void>(
                    isLoggedIn && this.configuration.label === LabelEnum.ISDE
                        ? RESOURCES.VALIDATE_ELEMENTS_EXISTINGRELATION
                        : RESOURCES.VALIDATE_ELEMENTS,
                    payload
                )
            )
        );
    }
}
