import { mapEnumApiResponse } from '../../core';
import { ApiNaturalPartyRoleEnum, ApiV1NaturalPartyRoleEnum, NaturalPartyRoleEnum } from '../enums';

export function mapNaturalPartyRoleResponse(
    response: ApiNaturalPartyRoleEnum,
    enablePartyCodes?: boolean
): NaturalPartyRoleEnum {
    const partyRoleEnumType = enablePartyCodes ? ApiV1NaturalPartyRoleEnum : ApiNaturalPartyRoleEnum;
    return mapEnumApiResponse<NaturalPartyRoleEnum>(response, partyRoleEnumType, NaturalPartyRoleEnum);
}
