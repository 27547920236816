import { createReducer, on } from '@ngrx/store';
import * as claimStepsActions from '../store/claim-steps.actions';
import { ClaimStepsState, INITIAL_CLAIM_STEPS_STATE } from './claim-steps.state';

export const claimStepsReducer = createReducer(
    INITIAL_CLAIM_STEPS_STATE,
    on(
        claimStepsActions.loadClaimStepsSuccess,
        (state, { claimSteps }): ClaimStepsState => ({
            ...state,
            claimSteps,
        })
    ),
    on(
        claimStepsActions.clearClaimSteps,
        (state): ClaimStepsState => ({
            ...state,
            claimSteps: null,
        })
    )
);
