import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimAppointmentEffects } from './store/claim-appointment.effects';
import { claimAppointmentReducer } from './store/claim-appointment.reducer';
import { CLAIM_APPOINTMENT_FEATURE } from './store/claim-appointment.state';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_APPOINTMENT_FEATURE, claimAppointmentReducer),
        EffectsModule.forFeature([ClaimAppointmentEffects]),
    ],
})
export class OutClaimAppointmentModule {}
