import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ContentElementsInterface, ContentInterface } from '../interfaces';
import { Content, ContentSelectors } from '../store';

@Injectable({
    providedIn: 'root',
})
export class ContentDataService {
    constructor(private store$: Store<Content>) {}

    public getElements$(): Observable<ContentElementsInterface> {
        return this.store$.select(ContentSelectors.getContentElements).pipe(

            map((contentElements?: ContentElementsInterface) => (contentElements ? contentElements : {}))
        );
    }

    public getElement$(name: string): Observable<ContentInterface[]> {
        return this.getElements$().pipe(
            filter((elements: ContentElementsInterface) => elements.hasOwnProperty(name)),
            map((contentElements: ContentElementsInterface): ContentInterface[] => {
                return contentElements[name];
            })
        );
    }

    public getElementById$(name: string, id: string): Observable<ContentInterface> {
        return this.getElement$(name).pipe(
            map((content: ContentInterface[]): ContentInterface => {
                const contentElement = content.find((element: ContentInterface) => element.content_id === id);
                if (!contentElement) {
                    throw new Error(`Content Element with id: '${id}' was not found in element: '${name}'`);
                }
                return contentElement;
            })
        );
    }

    public getIds$(): Observable<string[]> {
        return this.store$.select((ContentSelectors.getIds));
    }
}
