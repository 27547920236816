import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, forkJoin, from, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
    AcceptanceMethodInterface,
    AcceptanceService,
    AcceptanceValidationTypeEnum,
    payloadToContainers,
} from '../../acceptance';
import { MapperDataInterface, NotificationInterface, RequestResponse, RequestService } from '../../core';
import {
    Coverage,
    Customer,
    Insurance,
    PayloadInterface,
    PremiumPurchasedProduct,
    PurchasedProductReplacement,
    RequestInsuranceMapperInterface,
    StackDiscountDescription,
} from '../interfaces';
import { SalesStorageService } from '../services/sales-storage.service';
import { CalculationService } from './calculation/calculation.service';
import { CoverageService } from './coverage/coverage.service';
import { CustomerService } from './customer/customer.service';
import {
    InsuranceError,
    RequestInsurance,
    RequestInsuranceSuccess,
    ValidateAcceptance,
    ValidateEligibilityError,
    ValidateEligibilitySuccess,
} from './insurance/insurance.actions';
import { InsuranceService } from './insurance/insurance.service';
import { ProgressService } from './progress/progress.service';
import * as fromSales from './sales.reducers';
import * as salesSelectors from './sales.selectors';
import { SaveStackDiscount } from './stack-discount/stack-discount.actions';

@Injectable({
    providedIn: 'root',
})
export class SalesService {
    public receipt$: Observable<Coverage[]> = this.store$.select(salesSelectors.selectReceipt);

    public totalCollectivityDiscount$: Observable<number> = this.store$.select(
        salesSelectors.getTotalCollectivityDiscount
    );

    public totalExcludingDiscount$: Observable<number> = this.store$.select(
        salesSelectors.selectTotalsExcludingDiscount
    );

    public totalYearlyPayment$: Observable<number> = this.store$.select(salesSelectors.selectTotalYearlyPayment);

    public totalYearlyPaymentInlcudingDiscount$: Observable<number> = this.store$.select(
        salesSelectors.selectTotalYearlyPaymentInlcudingDiscount
    );

    public discount$: Observable<number> = this.store$.select(salesSelectors.selectDiscount);

    public totalIncludingDiscount$: Observable<number> = this.store$.select(salesSelectors.getTotalsIncludingDiscount);

    public totalIncludingStackDiscount$: Observable<number> = this.store$.select(
        salesSelectors.getTotalsIncludingStackDiscount
    );

    public totalStackDiscount$: Observable<number> = this.store$.select(salesSelectors.getTotalStackDiscount);

    public accountCosts$: Observable<number> = this.store$.select(salesSelectors.getAccountCosts);

    public stackDiscount$: Observable<StackDiscountDescription> = this.store$.select(salesSelectors.stackDiscount);

    public purchasedProductReplacement$: Observable<PurchasedProductReplacement> = this.store$.select(
        salesSelectors.getProductReplacement
    );

    public replacedVehicleModules$: Observable<PremiumPurchasedProduct> = this.purchasedProductReplacement$.pipe(
        filter((replacement) => !!replacement),
        map((replacement) => replacement.purchased_product_sequence_number),
        switchMap((purchasedProductSequenceNumber) =>
            this.calculationService.calculatePremiumPurchasedProduct$(purchasedProductSequenceNumber)
        )
    );

    public insuranceFromStorage$: Observable<{ [productId: string]: Insurance }> = from(
        this.salesStorageAPI.getInsurance()
    );

    public customerFromStorage$: Observable<Customer> = from(this.salesStorageAPI.getCustomer());

    public salesData$: Observable<[Insurance, Customer, Coverage[], string]> = forkJoin([
        this.insuranceService.currentInsuranceData$.pipe(take(1)),
        this.customerService.currentCustomerData$.pipe(take(1)),
        this.coverageService.coverages$.pipe(take(1)),
        this.progressService.productId$.pipe(take(1)),
    ]);

    public prefill$: Observable<[Insurance, Customer]> = combineLatest([
        this.insuranceService.currentInsuranceData$,
        this.customerService.currentCustomerData$,
    ]);

    constructor(
        private readonly store$: Store<fromSales.State>,
        private readonly requestService: RequestService,
        private readonly salesStorageAPI: SalesStorageService,
        private readonly calculationService: CalculationService,
        private readonly insuranceService: InsuranceService,
        private readonly customerService: CustomerService,
        private readonly coverageService: CoverageService,
        private readonly progressService: ProgressService,
        private readonly acceptanceService: AcceptanceService // eslint-disable-next-line comma-dangle
    ) {}

    public acceptance$({ webserviceId, data, mapper }: AcceptanceMethodInterface<MapperDataInterface>): Observable<{
        acceptance_success: string;
        notifications: NotificationInterface[];
    }> {
        this.store$.dispatch(new ValidateAcceptance());

        return this.salesData$.pipe(
            switchMap(([insurance, customer, coverages, productId]) => {
                const newData = {
                    insurance: { ...insurance, ...data.insurance },
                    customer: { ...customer, ...data.customer },
                    coverages: coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })),
                    productId,
                    authenticated: data.authenticated || false,
                };

                const payload = mapper(newData);

                return this.acceptanceService
                    .validate({
                        container: payloadToContainers(payload),
                        product_id: productId,
                        webservice_id: webserviceId,
                        acceptance_validation_type_code:
                            data.acceptanceValidationTypeCode || AcceptanceValidationTypeEnum.None,
                    })
                    .pipe(
                        tap(() => this.store$.dispatch(new ValidateEligibilitySuccess(newData))),
                        tap(() => this.salesStorageAPI.save(newData.productId, newData.insurance, newData.customer)),
                        catchError((errors) => {
                            this.store$.dispatch(new ValidateEligibilityError({ errors }));
                            return EMPTY;
                        })
                    );
            })
        );
    }

    public requestInsurance$({
        url,
        data,
        mapper,
    }: PayloadInterface<RequestInsuranceMapperInterface>): Observable<RequestResponse> {
        this.store$.dispatch(new RequestInsurance(data));

        return this.salesData$.pipe(
            switchMap(([insurance, customer, coverages, productId]) => {
                const newData = {
                    insurance: { ...insurance, ...data.insurance },
                    customer: { ...customer, ...data.customer },
                    indications: data.indications,
                    coverages,
                };

                return this.requestService.post(url, mapper(newData)).pipe(
                    take(1),
                    tap((response) =>
                        this.store$.dispatch(new RequestInsuranceSuccess({ productId, ...newData, ...response.data }))
                    ),
                    tap((response) => {
                        if (response.data.stack_discount) {
                            this.store$.dispatch(
                                new SaveStackDiscount({
                                    stack_discount: response.data.stack_discount,
                                })
                            );
                        }
                    }),
                    tap(() => this.salesStorageAPI.clear()),
                    catchError((errors) => this.handleErrors$(errors))
                );
            })
        );
    }

    private handleErrors$(errors: NotificationInterface[] | null): Observable<never> {
        if (Array.isArray(errors) && errors.length > 0) {
            return this.dispatchErrors$(errors);
        }
        return this.dispatchErrors$(this.requestService.createError('1234'));
    }

    private dispatchErrors$(errors: NotificationInterface[]): Observable<never> {
        this.store$.dispatch(new InsuranceError({ errors }));
        return EMPTY;
    }
}
