import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Coverage } from '../../interfaces';
import * as fromSales from '../sales.reducers';
import * as fromCoverage from './coverage.selectors';

@Injectable({
    providedIn: 'root',
})
export class CoverageService {
    public coverages$: Observable<Coverage[]> = this.store$.pipe(
        select(fromCoverage.getCurrentCoverages),
    );

    public modules$: Observable<string[]> = this.store$.pipe(
        select(fromCoverage.getSelectedModules),
    );

    public totalExcludingDiscount$: Observable<number> = this.store$.pipe(
        select(fromCoverage.getTotalsExcludingDiscount),
    );

    public discount$: Observable<number> = this.store$.pipe(
        select(fromCoverage.getDiscount),
    );

    public totalIncludingDiscount$: Observable<number> = this.store$.pipe(
        select(fromCoverage.getTotalsIncludingDiscount),
    );

    public totalIncludingStackDiscount$: Observable<number> = this.store$.pipe(
        select(fromCoverage.getTotalsIncludingStackDiscount),
    );

    public totalStackDiscount$: Observable<number> = this.store$.pipe(
        select(fromCoverage.getTotalStackDiscount),
    );

    constructor(private store$: Store<fromSales.State>) {}
}
