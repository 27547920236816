import { createSelector } from '@ngrx/store';
import { ProductBaseInterface } from '../../interfaces';
import { getMyZoneState, MyZoneState } from '../../my-zone';
import * as fromProduct from './product.reducer';

/** @deprecated use InsurancePolicyDataService */
export const getProductState = createSelector(getMyZoneState, (state: MyZoneState) => state.product);
/** @deprecated use InsurancePolicyDataService */
export const getProductEntities = createSelector(getProductState, fromProduct.getEntities);
/** @deprecated use InsurancePolicyDataService */
export const getProductIds = createSelector(getProductState, fromProduct.getIds);
/** @deprecated use InsurancePolicyDataService */
export const getDetailLoaded = createSelector(getProductState, fromProduct.getDetailLoaded);
/** @deprecated use InsurancePolicyDataService */
export const getSelectedProductId = createSelector(getProductState, fromProduct.getSelectedId);
/** @deprecated use InsurancePolicyDataService */
export const isProductLoading = createSelector(getProductState, fromProduct.isLoading);
/** @deprecated use InsurancePolicyDataService */
export const isProductLoaded = createSelector(getProductState, fromProduct.isLoaded);
/** @deprecated use InsurancePolicyDataService */
export const getSelectedProduct = createSelector(getProductEntities, getSelectedProductId, (entities, id) => {
    return entities[id];
});
/** @deprecated use InsurancePolicyDataService */
export const getProductCollection = createSelector(getProductEntities, getProductIds, (entities, ids) => {
    return ids.map((id) => entities[id]);
});
/** @deprecated use InsurancePolicyDataService */
export const getActiveProducts = createSelector(getProductCollection, (products) => {
    return products.filter((product) =>
        ['ACTI', 'TOEK', 'ACEI', 'NEVT', 'OSTP', 'ACWI'].includes(product.validity_status_code)
    );
});
/** @deprecated use InsurancePolicyDataService */
export const getToCancelProducts = createSelector(getProductCollection, (products) => {
    return products.filter((product) => ['ACTI', 'ACEI', 'ACWI', 'NEVT'].includes(product.validity_status_code));
});
/** @deprecated use InsurancePolicyDataService */
export const getEndedProducts = createSelector(getProductCollection, (products: ProductBaseInterface[]) => {
    return products.filter((product) => ['EIND'].includes(product.validity_status_code));
});
/** @deprecated use InsurancePolicyDataService */
export const getTransferProducts = createSelector(getProductCollection, (products: ProductBaseInterface[]) => {
    return products.filter((product) => ['OSTP'].includes(product.validity_status_code));
});
/** @deprecated use InsurancePolicyDataService */
export const allProductsLoaded = createSelector(
    getActiveProducts,
    getProductCollection,
    (activeProducts: ProductBaseInterface[], allProducts: ProductBaseInterface[]) => {
        return activeProducts.length === allProducts.length;
    }
);
