import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SpecificationInterface } from '../interfaces';
import { ClaimSpecificationSelectors } from '../store/claim-specification.selectors';
import { ClaimSpecificationState } from '../store/claim-specification.state';

@Injectable({
    providedIn: 'root',
})
export class ClaimSpecificationDataService {
    constructor(private store$: Store<ClaimSpecificationState>) {}

    public getState$(): Observable<ClaimSpecificationState> {
        return this.store$.select(ClaimSpecificationSelectors.getState);
    }

    public getSpecification$(): Observable<SpecificationInterface> {
        return this.store$.select(ClaimSpecificationSelectors.getSpecification);
    }
}
