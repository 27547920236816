import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import {
    CompleteGeneratedAccountResponseInterface,
    PersonalInformationInterface,
    RecoveryMethodsResponseInterface,
} from '../interfaces';
import { AccountRecovery, AccountRecoveryState, ACCOUNT_RECOVERY_FEATURE } from './account-recovery.state';

export class AccountRecoverySelectors {
    public static getAccountRecoveryState = createFeatureSelector<AccountRecoveryState>(ACCOUNT_RECOVERY_FEATURE);

    // Only for testing
    public static getState = createSelector(
        AccountRecoverySelectors.getAccountRecoveryState,
        (state: AccountRecoveryState) => state
    );

    public static getRecoveryMethods: Selector<AccountRecovery, RecoveryMethodsResponseInterface> = createSelector(
        AccountRecoverySelectors.getAccountRecoveryState,
        (state: AccountRecoveryState) => state.recoveryMethods
    );


    public static getCompletedAccount: Selector<AccountRecovery, CompleteGeneratedAccountResponseInterface> = createSelector(
        AccountRecoverySelectors.getAccountRecoveryState,
        (state: AccountRecoveryState) => state.completedAccount
    );

    public static getPersonalInformation: Selector<AccountRecovery, PersonalInformationInterface> = createSelector(
        AccountRecoverySelectors.getAccountRecoveryState,
        (state: AccountRecoveryState) => state.personalInformation
    );
}
