import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../core';
import { SessionInterface } from '../interfaces';

const SESSION_KEY = 'session';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationStorageService {
    constructor(private storageService: StorageService) {}

    public clear(): void {
        const obs$ = this.storageService.remove$(SESSION_KEY);
    }

    public setSession(session: SessionInterface): void {
        const obs$ = this.storageService.set$(SESSION_KEY, session);
    }

    public getSession$(): Observable<SessionInterface> {
        return this.storageService.get$<SessionInterface>(SESSION_KEY);
    }
}
