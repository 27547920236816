import { ApiPersonRoleInterface, PartyRoleInterface } from '../interfaces';

export function mapPersonRoleResponse(response: ApiPersonRoleInterface): PartyRoleInterface | null {
    if (!response) {
        return null;
    }

    return {
        businessRelationId: response.business_relation_id,
        relationToInsuredPartyCode: response.relation_to_insured_person_code,
        relationToInsuredPartyDescription: response.relation_to_insured_person_description,
    };
}
