import { mapFromSuccessIndication } from '../../core';
import { ApiGetClaimCoverageResponseInterface, ClaimCoverageInterface } from '../interfaces';
import { mapBaseDeductibleExcessResponse } from './base-deductibleexcess.mapper';
import { mapCoveredResponse } from './covered-response.mapper';
import { mapFinancialImpactLongTermResponse } from './financial-impact-long-term.mapper';
import { mapFinancialImpactResponse } from './financial-impact.mapper';
import { mapHandlingResponse } from './handling.mapper';
import { mapNoCoverageResponse } from './no-coverage.mapper';
import { mapProcedureExplanationResponse } from './procedure-explanation.mapper';

export function mapClaimCoverageResponse(response: ApiGetClaimCoverageResponseInterface): ClaimCoverageInterface {
    return {
        baseDeductibleExcess: mapBaseDeductibleExcessResponse(response.base_deductible_excess),
        handling: mapHandlingResponse(response.handling),
        coveredIndication: mapFromSuccessIndication(response.covered_indication),
        coveredIndicationDescription: response.covered_indication_description,
        financialImpact: mapFinancialImpactResponse(response.financiele_impact),
        financialImpactLongTerm: mapFinancialImpactLongTermResponse(response.financiele_impact_lange_termijn),
        covered: mapCoveredResponse(response.gedekt),
        noCoverage: mapNoCoverageResponse(response.niet_gedekt),
        procedureExplanation: mapProcedureExplanationResponse(response.procedure_uitleg),
    };
}
