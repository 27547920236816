import { ApiSpecificationLabelsInterface, SpecificationLabelsInterface } from '../interfaces';

export function mapSpecificationLabelsResponse(
    response: ApiSpecificationLabelsInterface[]
): SpecificationLabelsInterface[] {
    if (!response) {
        return [];
    }

    return response.map((item: ApiSpecificationLabelsInterface) => ({
        text: item.text,
        note: item.note,
        placeholder: item.placeholder,
        contextId: item.context_id,
    }));
}
