import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { CountryInterface } from '../interfaces/country.interface';

export const getCountries = createAction('[Countries] Get Countries');
export const getCountriesSuccess = createAction(
    '[Countries] Get Countries Success',
    props<{ countries: CountryInterface[] }>()
);
export const getCountriesError = createAction('[Countries] Get Countries Error', props<{ errors: ErrorInterface[] }>());
export const clearCountries = createAction('[Countries] Clear Countries');
