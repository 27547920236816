import { mapSequenceNumber } from '../../core';
import { RemoveClaimObjectRequestInterface, RemoveClaimObjectResponseInterface } from '../interfaces';

export function mapRemoveClaimObjectReponse(
    response: RemoveClaimObjectRequestInterface
): RemoveClaimObjectResponseInterface {
    return {
        removedClaimObjects: [
            {
                claimObjectId: response.claimObjectId,
                sustainedDamageSequenceNumber: mapSequenceNumber(response.sustainedDamageSequenceNumber),
            },
        ],
    };
}
