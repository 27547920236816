import {
    ApiGetTimeslotsReponseInterface,
    ApiGetTimeslotsRequestInterface,
    GetTimeslotsReponseInterface,
    GetTimeslotsRequestInterface,
} from '../interfaces/get-timeslots.interface';

export function mapGetTimeslotsResponse(response: ApiGetTimeslotsReponseInterface): GetTimeslotsReponseInterface {
    if (!response.time_slots) {
        return {
            timeslots: [],
        };
    }

    return {
        timeslots: response.time_slots.map((timeSlotResponse) => ({
            date: timeSlotResponse.date,
            eventTime: timeSlotResponse.event_time,
            duration: timeSlotResponse.duration,
        })),
    };
}

export function mapGetTimeslotsRequest(request: GetTimeslotsRequestInterface): ApiGetTimeslotsRequestInterface {
    return {
        network_id: request.networkId,
        damage_repair_event_code: request.damageRepairEventCode,
        business_relation_id: request.businessRelationId,
        preferred_date: request.preferredDate,
        case: {
            case_sequence_number: request.caseSequenceNumber,
        },
    };
}
