import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { CoreActions } from './core.actions';

export class CoreReducers {
    public static metaReducers: MetaReducer[] = [CoreReducers.resetReducer];

    public static resetReducer(actionReducer: ActionReducer<any, Action>): (state: any, action: any) => MetaReducer {
        return (state, action) => {
            if (action.type === CoreActions.RESET) {
                state = {
                    ...state,
                    // Add below all the feature states that needs to be cleared/reset on logout
                    authentication: undefined,
                    accountRecovery: undefined,
                    insurancePolicy: undefined,
                    productScenarios: undefined,
                    damageFreeYearsImpact: undefined,
                    relation: undefined,
                    invoice: undefined,
                    myZone: undefined,
                };
            }
            return actionReducer(state, action);
        };
    }
}
