import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService } from '../../core';
import { InsurancePolicyApiService, PolicyResponseInterface } from '../../insurance-policy';
import { AllClaimCategoriesResponseInterface, ApiAllClaimCategoriesResponse } from '../interfaces';
import { mapClaimCategoriesResponse } from '../mappers';

@Injectable({ providedIn: 'root' })
export class ClaimCategoriesApiService {
    constructor(
        private apiService: ApiService,
        private environmentService: EnvironmentService,
        private insurancePolicyApiService: InsurancePolicyApiService
    ) {}

    public allClaimCategories$(): Observable<{
        policy$: PolicyResponseInterface;
        allClaimCategories$: AllClaimCategoriesResponseInterface;
    }> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimCategories);

        return forkJoin({
            policy$: this.insurancePolicyApiService.policy$(),
            allClaimCategories$: this.apiService.post$<ApiAllClaimCategoriesResponse[]>(resource),
        }).pipe(
            map(({ policy$, allClaimCategories$ }) => {
                return { policy$, allClaimCategories$: mapClaimCategoriesResponse(allClaimCategories$) };
            })
        );
    }
}
