import { ApiProductCaravanResponse, ProductCaravanResponseInterface } from '../../interfaces';

export function mapProductCaravanResponse(response: ApiProductCaravanResponse): ProductCaravanResponseInterface {
    if (!response) {
        return null;
    }

    return {
        buildDate: response.build_date,
        caravanBrand: response.caravan_brand,
        caravanCode: response.caravan_code,
        caravanDescription: response.caravan_description,
        caravanModel: response.caravan_model,
        catalogValueAmount: response.catalog_value_amount,
        licencePlateIssuedDate: response.licence_plate_issued_date,
        licensePlate: response.license_plate,
        weight: response.weight,
    };
}
