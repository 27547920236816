import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ClaimStepsApiService, ClaimStepsEventService } from '../services';
import * as ClaimStepsActions from '../store/claim-steps.actions';

@Injectable()
export class ClaimStepsEffects {
    public loadClaimSteps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimStepsActions.loadClaimSteps),
            switchMap(({ request }) =>
                this.claimStepsApiService.loadClaimSteps$(request).pipe(
                    map((claimSteps) => ClaimStepsActions.loadClaimStepsSuccess({ claimSteps })),
                    catchError((errors) => of(ClaimStepsActions.loadClaimStepsError({ errors })))
                )
            )
        );
    });

    public loadClaimStepsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimStepsActions.loadClaimStepsError),
                tap(({ errors }) => this.claimStepsEventService.onLoadClaimStepsError(errors))
            );
        },
        { dispatch: false }
    );

    public loadClaimStepsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimStepsActions.loadClaimStepsSuccess),
                tap(() => this.claimStepsEventService.onLoadClaimStepsSuccess())
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private claimStepsApiService: ClaimStepsApiService,
        private claimStepsEventService: ClaimStepsEventService
    ) {}
}
