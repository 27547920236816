import { ApiProductBonusComponentIdEnum, BonusComponentIdEnum } from '../../enums';

export function mapBonusComponentIdResponse(response: ApiProductBonusComponentIdEnum): BonusComponentIdEnum {
    if (!response) {
        return null;
    }

    switch (response) {
    case ApiProductBonusComponentIdEnum.AlarmRegular:
        return BonusComponentIdEnum.AlarmRegular;
    case ApiProductBonusComponentIdEnum.AlarmSerure:
        return BonusComponentIdEnum.AlarmSerure;
    case ApiProductBonusComponentIdEnum.DriversLicense:
        return BonusComponentIdEnum.DriversLicense;
    case ApiProductBonusComponentIdEnum.DrivingTraining:
        return BonusComponentIdEnum.DrivingTraining;
    case ApiProductBonusComponentIdEnum.EnergyLable:
        return BonusComponentIdEnum.EnergyLable;
    case ApiProductBonusComponentIdEnum.ParkingSensors:
        return BonusComponentIdEnum.ParkingSensors;
    case ApiProductBonusComponentIdEnum.PreventativeCare:
        return BonusComponentIdEnum.PreventativeCare;
    case ApiProductBonusComponentIdEnum.RegularKeyLock:
        return BonusComponentIdEnum.RegularKeyLock;
    case ApiProductBonusComponentIdEnum.SecureKeyLock:
        return BonusComponentIdEnum.SecureKeyLock;
    case ApiProductBonusComponentIdEnum.SmokeDetecors:
        return BonusComponentIdEnum.SmokeDetecors;
    case ApiProductBonusComponentIdEnum.TestForWWW:
        return BonusComponentIdEnum.TestForWWW;
    case ApiProductBonusComponentIdEnum.WaterStop:
        return BonusComponentIdEnum.WaterStop;
    case ApiProductBonusComponentIdEnum.WinterStorage:
        return BonusComponentIdEnum.WinterStorage;
    case ApiProductBonusComponentIdEnum.WinterTires:
        return BonusComponentIdEnum.WinterTires;
    }
}
