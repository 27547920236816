import { ApiSuccessIndicationEnum } from '../../core/enums';
import {
    ApiAttachmentFileResponse,
    ApiAttachmentRequest,
    ApiAttachmentResponse,
    AttachmentFileInterface,
    AttachmentRequestInterface,
    AttachmentResponseInterface,
} from '../interfaces';

export function mapAttachmentRequest(request: AttachmentRequestInterface): ApiAttachmentRequest {
    return {
        mail_question: {
            mail_question_id: request.mailQuestionId,
        },
        attachment: {
            file_name: request.filename,
            data_base64: request.base64string,
        },
    };
}

export function mapAttachment(attachment: ApiAttachmentFileResponse): AttachmentFileInterface {
    return {
        filename: attachment.file_name,
        filesize: attachment.file_size,
        virusFree: attachment.virus_free_indication === ApiSuccessIndicationEnum.Success,
    };
}

export function mapAddAttachmentResponse(data: ApiAttachmentResponse): AttachmentResponseInterface {
    return {
        limitAmount: data.attachment_limit_amount,
        limitAmountRemaining: data.attachment_limit_amount_remaining,
        totalFileSize: data.attachment_file_size_total,
        limitFileSizeRemaining: data.attachment_limit_remaining,
        attachments: data.attachments.map(mapAttachment),
    };
}

