import { AllClaimCategoriesResponseInterface } from '../interfaces/all-claim-categories-response.interface';
import { ApiAllClaimCategoriesResponse } from '../interfaces/api.claim-categories.response';
import { ClaimCategoryInterface } from '../interfaces/claim-category.interface';
import { mapClaimCategoryIdResponse } from './claim-category-id.mapper';
import { mapDamageTypesResponse } from './damage-types.mapper';

export function mapClaimCategoriesResponse(
    response: ApiAllClaimCategoriesResponse[]
): AllClaimCategoriesResponseInterface {
    const claimCategories: ClaimCategoryInterface[] = [];

    response.forEach((response) =>
        claimCategories.push({
            claimCategoryId: mapClaimCategoryIdResponse(response.kind_of_loss_group_id),
            claimCategoryDescription: response.kind_of_loss_group_description,
            information: response.information,
            damageTypes: mapDamageTypesResponse(response.kind_of_loss),
        })
    );

    return {
        claimCategories,
    };
}
