import { Injectable } from '@angular/core';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService, mapFormatCode } from '../../core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapSnakeToCamel } from '../../core/mappers/snake-to-camel.mapper';
import { ClaimOverviewItem } from '../interfaces/claim-overview.interface';
import { ClaimOverviewApiResponse } from '../interfaces/claim-overview-api.interface';

@Injectable({ providedIn: 'root' })
export class ClaimOverviewService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getClaimOverviewData(caseSequenceNumber: number): Observable<ClaimOverviewItem[]> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimOverview);

        return this.apiService
            .post$<ClaimOverviewApiResponse>(resource, {
                case_sequence_number: caseSequenceNumber,
            })
            .pipe(
                map((response) => {
                    const camelCasedSpecifications: any[] = mapSnakeToCamel(response).caseSpecifications;
                    camelCasedSpecifications.forEach((specification) => {
                        specification.element.formatCode = mapFormatCode(specification.element.formatCode);
                    });

                    // Add date and time to the top of the list
                    camelCasedSpecifications.unshift({
                        specificationGroupDescription: 'Datum en tijd',
                        order: 1,
                        labels: [{ text: 'Datum en tijd' }],
                        element: {
                            elementContentDescription: `${response.damage_date} - ${
                                response.damage_event_time.split(':')[0]
                            }:${response.damage_event_time.split(':')[1]}`,
                        },
                    });

                    return camelCasedSpecifications;
                })
            );
    }
}
