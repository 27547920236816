import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ErrorInterface } from '../../core';
import { ProductEnGbInterface } from '../../my-zone';
import {
    CreateBankDetailsInterface,
    CreateDutchMailingAddressRequestInterface,
    CreateMailingAddressRequestInterface,
    CreateRelationRecoveryDataRequestInterface,
    CreateResidentialAddressInterface,
    CreateResidentialAddressResponseInterface,
    EditAccountCredentialsInterface,
    GetPremiumModificationInterface,
    ModifyFamilyCompositionInterface,
    RelationInterface,
    SaveOptOutInterface, StoreResidentialAddressInterface,
    TerminateActiveMailingAddressRequestInterface,
    TokenRelationOptOutInterface,
    UpdatePasswordRequestInterface,
    UpdatePersonalDetailsInterface,
    UpdatePrimaryEmailInterface,
} from '../interfaces';
import {
    AddDutchMailingAddressAction,
    AddMailingAddressAction,
    CancelFamilyCompositionAction,
    CancelFutureMailingAddressAction,
    CancelResidentialAddressAction,
    CreateBankDetailsAction,
    CreateRelationRecoveryDataAction,
    CreateResidentialAddressAction,
    EditAccountCredentialsAction,
    ModifyFamilyCompositionAction,
    PremiumModificationPurchasedProductsAction,
    ReviveTerminatedMailingAddressAction,
    SaveOptOutAction,
    SaveTokenRelationOptOutAction, StoreResidentialAddressAction,
    TerminateActiveMailingAddressAction,
    UpdateFamilyCompositionAction,
    UpdatePasswordAction,
    UpdatePersonalDetailsAction,
    UpdatePrimaryEmailAction,
    UpdateRelationAction,
} from '../store/relation.actions';
import { Relation } from '../store/relation.state';
import { RelationEventService } from './relation.event.service';

@Injectable({
    providedIn: 'root',
})
export class RelationActionService {
    constructor(private store$: Store<Relation>, private relationEventService: RelationEventService) {}

    /* Consider updating this file to new eslint standards */

    public updatePassword$(request: UpdatePasswordRequestInterface): Observable<void> {
        this.store$.dispatch(new UpdatePasswordAction({ request }));
        return this.relationEventService.getUpdatePassword$();
    }

    public saveOptOut$(optOut: SaveOptOutInterface): Observable<void> {
        this.store$.dispatch(new SaveOptOutAction({ request: optOut }));
        return this.relationEventService.getSaveOptOut$();
    }

    public updatePrimaryEmail$(payload: UpdatePrimaryEmailInterface): Observable<void> {
        this.store$.dispatch(new UpdatePrimaryEmailAction(payload));
        return this.relationEventService.getUpdatePrimaryEmail$();
    }

    public updateRelation$(relation: RelationInterface): Observable<void> {
        this.store$.dispatch(new UpdateRelationAction({ relation }));
        return this.relationEventService.getUpdateRelation$();
    }

    public updatePersonalDetails$(payload: UpdatePersonalDetailsInterface): Observable<void> {
        this.store$.dispatch(new UpdatePersonalDetailsAction({ request: payload }));
        return this.relationEventService.updatePersonalDetails$();
    }

    public addMailingAddress$(request: CreateMailingAddressRequestInterface): Observable<void> {
        this.store$.dispatch(new AddMailingAddressAction({ request }));
        return this.relationEventService.getCreateMailingAddress$();
    }

    public addDutchMailingAddress$(request: CreateDutchMailingAddressRequestInterface): Observable<void> {
        this.store$.dispatch(new AddDutchMailingAddressAction({ request }));
        return this.relationEventService.getCreateDutchMailingAddress$();
    }

    public cancelFutureMailingAddress$(): Observable<void> {
        this.store$.dispatch(new CancelFutureMailingAddressAction());
        return this.relationEventService.getCancelFutureMailingAddress$();
    }

    public terminateActiveMailingAddress$(request: TerminateActiveMailingAddressRequestInterface): Observable<void> {
        this.store$.dispatch(new TerminateActiveMailingAddressAction({ request }));
        return this.relationEventService.getTerminateActiveMailingAddress$();
    }

    public reviveActiveMailingAddress$(): Observable<void> {
        this.store$.dispatch(new ReviveTerminatedMailingAddressAction());
        return this.relationEventService.getReviveTerminatedMailingAddress$();
    }

    /** Not Yet Fully Implemented */
    public updateFamilyComposition$(request: ModifyFamilyCompositionInterface): Observable<void> {
        this.store$.dispatch(new UpdateFamilyCompositionAction({ request }));

        // TODO : Do we need to update the relation?
        //  Do a family composition request and put it in store
        //  Implement cancel future family composition
        return this.relationEventService.getUpdateFamilyComposition$();
    }

    public createBankDetails$(request: CreateBankDetailsInterface): Observable<void> {
        this.store$.dispatch(new CreateBankDetailsAction({ request }));
        return this.relationEventService.createBankDetails$();
    }

    public modifyFamilyComposition$(request: ModifyFamilyCompositionInterface): Observable<ErrorInterface[]> {
        this.store$.dispatch(new ModifyFamilyCompositionAction({ request }));
        return this.relationEventService.modifyFamilyComposition$();
    }

    public cancelFamilyComposition$(): Observable<void> {
        this.store$.dispatch(new CancelFamilyCompositionAction());
        return this.relationEventService.cancelFamilyComposition$();
    }

    public premiumModificationPurchasedProducts$(
        request: GetPremiumModificationInterface
    ): Observable<ProductEnGbInterface[]> {
        this.store$.dispatch(new PremiumModificationPurchasedProductsAction({ request }));
        return this.relationEventService.premiumModificationPurchasedProducts$();
    }

    public editAccountCredentialsAction$(request: EditAccountCredentialsInterface): Observable<void> {
        this.store$.dispatch(new EditAccountCredentialsAction({ request }));
        return this.relationEventService.editAccountCredentials$();
    }

    public createResidentialAddress$(request: CreateResidentialAddressInterface): Observable<CreateResidentialAddressResponseInterface> {
        this.store$.dispatch(new CreateResidentialAddressAction({ request }));
        return this.relationEventService.createResidentialAddress$();
    }

    public storeResidentialAddress$(request: StoreResidentialAddressInterface): Observable<void> {
        this.store$.dispatch(new StoreResidentialAddressAction({ request }));
        return this.relationEventService.storeResidentialAddress$();
    }

    public cancelResidentialAddress$(): Observable<void> {
        this.store$.dispatch(new CancelResidentialAddressAction());
        return this.relationEventService.cancelResidentialAddress$();
    }

    public saveTokenRelationOptOut$(request: TokenRelationOptOutInterface): Observable<void> {
        this.store$.dispatch(new SaveTokenRelationOptOutAction({ request }));
        return this.relationEventService.saveTokenRelationOptOut$();
    }

    public createRelationRecoveryData$(request: CreateRelationRecoveryDataRequestInterface): Observable<string> {
        this.store$.dispatch(new CreateRelationRecoveryDataAction(request));
        return this.relationEventService.createRelationRecoveryData$();
    }
}
