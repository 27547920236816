import { mapFormatCode, mapMandatoryCode } from '../../core';
import { ApiClaimObjectSpecificationInterface, ClaimObjectSpecificationInterface } from '../interfaces';
import { mapClaimObjectContent } from './claim-object-content.mapper';

export function mapClaimObjectSpecification(
    response: ApiClaimObjectSpecificationInterface[]
): ClaimObjectSpecificationInterface[] {
    if (!response) {
        return [];
    }

    return response.map((specification: ApiClaimObjectSpecificationInterface) => ({
        content: mapClaimObjectContent(specification.content),
        disabled: specification.disabled,
        elementContent: specification.element_content,
        elementDependence: specification.element_dependence,
        elementId: specification.element_id,
        elementName: specification.element_name,
        formatCode: mapFormatCode(specification.format_code),
        formatDescription: specification.format_description,
        information: specification.information,
        labels: specification.labels,
        mandatoryOptionalCode: mapMandatoryCode(specification.mandatory_optional_code),
        mandatoryOptionalDescription: specification.mandatory_optional_description,
        order: specification.order,
        visible: specification.visible,
    }));
}
