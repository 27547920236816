import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService } from '../../core';
import {
    UpdateSpecificationRequestInterface,
    ResumeSpecificationRequestInterface,
    GetSpecificationRequestInterface,
    GetSpecificationResponseInterface,
    ResumeSpecificationResponseInterface,
    UpdateSpecificationResponseInterface,
    RemoveSpecificationRequestInterface,
    RemoveSpecificationResponseInterface,
} from '../interfaces';
import {
    mapGetSpecifcationResponse,
    mapGetSpecificationRequest,
    mapResumeSpecificationRequest,
    mapResumeSpecificationResponse,
    mapUpdateSpecificationRequest,
    mapUpdateSpecificationResponse,
    mapRemoveSpecificationRequest,
    mapRemoveSpecificationResponse,
} from '../mappers';

@Injectable({
    providedIn: 'root',
})
export class ClaimSpecificationApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getSpecification$(request: GetSpecificationRequestInterface): Observable<GetSpecificationResponseInterface> {
        const mappedRequest = mapGetSpecificationRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(
            ApiUrlResourcesEnum.RequestClaimSpecification
        );

        return this.apiService.postResponse$(resource, mappedRequest).pipe(map(mapGetSpecifcationResponse));
    }

    public resumeSpecification$(
        request: ResumeSpecificationRequestInterface
    ): Observable<ResumeSpecificationResponseInterface> {
        const mappedRequest = mapResumeSpecificationRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(
            ApiUrlResourcesEnum.RequestClaimSpecification
        );

        return this.apiService.postResponse$(resource, mappedRequest).pipe(map(mapResumeSpecificationResponse));
    }

    public updateSpecification$(
        request: UpdateSpecificationRequestInterface
    ): Observable<UpdateSpecificationResponseInterface> {
        const mappedRequest = mapUpdateSpecificationRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(
            ApiUrlResourcesEnum.RequestClaimSpecification
        );

        return this.apiService.postResponse$(resource, mappedRequest).pipe(map(mapUpdateSpecificationResponse));
    }

    public removeSpecification$(
        request: RemoveSpecificationRequestInterface
    ): Observable<RemoveSpecificationResponseInterface> {
        const mappedRequest = mapRemoveSpecificationRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.RemoveSpecification);

        return this.apiService.post$(resource, mappedRequest).pipe(map(mapRemoveSpecificationResponse));
    }
}
