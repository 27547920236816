import { NotificationInterface } from '../../../core';
import { MotorVehiclesToReplace } from '../../interfaces';
import * as fromVehicleReplace from './vehicle-replace.actions';
import { Modal, UserChoice } from './vehicle-replace.enums';

export interface State {
    current: MotorVehiclesToReplace;
    loading: boolean;
    modal: Modal;
    userChoice: UserChoice;
    errors: NotificationInterface[];
}

export const initialState: State = {
    current: null,
    loading: false,
    modal: Modal.Initial,
    userChoice: UserChoice.Initial,
    errors: [],
};

export function reducer(state: State = initialState, action: fromVehicleReplace.VehicleReplaceActionUnion): State {
    switch (action.type) {
    case fromVehicleReplace.RESET_VEHICLE_REPLACEMENT: {
        return {
            ...initialState,
        };
    }

    case fromVehicleReplace.VALIDATE: {
        return {
            ...state,
            loading: true,
            current: null,
            modal: Modal.Initial,
            errors: [],
        };
    }

    case fromVehicleReplace.VALIDATE_TRUE: {
        const current = action.payload.response;

        return {
            ...state,
            current,
            loading: false,
            modal: Modal.Yes,
        };
    }

    case fromVehicleReplace.VALIDATE_FALSE: {
        return {
            ...state,
            loading: false,
            modal: Modal.No,
        };
    }

    case fromVehicleReplace.LOAD_ERROR: {
        return {
            ...state,
            loading: false,
            errors: action.payload.errors,
        };
    }

    case fromVehicleReplace.SET_USER_CHOICE: {
        return {
            ...state,
            userChoice: action.payload.choice,
        };
    }

    case fromVehicleReplace.CHOOSE_REPLACE_YES: {
        return {
            ...state,
            userChoice: UserChoice.Yes,
        };
    }

    case fromVehicleReplace.CHOOSE_REPLACE_NO: {
        return {
            ...state,
            userChoice: UserChoice.No,
        };
    }

    default: {
        return state;
    }
    }
}

export const getCurrent = (state: State): State['current'] => state.current;
export const getErrors = (state: State): State['errors'] => state.errors;
export const getModal = (state: State): State['modal'] => state.modal;
export const getUserChoice = (state: State): State['userChoice'] => state.userChoice;
export const isLoading = (state: State): State['loading'] => state.loading;
