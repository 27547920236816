import { mapSequenceNumber } from '../../../core/mappers';
import { AddPremiumDeterminingFactorRequestInterface, ApiAddPremiumDeterminingFactorRequest } from '../../interfaces';

export function mapAddPremiumDeterminingFactorRequest(
    request: AddPremiumDeterminingFactorRequestInterface
): ApiAddPremiumDeterminingFactorRequest {
    return {
        modifiable_premium_determining_factor: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
            start_date: request.startDate,
            premium_determining_factor_id: request.factorId,
            premium_determining_factor_code: request.factorCode,
            premium_determining_factor_date: request.factorDate,
            premium_determining_factor_number: request.factorNumber,
            premium_determining_factor_indication: request.factorIndication,
        },
    };
}
