import { ApiError, ApiLegacyError, NotificationInterface } from '../interfaces';

const LEGACY_DISCRIMINATOR = 'message_content';

/**
 * Maps the new ApiError back to NotificationInterface
 *  @deprecated supporting request service
 */
function mapError(error: ApiError): NotificationInterface {
    return {
        message_content: error.notification_text,
        message_code: `${error.element_id}-${error.notification_sequence_number}`,
        item: error.element_name,
        notification_type: error.notification_code as string,
    };
}

/** Needed because API will sometimes return different error types */
function errorIsLegacy(error: ApiError | ApiLegacyError): error is ApiLegacyError {
    return error.hasOwnProperty(LEGACY_DISCRIMINATOR);
}

/** @deprecated supporting request service */
export function mapLegacyErrors(errors: (ApiError | ApiLegacyError)[]): NotificationInterface[] {
    return errors.map((error: ApiError | ApiLegacyError) => {
        return errorIsLegacy(error) ? error : mapError(error);
    });
}
