import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccountRecoveryEffects, AccountRecoveryReducers, ACCOUNT_RECOVERY_FEATURE } from './store';

@NgModule({
    imports: [
        StoreModule.forFeature(ACCOUNT_RECOVERY_FEATURE, AccountRecoveryReducers.reducer),
        EffectsModule.forFeature([AccountRecoveryEffects]),
    ],
})
export class OutAccountRecoveryModule {}
