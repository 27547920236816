import {
    ApiForgotEmailRequest,
    ApiRecoveryDataRequest,
    ApiSecurityDataRequest,
    ForgotEmailRequestInterface,
    RecoveryDataRequestInterface,
    SecurityDataRequestInterface,
} from '../interfaces';

function mapSecurityData(data: SecurityDataRequestInterface): ApiSecurityDataRequest {
    return {
        birthdate: data.birthdate,
        invoice: {
            invoice_amount: data.invoiceAmount,
        },
        bank_mandate: {
            iban_bank_account_number: data.iban,
        },
        residential_address: {
            postal_code: data.postalCode,
            civic_number: data.civicNumber,
        },
        recovery_email_address: {
            email_address: data.newRecoveryEmail,
        },
    };
}

function mapRecoveryData(data: RecoveryDataRequestInterface): ApiRecoveryDataRequest {
    const request: ApiRecoveryDataRequest = {
        birthdate: data.birthdate,
    };

    if (data.recoveryEmail) {
        request.recovery_email_address = {
            email_address: data.recoveryEmail,
        };
    }

    if (data.phone) {
        request.mobile_telephone_number = {
            telephone_number: data.phone,
        };
    }
    return request;
}

export function mapForgotEmailRequest(request: ForgotEmailRequestInterface): ApiForgotEmailRequest {
    const apiRequest: ApiForgotEmailRequest = {};

    if (request.recoveryData) {
        apiRequest.recovery_data = mapRecoveryData(request.recoveryData);
    }

    if (request.securityData) {
        apiRequest.security_data = mapSecurityData(request.securityData);
    }

    return apiRequest;
}
