// Change to ProductIdEnum when MyZoneStoreModule is gone
export enum PolicyProductIdEnum {
    Liability = 'Liability insurance', // 'AANS',
    Car = 'Car insurance', // 'AUTO',
    Moped = 'Moped insurance', // 'BROM',
    Scooter = 'Scooter insurance', // 'SCOOTER',
    Caravan = 'Caravan insurance', // 'CVAN',
    Bicycle = 'Bicycle insurance', // 'FIET',
    Pet = 'Pet insurance', // 'DIER',
    Motorcycle = 'Motorcycle insurance', // 'MOTR',
    Accidents = 'Accident insurance', // 'ONGV',
    LegalAssistance = 'Legal assistance insurance', // 'RB',
    Travel = 'Travel insurance', // 'REIS',
    Home = 'Home insurance', // 'WOON',
    Furniture = 'Home furniture insurance', // 'INBOEDEL',
    Building = 'Home building insurance', // 'OPSTAL',
}
