import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { PolicyResponseInterface } from '../../insurance-policy';
import { AllClaimCategoriesResponseInterface } from '../interfaces/all-claim-categories-response.interface';

export const allClaimCategories = createAction('[claim categories] get all claim categories');

export const allClaimCategoriesSuccess = createAction(
    '[claim categories] get all claim categories success',
    props<{
        response: {
            policy$: PolicyResponseInterface;
            allClaimCategories$: AllClaimCategoriesResponseInterface;
        };
    }>()
);

export const allClaimCategoriesFailure = createAction(
    '[claim categories] get all claim categories failure',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimCategories = createAction('[claim categories] reset claim categories');
