import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { FetchAddressRequestInterface, FetchAddressResponseInterface } from '../interfaces';
import { ApiFetchAddressResponseInterface } from '../interfaces/api-fetch-address-response.interface';
import { mapFetchAddressRequest, mapFetchAddressResponse } from '../mappers';

const RESOURCES = {
    FETCH_ADDRESS: 'en-gb/address-general/request/dutch-address-postcode_address',
};

@Injectable({
    providedIn: 'root',
})
export class AddressApiService {
    constructor(private apiService: ApiService) {}

    public fetchAddress$(request: FetchAddressRequestInterface): Observable<FetchAddressResponseInterface> {
        return this.apiService
            .post$<ApiFetchAddressResponseInterface>(RESOURCES.FETCH_ADDRESS, mapFetchAddressRequest(request))
            .pipe(map(mapFetchAddressResponse));
    }
}
