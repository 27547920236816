/**
 * @deprecated - use insurances enums
 */
export enum ValidityStatusCodeEnum {
    Active = 'ACTI',
    ActiveEnd = 'ACEI',
    Future = 'TOEK',
    Overstap = 'OSTP',
    ActiveW = 'ACWI',
    New = 'NEVT',
    End = 'EIND',
}
