import { mapSuccessIndication } from '../../core';
import {
    ApiResumeSpecificationRequestInterface,
    ApiResumeSpecificationResponseInterface,
    ResumeSpecificationRequestInterface,
    ResumeSpecificationResponseInterface,
} from '../interfaces';
import { mapSpecificationResponse } from './specification.mapper';

export function mapResumeSpecificationRequest(
    request: ResumeSpecificationRequestInterface
): ApiResumeSpecificationRequestInterface {
    return {
        notifications: [
            {
                message_code: request.messageCode,
                message_agreement_indication: mapSuccessIndication(request.messageAgreementIndication),
            },
        ],
        kind_of_loss: {
            kind_of_loss_id: request.damageTypeId,
        },
    };
}

export function mapResumeSpecificationResponse(
    response: ApiResumeSpecificationResponseInterface
): ResumeSpecificationResponseInterface {
    return {
        specification: mapSpecificationResponse(response.data),
    };
}
