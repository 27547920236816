import { ApiExhibitsInterface, ExhibitsInterface } from '../interfaces';
import { mapExhibitsButtonsResponse } from './exhibits-button.mapper';
import { mapExhibitsResponse } from './exhibits.mapper';

export function mapLoadExhibitsResponse(response: ApiExhibitsInterface): ExhibitsInterface {
    return {
        exhibits: mapExhibitsResponse(response.exhibit),
        buttons: mapExhibitsButtonsResponse(response.buttons),
        submit: { uploadEnvelopeSequenceNumber: response.submit.upload_envelope_sequence_number },
    };
}
