export enum TerminateReasonEnum {
    Deregistration = 'Deregistration', // Abmeldung
    ChangeOfInsurer = 'ChangeOfInsurer', // Versicherungswechsel
    AfterAClaim = 'AfterAClaim', // Schadensfall
    DecommissioningWithSuspension = 'DecommissioningWithSuspension', // Außerbetriebsetzung mit Ruheversicherung
    PremiumIncrease = 'PremiumIncrease', // Beitragserhöhung
    ChangeOfTermsAndConditions = 'ChangeOfTermsAndConditions', // Änderung der Versicherungsbedingungen
    InsureSameCarAgain = 'InsureSameCarAgain', // Neue InShared-Versicherung für dasselbe Auto
    Other = 'Other', // Anderer Grund
}

export enum ApiTerminateReasonEnum {
    Deregistration = 'OPAF',
    ChangeOfInsurer = 'OPWI',
    AfterAClaim = 'OPSC',
    DecommissioningWithSuspension = 'OPBU',
    PremiumIncrease = 'OPBI',
    ChangeOfTermsAndConditions = 'OPVW',
    InsureSameCarAgain = 'OPZA',
    Other = 'OTHER',
}
