import { HttpStatusEnum, ResponseInterface } from '../../core';
import {
    ApiSessionResponse,
    ApiTokenRequest,
    isLegacySessionResponse,
    LegacyApiSessionResponse,
    SessionResponseInterface,
} from '../interfaces';

export function mapSessionResponse(response: ResponseInterface): ResponseInterface {
    if (response.statusCode !== HttpStatusEnum.OK) {
        throw response.errors;
    }
    const sessionResponse: ApiSessionResponse | LegacyApiSessionResponse = response.data;
    const sessionDuration = isLegacySessionResponse(sessionResponse)
        ? sessionResponse.session_duration
        : sessionResponse.expiry_duration;

    const session: SessionResponseInterface = {
        sessionStart: new Date(),
        sessionDurationInSeconds: sessionDuration,
    };

    if (sessionResponse.relation) {
        session.relation = sessionResponse.relation;
    }

    if (sessionResponse.continuous_login_token) {
        session.token = sessionResponse.continuous_login_token;
    }
    response.data = { ...session };

    return response;
}

export function mapTokenRequest(token: string): ApiTokenRequest {
    return {
        continuous_login_token: token,
    };
}
