import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AcceptanceValidationTypeEnum } from '../acceptance';
import { ErrorInterface } from '../core';
import { ValidateElementRequestInterface, ValidateMultipleElementRequestInterface } from './interfaces';
import { ValidationApiService } from './services/validation.api.service';
import { ValidateElementsErrorAction, ValidateElementSuccessAction } from './store/validation.actions';
import { Validation } from './store/validation.state';

@Injectable({
    providedIn: 'root',
})
export class ElementValidator {
    constructor(private apiService: ValidationApiService, private store$: Store<Validation>) {}

    /**
     * @param name The form field name.
     * @param webserviceId The webservice that you want to validate against (optional for Classic API).
     * @param context The context of the form field (e.g. `primary_email_address` for `email_address`).
     * @param treeValidation Should the webservice check extensively
     */
    public validate(name: string, webserviceId?: string, context?: string, treeValidation?: boolean): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value) {
                return of(null);
            }

            const element: ValidateElementRequestInterface = {
                name,
                value: control.value,
                webserviceId,
                context,
                acceptanceType: treeValidation ? AcceptanceValidationTypeEnum.Tree : AcceptanceValidationTypeEnum.None,
            };

            // We fire actions here, because we need the errors available in the store
            // We are doing this with apiService directly, because we need the immediate response for validator.
            return timer(500).pipe(
                // 'debounce' validation
                switchMap(() =>
                    this.apiService.validate$(element).pipe(
                        tap(() => this.store$.dispatch(new ValidateElementSuccessAction({ name }))),
                        map(() => null), // When no errors, return null for validator
                        catchError((errors: ErrorInterface[]) => {
                            this.store$.dispatch(new ValidateElementsErrorAction({ name, errors }));
                            return of({ invalidAsync: true });
                        })
                    )
                )
            );
        };
    }

    /**
     * @param groupName The name of the ValidateElementSuccess/ErrorAction.
     * @param names The form field names.
     * @param webserviceId The webservice that you want to validate against (optional for Classic API).
     * @param context The context of the form field (e.g. `primary_email_address` for `email_address`).
     * @param treeValidation Should the webservice check extensively
     */
    public validateMultiple(
        groupName: string,
        names: string[],
        webserviceId?: string,
        context?: string,
        treeValidation?: boolean
    ): AsyncValidatorFn {
        return (form: UntypedFormGroup): Observable<ValidationErrors> => {
            if (!form.value) {
                return of(null);
            }

            const element: ValidateMultipleElementRequestInterface = {
                elements: names.map((name: string) => {
                    return {
                        name: name.replace(/[a-z-_]+\./gmi, ''), // Remove form group names
                        value: form.get(name).value,
                    };
                }),
                webserviceId,
                context,
                acceptanceType: treeValidation ? AcceptanceValidationTypeEnum.Tree : AcceptanceValidationTypeEnum.None,
            };

            // We fire actions here, because we need the errors available in the store
            // We are doing this with apiService directly, because we need the immediate response for validator.
            return timer(500).pipe(
                // 'debounce' validation
                switchMap(() =>
                    this.apiService.validateMultiple$(element).pipe(
                        tap(() => this.store$.dispatch(new ValidateElementSuccessAction({ name: groupName }))),
                        map(() => null), // When no errors, return null for validator
                        catchError((errors: ErrorInterface[]) => {
                            this.store$.dispatch(new ValidateElementsErrorAction({ name: groupName, errors }));
                            return of({ invalidAsync: true });
                        })
                    )
                )
            );
        };
    }

    /**
     * @param groupName The name of the ValidateElementSuccessAction to reset.
     */
    public clearValidationError(groupName: string): void {
        this.store$.dispatch(new ValidateElementSuccessAction({name: groupName}));
    }
}
