import { mapFromSuccessIndication, mapSequenceNumber } from '../../core';
import { mapProductIdResponse } from '../../insurance-policy';
import { ApiClaimCoverageImpactResponseInterface, ClaimCoverageImpactInterface } from '../interfaces';

export function mapFinancialImpactResponse(
    response: ApiClaimCoverageImpactResponseInterface[]
): ClaimCoverageImpactInterface[] {
    if (!response) {
        return [];
    }

    return response.map((item) => ({
        amountOfEffectBm: item.aantal_effecten_bm,
        amountOfEffectCjb: item.aantal_effecten_cjb,
        amountOfEffectPjb: item.aantal_effecten_pjb,
        amountOfEffectSvj: item.aantal_effecten_svj,
        collectiveYearRewardAmount: item.collectieve_jaarbeloning_bedrag,
        inclusiveDamageIndication: mapFromSuccessIndication(item.inclusief_schade_indicatie),
        netPremium: item.netto_premie,
        noClaimsYearsAmount: item.no_claims_years_amount,
        productDescription: item.product_description,
        productId: mapProductIdResponse(item.product_id),
        productIdDescription: item.product_id_description,
        pointsEndOfClaimYear: item.punten_bij_einde_schadejaar,
        purchasedProductSequenceNumber: mapSequenceNumber(item.purchased_product_sequence_number),
        referenceDate: item.reference_date,
        step: item.trede,
        stepDescription: item.trede_omschrijving,
        extendAmount: item.verlengingen_aantal,
        yearlyPersonalRewardAmount: item.yearly_personal_reward_amount,
    }));
}
