import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ModalPosition } from './new-modal-config';
import { NewModalContainer } from './new-modal-container';

let uniqueId = 0;

export enum ModalState {
    OPEN,
    CLOSING,
    CLOSED,
}

export class NewModalRef<T, R = any> {
    public componentInstance: T | null;

    public disableClose: boolean | undefined = this._containerInstance._config.disableClose;

    /** Subject for notifying the user that the dialog has finished opening. */
    private readonly _afterOpened$ = new Subject<void>();

    /** Subject for notifying the user that the dialog has finished closing. */
    private readonly _afterClosed$ = new Subject<R | boolean>();

    /** Subject for notifying the user that the dialog has started closing. */
    private readonly _beforeClosed$ = new Subject<R | boolean>();

    /** Result to be passed to afterClosed. */
    private _result: R | boolean;

    /** Handle to the timeout that's running as a fallback in case the exit animation doesn't fire. */
    private _closeFallbackTimeout: ReturnType<typeof setTimeout>;

    /** Current state of the dialog. */
    private _state = ModalState.OPEN;

    constructor(
        private _overlayRef: OverlayRef,
        public _containerInstance: NewModalContainer,
        public readonly id: string = `ins-modal-${uniqueId++}`
    ) {
        _containerInstance._id = id;

        // Emit when opening animation completes
        _containerInstance._animationStateChanged
            .pipe(
                filter((event) => event.phaseName === 'done' && event.toState === 'enter'),
                take(1)
            )
            .subscribe(() => {
                this._afterOpened$.next();
                this._afterOpened$.complete();
            });

        // Dispose overlay when closing animation is complete
        _containerInstance._animationStateChanged
            .pipe(
                filter((event) => event.phaseName === 'done' && event.toState === 'exit'),
                take(1)
            )
            .subscribe(() => {
                clearTimeout(this._closeFallbackTimeout);
                this._overlayRef.dispose();
            });

        _overlayRef.detachments().subscribe(() => {
            this._beforeClosed$.next(this._result);
            this._beforeClosed$.complete();
            this._afterClosed$.next(this._result);
            this._afterClosed$.complete();
            this.componentInstance = null;
            this._overlayRef.dispose();
        });

        _overlayRef
            .keydownEvents()
            .pipe(filter((event) => event.keyCode === ESCAPE && !this.disableClose && !hasModifierKey(event)))
            .subscribe((event) => {
                event.preventDefault();
                this.close();
            });
    }

    public close(modalResults?: R): void {
        this._result = modalResults || false;

        this._containerInstance._animationStateChanged
            .pipe(
                filter((event) => event.phaseName === 'start'),
                take(1)
            )
            .subscribe((event) => {
                this._beforeClosed$.next(modalResults);
                this._beforeClosed$.complete();
                this._state = ModalState.CLOSED;
                this._overlayRef.detachBackdrop();

                // fallback detach backdrop
                this._closeFallbackTimeout = setTimeout(() => {
                    this._overlayRef.dispose();
                }, event.totalTime + 100);
            });
        this._containerInstance._startExitAnimation();
        this._state = ModalState.CLOSING;
    }

    public afterOpened$(): Observable<void> {
        return this._afterOpened$.asObservable();
    }

    public afterClosed$(): Observable<R | boolean> {
        return this._afterClosed$.asObservable();
    }

    public beforeClosed$(): Observable<R | boolean> {
        return this._beforeClosed$.asObservable();
    }

    public backdropClick$(): Observable<MouseEvent> {
        return this._overlayRef.backdropClick();
    }

    public keydownEvents$(): Observable<KeyboardEvent> {
        return this._overlayRef.keydownEvents();
    }

    public updatePosition(position?: ModalPosition): this {
        const strategy = this._getPositionStrategy();

        if (position && (position.left || position.right)) {
            position.left ? strategy.left(position.left) : strategy.right(position.right);
        } else {
            strategy.centerHorizontally();
        }

        if (position && (position.top || position.bottom)) {
            position.top ? strategy.top(position.top) : strategy.bottom(position.bottom);
        } else {
            strategy.centerVertically();
        }

        this._overlayRef.updatePosition();

        return this;
    }

    public updateSize(width: string = '', height: string = ''): this {
        this._getPositionStrategy().width(width).height(height);
        this._overlayRef.updatePosition();
        return this;
    }

    public addPanelClass(classes: string | string[]): this {
        this._overlayRef.addPanelClass(classes);
        return this;
    }

    public removePanelClass(classes: string | string[]): this {
        this._overlayRef.removePanelClass(classes);
        return this;
    }

    public getState(): ModalState {
        return this._state;
    }

    private _getPositionStrategy(): GlobalPositionStrategy {
        return this._overlayRef.getConfig().positionStrategy as GlobalPositionStrategy;
    }
}
