import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ErrorInterface } from '../../core';
import { RecoveryMethodEnum } from '../enums';
import {
    CompleteGeneratedAccountRequestInterface,
    ForgotEmailRequestInterface,
    RecoveryMethodsRequestInterface,
    SendPasswordRequestInterface,
    UnblockAccountRequestInterface,
    UpdateTemporaryPasswordInterface,
    ValidateGeneratedAccountRequestInterface,
    UpdateEmailAddressRequestInterface,
} from '../interfaces';
import {
    AccountRecovery,
    CompleteGeneratedAccountAction,
    ForgotEmailAction,
    RecoveryMethodsAction,
    SendPasswordAction,
    UpdateEmailAction,
    UpdatePasswordAction,
    UpdateTemporaryPasswordAction,
    ValidateGeneratedAccountAction,
} from '../store';
import { AccountRecoveryEventService } from './account-recovery.event.service';

@Injectable({
    providedIn: 'root',
})
export class AccountRecoveryActionService {
    constructor(private store$: Store<AccountRecovery>, private eventService: AccountRecoveryEventService) {}

    public getRecoveryMethods$(request: RecoveryMethodsRequestInterface): Observable<void> {
        this.store$.dispatch(new RecoveryMethodsAction({ request }));

        return this.eventService.getRecoveryMethods$();
    }

    public sendPassword$(request: SendPasswordRequestInterface): Observable<ErrorInterface[]> {
        this.store$.dispatch(new SendPasswordAction({ request }));

        return this.eventService.getSendPassword$();
    }

    /** Unblock Account is just an alias for send password to primary email address */
    public unblockAccount$(request: UnblockAccountRequestInterface): Observable<ErrorInterface[]> {
        const sendPasswordRequest = { ...request, recoveryMethod: RecoveryMethodEnum.PrimaryEmail };

        return this.sendPassword$(sendPasswordRequest);
    }

    public updateTemporaryPassword$(request: UpdateTemporaryPasswordInterface): Observable<void> {
        this.store$.dispatch(new UpdateTemporaryPasswordAction({ request }));

        return this.eventService.getUpdateTemporaryPassword$();
    }

    public updatePassword$(password: string): Observable<void> {
        this.store$.dispatch(new UpdatePasswordAction({ password }));

        return this.eventService.getUpdatePassword$();
    }

    public updateEmail$(request: UpdateEmailAddressRequestInterface): Observable<void> {
        this.store$.dispatch(new UpdateEmailAction({ request }));

        return this.eventService.getUpdateEmail$();
    }

    public forgotEmail$(request: ForgotEmailRequestInterface): Observable<ErrorInterface[]> {
        this.store$.dispatch(new ForgotEmailAction({ request }));

        return this.eventService.getForgotEmail$();
    }

    public validateGeneratedAccount$(request: ValidateGeneratedAccountRequestInterface): Observable<void> {
        this.store$.dispatch(new ValidateGeneratedAccountAction({ request }));

        return this.eventService.getValidateGeneratedAccount$();
    }

    public completeGeneratedAccount$(request: CompleteGeneratedAccountRequestInterface): Observable<void> {
        this.store$.dispatch(new CompleteGeneratedAccountAction({ request }));

        return this.eventService.getCompleteGeneratedAccount$();
    }
}
