import { ApiGetInsuranceCompaniesResponseInterface, GetInsuranceCompaniesResponseInterface } from '../interfaces';

export function mapGetInsuranceCompaniesResponse(
    response: ApiGetInsuranceCompaniesResponseInterface[]
): GetInsuranceCompaniesResponseInterface[] {
    return response.map((value: ApiGetInsuranceCompaniesResponseInterface) => {
        return {
            civicNumber: value.civic_number,
            civicNumberSuffix: value.civic_number_suffix,
            insuranceCompanyId: value.insurance_company_id,
            location: value.location,
            postalCode: value.postal_code,
            streetName: value.street_name,
            tradingName: value.trading_name,
        };
    });
}
