import { mapSequenceNumber } from '../../core';
import { ApiRemoveClaimObjectRequestInterface, RemoveClaimObjectRequestInterface } from '../interfaces';

export function mapRemoveClaimObjectRequest(
    request: RemoveClaimObjectRequestInterface
): ApiRemoveClaimObjectRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        suffered_damage: {
            sustained_damage_sequence_number: mapSequenceNumber(request.sustainedDamageSequenceNumber),
            claim_object_id: request.claimObjectId,
        },
    };
}
