import { createSelector } from '@ngrx/store';
import { getSalesState } from '../sales.reducers';
import * as fromCalculation from './calculation.reducer';

export const getCalculationState = createSelector(getSalesState, (state) => state.calculation);

export const getCurrent = createSelector(getCalculationState, fromCalculation.getCurrent);
export const getCampaignCode = createSelector(getCalculationState, fromCalculation.getCampaignCode);
export const isLoading = createSelector(getCalculationState, fromCalculation.isLoading);
export const getErrors = createSelector(getCalculationState, fromCalculation.getErrors);
export const getNotifications = createSelector(getCalculationState, fromCalculation.getNotifications);
