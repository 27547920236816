import { LegacyUserInterface, RelationInterface } from '../interfaces';

/** @deprecated supporting angular.js */
export function mapFromLegacyUser(relatie: LegacyUserInterface): RelationInterface {
    return {
        name: relatie.naam,
        relation_id: relatie.relatie_id,
        gender_code: relatie.geslacht_code,
        gender_description: relatie.geslacht_omschrijving,
        first_name: relatie.voornaam,
        surname: relatie.achternaam,
        initials: relatie.voorletters,
        middle_name_initials: relatie.tussenvoegsels,
        pre_nominal_letters: relatie.titel,
        post_nominal_letters: relatie.achtertitel,
        account_blocked_indication: relatie.account_geblokkeerd_indicatie,
        account_blocked_indication_description: relatie.account_geblokkeerd_indicatie_omschrijving,
        mobile_telephone_number: !relatie.mobiel_telefoonnummer
            ? null
            : {
                end_date: relatie.mobiel_telefoonnummer.einddatum,
                validity_status_code: relatie.mobiel_telefoonnummer.geldigheid_status_code,
                start_date: relatie.mobiel_telefoonnummer.ingangsdatum,
                telephone_number_type_code: relatie.mobiel_telefoonnummer.soort_telefoonnummer_code,
                telephone_number: relatie.mobiel_telefoonnummer.telefoonnummer,
            },
        mobile_telephone_number_future: null,
        mobile_telephone_number_history: null,
        landline_telephone_number: !relatie.vast_telefoonnummer
            ? null
            : {
                end_date: relatie.vast_telefoonnummer.einddatum,
                validity_status_code: relatie.vast_telefoonnummer.geldigheid_status_code,
                start_date: relatie.vast_telefoonnummer.ingangsdatum,
                telephone_number_type_code: relatie.vast_telefoonnummer.soort_telefoonnummer_code,
                telephone_number: relatie.vast_telefoonnummer.telefoonnummer,
            },
        landline_telephone_number_future: null,
        landline_telephone_number_history: null,
        birthdate: relatie.geboortedatum,
        date_of_birth_description: relatie.geboortedatum_omschrijving,
        primary_email_address: !relatie.primair_emailadres
            ? null
            : {
                end_date: relatie.primair_emailadres.einddatum,
                validity_status_code: relatie.primair_emailadres.geldigheid_status_code,
                start_date: relatie.primair_emailadres.ingangsdatum,
                email_address_type_code: relatie.primair_emailadres.soort_emailadres_code,
                email_address: relatie.primair_emailadres.emailadres,
            },
        primary_email_address_future: null,
        primary_email_address_history: null,
        recovery_email_address: !relatie.herstel_emailadres
            ? null
            : {
                end_date: relatie.herstel_emailadres.einddatum,
                validity_status_code: relatie.herstel_emailadres.geldigheid_status_code,
                start_date: relatie.herstel_emailadres.ingangsdatum,
                email_address_type_code: relatie.herstel_emailadres.soort_emailadres_code,
                email_address: relatie.herstel_emailadres.emailadres,
            },
        recovery_email_address_future: null,
        recovery_email_address_history: null,
        salutation_form_code: relatie.aanspreekwijze_code,
        salutation_form_description: relatie.aanspreekwijze_omschrijving,
        deceased_date: relatie.overlijdensdatum,
        credit_payment_block_timestamp: relatie.excasso_blokkade_moment,
        direct_debit_agreement_indication: relatie.automatische_incasso_akkoord_indicatie,
        direct_debit_agreement_indication_description: relatie.automatische_incasso_akkoord_indicatie_omschrijving,
        remarks: relatie.bijzonderheden,
        opt_out_timestamp: relatie.opt_out_moment,
        activation_timestamp: relatie.activeringsmoment,
        created_timestamp: relatie.registratiemoment,
        removed_timestamp: relatie.verwijderingsmoment,
        insurance_termination_timestamp: relatie.royementsmoment,
        insurance_termination_date: relatie.royementsdatum,
        stack_discount_indication: relatie.stapelkorting_toepassen_indicatie,
        stack_discount_indication_description: relatie.stapelkorting_toepassen_indicatie_omschrijving,
        residential_address: !relatie.woonadres
            ? null
            : {
                postal_code: relatie.woonadres.postcode,
                civic_number: relatie.woonadres.huisnummer,
                civic_number_suffix: relatie.woonadres.huisnummer_toevoeging,
                country_id: relatie.woonadres.land_id,
                country_description: relatie.woonadres.land_omschrijving,
                invalidated_timestamp: relatie.woonadres.ongedaan_moment,
                address: {
                    street_name: relatie.woonadres.straatnaam,
                    city: relatie.woonadres.woonplaats,
                },
                start_date: relatie.woonadres.ingangsdatum,
                end_date: relatie.woonadres.einddatum,
                validity_status_code: relatie.woonadres.geldigheid_status_code,
            },
        residential_address_future: null,
        residential_address_history: null,
        mailing_address: !relatie.correspondentieadres
            ? null
            : {
                postal_code: relatie.correspondentieadres.postcode,
                civic_number: relatie.correspondentieadres.huisnummer,
                civic_number_suffix: relatie.correspondentieadres.huisnummer_toevoeging,
                country_id: relatie.correspondentieadres.land_id,
                country_description: relatie.correspondentieadres.land_omschrijving,
                invalidated_timestamp: relatie.correspondentieadres.ongedaan_moment,
                address: {
                    street_name: relatie.correspondentieadres.straatnaam,
                    city: relatie.correspondentieadres.woonplaats,
                },
                start_date: relatie.correspondentieadres.ingangsdatum,
                end_date: relatie.correspondentieadres.einddatum,
                validity_status_code: relatie.correspondentieadres.geldigheid_status_code,
            },
        mailing_address_future: null,
        mailing_address_history: null,
        bank_mandate: !relatie.bank_machtiging
            ? null
            : {
                invalidated_timestamp: relatie.bank_machtiging.ongedaan_moment,
                iban_country_code: relatie.bank_machtiging.iban_land_code,
                iban_country_description: relatie.bank_machtiging.iban_land_omschrijving,
                iban_check_digits: relatie.bank_machtiging.iban_controle_getal,
                iban_bank_identifier_code: relatie.bank_machtiging.iban_bank_code,
                iban_bank_identifier_description: relatie.bank_machtiging.iban_bank_omschrijving,
                iban_account_number: relatie.bank_machtiging.iban_rekeningnummer,
                account_holder_name: relatie.bank_machtiging.tenaamstelling,
                iban_bank_account_number: relatie.bank_machtiging.iban_bankrekeningnummer,
                iban_account_number_formatted: relatie.bank_machtiging.rekeningnummer_opgemaakt,
                mandate_number: relatie.bank_machtiging.mandaatnummer,
                start_date: relatie.bank_machtiging.ingangsdatum,
                end_date: relatie.bank_machtiging.einddatum,
                validity_status_code: relatie.bank_machtiging.geldigheid_status_code,
            },
        bank_mandate_future: null,
        bank_mandate_history: null,
        family_composition: !relatie.gezinssamenstelling
            ? null
            : {
                relation_id: relatie.gezinssamenstelling.relatie_id,
                invalidated_timestamp: relatie.gezinssamenstelling.ongedaan_moment,
                family_composition_code: relatie.gezinssamenstelling.gezinssamenstelling_code,
                family_composition_description: relatie.gezinssamenstelling.gezinssamenstelling_omschrijving,
                start_date: relatie.gezinssamenstelling.ingangsdatum,
                end_date: relatie.gezinssamenstelling.einddatum,
                validity_status_code: relatie.gezinssamenstelling.geldigheid_status_code,
            },
        family_composition_future: !relatie.gezinssamenstelling_toekomstig
            ? null
            : {
                relation_id: relatie.gezinssamenstelling_toekomstig.relatie_id,
                invalidated_timestamp: relatie.gezinssamenstelling_toekomstig.ongedaan_moment,
                family_composition_code: relatie.gezinssamenstelling_toekomstig.gezinssamenstelling_code,
                family_composition_description:
                      relatie.gezinssamenstelling_toekomstig.gezinssamenstelling_omschrijving,
                start_date: relatie.gezinssamenstelling_toekomstig.ingangsdatum,
                end_date: relatie.gezinssamenstelling_toekomstig.einddatum,
                validity_status_code: relatie.gezinssamenstelling_toekomstig.geldigheid_status_code,
            },
        family_composition_history: null,
        mobile_telephone_number_present_indication: relatie.mobiel_telefoonnummer_aanwezig_indicatie,
        recovery_email_address_present_indication: relatie.herstel_emailadres_aanwezig_indicatie,
        collectivity_partner: !relatie.collectiviteit_partner
            ? null
            : {
                subdomain: relatie.collectiviteit_partner.subdomein,
            },
    };
}

/** @deprecated supporting angular.js */
export function mapToLegacyUser(relation: RelationInterface): LegacyUserInterface {
    return {
        naam: relation.name,
        relatie_id: relation.relation_id,
        geslacht_code: relation.gender_code,
        geslacht_omschrijving: relation.gender_description,
        voornaam: relation.first_name,
        achternaam: relation.surname,
        voorletters: relation.initials,
        tussenvoegsels: relation.middle_name_initials,
        titel: relation.pre_nominal_letters,
        achtertitel: relation.post_nominal_letters,
        account_geblokkeerd_indicatie: relation.account_blocked_indication,
        account_geblokkeerd_indicatie_omschrijving: relation.account_blocked_indication_description,
        mobiel_telefoonnummer: !relation.mobile_telephone_number
            ? null
            : {
                soort_telefoonnummer_code: relation.mobile_telephone_number.telephone_number_type_code,
                telefoonnummer: relation.mobile_telephone_number.telephone_number,
                ingangsdatum: relation.mobile_telephone_number.start_date,
                einddatum: relation.mobile_telephone_number.end_date,
                geldigheid_status_code: relation.mobile_telephone_number.validity_status_code,
            },
        mobiel_telefoonnummer_toekomstig: !relation.mobile_telephone_number_future
            ? null
            : {
                soort_telefoonnummer_code: relation.mobile_telephone_number_future.telephone_number_type_code,
                telefoonnummer: relation.mobile_telephone_number_future.telephone_number,
                ingangsdatum: relation.mobile_telephone_number_future.start_date,
                einddatum: relation.mobile_telephone_number_future.end_date,
                geldigheid_status_code: relation.mobile_telephone_number_future.validity_status_code,
            },
        mobiel_telefoonnummer_historie: null,
        vast_telefoonnummer: !relation.landline_telephone_number
            ? null
            : {
                soort_telefoonnummer_code: relation.landline_telephone_number.telephone_number_type_code,
                telefoonnummer: relation.landline_telephone_number.telephone_number,
                ingangsdatum: relation.landline_telephone_number.start_date,
                einddatum: relation.landline_telephone_number.end_date,
                geldigheid_status_code: relation.landline_telephone_number.validity_status_code,
            },
        vast_telefoonnummer_toekomstig: !relation.landline_telephone_number_future
            ? null
            : {
                soort_telefoonnummer_code: relation.landline_telephone_number_future.telephone_number_type_code,
                telefoonnummer: relation.landline_telephone_number_future.telephone_number,
                ingangsdatum: relation.landline_telephone_number_future.start_date,
                einddatum: relation.landline_telephone_number_future.end_date,
                geldigheid_status_code: relation.landline_telephone_number_future.validity_status_code,
            },
        vast_telefoonnummer_historie: null,
        geboortedatum: relation.birthdate,
        geboortedatum_omschrijving: relation.date_of_birth_description,
        primair_emailadres: !relation.primary_email_address
            ? null
            : {
                soort_emailadres_code: relation.primary_email_address.email_address_type_code,
                emailadres: relation.primary_email_address.email_address,
                ingangsdatum: relation.primary_email_address.start_date,
                einddatum: relation.primary_email_address.end_date,
                geldigheid_status_code: relation.primary_email_address.validity_status_code,
            },
        primair_emailadres_toekomstig: !relation.primary_email_address_future
            ? null
            : {
                soort_emailadres_code: relation.primary_email_address_future.email_address_type_code,
                emailadres: relation.primary_email_address_future.email_address,
                ingangsdatum: relation.primary_email_address_future.start_date,
                einddatum: relation.primary_email_address_future.end_date,
                geldigheid_status_code: relation.primary_email_address_future.validity_status_code,
            },
        primair_emailadres_historie: null,
        herstel_emailadres: !relation.recovery_email_address
            ? null
            : {
                soort_emailadres_code: relation.recovery_email_address.email_address_type_code,
                emailadres: relation.recovery_email_address.email_address,
                ingangsdatum: relation.recovery_email_address.start_date,
                einddatum: relation.recovery_email_address.end_date,
                geldigheid_status_code: relation.recovery_email_address.validity_status_code,
            },
        herstel_emailadres_toekomstig: !relation.recovery_email_address_future
            ? null
            : {
                soort_emailadres_code: relation.recovery_email_address_future.email_address_type_code,
                emailadres: relation.recovery_email_address_future.email_address,
                ingangsdatum: relation.recovery_email_address_future.start_date,
                einddatum: relation.recovery_email_address_future.end_date,
                geldigheid_status_code: relation.recovery_email_address_future.validity_status_code,
            },
        herstel_emailadres_historie: null,
        aanspreekwijze_code: relation.salutation_form_code,
        aanspreekwijze_omschrijving: relation.salutation_form_description,
        overlijdensdatum: relation.deceased_date,
        excasso_blokkade_moment: relation.credit_payment_block_timestamp,
        automatische_incasso_akkoord_indicatie: relation.direct_debit_agreement_indication,
        automatische_incasso_akkoord_indicatie_omschrijving: relation.direct_debit_agreement_indication_description,
        bijzonderheden: relation.remarks,
        opt_out_moment: relation.opt_out_timestamp,
        activeringsmoment: relation.activation_timestamp,
        registratiemoment: relation.created_timestamp,
        verwijderingsmoment: relation.removed_timestamp,
        royementsmoment: relation.insurance_termination_timestamp,
        royementsdatum: relation.insurance_termination_date,
        stapelkorting_toepassen_indicatie: relation.stack_discount_indication,
        stapelkorting_toepassen_indicatie_omschrijving: relation.stack_discount_indication_description,
        woonadres: !relation.residential_address
            ? null
            : {
                postcode: relation.residential_address.postal_code,
                huisnummer: relation.residential_address.civic_number,
                huisnummer_toevoeging: relation.residential_address.civic_number_suffix,
                land_id: relation.residential_address.country_id,
                land_omschrijving: relation.residential_address.country_description,
                ingangsdatum: relation.residential_address.start_date,
                einddatum: relation.residential_address.end_date,
                geldigheid_status_code: relation.residential_address.start_date,
                ongedaan_moment: relation.residential_address.invalidated_timestamp,
                adres: {
                    straatnaam: relation.residential_address.address.street_name,
                    woonplaats: relation.residential_address.address.city,
                },
            },
        woonadres_toekomstig: !relation.residential_address_future
            ? null
            : {
                postcode: relation.residential_address_future.postal_code,
                huisnummer: relation.residential_address_future.civic_number,
                huisnummer_toevoeging: relation.residential_address_future.civic_number_suffix,
                land_id: relation.residential_address_future.country_id,
                land_omschrijving: relation.residential_address_future.country_description,
                ingangsdatum: relation.residential_address_future.start_date,
                einddatum: relation.residential_address_future.end_date,
                geldigheid_status_code: relation.residential_address_future.start_date,
                ongedaan_moment: relation.residential_address_future.invalidated_timestamp,
                adres: {
                    straatnaam: relation.residential_address_future.address.street_name,
                    woonplaats: relation.residential_address_future.address.city,
                },
            },
        woonadres_historie: null,
        correspondentieadres: !relation.mailing_address
            ? null
            : {
                postcode: relation.mailing_address.postal_code,
                huisnummer: relation.mailing_address.civic_number,
                huisnummer_toevoeging: relation.mailing_address.civic_number_suffix,
                land_id: relation.mailing_address.country_id,
                land_omschrijving: relation.mailing_address.country_description,
                ingangsdatum: relation.mailing_address.start_date,
                einddatum: relation.mailing_address.end_date,
                geldigheid_status_code: relation.mailing_address.start_date,
                ongedaan_moment: relation.mailing_address.invalidated_timestamp,
                adres: {
                    straatnaam: relation.mailing_address.address.street_name,
                    woonplaats: relation.mailing_address.address.city,
                },
            },
        correspondentieadres_toekomstig: !relation.mailing_address_future
            ? null
            : {
                postcode: relation.mailing_address_future.postal_code,
                huisnummer: relation.mailing_address_future.civic_number,
                huisnummer_toevoeging: relation.mailing_address_future.civic_number_suffix,
                land_id: relation.mailing_address_future.country_id,
                land_omschrijving: relation.mailing_address_future.country_description,
                ingangsdatum: relation.mailing_address_future.start_date,
                einddatum: relation.mailing_address_future.end_date,
                geldigheid_status_code: relation.mailing_address_future.start_date,
                ongedaan_moment: relation.mailing_address_future.invalidated_timestamp,
                adres: {
                    straatnaam: relation.mailing_address_future.address.street_name,
                    woonplaats: relation.mailing_address_future.address.city,
                },
            },
        correspondentieadres_historie: null,
        bank_machtiging: !relation.bank_mandate
            ? null
            : {
                ingangsdatum: relation.bank_mandate.start_date,
                einddatum: relation.bank_mandate.end_date,
                geldigheid_status_code: relation.bank_mandate.validity_status_code,
                ongedaan_moment: relation.bank_mandate.invalidated_timestamp,
                iban_land_code: relation.bank_mandate.iban_country_code,
                iban_land_omschrijving: relation.bank_mandate.iban_country_description,
                iban_controle_getal: relation.bank_mandate.iban_check_digits,
                iban_bank_code: relation.bank_mandate.iban_bank_identifier_code,
                iban_bank_omschrijving: relation.bank_mandate.iban_bank_identifier_description,
                iban_rekeningnummer: relation.bank_mandate.iban_account_number,
                tenaamstelling: relation.bank_mandate.account_holder_name,
                iban_bankrekeningnummer: relation.bank_mandate.iban_bank_account_number,
                iban_rekeningnummer_opgemaakt: relation.bank_mandate.iban_account_number_formatted,
                mandaatnummer: relation.bank_mandate.mandate_number,
            },
        bank_machtiging_toekomstig: !relation.bank_mandate_future
            ? null
            : {
                ingangsdatum: relation.bank_mandate_future.start_date,
                einddatum: relation.bank_mandate_future.end_date,
                geldigheid_status_code: relation.bank_mandate_future.validity_status_code,
                ongedaan_moment: relation.bank_mandate_future.invalidated_timestamp,
                iban_land_code: relation.bank_mandate_future.iban_country_code,
                iban_land_omschrijving: relation.bank_mandate_future.iban_country_description,
                iban_controle_getal: relation.bank_mandate_future.iban_check_digits,
                iban_bank_code: relation.bank_mandate_future.iban_bank_identifier_code,
                iban_bank_omschrijving: relation.bank_mandate_future.iban_bank_identifier_description,
                iban_rekeningnummer: relation.bank_mandate_future.iban_account_number,
                tenaamstelling: relation.bank_mandate_future.account_holder_name,
                iban_bankrekeningnummer: relation.bank_mandate_future.iban_bank_account_number,
                iban_rekeningnummer_opgemaakt: relation.bank_mandate_future.iban_account_number_formatted,
                mandaatnummer: relation.bank_mandate_future.mandate_number,
            },
        bank_machtiging_historie: null,
        gezinssamenstelling: !relation.family_composition
            ? null
            : {
                relatie_id: relation.family_composition.relation_id,
                ingangsdatum: relation.family_composition.start_date,
                einddatum: relation.family_composition.end_date,
                geldigheid_status_code: relation.family_composition.validity_status_code,
                ongedaan_moment: relation.family_composition.invalidated_timestamp,
                gezinssamenstelling_code: relation.family_composition.family_composition_code,
                gezinssamenstelling_omschrijving: relation.family_composition.family_composition_description,
            },
        gezinssamenstelling_toekomstig: !relation.family_composition_future
            ? null
            : {
                relatie_id: relation.family_composition_future.relation_id,
                ingangsdatum: relation.family_composition_future.start_date,
                einddatum: relation.family_composition_future.end_date,
                geldigheid_status_code: relation.family_composition_future.validity_status_code,
                ongedaan_moment: relation.family_composition_future.invalidated_timestamp,
                gezinssamenstelling_code: relation.family_composition_future.family_composition_code,
                gezinssamenstelling_omschrijving: relation.family_composition_future.family_composition_description,
            },
        gezinssamenstelling_historie: null,
        mobiel_telefoonnummer_aanwezig_indicatie: relation.mobile_telephone_number_present_indication,
        herstel_emailadres_aanwezig_indicatie: relation.recovery_email_address_present_indication,
        collectiviteit_partner: !relation.collectivity_partner
            ? null
            : {
                subdomein: relation.collectivity_partner.subdomain,
            },
    };
}
