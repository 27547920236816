export enum ApiBusinessRelationLegalPartyVersionIdEnum {
    Alarm = 'ALRM', // Alarm centrale
    LegalAidInsurer = 'ASRB', // Rechtsbijstand assuradeur
    Insurer = 'ASSU', // Assuradeur/Maatschappij
    CareInsurer = 'ASZG', // Zorg assuradeur
    PartInsharedConcern = 'CCRN', // Onderdeel InShared Concern
    DistributionPartner = 'DPRT', // Distributie partner
    ExternalHiring = 'EXIH', // Externe inhuur
    ExpertiseAgency = 'EXPE', // Expertisebureau
    InjuryClaimsSettelmentAgency = 'LSRL', // Letsel schaderegelingsbureau
    Brand = 'MERK', // Merk
    Buyer = 'OPKO', // Opkoper
    DamageRepairCompany = 'SHER', // Schadeherstelbedrijf
    ClaimSettlementOffice = 'SRLR', // Assurantiekantoor/Schaderegelingsbureau
    Counterparty = 'TEGP', // TEGP
    Supervisor = 'TZHR', // Toezichthouder
    PowerOfAttorny = 'VOLM', // Volmacht
}
