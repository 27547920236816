import { createAction, props, union } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { ApiTestimonialsRequestInterface, GetTestimonialsResponseInterface } from '../interfaces';

export enum TestimonialsActionsEnum {
    GetTestimonials = '[Reviews] Get Testimonials',
    GetTestimonialsSuccess = '[Reviews] Get Testimonials Success',
    GetTestimonialsError = '[Reviews] Get Testimonials Error',
}

export const getTestimonialsAction = createAction(
    TestimonialsActionsEnum.GetTestimonials,
    props<ApiTestimonialsRequestInterface>()
);
export const getTestimonialsSuccessAction = createAction(
    TestimonialsActionsEnum.GetTestimonialsSuccess,
    props<GetTestimonialsResponseInterface>()
);

export const getTestimonialsErrorAction = createAction(
    TestimonialsActionsEnum.GetTestimonialsError,
    props<{ errors: ErrorInterface[] }>()
);

const testimonialsActionsUnion = union({
    getTestimonialsAction,
    getTestimonialsSuccessAction,
    getTestimonialsErrorAction,
});

export type TestimonialsActions = typeof testimonialsActionsUnion;
