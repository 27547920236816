import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContentEffects, ContentReducers, CONTENT_FEATURE } from './store';

@NgModule({
    imports: [
        StoreModule.forFeature(CONTENT_FEATURE, ContentReducers.reducer),
        EffectsModule.forFeature([ContentEffects]),
    ],
})
export class OutContentModule {}
