import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ApiService,
    DateService,
    IndicationEnum,
    RequestResponse,
    RequestService,
    ResponseInterface,
} from '../../../core';
import { ProductIdEnum } from '../../enums';
import {
    DownloadFileInterface,
    NonRegularDriverPropsInterface,
    ProductBaseInterface,
    ProductBasePayloadInterface,
    ProductBasePurchasedCoverageInterface,
    TerminateActivePurchasedProductRequestInterface,
    TerminateActivePurchasedProductResponseInterface,
    ApiTerminateActivePurchasedProductRequest,
    ApiTerminateActivePurchasedProductResponse,
    ApiReviveTerminatedPurchasedProductRequest,
    CreateNonRegularDriverRequestInterface,
    ApiProductEnGbInterface,
} from '../../interfaces';
import {
    mapCreateNonRegularDriverRequest,
    mapReviveTerminatedActivePurchasedProductRequest,
    mapTerminateActivePurchasedProductRequest,
    mapTerminateActivePurchasedProductResponse,
    PolicyFileDownloadMapper,
} from '../../mappers/';

/** @deprecated use InsurancePolicyDataService / InsurancePolicyActionService */
@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private requestService: RequestService, private apiService: ApiService) {}

    /* File is deprecated and needs to be removed after integration insurance-policy store. OPPO-5765 */
    /* eslint-disable rxjs/finnish, @typescript-eslint/naming-convention */
    /** @deprecated use InsurancePolicyActionService */
    public loadPolicy(props: { present_history_indication: boolean }): Observable<ProductBaseInterface[]> {
        const payload = {
            present_history_indication: props.present_history_indication ? 'J' : 'N',
            premium_determining_factor_indication: null,
        };

        return this.requestService.post('en-gb/relation-general/request/policy', payload).pipe(
            map((response) => response.data.purchased_products),
            map((products) => products.map((product) => this.validateStatusCode(product))),
            map((products) => products.map((product) => this.validatePermittedCoverage(product)))
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public downloadPolicy(historyIndication: IndicationEnum): Observable<DownloadFileInterface> {
        const payload = {
            show_history_indication: historyIndication,
        };

        return this.apiService.postResponse$('en-gb/relation-general/provide/policy', payload).pipe(
            map(ApiService.throwErrors),
            map((policy: ResponseInterface) => PolicyFileDownloadMapper(policy))
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public loadPurchasedProduct(props: {
        purchased_product_sequence_number: number;
        product_id: ProductIdEnum;
    }): Observable<ProductBaseInterface> {
        const { purchased_product_sequence_number, product_id } = props;
        const payload: ProductBasePayloadInterface = {
            purchased_product: {
                purchased_product_sequence_number,
            },
        };

        return this.getPurchasedProduct(product_id, payload).pipe(
            map((purchasedProduct) => this.validateStatusCode(purchasedProduct)),
            map((purchasedProduct) => this.validatePermittedCoverage(purchasedProduct))
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFuturePurchasedProduct(props: {
        purchased_product_sequence_number: number;
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number } = props;
        const payload = {
            purchased_product: {
                purchased_product_sequence_number,
            },
        };

        return this.requestService.post('en-gb/insurance-product/cancel/future-purchased_product', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public modifyFuturePurchasedProductStartDate(props: {
        start_date: string;
        purchased_product_sequence_number: number;
    }): Observable<RequestResponse> {
        const { start_date, purchased_product_sequence_number } = props;
        const payload = {
            purchased_product: {
                start_date,
                purchased_product_sequence_number,
            },
        };

        return this.requestService.post('en-gb/insurance-product/modify/future-purchased_product-start_date', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public terminateActivePurchasedProduct(
        request: TerminateActivePurchasedProductRequestInterface
    ): Observable<TerminateActivePurchasedProductResponseInterface> {
        const payload: ApiTerminateActivePurchasedProductRequest = mapTerminateActivePurchasedProductRequest(request);

        return this.apiService
            .post$('en-gb/insurance-product/terminate/active-purchased_product', payload)
            .pipe(
                map((purchasedProduct: ApiTerminateActivePurchasedProductResponse) =>
                    mapTerminateActivePurchasedProductResponse(purchasedProduct)
                )
            );
    }

    /** @deprecated use InsurancePolicyActionService */
    public reviveTerminatedPurchasedProduct(purchasedProductSequenceNumber: number): Observable<string> {
        const payload: ApiReviveTerminatedPurchasedProductRequest =
            mapReviveTerminatedActivePurchasedProductRequest(purchasedProductSequenceNumber);

        return this.apiService.post$<string>('en-gb/insurance-product/revive/terminated-purchased_product', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public modifyTerminationDateForProductEndDate(props: {
        purchased_product_sequence_number: number;
        end_date: string;
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number, end_date } = props;
        const payload = {
            purchased_product: {
                end_date,
                purchased_product_sequence_number,
            },
        };

        return this.requestService.post(
            'en-gb/insurance-product/modify/new_termination_date_for_future-purchased_product-end_date',
            payload
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    public requestNonRegularDriver(props: { purchasedProductSequenceNumber: number }): Observable<RequestResponse> {
        const { purchasedProductSequenceNumber } = props;
        const payload = {
            purchased_product: {
                purchased_product_sequence_number: purchasedProductSequenceNumber,
            },
        };

        return this.requestService.post('en-gb/insurance-product/request/non_regular_driver', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public createNonRegularDriver(request: CreateNonRegularDriverRequestInterface): Observable<RequestResponse> {
        const payload = mapCreateNonRegularDriverRequest(request);
        return this.requestService.post('en-gb/insurance-product/create/non_regular_driver', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    /** Use createNonRegularDriver in favor of storeNonRegularDriver; they are both the same */
    public storeNonRegularDriver(props: {
        purchased_product_sequence_number: number;
        non_regular_driver: NonRegularDriverPropsInterface;
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number, non_regular_driver } = props;
        const payload = {
            afgenomen_product: {
                afgenomen_product_volgnummer: purchased_product_sequence_number,
            },
            afwijkende_bestuurder: {
                achternaam: non_regular_driver.surname,
                geboortedatum: non_regular_driver.birthdate,
                geslacht_code: non_regular_driver.gender_code,
                ingangsdatum: non_regular_driver.start_date,
                relatie_code: non_regular_driver.relation_code,
                tussenvoegsels: non_regular_driver.middle_name_initials,
                voorletters: non_regular_driver.initials,
            },
        };

        // TODO: WINS-5173 - Endpoint aanpassen naar engelse endpoint
        return this.requestService.post('en-gb/insurance-product/store/non_regular_driver', payload);
        // return this.requestService2.post('verzekering-product/vastleggen/afwijkendebestuurder', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFutureNonRegularDriver(props: {
        purchased_product_sequence_number: number;
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number } = props;
        const payload = {
            purchased_product: {
                purchased_product_sequence_number,
            },
        };

        return this.requestService.post('en-gb/insurance-product/cancel/future-non_regular_driver', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public terminateActiveNonRegularDriver(props: {
        end_date: string;
        purchased_product_sequence_number: number;
    }): Observable<RequestResponse> {
        const { end_date, purchased_product_sequence_number } = props;
        const payload = {
            non_regular_driver: {
                end_date,
                purchased_product_sequence_number,
            },
        };

        return this.requestService.post('en-gb/insurance-product/terminate/active-non_regular_driver', payload);
    }

    /** @deprecated use InsurancePolicyActionService */
    public cancelFutureModifiablePremiumDeterminingFactor(props: {
        premium_determining_factor_id: string;
        purchased_product_sequence_number: number;
    }): Observable<RequestResponse> {
        const { purchased_product_sequence_number, premium_determining_factor_id } = props;
        const payload = {
            modifiable_premium_determining_factor: {
                purchased_product_sequence_number,
                premium_determining_factor_id,
            },
        };

        return this.requestService.post(
            'en-gb/insurance-product/cancel/future-modifiable_premium_determining_factor',
            payload
        );
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPremiumModificationPurchasedProducts(
        referenceDate: string,
        sequenceNumber?: number
    ): Observable<ApiProductEnGbInterface[]> {
        const payload = {
            reference_date: referenceDate,
            ...(sequenceNumber && { purchased_product: { purchased_product_sequence_number: sequenceNumber } }),
        };

        return this.requestService
            .post('en-gb/insurance-product/request/premium_modification-purchased_products', payload)
            .pipe(
                map((result) => result.data),
                map((result) => (result ? result.purchased_products : []))
            );
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProduct(
        productId: ProductIdEnum,
        payload: ProductBasePayloadInterface
    ): Observable<ProductBaseInterface> {
        switch (productId) {
            case ProductIdEnum.Liability:
                return this.getPurchasedProductLiability(payload);
            case ProductIdEnum.Car:
                return this.getPurchasedProductCar(payload);
            case ProductIdEnum.Moped:
                return this.getPurchasedProductMoped(payload);
            case ProductIdEnum.Pet:
                return this.getPurchasedProductPet(payload);
            case ProductIdEnum.Motorcycle:
                return this.getPurchasedProductMotor(payload);
            case ProductIdEnum.Accidents:
                return this.getPurchasedProductAccident(payload);
            case ProductIdEnum.Legal:
                return this.getPurchasedProductLegalCounsel(payload);
            case ProductIdEnum.Travel:
                return this.getPurchasedProductTravel(payload);
            case ProductIdEnum.Home:
                return this.getPurchasedProductHome(payload);
            case ProductIdEnum.Bicycle:
                return this.getPurchasedProductBycicle(payload);
            case ProductIdEnum.Caravan:
                return this.getPurchasedProductCaravan(payload);
        }
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductLiability(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-liability', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductCar(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-auto', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductMoped(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-limited_speed_motorcycle', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductPet(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-pet', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductMotor(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-motorcycle', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductAccident(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-accidents', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductLegalCounsel(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-legal_assistance', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductTravel(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-travel', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductHome(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-home', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductBycicle(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-bicycle', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private getPurchasedProductCaravan(payload: ProductBasePayloadInterface): Observable<ProductBaseInterface> {
        return this.requestService
            .post('en-gb/insurance-product/request/purchased_product-caravan', payload)
            .pipe(map((response) => response.data));
    }

    /** @deprecated use InsurancePolicyActionService */
    private validateStatusCode(purchasedProduct: ProductBaseInterface): ProductBaseInterface {
        if (purchasedProduct.validity_status_code === 'TOEK' && purchasedProduct.start_date === '9999-12-31') {
            purchasedProduct.validity_status_code = 'OSTP';
        }

        return purchasedProduct;
    }

    /** @deprecated use InsurancePolicyActionService */
    private validatePermittedCoverage(purchasedProduct: ProductBaseInterface): ProductBaseInterface {
        const dateService = new DateService();
        const permitted_coverage: ProductBasePurchasedCoverageInterface[] = purchasedProduct['permitted_coverage']
            ? purchasedProduct['permitted_coverage']
            : null;

        if (!permitted_coverage) {
            return purchasedProduct;
        }

        if (purchasedProduct.validity_status_code !== 'ACTI') {
            return purchasedProduct;
        }

        for (const coverage of permitted_coverage) {
            if (coverage['purchased_coverage'] && coverage['purchased_coverage'].end_date) {
                if (dateService.isAfter(coverage['purchased_coverage'].end_date, purchasedProduct.start_date)) {
                    purchasedProduct.validity_status_code = 'ACWI';
                }
            }
            if (coverage['purchased_coverage_future']) {
                if (
                    dateService.isAfter(coverage['purchased_coverage_future'].start_date, purchasedProduct.start_date)
                ) {
                    purchasedProduct.validity_status_code = 'ACWI';
                }
            }
        }

        return purchasedProduct;
    }
}
