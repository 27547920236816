/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostBinding, HostListener, OnInit, Optional } from '@angular/core';
import { NewModalRef } from './new-modal-ref';
import { getClosestModal } from './new-modal-utilities';
import { NewModal } from './new-modal.service';

/**
 * Close button at the top of the dialog.
 * Position absolute on top-right.
 */
@Directive({
    selector: '[modal-close-btn], modal-close-btn, [insModalCloseBtn]',
})
export class NewModalCloseBtnDirective implements OnInit {
    @HostBinding('class.modal__close-btn') public _class: boolean = true;

    constructor(
        @Optional() public modalRef: NewModalRef<any>,
        private _elementRef: ElementRef<HTMLElement>,
        private _modal: NewModal
    ) {}

    @HostListener('click')
    public onClick(): boolean {
        this.modalRef.close(false);
        return false;
    }

    public ngOnInit(): void {
        if (!this.modalRef) {
            this.modalRef = getClosestModal(this._elementRef, this._modal.openModals);
        }
    }
}
