import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClaimPartiesEffects } from './store/claim-parties.effects';
import { claimPartiesReducer } from './store/claim-parties.reducers';
import { CLAIM_PARTIES_FEATURE } from './store/claim-parties.state';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_PARTIES_FEATURE, claimPartiesReducer),
        EffectsModule.forFeature([ClaimPartiesEffects]),
    ],
})
export class OutClaimPartiesModule {}
