export enum FactorIdEnum {
    DamageFreeYears = 'Damage Free Years', // Schadevrije jaren;
    BonusMalusStep = 'Bonus Malus Step', // BM trede
    KilometerPerYearCar = 'Kilometers Per Year Car', // Kilometers per jaar auto
    PostalcodeRateMoped = 'Postalcode Rate Moped', // Postcode tarief bromfiets
    FuelMoped = 'Fuel Moped', // Brandstof Bromfiets
    HailroofIndication = 'Hailroofing indication', // Hageldak indicatie
    KilometerPerYearMotor = 'Kilometer Per Year Motor', // Kilometers per jaar
    Content = 'Content', // Inhoud
    Strawroofing = 'Strawroofing', // Dak met riet
    MonumentCode = 'Momument Code', // Monument code
    BuildYear = 'Build Year', // Bouwjaar
    OwnRisk = 'Own Risk', // eigen risico
    HomeType = 'Home Type', // Type woonhuis
    UserSurface = 'User Surface', // Gebruiksoppervlakte
    Security = 'Security', // Beveiliging
}
