import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    UpdateSpecificationRequestInterface,
    ResumeSpecificationRequestInterface,
    GetSpecificationRequestInterface,
    GetSpecificationResponseInterface,
    RemoveSpecificationRequestInterface,
} from '../interfaces';
import * as ClaimSpecificationActions from '../store/claim-specification.actions';
import { ClaimSpecificationState } from '../store/claim-specification.state';
import { ClaimSpecificationEventService } from './claim-specification.event.service';

@Injectable({
    providedIn: 'root',
})
export class ClaimSpecificationActionService {
    constructor(
        private store$: Store<ClaimSpecificationState>,
        private claimSpecificationEventService: ClaimSpecificationEventService
    ) {}

    // get specification
    public getSpecification$(request: GetSpecificationRequestInterface): Observable<GetSpecificationResponseInterface> {
        this.store$.dispatch(ClaimSpecificationActions.getSpecification({ request }));
        return this.claimSpecificationEventService.getSpecification$();
    }

    // resume specification
    public resumeSpecification$(request: ResumeSpecificationRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimSpecificationActions.resumeSpecification({ request }));
        return this.claimSpecificationEventService.resumeSpecification$();
    }

    // update specifiction
    public updateSpecification$(request: UpdateSpecificationRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimSpecificationActions.updateSpecification({ request }));
        return this.claimSpecificationEventService.updateSpecification$();
    }

    // remove specifiction
    public removeSpecification$(request: RemoveSpecificationRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimSpecificationActions.removeSpecification({ request }));
        return this.claimSpecificationEventService.removeSpecification$();
    }

    // reset claim specification
    public resetClaimSpecifications(): void {
        this.store$.dispatch(ClaimSpecificationActions.resetClaimSpecification());
    }
}
