import { createSelector } from '@ngrx/store';
import { getSalesState } from '../sales.reducers';
import * as fromProgress from './progress.reducer';

export const getProgressState = createSelector(getSalesState, (state) => state.progress);
export const getProductId = createSelector(getProgressState, fromProgress.getProductId);
export const getSteps = createSelector(getProgressState, fromProgress.getSteps);
export const getCurrent = createSelector(getProgressState, fromProgress.getCurrent);
export const getRoutes = createSelector(getProgressState, fromProgress.getRoutes);
export const getRouteIndex = createSelector(getProgressState, fromProgress.getRouteIndex);

export const getNext = createSelector(getSteps, getCurrent, (steps, current) => {
    if (current + 1) {
        return steps.find((step) => +step.step === current + 1);
    }
});

export const getPrevious = createSelector(getSteps, getCurrent, (steps, current) => {
    if (current - 1 > -1) {
        return steps.find((step) => +step.step === current - 1);
    }
});

export const getRouteUri = createSelector(getRoutes, getRouteIndex, (routes, index) => {
    return routes[index];
});
