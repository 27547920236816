import { mapEnumApiRequest, mapEnumApiResponse } from '../../core';
import { ApiMethodClaimObjectAdditionCodeEnum, MethodClaimObjectAdditionCodeEnum } from '../enums';

export function mapSpecificationMethodClaimObjectAdditionCodeResponse(
    response: ApiMethodClaimObjectAdditionCodeEnum
): MethodClaimObjectAdditionCodeEnum {
    return mapEnumApiResponse(response, ApiMethodClaimObjectAdditionCodeEnum, MethodClaimObjectAdditionCodeEnum);
}

export function mapSpecificationMethodClaimObjectAdditionCodeRequest(
    request: MethodClaimObjectAdditionCodeEnum
): ApiMethodClaimObjectAdditionCodeEnum {
    return mapEnumApiRequest(request, ApiMethodClaimObjectAdditionCodeEnum, MethodClaimObjectAdditionCodeEnum);
}
