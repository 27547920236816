import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { ErrorInterface, mapFromIndication, ResponseInterface } from '../../core';
import { ProductActionService, ProductEnGbInterface } from '../../my-zone';
import { CreateResidentialAddressResponseInterface, RelationInterface } from '../interfaces';
import { LegacyRelationStorageService } from '../services/legacy-relation.storage.service';
import { RelationApiService } from '../services/relation.api.service';
import { RelationEventService } from '../services/relation.event.service';
import { RelationStorageService } from './../services/relation.storage.service';
import {
    AddDutchMailingAddressAction,
    AddDutchMailingAddressErrorAction,
    AddDutchMailingAddressSuccessAction,
    AddMailingAddressAction,
    AddMailingAddressErrorAction,
    AddMailingAddressSuccessAction,
    CancelFamilyCompositionAction,
    CancelFamilyCompositionErrorAction,
    CancelFamilyCompositionSuccessAction,
    CancelFutureMailingAddressErrorAction,
    CancelFutureMailingAddressSuccessAction,
    CancelResidentialAddressErrorAction,
    CancelResidentialAddressSuccessAction,
    CreateBankDetailsAction,
    CreateBankDetailsErrorAction,
    CreateBankDetailsSuccessAction,
    CreateRelationRecoveryDataAction,
    CreateRelationRecoveryDataErrorAction,
    CreateRelationRecoveryDataSuccessAction,
    CreateResidentialAddressAction,
    CreateResidentialAddressErrorAction,
    CreateResidentialAddressSuccessAction,
    EditAccountCredentialsAction,
    EditAccountCredentialsErrorAction,
    EditAccountCredentialsSuccessAction,
    GetRelationAction,
    ModifyFamilyCompositionAction,
    ModifyFamilyCompositionErrorAction,
    ModifyFamilyCompositionSuccessAction,
    PremiumModificationPurchasedProductsAction,
    PremiumModificationPurchasedProductsErrorAction,
    PremiumModificationPurchasedProductsSuccessAction,
    RelationActionEnum,
    RelationAvailableAction,
    RequestRelationAction,
    RequestRelationError,
    ReviveTerminatedMailingAddressErrorAction,
    ReviveTerminatedMailingAddressSuccessAction,
    SaveOptOutAction,
    SaveOptOutErrorAction,
    SaveOptOutSuccessAction,
    SaveTokenRelationOptOutAction,
    SaveTokenRelationOptOutErrorAction,
    SaveTokenRelationOptOutSuccessAction,
    StoreResidentialAddressAction,
    StoreResidentialAddressErrorAction,
    StoreResidentialAddressSuccessAction,
    TerminateActiveMailingAddressAction,
    TerminateActiveMailingAddressErrorAction,
    TerminateActiveMailingAddressSuccessAction,
    UpdateFamilyCompositionAction,
    UpdateFamilyCompositionErrorAction,
    UpdateFamilyCompositionSuccessAction,
    UpdatePasswordAction,
    UpdatePasswordErrorAction,
    UpdatePasswordSuccessAction,
    UpdatePersonalDetailsAction,
    UpdatePersonalDetailsErrorAction,
    UpdatePrimaryEmailAction,
    UpdatePrimaryEmailErrorAction,
    UpdatePrimaryEmailSuccessAction,
    UpdateRelationAction,
    UpdateRelationErrorAction,
    UpdateRelationSuccessAction,
} from './relation.actions';

@Injectable()
export class RelationEffects {
    public getRelation$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.GetRelation),
            map((action: GetRelationAction) => action.payload.relation),
            map((relation: RelationInterface) =>
                !!relation && !!relation.birthdate
                    ? new RelationAvailableAction({relation: relation})
                    : new RequestRelationAction()
            )
        );
    });

    public requestRelation$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.RequestRelation),
            switchMap(() =>
                this.apiService.getRelation$().pipe(
                    map((relation: RelationInterface) => new RelationAvailableAction({ relation })),
                    catchError(() => of(new RequestRelationError()))
                )
            )
        );
    });

    public requestRelationError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.RequestRelationError),
                tap(() => this.storageService.clear()),
                tap(() => this.legacyStorageService.clear())
            );
        },
        { dispatch: false }
    );

    public relationAvailable$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.RelationAvailable),
                tap((action: RelationAvailableAction) => this.storageService.setRelation(action.payload.relation)),
                map((action: RelationAvailableAction) => this.legacyStorageService.storeLegacyUser(action.payload.relation))
            );
        },
        { dispatch: false }
    );

    public updatePassword$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.UpdatePassword),
            switchMap((action: UpdatePasswordAction) =>
                this.apiService.updatePassword$(action.payload.request).pipe(
                    map(() => new UpdatePasswordSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new UpdatePasswordErrorAction({ errors })))
                )
            )
        );
    });

    public updatePasswordSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdatePasswordSuccess),
                tap(() => this.eventService.onUpdatePasswordSuccess())
            );
        },
        { dispatch: false }
    );

    public updatePasswordError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdatePasswordError),
                map((action: UpdatePasswordErrorAction) =>
                    this.eventService.onUpdatePasswordError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    public updatePrimaryEmailAdress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.UpdatePrimaryEmail),
            switchMap((action: UpdatePrimaryEmailAction) =>
                this.apiService.updatePrimaryEmail$(action.payload).pipe(
                    concatMap((response: string) => [
                        new UpdatePrimaryEmailSuccessAction({ email: response }),
                        new RequestRelationAction(),
                    ]),
                    catchError((errors: ErrorInterface[]) => of(new UpdatePrimaryEmailErrorAction({ errors })))
                )
            )
        );
    });

    public updatePrimaryEmailSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdatePrimaryEmailSuccess),
                tap(() => this.eventService.onUpdatePrimaryEmailSuccess())
            );
        },
        { dispatch: false }
    );

    public updatePrimaryEmailError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdatePrimaryEmailError),
                map((action: UpdatePrimaryEmailErrorAction) => action.payload.errors),
                map((errors: ErrorInterface[]) => this.eventService.onUpdatePrimaryEmailError(errors))
            );
        },
        { dispatch: false }
    );

    public updateRelation$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.UpdateRelation),
            switchMap((action: UpdateRelationAction) =>
                this.apiService.updateRelation$(action.payload.relation).pipe(
                    map((relation: RelationInterface) => new UpdateRelationSuccessAction({ relation })),
                    catchError((errors: ErrorInterface[]) => of(new UpdateRelationErrorAction({ errors })))
                )
            )
        );
    });

    public updateRelationSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdateRelationSuccess),
                tap(() => this.eventService.onUpdateRelationSuccess())
            );
        },
        { dispatch: false }
    );

    public updateRelationError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdateRelationError),
                map((action: UpdateRelationErrorAction) =>
                    this.eventService.onUpdateRelationError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    public addMailingAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.AddMailingAddress),
            switchMap((action: AddMailingAddressAction) =>
                this.apiService.createMailingAddress$(action.payload.request).pipe(
                    concatMap(() => {
                        return [new AddMailingAddressSuccessAction(), new RequestRelationAction()];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new AddMailingAddressErrorAction({ errors })))
                )
            )
        );
    });

    public addMailingAddressSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.AddMailingAddressSuccess),
                tap(() => this.eventService.onCreateMailingAddressSuccess())
            );
        },
        { dispatch: false }
    );

    public addMailingAddressError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.AddMailingAddressError),
                map((action: AddMailingAddressErrorAction) => {
                    return this.eventService.onCreateMailingAddressError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public addDutchMailingAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.AddDutchMailingAddress),
            switchMap((action: AddDutchMailingAddressAction) =>
                this.apiService.createDutchMailingAddress$(action.payload.request).pipe(
                    concatMap(() => {
                        return [new AddDutchMailingAddressSuccessAction(), new RequestRelationAction()];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new AddDutchMailingAddressErrorAction({ errors })))
                )
            )
        );
    });

    public addDutchMailingAddressSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.AddDutchMailingAddressSuccess),
                tap(() => this.eventService.onCreateDutchMailingAddressSuccess())
            );
        },
        { dispatch: false }
    );

    public addDutchMailingAddressError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.AddDutchMailingAddressError),
                map((action: AddDutchMailingAddressErrorAction) => {
                    return this.eventService.onCreateDutchMailingAddressError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public cancelFutureMailingAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.CancelFutureMailingAddress),
            switchMap(() =>
                this.apiService.cancelFutureMailingAddress$().pipe(
                    map(() => new CancelFutureMailingAddressSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new CancelFutureMailingAddressErrorAction({ errors })))
                )
            )
        );
    });

    public cancelFutureMailingAddressSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CancelFutureMailingAddressSuccess),
                tap(() => this.eventService.onCancelFutureMailingAddressSuccess())
            );
        },
        { dispatch: false }
    );

    public cancelFutureMailingAddressError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CancelFutureMailingAddressError),
                map((action: CancelFutureMailingAddressErrorAction) => {
                    return this.eventService.onCancelFutureMailingAddressError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public terminateActiveMailingAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.TerminateActiveMailingAddress),
            switchMap((action: TerminateActiveMailingAddressAction) =>
                this.apiService.terminateActiveMailingAddress$(action.payload.request).pipe(
                    map(() => new TerminateActiveMailingAddressSuccessAction()),
                    catchError((errors: ErrorInterface[]) =>
                        of(new TerminateActiveMailingAddressErrorAction({ errors }))
                    )
                )
            )
        );
    });

    public terminateActiveMailingAddressSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.TerminateActiveMailingAddressSuccess),
                tap(() => this.eventService.onTerminateActiveMailingAddressSuccess())
            );
        },
        { dispatch: false }
    );

    public terminateActiveMailingAddressError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.TerminateActiveMailingAddressError),
                map((action: TerminateActiveMailingAddressErrorAction) => {
                    return this.eventService.onTerminateActiveMailingAddressError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public reviveTerminatedActiveMailingAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.ReviveTerminatedMailingAddress),
            switchMap(() =>
                this.apiService.reviveTerminatedMailingAddress$().pipe(
                    map(() => new ReviveTerminatedMailingAddressSuccessAction()),
                    catchError((errors: ErrorInterface[]) =>
                        of(new ReviveTerminatedMailingAddressErrorAction({ errors }))
                    )
                )
            )
        );
    });

    public reviveTerminatedMailingAddressSuccess$: Observable<Action> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.ReviveTerminatedMailingAddressSuccess),
                tap(() => this.eventService.onReviveTerminatedMailingAddressSuccess())
            );
        },
        { dispatch: false }
    );

    public reviveTerminatedMailingAddressError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.ReviveTerminatedMailingAddressError),
                map((action: ReviveTerminatedMailingAddressErrorAction) => {
                    return this.eventService.onReviveTerminatedMailingAddressError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public updateFamilyComposition$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.UpdateFamilyComposition),
            switchMap((action: UpdateFamilyCompositionAction) => {
                return this.apiService.updateFamilyComposition$(action.payload.request).pipe(
                    map(() => new UpdateFamilyCompositionSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new UpdateFamilyCompositionErrorAction({ errors })))
                );
            })
        );
    });

    public updateFamilyCompositionSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdateFamilyCompositionSuccess),
                tap(() => this.eventService.onUpdateFamilyCompositionSuccess())
            );
        },
        { dispatch: false }
    );

    public updateFamilyCompositionError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdateFamilyCompositionError),
                map((action: UpdateFamilyCompositionErrorAction) => {
                    this.eventService.onUpdateFamilyCompositionError(action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    public updatePersonalDetail$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.UpdatePersonalDetails),
            switchMap((action: UpdatePersonalDetailsAction) => {
                return this.apiService.updatePersonalDetails$(action.payload.request).pipe(
                    map(() => new RequestRelationAction()),
                    tap(() => this.eventService.onUpdatePersonalDetailsSuccess$()),
                    catchError((errors: ErrorInterface[]) => {
                        this.eventService.onUpdatePersonalDetailsError$(errors);
                        return of(new UpdatePersonalDetailsErrorAction({ errors }));
                    })
                );
            })
        );
    });

    public updatePersonalDetailsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdatePersonalDetailsSuccess),
                tap(() => this.eventService.onUpdatePersonalDetailsSuccess$())
            );
        },
        { dispatch: false }
    );

    public updatePersonalDetailsError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.UpdatePersonalDetailsError),
                map((errors: UpdatePersonalDetailsErrorAction) =>
                    this.eventService.onUpdatePersonalDetailsError$(errors.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    public reset$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.Reset),
                tap(() => this.storageService.clear()),
                tap(() => this.legacyStorageService.clear())
            );
        },
        { dispatch: false }
    );

    public saveOptOut$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.SaveOptOut),
            switchMap((action: SaveOptOutAction) =>
                this.apiService.updateOptOut$(mapFromIndication(action.payload.request.optOutIndication)).pipe(
                    map((optOut: boolean) => new SaveOptOutSuccessAction({ optOut })),
                    catchError((errors: ErrorInterface[]) => of(new SaveOptOutErrorAction({ errors })))
                )
            ),
            catchError((errors: ErrorInterface[]) => of(new SaveOptOutErrorAction({ errors })))
        );
    });

    public saveOptOutSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.SaveOptOutSuccess),
                tap(() => this.eventService.onSaveOptOutSuccess())
            );
        },
        { dispatch: false }
    );

    public saveOptOutError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.SaveOptOutError),
                map((action: SaveOptOutErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.onSaveOptOutError(errors))
            );
        },
        { dispatch: false }
    );

    public createBankDetails$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.CreateBankDetails),
            switchMap((action: CreateBankDetailsAction) => {
                return this.apiService.createBankDetails$(action.payload.request).pipe(
                    concatMap(() => {
                        return [new CreateBankDetailsSuccessAction(), new RequestRelationAction()];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new CreateBankDetailsErrorAction({ errors })))
                );
            })
        );
    });

    public createBankDetailsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CreateBankDetailsSuccess),
                tap(() => this.eventService.createBankDetailsSuccess$())
            );
        },
        { dispatch: false }
    );

    public createBankDetailsError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CreateBankDetailsError),
                map((action: CreateBankDetailsErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.createBankDetailsError$(errors))
            );
        },
        { dispatch: false }
    );

    public modifyFamilyComposition$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.ModifyFamilyComposition),
            switchMap((action: ModifyFamilyCompositionAction) => {
                return this.apiService.modifyFamilyComposition$(action.payload.request).pipe(
                    switchMap((response: ResponseInterface) => {
                        return response.errors.some((error) => error.id === '11811-6')
                            ? throwError(response.errors)
                            : of(response);
                    }),
                    concatMap((response: ResponseInterface) => {
                        return [
                            new ModifyFamilyCompositionSuccessAction({ notifications: response.errors }),
                            new RequestRelationAction(),
                        ];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new ModifyFamilyCompositionErrorAction({ errors })))
                );
            })
        );
    });

    public modifyFamilyCompositionSuccess$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.ModifyFamilyCompositionSuccess),
                map((action: ModifyFamilyCompositionSuccessAction) => action.payload.notifications),
                tap((notifications: ErrorInterface[]) =>
                    this.eventService.modifyFamilyCompositionSuccess$(notifications)
                )
            );
        },
        { dispatch: false }
    );

    public modifyFamilyCompositionError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.ModifyFamilyCompositionError),
                map((action: ModifyFamilyCompositionErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.modifyFamilyCompositionError$(errors))
            );
        },
        { dispatch: false }
    );

    public cancelFamilyComposition$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.CancelFamilyComposition),
            switchMap((action: CancelFamilyCompositionAction) => {
                return this.apiService.cancelFamilyComposition$().pipe(
                    concatMap(() => {
                        return [new CancelFamilyCompositionSuccessAction(), new RequestRelationAction()];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new CancelFamilyCompositionErrorAction({ errors })))
                );
            })
        );
    });

    public cancelFamilyCompositionSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CancelFamilyCompositionSuccess),
                tap(() => this.eventService.cancelFamilyCompositionSuccess$())
            );
        },
        { dispatch: false }
    );

    public cancelFamilyCompositionError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CancelFamilyCompositionError),
                map((action: CancelFamilyCompositionErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.cancelFamilyCompositionError$(errors))
            );
        },
        { dispatch: false }
    );

    public premiumModificationPurchasedProducts$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.PremiumModificationPurchasedProducts),
            switchMap((action: PremiumModificationPurchasedProductsAction) => {
                return this.apiService.getPremiumModificationPurchasedProducts$(action.payload.request).pipe(
                    map(
                        (purchasedProducts: ProductEnGbInterface[]) =>
                            new PremiumModificationPurchasedProductsSuccessAction({ purchasedProducts })
                    ),
                    catchError((errors: ErrorInterface[]) =>
                        of(new PremiumModificationPurchasedProductsErrorAction({ errors }))
                    )
                );
            })
        );
    });

    public premiumModificationPurchasedProductsSuccess$: Observable<PremiumModificationPurchasedProductsSuccessAction> =
        createEffect(
            () => {
                return this.action$.pipe(
                    ofType(RelationActionEnum.PremiumModificationPurchasedProductsSuccess),
                    tap((action: PremiumModificationPurchasedProductsSuccessAction) =>
                        this.eventService.premiumModificationPurchasedProductsSuccess$(action.payload.purchasedProducts)
                    )
                );
            },
            { dispatch: false }
        );

    public premiumModificationPurchasedProductsError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.PremiumModificationPurchasedProductsError),
                map((action: PremiumModificationPurchasedProductsErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.premiumModificationPurchasedProductsError$(errors))
            );
        },
        { dispatch: false }
    );

    public editAccountCredentials$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.EditAccountCredentials),
            switchMap((action: EditAccountCredentialsAction) => {
                return this.apiService.editAccountCredentials$(action.payload.request).pipe(
                    map(() => new EditAccountCredentialsSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new EditAccountCredentialsErrorAction({ errors })))
                );
            })
        );
    });

    public editAccountCredentialsSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.EditAccountCredentialsSuccess),
                tap(() => this.eventService.editAccountCredentialsSuccess$())
            );
        },
        { dispatch: false }
    );

    public editAccountCredentialsError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.EditAccountCredentialsError),
                map((action: EditAccountCredentialsErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.editAccountCredentialsError$(errors))
            );
        },
        { dispatch: false }
    );

    public createResidentialAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.CreateResidentialAddress),
            switchMap((action: CreateResidentialAddressAction) => {
                return this.apiService.createResidentialAddress$(action.payload.request).pipe(
                    concatMap((response: CreateResidentialAddressResponseInterface) => {
                        return [
                            new CreateResidentialAddressSuccessAction({response}),
                            new RequestRelationAction(),
                        ];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new CreateResidentialAddressErrorAction({errors})))
                );
            })
        );
    });

    public createResidentialAddressSuccess$: Observable<CreateResidentialAddressSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CreateResidentialAddressSuccess),
                tap((action) => this.eventService.createResidentialAddressSuccess$(action)),
                tap(() => this.productActionService.clear())
            );
        },
        { dispatch: false }
    );

    public createResidentialAddressError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CreateResidentialAddressError),
                map((action: CreateResidentialAddressErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.createResidentialAddressError$(errors))
            );
        },
        { dispatch: false }
    );

    public cancelResidentialAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.CancelResidentialAddress),
            switchMap(() => {
                return this.apiService.cancelResidentialAddress$().pipe(
                    concatMap(() => {
                        return [new CancelResidentialAddressSuccessAction(), new RequestRelationAction()];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new CancelResidentialAddressErrorAction({ errors })))
                );
            })
        );
    });

    public cancelResidentialAddressSuccess$: Observable<CancelResidentialAddressSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CancelResidentialAddressSuccess),
                tap(() => this.eventService.cancelResidentialAddressSuccess$())
            );
        },
        { dispatch: false }
    );

    public cancelResidentialAddressError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CancelResidentialAddressError),
                map((action: CancelResidentialAddressErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.cancelResidentialAddressError$(errors))
            );
        },
        { dispatch: false }
    );

    public storeResidentialAddress$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.StoreResidentialAddress),
            switchMap((action: StoreResidentialAddressAction) => {
                return this.apiService.storeResidentialAddress$(action.payload.request).pipe(
                    concatMap(() => {
                        return [new StoreResidentialAddressSuccessAction(), new RequestRelationAction()];
                    }),
                    catchError((errors: ErrorInterface[]) => of(new StoreResidentialAddressErrorAction({ errors })))
                );
            })
        );
    });

    public storeResidentialAddressSuccess$: Observable<StoreResidentialAddressSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.StoreResidentialAddressSuccess),
                tap(() => this.eventService.storeResidentialAddressSuccess$())
            );
        },
        { dispatch: false }
    );

    public storeResidentialAddressError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.StoreResidentialAddressError),
                map((action: StoreResidentialAddressErrorAction) => action.payload.errors),
                tap((errors: ErrorInterface[]) => this.eventService.storeResidentialAddressError$(errors))
            );
        },
        { dispatch: false }
    );

    public saveTokenRelationOptOut$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.SaveTokenRelationOptOut),
            switchMap((action: SaveTokenRelationOptOutAction) =>
                this.apiService.tokenRelationOptOut$(action.payload.request).pipe(
                    map(() => new SaveTokenRelationOptOutSuccessAction()),
                    catchError((errors: ErrorInterface[]) => of(new SaveTokenRelationOptOutErrorAction({ errors })))
                )
            )
        );
    });

    public saveTokenRelationOptOutSuccess$: Observable<SaveTokenRelationOptOutSuccessAction> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.SaveTokenRelationOptOutSuccess),
                tap(() => this.eventService.saveTokenRelationOptOutSuccess$())
            );
        },
        { dispatch: false }
    );

    public saveTokenRelationOptOutError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.SaveTokenRelationOptOutError),
                map((errors: SaveTokenRelationOptOutErrorAction) =>
                    this.eventService.saveTokenRelationOptOutError$(errors.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    public createRelationRecoveryData$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(RelationActionEnum.CreateRelationRecoveryData),
            switchMap((action: CreateRelationRecoveryDataAction) => {
                return this.apiService.createRelationRecoveryData$(action.payload).pipe(
                    map((response) => new CreateRelationRecoveryDataSuccessAction(response)),
                    catchError((errors: ErrorInterface[]) => of(new CreateRelationRecoveryDataErrorAction({ errors })))
                );
            })
        );
    });

    public createRelationRecoveryDataSuccess$: Observable<string> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CreateRelationRecoveryDataSuccess),
                tap((response: string) => this.eventService.createRelationRecoveryDataSuccess$(response))
            );
        },
        { dispatch: false }
    );

    public createRelationRecoveryDataError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(RelationActionEnum.CreateRelationRecoveryDataError),
                map((errors: SaveTokenRelationOptOutErrorAction) =>
                    this.eventService.createRelationRecoveryDataError$(errors.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private apiService: RelationApiService,
        private eventService: RelationEventService,
        private legacyStorageService: LegacyRelationStorageService,
        private storageService: RelationStorageService,
        private productActionService: ProductActionService,
    ) {}
}
