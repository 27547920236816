import { mapSequenceNumber } from '../../core';
import { ApiSaveClaimObjectRequestInterface, SaveClaimObjectRequestInterface } from '../interfaces';

export function mapSaveClaimObjectRequest(
    request: SaveClaimObjectRequestInterface
): ApiSaveClaimObjectRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        claim_object: {
            claim_object_id: request.claimObjectId,
            specification: request.claimObjectElements.map((element) => ({
                element_name: element.elementName,
                element_content: element.elementContent,
            })),
        },
    };
}
