/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import { isPlatformServer } from '@angular/common';
import {
    AfterContentChecked,
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';
import { CarouselBase } from './carousel.base';

@Directive({
    selector: '[buttonNext]',
})
export class ButtonNextDirective {
    @Input() public type: string;
}

@Directive({
    selector: '[buttonPrev]',
})
export class ButtonPreviousDirective {
    @Input() public type: string;
}

@Component({
    selector: 'out-carousel',
    templateUrl: 'carousel.component.html',
    styleUrls: ['carousel.component.scss'],
})
export class CarouselComponent
    extends CarouselBase
    implements AfterContentInit, AfterContentChecked, OnChanges, OnDestroy, OnInit
{
    @Input() public showSlide: { id: number } = { id: 0 };
    @Input() public showControls = true;
    @Input() public showNavigation = true;
    @Input() public timing = '500ms ease';
    @Input() public snapRight = true;
    @Input() public autoPlay = false;
    @Input() public isMobile = false;
    @Input() public autoPlayInterval = 5000;
    @Output() public slideChanged: EventEmitter<number> = new EventEmitter();
    public active: number = 0;
    public navigation: { active: boolean; id: number }[] = [];
    @ViewChild('carousel', { static: true }) private carousel: ElementRef | null = null;
    private player: AnimationPlayer | null = null;
    private resizeTimer: number;
    private children: HTMLCollection | null = null;

    /** to store the projected content, so we can prevent unneccesary validation calls */
    private projectedItemContent: string = '';

    private subscription: Subscription = new Subscription();
    private start$: Subject<void> = new Subject<void>();

    constructor(
        private builder: AnimationBuilder,
        @Inject(PLATFORM_ID) private platformId: any,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit(): void {
        this.buildTimer();
    }

    public ngAfterContentInit(): void {
        if (this.carousel) {
            this.projectedItemContent = this.carousel.nativeElement.innerHTML;
            this.children = this.carousel.nativeElement.children;

            this.buildNavigation();
        }
    }

    public ngAfterContentChecked(): void {
        if (this.carousel) {
            const c = this.carousel.nativeElement.innerHTML;

            /** only validate if the content has changed */
            if (c !== this.projectedItemContent) {
                this.projectedItemContent = c;
                this.buildNavigation();
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('showSlide') && !changes.showSlide.firstChange) {
            const currentValue = changes.showSlide.currentValue as { id: number };
            this.show(currentValue.id);
        }
    }

    @HostListener('window:resize') public onResize(): void {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(() => {
            this.resetTimer();
            this.show(this.active);
        }, 250) as any as number;
    }

    /** UI Events */
    public handleClick(index: number): boolean {
        this.show(index);
        this.resetTimer();

        return false;
    }

    public swipe(event: Event): void {
        switch (event.type) {
            case 'swipeleft':
                this.next();
                break;
            case 'swiperight':
                this.prev();
                break;
        }
    }

    public next(): boolean {
        if (!this.children) {
            return false;
        }

        const next = this.active + 1;
        const total = this.children.length - 1;
        this.start$.next();

        if (next > total) {
            this.show(0);
            return false;
        }

        this.show(next);

        return false;
    }

    public prev(): boolean {
        if (!this.children) {
            return false;
        }

        const prev = this.active - 1;
        this.start$.next();

        if (prev < 0) {
            this.show(this.children.length - 1);
            return false;
        }

        this.show(prev);

        return false;
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /** Navigation */
    private buildNavigation(): void {
        this.navigation = [];

        if (this.children) {
            for (let i = 0; i < this.children.length; i++) {
                this.navigation.push({
                    active: i === 0,
                    id: i,
                });
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    private buildTimer(): void {
        if (!this.autoPlay || isPlatformServer(this.platformId)) {
            return;
        }

        const timer$ = timer(this.autoPlayInterval, this.autoPlayInterval);
        const resetMap$ = this.start$.pipe(switchMapTo(timer$)).subscribe(() => this.onTime());

        this.subscription.add(resetMap$);
        this.start$.next();
    }

    private resetTimer(): void {
        if (!this.autoPlay) {
            return;
        }

        this.start$.next();
    }

    private onTime(): void {
        if (this.children) {
            if (this.active >= this.children.length - 1) {
                this.active = -1;
            }
            this.next();
        }
    }

    /** Animations */
    private animateOffset(offset: string): void {
        const myAnimation: AnimationFactory = this.buildAnimation(offset);

        if (this.carousel) {
            this.player = myAnimation.create(this.carousel.nativeElement);
            this.player.play();
        }
    }

    private buildAnimation(offset: string): AnimationFactory {
        return this.builder.build([animate(this.timing, style({ transform: `translateX(${offset}px)` }))]);
    }

    /** Show Slide */
    private show(index: number): boolean {
        if (!this.carousel || !this.children) {
            return false;
        }

        const children = this.children;
        const offsetWidth = this.carousel.nativeElement.offsetWidth;

        if (this.snapRight && this.allVisibleInView(offsetWidth, children)) {
            return this.showLeftToRight(index);
        } else if (this.snapRight) {
            return this.showSnapRight(index);
        }
        return this.showDefault(index);
    }

    /** Show Default Slide Animation */
    private showDefault(index: number): boolean {
        if (this.active === index) {
            return false;
        }

        if (this.children) {
            this.animateOffset(`-${this.calculateDefaultOffset(index, this.children)}`);
            this.setActive(index);
            return false;
        }

        return false;
    }

    /** Show Snap Right Slide Animation */
    private showSnapRight(index: number): boolean {
        if (!this.carousel || !this.children) {
            return false;
        }

        const children = this.children;
        const offsetWidth = this.carousel.nativeElement.offsetWidth;

        if (this.active === index) {
            if (this.isFirstItem(index)) {
                this.animateOffset('0');
                this.setActive(index);
                return false;
            }

            if (this.isLastItem(index, children)) {
                this.animateOffset(`-${this.calculateLastChildOffsetRight(children, offsetWidth)}`);
                this.setActive(index);
                return false;
            }
        }

        if (this.isLastItem(index, children) || this.isNextItemLastToShow(index, children, offsetWidth)) {
            if (this.isNextItemLastToShow(index, children, offsetWidth)) {
                if (this.active < index) {
                    this.animateOffset(`-${this.calculateLastChildOffsetRight(children, offsetWidth)}`);
                    this.setActive(children.length - 1);
                    return false;
                } else {
                    const nextIndex = this.predictPreviousPossibleIndex(children, offsetWidth);
                    this.animateOffset(`-${this.calculateDefaultOffset(nextIndex, children)}`);
                    this.setActive(nextIndex);
                    return false;
                }
            } else {
                this.animateOffset(`-${this.calculateLastChildOffsetRight(children, offsetWidth)}`);
                this.setActive(index);
                return false;
            }
        }

        this.animateOffset(`-${this.calculateDefaultOffset(index, children)}`);
        this.setActive(index);
        return false;
    }

    /** Show Left To Right Slide Animation */
    private showLeftToRight(index: number): boolean {
        if (!this.carousel || !this.children) {
            return false;
        }

        const children = this.children;
        const offsetWidth = this.carousel.nativeElement.offsetWidth;

        if (this.active === index) {
            if (this.isFirstItem(index)) {
                this.animateOffset('0');
                this.setActive(index);
                return false;
            }

            if (this.isLastItem(index, children)) {
                this.animateOffset(`${this.calculateLastChildOffsetToRight(children, offsetWidth)}`);
                this.setActive(index);
                return false;
            }
        }

        if (this.isCenterItem(index, children)) {
            if (index > this.active) {
                this.animateOffset(`${this.calculateLastChildOffsetToRight(children, offsetWidth)}`);
                this.setActive(children.length - 1);
            } else {
                this.animateOffset('0');
                this.setActive(0);
            }
            return false;
        }

        if (this.isFirstItem(index)) {
            this.animateOffset('0');
            this.setActive(0);
            return false;
        }

        if (this.isLastItem(index, children)) {
            this.animateOffset(`${this.calculateLastChildOffsetToRight(children, offsetWidth)}`);
            this.setActive(children.length - 1);
            return false;
        }

        this.animateOffset(`-${this.calculateDefaultOffset(index, children)}`);
        this.setActive(index);
        return false;
    }

    /** State Updates */
    private setActive(index: number): void {
        this.updateNavigation(index);
        this.slideChanged.emit(index);
        this.active = index;
    }

    private updateNavigation(index: number): void {
        this.navigation.forEach((item) => {
            item.active = item.id === index;
        });

        this.changeDetectorRef.detectChanges();
    }
}
