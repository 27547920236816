import {
    mapFactorId,
    mapFormatCode,
    mapFromSuccessIndication,
    mapModifiableCode,
    mapSequenceNumber,
} from '../../../core';
import {
    ApiProductModifiablePremiumDeterminingFactorResponse,
    ApiProductPremiumDeterminingFactorResponse,
    ModifiableFactorResponseInterface,
    ProductPremiumDeterminingFactorResponseInterface,
} from '../../interfaces';
import { mapValidityStatusCodeResponse } from './validity-status-code.mapper';

function mapModifiableFactor(
    response: ApiProductModifiablePremiumDeterminingFactorResponse
): ModifiableFactorResponseInterface {
    if (!response) {
        return null;
    }

    return {
        endDate: response.end_date,
        modifiableFactorSequenceNumber: mapSequenceNumber(
            response.modifiable_premium_determining_factor_sequence_number
        ),
        factorDate: response.premium_determining_factor_date,
        factorDescription: response.premium_determining_factor_description,
        factorId: mapFactorId(response.premium_determining_factor_id),
        factorIndication: mapFromSuccessIndication(response.premium_determining_factor_indication),
        factorIndicationDescription: response.premium_determining_factor_indication_description,
        factorNumber: response.premium_determining_factor_number,
        factorValueDescription: response.premium_determining_factor_value_description,
        startDate: response.premium_determining_factor_date,
        validityStatusCode: mapValidityStatusCodeResponse(response.validity_status_code),
    };
}

export function mapProductPremiumDeterminingFactorResponse(
    response: ApiProductPremiumDeterminingFactorResponse[]
): ProductPremiumDeterminingFactorResponseInterface[] | null {
    if (!response) {
        return null;
    }

    return response.map((factor) => ({
        elementName: factor.element_name,
        formatCode: mapFormatCode(factor.format_code),
        formatDescription: factor.format_description,
        modifiableCode: mapModifiableCode(factor.modifiable_by_code),
        modifiableByDescription: factor.modifiable_by_description,
        modifiableFactor: mapModifiableFactor(factor.modifiable_premium_determining_factor),
        modifiableFactorFuture: mapModifiableFactor(factor.modifiable_premium_determining_factor_future),
        factorId: mapFactorId(factor.premium_determining_factor_id),
        factorDescription: factor.premium_determining_factor_description,
    }));
}


