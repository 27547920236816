import { createReducer, on } from '@ngrx/store';
import * as ClaimsAppointmentActions from './claim-appointment.actions';
import { ClaimAppointmentState, INITIAL_CLAIM_APPOINTMENT_STATE } from './claim-appointment.state';

export const claimAppointmentReducer = createReducer(
    INITIAL_CLAIM_APPOINTMENT_STATE,
    on(
        ClaimsAppointmentActions.getAppointmentsSuccess,
        (state, { response }): ClaimAppointmentState => ({
            ...state,
            appointments: response.appointments,
        })
    ),
    on(
        ClaimsAppointmentActions.saveAppointmentSuccess,
        (state, { response }): ClaimAppointmentState => ({
            ...state,
            appointments: response.appointments,
        })
    ),
    on(
        ClaimsAppointmentActions.getLocationsSuccess,
        (state, { response }): ClaimAppointmentState => ({
            ...state,
            locations: response.locations,
        })
    ),
    on(
        ClaimsAppointmentActions.getTimeslotsSuccess,
        (state, { response }): ClaimAppointmentState => ({
            ...state,
            timeslots: response.timeslots,
        })
    ),
    on(ClaimsAppointmentActions.resetClaimAppointment, (): ClaimAppointmentState => INITIAL_CLAIM_APPOINTMENT_STATE)
);
