import { ApiMandatoryOptionalCodeEnum, MandatoryOptionalCodeEnum } from '../enums';

export function mapMandatoryCode(response: ApiMandatoryOptionalCodeEnum): MandatoryOptionalCodeEnum {
    switch (response) {
    case ApiMandatoryOptionalCodeEnum.Mandatory:
        return MandatoryOptionalCodeEnum.Mandatory;
    default:
        return MandatoryOptionalCodeEnum.Optional;
    }
}
