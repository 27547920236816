import { PartiesInterface } from '../interfaces';

export interface ClaimParties {
    claimParties: ClaimPartiesState;
}

export interface ClaimPartiesState {
    parties: PartiesInterface;
}

export const INITIAL_CLAIM_PARTIES_STATE: ClaimPartiesState = {
    parties: null,
};

export const CLAIM_PARTIES_FEATURE = 'claimParties';
