import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { LoadProductScenarioResponseInterface } from '../interfaces/load-product-scenario-response.interface';

export const loadProductScenarios = createAction('[Product Scenarios] Load Product Scenarios');
export const loadProductScenariosSuccess = createAction(
    '[Product Scenarios] Load Product Scenarios Success',
    props<{ scenarios: LoadProductScenarioResponseInterface[] }>()
);
export const loadProductScenariosError = createAction(
    '[Product Scenarios] Load Product Scenarios Error',
    props<{ errors: ErrorInterface[] }>()
);
export const clearProductScenarios = createAction('[Product Scenarios] Clear Product Scenarios');
