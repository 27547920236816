import { mapEnumApiRequest, mapEnumApiResponse } from '../../core';
import { ClaimCategoryEnum } from '../enums';
import { ApiClaimCategoryEnum } from '../enums/api.claim-category.enum';

export function mapClaimCategoryIdResponse(response: ApiClaimCategoryEnum): ClaimCategoryEnum {
    return mapEnumApiResponse(response ? response.trim() : null, ApiClaimCategoryEnum, ClaimCategoryEnum);
}

export function mapClaimCategoryIdRequest(request: ClaimCategoryEnum): ApiClaimCategoryEnum {
    return mapEnumApiRequest(request, ApiClaimCategoryEnum, ClaimCategoryEnum);
}
