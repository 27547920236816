import {
    ApiTerminationLetterRequestInterface,
    ApiTerminationLetterResponseInterface,
    DownloadTerminationLetterResponseInterface,
    TerminationLetterRequestInterface,
} from '../interfaces';

export function mapTerminationLetterRequest(
    request: TerminationLetterRequestInterface
): ApiTerminationLetterRequestInterface {
    return {
        relation: {
            initials: request.relation.initials,
            middle_name_initials: request.relation.middleNameInitials,
            surname: request.relation.surname,
            email_address: request.relation.emailAddress,
        },
        address: {
            civic_number_suffix: request.address.civicNumberSuffix,
            postal_code: request.address.postalCode,
            civic_number: request.address.civicNumber,
        },
        letter_of_termination: {
            product_id: request.letterOfTermination.productId,
            insurance_company_id: request.letterOfTermination.insuranceCompanyId,
            policy_number: request.letterOfTermination.policyNumber,
            end_date: request.letterOfTermination.endDate,
        },
    };
}

export function mapTerminationLetterResponse(
    response: ApiTerminationLetterResponseInterface
): DownloadTerminationLetterResponseInterface {
    return {
        type: response.type,
        pdf: response.pdf,
        identifyingName: response.identifying_name,
    };
}
