import {
    CalculatePremiumAutoPayload,
    CalculatePremiumBicyclePayload,
    CalculatePremiumHomePayload,
    Coverage,
    Customer,
    EmailPremiumAutoPayload,
    EmailPremiumBicyclePayload,
    EmailPremiumHomePayload,
    Insurance,
    Payload,
} from '../../interfaces';

export class CalculationPayload {
    public static calculatePremiumAuto(insurance: Insurance, customer: Customer): Payload<CalculatePremiumAutoPayload> {
        const payload: CalculatePremiumAutoPayload = {
            relation: {
                general: {
                    birthdate: customer.birthdate,
                },
                residential_address: {
                    postal_code: customer.postal_code,
                },
            },
            non_regular_driver_transfer_indication: null,
            non_regular_driver: null,
            premium_factor: {
                // start_date: insurance.start_date, // staat uit om issues met start datum in het verleden te voorkomen
                mileage_auto: insurance.mileage_auto,
                no_claims_years_amount: insurance.no_claims_years_amount,
                family_composition_code: customer.family_composition_code,
                campaign_code: insurance.campaign_code,
                partner_id: insurance.partner_id,
                partner_component_code: insurance.partner_component_code,
                external_reference_id: insurance.external_reference_id,
            },
            object: {
                license_plate: insurance.license_plate || undefined,
                style_trim_id: insurance.style_trim_id,
                build_year: insurance.build_year || undefined,
                build_month: insurance.build_month || undefined,
            },
            related_vehicle: null,
        };

        if (insurance.non_regular_driver_transfer_indication) {
            payload.non_regular_driver_transfer_indication = insurance.non_regular_driver_transfer_indication;
        }

        if (insurance.non_regular_driver_transfer_indication !== 'J' && insurance.non_regular_driver) {
            payload.non_regular_driver = {
                relation_code: insurance.relation_code,
                birthdate: insurance.non_regular_driver.birthdate,
            };
        }

        if (insurance.related_vehicle_purchased_product_sequence_number) {
            payload.related_vehicle = {
                related_vehicle_purchased_product_sequence_number:
                    insurance.related_vehicle_purchased_product_sequence_number,
            };
            payload.premium_factor.no_claims_years_amount = null;
        }

        return {
            url: 'en-gb/insurance-coverage/calculate/coverage-premium-auto',
            payload,
        };
    }

    public static emailPremiumAuto(
        coverages: Coverage[],
        insurance: Insurance,
        customer: Customer,
        emailAddress: string
    ): Payload<EmailPremiumAutoPayload> {
        const { payload } = this.calculatePremiumAuto(insurance, customer);

        const newPayload: EmailPremiumAutoPayload = {
            ...payload,
            email_address: emailAddress,
            coverages: coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })),
        };

        return {
            url: 'en-gb/insurance-coverage/provide/email-premium-auto',
            payload: newPayload,
        };
    }

    public static calculatePremiumBicycle(
        insurance: Insurance,
        customer: Customer
    ): Payload<CalculatePremiumBicyclePayload> {
        const payload: CalculatePremiumBicyclePayload = {
            relation: {
                general: {
                    birthdate: customer.birthdate,
                },
                residential_address: {
                    postal_code: customer.postal_code,
                },
            },
            non_regular_driver: null,
            premium_factor: {
                // start_date: insurance.start_date, // staat uit om issues met start datum in het verleden te voorkomen
                campaign_code: insurance.campaign_code,
                family_composition_code: customer.family_composition_code,
                partner_id: insurance.partner_id,
                partner_component_code: insurance.partner_component_code,
                external_reference_id: insurance.external_reference_id,
            },
            object: {
                bicycle_brand: insurance.bicycle_brand,
                bicycle_model: insurance.bicycle_model,
                purchase_date: insurance.purchase_date,
                purchase_value_amount: insurance.purchase_value_amount,
            },
        };

        if (insurance.non_regular_driver) {
            payload.non_regular_driver = {
                relation_code: insurance.relation_code,
                birthdate: insurance.non_regular_driver.birthdate,
                postal_code: null,
            };
        }

        return {
            url: 'en-gb/insurance-coverage/calculate/coverage-premium-bicycle',
            payload,
        };
    }

    public static emailPremiumBicycle(
        coverages: Coverage[],
        insurance: Insurance,
        customer: Customer,
        emailAddress: string
    ): Payload<EmailPremiumBicyclePayload> {
        const { payload } = this.calculatePremiumBicycle(insurance, customer);

        const newPayload: EmailPremiumBicyclePayload = {
            ...payload,
            email_address: emailAddress,
            coverages: coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })),
        };

        return {
            url: 'en-gb/insurance-coverage/provide/email-premium-bicycle',
            payload: newPayload,
        };
    }

    public static calculatePremiumHome(
        insurance: Insurance,
        customer: Customer,
        coverages: Coverage[]
    ): Payload<CalculatePremiumHomePayload> {
        const payload: CalculatePremiumHomePayload = {
            relation: {
                general: {
                    birthdate: customer.birthdate,
                },
            },
            premium_factor: {
                family_composition_code: customer.family_composition_code,
                tenant_owner_code: insurance.tenant_owner_code,
                property_type: insurance.property_type,
                security_type_code: insurance.security_type_code,
                campaign_code: insurance.campaign_code,
                partner_id: insurance.partner_id,
                partner_component_code: insurance.partner_component_code,
                build_year:
                    insurance.address && insurance.address.buildYear
                        ? insurance.address.buildYear
                        : insurance.build_year,
                external_reference_id: insurance.external_reference_id,
                choice_deductible_excess_amount: insurance.choice_deductible_excess_amount || '150',
                straw_roofing_indication: insurance.straw_roofing_indication,
            },
            object: {
                postal_code: insurance.address?.postalCode.replace(/\s+/g, ''),
                civic_number: insurance.address?.houseNumber,
                civic_number_suffix: insurance.address?.houseNumberAddition,
            },
            coverages: coverages ? coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })) : null,
        };

        return {
            url: 'en-gb/insurance-coverage/calculate/coverage-premium-home',
            payload,
        };
    }

    public static emailPremiumHome(
        coverages: Coverage[],
        insurance: Insurance,
        customer: Customer,
        emailAddress: string
    ): Payload<EmailPremiumHomePayload> {
        const { payload } = this.calculatePremiumHome(insurance, customer, coverages);

        const newPayload: EmailPremiumHomePayload = {
            ...payload,
            email_address: emailAddress,
            coverages: coverages.map((coverage) => ({ coverage_id: coverage.coverage_id })),
        };

        return {
            url: 'en-gb/insurance-coverage/provide/email-premium-home',
            payload: newPayload,
        };
    }
}
