import { NotificationInterface } from '../../../core';
import { Customer } from '../../interfaces';
import * as addressActions from '../address/address.actions';
import * as calculationActions from '../calculation/calculation.actions';
import * as insuranceActions from '../insurance/insurance.actions';
import * as progressActions from '../progress/progress.actions';
import * as customerActions from './customer.actions';

export interface State {
    data: Customer;
    returningCustomer: boolean | null;
    errors: NotificationInterface[];
}

const initialState: State = {
    data: null,
    returningCustomer: null,
    errors: [],
};

export function reducer(
    state: State = initialState,
    action:
        | customerActions.CustomerActionUnion
        | insuranceActions.InsuranceActionUnion
        | calculationActions.CalculationActionUnion
        | addressActions.AddressActionUnion
        | progressActions.ProgressActionsUnion
): State {
    switch (action.type) {
    case progressActions.INIT_SUCCESS: {
        const payload = action.payload;

        return {
            ...state,
            returningCustomer: payload.isReturningCustomer,
            data: {
                ...state.data,
                ...payload.customer,
            },
        };
    }

    case calculationActions.CALCULATE_PREMIUM_SUCCESS:
    case calculationActions.CALCULATE_SUCCESS:
    case insuranceActions.VALIDATE_ELIGIBILITY_SUCCESS:
    case insuranceActions.PREFILL_CAR_INSURANCE_DATA: {
        return {
            ...state,
            data: {
                ...state.data,
                ...action.payload.customer,
            },
        };
    }

    case addressActions.GET_ADDRESS_DATA:
    case addressActions.GET_ADDRESS_DATA_ERROR: {
        return {
            ...state,
            data: {
                ...state.data,
                address: null,
            },
        };
    }

    case addressActions.GET_ADDRESS_DATA_SUCCESS: {
        return {
            ...state,
            data: {
                ...state.data,
                address: action.payload.result,
            },
        };
    }

    case customerActions.NEW_CUSTOMER: {
        return {
            ...state,
            returningCustomer: false,
        };
    }

    case customerActions.RETURNING_CUSTOMER: {
        return {
            ...state,
            returningCustomer: true,
        };
    }

    case calculationActions.PREMIUM_UPDATED_SUCCESS:
    case calculationActions.UPDATE_SUCCESS: {
        return {
            ...state,
            data: {
                ...state.data,
                ...action.payload.customer,
            },
            errors: [],
        };
    }

    case customerActions.STORE_CUSTOMER_DATA: {
        return {
            ...state,
            data: {
                ...state.data,
                ...action.payload.customer,
            },
        };
    }

    case insuranceActions.CLEAR_INSURANCE_DATA: {
        return {
            ...initialState,
        };
    }

    case insuranceActions.INSURE_SUCCESS:
    case insuranceActions.REQUEST_INSURANCE_SUCCESS: {
        return {
            ...state,
            data: {
                ...state.data,
                ...action.payload.relation,
                ...action.payload.token,
            },
        };
    }

    default: {
        return {
            ...state,
        };
    }
    }
}

export const getData = (state: State): State['data'] => state.data;
export const getErrors = (state: State): State['errors'] => state.errors;
export const isReturningCustomer = (state: State): State['returningCustomer'] => state.returningCustomer;
