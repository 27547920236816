export const deduceContext = (element: string): string | undefined => {
    switch (element) {
        // AUTO
        case 'license_plate':
        case 'style_trim_id':
        case 'vin_last_four_digits_code':
        case 'licenseplate_holder_code':
        // FIET
        case 'bicycle_brand':
        case 'bicycle_model':
        case 'purchase_value_amount':
        case 'purchase_date':
        case 'bicycle_key_number':
        case 'framenumber':
        case 'new_indication': {
            return 'object';
        }
        case 'gender_code':
        case 'initials':
        case 'middle_name_initials':
        case 'surname':
        case 'birthdate': {
            return 'general';
        }
        case 'postal_code':
        case 'civic_number':
        case 'civic_number_suffix': {
            return 'residential_address';
        }
        case 'family_composition_code':
        case 'no_claims_years_amount':
        case 'mileage_auto':
        case 'start_date':
        case 'partner_id':
        case 'partner_component_code':
        case 'external_reference_id':
        // WOON
        case 'straw_roofing_indication':
        case 'property_type':
        case 'tenant_owner_code':
        case 'choice_deductible_excess_amount':
        case 'security_type_code': {
            return 'premium_factor';
        }
        case 'switch_service_indication': {
            return 'indications';
        }
        case 'in_verleden_aantal_gemelde_schades_code': {
            return 'codes';
        }
        case 'telephone_number': {
            return 'landline_or_mobile_telephone_number';
        }
        case 'email_address': {
            return 'primary_email_address';
        }
        case 'password': {
            return 'relation_login';
        }
        case 'account_holder_name':
        case 'iban_bank_account_number':
        case 'direct_debit_agreement_indication': {
            return 'bank_mandate';
        }
        case 'relation_code': {
            return 'non_regular_driver';
        }
        case 'related_vehicle_purchased_product_sequence_number': {
            return 'related_vehicle';
        }
    }
    return;
};
