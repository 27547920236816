import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimSpecificationState, CLAIM_SPECIFICATION_FEATURE } from './claim-specification.state';

export class ClaimSpecificationSelectors {
    /** Feature state selector */
    private static getClaimSpecificationState =
        createFeatureSelector<ClaimSpecificationState>(CLAIM_SPECIFICATION_FEATURE);

    public static getState = createSelector(
        ClaimSpecificationSelectors.getClaimSpecificationState,
        (state: ClaimSpecificationState) => state
    );

    public static getSpecification = createSelector(
        ClaimSpecificationSelectors.getClaimSpecificationState,
        (state: ClaimSpecificationState) => state.specification
    );
}
