import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';
import { InternationalMotorInsuranceCardState } from '../store';
import {
    LoadEmailInternationalMotorInsuranceCardAction,
    LoadEmailNotInsuredInternationalMotorInsuranceCardAction,
    LoadPdfInternationalMotorInsuranceCardAction,
    LoadPdfNotInsuredInternationalMotorInsuranceCardAction,
    ResetInternationalMotorInsuranceCardAction,
} from '../store/international-motor-insurance-card.actions';
import { InternationalMotorInsuranceCardEventService } from './international-motor-insurance-card-event.service';

@Injectable({
    providedIn: 'root',
})
export class InternationalMotorInsuranceCardActionService {
    constructor(
        private store$: Store<InternationalMotorInsuranceCardState>,
        private internationalMotorInsuranceCardEventService: InternationalMotorInsuranceCardEventService
    ) {}

    public loadEmailInternationalMotorInsuranceCard$(productSequenceNumber: number): Observable<string> {
        this.store$.dispatch(new LoadEmailInternationalMotorInsuranceCardAction({ productSequenceNumber }));
        return this.internationalMotorInsuranceCardEventService.loadEmailInternationalMotorInsuranceCard$();
    }

    public loadEmailNotInsuredInternationalMotorInsuranceCard$(
        productSequenceNumber: number,
        caravanBrand: string,
        licensePlate: string
    ): Observable<string> {
        this.store$.dispatch(
            new LoadEmailNotInsuredInternationalMotorInsuranceCardAction({
                productSequenceNumber,
                caravanBrand,
                licensePlate,
            })
        );
        return this.internationalMotorInsuranceCardEventService.loadEmailNotInsuredInternationalMotorInsuranceCard$();
    }

    public loadPdfInternationalMotorInsuranceCard$(
        productSequenceNumber: number
    ): Observable<PdfInternationalMotorInsuranceCardInterface> {
        this.store$.dispatch(new LoadPdfInternationalMotorInsuranceCardAction({ productSequenceNumber }));
        return this.internationalMotorInsuranceCardEventService.loadPdfInternationalMotorInsuranceCard$();
    }

    public loadPdfNotInsuredInternationalMotorInsuranceCard$(
        productSequenceNumber: number,
        caravanBrand: string,
        licensePlate: string
    ): Observable<PdfInternationalMotorInsuranceCardInterface> {
        this.store$.dispatch(
            new LoadPdfNotInsuredInternationalMotorInsuranceCardAction({
                productSequenceNumber,
                caravanBrand,
                licensePlate,
            })
        );
        return this.internationalMotorInsuranceCardEventService.loadPdfNotInsuredInternationalMotorInsuranceCard$();
    }

    public reset(): void {
        this.store$.dispatch(new ResetInternationalMotorInsuranceCardAction());
    }
}
