import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ClaimCategoriesApiService } from '../services/claim-categories.api.service';
import { ClaimCategoriesEventService } from '../services/claim-categories.event.service';
import * as ClaimCategoriesActions from './claim-categories.actions';

@Injectable()
export class ClaimCategoriesEffects {
    public allClaimCategories$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimCategoriesActions.allClaimCategories),
            exhaustMap(() =>
                this.claimCategoriesApiService.allClaimCategories$().pipe(
                    map((response) => ClaimCategoriesActions.allClaimCategoriesSuccess({ response })),
                    catchError((errors) => of(ClaimCategoriesActions.allClaimCategoriesFailure({ errors })))
                )
            )
        );
    });

    public allClaimCategoriesSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimCategoriesActions.allClaimCategoriesSuccess),
                tap(() => this.claimCategoriesEventService.onAllClaimCategoriesSuccess())
            );
        },
        { dispatch: false }
    );

    public allClaimCategoriesError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimCategoriesActions.allClaimCategoriesFailure),
                tap(({ errors }) => this.claimCategoriesEventService.onAllClaimCategoriesError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private claimCategoriesApiService: ClaimCategoriesApiService,
        private claimCategoriesEventService: ClaimCategoriesEventService
    ) {}
}
