import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OutCoreModule } from '../core';
import { RELATION_CONFIGURATION } from './configuration';
import { RelationConfigurationInterface } from './interfaces';
import { RelationEffects } from './store/relation.effects';
import { RelationReducers } from './store/relation.reducers';
import { RELATION_FEATURE } from './store/relation.state';

@NgModule({
    imports: [
        OutCoreModule,
        StoreModule.forFeature(RELATION_FEATURE, RelationReducers.reducer),
        EffectsModule.forFeature([RelationEffects]),
    ],
})
export class OutRelationModule {
    public static forRoot(configuration: RelationConfigurationInterface): ModuleWithProviders<OutRelationModule> {
        return {
            ngModule: OutRelationModule,
            providers: [{ provide: RELATION_CONFIGURATION, useValue: configuration }],
        };
    }
}
