import { Action } from '@ngrx/store';
import { type } from '../../../core';
import { CoveragePremiumCoverageInterface, CoveragePremiumInterface } from '../../interfaces';

/** @deprecated use InsurancePolicyActionService */
export const CLEAR = type('[coverage premium action service] clear');
/** @deprecated use InsurancePolicyActionService */
export const LOAD = type('[coverage premium action service] load');
/** @deprecated use InsurancePolicyActionService */
export const LOAD_SUCCESS = type('[coverage premium action service] load success');
/** @deprecated use InsurancePolicyActionService */
export const LOAD_ERROR = type('[coverage premium action service] load error');
/** @deprecated use InsurancePolicyActionService */
export const SELECT = type('[coverage premium action service] select');
/** @deprecated use InsurancePolicyActionService */
export const CHANGE_GENERIC_PURCHASED_COVERAGES = type(
    '[coverage premium action service] change generic purchased coverages'
);
/** @deprecated use InsurancePolicyActionService */
export const CHANGE_GENERIC_PURCHASED_COVERAGES_SUCCESS = type(
    '[coverage premium action service] change generic purchased coverages success'
);
/** @deprecated use InsurancePolicyActionService */
export const CANCEL_FUTURE_PURCHASED_COVERAGE = type(
    '[coverage premium action service] cancel future purchased coverage'
);
/** @deprecated use InsurancePolicyActionService */
export const CANCEL_FUTURE_PURCHASED_COVERAGE_SUCCESS = type(
    '[coverage premium action service] cancel future purchased coverage success'
);

/** @deprecated use InsurancePolicyActionService */
export class Clear implements Action {
    public readonly type = CLEAR;
}
/** @deprecated use InsurancePolicyActionService */
export class Load implements Action {
    public readonly type = LOAD;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated use InsurancePolicyActionService */
export class LoadSuccess implements Action {
    public readonly type = LOAD_SUCCESS;

    constructor(public payload: { purchased_product_sequence_number: number; coverages: CoveragePremiumInterface }) {}
}
/** @deprecated use InsurancePolicyActionService */
export class LoadError implements Action {
    public readonly type = LOAD_ERROR;
}
/** @deprecated use InsurancePolicyActionService */
export class Select implements Action {
    public readonly type = SELECT;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated use InsurancePolicyActionService */
export class ChangeGenericPurchasedCoverages implements Action {
    public readonly type = CHANGE_GENERIC_PURCHASED_COVERAGES;

    constructor(
        public payload: {
            purchased_product_sequence_number: number;
            start_date: string;
            coverage_ids: {
                coverage_id: string;
            }[];
        }
    ) {}
}
/** @deprecated use InsurancePolicyActionService */
export class ChangeGenericPurchasedCoveragesSuccess implements Action {
    public readonly type = CHANGE_GENERIC_PURCHASED_COVERAGES_SUCCESS;

    constructor(
        public payload: {
            purchased_product_sequence_number: number;
            start_date: string;
            purchased_coverages: CoveragePremiumCoverageInterface[];
        }
    ) {}
}
/** @deprecated use InsurancePolicyActionService */
export class CancelFuturePurchasedCoverage implements Action {
    public readonly type = CANCEL_FUTURE_PURCHASED_COVERAGE;

    constructor(public payload: { purchased_product_sequence_number: number }) {}
}
/** @deprecated use InsurancePolicyActionService */
export class CancelFuturePurchasedCoverageSuccess implements Action {
    public readonly type = CANCEL_FUTURE_PURCHASED_COVERAGE_SUCCESS;

    constructor(
        public payload: {
            purchased_product_sequence_number: number;
            purchased_coverages: CoveragePremiumCoverageInterface[];
        }
    ) {}
}
/** @deprecated use InsurancePolicyActionService */
export type CoveragePremiumActions =
    | Clear
    | Load
    | LoadSuccess
    | LoadError
    | Select
    | ChangeGenericPurchasedCoverages
    | ChangeGenericPurchasedCoveragesSuccess
    | CancelFuturePurchasedCoverage
    | CancelFuturePurchasedCoverageSuccess;
