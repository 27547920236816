import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    ClaimObjectDetailsInterface,
    ClaimObjectElementInterface,
    ClaimObjectInterface,
    SavedClaimObjectInterface,
} from '../interfaces';
import { ClaimObjectsSelectors } from '../store/claim-objects.selectors';
import { ClaimObjectsState } from '../store/claim-objects.state';

@Injectable({ providedIn: 'root' })
export class ClaimObjectsDataService {
    constructor(private store$: Store<ClaimObjectsState>) {}

    public getState$(): Observable<ClaimObjectsState> {
        return this.store$.select(ClaimObjectsSelectors.getState);
    }

    public getClaimObjects$(): Observable<ClaimObjectInterface[]> {
        return this.store$.select(ClaimObjectsSelectors.getClaimObjects);
    }

    public getClaimObjectDetails$(claimObjectId: string): Observable<ClaimObjectDetailsInterface> {
        return this.store$.select(ClaimObjectsSelectors.getClaimObjectDetails(claimObjectId));
    }

    public getClaimObjectElements$(claimObjectId: string): Observable<ClaimObjectElementInterface[]> {
        return this.store$.select(ClaimObjectsSelectors.getClaimObjectElements(claimObjectId));
    }

    public getSavedClaimObjects$(): Observable<SavedClaimObjectInterface[]> {
        return this.store$.select(ClaimObjectsSelectors.getSavedClaimObjects);
    }
}
