import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, exhaustMap, tap } from 'rxjs/operators';
import { ClaimObjectsApiService } from '../services/claim-objects.api.service';
import { ClaimObjectsEventService } from '../services/claim-objects.event.service';
import * as ClaimObjectsActions from './claim-objects.actions';

@Injectable()
export class ClaimObjectsEffects {
    public getClaimObjects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimObjectsActions.getClaimObjects),
            exhaustMap(({ request }) =>
                this.claimObjectsApiService.getClaimObjects$(request).pipe(
                    map((response) => ClaimObjectsActions.getClaimObjectsSuccess({ response })),
                    catchError((errors) => of(ClaimObjectsActions.getClaimObjectsFailure({ errors })))
                )
            )
        );
    });

    public getClaimObjectsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.getClaimObjectsSuccess),
                tap(() => this.claimObjectsEventService.onGetClaimObjectsSuccess())
            );
        },
        { dispatch: false }
    );

    public getClaimObjectsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.getClaimObjectsFailure),
                tap(({ errors }) => this.claimObjectsEventService.onGetClaimObjectsError(errors))
            );
        },
        { dispatch: false }
    );

    public getClaimObjectDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimObjectsActions.getClaimObjectDetails),
            exhaustMap(({ request }) =>
                this.claimObjectsApiService.getClaimObjectDetails$(request).pipe(
                    map((response) => ClaimObjectsActions.getClaimObjectDetailsSuccess({ response })),
                    catchError(({ errors }) => of(ClaimObjectsActions.getClaimObjectDetailsSuccess(errors)))
                )
            )
        );
    });

    public getClaimObjectDetailsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.getClaimObjectDetailsSuccess),
                tap(() => this.claimObjectsEventService.onGetClaimObjectDetailsSuccess())
            );
        },
        { dispatch: false }
    );

    public getClaimObjectDetailsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.getClaimObjectDetailsFailure),
                map(({ errors }) => this.claimObjectsEventService.onGetClaimObjectDetailsError(errors))
            );
        },
        { dispatch: false }
    );

    public updateClaimObjectDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimObjectsActions.updateClaimObjectDetails),
            exhaustMap(({ request }) =>
                this.claimObjectsApiService.updateClaimObjectDetails$(request).pipe(
                    map((response) => ClaimObjectsActions.updateClaimObjectDetailsSuccess({ response })),
                    catchError(({ errors }) => of(ClaimObjectsActions.updateClaimObjectDetailsSuccess(errors)))
                )
            )
        );
    });

    public updateClaimObjectDetailsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.updateClaimObjectDetailsSuccess),
                tap(() => this.claimObjectsEventService.onUpdateClaimObjectDetailsSuccess())
            );
        },
        { dispatch: false }
    );

    public updateClaimObjectDetailsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.updateClaimObjectDetailsFailure),
                map(({ errors }) => this.claimObjectsEventService.onUpdateClaimObjectDetailsError(errors))
            );
        },
        { dispatch: false }
    );

    public reloadClaimObjectDetails$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimObjectsActions.reloadClaimObjectDetails),
            exhaustMap(({ request }) =>
                this.claimObjectsApiService.reloadClaimObjectDetails$(request).pipe(
                    map((response) => ClaimObjectsActions.reloadClaimObjectDetailsSuccess({ response })),
                    catchError(({ errors }) => of(ClaimObjectsActions.reloadClaimObjectDetailsFailure(errors)))
                )
            )
        );
    });

    public reloadClaimObjectDetailsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.reloadClaimObjectDetailsSuccess),
                tap(() => this.claimObjectsEventService.onReloadClaimObjectDetailsSuccess())
            );
        },
        { dispatch: false }
    );

    public reloadClaimObjectDetailsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.reloadClaimObjectDetailsFailure),
                map(({ errors }) => this.claimObjectsEventService.onReloadClaimObjectDetailsError(errors))
            );
        },
        { dispatch: false }
    );

    public saveClaimObject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimObjectsActions.saveClaimObject),
            exhaustMap(({ request }) =>
                this.claimObjectsApiService.saveClaimObject$(request).pipe(
                    map((response) => ClaimObjectsActions.saveClaimObjectSuccess({ response })),
                    catchError((errors) => of(ClaimObjectsActions.saveClaimObjectFailure({ errors })))
                )
            )
        );
    });

    public saveClaimObjectSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.saveClaimObjectSuccess),
                tap(() => this.claimObjectsEventService.onSaveClaimObjectSuccess())
            );
        },
        { dispatch: false }
    );

    public saveSpecificationError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.saveClaimObjectFailure),
                map(({ errors }) => this.claimObjectsEventService.onSaveClaimObjectError(errors))
            );
        },
        { dispatch: false }
    );

    public removeClaimObject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimObjectsActions.removeClaimObject),
            exhaustMap(({ request }) =>
                this.claimObjectsApiService.removeClaimObject$(request).pipe(
                    map((response) => ClaimObjectsActions.removeClaimObjectSuccess({ response })),
                    catchError((errors) => of(ClaimObjectsActions.removeClaimObjectFailure({ errors })))
                )
            )
        );
    });

    public removeClaimObjectSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.removeClaimObjectSuccess),
                tap(() => this.claimObjectsEventService.onRemoveClaimObjectsSuccess())
            );
        },
        { dispatch: false }
    );

    public removeClaimObjectError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimObjectsActions.removeClaimObjectFailure),
                map(({ errors }) => this.claimObjectsEventService.onRemoveClaimObjectsError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private claimObjectsApiService: ClaimObjectsApiService,
        private claimObjectsEventService: ClaimObjectsEventService
    ) {}
}
