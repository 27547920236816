import {
    ApiValidateGeneratedAccountRequest,
    ApiValidateGeneratedAccountResponse,
    ValidateGeneratedAccountRequestInterface,
    ValidateGeneratedAccountResponseInterface,
} from '../interfaces';

export function mapValidateGeneratedAccountRequest(
    request: ValidateGeneratedAccountRequestInterface
): ApiValidateGeneratedAccountRequest {
    return {
        birthdate: request.birthdate,
        postal_code: request.postalCode,
    };
}

export function mapValidateGeneratedAccountResponse(
    response: ApiValidateGeneratedAccountResponse
): ValidateGeneratedAccountResponseInterface {
    return {
        validationSuccessful: response.check_successful,
        numberOfAttempts: response.number_of_attempts,
        relation: response.relation,
    };
}
