import { mapEnumApiRequest, mapSequenceNumber, mapSuccessIndication } from '../../core';
import { apiInvalidatedReasonCodeEnum, invalidatedReasonCodeEnum } from '../enums';
import { RemoveSpecificationRequestInterface, RemoveSpecificationResponseInterface } from '../interfaces';
import { ApiRemoveSpecificationRequestInterface } from '../interfaces/api.remove-specification-request.interface';
import { ApiRemoveSpecificationResponseInterface } from '../interfaces/api.remove-specification-response.interface';

export function mapRemoveSpecificationRequest(
    request: RemoveSpecificationRequestInterface
): ApiRemoveSpecificationRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        invalidated_reason_code: mapEnumApiRequest(
            request.invalidatedReasonCode,
            apiInvalidatedReasonCodeEnum,
            invalidatedReasonCodeEnum
        ),
        notifications: [
            {
                message_agreement_indication: mapSuccessIndication(request.messageAgreementIndication),
                message_code: request.messageCode,
            },
        ],
    };
}

export function mapRemoveSpecificationResponse(
    response: ApiRemoveSpecificationResponseInterface
): RemoveSpecificationResponseInterface {
    return {
        specification: null,
    };
}
