import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../../core';

@Injectable({
    providedIn: 'root',
})
export class CalculationEventService {
    private _emailPremiumCalculation$: Subject<void>;

    public emailPremiumCalculation$(): Observable<void> {
        this._emailPremiumCalculation$ = new Subject<void>();

        return this._emailPremiumCalculation$.asObservable();
    }

    public onEmailPremiumCalculationSuccess(): void {
        this._emailPremiumCalculation$.next();
        this._emailPremiumCalculation$.complete();
    }

    public onEmailPremiumCalculationError(errors: ErrorInterface[]): void {
        this._emailPremiumCalculation$.error(errors);
    }
}
