import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationInterface, RequestService } from '../../../core';
import { ProductIdEnum } from '../../../my-zone';
import { MotorVehiclesToReplace } from '../../interfaces';
import { SalesStorageService } from '../../services/sales-storage.service';
import * as fromSales from '../sales.reducers';
import { LoadError, Validate, ValidateFalse, ValidateTrue } from './vehicle-replace.actions';
import { UserChoice } from './vehicle-replace.enums';
import * as fromVehicleReplace from './vehicle-replace.selectors';

@Injectable({
    providedIn: 'root',
})
export class VehicleReplaceService {
    public userChoice$: Observable<UserChoice> = this.store$.select(fromVehicleReplace.getUserChoice);

    public current$: Observable<MotorVehiclesToReplace> = this.store$.select(fromVehicleReplace.getCurrent);

    public showModal$: Observable<boolean> = this.store$.select(fromVehicleReplace.showModal);

    public errors$: Observable<NotificationInterface[]> = this.store$.select(fromVehicleReplace.getErrors);

    constructor(
        private request: RequestService,
        private store$: Store<fromSales.State>,
        private storageService: SalesStorageService
    ) {}

    public validate$(
        licensePlate: string,
        noClaimsYearsAmount: string | number,
        productId = ProductIdEnum.Car
    ): Observable<boolean | MotorVehiclesToReplace> {
        this.store$.dispatch(new Validate({ licensePlate, noClaimsYearsAmount }));
        this.storageService.removeItem('vehicleReplace');

        return this.validate(licensePlate, noClaimsYearsAmount, productId).pipe(
            map((response: MotorVehiclesToReplace) => {
                if (response && (response.purchased_product_addition || response.purchased_product_replacement)) {
                    this.store$.dispatch(new ValidateTrue({ response }));
                    this.storageService.setItem('vehicleReplace', response);
                    return response;
                }

                this.store$.dispatch(new ValidateFalse());
                return false;
            }),
            catchError((errors) => {
                this.store$.dispatch(new LoadError({ errors }));
                return throwError(errors);
            })
        );
    }

    private validate(
        licensePlate: string,
        noClaimsYearsAmount: string | number,
        productId
    ): Observable<MotorVehiclesToReplace> {
        const payload = {
            product_id: productId,
            no_claims_years_amount: noClaimsYearsAmount,
            license_plate: licensePlate,
        };

        return this.request
            .post('en-gb/relation-general/request/motor_vehicles_to_replace', payload)
            .pipe(map((response) => response.data));
    }
}
