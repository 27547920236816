export enum ApiProductBonusComponentIdEnum {
    AlarmRegular = 'ALRM', // Alarm
    AlarmSerure = 'MALR', // Alarm (klasse SCM 2 of hoger)
    DriversLicense = 'RBBE', // Rijbewijs BE
    DrivingTraining = 'ROPL', // Rij Opleiding VRO-I
    EnergyLable = 'ERGL', // Energielabel A of B
    ParkingSensors = 'PRKS', // Parkeersensoren
    PreventativeCare = 'PZRG', // Preventieve zorg
    RegularKeyLock = 'SLOT', // Extra goed slot
    SecureKeyLock = 'CSLT', // Slot
    SmokeDetecors = 'RKMD', // Rookmelders
    TestForWWW = 'DIET', // Test voor WWW
    WaterStop = 'STOP', // Waterstop in (vaat)wasautomaat
    WinterStorage = 'WNRS', // Winterstalling
    WinterTires = 'WBND', // Winterbanden
}
