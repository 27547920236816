import { PolicyResponseInterface, ProductRequestInterface, ProductResponseInterface } from '../../interfaces';

export function mapProductResponse(
    payload: ProductRequestInterface,
    response: PolicyResponseInterface
): ProductResponseInterface {
    const purchasedProduct = response.purchasedProducts.find(
        (product) => product.productSequenceNumber === payload.productSequenceNumber
    );

    return {
        productId: payload.productId,
        productSequenceNumber: payload.productSequenceNumber,
        product: purchasedProduct.product,
    };
}
