import { mapSequenceNumber } from '../../core';
import { ApiGetClaimObjectsRequestInterface, GetClaimObjectsRequestInterface } from '../interfaces';

export function mapGetClaimObjectsRequest(
    request: GetClaimObjectsRequestInterface
): ApiGetClaimObjectsRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        claim_object: {
            kind_of_loss_id: request.damageTypeId,
        },
    };
}
