import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ErrorInterface } from '../../../core';
import { SalesStorageService } from '../../services/sales-storage.service';
import { CoverageService } from '../coverage/coverage.service';
import { CustomerService } from '../customer/customer.service';
import { InsuranceService } from '../insurance/insurance.service';
import { ProgressService } from '../progress/progress.service';
import { UserChoice } from '../vehicle-replace/vehicle-replace.enums';
import { VehicleReplaceService } from '../vehicle-replace/vehicle-replace.service';
import * as calculationActions from './calculation.actions';
import { CalculationEventService } from './calculation.event.service';
import { CalculationService } from './calculation.service';
import * as coverageActions from '../coverage/coverage.actions';

@Injectable()
export class CalculationEffects {
    public calculatePremium$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                calculationActions.CALCULATE_CAR_PREMIUM,
                calculationActions.CALCULATE_HOME_PREMIUM,
                calculationActions.CALCULATE_BICYCLE_PREMIUM
            ),
            map((action: calculationActions.CalculatePremium) => action.payload),
            withLatestFrom(
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.vehicleReplaceService.userChoice$,
                this.progressService.productId$
            ),
            map(([input, insurance, customer, userChoice, productId]) => {
                const insuranceInput = {
                    ...insurance,
                    build_month: null,
                    build_year: null,
                    license_plate: null,
                    style_trim_id: null,
                    ...input.insurance,
                };

                if (userChoice !== UserChoice.Yes) {
                    insuranceInput.related_vehicle_purchased_product_sequence_number = null;
                    insuranceInput.non_regular_driver_transfer_indication = null;
                }

                return {
                    insurance: insuranceInput,
                    customer: { ...customer, ...input.customer },
                    productId,
                };
            }),
            switchMap((payload) => {
                return this.calculationService.effectCalculate$(payload).pipe(
                    map((premium) => new calculationActions.CalculatePremiumSuccess({ ...payload, premium })),
                    tap(() => this.storageService.save(payload.productId, payload.insurance, payload.customer)),
                    catchError((errors) =>
                        of(
                            new calculationActions.CalculatePremiumError({
                                data: { ...payload, premium: null },
                                errors,
                            })
                        )
                    )
                );
            })
        );
    });

    public campaignCodeChanged$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(calculationActions.CAMPAIGN_CODE_CHANGED),
            map((action: calculationActions.CampaignCodeChanged) => action.payload),
            withLatestFrom(
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.coverageService.modules$,
                this.progressService.productId$
            ),
            map(([input, insurance, customer, modules, productId]) => ({
                insurance: { ...insurance, campaign_code: input.campaignCode },
                customer,
                coverage: modules,
                productId,
            })),
            switchMap((payload) => {
                return this.calculationService.effectCalculate$(payload).pipe(
                    map((premium) => new calculationActions.CampaignCodeChangedSuccess({ ...payload, premium })),
                    tap(() => this.storageService.save(payload.productId, payload.insurance, payload.customer)),
                    catchError((errors) => of(new calculationActions.CampaignCodeChangedError({ errors })))
                );
            })
        );
    });

    public premiumFactorUpdate$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(calculationActions.PREMIUM_FACTOR_UPDATE),
            map((action: calculationActions.PremiumFactorUpdated) => action.payload),
            withLatestFrom(
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.progressService.productId$
            ),
            map(([input, insurance, customer, productId]) => ({
                insurance: { ...insurance, ...input.insurance },
                customer: { ...customer, ...input.customer },
                coverage: { ...input.coverage },
                productId,
            })),
            switchMap((payload) => {
                return this.calculationService.effectCalculate$(payload).pipe(
                    map((premium) => new calculationActions.PremiumUpdatedSuccess({ ...payload, premium })),
                    tap(() => this.storageService.save(payload.productId, payload.insurance, payload.customer)),
                    catchError((errors) => of(new calculationActions.PremiumUpdatedError({ errors })))
                );
            })
        );
    });

    public calculateUpdateSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(calculationActions.UPDATE_SUCCESS),
            withLatestFrom(this.coverageService.coverages$, this.progressService.productId$),
            map(([_, coverages, productId]) => {
                return new coverageActions.UpdateReceipt({
                    productId: productId,
                    coverage: coverages.map((coverage) => coverage.coverage_id),
                });
            })
        );
    });

    // Todo: delete after fully implementing new version of calculation email
    public emailPremium$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                calculationActions.EMAIL_CAR_PREMIUM,
                calculationActions.EMAIL_HOME_PREMIUM,
                calculationActions.EMAIL_BICYCLE_PREMIUM
            ),
            map((action: calculationActions.EmailPremium) => action.payload),
            withLatestFrom(
                this.coverageService.coverages$,
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.progressService.productId$
            ),
            map(([input, coverages, insurance, customer, productId]) => ({
                coverages,
                insurance,
                customer,
                productId,
                emailAddress: input.emailAddress,
            })),
            switchMap((payload) => {
                return this.calculationService.email$(payload).pipe(
                    map(() => new calculationActions.EmailSent()),
                    catchError((errors) => of(new calculationActions.EmailFailed({ errors })))
                );
            })
        );
    });

    public emailPremiumCalculation$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(calculationActions.EMAIL_PREMIUM_CALCULATION),
            map((action: calculationActions.EmailPremiumCalculation) => action.payload),
            switchMap((payload) => {
                return this.calculationService
                    .emailPremiumCalculation$(payload.url, payload.email, payload.payload)
                    .pipe(
                        map(() => new calculationActions.EmailPremiumCalculationSuccess()),
                        catchError((errors) => of(new calculationActions.EmailPremiumCalculationError({ errors })))
                    );
            })
        );
    });

    public emailPremiumCalculationSuccess$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(calculationActions.EMAIL_PREMIUM_CALCULATION_SUCCESS),
                tap(() => this.calculationEventService.onEmailPremiumCalculationSuccess())
            );
        },
        { dispatch: false }
    );

    public emailPremiumCalculationError$: Observable<ErrorInterface[]> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(calculationActions.EMAIL_PREMIUM_CALCULATION_ERROR),
                tap((error: ErrorInterface[]) => this.calculationEventService.onEmailPremiumCalculationError(error))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private progressService: ProgressService,
        private calculationEventService: CalculationEventService,
        private calculationService: CalculationService,
        private insuranceService: InsuranceService,
        private customerService: CustomerService,
        private coverageService: CoverageService,
        private storageService: SalesStorageService,
        private vehicleReplaceService: VehicleReplaceService
    ) {}
}
