import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AddressService } from './address.service';

@Injectable({
    providedIn: 'root',
})
export class AddressValidator {
    constructor(private addressService: AddressService) {}

    public validate(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return this.addressService.validate({ address: control.value }).pipe(
                map(() => null),
                catchError(() => of({ invalidAddress: true }))
            );
        };
    }
}
