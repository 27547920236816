import { ApiProductMotorcycleResponse , ProductMotorcycleResponseInterface } from '../../interfaces';

export function mapProductMotorcycleResponse(
    response: ApiProductMotorcycleResponse
): ProductMotorcycleResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        autotelexId: response.autotelex_id,
        bodyStyle: response.body_style,
        bodyStyleDescription: response.body_style_description,
        buildDate: response.build_date,
        catalogValueAmount: response.catalog_value_amount,
        licencePlateIssuedDate: response.licence_plate_issued_date,
        licensePlate: response.license_plate,
        motorVehicleBrandDescription: response.motor_vehicle_brand_description,
        motorVehicleMake: response.motor_vehicle_make,
        motorVehicleModel: response.motor_vehicle_model,
        motorVehicleType: response.motor_vehicle_type,
        motorVehicleTypeDescription: response.motor_vehicle_type_description,
        newValueAmount: response.new_value_amount,
        weight: response.weight,
    };
}
