import {
    ApiGetLocationsReponseInterface,
    ApiGetLocationsRequestInterface,
    GetLocationsReponseInterface,
    GetLocationsRequestInterface,
} from '../interfaces/get-locations.interface';

export function mapGetLocationsResponse(response: ApiGetLocationsReponseInterface): GetLocationsReponseInterface {
    if (!response.locations) {
        return {
            locations: [],
        };
    }

    return {
        locations: response.locations.map((responseLocation) => ({
            businessRelationId: responseLocation.business_relation_id,
            networkId: responseLocation.network_id,
            networkDescription: responseLocation.network_description,
            name: responseLocation.name,
            city: responseLocation.city,
            streetName: responseLocation.street_name,
            civicNumber: responseLocation.civic_number,
            civicNumberSuffix: responseLocation.civic_number_suffix,
            postalCode: responseLocation.postal_code,
            countryId: responseLocation.country_id,
            countryDescription: responseLocation.country_description,
            provinceCode: responseLocation.province_code,
            proviceDescription: responseLocation.provice_description,
            mailbox: responseLocation.mailbox,
            additionalAdressingExplenation: responseLocation.additional_adressing_explenation,
            telephoneNumber: responseLocation.telephone_number,
            emailAddress: responseLocation.email_address,
            latitude: responseLocation.latitude,
            longitude: responseLocation.longitude,
            distance: responseLocation.distance,
        })),
    };
}

export function mapGetLocationsRequest(request: GetLocationsRequestInterface): ApiGetLocationsRequestInterface {
    return {
        network_id: request.networkId,
        damage_repair_event_code: request.damageRepairEventCode,
        postal_code: request.postalCode,
        case: {
            case_sequence_number: request.caseSequenceNumber,
        },
    };
}
