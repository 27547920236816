import { mapSequenceNumber } from '../../../core/mappers';
import { ApiEndProductRequest, EndProductRequestInterface } from '../../interfaces';

export function mapEndProductRequest(request: EndProductRequestInterface): ApiEndProductRequest {
    return {
        purchased_product: {
            end_date: request.endDate,
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
