import { Injectable } from '@angular/core';
import { Coverage, Customer, Insurance, NonRegularDriver } from '../../interfaces';
import { deduceContext } from './insurance-context.helper';

interface ElementType {
    element_name: string;
    value: string | number | null;
    context_element_name?: string | undefined;
}

interface Input {
    coverages: Coverage[];
    insurance: Insurance;
    customer: Customer;
    isReturningCustomer: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class EligibilityService {
    private elements: ElementType[] = [];

    public getElements(productId: string, index: number, input: Input): ElementType[] {
        const products = {
            MOTR: () => this.getElementsMotor(index, input),
            AUTO: () => this.getElementsAuto(index, input),
            FIET: () => this.getElementsBicycle(index, input),
            WOON: () => this.getElementsHome(index, input),
            CVAN: () => this.getElementsCaravan(index, input),
            BROM: () => this.getElementsMoped(index, input),
        };

        this.elements = [];

        if (!products.hasOwnProperty(productId)) {
            return this.elements;
        }

        return products[productId]();
    }

    private getElementsAuto(index: number, input: Input): ElementType[] {
        // berekenen
        if (index >= 0) {
            this.addElementsFrom(input.insurance, ['license_plate', 'style_trim_id', 'mileage_auto']);
            this.addElementsFrom(input.customer, ['postal_code', 'family_composition_code', 'birthdate']);

            if (['PRTR', 'KIND'].includes(input.insurance.relation_code)) {
                this.addElement('relation_code', input.insurance.relation_code);
                this.addElement('birthdate', input.insurance.non_regular_driver.birthdate, 'non_regular_driver');
            }

            if (input.insurance.non_regular_driver_transfer_indication) {
                this.addElement(
                    'non_regular_driver_transfer_indication',
                    input.insurance.non_regular_driver_transfer_indication
                );
            }

            // bij vervangen geen schadevrije jaren doorgeven
            if (input.insurance.related_vehicle_purchased_product_sequence_number) {
                this.addElement(
                    'related_vehicle_purchased_product_sequence_number',
                    input.insurance.related_vehicle_purchased_product_sequence_number,
                    'related_vehicle'
                );
            } else {
                this.addElement('no_claims_years_amount', input.insurance.no_claims_years_amount);
            }
        }

        // dekkingen
        if (index >= 1) {
            input.coverages.forEach((coverage) => {
                this.elements.push({ element_name: 'coverage_id', value: coverage.coverage_id });
            });
        }

        // verzekeringsgegevens
        if (index >= 2) {
            this.addElementsFrom(input.insurance, [
                'vin_last_four_digits_code',
                'start_date',
                'in_verleden_aantal_gemelde_schades_code',
                'licenseplate_holder_code',
                // 'switch_service_indication' >> hier loopt de BE op vast
            ]);

            if (input.insurance.end_date) {
                this.addElement('end_date', input.insurance.end_date, 'related_vehicle');
            }
        }

        // persoonsgegevens
        if (index >= 3) {
            this.addAccountElements(input);

            if (['PRTR', 'KIND'].includes(input.insurance.relation_code)) {
                this.addElementsFrom(input.insurance.non_regular_driver, [], 'non_regular_driver');
            }
        }

        return this.elements;
    }

    private getElementsBicycle(index: number, input: Input): ElementType[] {
        // berekenen
        if (index >= 0) {
            this.addElementsFrom(input.insurance, [
                'bicycle_model',
                'bicycle_brand',
                'purchase_value_amount',
                'purchase_date',
            ]);
            this.addElementsFrom(input.customer, ['postal_code', 'family_composition_code', 'birthdate']);
        }

        // dekkingen
        if (index >= 1) {
            input.coverages.forEach((coverage) => {
                this.elements.push({ element_name: 'coverage_id', value: coverage.coverage_id });
            });
        }

        // verzekeringsgegevens
        if (index >= 2) {
            this.addElementsFrom(input.insurance, [
                'bicycle_key_number',
                'framenumber',
                'new_indication',
                'start_date',
                'in_verleden_aantal_gemelde_schades_code',
            ]);
        }

        // persoonsgegevens
        if (index >= 3) {
            this.addAccountElements(input);

            if (['PRTR', 'KIND'].includes(input.insurance.relation_code)) {
                this.addElementsFrom(input.insurance.non_regular_driver, [], 'non_regular_driver');
            }
        }

        return this.elements;
    }

    private getElementsMotor(index: number, input: Input): ElementType[] {
        // berekenen
        if (index >= 0) {
            this.addElementsFrom(input.insurance, ['mileage_motorcycle', 'license_plate', 'style_trim_id']);
            this.addElementsFrom(input.customer, ['postal_code', 'family_composition_code', 'birthdate']);
        }

        // dekkingen
        if (index >= 1) {
            input.coverages.forEach((coverage) => {
                this.elements.push({ element_name: 'coverage_id', value: coverage.coverage_id });
            });
        }

        // verzekeringsgegevens
        if (index >= 2) {
            this.addElementsFrom(input.insurance, [
                'start_date',
                'vin_last_four_digits_code',
                'in_verleden_aantal_gemelde_schades_code',
            ]);

            if (input.insurance.hasOwnProperty('external_reference_id')) {
                this.addElementsFrom(input.insurance, [
                    'external_reference_id',
                    'partner_id',
                    'partner_component_code',
                ]);
            }
        }

        // persoonsgegevens
        if (index >= 3) {
            this.addAccountElements(input);

            if (['PRTR', 'KIND'].includes(input.insurance.relation_code)) {
                this.addElementsFrom(input.insurance.non_regular_driver, [], 'non_regular_driver');
            }
        }
        return this.elements;
    }

    private getElementsHome(index: number, input: Input): ElementType[] {
        // berekenen
        if (index >= 0) {
            this.addElementsFrom(input.insurance, [
                'build_year',
                'property_type',
                'straw_roofing_indication',
                'security_type_code',
                'tenant_owner_code',
            ]);
            this.addElementsFrom(input.customer, [
                'postal_code',
                'civic_number',
                'civic_number_suffix',
                'family_composition_code',
                'birthdate',
            ]);
        }

        // dekkingen
        if (index >= 1) {
            input.coverages.forEach((coverage) => {
                this.elements.push({ element_name: 'coverage_id', value: coverage.coverage_id });
            });
        }

        // verzekeringsgegevens
        if (index >= 2) {
            this.addElementsFrom(input.insurance, ['start_date', 'in_verleden_aantal_gemelde_schades_code']);
        }

        // persoonsgegevens
        if (index >= 3) {
            this.addAccountElements(input);
        }

        return this.elements;
    }

    private getElementsCaravan(index: number, input: Input): ElementType[] {
        // berekenen
        if (index >= 0) {
            this.addElementsFrom(input.insurance, [
                'catalog_value_amount',
                'caravan_code',
                'hail_roof_indication',
                'license_plate',
            ]);
            this.addElementsFrom(input.customer, ['postal_code', 'family_composition_code', 'birthdate']);
        }

        // dekkingen
        if (index >= 1) {
            input.coverages.forEach((coverage) => {
                this.elements.push({ element_name: 'coverage_id', value: coverage.coverage_id });
            });
        }

        // verzekeringsgegevens
        if (index >= 2) {
            this.addElementsFrom(input.insurance, [
                'switch_service_indication',
                'start_date',
                'vin_last_four_digits_code',
                'in_verleden_aantal_gemelde_schades_code',
            ]);

            if (input.insurance.hasOwnProperty('external_reference_id')) {
                this.addElementsFrom(input.insurance, [
                    'external_reference_id',
                    'partner_id',
                    'partner_component_code',
                ]);
            }
        }

        // persoonsgegevens
        if (index >= 3) {
            this.addAccountElements(input);
        }

        return this.elements;
    }

    private getElementsMoped(index: number, input: Input): ElementType[] {
        // berekenen
        if (index >= 0) {
            this.addElementsFrom(input.insurance, ['license_plate', 'style_trim_id']);
            this.addElementsFrom(input.customer, ['postal_code', 'family_composition_code', 'birthdate']);
        }

        // dekkingen
        if (index >= 1) {
            input.coverages.forEach((coverage) => {
                this.elements.push({ element_name: 'coverage_id', value: coverage.coverage_id });
            });
        }

        // verzekeringsgegevens
        if (index >= 2) {
            this.addElementsFrom(input.insurance, [
                'start_date',
                'vin_last_four_digits_code',
                'in_verleden_aantal_gemelde_schades_code',
            ]);

            if (input.insurance.hasOwnProperty('external_reference_id')) {
                this.addElementsFrom(input.insurance, [
                    'external_reference_id',
                    'partner_id',
                    'partner_component_code',
                ]);
            }
        }

        // persoonsgegevens
        if (index >= 3) {
            this.addAccountElements(input);
        }

        return this.elements;
    }

    private addAccountElements(input: Input): void {
        if (!input.isReturningCustomer) {
            this.addElementsFrom(input.customer, [
                'gender_code',
                'initials',
                'middle_name_initials',
                'surname',
                'civic_number',
                'civic_number_suffix',
                'telephone_number',
                'email_address',
                'password',
                'iban_bank_account_number',
                'account_holder_name',
                'direct_debit_agreement_indication',
            ]);

            if (input.customer.recovery_email_address) {
                this.addElement('email_address', input.customer.recovery_email_address, 'recovery_email_address');
            }
        }
    }

    private addElement(name: string, value: string | number | null, context: string = null): void {
        this.elements.push({
            element_name: name,
            context_element_name: context ? context : deduceContext(name),
            value,
        });
    }

    private addElementsFrom(
        input: Insurance | Customer | Partial<NonRegularDriver>,
        elements: string[],
        context: string = null
    ): void {
        elements.forEach((name) => {
            this.addElement(name, input[name] || null, context ? context : deduceContext(name));
        });
    }
}
