import { ApiGetClaimObjectsResponseInterface } from '../interfaces/api.get-claim-objects-response.interface';
import { GetClaimObjectsResponseInterface } from '../interfaces/get-claim-objects-response.interface';
import { mapClaimObjects } from './claim-objects.mapper';

export function mapGetClaimObjectsResponse(
    response: ApiGetClaimObjectsResponseInterface
): GetClaimObjectsResponseInterface {
    return {
        claimObjects: mapClaimObjects(response.claim_object),
    };
}
