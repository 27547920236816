import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ClaimAppointmentApiService } from '../services/claim-appointment.api.service';
import { ClaimAppointmentEventService } from '../services/claim-appointment.event.service';
import * as ClaimAppointmentActions from './claim-appointment.actions';

@Injectable()
export class ClaimAppointmentEffects {
    // Get Appointment
    public getAppointments$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimAppointmentActions.getAppointments),
            exhaustMap(({ request }) =>
                this.claimAppointmentApiService.getAppointments$(request).pipe(
                    map((response) => ClaimAppointmentActions.getAppointmentsSuccess({ response })),
                    catchError((errors) => of(ClaimAppointmentActions.getAppointmentsFailure({ errors })))
                )
            )
        );
    });

    public getAppointmentsSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.getAppointmentsSuccess),
                tap(() => this.claimAppointmentEventService.onGetAppointmentsSuccess())
            );
        },
        { dispatch: false }
    );

    public getAppointmentsError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.getAppointmentsFailure),
                tap(({ errors }) => this.claimAppointmentEventService.onGetAppointmentsError(errors))
            );
        },
        { dispatch: false }
    );

    // Save Appointment
    public saveAppointment$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimAppointmentActions.saveAppointment),
            exhaustMap(({ request }) =>
                this.claimAppointmentApiService.saveAppointment$(request).pipe(
                    map((response) => ClaimAppointmentActions.saveAppointmentSuccess({ response })),
                    catchError((errors) => of(ClaimAppointmentActions.saveAppointmentFailure({ errors })))
                )
            )
        );
    });

    public saveAppointmentSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.saveAppointmentSuccess),
                tap(() => this.claimAppointmentEventService.onSaveAppointmentSuccess())
            );
        },
        { dispatch: false }
    );

    public saveAppointmentError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.saveAppointmentFailure),
                tap(({ errors }) => this.claimAppointmentEventService.onSaveAppointmentError(errors))
            );
        },
        { dispatch: false }
    );

    // Get Locations
    public getLocations$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimAppointmentActions.getLocations),
            exhaustMap(({ request }) =>
                this.claimAppointmentApiService.getLocations$(request).pipe(
                    map((response) => ClaimAppointmentActions.getLocationsSuccess({ response })),
                    catchError((errors) => of(ClaimAppointmentActions.getLocationsFailure({ errors })))
                )
            )
        );
    });

    public getLocationsSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.getLocationsSuccess),
                tap(() => this.claimAppointmentEventService.onGetLocationsSuccess())
            );
        },
        { dispatch: false }
    );

    public getLocationsError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.getLocationsFailure),
                tap(({ errors }) => this.claimAppointmentEventService.onGetLocationsError(errors))
            );
        },
        { dispatch: false }
    );

    // Get Timeslots
    public getTimeslots$ = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimAppointmentActions.getTimeslots),
            exhaustMap(({ request }) =>
                this.claimAppointmentApiService.getTimeslots$(request).pipe(
                    map((response) => ClaimAppointmentActions.getTimeslotsSuccess({ response })),
                    catchError((errors) => of(ClaimAppointmentActions.getTimeslotsFailure({ errors })))
                )
            )
        );
    });

    public getTimeslotsSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.getTimeslotsSuccess),
                tap(() => this.claimAppointmentEventService.onGetTimeslotsSuccess())
            );
        },
        { dispatch: false }
    );

    public getTimeslotsError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimAppointmentActions.getTimeslotsFailure),
                tap(({ errors }) => this.claimAppointmentEventService.onGetTimeslotsError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private claimAppointmentApiService: ClaimAppointmentApiService,
        private claimAppointmentEventService: ClaimAppointmentEventService
    ) {}
}
