import { ApiSaveExhibitRequestInterface, SaveExhibitRequestInterface } from '../interfaces';

export function mapSaveExhibitRequest(request: SaveExhibitRequestInterface): ApiSaveExhibitRequestInterface {
    return {
        case: {
            case_sequence_number: request.caseSequenceNumber,
        },
        upload_item: {
            file_name: request.fileName,
            data_base64: request.dataBase64,
            document_id: request.documentId,
        },
    };
}
