import { SavedClaimObjectInterface } from '../interfaces/saved-claim-object.interface';

export function removeSavedClaimObjectsUtility(
    stateSavedClaimObjects: SavedClaimObjectInterface[],
    responseSavedClaimObjects: SavedClaimObjectInterface[]
): SavedClaimObjectInterface[] {
    const savedClaimObjectsFromState = Object.assign([], stateSavedClaimObjects).filter(
        (savedClaimObject) => savedClaimObject.claimObjectId !== responseSavedClaimObjects[0].claimObjectId
    );

    // Filter duplicates
    return [...new Set(savedClaimObjectsFromState)];
}
