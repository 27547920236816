import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ApiErrorLevelEnum, ApiSuccessIndicationEnum } from '../enums';
import { ApiResponse, ResponseInterface } from '../interfaces';
import { mapErrors } from './error.mapper';
import { mapFromSuccessIndication } from './success-indication.mapper';

export function mapResponse(response: ApiResponse, statusCode: number): ResponseInterface {
    return {
        statusCode,
        data: response.result,
        successIndication: mapFromSuccessIndication(response.successful_indication),
        errors: mapErrors(response.notifications),
    };
}

export function mapResponseToGeneric<T>(response: ResponseInterface): T {
    return response.data;
}

export function mapRawResponse(response: HttpResponse<ApiResponse>): ResponseInterface {
    const body: ApiResponse = response.body;

    return {
        statusCode: response.status,
        successIndication: mapFromSuccessIndication(body.successful_indication),
        data: body.result,
        errors: body.notifications ? mapErrors(body.notifications) : [],
    };
}

export function mapRawErrorResponse(response: HttpErrorResponse): ResponseInterface {
    let body: ApiResponse = response.error;

    if (!body) {
        body = {
            successful_indication: ApiSuccessIndicationEnum.Error,
            result: null,
            notifications: [
                {
                    element_id: 0,
                    element_name: 'server_error',
                    notification_sequence_number: response.status,
                    notification_code: ApiErrorLevelEnum.Error,
                    notification_text: response.statusText,
                },
            ],
        };
    }

    return {
        statusCode: response.status,
        successIndication: mapFromSuccessIndication(body.successful_indication),
        data: body.result,
        errors: mapErrors(body.notifications),
    };
}
