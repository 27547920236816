import { Observable, throwError } from 'rxjs';
import { ErrorInterface, ErrorLevelEnum, mapFromSuccessIndication, mapSequenceNumber } from '../../core';
import {
    ApiGetClaimCompensationCalculationInterface,
    ApiGetClaimCompensationCoverageInterface,
    ApiGetClaimCompensationEligibilityInterface,
    ApiGetClaimCompensationResponseInterface,
    ApiGetClaimCompensationSpecificationInterface,
    ClaimCompensationCalculationInterface,
    ClaimCompensationCoverageInterface,
    ClaimCompensationEligibilityInterface,
    ClaimCompensationSpecificationsInterface,
    GetClaimCompensationResponseInterface,
} from '../interfaces';

function mapCalculation(
    response: ApiGetClaimCompensationCalculationInterface[]
): ClaimCompensationCalculationInterface[] {
    if (!response) {
        return [];
    }

    return response.map((calculation) => ({
        costType: calculation.cost_type.map((costType) => ({
            costTypeCode: costType.cost_type_code,
            costTypeDescription: costType.cost_type_description,
            debitCreditCode: costType.debit_credit_code,
            debetCreditDescription: costType.debit_credit_description,
            amount: costType.amount,
        })),
        coverageId: calculation.coverage_id,
        coverageDescription: calculation.coverage_description,
    }));
}

function mapSpecifications(
    response: ApiGetClaimCompensationSpecificationInterface
): ClaimCompensationSpecificationsInterface {
    return {
        animalNumberOfInvoices: response.animal_number_of_invoices,
        animalNumberOfInvoicesDescription: response.animal_number_of_invoices_description,
        caseSequenceNumber: response.case_sequence_number,
        caseSequenceNumberDescription: response.case_sequence_number_description,
        causeAccidentAnimalCode: response.cause_accident_animal_code,
        causeAccidentAnimalDescription: response.cause_accident_animal_description,
        causeDentalAnimalCode: response.cause_dental_animal_code,
        causeDentalAnimalDescription: response.cause_dental_animal_description,
        causeDiseaseAnimalCode: response.cause_disease_animal_code,
        causeDiseaseAnimalDescription: response.cause_disease_animal_description,
        causePreventiveCareAnimalCode: response.cause_preventive_care_animal_code,
        causePreventiveCareAnimalDescription: response.cause_preventive_care_animal_description,
        causeTherapyAnimalCode: response.cause_therapy_animal_code,
        causeTherapyAnimalDescription: response.cause_therapy_animal_description,
        chipNumber: response.chip_number,
        consultationAmount: response.consultation_amount,
        consultationInclTaxAmount: response.consultation_incl_tax_amount,
        damageCausationDescription: response.damage_causation_description,
        damageCausationId: response.damage_causation_id,
        damageDate: response.damage_date,
        damageDateDescription: response.damage_date_description,
        damageEventExplanation: response.damage_event_explanation,
        damageEventTime: response.damage_event_time,
        damageEventTimeDescription: response.damage_event_time_description,
        invoicesFromSameYearIndication: mapFromSuccessIndication(response.invoices_from_same_year_indication),
        invoicesFromSameYearIndicationDescription: response.invoices_from_same_year_indication_description,
        kindOfLossDescription: response.kind_of_loss_description,
        kindOfLossId: response.kind_of_loss_id,
        opposingPartyKnownIndication: mapFromSuccessIndication(response.opposing_party_known_indication),
        opposingPartyKnownIndicationDescription: response.opposing_party_known_indication_description,
        otherCauseIndication: mapFromSuccessIndication(response.other_cause_indication),
        otherCauseIndicationDescription: response.other_cause_indication_description,
        otherLiabilityIndication: mapFromSuccessIndication(response.other_liability_indication),
        otherLiabilityIndicationDescription: response.other_liability_indication_description,
        petAidsAmount: response.pet_aids_amount,
        petAidsInclTaxAmount: response.pet_aids_incl_tax_amount,
        petAidsIndication: mapFromSuccessIndication(response.pet_aids_indication),
        petAidsIndicationDescription: response.pet_aids_indication_description,
        petAidsTaxIndication: mapFromSuccessIndication(response.pet_aids_tax_indication),
        petAidsTaxIndicationDescription: response.pet_aids_tax_indication_description,
        petCareProductsAmount: response.pet_care_products_amount,
        petCareProductsInclTaxAmount: response.pet_care_products_incl_tax_amount,
        petCareProductsIndication: mapFromSuccessIndication(response.pet_care_products_indication),
        petCareProductsIndicationDescription: response.pet_care_products_indication_description,
        petCareProductsTaxIndication: mapFromSuccessIndication(response.pet_care_products_tax_indication),
        petCareProductsTaxIndicationDescription: response.pet_care_products_tax_indication_description,
        petConsultationAmountTaxIndication: mapFromSuccessIndication(response.pet_consultation_amount_tax_indication),
        petConsultationAmountTaxIndicationDescription: response.pet_consultation_amount_tax_indication_description,
        petInvoiceTotalAmount: response.pet_invoice_total_amount,
        petMedicationsOrderedOnlineIndication: mapFromSuccessIndication(
            response.pet_medications_ordered_online_indication
        ),
        petMedicationsOrderedOnlineIndicationDescription:
            response.pet_medications_ordered_online_indication_description,
        petName: response.pet_name,
        petNutritionVitaminsAmount: response.pet_nutrition_vitamins_amount,
        petNutritionVitaminsInclTaxAmount: response.pet_nutrition_vitamins_incl_tax_amount,
        petNutritionVitaminsIndication: mapFromSuccessIndication(response.pet_nutrition_vitamins_indication),
        petNutritionVitaminsIndicationDescription: response.pet_nutrition_vitamins_indication_description,
        petNutritionVitaminsTaxIndication: mapFromSuccessIndication(response.pet_nutrition_vitamins_tax_indication),
        petNutritionVitaminsTaxIndicationDescription: response.pet_nutrition_vitamins_tax_indication_description,
        relationId: response.relation_id,
    };
}

function calculateReimbursementPercentage(amount: number, maximumAmount: number): number {
    return (maximumAmount / 100) * amount;
}

function mapCoverages(response: ApiGetClaimCompensationCoverageInterface[]): ClaimCompensationCoverageInterface[] {
    return response.map((coverage) => ({
        claimCoverageIndication: mapFromSuccessIndication(coverage.claim_coverage_indication),
        coverageDescription: coverage.coverage_description,
        coverageId: coverage.coverage_id,
        coverageIndicationDescription: coverage.coverage_indication_description,
        coverages: coverage.coverages.map((apiCoverage) => ({
            claimCoverageIndication: mapFromSuccessIndication(apiCoverage.claim_coverage_indication),
            coverageExceedanceExplanation: apiCoverage.coverage_exceedance_explanation,
            coverageIndicationDescription: apiCoverage.coverage_indication_description,
            declarationAmount: apiCoverage.declaration_amount,
            freeSpaceAmount: apiCoverage.free_space_amount,
            maximumReimbursementAmount: apiCoverage.maximum_reimbursed_amount,
            name: apiCoverage.name,
            nonReimbursedAmount: apiCoverage.non_reimbursed_amount,
            ownRiskAmount: apiCoverage.own_risk_amount,
            paidOutAmount: apiCoverage.paid_out_amount,
            reimbursementAmount: apiCoverage.reimbursement_amount,
            reimbursementPercentage: calculateReimbursementPercentage(
                apiCoverage.reimbursement_amount,
                apiCoverage.maximum_reimbursed_amount
            ),
            remainingAmount: apiCoverage.remaining_free_space_amount,
        })),
        paidOutAmount: coverage.paid_out_amount,
        freeSpaceAmount: coverage.free_space_amount,
        ownRiskAmount: coverage.own_risk_amount,
        declarationAmount: coverage.declaration_amount,
        maximumReimbursementAmount: coverage.maximum_reimbursed_amount,
        reimbursementAmount: coverage.reimbursement_amount,
        reimbursementPercentage: calculateReimbursementPercentage(
            coverage.reimbursement_amount,
            coverage.maximum_reimbursed_amount
        ),
        nonReimbursedAmount: coverage.non_reimbursed_amount,
        remainingAmount: coverage.remaining_free_space_amount,
    }));
}

function mapEligibility(response: ApiGetClaimCompensationEligibilityInterface): ClaimCompensationEligibilityInterface {
    if (!response.rules) {
        return {
            rules: [],
            acceptedIndication: mapFromSuccessIndication(response.accepted_indication),
        };
    }

    return {
        rules: response.rules.map((rule) => ({
            acceptanceCode: rule.acceptance_code,
            description: rule.description,
        })),
        acceptedIndication: mapFromSuccessIndication(response.accepted_indication),
    };
}

export function mapGetClaimCompensationResponse(
    response: ApiGetClaimCompensationResponseInterface
): GetClaimCompensationResponseInterface {
    if (response.no_coverage_explanation) {
        throw response.no_coverage_explanation;
    }

    return {
        claimCompensation: {
            calculation: mapCalculation(response.calculation),
            claimCoverageIndication: mapFromSuccessIndication(response.claim_coverage_indication),
            coverageIndicationDescription: response.coverage_indication_description,
            coverageModuleId: response.coverage_module_id,
            coverageName: response.coverage_name,
            coverages: mapCoverages(response.coverages),
            eligibility: mapEligibility(response.eligibility),
            excludedCostAmount: response.excluded_costs_amount,
            insuredAmount: response.insured_amount,
            invoiceAmount: response.invoice_amount,
            noCoverageExplanation: response.no_coverage_explanation,
            onlyExcludedCostsIndication: mapFromSuccessIndication(response.only_excluded_costs_indication),
            ownRiskAmount: response.own_risk_amount,
            purchasedProductSequenceNumber: mapSequenceNumber(response.purchased_product_sequence_number),
            reimbursementAmount: response.reimbursement_amount,
            specifications: mapSpecifications(response.specifications),
            stpIndication: mapFromSuccessIndication(response.stp_indication),
        },
    };
}

export function mapGetClaimCompensationError$(error: string | ErrorInterface[]): Observable<never> {
    if (typeof error === 'object') {
        return throwError(error);
    }

    return throwError([
        {
            id: 'claim-coverage',
            element: 'mapGetClaimCompensationResponse',
            level: ErrorLevelEnum.Error,
            message: error,
        },
    ]);
}
