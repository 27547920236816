import {
    ApiReloadClaimObjectDetailsResponseInterface,
    ReloadClaimObjectDetailsRequestInterface,
    ReloadClaimObjectDetailsResponseInterface,
} from '../interfaces';
import { mapClaimObjectDetails } from './claim-object-details.mapper';
import { mapClaimObjectElements } from './claim-object-elements.mapper';

// This mapper uses data from te request to create the desired response
export function mapReloadClaimObjectDetailsResponse(
    response: ApiReloadClaimObjectDetailsResponseInterface[],
    request: ReloadClaimObjectDetailsRequestInterface
): ReloadClaimObjectDetailsResponseInterface {
    return {
        claimObjectDetails: mapClaimObjectDetails(response, request.claimObjectId),
        claimObjectElements: mapClaimObjectElements(request),
    };
}
