import { mapSequenceNumber, mapSuccessIndication } from '../../core';
import { ApiSaveClaimCoverageRequestInterface, SaveClaimCoverageRequestInterface } from '../interfaces';

export function mapSaveClaimCoverageRequest(
    request: SaveClaimCoverageRequestInterface
): ApiSaveClaimCoverageRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        permission_effects_indication: mapSuccessIndication(request.approved),
    };
}
