import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    CompleteGeneratedAccountResponseInterface,
    PersonalInformationInterface,
    RecoveryMethodsResponseInterface,
} from '../interfaces';
import { AccountRecovery, AccountRecoverySelectors, AccountRecoveryState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class AccountRecoveryDataService {
    constructor(private store$: Store<AccountRecovery>) {}

    /** Only for testing in demo */
    public getState$(): Observable<AccountRecoveryState> {
        return this.store$.select(AccountRecoverySelectors.getState);
    }

    public getRecoveryMethods$(): Observable<RecoveryMethodsResponseInterface> {
        return this.store$.select(AccountRecoverySelectors.getRecoveryMethods);
    }


    public getCompletedAccount$(): Observable<CompleteGeneratedAccountResponseInterface> {
        return this.store$.select(AccountRecoverySelectors.getCompletedAccount);
    }

    public getPersonalInformation$(): Observable<PersonalInformationInterface> {
        return this.store$.select(AccountRecoverySelectors.getPersonalInformation);
    }
}
