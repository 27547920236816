import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LegalPartyInterface } from '../interfaces/legal-party.interface';
import { NaturalPartyInterface } from '../interfaces/natural-party.interface';
import { RelatedPartyTypeInterface } from '../interfaces/related-party-type.interface';
import { ClaimPartiesSelectors } from '../store/claim-parties.selectors';
import { ClaimPartiesState } from '../store/claim-parties.state';

@Injectable({ providedIn: 'root' })
export class ClaimPartiesDataService {
    constructor(private store$: Store<ClaimPartiesState>) {}

    public getState$(): Observable<ClaimPartiesState> {
        return this.store$.select((ClaimPartiesSelectors.getState));
    }

    public getLegalParties$(): Observable<LegalPartyInterface[]> {
        return this.store$.select((ClaimPartiesSelectors.getLegalParties));
    }

    public getNaturalParties$(): Observable<NaturalPartyInterface[]> {
        return this.store$.select((ClaimPartiesSelectors.getNaturalParties));
    }

    public getRelatedPartyTypes$(): Observable<RelatedPartyTypeInterface[]> {
        return this.store$.select((ClaimPartiesSelectors.relatedPartyTypes));
    }

    public getSingleAllowdRelatedPartyTypes$(): Observable<RelatedPartyTypeInterface[]> {
        return this.getRelatedPartyTypes$().pipe(
            map((relatedPartyTypes) => relatedPartyTypes.filter((party) => party.singleAllowed))
        );
    }
}
