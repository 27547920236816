import { ApiClaimObjectInterface } from '../interfaces/api.claim-object.interface';
import { ClaimObjectInterface } from '../interfaces/claim-objects.interface';

export function mapClaimObjects(response: ApiClaimObjectInterface[]): ClaimObjectInterface[] {
    return response.map((claimObject) => ({
        claimObjectId: claimObject.claim_object_id,
        claimObjectDescription: claimObject.claim_object_description,
        sustainedDamageSequenceNumber: claimObject.sustained_damage_sequence_number,
    }));
}
