import { mapGenderCode, mapRelationCodeResponse } from '../../../core/mappers';
import { ApiProductRegularDriverResponse, ProductRegularDriverResponseInterface } from '../../interfaces';
import { mapValidityStatusCodeResponse } from './validity-status-code.mapper';

export function mapProductRegularDriverResponse(
    response: ApiProductRegularDriverResponse
): ProductRegularDriverResponseInterface {
    if (!response) {
        return null;
    }

    return {
        city: response.address?.city,
        streetName: response.address?.street_name,
        birthdate: response.birthdate,
        civicNumber: response.civic_number,
        civicNumberSuffix: response.civic_number_suffix,
        countryDescription: response.country_description,
        countryId: response.country_id,
        dateOfBirthDescription: response.date_of_birth_description,
        emailAddress: response.email_address,
        endDate: response.end_date,
        genderCode: mapGenderCode(response.gender_code),
        genderDescription: response.gender_description,
        initials: response.initials,
        middleNameInitials: response.middle_name_initials,
        postalCode: response.postal_code,
        relationCode: mapRelationCodeResponse(response.relation_code),
        relationDescription: response.relation_description,
        startDate: response.start_date,
        surname: response.surname,
        validityStatusCode: mapValidityStatusCodeResponse(response.validity_status_code),
    };
}
