import { InvoiceDetailsInterface, InvoiceInterface, OpenInvoiceInterface } from '../interfaces';

export interface Invoice {
    invoice: InvoiceState;
}

export interface InvoiceState {
    invoices: InvoiceInterface[];
    invoiceDetails: InvoiceDetailsInterface[];
    years: number[];
    openInvoice: OpenInvoiceInterface;
    selectedYear: number;
}

export const INITIAL_INVOICE_STATE: InvoiceState = {
    invoices: [],
    invoiceDetails: [],
    years: null,
    openInvoice: null,
    selectedYear: null,
};

export const INVOICE_FEATURE = 'invoice';
