import { ApiMailQuestionRequest, ComplaintRequestInterface } from '../interfaces';

const COMPLAINT_SUBJECT_CODE = 'KLAC';
const COMPLAINT_CATEGORY_CODE = 'KLAC';

export function mapComplaintRequest(request: ComplaintRequestInterface): ApiMailQuestionRequest {
    return {
        mail_question: {
            email_address: request.email,
        },
        mail_question_input: {
            category_code: COMPLAINT_CATEGORY_CODE,
            subject_code: COMPLAINT_SUBJECT_CODE,
            question: request.complaint,
        },
    };
}
