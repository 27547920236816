import { mapSequenceNumber } from '../../../core';
import { ApiProductRelatedVehicleResponse, ProductRelatedVehicleResponseInterface } from '../../interfaces';

export function mapProductRelatedVehicleResponse(
    response: ApiProductRelatedVehicleResponse
): ProductRelatedVehicleResponseInterface | null {
    if (!response) {
        return null;
    }

    return {
        relatedVehicleSequenceNumber: mapSequenceNumber(response.related_vehicle_purchased_product_sequence_number),
        licensePlate: response.license_plate,
        vehicleReplacementTypeCode: response.vehicle_replacement_type_code,
        vehicleReplacementTypeDescription: response.vehicle_replacement_type_description,
    };
}
