import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

/**
 * Container for the bottom action buttons in a dialog.
 * Stays fixed to the bottom when scrolling.
 */
@Directive({
    selector: '[modal-actions], modal-actions, [insModalActions]',
})
export class NewModalActionsDirective implements AfterViewInit {
    @HostBinding('class') public _class = 'modal__actions';
    @Input() public alignment: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';

    constructor(private _elementRef: ElementRef) {}

    public ngAfterViewInit(): void {
        const root = this._elementRef.nativeElement as HTMLElement;
        root.classList.add(this.alignment);
    }
}
