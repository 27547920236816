import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ClaimNotificationInterface } from '../interfaces/claim-notification.interface';
import { ClaimNotificationApiService } from '../services/claim-notification.api.service';
import { ClaimNotificationEventService } from '../services/claim-notification.event.service';
import {
    ClaimNotificationActionEnum,
    GetClaimNotificationAction,
    GetClaimNotificationErrorAction,
    GetClaimNotificationSuccessAction,
    SaveClaimNotificationAction,
    SaveClaimNotificationErrorAction,
    SaveClaimNotificationSuccessAction,
} from './claim-notification.actions';

@Injectable()
export class ClaimNotificationEffects {
    // Get Claim Notification
    public getClaimNotification$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimNotificationActionEnum.GetClaimNotification),
            map((action: GetClaimNotificationAction) => action.payload),
            switchMap((payload) =>
                this.claimNotificationApiService.getClaimNotification$(payload).pipe(
                    map((response: ClaimNotificationInterface) => new GetClaimNotificationSuccessAction({ response })),
                    catchError((errors) => of(new GetClaimNotificationErrorAction({ errors })))
                )
            )
        );
    });

    public getClaimNotificationSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimNotificationActionEnum.GetClaimNotificationSuccess),
                tap(() => this.claimNotificationEventService.onClaimNotificationSuccess())
            );
        },
        { dispatch: false }
    );

    public getClaimNotificationError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimNotificationActionEnum.GetClaimNotificationError),
                map((action: GetClaimNotificationErrorAction) =>
                    this.claimNotificationEventService.onClaimNotificationError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    // Save Claim Notification
    public saveClaimNotification$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(ClaimNotificationActionEnum.SaveClaimNotification),
            map((action: SaveClaimNotificationAction) => action.payload),
            switchMap((payload) =>
                this.claimNotificationApiService.saveClaimNotification$(payload).pipe(
                    map((response: ClaimNotificationInterface) => new SaveClaimNotificationSuccessAction({ response })),
                    catchError((errors) => of(new SaveClaimNotificationErrorAction({ errors })))
                )
            )
        );
    });

    public saveClaimNotificationSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimNotificationActionEnum.SaveClaimNotificationSuccess),
                tap(() => this.claimNotificationEventService.onSaveClaimNotificationSuccess())
            );
        },
        { dispatch: false }
    );

    public saveClaimNotificationError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ClaimNotificationActionEnum.SaveClaimNotificationError),
                map((action: SaveClaimNotificationErrorAction) =>
                    this.claimNotificationEventService.onSaveClaimNotificationError(action.payload.errors)
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private claimNotificationApiService: ClaimNotificationApiService,
        private claimNotificationEventService: ClaimNotificationEventService
    ) {}
}
