import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EmailPremiumCalculation } from './calculation.actions';
import { CalculationEventService } from './calculation.event.service';
import { State } from './calculation.reducer';

@Injectable({
    providedIn: 'root',
})
export class CalculationActionService {
    constructor(private store$: Store<State>, private calculationEventService: CalculationEventService) {}

    public emailPremiumCalculation$(request: { url: string; email: string; payload: any }): Observable<void> {
        this.store$.dispatch(new EmailPremiumCalculation({ ...request }));
        return this.calculationEventService.emailPremiumCalculation$();
    }
}
