/* eslint-disable @angular-eslint/component-selector */
import { AnimationEvent } from '@angular/animations';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    Inject,
    Optional,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { newModalAnimations } from './new-modal-animations';
import { NewModalConfig } from './new-modal-config';

/**
 * Throws an exception for the case when a ComponentPortal is
 * attached to a DomPortalOutlet without an origin.
 * @docs-private
 */
export function throwModalContentAlreadyAttachedError(): ErrorConstructor {
    throw Error('Attempting to attach dialog content after content is already attached');
}

/**
 * Internal component that wraps user-provided dialog content.
 * Animation is based on https://material.io/guidelines/motion/choreography.html.
 * @docs-private
 */
@Component({
    selector: 'out-new-modal-container',
    templateUrl: 'new-modal-container.html',
    styleUrls: ['new-modal-container.scss'],
    encapsulation: ViewEncapsulation.None,
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [newModalAnimations.modalContainer],
    host: {
        class: 'temp-modal',
        tabindex: '-1',
        'aria-modal': 'true',
        '[attr.id]': '_id',
        '[attr.role]': '_config.role',
        '[attr.aria-labelledby]': '_config.ariaLabel ? null : _ariaLabelledBy',
        '[attr.aria-label]': '_config.ariaLabel',
        '[attr.aria-describedby]': '_config.ariaDescribedBy || null',
        '[@modalContainer]': '_state',
        '(@modalContainer.start)': '_onAnimationStart($event)',
        '(@modalContainer.done)': '_onAnimationDone($event)',
    },
})
export class NewModalContainer extends BasePortalOutlet {
    /** The portal outlet inside of this container into which the dialog content will be loaded. */
    @ViewChild(CdkPortalOutlet, { static: true }) public _portalOutlet: CdkPortalOutlet;
    /** State of the dialog animation. */
    public _state: 'void' | 'enter' | 'exit' = 'enter';
    /** Emits when an animation state changes. */
    public _animationStateChanged = new EventEmitter<AnimationEvent>();
    /** ID of the element that should be considered as the dialog's label. */
    public _ariaLabelledBy: string | null;
    /** ID for the container DOM element. */
    public _id: string;
    private _document: Document;
    /** The class that traps and manages focus within the dialog. */
    private _focusTrap: FocusTrap;
    /** Element that was focused before the dialog was opened. Save this to restore upon close. */
    private _elementFocusedBeforeDialogWasOpened: HTMLElement | null = null;

    constructor(
        private _elementRef: ElementRef,
        private _focusTrapFactory: FocusTrapFactory,
        private _changeDetectorRef: ChangeDetectorRef,
        @Optional() @Inject(DOCUMENT) document: any,
        /** The dialog configuration. */
        public _config: NewModalConfig
    ) {
        super();
        this._ariaLabelledBy = _config.ariaLabelledBy || null;
        this._document = document;
    }

    /**
     * Attach a ComponentPortal as content to this dialog container.
     * @param portal Portal to be attached as the dialog content.
     */
    public attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        if (this._portalOutlet.hasAttached()) {
            throwModalContentAlreadyAttachedError();
        }

        this._setupFocusTrap();
        return this._portalOutlet.attachComponentPortal(portal);
    }

    /**
     * Attach a TemplatePortal as content to this dialog container.
     * @param portal Portal to be attached as the dialog content.
     */
    public attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        if (this._portalOutlet.hasAttached()) {
            throwModalContentAlreadyAttachedError();
        }

        this._setupFocusTrap();
        return this._portalOutlet.attachTemplatePortal(portal);
    }

    /**
     * Attaches a DOM portal to the dialog container.
     * @param portal Portal to be attached.
     * @deprecated To be turned into a method.
     * @breaking-change 10.0.0
     */
    public attachDomPortal = (portal: any): ErrorConstructor | void => {
        if (this._portalOutlet.hasAttached()) {
            throwModalContentAlreadyAttachedError();
        }

        this._setupFocusTrap();
        return this._portalOutlet.attachDomPortal(portal);
    };

    /**
     * Moves focus back into the dialog if it was moved out.
     * Is this actualy use? Stumbled upon while cleaning linting
     * Set to public to be on the save side...
     */
    public _recaptureFocus(): void {
        if (!this._containsFocus()) {
            const hasFocusWasTrapped = this._focusTrap.focusInitialElement();

            if (!hasFocusWasTrapped) {
                this._elementRef.nativeElement.focus();
            }
        }
    }

    /** Callback, invoked whenever an animation on the host completes. */
    public _onAnimationDone(event: AnimationEvent): void {
        if (event.toState === 'enter') {
            this._trapFocus();
        } else if (event.toState === 'exit') {
            this._restoreFocus();
        }

        this._animationStateChanged.emit(event);
    }

    /** Callback, invoked when an animation on the host starts. */
    public _onAnimationStart(event: AnimationEvent): void {
        this._animationStateChanged.emit(event);
    }

    /** Starts the dialog exit animation. */
    public _startExitAnimation(): void {
        this._state = 'exit';

        // Mark the container for check so it can react if the
        // view container is using OnPush change detection.
        this._changeDetectorRef.markForCheck();
    }

    /** Moves the focus inside the focus trap. */
    private _trapFocus(): void {
        // If we were to attempt to focus immediately, then the content of the dialog would not yet be
        // ready in instances where change detection has to run first. To deal with this, we simply
        // wait for the microtask queue to be empty.
        if (this._config.autoFocus) {
            this._focusTrap.focusInitialElementWhenReady();
        } else if (!this._containsFocus()) {
            // Otherwise ensure that focus is on the dialog container. It's possible that a different
            // component tried to move focus while the open animation was running. See:
            // https://github.com/angular/components/issues/16215. Note that we only want to do this
            // if the focus isn't inside the dialog already, because it's possible that the consumer
            // turned off `autoFocus` in order to move focus themselves.
            this._elementRef.nativeElement.focus();
        }
    }

    /** Restores focus to the element that was focused before the dialog opened. */
    private _restoreFocus(): void {
        const toFocus = this._elementFocusedBeforeDialogWasOpened;

        // We need the extra check, because IE can set the `activeElement` to null in some cases.
        if (this._config.restoreFocus && toFocus && typeof toFocus.focus === 'function') {
            const activeElement = this._document.activeElement;
            const element = this._elementRef.nativeElement;

            // Make sure that focus is still inside the dialog or is on the body (usually because a
            // non-focusable element like the backdrop was clicked) before moving it. It's possible that
            // the consumer moved it themselves before the animation was done, in which case we shouldn't
            // do anything.
            if (
                !activeElement ||
                activeElement === this._document.body ||
                activeElement === element ||
                element.contains(activeElement)
            ) {
                toFocus.focus();
            }
        }

        if (this._focusTrap) {
            this._focusTrap.destroy();
        }
    }

    /**
     * Sets up the focus trand and saves a reference to the
     * element that was focused before the dialog was opened.
     */
    private _setupFocusTrap(): void {
        if (!this._focusTrap) {
            this._focusTrap = this._focusTrapFactory.create(this._elementRef.nativeElement);
        }

        if (this._document) {
            this._elementFocusedBeforeDialogWasOpened = this._document.activeElement as HTMLElement;

            // Note that there is no focus method when rendering on the server.
            if (this._elementRef.nativeElement.focus) {
                // Move focus onto the dialog immediately in order to prevent the user from accidentally
                // opening multiple dialogs at the same time. Needs to be async, because the element
                // may not be focusable immediately.
                Promise.resolve().then(() => this._elementRef.nativeElement.focus());
            }
        }
    }

    /** Returns whether focus is inside the dialog. */
    private _containsFocus(): Element {
        const element = this._elementRef.nativeElement;
        const activeElement = this._document.activeElement;
        return element === activeElement || element.contains(activeElement);
    }
}
