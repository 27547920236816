import { ChatbotConfigurationInterface } from '../interfaces';
import { ApiChatbotRequestInterface } from '../interfaces/api';

export function mapFeedbackRequest(
    configuration: ChatbotConfigurationInterface,
    sessionId: string,
    interactionId: string,
    score: number,
    feedback?: string,
    q?: string
): Partial<ApiChatbotRequestInterface> {
    const request: Partial<ApiChatbotRequestInterface> = {
        customerKey: configuration.customerKey,
        projectKey: configuration.projectKey,
        apiKey: configuration.apiKey,
        classificationId: 1,
        culture: configuration.culture,
        'dim.VAStyle': 'VA',
        requestContext: 'VA',
        originInteractionId: interactionId,
        score: score,
        label: score,
        comment: feedback || null,
        q,
    };
    if (sessionId) {
        request['session.id'] = sessionId;
    }
    return request;
}
