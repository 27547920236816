import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION } from '../configuration';
import { ApiVersionEnum } from '../enums';
import { ApiConfigurationInterface } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiVersionService {
    constructor(@Inject(API_CONFIGURATION) private configuration: ApiConfigurationInterface) {}

    public isClassic(): boolean {
        return this.configuration.version === ApiVersionEnum.Classic;
    }
}
