import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ApiGetInternationalMotorInsurancePayloadInterface } from '../interfaces/api-get-international-motor-insurance-payload.interface';
import { ApiGetNotInsuredInternationalMotorInsurancePayloadInterface } from '../interfaces/api-get-not-insured-international-motor-insurance-payload.interface';
import { ApiPdfInternationalMotorInsuranceCardInterface } from '../interfaces/api-pdf-international-motor-insurance-card.interface';
import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces/international-motor-insurance-card.interface';
import { pdfInternationalMotorInsuranceCardMapper } from '../mappers/international-motor-insurance-card.mapper';

const RESOURCES = {
    EMAIL_INSURANCE_CARD: 'en-gb/insurance-coverage/provide/email-international_motor_insurance_card',
    EMAIL_NOT_INSURED_INSURANCE_CARD: 'en-gb/insurance-coverage/provide/email-groenekaart-nietverzekerd',
    PDF_INSURANCE_CARD: 'en-gb/insurance-coverage/provide/pdf-international_motor_insurance_card',
    PDF_NOT_INSURED_INSURANCE_CARD:
        'en-gb/insurance-coverage/provide/pdf-international_motor_insurance_card-not_insured',
};

@Injectable({
    providedIn: 'root',
})
export class InternationalMotorInsuranceCardApiService {
    constructor(private readonly apiService: ApiService) {}

    public getEmailInternationalMotorInsuranceCard$(productSequenceNumber: number): Observable<string> {
        const payload: ApiGetInternationalMotorInsurancePayloadInterface = {
            purchased_product: {
                purchased_product_sequence_number: productSequenceNumber,
            },
        };

        return this.apiService.post$<string>(RESOURCES.EMAIL_INSURANCE_CARD, payload);
    }

    public getEmailNotInsuredInternationalMotorInsuranceCard$(
        productSequenceNumber: number,
        licensePlate: string,
        caravanBrand: string
    ): Observable<string> {
        const payload: ApiGetNotInsuredInternationalMotorInsurancePayloadInterface = {
            purchased_product: {
                purchased_product_sequence_number: productSequenceNumber,
            },
            license_plate: licensePlate,
            caravan_brand: caravanBrand,
        };

        return this.apiService.post$<string>(RESOURCES.EMAIL_NOT_INSURED_INSURANCE_CARD, payload);
    }

    public getPdfInternationalMotorInsuranceCard$(
        productSequenceNumber: number
    ): Observable<PdfInternationalMotorInsuranceCardInterface> {
        const payload: ApiGetInternationalMotorInsurancePayloadInterface = {
            purchased_product: {
                purchased_product_sequence_number: productSequenceNumber,
            },
        };

        return this.apiService
            .post$<ApiPdfInternationalMotorInsuranceCardInterface>(RESOURCES.PDF_INSURANCE_CARD, payload)
            .pipe(
                map((response: ApiPdfInternationalMotorInsuranceCardInterface) => {
                    return pdfInternationalMotorInsuranceCardMapper(response);
                })
            );
    }

    public getPdfNotInsuredInternationalMotorInsuranceCard$(
        productSequenceNumber: number,
        licensePlate: string,
        caravanBrand: string
    ): Observable<PdfInternationalMotorInsuranceCardInterface> {
        const payload: ApiGetNotInsuredInternationalMotorInsurancePayloadInterface = {
            purchased_product: {
                purchased_product_sequence_number: productSequenceNumber,
            },
            license_plate: licensePlate,
            caravan_brand: caravanBrand,
        };

        return this.apiService
            .post$<ApiPdfInternationalMotorInsuranceCardInterface>(RESOURCES.PDF_NOT_INSURED_INSURANCE_CARD, payload)
            .pipe(
                map((response: ApiPdfInternationalMotorInsuranceCardInterface) => {
                    return pdfInternationalMotorInsuranceCardMapper(response);
                })
            );
    }
}
