import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ClaimCategoriesActions from '../store/claim-categories.actions';
import { ClaimCategoriesState } from '../store/claim-categories.state';
import { ClaimCategoriesEventService } from './claim-categories.event.service';

@Injectable({ providedIn: 'root' })
export class ClaimCategoriesActionService {
    constructor(
        private store$: Store<ClaimCategoriesState>,
        private claimCategoriesEventService: ClaimCategoriesEventService
    ) {}

    public allClaimCategories$(): Observable<void> {
        this.store$.dispatch(ClaimCategoriesActions.allClaimCategories());
        return this.claimCategoriesEventService.allClaimCategories$();
    }

    public resetClaimCategories(): void {
        this.store$.dispatch(ClaimCategoriesActions.resetClaimCategories());
    }
}
