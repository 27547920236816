import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    GetClaimCompensationRequestInterface,
    GetClaimCoverageRequestInterface,
    SaveClaimCoverageRequestInterface,
} from '../interfaces';
import * as ClaimCoverageActions from '../store/claim-coverage.actions';
import { ClaimCoverageState } from '../store/claim-coverage.state';
import { ClaimCoverageEventService } from './claim-coverage.event.service';

@Injectable({
    providedIn: 'root',
})
export class ClaimCoverageActionService {
    constructor(
        private store$: Store<ClaimCoverageState>,
        private claimCoverageEventService: ClaimCoverageEventService
    ) {}

    public getClaimCompensation$(request: GetClaimCompensationRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimCoverageActions.getClaimCompensation({ request }));
        return this.claimCoverageEventService.getClaimCompensation$();
    }

    public getClaimCoverage$(request: GetClaimCoverageRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimCoverageActions.getClaimCoverage({ request }));
        return this.claimCoverageEventService.getClaimCoverage$();
    }

    public saveClaimCoverage$(request: SaveClaimCoverageRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimCoverageActions.saveClaimCoverage({ request }));
        return this.claimCoverageEventService.saveClaimCoverage$();
    }

    public resetClaimCoverage(): void {
        this.store$.dispatch(ClaimCoverageActions.resetClaimCoverage());
    }
}
