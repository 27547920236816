import { createReducer, on } from '@ngrx/store';
import * as countriesActions from './countries.actions';
import { CountriesState, INITIAL_COUNTRIES_STATE } from './countries.state';

export const countriesReducer = createReducer(
    INITIAL_COUNTRIES_STATE,
    on(
        countriesActions.getCountriesSuccess,
        (state, { countries }): CountriesState => ({
            ...state,
            countries,
        })
    ),
    on(
        countriesActions.clearCountries,
        (state): CountriesState => ({
            ...state,
            countries: null,
        })
    )
);
