import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClaimCoverageInterface } from '../interfaces';
import { ClaimCompensationInterface } from '../interfaces/claim-compensation.interface';
import { ClaimCoverageSelectors } from '../store/claim-coverage.selectors';
import { ClaimCoverageState } from '../store/claim-coverage.state';

@Injectable({
    providedIn: 'root',
})
export class ClaimCoverageDataService {
    constructor(private store$: Store<ClaimCoverageState>) {}

    public getState$(): Observable<ClaimCoverageState> {
        return this.store$.select(ClaimCoverageSelectors.getState);
    }

    public getClaimCompensation$(): Observable<ClaimCompensationInterface> {
        return this.store$.select(ClaimCoverageSelectors.getClaimCompensation);
    }

    public getClaimCoverage$(): Observable<ClaimCoverageInterface> {
        return this.store$.select(ClaimCoverageSelectors.getClaimCoverage);
    }
}
