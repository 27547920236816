import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';
import { 
    ApiPdfInternationalMotorInsuranceCardInterface
} from '../interfaces/api-pdf-international-motor-insurance-card.interface';

export function pdfInternationalMotorInsuranceCardMapper(
    response: ApiPdfInternationalMotorInsuranceCardInterface
): PdfInternationalMotorInsuranceCardInterface {
    return {
        pdf: response.pdf,
        type: response.type,
        identifyingName: response.identifying_name,
    };
}
