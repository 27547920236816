import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ApiClaimStepsResponseInterface, ClaimStepResponseInterface, ClaimStepsRequestInterface } from '../interfaces';
import { mapClaimStepsRequest, mapClaimStepsResponse } from '../mappers';

const RESOURCES = {
    LOAD_CLAIM_STEPS: 'en-gb/damage-damage_report/request/next_steps',
};

@Injectable({
    providedIn: 'root',
})
export class ClaimStepsApiService {
    constructor(private apiService: ApiService) {}

    public loadClaimSteps$(request: ClaimStepsRequestInterface): Observable<ClaimStepResponseInterface[]> {
        return this.apiService
            .post$<ApiClaimStepsResponseInterface>(RESOURCES.LOAD_CLAIM_STEPS, mapClaimStepsRequest(request))
            .pipe(map(mapClaimStepsResponse));
    }
}
