import { mapSuccessIndication } from '../../core';
import { CreateBankDetailsInterface, ApiCreateBankDetailsRequest } from '../interfaces';

export function mapCreateBankDetailsRequest(request: CreateBankDetailsInterface): ApiCreateBankDetailsRequest {
    return {
        bank_mandate: {
            account_holder_name: request.accountHolderName,
            iban_bank_account_number: request.ibanAccountNumber,
            direct_debit_agreement_indication: mapSuccessIndication(request.directDebitAgreementIndication),
        },
    };
}
