import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RequestResponse } from '../../../core';
import { SalesStorageService } from '../../services/sales-storage.service';
import * as coverageActions from '../coverage/coverage.actions';
import { CoverageService } from '../coverage/coverage.service';
import * as customerActions from '../customer/customer.actions';
import { CustomerService } from '../customer/customer.service';
import { ProgressService } from '../progress/progress.service';
import * as insuranceActions from './insurance.actions';
import { InsuranceService } from './insurance.service';

@Injectable()
export class InsuranceEffects {
    public requestInsurance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(insuranceActions.INSURE_CAR, insuranceActions.INSURE_BICYCLE),
            map((action: insuranceActions.InsureCar | insuranceActions.InsureBicycle) => action.payload.indications),
            withLatestFrom(
                this.coverageService.coverages$,
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.progressService.productId$
            ),
            switchMap(([indications, coverages, insurance, customer, productId]) => {
                const payload = {
                    coverages,
                    insurance: {
                        ...insurance,
                        ...indications,
                    },
                    customer,
                    productId,
                };

                return this.insuranceService.requestInsurance(payload).pipe(
                    map((response) => new insuranceActions.InsuranceSuccess({ ...payload, ...response.data })),
                    tap(() => this.storageService.clear()),
                    catchError((errors) => of(new insuranceActions.InsuranceError({ errors })))
                );
            })
        );
    });

    public togglePaymentPeriodEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(insuranceActions.TOGGLE_PAYMENT_PERIOD),
            withLatestFrom(this.coverageService.coverages$, this.progressService.productId$),
            map(([_, coverages, productId]) => {
                return new coverageActions.UpdateReceipt({
                    productId: productId,
                    coverage: coverages.map((coverage) => coverage.coverage_id),
                });
            })
        );
    });

    public requestExtraInsurance$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(insuranceActions.INSURE_EXTRA_CAR, insuranceActions.INSURE_EXTRA_BICYCLE),
            map((action: insuranceActions.InsureExtraCar | insuranceActions.InsureExtraBicycle) => {
                return action.payload.indications;
            }),
            withLatestFrom(
                this.coverageService.coverages$,
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.progressService.productId$
            ),
            switchMap(([indications, coverages, insurance, customer, productId]) => {
                const payload = {
                    coverages,
                    insurance: {
                        ...insurance,
                        ...indications,
                    },
                    customer,
                    productId,
                };

                return this.insuranceService.requestExtraInsurance(payload).pipe(
                    map((response: RequestResponse) => {
                        return new insuranceActions.InsuranceSuccess({ ...payload, ...response.data });
                    }),
                    tap(() => this.storageService.clear()),
                    catchError((errors) => of(new insuranceActions.InsuranceError({ errors })))
                );
            })
        );
    });

    public validateEligibility$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                coverageActions.CHECK_COVERAGE,
                insuranceActions.SAVE_CAR_INSURANCE_DATA,
                insuranceActions.SAVE_BICYCLE_INSURANCE_DATA,
                insuranceActions.SAVE_HOME_INSURANCE_DATA,
                insuranceActions.SAVE_CARAVAN_INSURANCE_DATA,
                customerActions.SAVE_PERSONAL_DATA
            ),
            map(
                (
                    action:
                        | insuranceActions.SaveCarInsuranceData
                        | insuranceActions.SaveBicycleInsuranceData
                        | insuranceActions.SaveHomeInsuranceData
                        | insuranceActions.SaveCaravanInsuranceData
                ) => {
                    return action.payload;
                }
            ),
            withLatestFrom(
                this.coverageService.coverages$,
                this.insuranceService.currentInsuranceData$,
                this.customerService.currentCustomerData$,
                this.customerService.isReturningCustomer$,
                this.progressService.productId$,
                this.progressService.index$
            ),
            switchMap(([input, coverages, insurance, customer, isReturningCustomer, productId, index]) => {
                const validationStepCode: string = input.validationStepCode || 'POST';

                const payload = {
                    coverages,
                    insurance: { ...insurance, ...input.insurance },
                    customer: { ...customer, ...input.customer },
                    productId,
                    validationStepCode,
                    index,
                    isReturningCustomer,
                };

                return this.insuranceService.validateEligibility(payload).pipe(
                    map(() => new insuranceActions.ValidateEligibilitySuccess(payload)),
                    tap(() => this.storageService.save(payload.productId, payload.insurance, payload.customer)),
                    catchError((errors) => of(new insuranceActions.ValidateEligibilityError({ errors })))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private insuranceService: InsuranceService,
        private progressService: ProgressService,
        private customerService: CustomerService,
        private coverageService: CoverageService,
        private storageService: SalesStorageService
    ) {}
}
