import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthenticationStatusEnum } from '../enums';
import { CredentialsInterface } from '../interfaces';
import { Authentication, AuthenticationSelectors, AuthenticationState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationDataService {
    constructor(private store$: Store<Authentication>) {}

    public getState$(): Observable<AuthenticationState> {
        return this.store$.select(AuthenticationSelectors.getState);
    }

    public getStatus$(): Observable<AuthenticationStatusEnum> {
        return this.store$.select(AuthenticationSelectors.getStatus);
    }

    public isLoggedIn$(): Observable<boolean> {
        return this.store$.select(AuthenticationSelectors.isLoggedIn);
    }

    public sessionExpired$(): Observable<boolean> {
        return this.store$.select(AuthenticationSelectors.sessionExpired);
    }

    public initializationPending$(): Observable<boolean> {
        return this.store$.select(AuthenticationSelectors.initializationPending);
    }

    public loginPending$(): Observable<boolean> {
        return this.store$.select(AuthenticationSelectors.loginPending);
    }

    public logoutPending$(): Observable<boolean> {
        return this.store$.select(AuthenticationSelectors.logoutPending);
    }

    public getCredentials$(): Observable<CredentialsInterface> {
        return this.store$.select(AuthenticationSelectors.getCredentials);
    }
}
