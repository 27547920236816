import { ChatbotConfigurationInterface } from '../interfaces';
import { ApiChatbotRequestInterface } from '../interfaces/api';

export function mapFaqListRequest(
    configuration: ChatbotConfigurationInterface,
    classificationId: number,
    classificationName: string,
    step: number,
    sessionId?: string,
    sessionMode?: string
): Partial<ApiChatbotRequestInterface> {
    const request: Partial<ApiChatbotRequestInterface> = {
        customerKey: configuration.customerKey,
        projectKey: configuration.projectKey,
        apiKey: configuration.apiKey,
        culture: configuration.culture,
        classificationId: classificationId,
        minFaqs: 3,
        maxFaqs: 3,
    };
    if (sessionId) {
        request['session.id'] = sessionId;
    }
    if (sessionMode) {
        request['session.mode'] = sessionMode;
    }
    return request;
}
