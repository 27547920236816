import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { FetchAddressRequestInterface, FetchAddressResponseInterface } from '../interfaces';

export const fetchAddress = createAction('[Address] Fetch Address', props<{ request: FetchAddressRequestInterface }>());
export const fetchAddressSuccess = createAction(
    '[Address] Fetch Address Success',
    props<{ address: FetchAddressResponseInterface }>()
);
export const fetchAddressError = createAction('[Address] Fetch Address Error', props<{ errors: ErrorInterface[] }>());
export const clearAddress = createAction('[Address] Clear address');
