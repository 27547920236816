import { Action } from '@ngrx/store';
import { type } from '../../../core';
import { StackDiscountDescription } from '../../interfaces';

export const SAVE_STACK_DISCOUNT = type('[Stack Discount] Save Stack Discount');

export class SaveStackDiscount implements Action {
    public readonly type = SAVE_STACK_DISCOUNT;

    constructor(public payload: { stack_discount: StackDiscountDescription }) {}
}

export type StackDiscountActionUnion = SaveStackDiscount;
