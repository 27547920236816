import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    InternationalMotorInsuranceCardState,
    INTERNATIONAL_MOTOR_INSURANCE_CARD_FEATURE,
} from './international-motor-insurance-card.state';

export class InternationalMotorInsuranceCardSelectors {
    private static getInternationalMotorInsuranceCardState =
        createFeatureSelector<InternationalMotorInsuranceCardState>(
            INTERNATIONAL_MOTOR_INSURANCE_CARD_FEATURE
        );

    public static getState = createSelector(
        InternationalMotorInsuranceCardSelectors.getInternationalMotorInsuranceCardState,
        (state: InternationalMotorInsuranceCardState) => state
    );

    public static getPdf = createSelector(
        InternationalMotorInsuranceCardSelectors.getInternationalMotorInsuranceCardState,
        (state: InternationalMotorInsuranceCardState) => state.pdfInternationalMotorInsuranceCard
    );

    public static getEmail = createSelector(
        InternationalMotorInsuranceCardSelectors.getInternationalMotorInsuranceCardState,
        (state: InternationalMotorInsuranceCardState) => state.emailInternationalMotorInsuranceCard
    );
}
