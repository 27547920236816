import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ScenarioInterface } from '../../interfaces';
import * as fromMyZone from '../../my-zone';
import { getAllScenarios, getScenarioEntities, getScenarioIds } from './scenarios.selectors';

/* @deprecated - Use new ScenarioDataService */
@Injectable({
    providedIn: 'root',
})
export class ScenariosSelectorService {
    public getScenarioEntities$: Observable<{ [id: string]: ScenarioInterface }> = this.store$.
        select((getScenarioEntities)
        );

    public getScenarioIds$: Observable<string[]> = this.store$.select((getScenarioIds));
    public getAllScenarios$: Observable<ScenarioInterface[]> = this.store$.select((getAllScenarios));

    constructor(private store$: Store<fromMyZone.State>) {}
}
