import { NgModule } from '@angular/core';
import { ActiveModal } from './active-modal';
import { ModalBackdropComponent } from './modal-backdrop';
import { ModalClickComponent } from './modal-click';
import { ModalContainerComponent } from './modal-container';
import { ModalComponent } from './modal.component';
import { RouteModalComponent } from './route-modal.component';

@NgModule({
    declarations: [
        ModalBackdropComponent,
        ModalContainerComponent,
        ModalClickComponent,
        ModalComponent,
        RouteModalComponent,
    ],
    exports: [
        ModalBackdropComponent,
        ModalContainerComponent,
        ModalClickComponent,
        ModalComponent,
        RouteModalComponent,
    ],
    providers: [ActiveModal],
})
export class ModalModule {}
