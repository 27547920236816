import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../core';
import { RelationInterface } from '../interfaces';

const RELATION_KEY = 'relation';

@Injectable({
    providedIn: 'root',
})
export class RelationStorageService {
    private readonly storageService = inject(StorageService);

    public clear(): void {
        const obs$ = this.storageService.remove$(RELATION_KEY);
    }

    public setRelation(session: RelationInterface): void {
        const obs$ = this.storageService.set$(RELATION_KEY, session);
    }

    public getSession$(): Observable<RelationInterface> {
        return this.storageService.get$<RelationInterface>(RELATION_KEY);
    }
}
