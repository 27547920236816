import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CountryInterface } from '../interfaces';
import { CountriesSelectors, CountriesState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class CountriesDataService {
    constructor(private store$: Store<CountriesState>) {}

    public getCountries$(): Observable<CountryInterface[]> {
        return this.store$.select(CountriesSelectors.getCountries);
    }
}
