import { mapCoverageIdResponse } from '../../insurance-policy';
import {
    ApiClaimCoverageDeductibleExcessResponseInterface,
    ClaimCoverageDeductibleExcessInterface,
} from '../interfaces';

export function mapBaseDeductibleExcessResponse(
    response: ApiClaimCoverageDeductibleExcessResponseInterface[]
): ClaimCoverageDeductibleExcessInterface[] {
    if (!response) {
        return [];
    }

    return response.map((item) => {
        return {
            baseDeductibleExcessAmount: item.base_deductible_excess_amount,
            baseDeductibleExcessTypeCode: item.base_deductible_excess_type_code,
            baseDeductibleExcessTypeDescription: item.base_deductible_excess_type_description,
            deductibleExcessNoteCode: item.deductible_excess_note_code,
            deductibleExcessNoteDescription: item.deductible_excess_note_description,
            coverageDescription: item.coverage_description,
            coverageId: mapCoverageIdResponse(item.coverage_id),
        };
    });
}
