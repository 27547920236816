import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService } from '../../core';
import {
    GetAppointmentsReponseInterface,
    ApiGetAppointmentsReponseInterface,
    GetAppointmentsRequestInterface,
} from '../interfaces/get-appointment.interface';
import {
    ApiGetLocationsReponseInterface,
    GetLocationsReponseInterface,
    GetLocationsRequestInterface,
} from '../interfaces/get-locations.interface';
import {
    ApiGetTimeslotsReponseInterface,
    GetTimeslotsReponseInterface,
    GetTimeslotsRequestInterface,
} from '../interfaces/get-timeslots.interface';
import {
    ApiSaveAppointmentReponseInterface,
    SaveAppointmentReponseInterface,
    SaveAppointmentRequestInterface,
} from '../interfaces/save-appointment.interface';
import { mapGetAppointmentsRequest, mapGetAppointmentsResponse } from '../mappers/get-appointment.mapper';
import { mapGetLocationsRequest, mapGetLocationsResponse } from '../mappers/get-locations.mapper';
import { mapGetTimeslotsRequest, mapGetTimeslotsResponse } from '../mappers/get-timeslots.mapper';
import { mapSaveAppointmentRequest } from '../mappers/save-appointment.mapper';

@Injectable({ providedIn: 'root' })
export class ClaimAppointmentApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getAppointments$(request: GetAppointmentsRequestInterface): Observable<GetAppointmentsReponseInterface> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.GetAppointments);
        const payload = mapGetAppointmentsRequest(request);

        return this.apiService
            .post$<ApiGetAppointmentsReponseInterface>(resource, payload)
            .pipe(map(mapGetAppointmentsResponse));
    }

    public saveAppointment$(request: SaveAppointmentRequestInterface): Observable<SaveAppointmentReponseInterface> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.SaveAppointment);
        const payload = mapSaveAppointmentRequest(request);
        const { caseSequenceNumber } = request.case;

        return this.apiService
            .post$<ApiSaveAppointmentReponseInterface>(resource, payload)
            .pipe(switchMap(() => this.getAppointments$({ caseSequenceNumber })));
    }

    public getLocations$(request: GetLocationsRequestInterface): Observable<GetLocationsReponseInterface> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.GetLocations);
        const payload = mapGetLocationsRequest(request);

        return this.apiService
            .post$<ApiGetLocationsReponseInterface>(resource, payload)
            .pipe(map(mapGetLocationsResponse));
    }

    public getTimeslots$(request: GetTimeslotsRequestInterface): Observable<GetTimeslotsReponseInterface> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.GetTimeslots);
        const payload = mapGetTimeslotsRequest(request);

        return this.apiService
            .post$<ApiGetTimeslotsReponseInterface>(resource, payload)
            .pipe(map(mapGetTimeslotsResponse));
    }
}
