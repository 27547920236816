import { RelationInterface } from '../interfaces';

export const RELATION_FEATURE = 'relation';

export interface Relation {
    relation: RelationState;
}

export interface RelationState {
    relation?: RelationInterface;
}

export const INITIAL_RELATION_STATE = {
    relation: null,
};
