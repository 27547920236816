import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, ApiUrlResourcesEnum, EnvironmentService } from '../../core';
import {
    ApiGetClaimProcessStepsResponseInterface,
    GetClaimProcessStepsRequestInterface,
    GetClaimProcessStepsResponseInterface,
} from '../interfaces';
import { mapClaimProcessStepsRequest, mapClaimProcessStepsResponse } from '../mappers/claim-process-steps.mapper';

@Injectable({
    providedIn: 'root',
})
export class ClaimActionsApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getClaimProcessSteps$(
        request: GetClaimProcessStepsRequestInterface
    ): Observable<GetClaimProcessStepsResponseInterface> {
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimProcessSteps);

        return this.apiService
            .post$<ApiGetClaimProcessStepsResponseInterface>(resource, mapClaimProcessStepsRequest(request))
            .pipe(map(mapClaimProcessStepsResponse));
    }
}
