import { mapSequenceNumber } from '../../../core';
import { ApiCancelAddedCoverageRequest, CancelAddedCoverageRequestInterface } from '../../interfaces';

export function mapCancelAddedCoverageRequest(
    request: CancelAddedCoverageRequestInterface
): ApiCancelAddedCoverageRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}
