import { createSelector } from '@ngrx/store';
import { Coverage, CoveragePremium } from '../../interfaces';
import { getProductId } from '../progress/progress.selectors';
import { getSalesState } from '../sales.reducers';
import * as fromCoverage from './coverage.reducer';

const addPremiums = (currentTotal: number, coveragePremium: CoveragePremium | null): number => {
    const premiums = currentTotal + (coveragePremium ? coveragePremium.premium_including_premium_tax : 0);
    return +parseFloat(`${premiums}`).toFixed(2);
};

export const getCoverageState = createSelector(getSalesState, (state) => state.coverage);

export const getEntities = createSelector(getCoverageState, fromCoverage.getEntities);
export const getCurrentCoverages = createSelector(getEntities, getProductId, (entities, productId) => {
    return entities[productId] || [];
});

export const getModules = createSelector(getCoverageState, fromCoverage.getModules);
export const getSelectedModules = createSelector(getModules, getProductId, (modules, productId) => {
    return modules[productId] || [];
});

export const getTotalsExcludingDiscount = createSelector(getCurrentCoverages, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.premium);
    }, 0);
});

export const getDiscount = createSelector(getCurrentCoverages, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.discount);
    }, 0);
});

export const getTotalsIncludingDiscount = createSelector(getCurrentCoverages, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return addPremiums(accumulator, currentValue.premium_including_discount);
    }, 0);
});

export const getTotalsIncludingStackDiscount = createSelector(getCurrentCoverages, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return currentValue.premium_including_stack_discount ?
            addPremiums(accumulator, currentValue.premium_including_stack_discount[0]) :
            null;
    }, 0);
});

export const getTotalStackDiscount = createSelector(getCurrentCoverages, (coverages: Coverage[]) => {
    return (coverages || []).reduce((accumulator, currentValue) => {
        return currentValue.stack_discount ?
            addPremiums(accumulator, currentValue.stack_discount[0]) :
            null;
    }, 0);
});
