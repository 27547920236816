import { mapEnumApiResponse } from '../../core';
import { ApiLegalPartyRoleEnum, ApiV1LegalPartyRoleEnum, LegalPartyRoleEnum } from '../enums';

export function mapLegalPartyRoleResponse(
    response: ApiLegalPartyRoleEnum,
    enablePartyCodes?: boolean
): LegalPartyRoleEnum {
    const partyRoleEnumType = enablePartyCodes ? ApiV1LegalPartyRoleEnum : ApiLegalPartyRoleEnum;
    return mapEnumApiResponse<LegalPartyRoleEnum>(response, partyRoleEnumType, LegalPartyRoleEnum);
}
