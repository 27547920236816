import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, EnvironmentService, ApiUrlResourcesEnum } from '../../core';
import {
    GetClaimObjectsRequestInterface,
    GetClaimObjectsResponseInterface,
    ApiClaimObjectsInterface,
    GetClaimObjectDetailsRequestInterface,
    GetClaimObjectDetailsResponseInterface,
    ApiGetClaimObjectDetailsResponseInterface,
    ReloadClaimObjectDetailsRequestInterface,
    ReloadClaimObjectDetailsResponseInterface,
    ApiClaimObjectDetailsInterface,
    SaveClaimObjectRequestInterface,
    SaveClaimObjectResponseInterface,
    ApiSaveClaimObjectResponseInterface,
    RemoveClaimObjectRequestInterface,
    RemoveClaimObjectResponseInterface,
    ApiRemoveClaimObjectResponseInterface,
    UpdateClaimObjectDetailsRequestInterface,
    UpdateClaimObjectDetailsResponseInterface,
    ApiUpdateClaimObjectDetailsResponseInterface,
} from '../interfaces';
import {
    mapGetClaimObjectDetailsRequest,
    mapGetClaimObjectDetailsResponse,
    mapGetClaimObjectsRequest,
    mapGetClaimObjectsResponse,
    mapReloadClaimObjectDetailsRequest,
    mapReloadClaimObjectDetailsResponse,
    mapRemoveClaimObjectReponse,
    mapRemoveClaimObjectRequest,
    mapSaveClaimObjectReponse,
    mapSaveClaimObjectRequest,
    mapUpdateClaimObjectDetailsRequest,
    mapUpdateClaimObjectDetailsResponse,
} from '../mappers';

@Injectable({ providedIn: 'root' })
export class ClaimObjectsApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getClaimObjects$(request: GetClaimObjectsRequestInterface): Observable<GetClaimObjectsResponseInterface> {
        const mappedRequest = mapGetClaimObjectsRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimObjects);

        return this.apiService
            .post$<ApiClaimObjectsInterface>(resource, mappedRequest)
            .pipe(map(mapGetClaimObjectsResponse));
    }

    public getClaimObjectDetails$(
        request: GetClaimObjectDetailsRequestInterface
    ): Observable<GetClaimObjectDetailsResponseInterface> {
        const mappedRequest = mapGetClaimObjectDetailsRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimObjectDetails);

        return this.apiService
            .post$<ApiGetClaimObjectDetailsResponseInterface[]>(resource, mappedRequest)
            .pipe(map((response) => mapGetClaimObjectDetailsResponse(response, request.claimObjectId)));
    }

    public updateClaimObjectDetails$(
        request: UpdateClaimObjectDetailsRequestInterface
    ): Observable<UpdateClaimObjectDetailsResponseInterface> {
        const mappedRequest = mapUpdateClaimObjectDetailsRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimObjectDetails);

        return this.apiService
            .post$<ApiUpdateClaimObjectDetailsResponseInterface[]>(resource, mappedRequest)
            .pipe(map((response) => mapUpdateClaimObjectDetailsResponse(response, request.claimObjectId)));
    }

    public reloadClaimObjectDetails$(
        request: ReloadClaimObjectDetailsRequestInterface
    ): Observable<ReloadClaimObjectDetailsResponseInterface> {
        const mappedRequest = mapReloadClaimObjectDetailsRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimObjects);

        return this.apiService
            .post$<ApiClaimObjectDetailsInterface[]>(resource, mappedRequest)
            .pipe(map((response) => mapReloadClaimObjectDetailsResponse(response, request)));
    }

    public saveClaimObject$(request: SaveClaimObjectRequestInterface): Observable<SaveClaimObjectResponseInterface> {
        const mappedRequest = mapSaveClaimObjectRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.SaveClaimObject);

        return this.apiService
            .post$<ApiSaveClaimObjectResponseInterface>(resource, mappedRequest)
            .pipe(map(mapSaveClaimObjectReponse));
    }

    public removeClaimObject$(
        request: RemoveClaimObjectRequestInterface
    ): Observable<RemoveClaimObjectResponseInterface> {
        const mappedRequest = mapRemoveClaimObjectRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.RemoveClaimObject);

        return this.apiService
            .post$<ApiRemoveClaimObjectResponseInterface>(resource, mappedRequest)
            .pipe(map(() => mapRemoveClaimObjectReponse(request)));
    }
}
