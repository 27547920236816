import { DamageFreeYearsImpactResponseInterface } from '../interfaces';

export interface DamageFreeYearsImpactState {
    damageFreeYearsImpact: DamageFreeYearsImpactResponseInterface;
}

export const DAMAGE_FREE_YEARS_IMPACT_INITIAL_STATE: DamageFreeYearsImpactState = {
    damageFreeYearsImpact: null,
};

export const DAMAGE_FREE_YEARS_IMPACT_FEATURE = 'damageFreeYearsImpact';
