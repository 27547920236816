import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    AttachmentRequestInterface,
    ComplaintRequestInterface,
    CreateEmailRequestInterface,
    DownloadTerminationLetterRequestInterface,
    DownloadTerminationLetterResponseInterface,
    EmailTerminationLetterRequestInterface,
    GetInsuranceCompaniesResponseInterface,
    SendEmailRequestInterface,
} from '../interfaces';
import * as ContactActions from '../store/contact.actions';
import { ContactEventService } from './contact.event.service';

@Injectable({
    providedIn: 'root',
})
export class ContactActionService {
    constructor(private store$: Store, private eventService: ContactEventService) {}

    public createEmail$(request: CreateEmailRequestInterface): Observable<void> {
        this.store$.dispatch(ContactActions.createEmail({ request }));

        return this.eventService.getCreateEmail$();
    }

    public addAttachment$(request: AttachmentRequestInterface): Observable<void> {
        this.store$.dispatch(ContactActions.addAttachment({ request }));

        return this.eventService.getAddAttachment$();
    }

    public sendEmail$(request: SendEmailRequestInterface): Observable<void> {
        this.store$.dispatch(ContactActions.sendEmail({ mailQuestionId: request.mailQuestionId }));

        return this.eventService.getSendEmail$();
    }

    public sendComplaint$(request: ComplaintRequestInterface): Observable<void> {
        this.store$.dispatch(ContactActions.sendComplaint({ request }));

        return this.eventService.getSendComplaint$();
    }

    public getInsuranceCompanies$(): Observable<GetInsuranceCompaniesResponseInterface[]> {
        this.store$.dispatch(ContactActions.getInsuranceCompanies());
        return this.eventService.getGetInsuranceCompanies$();
    }

    public downloadTerminationLetter$(
        request: DownloadTerminationLetterRequestInterface
    ): Observable<DownloadTerminationLetterResponseInterface> {
        this.store$.dispatch(ContactActions.downloadTerminationLetter({ request }));
        return this.eventService.getDownloadTerminationLetter$();
    }

    public emailTerminationLetter$(request: EmailTerminationLetterRequestInterface): Observable<void> {
        this.store$.dispatch(ContactActions.emailTerminationLetter({ request }));
        return this.eventService.getEmailTerminationLetter$();
    }
}
