import { createReducer, on } from '@ngrx/store';
import { mergeProductsUtility, mergeProductUtility, removeProductUtility } from '../utilities';
import * as InsurancePolicyActions from './insurance-policy.actions';
import { INSURANCE_POLICY_INITIAL_STATE } from './insurance-policy.state';

export const insurancePolicyReducer = createReducer(
    INSURANCE_POLICY_INITIAL_STATE,
    on(InsurancePolicyActions.productSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.policySuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: response.purchasedProducts,
    })),
    on(InsurancePolicyActions.productDetailsSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.allProductDetailsSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductsUtility(state.purchasedProducts, response.purchasedProducts),
    })),
    on(InsurancePolicyActions.allCoveragesSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductsUtility(state.purchasedProducts, response.purchasedProducts),
    })),
    on(InsurancePolicyActions.coverageSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.reviveProductSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.startDateProductSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.endProductSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.endDateProductSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.cancelAddedProductSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: removeProductUtility(state.purchasedProducts, response.productSequenceNumber),
    })),
    on(InsurancePolicyActions.addPremiumDeterminingFactorSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.cancelAddedPremiumDeterminingFactorSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.addRegularDriverSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.endRegularDriverSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.cancelAddedRegularDriverSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.addCoverageSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    })),
    on(InsurancePolicyActions.cancelAddedCoverageSuccess, (state, { response }) => ({
        ...state,
        purchasedProducts: mergeProductUtility(response, state.purchasedProducts),
    }))
);
