/* eslint-disable rxjs/finnish */
import { Directionality } from '@angular/cdk/bidi';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    Overlay,
    OverlayConfig,
    OverlayContainer,
    OverlayRef,
    ScrollStrategy,
    ViewportRuler,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import {
    Inject,
    Injectable,
    Injector,
    OnDestroy,
    Optional,
    SkipSelf,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { defer, Observable, of, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DefaultModalComponent, DefaultModalData } from './default-modal-component';
import { Location } from './interfaces';
import { NewModalConfig } from './new-modal-config';
import { NewModalContainer } from './new-modal-container';
import { NewModalRef } from './new-modal-ref';
import { NEW_MODAL_DATA, NEW_MODAL_DEFAULT_OPTIONS, NEW_MODAL_SCROLL_STRATEGY } from './new-modal-utilities';
import { FullscreenScrollStrategy } from './scroll-strategy';

function applyConfigDefaults(config?: NewModalConfig, defaultOptions?: NewModalConfig): NewModalConfig {
    return { ...defaultOptions, ...config };
}

@Injectable({
    providedIn: 'root',
})
export class NewModal implements OnDestroy {
    // noinspection JSUnusedGlobalSymbols
    public readonly afterAllClosed: Observable<void> = defer(() =>
        this.openModals.length ? this._afterAllClosed : this._afterAllClosed.pipe(startWith(undefined))
    ) as Observable<any>;

    private _openModalsAtThisLevel: NewModalRef<any>[] = [];
    private readonly _afterAllClosedAtThisLevel$ = new Subject<void>();
    private readonly _afterOpenedAtThisLevel$ = new Subject<NewModalRef<any>>();
    private _ariaHiddenElements = new Map<Element, string | null>();
    private readonly _scrollStrategy: () => ScrollStrategy;
    private _isHandset: boolean;

    constructor(
        private _overlay: Overlay,
        private _injector: Injector,
        private _overlayContainer: OverlayContainer,
        private _viewportRuler: ViewportRuler,
        @Optional() @Inject('_location') public _location: Location,
        @Optional() @Inject(NEW_MODAL_DEFAULT_OPTIONS) private _defaultOptions: NewModalConfig,
        @Optional() @SkipSelf() private _parentModal: NewModal,
        @Inject(NEW_MODAL_SCROLL_STRATEGY) scrollStrategy: any,
        breakpointObserver: BreakpointObserver
    ) {
        breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
            this._isHandset = result.matches;
        });
        this._scrollStrategy = scrollStrategy;
    }

    /**
     * Keep track of currently-open modals.
     */
    public get openModals(): NewModalRef<any>[] {
        const parent = this._parentModal;
        return parent ? parent.openModals : this._openModalsAtThisLevel;
    }

    public get afterOpened(): Subject<NewModalRef<any>> {
        const parent = this._parentModal;
        return parent ? parent.afterOpened : this._afterOpenedAtThisLevel$;
    }

    public get _afterAllClosed(): Subject<void> {
        const parent = this._parentModal;
        return parent ? parent._afterAllClosed : this._afterAllClosedAtThisLevel$;
    }

    public openDefault(
        defaultModalData: DefaultModalData | TemplateRef<any>,
        config?: NewModalConfig
    ): NewModalRef<any> {
        config = applyConfigDefaults(config, this._defaultOptions || new NewModalConfig());
        config.data = defaultModalData;
        if (defaultModalData instanceof TemplateRef) {
            return this.open(defaultModalData, config);
        }
        return this.open<DefaultModalComponent, DefaultModalData>(DefaultModalComponent, config);
    }

    public open<T, D = any, R = any>(
        component: ComponentType<T> | TemplateRef<T>,
        config?: NewModalConfig<D>
    ): NewModalRef<T, R> {
        config = applyConfigDefaults(config, this._defaultOptions || new NewModalConfig());
        // when on mobile, all modals should be full-screen
        if (this._isHandset) {
            config.fullScreen = true;
        }

        // when full-screen is on true, these properties need to be hardwired to ensure a full-screen modal.
        if (config.fullScreen) {
            config.width = '100vw';
            config.height = '100vh';
            config.maxHeight = 'none';
            config.maxWidth = 'none';
        }

        if (config.id && this.getModalById(config.id)) {
            throw Error(`Modal with id "${config.id}" exists already. the modal id must be unique.`);
        }

        const overlayRef = this._createOverlay(config);
        const modalContainer = this._attachModalContainer(overlayRef, config);
        const modalRef = this._attachModalContent<T, R>(component, modalContainer, overlayRef, config);

        if (!this.openModals.length) {
            this._hideNonModalContentFromAssistiveTechnology();
        }
        this.openModals.push(modalRef);
        modalRef.afterClosed$().subscribe(() => this._removeOpenModal(modalRef));
        this.afterOpened.next(modalRef);
        return modalRef;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * closes all of the currently-open modals.
     */
    public closeAll(): void {
        this._closeModals(this.openModals);
    }

    public getModalById(id: string): NewModalRef<any> | undefined {
        return this.openModals.find((modal) => modal.id === id);
    }

    public ngOnDestroy(): void {
        // Only close the modals at this level on destroy
        // since the parent service may still be active.
        this._closeModals(this._openModalsAtThisLevel);
        this._afterAllClosedAtThisLevel$.complete();
        this._afterOpenedAtThisLevel$.complete();
    }

    /**
     * Creates the overlay into which the modal will be loaded
     * @param config The modal configuration
     * @returns a promise resolving to the OverlayRef for the created overlay
     */
    private _createOverlay(config: NewModalConfig): OverlayRef {
        const overlayConfig = this._getOverlayConfig(config);
        return this._overlay.create(overlayConfig);
    }

    /**
     * Creates an overlay config from a modal config
     * @param config The modal configuration
     * @returns The overlay configuration
     */
    private _getOverlayConfig(config: NewModalConfig): OverlayConfig {
        let scrollStrategy = config.scrollStrategy || this._scrollStrategy();
        if (config.fullScreen) {
            scrollStrategy = this._createFullScreenScrollStrategy();
        }
        const state = new OverlayConfig({
            positionStrategy: this._overlay.position().global(),
            scrollStrategy,
            direction: config.direction,
            panelClass: config.panelClass,
            hasBackdrop: config.hasBackdrop,
            minWidth: config.minWidth,
            minHeight: config.minHeight,
            maxWidth: config.maxWidth,
            maxHeight: config.maxHeight,
            disposeOnNavigation: config.closeOnNavigation,
        });

        if (config.backdropClass) {
            state.backdropClass = config.backdropClass;
        }
        return state;
    }

    /**
     * Attaches an ModalContainer to a modal's already-created overlay.
     * @param overlay Reference to the modal's underlying overlay.
     * @param config The modal configuration
     * @returns A promise resolving to a ComponentRef for the attached container.
     */
    private _attachModalContainer(overlay: OverlayRef, config: NewModalConfig): NewModalContainer {
        const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
        const injector = new PortalInjector(userInjector || this._injector, new WeakMap([[NewModalConfig, config]]));
        const containerPortal = new ComponentPortal(
            NewModalContainer,
            config.viewContainerRef,
            injector,
            config.componentFactoryResolver
        );
        const containerRef = overlay.attach<NewModalContainer>(containerPortal);

        return containerRef.instance;
    }

    /**
     * Attaches the user-provided component to the already-created ModalContainer.
     * @param component The type of component being loaded into the modal,
     * @param modalContainer Reference to the wrapping ModalContainer.
     * @param overlayRef Reference to the overlay in which the modal resides.
     * @param config The modal configuration.
     * @returns A promise resolving to the ModalRef that should be returned to the user.
     */
    private _attachModalContent<T, R>(
        component: ComponentType<T> | TemplateRef<T>,
        modalContainer: NewModalContainer,
        overlayRef: OverlayRef,
        config: NewModalConfig
    ): NewModalRef<T, R> {
        const modalRef = new NewModalRef<T, R>(overlayRef, modalContainer, config.id);
        // when the backdrop is clicked, we close it
        if (config.hasBackdrop) {
            overlayRef.backdropClick().subscribe(() => {
                if (!modalRef.disableClose) {
                    modalRef.close();
                }
            });
        }

        if (component instanceof TemplateRef) {
            modalContainer.attachTemplatePortal(
                new TemplatePortal<T>(component, {} as ViewContainerRef, { $implicit: config.data, modalRef } as any)
            );
        } else {
            const injector = this._createInjector<T>(config, modalRef, modalContainer);
            const contentRef = modalContainer.attachComponentPortal<T>(
                new ComponentPortal(component, config.viewContainerRef, injector)
            );
            modalRef.componentInstance = contentRef.instance;
            (contentRef.location.nativeElement as HTMLElement).classList.add('modal__wrapper');
            modalRef.componentInstance = contentRef.instance;
        }
        // The modal__wrapper element is an unknown until this moment.
        // By giving the wrapper element the modal__wrapper class here, it is possible to add styling to the wrapper.
        modalRef.updateSize(config.width, config.height);
        modalRef.updatePosition(config.position);

        return modalRef;
    }

    /**
     * Creates a custom injector to be used inside the modal. This allows a component loaded inside
     * of a modal to close itself and, optionally, to return a value.
     * @param config Config object that is used to construct the modal.
     * @param modalRef Reference to the modal
     * @param modalContainer Modal container element that wraps all of the contents.
     * @returns The custom injector that can be used inside the modal.
     */
    private _createInjector<T>(
        config: NewModalConfig,
        modalRef: NewModalRef<T>,
        modalContainer: NewModalContainer
    ): PortalInjector {
        const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;
        const injectionTokens = new WeakMap<any, any>([
            [NewModalContainer, modalContainer],
            [NEW_MODAL_DATA, config.data],
            [NewModalRef, modalRef],
        ]);

        if (config.direction && (!userInjector || !userInjector.get<Directionality | null>(Directionality, null))) {
            injectionTokens.set(Directionality, {
                value: config.direction,
                change: of(),
            });
        }

        return new PortalInjector(userInjector || this._injector, injectionTokens);
    }

    /**
     * Removes a modal from the array of open modals.
     * @param modalRef Modal to be removed.
     */
    private _removeOpenModal(modalRef: NewModalRef<any>): void {
        const index = this.openModals.indexOf(modalRef);

        if (index > -1) {
            this.openModals.splice(index, 1);

            // If all the modals were closed, remove/restore the `aria-hidden`
            // to a the siblings and emit to the `afterAllClosed` stream.
            if (!this.openModals.length) {
                this._ariaHiddenElements.forEach((previousValue, element) => {
                    if (previousValue) {
                        element.setAttribute('aria-hidden', previousValue);
                    } else {
                        element.removeAttribute('aria-hidden');
                    }
                });

                this._ariaHiddenElements.clear();
                this._afterAllClosed.next();
            }
        }
    }

    /**
     * Hides all of the content that isn't an overlay from assistive technology.
     */
    private _hideNonModalContentFromAssistiveTechnology(): void {
        const overlayContainer = this._overlayContainer.getContainerElement();

        // Ensure that the overlay container is attached to the DOM.
        if (overlayContainer.parentElement) {
            const siblings = overlayContainer.parentElement.children;

            for (let i = siblings.length - 1; i > -1; i--) {
                const sibling = siblings[i];

                if (
                    sibling !== overlayContainer &&
                    sibling.nodeName !== 'SCRIPT' &&
                    sibling.nodeName !== 'STYLE' &&
                    !sibling.hasAttribute('aria-live')
                ) {
                    this._ariaHiddenElements.set(sibling, sibling.getAttribute('aria-hidden'));
                    sibling.setAttribute('aria-hidden', 'true');
                }
            }
        }
    }

    // noinspection JSMethodCanBeStatic
    /** Closes all of the modals in an array. */
    private _closeModals(modals: NewModalRef<any>[]): void {
        let i = modals.length;

        while (i--) {
            // The `_openModals` property isn't updated after close until the rxjs subscription
            // runs on the next microtask, in addition to modifying the array as we're going
            // through it. We loop through all of them and call close without assuming that
            // they'll be removed from the list instantaneously.
            modals[i].close();
        }
    }

    private _createFullScreenScrollStrategy(): FullscreenScrollStrategy {
        return new FullscreenScrollStrategy(this._viewportRuler, document);
    }
}
