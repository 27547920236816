import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentProcessState, PAYMENT_PROCESS_FEATURE } from './payment-process.state';

export class PaymentProcessSelectors {
    public static getPaymentProcessState = createFeatureSelector<PaymentProcessState>(PAYMENT_PROCESS_FEATURE);

    public static getState = createSelector(
        PaymentProcessSelectors.getPaymentProcessState,
        (state: PaymentProcessState) => state
    );

    public static getPaymentProcess = createSelector(
        PaymentProcessSelectors.getPaymentProcessState,
        (state: PaymentProcessState) => {
            return state.paymentResponse;
        }
    );
}
