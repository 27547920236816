import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ApiGetCountriesResponseInterface, GetCountriesResponseInterface } from '../interfaces/country.interface';
import { mapGetCountriesResponse } from '../mappers/get-countries.mapper';

const RESOURCES = {
    GET_COUNTRIES: 'en-gb/damage-general/request/countries',
};

@Injectable({
    providedIn: 'root',
})
export class CountriesApiService {
    constructor(private apiService: ApiService) {}

    public getCountries$(): Observable<GetCountriesResponseInterface> {
        return this.apiService
            .post$<ApiGetCountriesResponseInterface>(RESOURCES.GET_COUNTRIES)
            .pipe(map(mapGetCountriesResponse));
    }
}
