import { ApiCreateNonRegularDriverRequestInterface } from "../interfaces/product/api.create-non-regular-driver.request.interface";
import { CreateNonRegularDriverRequestInterface } from "../interfaces/product/create-non-regular-driver.request.interface";

export function mapCreateNonRegularDriverRequest(request: CreateNonRegularDriverRequestInterface)
    : ApiCreateNonRegularDriverRequestInterface {
    return {
        purchased_product: {
            purchased_product_sequence_number: request.productSequenceNumber
        },
        non_regular_driver: {
            first_name: request.firstName,
            start_date: request.startDate,
            relation_code: request.relationCode,
            gender_code: request.genderCode,
            initials: request.initials,
            prefix_lastname: request.prefixSurname,
            surname: request.surname,
            birthdate: request.birthdate,
            driving_license_first_date_of_issue: request.drivingLicenseFirstDateOfIssue,
            email_address: request.emailAddress
        }
    }
}
