import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CLAIM_STEPS_FEATURE, ClaimStepsEffects, claimStepsReducer } from './store';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_STEPS_FEATURE, claimStepsReducer),
        EffectsModule.forFeature([ClaimStepsEffects]),
    ],
})
export class OutClaimStepsModule {}
