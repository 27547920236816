import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    GetInsuranceInfoCheckRequestInterface,
    GetInsuranceInfoCheckResponseInterface,
    SaveInsuranceInfoCheckRequestInterface,
} from '../interfaces';

export const getInsuranceInfoCheck = createAction(
    '[Insurance Info Check] Get insurance info check',
    props<{ request: GetInsuranceInfoCheckRequestInterface }>()
);

export const getInsuranceInfoCheckSuccess = createAction(
    '[Insurance Info Check] Get insurance info check success',
    props<{ response: GetInsuranceInfoCheckResponseInterface }>()
);

export const getInsuranceInfoCheckFailure = createAction(
    '[Insurance Info Check] Get insurance info check failure',
    props<{ errors: ErrorInterface[] }>()
);
export const saveInsuranceInfoCheckInfo = createAction(
    '[Insurance Info Check] Save insurance info check',
    props<{ request: SaveInsuranceInfoCheckRequestInterface }>()
);

export const saveInsuranceInfoCheckSuccess = createAction('[Insurance Info Check] Save insurance info check success');

export const saveInsuranceInfoCheckFailure = createAction(
    '[Insurance Info Check] Save insurance info check failure',
    props<{ errors: ErrorInterface[] }>()
);

export const clearInsuranceInfoCheckInfo = createAction('[Insurance Info Check] Clear insurance info check');
