import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { GetAppointmentsReponseInterface, GetAppointmentsRequestInterface } from '../interfaces';
import { GetLocationsReponseInterface, GetLocationsRequestInterface } from '../interfaces/get-locations.interface';
import { GetTimeslotsReponseInterface, GetTimeslotsRequestInterface } from '../interfaces/get-timeslots.interface';
import {
    SaveAppointmentReponseInterface,
    SaveAppointmentRequestInterface,
} from '../interfaces/save-appointment.interface';

// Get Appointment
export const getAppointments = createAction(
    '[claim appointment] get appointments',
    props<{ request: GetAppointmentsRequestInterface }>()
);

export const getAppointmentsSuccess = createAction(
    '[claim appointment] get appointments success',
    props<{ response: GetAppointmentsReponseInterface }>()
);

export const getAppointmentsFailure = createAction(
    '[claim appointment] get appointments failure',
    props<{ errors: ErrorInterface[] }>()
);

// Save appointment
export const saveAppointment = createAction(
    '[claim appointment] save appointment',
    props<{ request: SaveAppointmentRequestInterface }>()
);

export const saveAppointmentSuccess = createAction(
    '[claim appointment] save appointment success',
    props<{ response: SaveAppointmentReponseInterface }>()
);

export const saveAppointmentFailure = createAction(
    '[claim appointment] save appointment failure',
    props<{ errors: ErrorInterface[] }>()
);

// Get Locations
export const getLocations = createAction(
    '[claim appointment] get locations',
    props<{ request: GetLocationsRequestInterface }>()
);

export const getLocationsSuccess = createAction(
    '[claim appointment] get locations success',
    props<{ response: GetLocationsReponseInterface }>()
);

export const getLocationsFailure = createAction(
    '[claim appointment] get locations failure',
    props<{ errors: ErrorInterface[] }>()
);

// Get Timeslots
export const getTimeslots = createAction(
    '[claim appointment] get timeslots',
    props<{ request: GetTimeslotsRequestInterface }>()
);

export const getTimeslotsSuccess = createAction(
    '[claim appointment] get timeslots success',
    props<{ response: GetTimeslotsReponseInterface }>()
);

export const getTimeslotsFailure = createAction(
    '[claim appointment] get timeslots failure',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimAppointment = createAction('[claim appointment] reset claim appointment');
