import { mapSequenceNumber } from '../../../core';
import { AddCoverageRequestInterface, ApiAddCoverageRequest } from '../../interfaces';
import { mapCoverageIdRequest } from '../product';

export function mapAddCoverageRequest(request: AddCoverageRequestInterface): ApiAddCoverageRequest {
    const coverageIds = request.coverageIds.map((coverageId) => ({ coverage_id: mapCoverageIdRequest(coverageId) }));

    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
        claim_coverage: {
            start_date: request.startDate,
            coverages: coverageIds,
        },
        premium_factor: {
            campaign_code: null,
        },
    };
}
