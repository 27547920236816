import { ContentElementsInterface } from '../interfaces';

export interface Content {
    content: ContentState;
}

export interface ContentState {
    contentElements: ContentElementsInterface;
}

export const INITIAL_CONTENT_STATE: ContentState = {
    contentElements: {},
};

export const CONTENT_FEATURE = 'content';
