import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, EnvironmentService } from '../../core';
import { ApiUrlResourcesEnum } from '../../core/enums/resources/resources';
import {
    SaveExhibitRequestInterface,
    LoadExhibitsRequestInterface,
    SubmitExhibitsRequestInterface,
    DeleteExhibitRequestInterface,
    LoadExhibitsResponseInterface,
    SubmitExhibitsResponseInterface,
    SaveExhibitResponseInterface,
    DeleteExhibitResponseInterface,
} from '../interfaces';
import {
    mapLoadExhibitsResponse,
    mapDeleteExhibitRequest,
    mapLoadExhibitsRequest,
    mapSaveExhibitRequest,
    mapSubmitExhibitsRequest,
} from '../mappers';

@Injectable({
    providedIn: 'root',
})
export class ClaimExhibitsApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public loadExhibits$(request: LoadExhibitsRequestInterface): Observable<LoadExhibitsResponseInterface> {
        const mappedRequest = mapLoadExhibitsRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimLoadExhibits);

        return this.apiService.post$(resource, mappedRequest).pipe(map(mapLoadExhibitsResponse));
    }

    public submitExhibits$(request: SubmitExhibitsRequestInterface): Observable<SubmitExhibitsResponseInterface> {
        const { caseSequenceNumber } = request;
        const mappedRequest = mapSubmitExhibitsRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimSubmitExhibits);

        return this.apiService
            .post$(resource, mappedRequest)
            .pipe(switchMap(() => this.loadExhibits$({ caseSequenceNumber })));
    }

    public saveExhibit$(request: SaveExhibitRequestInterface): Observable<SaveExhibitResponseInterface> {
        const { caseSequenceNumber } = request;
        const mappedRequest = mapSaveExhibitRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimSaveExhibits);

        return this.apiService
            .post$(resource, mappedRequest)
            .pipe(switchMap(() => this.loadExhibits$({ caseSequenceNumber })));
    }

    public deleteExhibit$(request: DeleteExhibitRequestInterface): Observable<DeleteExhibitResponseInterface> {
        const { caseSequenceNumber } = request;
        const mappedRequest = mapDeleteExhibitRequest(request);
        const resource = this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.ClaimDeleteExhibits);

        return this.apiService
            .post$(resource, mappedRequest)
            .pipe(switchMap(() => this.loadExhibits$({ caseSequenceNumber })));
    }
}
