export enum ApiCoverageIdEnum {
    Liability = 'AVP', // 'Aansprakelijkheid',
    CarLegalLiability = 'WA', // 'WA',
    CarLimitedCasco = 'BC', // 'Beperkt Casco',
    CarLimitedCascoExtra = 'BCE', // 'Beperkt Casco Extra',
    CarAllrisk = 'CA', // 'Allrisk',
    CarDamageInsuranceOccupants = 'ASVI', // 'Schadeverzekering Inzittenden',
    CarRoadsideAssistanceNetherlands = 'PHNL', // 'Pechhulp Nederland',
    CarRoadsideAssistanceEurope = 'PHEU', // 'Pechhulp Europa',
    CarRedressLegalAid = 'AVHS', // 'Verhaalsrechtsbijstand',
    CarDirectClaimsHandling = 'DISA', // 'Directe Schadeafhandeling Auto',
    MotorDirectClaimsHandling = 'DISM', // 'Directe Schadeafhandeling Motor',
    MopedLegalLiability = 'BRWA', // 'WA',
    MopedLimitedCasco = 'BRBC', // 'Beperkt Casco'
    MopedAllrisk = 'BRCA', // 'Allrisk',
    CaravanAllriskBenelux = 'ALRN', // 'Allrisk Benelux en Duitsland',
    CaravanAllriskEurope = 'ALRE', // 'Allrisk Europa',
    CaravanHouseholdEffects = 'CINB', // 'Inboedel',
    CaravanExtra = 'ACCS', // 'Extra\'s',
    CaravanHailBenelux = 'HGLN', // 'Hagel Benelux en Duitsland',
    CaravanHailEurope = 'HGLE', // 'Hagel Europa',
    PetInsurance = 'SZGP', // 'Huisdierenverzekering',
    PetAccident = 'BZDO', // 'Huisdier & Ongeval',
    PetCare50Percent = 'ADGZ', // 'Huisdier & Zorg 50%',
    PetCare100Percent = 'ADCZ', // 'Huisdier & Zorg 100%',
    PetExtraCoverage = 'FYSI', // 'Huisdier Extra Dekking'
    PetCancerTreatment = 'CHEM', // 'Huisdier behandeling van kanker'
    PetTeeth = 'TAND', // 'Huisdier Gebit'
    PetCrem = 'CREM', // 'Huisdier crematie' // HEIN
    BicycleTheft = 'FDIE', // 'Fiets Diefstal',
    BicycleAllrisk = 'FALL', // 'Fiets Allrisk',
    BicycleHomeDeliveryService = 'FTBP', // 'Thuisbrengservice bij pech',
    MotorLimitedCasco = 'MWA', // 'WA',
    MotorInStorage = 'MTOP', // 'Motor op Stal',
    MotorAllrisk = 'MCA', // 'Allrisk',
    MotorDamageInsuranceOpputants = 'MSVO', // 'Schadeverzekering Opzittenden',
    MotorRedressLegalAid = 'MVHS', // 'Verhaalsrechtsbijstand',
    MotorAccidentPermanentInjury = 'INVL', // 'Ongeval & Blijvend letsel',
    MotorAccidentDeath = 'OVRL', // 'Ongeval & Overlijden',
    TravelEurope = 'EURB', // 'Reisverzekering (basisdekking) Europa',
    TravelWorld = 'WERB', // 'Werelddekking',
    TravelVehicleAssistance = 'VHLP', // 'Voertuighulp',
    TravelCancellation = 'ANNU', // 'Annulering',
    TravelMedicalCostEurope = 'GENE', // 'Medische kosten Europa',
    TravelMedicalCostWorld = 'GENW', // 'Medische kosten Wereld',
    HomeHouseholdEffectStandard = 'INBL', // 'Inboedel Standaard',
    HomeBuildingStandard = 'OPST', // 'Opstal Standaard',
    HomeHouseholdEffectsComplete = 'IBAR', // 'Inboedel Compleet',
    HomeBuildingComplete = 'OSAR', // 'Opstal Compleet',
    HomeGlass = 'GLAS', // 'Glas',
    HomeMobileElectronics = 'MBEL', // 'Mobiele Elektronica',
    HomeOwnerTenantInterest = 'HUAP', // 'Eigenaars-/ huurderbelang',
    HomeOwnerTenantInterestComplete = 'HAAR', // 'Eigenaars-/ huurderbelang Compleet',
    LegalAssistanceConsumerAndLiving = 'CENW', // 'Consument & Wonen'
    LegalAssistanceWorkAndIncome = 'WENI', // 'Werk & Inkomen',
    LegalAssistanceFamilyAndWealth = 'FENV', // 'Familie & Vermogen',
    LegalAssistanceAccidentAndTraffic = 'ORB', // 'Ongeval & Verkeer',
}
