import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { ElementRef, InjectionToken } from '@angular/core';
import { NewModalConfig } from './new-modal-config';
import { NewModalRef } from './new-modal-ref';

/**
 * Finds the closest MatDialogRef to an element by looking at the DOM.
 * @param element Element relative to which to look for a dialog.
 * @param openModals References to the currently-open dialogs.
 */
export function getClosestModal(element: ElementRef<HTMLElement>, openModals: NewModalRef<any>[]): NewModalRef<any> {
    let parent: HTMLElement | null = element.nativeElement.parentElement;
    while (parent && !parent.classList.contains('temp-modal')) {
        parent = parent.parentElement;
    }
    // parent is not null in left side of the short-if, because then it would be false.
    // @ts-ignore
    return parent ? openModals.find((modal) => modal.id === parent.id) : null;
}

export function getModalRef(elementRef, modal): NewModalRef<any> {
    return getClosestModal(elementRef, modal.openModals);
}

/** Injection token that can be used to access the data that was passed in to a modal. */
export const NEW_MODAL_DATA = new InjectionToken<any>('ModalData');

/** Injection token that can be used to specify default modal options. */
export const NEW_MODAL_DEFAULT_OPTIONS = new InjectionToken<NewModalConfig>('modal-default-options');

/** Injection token that determines the scroll handling while the modal is open. */
export const NEW_MODAL_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('modal-scroll-strategy');

/** @docs-private */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function NEW_MODAL_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => ScrollStrategy {
    return () => overlay.scrollStrategies.block();
}

/** @docs-private */
export const NEW_MODAL_SCROLL_STRATEGY_PROVIDER = {
    provide: NEW_MODAL_SCROLL_STRATEGY,
    deps: [Overlay],
    useFactory: NEW_MODAL_SCROLL_STRATEGY_PROVIDER_FACTORY,
};
