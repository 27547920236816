import { SpecificationInterface } from '../interfaces';

export interface ClaimSpecificationState {
    specification: SpecificationInterface;
}

export const CLAIM_SPECIFICATION_FEATURE = 'claimSpecification';

export const INITIAL_CLAIM_SPECIFICATION_STATE: ClaimSpecificationState = {
    specification: null,
};
