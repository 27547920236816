import { ChatbotAnswerInterface, ChatbotLinkInterface, ChatbotVideoInterface } from '../interfaces';
import {
    ApiChatbotAnswerInterface,
    ApiChatbotLinkInterface,
    ApiChatbotResponseInterface,
    ApiChatbotVideoInterface,
} from '../interfaces/api';
import { ApiChatbotMetadataInterface } from '../interfaces/api/api-chatbot-metadata.interface';
import { ChatbotAnswerParseService } from '../services/chatbot-answer-parse.service';

function mapMetadataResponse(metadata: ApiChatbotMetadataInterface | string): { [key: string]: boolean } {
    // livechat does not contain livechat data this is a legacy name containing other channels,
    // for now the show livechat data is in the metadata.test property
    const livechatData = metadata['livechat'];
    const otherData = metadata['test'];
    const showChatDE = metadata['showChatDE'];


    const options: { [key: string]: string | boolean } = {
        ...(livechatData && JSON.parse(livechatData)),
        ...(otherData && JSON.parse(otherData)),
        ...(showChatDE && JSON.parse(showChatDE)),
    };

    const converted: { [key: string]: boolean } = {};
    Object.keys(options).forEach((key: string) => (converted[key] = options[key] === 'true' || options[key] === true));
    return converted;
}

function mapVideoResponse(video: ApiChatbotVideoInterface): ChatbotVideoInterface {
    return {
        id: video.id,
        name: video.name,
        videoId: video.additionalData.videoId,
    };
}

function mapLinkResponse(link: ApiChatbotLinkInterface): ChatbotLinkInterface {
    return {
        id: link.id,
        text: link.text,
        url: link.url,
        target: link.target,
    };
}

export function mapQuestionResponse(response: ApiChatbotResponseInterface): ChatbotAnswerInterface {
    const answer: ApiChatbotAnswerInterface = response.outputs[0].outputParts[0];
    const links: ChatbotLinkInterface[] = answer.links.map(mapLinkResponse);
    const videos: ChatbotVideoInterface[] = answer.videos.map(mapVideoResponse);
    const dialogPath: string = response.outputs[0].dialogPath;
    const service = new ChatbotAnswerParseService(links, videos);
    return {
        sessionId: response.sessionId,
        interactionId: response.interactionId,
        question: response.inputs.originalInputText,
        elements: service.parseAnswer(answer.text, dialogPath),
        escalation: mapMetadataResponse(answer.metadata),
    };
}
