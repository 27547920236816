// Change to ValidityStatusCodeEnum when MyZoneStoreModule is gone
export enum PolicyValidityStatusCodeEnum {
    All = 'Alle', // 'ALLE',
    Active = 'Active product', // 'ACTI',
    ActiveFuture = 'Active future product', // 'ACTI',
    ActiveEnded = 'Active ended product', // 'ACEI',
    ActiveChange = 'Active change product', // 'ACWI',
    Future = 'Future product', // 'TOEK',
    Transfer = 'Transfer product', // 'OSTP',
    Disbarred = 'Disbarred but not ended product', // 'NEVT',
    Ended = 'Ended product', // 'EIND',
    Unknown = 'Unknown validity status code',
}
