import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import { AggregateApiService, AggregateEventService } from '../services';
import {
    AggregateActions,
    AggregateActionsEnum,
    getAggregateErrorAction,
    getAggregateSuccessAction,
} from './aggregate.actions';
import { AggregateSelectors } from './aggregate.selectors';
import { Aggregate } from './aggregate.state';

@Injectable()
export class AggregateEffects {
    constructor(
        private action$: Actions<AggregateActions>,
        private store$: Store<Aggregate>,
        private aggregateApiService: AggregateApiService,
        private aggregateEventService: AggregateEventService
    ) {}

    public getAggregate$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(AggregateActionsEnum.GetAggregate),
            distinctUntilChanged((x, y) => {
                return x.ids.join(',') === y.ids.join(',');
            }),
            concatLatestFrom((action) => this.store$.select(AggregateSelectors.getAggregateById(action.ids.join(',')))),
            mergeMap(([action, aggregates]) => {
                if (aggregates && aggregates.length) {
                    return of(getAggregateSuccessAction(aggregates[0]));
                } else {
                    return this.aggregateApiService.getAggregate$(action).pipe(
                        map((response) => getAggregateSuccessAction(response)),
                        catchError((errors) => of(getAggregateErrorAction({ errors })))
                    );
                }
            })
        );
    });

    public getAggregateSuccess$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AggregateActionsEnum.GetAggregateSuccess),
                tap(() => this.aggregateEventService.onGetAggregateSuccess())
            );
        },
        { dispatch: false }
    );

    public getAggregateError$ = createEffect(
        () => {
            return this.action$.pipe(
                ofType(AggregateActionsEnum.GetAggregateError),
                map((action) => this.aggregateEventService.onGetAggregateError(action.errors))
            );
        },
        { dispatch: false }
    );
}
