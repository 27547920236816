import { mapFromSuccessIndication } from '../../core';
import { mapValidityStatusCodeResponse } from '../../insurance-policy';
import { ApiNaturalPersonInterface, NaturalPartyInterface } from '../interfaces';
import { mapBusinessRelationNaturalPartyVersionId } from './business-relation-natural-party-version-id.mapper';
import { mapNaturalPartyRoleResponse } from './natural-party-role.mapper';
import { mapNaturalPartyTypeCodeResponse } from './natural-party-type-code.mapper';
import { mapPersonRoleResponse } from './person-role.mapper';

export function mapNaturalPersonResponse(
    response: ApiNaturalPersonInterface[],
    enablePartyCodes?: boolean
): NaturalPartyInterface[] {
    if (!response) {
        return [];
    }

    return response.map((person) => ({
        businessRelationId: person.business_relation_id,
        businessRelationNaturalPartyVersionId: mapBusinessRelationNaturalPartyVersionId(
            person.business_relation_natural_person_version_id,
            enablePartyCodes
        ),
        naturalPartyTypeCode: mapNaturalPartyTypeCodeResponse(person.np_type_code, enablePartyCodes),
        naturalPartyTypeDescription: person.np_type_description,
        startDate: person.start_date,
        endDate: person.end_date,
        validityStatusCode: mapValidityStatusCodeResponse(person.validity_status_code),
        terminatedIndication: mapFromSuccessIndication(person.terminated_indication),
        terminatedIndicationDescription: person.terminated_indication_description,
        initials: person.initials,
        prefixLastname: person.prefix_lastname,
        surname: person.surname,
        registrationOrganizationId: person.registration_organization_id,
        registrationOrganizationIdDescription: person.registration_organization_id_description,
        registrationEmployeeId: person.registration_employee_id,
        registrationEmployeeIdDescription: person.registration_organization_id_description,
        role: {
            role: mapNaturalPartyRoleResponse(person.role.role, enablePartyCodes),
            roleDescription: person.role.rol_omschrijving,
            contact: mapPersonRoleResponse(person.role.contact_person),
            coInsured: mapPersonRoleResponse(person.role.co_insured),
            driver: mapPersonRoleResponse(person.role.driver),
            witness: mapPersonRoleResponse(person.role.witness),
            counterparty: mapPersonRoleResponse(person.role.counterparty),
            other: mapPersonRoleResponse(person.role.other_party),
        },
        roleDescription: person.rol_omschrijving,
    }));
}
