export enum ApiProcedureTypeCodeEnum {
    ProcedureDamageAssessment = 'PRSV',
    ProcedureDamageRepair = 'PRSH',
    ProcedureReplacementTransport = 'PVVV',
    ProcedurePremium = 'PPRE',
    LegalAdvice = 'JUAD',
    NotifyStichtingVerzekeringsbureauVoertuigcriminaliteit = 'MVBV',
    SummaryAndConfirmation = 'SABV',
    ClaimsHandling = 'CONT',
    NoExpert = 'GEXP',
    AppointmentDamageAssessment = 'AFSV',
    GreenCard = 'GRKA',
    AppointmentDamageRepair = 'AFSH',
    ReplacementTransport = 'VVOE',
    StichtingVBV = 'SVBV',
    TerminationPremium = 'SPRE',
    AppraiseRepairman = 'TXRE',
    ExpertPresent = 'WEXP',
    KeysAndRegistrationPapers = 'SLKE',
    WaitingPeriod = 'WCHT',
    TotalLoss = 'TOTL',
    DailyAllowance = 'DAGV',
    ExpertiseReport = 'EXRA',
    RepairBill = 'REKR',
    OwnRepairer = 'EIGH',
    RecoverFromCounterparty = 'VHTP',
    CounterpartyResponseTime = 'RETP',
    RecoverFromGuaranteeInstitution = 'VWBF',
    ClaimsSettlementCounterparty = 'SRTP',
    DamageAmount = 'HSBD',
    Payment = 'UITB',
    Send = 'STRN',
    PaymentExcess = 'BTER',
    RepairReady = 'REGE',
    NotRepairable = 'NREP',
    MoneyForDevice = 'GTOA',
    ReplaceDevice = 'VTOA',
    DamageFolder = 'SMAP',
    AnnualBonus = 'JBEL',
    ExcessRecovery = 'VEIG',
    ClaimFreeYearsAndBMLadder = 'SVBM',
    AffordableRepairViaFixico = 'RFIX',
    PhotosDamage = 'FFIX',
    ThreeQuotationsWithoutObligationWithinTwentyFourHours = 'OFIX',
    MakeYourChoice = 'KFIX',
    ProcedureClaimProcessing = 'PRSB',
    KeysAndDocuments = 'SLDO',
    ProcedureBonusMalusConsequences = 'PRBM',
    GuaranteeFundProcedure = 'PWBF',
}
