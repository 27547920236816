/**
 * @deprecated - use insurances enums
 */
export enum ScenarioCodeEnum {
    DamageFreeYears = 'SJVG', // Schadevrije jaren vragen
    VehicleReplacement = 'VEVO', // Vervangen
    CompareAddressDetails = 'VZWA', // Vergelijken opgegeven adresgegevens met het woonadres die bij ons bekend is.
    CompareAddressStartDate = 'VZID' /** Vergelijken opgegeven ingangsdatum met de ingangsdatum van het toekomstig
         woonadres die bij ons bekend is of in de ingangsdatum van het woonadres in de input. */,
}
