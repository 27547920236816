/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostBinding, Input, OnInit, Optional } from '@angular/core';
import { NewModalRef } from './new-modal-ref';
import { getModalRef } from './new-modal-utilities';
import { NewModal } from './new-modal.service';

/** Counter used to generate unique IDs for dialog elements. */
let modalElementUid = 0;

/**
 * Title of a dialog element. Stays fixed to the top of the dialog when scrolling.
 */
@Directive({
    selector: '[modal-title], [modalTitle]',
    exportAs: 'modalTitle',
})
export class NewModalTitleDirective implements OnInit {
    @Input() public id: string = `modal-title-${modalElementUid++}`;

    @HostBinding('class') public _class = 'modal__title';
    @HostBinding('id') public _id = this.id;

    constructor(
        @Optional() private _modalRef: NewModalRef<any>,
        private _elementRef: ElementRef<HTMLElement>,
        private _modal: NewModal
    ) {}

    public ngOnInit(): void {
        if (!this._modalRef) {
            this._modalRef = getModalRef(this._elementRef, this._modal);
        }

        if (this._modalRef) {
            Promise.resolve().then(() => {
                const container = this._modalRef._containerInstance;

                if (container && !container._ariaLabelledBy) {
                    container._ariaLabelledBy = this.id;
                }
            });
        }
    }
}
