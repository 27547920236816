import { mapSequenceNumber } from '../../core';
import { ApiSpecificationClaimObjectInterface, SpecificationClaimObjectInterface } from '../interfaces';

export function mapSpecificationClaimObjectResponse(
    response: ApiSpecificationClaimObjectInterface[]
): SpecificationClaimObjectInterface[] {
    if (!response) {
        return [];
    }

    return response.map((item: ApiSpecificationClaimObjectInterface) => ({
        claimObjectId: item.claim_object_id,
        claimObjectDescription: item.claim_object_description,
        sustainedDamageSequenceNumber: mapSequenceNumber(item.sustained_damage_sequence_number),
    }));
}
