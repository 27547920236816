/**
 * @deprecated - use insurances enums
 */
export enum ProductIdEnum {
    Liability = 'AANS',
    Car = 'AUTO',
    Moped = 'BROM',
    Scooter = 'SCOOTER',
    Caravan = 'CVAN',
    Bicycle = 'FIET',
    Pet = 'DIER',
    Motorcycle = 'MOTR',
    Accidents = 'ONGV',
    Legal = 'RB',
    Travel = 'REIS',
    Home = 'WOON',
    Furniture = 'INBOEDEL',
    Building = 'OPSTAL',
}
