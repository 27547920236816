import { ApiClaimCoverageImpactLongTermResponseInterface, ClaimCoverageImpactLongTermInterface } from '../interfaces';

export function mapFinancialImpactLongTermResponse(
    response: ApiClaimCoverageImpactLongTermResponseInterface[]
): ClaimCoverageImpactLongTermInterface[] {
    if (!response) {
        return [];
    }

    return response.map((item) => ({
        netPremiumInclusive: item.netto_premie_incl,
        differenceIncExcl: item.verschil_inc_excl,
        netPremiumExclusive: item.netto_premie_excl,
        years: item.jaren,
    }));
}
