import { ApiTerminateReasonEnum, TerminateReasonEnum } from '../enums';

export function mapTerminateReasonRequest(request: TerminateReasonEnum): ApiTerminateReasonEnum {
    switch (request) {
        case TerminateReasonEnum.Deregistration:
            return ApiTerminateReasonEnum.Deregistration;
        case TerminateReasonEnum.ChangeOfInsurer:
            return ApiTerminateReasonEnum.ChangeOfInsurer;
        case TerminateReasonEnum.AfterAClaim:
            return ApiTerminateReasonEnum.AfterAClaim;
        case TerminateReasonEnum.DecommissioningWithSuspension:
            return ApiTerminateReasonEnum.DecommissioningWithSuspension;
        case TerminateReasonEnum.PremiumIncrease:
            return ApiTerminateReasonEnum.PremiumIncrease;
        case TerminateReasonEnum.ChangeOfTermsAndConditions:
            return ApiTerminateReasonEnum.ChangeOfTermsAndConditions;
        case TerminateReasonEnum.InsureSameCarAgain:
            return ApiTerminateReasonEnum.InsureSameCarAgain;
        case TerminateReasonEnum.Other:
            return ApiTerminateReasonEnum.Other;
        default:
            return ApiTerminateReasonEnum.Other;
    }
}
