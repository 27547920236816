import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { InsurancePolicyApiService } from '../services/insurance-policy.api.service';
import { InsurancePolicyEventService } from '../services/insurance-policy.event.service';
import * as InsurancePolicyActions from './insurance-policy.actions';

@Injectable()
export class InsurancePolicyEffects {
    // Product
    public product$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.product),
            exhaustMap(({ request }) =>
                this.insurancesApiService.product$(request).pipe(
                    map((response) => InsurancePolicyActions.productSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.productFailure({ errors })))
                )
            )
        );
    });

    public productSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.productSuccess),
                tap(() => this.insurancesEventService.onProductSuccess())
            );
        },
        { dispatch: false }
    );

    public policyError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.policyFailure),
                tap(({ errors }) => this.insurancesEventService.onPolicyError(errors))
            );
        },
        { dispatch: false }
    );

    // Policy
    public policy$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.policy),
            exhaustMap(() =>
                this.insurancesApiService.policy$().pipe(
                    map((response) => InsurancePolicyActions.policySuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.policyFailure({ errors })))
                )
            )
        );
    });

    public policySuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.policySuccess),
                tap(() => this.insurancesEventService.onPolicySuccess())
            );
        },
        { dispatch: false }
    );

    public onPolicyError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.policyFailure),
                tap(({ errors }) => this.insurancesEventService.onPolicyError(errors))
            );
        },
        { dispatch: false }
    );

    public productDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.productDetails),
            mergeMap(({ request }) =>
                this.insurancesApiService.productDetails$(request).pipe(
                    map((response) => InsurancePolicyActions.productDetailsSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.productDetailsFailure({ errors })))
                )
            )
        );
    });

    public productDetailsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.productDetailsSuccess),
                tap(() => this.insurancesEventService.onProductDetailsSuccess())
            );
        },
        { dispatch: false }
    );

    public productDetailsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.productDetailsFailure),
                tap(({ errors }) => this.insurancesEventService.onProductDetailsError(errors))
            );
        },
        { dispatch: false }
    );

    public allProductDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.allProductDetails),
            mergeMap(({ request }) =>
                this.insurancesApiService.allProductDetails$(request).pipe(
                    map((response) => InsurancePolicyActions.allProductDetailsSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.allProductDetailsFailure({ errors })))
                )
            )
        );
    });

    public allProductDetailsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.allProductDetailsSuccess),
                tap(() => this.insurancesEventService.onAllProductDetailsSuccess())
            );
        },
        { dispatch: false }
    );

    public allProductDetailsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.allProductDetailsFailure),
                tap(({ errors }) => this.insurancesEventService.onAllProductDetailsError(errors))
            );
        },
        { dispatch: false }
    );

    public allCoverages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.allCoverages),
            exhaustMap(({ request }) =>
                this.insurancesApiService.allCoverages$(request).pipe(
                    map((response) => InsurancePolicyActions.allCoveragesSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.allCoveragesFailure({ errors })))
                )
            )
        );
    });

    public allCoveragesSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.allCoveragesSuccess),
                tap(() => this.insurancesEventService.onAllCoveragesSuccess())
            );
        },
        { dispatch: false }
    );

    public allCoveragesError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.allCoveragesFailure),
                tap(({ errors }) => this.insurancesEventService.onAllCoveragesError(errors))
            );
        },
        { dispatch: false }
    );

    public coverage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.coverage),
            mergeMap(({ request }) =>
                this.insurancesApiService.coverage$(request).pipe(
                    map((response) => InsurancePolicyActions.coverageSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.coverageFailure({ errors })))
                )
            )
        );
    });

    public coverageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.coverageSuccess),
                tap(() => this.insurancesEventService.onCoverageSuccess())
            );
        },
        { dispatch: false }
    );

    public coverageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.coverageFailure),
                tap(({ errors }) => this.insurancesEventService.onCoverageError(errors))
            );
        },
        { dispatch: false }
    );

    public reviveProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.reviveProduct),
            exhaustMap(({ request }) =>
                this.insurancesApiService.reviveProduct$(request).pipe(
                    map((response) => InsurancePolicyActions.reviveProductSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.reviveProductFailure({ errors })))
                )
            )
        );
    });

    public reviveProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.reviveProductSuccess),
                tap(() => this.insurancesEventService.onReviveProductSuccess())
            );
        },
        { dispatch: false }
    );

    public reviveProductError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.reviveProductFailure),
                tap(({ errors }) => this.insurancesEventService.onReviveProductError(errors))
            );
        },
        { dispatch: false }
    );

    public startDateProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.startDateProduct),
            exhaustMap(({ request }) =>
                this.insurancesApiService.startDateProduct$(request).pipe(
                    map((response) => InsurancePolicyActions.startDateProductSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.startDateProductFailure({ errors })))
                )
            )
        );
    });

    public startDateProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.startDateProductSuccess),
                tap(() => this.insurancesEventService.onStartDateProductSuccess())
            );
        },
        { dispatch: false }
    );

    public startDateProductError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.startDateProductFailure),
                tap(({ errors }) => this.insurancesEventService.onStartDateProductError(errors))
            );
        },
        { dispatch: false }
    );

    public endProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.endProduct),
            exhaustMap(({ request }) =>
                this.insurancesApiService.endProduct$(request).pipe(
                    map((response) => InsurancePolicyActions.endProductSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.endProductFailure({ errors })))
                )
            )
        );
    });

    public endProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.endProductSuccess),
                tap(() => this.insurancesEventService.onEndProductSuccess())
            );
        },
        { dispatch: false }
    );

    public endProductError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.endProductFailure),
                tap(({ errors }) => this.insurancesEventService.onEndProductError(errors))
            );
        },
        { dispatch: false }
    );

    public endDateProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.endDateProduct),
            exhaustMap(({ request }) =>
                this.insurancesApiService.endDateProduct$(request).pipe(
                    map((response) => InsurancePolicyActions.endDateProductSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.endDateProductFailure({ errors })))
                )
            )
        );
    });

    public endDateProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.endDateProductSuccess),
                tap(() => this.insurancesEventService.onEndDateProductSuccess())
            );
        },
        { dispatch: false }
    );

    public endDateProductError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.endDateProductFailure),
                tap(({ errors }) => this.insurancesEventService.onEndDateProductError(errors))
            );
        },
        { dispatch: false }
    );

    public cancelAddedProduct$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.cancelAddedProduct),
            exhaustMap(({ request }) =>
                this.insurancesApiService.cancelAddedProduct$(request).pipe(
                    map((response) => InsurancePolicyActions.cancelAddedProductSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.cancelAddedProductFailure({ errors })))
                )
            )
        );
    });

    public cancelAddedProductSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedProductSuccess),
                tap(() => this.insurancesEventService.onCancelAddedProductSuccess())
            );
        },
        { dispatch: false }
    );

    public cancelAddedProductError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedProductFailure),
                tap(({ errors }) => this.insurancesEventService.onCancelAddedProductError(errors))
            );
        },
        { dispatch: false }
    );

    public addPremiumDeterminingFactor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.addPremiumDeterminingFactor),
            exhaustMap(({ request }) =>
                this.insurancesApiService.addPremiumDeterminingFactor$(request).pipe(
                    map((response) => InsurancePolicyActions.addPremiumDeterminingFactorSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.addPremiumDeterminingFactorFailure({ errors })))
                )
            )
        );
    });

    public addPremiumDeterminingFactorSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.addPremiumDeterminingFactorSuccess),
                tap(() => this.insurancesEventService.onAddPremiumDeterminingFactorSuccess())
            );
        },
        { dispatch: false }
    );

    public addPremiumDeterminingFactorError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.addPremiumDeterminingFactorFailure),
                tap(({ errors }) => this.insurancesEventService.onAddPremiumDeterminingFactorError(errors))
            );
        },
        { dispatch: false }
    );

    public cancelAddedPremiumDeterminingFactor$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.cancelAddedPremiumDeterminingFactor),
            exhaustMap(({ request }) =>
                this.insurancesApiService.cancelAddedPremiumDeterminingFactor$(request).pipe(
                    map((response) => InsurancePolicyActions.cancelAddedPremiumDeterminingFactorSuccess({ response })),
                    catchError((errors) =>
                        of(InsurancePolicyActions.cancelAddedPremiumDeterminingFactorFailure({ errors }))
                    )
                )
            )
        );
    });

    public cancelAddedPremiumDeterminingFactorSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedPremiumDeterminingFactorSuccess),
                tap(() => this.insurancesEventService.onCancelAddedPremiumDeterminingFactorSuccess())
            );
        },
        { dispatch: false }
    );

    public cancelAddedPremiumDeterminingFactorError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedPremiumDeterminingFactorFailure),
                tap(({ errors }) => this.insurancesEventService.onCancelAddedPremiumDeterminingFactorError(errors))
            );
        },
        { dispatch: false }
    );

    public addRegularDriver$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.addRegularDriver),
            exhaustMap(({ request }) =>
                this.insurancesApiService.addRegularDriver$(request).pipe(
                    map((response) => InsurancePolicyActions.addRegularDriverSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.addRegularDriverFailure({ errors })))
                )
            )
        );
    });

    public addRegularDriverSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.addRegularDriverSuccess),
                tap(() => this.insurancesEventService.onAddRegularDriverSuccess())
            );
        },
        { dispatch: false }
    );

    public addRegularDriverError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.addRegularDriverFailure),
                tap(({ errors }) => this.insurancesEventService.onAddRegularDriverError(errors))
            );
        },
        { dispatch: false }
    );

    public endRegularDriver$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.endRegularDriver),
            exhaustMap(({ request }) =>
                this.insurancesApiService.endRegularDriver$(request).pipe(
                    map((response) => InsurancePolicyActions.endRegularDriverSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.endRegularDriverFailure({ errors })))
                )
            )
        );
    });

    public endRegularDriverSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.endRegularDriverSuccess),
                tap(() => this.insurancesEventService.onEndRegularDriverSuccess())
            );
        },
        { dispatch: false }
    );

    public endRegularDriverError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.endRegularDriverFailure),
                tap(({ errors }) => this.insurancesEventService.onEndRegularDriverError(errors))
            );
        },
        { dispatch: false }
    );

    public cancelAddedRegularDriver$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.cancelAddedRegularDriver),
            exhaustMap(({ request }) =>
                this.insurancesApiService.cancelAddedRegularDriver$(request).pipe(
                    map((response) => InsurancePolicyActions.cancelAddedRegularDriverSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.cancelAddedRegularDriverFailure({ errors })))
                )
            )
        );
    });

    public cancelAddedRegularDriverSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedRegularDriverSuccess),
                tap(() => this.insurancesEventService.onCancelAddedRegularDriverSuccess())
            );
        },
        { dispatch: false }
    );

    public cancelAddedRegularDriverError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedRegularDriverFailure),
                tap(({ errors }) => this.insurancesEventService.onCancelAddedRegularDriverError(errors))
            );
        },
        { dispatch: false }
    );

    public addCoverage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.addCoverage),
            exhaustMap(({ request }) =>
                this.insurancesApiService.addCoverage$(request).pipe(
                    map((response) => InsurancePolicyActions.addCoverageSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.addCoverageFailure({ errors })))
                )
            )
        );
    });

    public addCoverageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.addCoverageSuccess),
                tap(() => this.insurancesEventService.onAddCoverageSuccess())
            );
        },
        { dispatch: false }
    );

    public addCoverageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.addCoverageFailure),
                tap(({ errors }) => this.insurancesEventService.onAddCoverageError(errors))
            );
        },
        { dispatch: false }
    );

    public cancelAddedCoverage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InsurancePolicyActions.cancelAddedCoverage),
            exhaustMap(({ request }) =>
                this.insurancesApiService.cancelAddedCoverage$(request).pipe(
                    map((response) => InsurancePolicyActions.cancelAddedCoverageSuccess({ response })),
                    catchError((errors) => of(InsurancePolicyActions.cancelAddedCoverageFailure({ errors })))
                )
            )
        );
    });

    public cancelAddedCoverageSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedCoverageSuccess),
                tap(() => this.insurancesEventService.onCancelAddedCoverageSuccess())
            );
        },
        { dispatch: false }
    );

    public cancelAddedCoverageError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InsurancePolicyActions.cancelAddedCoverageFailure),
                tap(({ errors }) => this.insurancesEventService.onCancelAddedCoverageError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private insurancesApiService: InsurancePolicyApiService,
        private insurancesEventService: InsurancePolicyEventService
    ) {}
}
