import { PurchasedProductInterface } from '../interfaces';

// Merge new product with existing state product
export function mergeProductUtility(
    product: PurchasedProductInterface,
    stateProducts: PurchasedProductInterface[]
): PurchasedProductInterface[] {
    const newProducts = stateProducts.map((currentProduct) => {
        if (currentProduct.productSequenceNumber === product.productSequenceNumber) {
            return {
                ...currentProduct,
                ...product,
            };
        }

        return currentProduct;
    });

    return newProducts;
}
