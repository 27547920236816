import { ApiCreateRelationRecoveryDataRequest } from '../interfaces/api.create-relation-recovery-data.request';
import { CreateRelationRecoveryDataRequestInterface } from '../interfaces/create-relation-recovery-data.request.interface';

export function mapCreateRelationRecoveryDataRequest(
    request: CreateRelationRecoveryDataRequestInterface
): ApiCreateRelationRecoveryDataRequest {
    return {
        relation: {
            recovery_email_address: {
                email_address: request?.emailAddress,
            },
            mobile_telephone_number: {
                telephone_number: request?.telephoneNumber,
            },
        },
    };
}
