import * as internationalMotorInsuranceCardActions from './international-motor-insurance-card.actions';
import { InternationalMotorInsuranceCardActionsEnum } from './international-motor-insurance-card.actions';
import {
    InternationalMotorInsuranceCardState,
    INTERNATIONAL_MOTOR_INSURANCE_CARD_INITIAL_STATE,
} from './international-motor-insurance-card.state';

export function internationalMotorInsuranceCardReducer(
    state: InternationalMotorInsuranceCardState = INTERNATIONAL_MOTOR_INSURANCE_CARD_INITIAL_STATE,
    action: internationalMotorInsuranceCardActions.Actions
): InternationalMotorInsuranceCardState {
    switch (action.type) {
    case InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCard: {
        return {
            ...state,
            selectedId: action.payload.productSequenceNumber,
        };
    }

    case InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCardSuccess: {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            emailInternationalMotorInsuranceCard: action.payload.email,
        };
    }

    case InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCard: {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            selectedId: action.payload.productSequenceNumber,
        };
    }

    case InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCardSuccess: {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            pdfInternationalMotorInsuranceCard: action.payload.pdf,
        };
    }

    case InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCard: {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            selectedId: action.payload.productSequenceNumber,
            caravanBrand: action.payload.caravanBrand,
            licensePlate: action.payload.licensePlate,
        };
    }

    case InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCardSuccess: {
        if (!action.payload) {
            return state;
        }

        return {
            ...state,
            pdfInternationalMotorInsuranceCard: action.payload.pdf,
        };
    }

    case InternationalMotorInsuranceCardActionsEnum.ResetInternationalMotorInsuranceCard: {
        return INTERNATIONAL_MOTOR_INSURANCE_CARD_INITIAL_STATE;
    }

    default: {
        return state;
    }
    }
}
