import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PolicyProductIdEnum } from '../enums';
import { PurchasedProductInterface } from '../interfaces';
import { InsurancePolicySelectors, InsurancePolicyState } from '../store';

@Injectable({
    providedIn: 'root',
})
export class InsurancePolicyDataService {
    constructor(private store$: Store<InsurancePolicyState>) {}

    public getState$(): Observable<InsurancePolicyState> {
        return this.store$.select(InsurancePolicySelectors.getState);
    }

    public getPurchasedProducts$(): Observable<PurchasedProductInterface[]> {
        return this.store$.select(InsurancePolicySelectors.getPurchasedProducts);
    }

    public getPurchasedProduct$(productSequenceNumber: number): Observable<PurchasedProductInterface> {
        return this.store$.select(InsurancePolicySelectors.getPurchasedProduct(productSequenceNumber));
    }

    public getPurchasedProductsByProductId$(productId: PolicyProductIdEnum): Observable<PurchasedProductInterface[]> {
        return this.store$.select(InsurancePolicySelectors.getPurchasedProductsByProductId(productId));
    }

    public getTotal$(): Observable<number> {
        return this.store$.select(InsurancePolicySelectors.getTotal);
    }
}
