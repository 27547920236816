export enum BonusComponentIdEnum {
    AlarmRegular = 'Alarm Regular', // Alarm
    AlarmSerure = 'Alarm Secure', // Alarm (klasse SCM 2 of hoger)
    DriversLicense = 'Drivers License', // Rijbewijs BE
    DrivingTraining = 'Driving Training', // Rij Opleiding VRO-I
    EnergyLable = 'Energy Lable', // Energielabel A of B
    ParkingSensors = 'Parking Sensors', // Parkeersensoren
    PreventativeCare = 'Preventative Care', // Preventieve zorg
    RegularKeyLock = 'Regular Key Lock', // Extra goed slot
    SecureKeyLock = 'Secure Key Lock', // Slot
    SmokeDetecors = 'Smoke Detectors', // Rookmelders
    TestForWWW = 'Test for WWW', // Test voor WWW
    WaterStop = 'Water Stop', // Waterstop in (vaat)wasautomaat
    WinterStorage = 'Winter Storage', // Winterstalling
    WinterTires = 'Winter Tires', // Winterbanden
}
