import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CLAIM_STEPS_FEATURE, ClaimStepsState } from './claim-steps.state';

export class ClaimStepsSelectors {
    public static getClaimStepsState = createFeatureSelector<ClaimStepsState>(CLAIM_STEPS_FEATURE);

    public static getState = createSelector(ClaimStepsSelectors.getClaimStepsState, (state: ClaimStepsState) => state);

    public static getClaimSteps = createSelector(
        ClaimStepsSelectors.getClaimStepsState,
        (state: ClaimStepsState) => state.claimSteps
    );
}
