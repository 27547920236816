import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgForage } from 'ngforage';
import { from, map, Observable } from 'rxjs';
import { Coverage, Customer, Insurance, MotorVehiclesToReplace } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class SalesStorageService {
    constructor(private ngForage: NgForage, @Inject(PLATFORM_ID) private platformId: any) {}

    public getItem(key: string): Promise<unknown> {
        if (isPlatformServer(this.platformId)) {
            return Promise.resolve(null);
        }
        return this.ngForage.getItem(key);
    }

    public setItem(key: string, data: unknown): Promise<unknown> {
        if (isPlatformServer(this.platformId)) {
            return Promise.resolve(null);
        }
        return this.ngForage.setItem(key, data);
    }

    public removeItem(key: string): Promise<void> {
        if (isPlatformServer(this.platformId)) {
            return Promise.resolve();
        }
        return this.ngForage.removeItem(key);
    }

    public save(
        productId: string,
        insurance?: Insurance,
        customer?: Customer,
        vehicleReplace?: MotorVehiclesToReplace
    ): void {
        if (insurance) {
            this.getInsurance().then((insurances) => {
                this.setItem('insurances', {
                    ...insurances,
                    [productId]: insurance,
                });
            });
        }
        if (customer) {
            this.setItem('customer', {
                ...customer,
                password: null,
                direct_debit_agreement_indication: null,
            });
        }

        if (vehicleReplace) {
            this.setItem('vehicleReplace', vehicleReplace);
        }
    }

    public getUpdatedCustomer$(customerDetails: Partial<Customer>): Observable<Customer> {
        return this.getCustomer$().pipe(
            map((customer) => ({
                ...customer,
                ...customerDetails,
            }))
        );
    }

    public clear(clearAll: boolean = false): Promise<void[]> {
        const promises: Promise<void>[] = [];

        promises.push(this.removeItem('insurances'));
        promises.push(this.removeItem('customer'));

        promises.push(this.removeItem('coverages'));
        promises.push(this.removeItem('isReturningCustomer'));

        if (clearAll) {
            promises.push(this.removeItem('calculation'));
            promises.push(this.removeItem('progress'));
        }

        // zodra we voor het formulier gewoon insurance gebruiken hoeft vehicle niet meer weg,
        // dan werkt het gewoon als cache
        promises.push(this.removeItem('vehicle'));
        promises.push(this.removeItem('vehicleReplace'));
        promises.push(this.removeItem('vehicleReplaceChoice'));

        return Promise.all(promises);
    }

    public getInsurance(): Promise<{ [productId: string]: Insurance }> {
        return this.getItem('insurances') as Promise<{ [productId: string]: Insurance }>;
    }

    public getCustomer(): Promise<Customer> {
        return this.getItem('customer') as Promise<Customer>;
    }

    public isReturningCustomer(): Promise<boolean> {
        return this.getItem('isReturningCustomer') as Promise<boolean>;
    }

    public getCoverage(): Promise<{
        [productId: string]: { productId: string; modules: string[]; coverage: Coverage[] };
    }> {
        return this.getItem('coverages') as Promise<{
            [productId: string]: { productId: string; modules: string[]; coverage: Coverage[] };
        }>;
    }

    /**
     * Storage data as observables
     */
    public getInsurance$(): Observable<{ [productId: string]: Insurance }> {
        return from(this.getInsurance());
    }

    public getCustomer$(): Observable<Customer> {
        return from(this.getCustomer());
    }

    public isReturningCustomer$(): Observable<boolean> {
        return from(this.isReturningCustomer());
    }

    public getCoverage$(): Observable<{
        [productId: string]: { productId: string; modules: string[]; coverage: Coverage[] };
    }> {
        return from(this.getCoverage());
    }
}
