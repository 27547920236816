import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { ContentElementsInterface } from '../interfaces';
import { Content, ContentState, CONTENT_FEATURE } from './content.state';

export class ContentSelectors {
    public static getContentState = createFeatureSelector<ContentState>(CONTENT_FEATURE);

    public static getContentElements: Selector<Content, ContentElementsInterface> = createSelector(
        ContentSelectors.getContentState,
        (state: ContentState) => state.contentElements
    );

    public static getIds: Selector<Content, string[]> = createSelector(
        ContentSelectors.getContentElements,
        (contentElements: ContentElementsInterface) => Object.keys(contentElements)
    );
}
