import { ClaimNotificationActionEnum, ClaimNotificationActionType } from './claim-notification.actions';
import { ClaimNotificationState, INITIAL_CLAIM_NOTIFICATION_STATE } from './claim-notification.state';

export class ClaimNotificationReducers {
    public static reducer(
        state: ClaimNotificationState = INITIAL_CLAIM_NOTIFICATION_STATE,
        action: ClaimNotificationActionType
    ): ClaimNotificationState {
        switch (action.type) {
        case ClaimNotificationActionEnum.GetClaimNotificationSuccess:
        case ClaimNotificationActionEnum.SaveClaimNotificationSuccess: {
            const caseSequenceNumber = action.payload.response.caseSequenceNumber;
            const notifications = state.notifications.filter(
                (notification) => notification.caseSequenceNumber !== caseSequenceNumber
            );

            notifications.push(action.payload.response);

            return {
                ...state,
                notifications,
            };
        }

        case ClaimNotificationActionEnum.ResetClaimNotification: {
            return INITIAL_CLAIM_NOTIFICATION_STATE;
        }

        default: {
            return state;
        }
        }
    }
}
