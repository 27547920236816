import { mapFromSuccessIndication } from '../../core';
import { mapCoverageIdResponse } from '../../insurance-policy';
import { ApiClaimCoverageCoveredResponseInterface, ClaimCoverageCoveredInterface } from '../interfaces';

export function mapNoCoverageResponse(
    response: ApiClaimCoverageCoveredResponseInterface[]
): ClaimCoverageCoveredInterface[] {
    if (!response) {
        return [];
    }

    return response.map((coverage) => ({
        coverageId: mapCoverageIdResponse(coverage.coverage_id),
        coverageDescription: coverage.coverage_description,
        claimCoverageNoteCodeDescription: coverage.claim_coverage_note_code_description,
        coveredIndication: mapFromSuccessIndication(coverage.covered_indication),
        coveredIndicationDescription: coverage.covered_indication_description,
        claimObjectDescription: coverage.claim_object_description,
    }));
}
