import { mapClaimCategoryIdResponse } from '../../claim-categories';
import { ApiSpecificationInterface, SpecificationInterface } from '../interfaces';
import { mapClaimObjectTypeCodeResponse } from './claim-object-type-code.mapper';
import { mapSpecificationCaseResponse } from './specification-case.mapper';
import { mapSpecificationClaimObjectResponse } from './specification-claim-object.mapper';
import { mapSpecificationGroupResponse } from './specification-group.mapper';
/* Autofixer creates line with max-lenght of over 120 */
/* prettier-ignore */
import {
    mapSpecificationMethodClaimObjectAdditionCodeResponse,
} from './specification-method-claim-object-addition-code.mapper';

export function mapSpecificationResponse(response: ApiSpecificationInterface): SpecificationInterface | null {
    if (!response) {
        return null;
    }

    return {
        case: mapSpecificationCaseResponse(response.case),
        claimObjects: mapSpecificationClaimObjectResponse(response.claim_object),
        claimObjectTypeCode: mapClaimObjectTypeCodeResponse(response.claim_object_type_code),
        claimObjectTypeDescription: response.claim_object_type_description,
        claimObjectTypeNoteCode: response.claim_object_type_note_code,
        claimObjectTypeNoteDescription: response.claim_object_type_note_description,
        groups: mapSpecificationGroupResponse(response.group),
        damageTypeDescription: response.kind_of_loss_description,
        damageTypeId: response.kind_of_loss_id,
        claimCategoryDescription: response.kind_of_loss_group_description,
        claimCategoryId: mapClaimCategoryIdResponse(response.kind_of_loss_group_id),
        methodClaimObjectAdditionCode: mapSpecificationMethodClaimObjectAdditionCodeResponse(
            response.method_claim_object_addition_code
        ),
        methodClaimObjectAdditionDescription: response.method_claim_object_addition_description,
    };
}
