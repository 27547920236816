import { createSelector } from '@ngrx/store';
import { ScenarioInterface } from '../../interfaces';
import * as fromScenarios from './scenarios.actions';

export interface State {
    entities: { [id: string]: ScenarioInterface };
    ids: string[];
    loaded: boolean;
    loading: boolean;
}

export const initialState: State = {
    entities: {},
    ids: [],
    loaded: false,
    loading: false,
};

export function reducer(state = initialState, action: fromScenarios.Actions): State {
    switch (action.type) {
    case fromScenarios.LOAD: {
        return Object.assign({}, state, {
            loading: true,
            loaded: false,
        });
    }

    case fromScenarios.LOAD_SUCCESS: {
        const scenarios = action.payload.scenarios || [];
        const ids = scenarios.map((scenario) => scenario.scenario_code);

        const entities = scenarios.reduce(
            (entities: { [id: string]: ScenarioInterface }, scenario: ScenarioInterface) => {
                return Object.assign(entities, {
                    [scenario.scenario_code]: scenario,
                });
            },
            {}
        );

        return Object.assign({}, state, {
            entities,
            ids,
            loading: false,
            loaded: true,
        });
    }

    case fromScenarios.LOAD_ERROR: {
        return {
            ...state,
            loading: false,
        };
    }

    case fromScenarios.CLEAR: {
        return initialState;
    }

    default: {
        return state;
    }
    }
}

export const getEntities = (state: State): State['entities'] => state.entities;
export const getIds = (state: State): State['ids'] => state.ids;
export const getAll = createSelector(getEntities, getIds, (entities, ids) => ids.map((id) => entities[id]));
