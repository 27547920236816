import { Coverage } from '../../interfaces';
import * as calculationActions from '../calculation/calculation.actions';
import * as insuranceActions from '../insurance/insurance.actions';
import * as progressActions from '../progress/progress.actions';
import * as coverageActions from './coverage.actions';

export interface State {
    entities: { [key: string]: Coverage[] };
    modules: { [key: string]: string[] };
    ids: string[];
}

const initialState: State = {
    entities: {},
    modules: {},
    ids: [],
};

export function reducer(
    state: State = initialState,
    action: coverageActions.CoverageActionUnion | insuranceActions.InsuranceActionUnion |
        calculationActions.CalculationActionUnion | progressActions.ProgressActionsUnion,
): State {
    switch (action.type) {

        case progressActions.INIT_SUCCESS: {
            const payload = action.payload;

            return {
                ...state,
                modules: {
                    ...state.modules,
                    [payload.productId]: payload.coverage.map((coverage) => coverage.coverage_id),
                },
                entities: {
                    ...state.entities,
                    [payload.productId]: payload.coverage,
                },
                ids: [
                    ...new Set([...state.ids, payload.productId]),
                ],
            };
        }

        case coverageActions.UPDATE_RECEIPT_SUCCESS: {
            const payload = action.payload;

            return {
                ...state,
                modules: {
                    ...state.modules,
                    [payload.productId]: payload.modules,
                },
                entities: {
                    ...state.entities,
                    [payload.productId]: payload.coverage,
                },
                ids: [
                    ...new Set([...state.ids, payload.productId]),
                ],
            };
        }

        case calculationActions.PREMIUM_UPDATED_SUCCESS: {
            const entities = state.entities[action.payload.productId];
            const modules = state.modules[action.payload.productId];
            const productXsell = action.payload.premium.product.xsell || [];
            const productCoverages = [
                ...action.payload.premium.product.base_coverages,
                ...action.payload.premium.product.additional_coverage || [],
            ];

            // Maak lijst met (geaccepteerde) coverage ids
            const calculationCoverageIds = productCoverages
                .filter((coverage) => coverage.eligibility.accepted_indication === 'J')
                .map((coverage) => coverage.coverage_id);

            // Als er xsell producten zijn neem ze dan mee als (geaccepteerde) coverage ids
            if (productXsell.length > 0) {
                productXsell.forEach((products) => {
                    products.coverages.forEach((coverage) => {
                        calculationCoverageIds.push(coverage.coverage_id);
                    });
                });
            }

            // Huidige entities filteren obv (geaccepteerde) ids
            const newEntities: Coverage[] = entities.filter(
                (entity) => calculationCoverageIds.includes(entity.coverage_id),
            );

            // Huidige modules filteren obv (geaccepteerde) ids
            const newModules: string[] = modules.filter((id) => calculationCoverageIds.includes(id));

            return {
                ...state,
                modules: {
                    ...state.modules,
                    [action.payload.productId]: newModules,
                },
                entities: {
                    ...state.entities,
                    [action.payload.productId]: newEntities,
                },
            };
        }

        case insuranceActions.CLEAR_INSURANCE_DATA: {
            return {
                ...initialState,
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

export const getEntities = (state: State) => state.entities;
export const getIds = (state: State) => state.ids;
export const getModules = (state: State) => state.modules;
