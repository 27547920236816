import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClaimStepsRequestInterface } from '../interfaces';
import { ClaimStepsState } from '../store';
import * as claimStepsActions from '../store/claim-steps.actions';
import { ClaimStepsEventService } from './claim-steps.event.service';

@Injectable({
    providedIn: 'root',
})
export class ClaimStepsActionService {
    constructor(private store$: Store<ClaimStepsState>, private claimStepsEventService: ClaimStepsEventService) {}

    public loadClaimSteps$(request: ClaimStepsRequestInterface): Observable<void> {
        this.store$.dispatch(claimStepsActions.loadClaimSteps({ request }));
        return this.claimStepsEventService.loadClaimSteps$();
    }

    public clearClaimSteps(): void {
        this.store$.dispatch(claimStepsActions.clearClaimSteps());
    }
}
