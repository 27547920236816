import { ClaimNotificationInterface } from '../interfaces/claim-notification.interface';

export interface ClaimNotification {
    ClaimNotification: ClaimNotificationState;
}

export interface ClaimNotificationState {
    notifications: ClaimNotificationInterface[];
}

export const INITIAL_CLAIM_NOTIFICATION_STATE: ClaimNotificationState = {
    notifications: [],
};

export const CLAIM_NOTIFICATION_FEATURE = 'claimNotification';
