import { SpecificationGroupSpecInterface, SpecificationInterface } from '../interfaces';

export function mergeSpecificationUtility(
    response: SpecificationInterface,
    state: SpecificationInterface
): SpecificationInterface {
    const stateGroups = [...state.groups];
    const responseGroups = response?.groups ?? [];

    if (responseGroups.length < 1) {
        return state;
    }

    stateGroups.forEach((groupItem, index) => {
        const groupUpdate = responseGroups.find(
            (update) => update.specificationGroupDescription === groupItem.specificationGroupDescription
        );

        if (groupUpdate) {
            /** Check if specification has changed */
            if (groupUpdate.specification && groupUpdate.specification.length) {
                const specifications: SpecificationGroupSpecInterface[] = [];

                for (const spec of groupItem.specification) {
                    const specUpdate = groupUpdate.specification.find(
                        (specification) => specification.elementId === spec.elementId
                    );

                    if (specUpdate) {
                        specifications.push(specUpdate);
                    } else {
                        specifications.push(spec);
                    }
                }

                groupItem = Object.assign({}, groupItem, {
                    specification: specifications,
                });
            }

            /** Check if visibility has changed */
            if (groupUpdate.visible !== groupItem.visible) {
                groupItem = Object.assign({}, groupItem, {
                    visible: groupUpdate.visible,
                });
            }

            stateGroups[index] = { ...groupItem };
        }
    });

    return {
        ...state,
        groups: stateGroups,
        claimObjects: response.claimObjects,
    };
}
