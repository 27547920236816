import { ClaimObjectElementInterface, ReloadClaimObjectDetailsRequestInterface } from '../interfaces';

// We use the request to create a response for ReloadClaimObjectDetailsResponse
export function mapClaimObjectElements(
    response: ReloadClaimObjectDetailsRequestInterface
): ClaimObjectElementInterface[] {
    return [
        {
            claimObjectId: response.claimObjectId,
            elementContent: response.elementContent,
            elementName: response.elementName,
        },
    ];
}
