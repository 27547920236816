/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { NewModalRef } from './new-modal-ref';
import { getClosestModal } from './new-modal-utilities';
import { NewModal } from './new-modal.service';

/**
 * Button that will close the current dialog.
 */
@Directive({
    selector: '[modal-close], [modalClose]',
    exportAs: 'modalClose',
})
export class NewModalCloseDirective implements OnInit, OnChanges {
    /** Screen-reader label for the button. */
    @Input('aria-label') public ariaLabel: string;

    /** Default to "button" to prevents accidental form submits. */
    @Input() public type: 'submit' | 'button' | 'reset' = 'button';

    /** Dialog close input. */
    @Input('modal-close') public modalResult: any;

    @Input('modalClose') public _matDialogClose: any;

    constructor(
        @Optional() public modalRef: NewModalRef<any>,
        private _elementRef: ElementRef<HTMLElement>,
        private _modal: NewModal
    ) {}

    @HostListener('click')
    public onClick(): void {
        if (!this.modalRef) {
            return;
        }

        this.modalRef.close(this.modalResult);
    }

    public ngOnInit(): void {
        if (!this.modalRef) {
            this.modalRef = getClosestModal(this._elementRef, this._modal.openModals);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const proxiedChange = changes._modalDialogClose || changes.modalResult;

        if (proxiedChange) {
            this.modalResult = proxiedChange.currentValue;
        }
    }
}
