import { ChatbotConfigurationInterface } from '../interfaces';
import { ApiChatbotRequestInterface } from '../interfaces/api';

export function mapQuestionRequest(
    configuration: ChatbotConfigurationInterface,
    question: string,
    sessionId?: string,
    dialogPath?: string
): Partial<ApiChatbotRequestInterface> {
    const request: Partial<ApiChatbotRequestInterface> = {
        customerKey: configuration.customerKey,
        projectKey: configuration.projectKey,
        apiKey: configuration.apiKey,
        classificationId: 1,
        culture: configuration.culture,
        'dim.VAStyle': 'VA',
        requestContext: 'VA',
        q: question,
    };
    if (sessionId) {
        request['session.id'] = sessionId;
    }
    if (dialogPath) {
        request['session.dialogPath'] = dialogPath;
    }
    return request;
}
