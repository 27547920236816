export enum ApiFormatCodeEnum {
    Alphanumeric = 'ALFA',
    Boolean = 'BOOL',
    Checkbox = 'CHCK',
    Codelist = 'CODE',
    Date = 'DATE',
    LicensePlate = 'KTKN',
    Numeric = 'NUM',
    PostalCode = 'PSTC',
    Time = 'TIME',
    TimeStamp = 'TMSP',
    Text = 'TEXT',
    Valuta = 'BDRG',
}
