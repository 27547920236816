import { ClaimObjectElementInterface } from '../interfaces';

export function mergeClaimObjectElementsUtility(
    stateClaimObjectElements: ClaimObjectElementInterface[],
    responseClaimObjectElements: ClaimObjectElementInterface[]
): ClaimObjectElementInterface[] {
    const responseClaimObjectElement = responseClaimObjectElements[0];
    const claimObjectElements: ClaimObjectElementInterface[] = Object.assign([], stateClaimObjectElements).filter(
        (claimObjectElement) => claimObjectElement.elementName !== responseClaimObjectElement.elementName
    );

    if (!responseClaimObjectElements || responseClaimObjectElements.length < 1) {
        return [];
    }

    return [...claimObjectElements, responseClaimObjectElement];
}
