import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    DeleteExhibitRequestInterface,
    DeleteExhibitResponseInterface,
    LoadExhibitsRequestInterface,
    LoadExhibitsResponseInterface,
    SaveExhibitRequestInterface,
    SaveExhibitResponseInterface,
    SubmitExhibitsRequestInterface,
    SubmitExhibitsResponseInterface,
} from '../interfaces';

export const loadExhibits = createAction(
    '[claim exhibits] load exhibits',
    props<{ request: LoadExhibitsRequestInterface }>()
);
export const loadExhibitsSuccess = createAction(
    '[claim exhibits] load exhibits success',
    props<{ response: LoadExhibitsResponseInterface }>()
);
export const loadExhibitsError = createAction(
    '[claim exhibits] load exhibits error',
    props<{ errors: ErrorInterface[] }>()
);

export const submitExhibits = createAction(
    '[claim exhibits] submit exhibits',
    props<{ request: SubmitExhibitsRequestInterface }>()
);
export const submitExhibitsSuccess = createAction(
    '[claim exhibits] submit exhibits success',
    props<{ response: SubmitExhibitsResponseInterface }>()
);
export const submitExhibitsError = createAction(
    '[claim exhibits] submit exhibits error',
    props<{ errors: ErrorInterface[] }>()
);

export const saveExhibit = createAction(
    '[claim exhibits] save exhibit',
    props<{ request: SaveExhibitRequestInterface }>()
);
export const saveExhibitSuccess = createAction(
    '[claim exhibits] save exhibit success',
    props<{ response: SaveExhibitResponseInterface }>()
);
export const saveExhibitError = createAction(
    '[claim exhibits] save exhibit error',
    props<{ errors: ErrorInterface[] }>()
);

export const deleteExhibit = createAction(
    '[claim exhibits] delete exhibit',
    props<{ request: DeleteExhibitRequestInterface }>()
);
export const deleteExhibitSuccess = createAction(
    '[claim exhibits] delete exhibit success',
    props<{ response: DeleteExhibitResponseInterface }>()
);
export const deleteExhibitError = createAction(
    '[claim exhibits] delete exhibit error',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimExhibits = createAction('[claim exhibits] reset claim exhibits');
