import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { Contact, ContactState, CONTACT_FEATURE } from './contact.state';

export class ContactSelectors {
    public static getContactState = createFeatureSelector<ContactState>(CONTACT_FEATURE);

    public static getMailQuestionId: Selector<Contact, number> = createSelector(
        ContactSelectors.getContactState,
        (state: ContactState) => state.mailQuestionId
    );
}
