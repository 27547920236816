import { PurchasedProductInterface } from '../interfaces';

export function mergeProductsUtility(
    stateProducts: PurchasedProductInterface[],
    responseProducts: PurchasedProductInterface[]
): PurchasedProductInterface[] {
    const newProducts = stateProducts.map((stateProduct) => {
        const responseProduct = responseProducts.find(
            (responseProduct) => responseProduct.productSequenceNumber === stateProduct.productSequenceNumber
        );

        if (responseProduct) {
            return {
                ...stateProduct,
                ...responseProduct,
            };
        }

        return stateProduct;
    });

    return newProducts;
}
