import { Injectable } from '@angular/core';
import { SessionInterface } from '../interfaces';

const MAX_EXPIRATION_IN_SECONDS = 7200;
const MILLISECONDS_IN_SECOND = 1000;

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    public static isValid(session: SessionInterface): boolean {
        if (!session) {
            return false;
        }
        return (
            Date.now() <
            new Date(session.sessionStart).getTime() + session.sessionDurationInSeconds * MILLISECONDS_IN_SECOND
        );
    }

    public static canExtend(session: SessionInterface): boolean {
        if (!session) {
            return false;
        }
        return (
            Date.now() < new Date(session.sessionStart).getTime() + MAX_EXPIRATION_IN_SECONDS * MILLISECONDS_IN_SECOND
        );
    }

    public static canExtendWithToken(session: SessionInterface): boolean {
        if (!session) {
            return false;
        }
        return !!session.token;
    }
}
