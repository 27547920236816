import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({ providedIn: 'root' })
export class ClaimPartiesEventService {
    private _getParties$: Subject<void>;
    private _saveWitness$: Subject<void>;
    private _saveContact$: Subject<void>;
    private _saveCounterparty$: Subject<void>;

    // Get Parties
    public getParties$(): Observable<void> {
        this._getParties$ = new Subject();
        return this._getParties$.asObservable();
    }

    public onGetPartiesSuccess$(): void {
        this._getParties$.next();
        this._getParties$.complete();
    }

    public onGetPartiesError$(errors: ErrorInterface[]): void {
        this._getParties$.error(errors);
    }

    // Save Witness
    public saveWitness$(): Observable<void> {
        this._saveWitness$ = new Subject();
        return this._saveWitness$.asObservable();
    }

    public onSaveWitnessSuccess$(): void {
        this._saveWitness$.next();
        this._saveWitness$.complete();
    }

    public onSaveWitnessError$(errors: ErrorInterface[]): void {
        this._saveWitness$.error(errors);
    }

    // Save Contact
    public saveContact$(): Observable<void> {
        this._saveContact$ = new Subject();
        return this._saveContact$.asObservable();
    }

    public onSaveContactSuccess$(): void {
        this._saveContact$.next();
        this._saveContact$.complete();
    }

    public onSaveContactError$(errors: ErrorInterface[]): void {
        this._saveContact$.error(errors);
    }

    // Save Counter Party
    public saveCounterparty$(): Observable<void> {
        this._saveCounterparty$ = new Subject();
        return this._saveCounterparty$.asObservable();
    }

    public onSaveCounterpartySuccess$(): void {
        this._saveCounterparty$.next();
        this._saveCounterparty$.complete();
    }

    public onSaveCounterpartyError$(errors: ErrorInterface[]): void {
        this._saveCounterparty$.error(errors);
    }
}
