import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PolicyProductIdEnum } from '../enums';
import { PurchasedProductInterface } from '../interfaces';
import { InsurancePolicyState, INSURANCE_POLICY_FEATURE } from './insurance-policy.state';

export class InsurancePolicySelectors {
    private static getInsurancePolicyState = createFeatureSelector(INSURANCE_POLICY_FEATURE);

    // TODO: types toevoegen
    public static getState = createSelector(
        InsurancePolicySelectors.getInsurancePolicyState,
        (state: InsurancePolicyState) => state
    );

    public static getSelectedId = createSelector(
        InsurancePolicySelectors.getInsurancePolicyState,
        (state: InsurancePolicyState) => state.selectedId
    );

    public static getPurchasedProducts = createSelector(
        InsurancePolicySelectors.getInsurancePolicyState,
        (state: InsurancePolicyState) => state.purchasedProducts
    );

    public static getTotal = createSelector(InsurancePolicySelectors.getPurchasedProducts, (purchasedProducts) =>
        purchasedProducts.reduce((accumulator: number, purchasedProduct: PurchasedProductInterface) => {
            const premium = purchasedProduct.coverage?.totalPremiumIncludingDiscount;
            return !premium ? accumulator : accumulator + premium.premiumIncludingPremiumTax;
        }, 0)
    );

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public static getPurchasedProduct = (productSequenceNumber: number) =>
        createSelector(
            InsurancePolicySelectors.getPurchasedProducts,
            (purchasedProducts: PurchasedProductInterface[]) =>
                purchasedProducts.find(
                    (purchasedProduct: PurchasedProductInterface) =>
                        purchasedProduct.productSequenceNumber === productSequenceNumber
                )
        );

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public static getPurchasedProductsByProductId = (productId: PolicyProductIdEnum) =>
        createSelector(
            InsurancePolicySelectors.getPurchasedProducts,
            (purchasedProducts: PurchasedProductInterface[]) =>
                purchasedProducts.filter(
                    (purchasedProduct: PurchasedProductInterface) => purchasedProduct.productId === productId
                )
        );
}
