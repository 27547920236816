import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClaimCoverageState, CLAIM_COVERAGE_FEATURE } from './claim-coverage.state';

export class ClaimCoverageSelectors {
    public static getClaimCoverageState = createFeatureSelector<ClaimCoverageState>(CLAIM_COVERAGE_FEATURE);

    public static getState = createSelector(
        ClaimCoverageSelectors.getClaimCoverageState,
        (state: ClaimCoverageState) => state
    );

    public static getClaimCompensation = createSelector(
        ClaimCoverageSelectors.getClaimCoverageState,
        (state: ClaimCoverageState) => state.claimCompensation
    );

    public static getClaimCoverage = createSelector(
        ClaimCoverageSelectors.getClaimCoverageState,
        (state: ClaimCoverageState) => state.claimCoverage
    );
}
