import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GetClaimNotificationPayloadInterface, SaveClaimNotificationPayloadInterface } from '../interfaces';
import {
    ClaimNotificationState,
    GetClaimNotificationAction,
    ResetClaimNotificationAction,
    SaveClaimNotificationAction,
} from '../store/';
import { ClaimNotificationEventService } from './claim-notification.event.service';

@Injectable({ providedIn: 'root' })
export class ClaimNotificationActionService {
    constructor(
        private store$: Store<ClaimNotificationState>,
        private claimNotificationEventService: ClaimNotificationEventService
    ) {}

    public getClaimNotification$(payload: GetClaimNotificationPayloadInterface): Observable<void> {
        this.store$.dispatch(new GetClaimNotificationAction(payload));
        return this.claimNotificationEventService.getClaimNotification$();
    }

    public saveClaimNotification$(payload: SaveClaimNotificationPayloadInterface): Observable<void> {
        this.store$.dispatch(new SaveClaimNotificationAction(payload));
        return this.claimNotificationEventService.saveClaimNotification$();
    }

    public resetClaimNotification(): void {
        this.store$.dispatch(new ResetClaimNotificationAction());
    }
}
