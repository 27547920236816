import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    GetPartiesRequestInterface,
    SaveContactRequestInterface,
    SaveCounterpartyRequestInterface,
    SaveWitnessRequestInterface,
} from '../interfaces';
import * as ClaimPartiesActions from '../store/claim-parties.actions';
import { ClaimPartiesState } from '../store/claim-parties.state';
import { ClaimPartiesEventService } from './claim-parties.event.service';

@Injectable({ providedIn: 'root' })
export class ClaimPartiesActionService {
    constructor(private store$: Store<ClaimPartiesState>, private claimPartiesEventService: ClaimPartiesEventService) {}

    public getParties$(request: GetPartiesRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimPartiesActions.getParties({ request }));
        return this.claimPartiesEventService.getParties$();
    }

    public saveWitness$(request: SaveWitnessRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimPartiesActions.saveWitness({ request }));
        return this.claimPartiesEventService.saveWitness$();
    }

    public saveContact$(request: SaveContactRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimPartiesActions.saveContact({ request }));
        return this.claimPartiesEventService.saveContact$();
    }

    public saveCounterparty$(request: SaveCounterpartyRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimPartiesActions.saveCounterparty({ request }));
        return this.claimPartiesEventService.saveCounterparty$();
    }

    public resetClaimParties(): void {
        this.store$.dispatch(ClaimPartiesActions.resetClaimParties());
    }
}
