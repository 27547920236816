import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { GetPaymentProcessPayloadRequestInterface, GetPaymentProcessResponseInterface } from '../interfaces';

export enum PaymentProcessActionsEnum {
    GetPaymentProcess = '[Payment Process] Get Payment Process',
    GetPaymentProcessSuccess = '[Payment Process] Get Payment Process success',
    GetPaymentProcessError = '[Payment Process] Get Payment Process error',
}

export const getPaymentProcessAction = createAction(
    PaymentProcessActionsEnum.GetPaymentProcess,
    props<GetPaymentProcessPayloadRequestInterface>()
);

export const getPaymentProcessSuccessAction = createAction(
    PaymentProcessActionsEnum.GetPaymentProcessSuccess,
    props<GetPaymentProcessResponseInterface>()
);

export const getPaymentProcessErrorAction = createAction(
    PaymentProcessActionsEnum.GetPaymentProcessError,
    props<{ errors: ErrorInterface[] }>()
);
