import {
    AggregateInterface,
    ApiAggregateResponseInterface,
    SurveyRequestInterface,
    SurveyInterface,
    AggregateRequestInterface,
} from '../interfaces';

function surveyMapper(response: SurveyRequestInterface[]): SurveyInterface[] {
    if (!response) {
        return [];
    }

    return response.map((survey) => ({
        subject: survey.subject,
        subjectDescription: survey.subject_description,
        total: survey.total,
        score: survey.score,
    }));
}

export function mapAggregateResponse(
    payload: AggregateRequestInterface,
    response: ApiAggregateResponseInterface
): AggregateInterface {
    return {
        key: payload.ids.join(','),
        value: {
            surveys: surveyMapper(response.surveys),
            averageScore: response.average_score,
            total: response.total,
        },
    };
}
