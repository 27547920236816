import { mapEnumApiRequest, mapEnumApiResponse } from '../../core';
import { ApiLegalPartyTypeCodeEnum, ApiV1LegalPartyTypeCodeEnum, LegalPartyTypeCodeEnum } from '../enums';

export function mapLegalPartyTypeCodeResponse(
    response: ApiLegalPartyTypeCodeEnum,
    enablePartyCodes?: boolean
): LegalPartyTypeCodeEnum {
    const partyRoleEnumType = enablePartyCodes ? ApiV1LegalPartyTypeCodeEnum : ApiLegalPartyTypeCodeEnum;
    return mapEnumApiResponse<LegalPartyTypeCodeEnum>(response, partyRoleEnumType, LegalPartyTypeCodeEnum);
}

export function mapLegalPartyTypeCodeRequest(request: LegalPartyTypeCodeEnum): ApiLegalPartyTypeCodeEnum {
    return mapEnumApiRequest<ApiLegalPartyTypeCodeEnum>(request, ApiLegalPartyTypeCodeEnum, LegalPartyTypeCodeEnum);
}
