import { GetClaimObjectDetailsResponseInterface, ApiGetClaimObjectDetailsResponseInterface } from '../interfaces';
import { mapClaimObjectDetails } from './claim-object-details.mapper';

export function mapGetClaimObjectDetailsResponse(
    response: ApiGetClaimObjectDetailsResponseInterface[],
    claimObjectId: string
): GetClaimObjectDetailsResponseInterface {
    return {
        claimObjectDetails: mapClaimObjectDetails(response, claimObjectId),
    };
}
