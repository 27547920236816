import { mapSequenceNumber } from '../../core';
import { ApiUpdateClaimObjectDetailsRequestInterface, UpdateClaimObjectDetailsRequestInterface } from '../interfaces';

export function mapUpdateClaimObjectDetailsRequest(
    request: UpdateClaimObjectDetailsRequestInterface
): ApiUpdateClaimObjectDetailsRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
        element: {
          element_name: request.elementName,
          element_content: request.elementContent
        },
        claim_object: {
            claim_object_id: request.claimObjectId,
        },
    };
}
