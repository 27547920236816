import { createReducer, on } from '@ngrx/store';
import * as ClaimExhibitsActions from '../store/claim-exhibits.actions';
import { CLAIM_EXHIBITS_INITIAL_STATE } from './claim-exhibits.state';

export const claimExhibitsReducer = createReducer(
    CLAIM_EXHIBITS_INITIAL_STATE,
    on(ClaimExhibitsActions.loadExhibitsSuccess, (state, { response }) => ({
        ...state,
        exhibits: response.exhibits,
        submit: response.submit,
        buttons: response.buttons,
    })),
    on(ClaimExhibitsActions.saveExhibitSuccess, (state, { response }) => ({
        ...state,
        exhibits: response.exhibits,
        submit: response.submit,
        buttons: response.buttons,
    })),
    on(ClaimExhibitsActions.saveExhibitSuccess, (state, { response }) => ({
        ...state,
        exhibits: response.exhibits,
        submit: response.submit,
        buttons: response.buttons,
    })),
    on(ClaimExhibitsActions.deleteExhibitSuccess, (state, { response }) => ({
        ...state,
        exhibits: response.exhibits,
        submit: response.submit,
        buttons: response.buttons,
    }))
);
