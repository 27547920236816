import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { ErrorInterface } from '../../core';
import { ClaimExhibitsApiService } from '../services/claim-exhibits.api.service';
import { ClaimExhibitsEventService } from '../services/claim-exhibits.event.service';
import * as ClaimExhibitsActions from './claim-exhibits.actions';

@Injectable({
    providedIn: 'root',
})
export class ClaimExhibitsEffects {
    public loadExhibits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimExhibitsActions.loadExhibits),
            exhaustMap(({ request }) =>
                this.claimExhibitsApiService.loadExhibits$(request).pipe(
                    map((response) => ClaimExhibitsActions.loadExhibitsSuccess({ response })),
                    catchError((errors: ErrorInterface[]) => of(ClaimExhibitsActions.loadExhibitsError({ errors })))
                )
            )
        );
    });

    public loadExhibitsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.loadExhibitsSuccess),
                tap(() => this.claimExhibitsEventService.onLoadExhibitsSuccess())
            );
        },
        { dispatch: false }
    );

    public loadExhibitsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.loadExhibitsError),
                tap(({ errors }) => this.claimExhibitsEventService.onLoadExhibitsError(errors))
            );
        },
        { dispatch: false }
    );

    public submitExhibits$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimExhibitsActions.submitExhibits),
            exhaustMap(({ request }) =>
                this.claimExhibitsApiService.submitExhibits$(request).pipe(
                    map((response) => ClaimExhibitsActions.submitExhibitsSuccess({ response })),
                    catchError((errors: ErrorInterface[]) => of(ClaimExhibitsActions.submitExhibitsError({ errors })))
                )
            )
        );
    });

    public submitExhibitsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.submitExhibitsSuccess),
                tap(() => this.claimExhibitsEventService.onSubmitExhibitsSuccess())
            );
        },
        { dispatch: false }
    );

    public submitExhibitsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.submitExhibitsError),
                tap(({ errors }) => this.claimExhibitsEventService.onSubmitExhibitsError(errors))
            );
        },
        { dispatch: false }
    );

    public saveExhibit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimExhibitsActions.saveExhibit),
            exhaustMap(({ request }) =>
                this.claimExhibitsApiService.saveExhibit$(request).pipe(
                    map((response) => ClaimExhibitsActions.saveExhibitSuccess({ response })),
                    catchError((errors: ErrorInterface[]) => of(ClaimExhibitsActions.saveExhibitError({ errors })))
                )
            )
        );
    });

    public saveExhibitSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.saveExhibitSuccess),
                tap(() => this.claimExhibitsEventService.onSaveExhibitSuccess())
            );
        },
        { dispatch: false }
    );

    public saveExhibitError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.saveExhibitError),
                tap(({ errors }) => this.claimExhibitsEventService.onSaveExhibitError(errors))
            );
        },
        { dispatch: false }
    );

    public deleteExhibit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ClaimExhibitsActions.deleteExhibit),
            exhaustMap(({ request }) =>
                this.claimExhibitsApiService.deleteExhibit$(request).pipe(
                    map((response) => ClaimExhibitsActions.deleteExhibitSuccess({ response })),
                    catchError((errors: ErrorInterface[]) => of(ClaimExhibitsActions.deleteExhibitError({ errors })))
                )
            )
        );
    });

    public deleteExhibitSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.deleteExhibitSuccess),
                tap(() => this.claimExhibitsEventService.onDeleteExhibitSuccess())
            );
        },
        { dispatch: false }
    );

    public deleteExhibitError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ClaimExhibitsActions.deleteExhibitError),
                tap(({ errors }) => this.claimExhibitsEventService.onDeleteExhibitError(errors))
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private claimExhibitsApiService: ClaimExhibitsApiService,
        private claimExhibitsEventService: ClaimExhibitsEventService
    ) {}
}
