import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    GetPartiesRequestInterface,
    GetPartiesResponseInterface,
    SaveContactRequestInterface,
    SaveContactResponseInterface,
    SaveCounterpartyRequestInterface,
    SaveCounterpartyResponseInterface,
    SaveWitnessRequestInterface,
    SaveWitnessResponseInterface,
} from '../interfaces';

export const getParties = createAction('[claim parties] get parties', props<{ request: GetPartiesRequestInterface }>());

export const getPartiesSuccess = createAction(
    '[claim parties] get parties success',
    props<{ response: GetPartiesResponseInterface }>()
);

export const getPartiesError = createAction('[claim parties] get parties error', props<{ errors: ErrorInterface[] }>());

export const saveWitness = createAction(
    '[claim parties] save witness',
    props<{ request: SaveWitnessRequestInterface }>()
);

export const saveWitnessSuccess = createAction(
    '[claim parties] save witness success',
    props<{ response: SaveWitnessResponseInterface }>()
);

export const saveWitnessError = createAction(
    '[claim parties] save witness error',
    props<{ errors: ErrorInterface[] }>()
);

export const saveContact = createAction(
    '[claim parties] save contact',
    props<{ request: SaveContactRequestInterface }>()
);

export const saveContactSuccess = createAction(
    '[claim parties] save contact success',
    props<{ response: SaveContactResponseInterface }>()
);

export const saveContactError = createAction(
    '[claim parties] save contact error',
    props<{ errors: ErrorInterface[] }>()
);

export const saveCounterparty = createAction(
    '[claim parties] save counterparty',
    props<{ request: SaveCounterpartyRequestInterface }>()
);

export const saveCounterpartySuccess = createAction(
    '[claim parties] save counterparty success',
    props<{ response: SaveCounterpartyResponseInterface }>()
);

export const saveCounterpartyError = createAction(
    '[claim parties] save counterparty error',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimParties = createAction('[claim parties] reset claim Parties');
