import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ValidationElementsInterface } from '../interfaces';
import { ValidationState, VALIDATION_FEATURE } from './validation.state';

export class ValidationSelectors {
    public static getValidationState = createFeatureSelector<ValidationState>(VALIDATION_FEATURE);

    public static getValidationElements = createSelector(
        ValidationSelectors.getValidationState,
        (state: ValidationState) => state.validationElements
    );

    public static getElement = (name: string) =>
        createSelector(ValidationSelectors.getValidationElements, (elements: ValidationElementsInterface) =>
            elements.hasOwnProperty(name) ? elements[name] : []
        );
}
