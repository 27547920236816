import { mapSequenceNumber, mapSuccessIndication } from '../../core';
import {
    AdditionalElementRequestInterface,
    ApiAdditionalElementRequestInterface,
    ApiAppointmentRequestInterface,
    ApiClaimRecoveryEventRequestInterface,
    ApiSaveAppointmentReponseInterface,
    ApiSaveAppointmentRequestInterface,
    AppointmentRequestInterface,
    ClaimRecoveryEventRequestInterface,
    SaveAppointmentReponseInterface,
    SaveAppointmentRequestInterface,
} from '../interfaces/save-appointment.interface';

export function mapSaveAppointmentResponse(
    response: SaveAppointmentReponseInterface
): ApiSaveAppointmentReponseInterface {
    return response;
}

export function mapClaimRecoveryEventRequest(
    request: ClaimRecoveryEventRequestInterface[]
): ApiClaimRecoveryEventRequestInterface[] {
    if (!request) {
        return [];
    }

    return request.map((claimRecoveryEvent) => ({
        damage_repair_event_date: claimRecoveryEvent.damageRepairEventDate,
        damage_repair_event_time: claimRecoveryEvent.damageRepairEventTime,
        schadeherstel_gebeurtenis_eindtijd: claimRecoveryEvent.claimRecoveryEventEndTime,
        damage_repair_event_sequence_number: mapSequenceNumber(claimRecoveryEvent.damageRepairEventSequenceNumber),
        damage_repair_event_code: claimRecoveryEvent.damageRepairEventCode,
        duration: claimRecoveryEvent.duration,
        schadeherstel_soort_vervangend_vervoer_code: claimRecoveryEvent.claimRecoveryTypeReplacementVehicleCode,
    }));
}

function mapAdditionalElements(request: AdditionalElementRequestInterface[]): ApiAdditionalElementRequestInterface[] {
    if (!request) {
        return [];
    }

    return request.map((additionalElement) => ({
        element_name: additionalElement.elementName,
        element_content: additionalElement.elementContent,
    }));
}

export function mapAppointmentRequest(request: AppointmentRequestInterface): ApiAppointmentRequestInterface {
    return {
        business_relation_id: request.businessRelationId,
        schadeherstel_gebeurtenis: mapClaimRecoveryEventRequest(request.claimRecoveryEvent),
        aanvullende_elementen: mapAdditionalElements(request.additionalElements),
    };
}

export function mapSaveAppointmentRequest(
    request: SaveAppointmentRequestInterface
): ApiSaveAppointmentRequestInterface {
    return {
        network_id: request.networkId,
        damage_repair_event_code: request.damageRepairEventCode,
        case: {
            case_sequence_number: request.case.caseSequenceNumber,
            claim_sequence_number: request.case.claimSequenceNumber,
        },
        appointment: mapAppointmentRequest(request.appointment),
        dossier_notificatie_onderdrukken_indicatie: mapSuccessIndication(request.caseNotificationSuppressIndication),
    };
}
