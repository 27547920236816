import { Action } from '@ngrx/store';
import { type } from '../../../core';
import { Coverage, Customer, Insurance, Step } from '../../interfaces';

export const INIT = type('[Salesfunnel] Initialize');
export const INIT_SUCCESS = type('[Salesfunnel] Initialize Success');
export const NAVIGATE = type('[Salesfunnel] Navigate');

export class Initialize implements Action {
    public readonly type = INIT;

    constructor(
        public payload: {
            productId: string;
            steps: Step[];
            current: number;
            url?: string;
        }
    ) {}
}

export class InitializeSuccess implements Action {
    public readonly type = INIT_SUCCESS;

    constructor(
        public payload: {
            productId: string;
            insurance?: Insurance;
            customer?: Customer;
            isReturningCustomer?: boolean;
            coverage?: Coverage[];
        }
    ) {}
}

export class Navigate implements Action {
    public readonly type = NAVIGATE;

    constructor(public payload?: any) {}
}

export type ProgressActionsUnion = Initialize | InitializeSuccess | Navigate;
