import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { claimCategoriesReducer } from './store';
import { ClaimCategoriesEffects } from './store/claim-categories.effects';
import { CLAIM_CATEGORIES_FEATURE } from './store/claim-categories.state';

@NgModule({
    imports: [
        StoreModule.forFeature(CLAIM_CATEGORIES_FEATURE, claimCategoriesReducer),
        EffectsModule.forFeature([ClaimCategoriesEffects]),
    ],
})
export class OutClaimCategoriesModule {}
