import { mapEnumApiRequest, mapEnumApiResponse } from '../../core';
import { ApiProcedureTypeCodeEnum, ProcedureTypeCodeEnum } from '../enums';

export function mapProcedureTypeCodeResponse(procedureTypeCode: ApiProcedureTypeCodeEnum): ProcedureTypeCodeEnum {
    return mapEnumApiResponse(procedureTypeCode, ApiProcedureTypeCodeEnum, ProcedureTypeCodeEnum);
}

export function mapProcedureTypeCodeRequest(procedureTypeCode: ProcedureTypeCodeEnum): ApiProcedureTypeCodeEnum {
    return mapEnumApiRequest(procedureTypeCode, ApiProcedureTypeCodeEnum, ProcedureTypeCodeEnum);
}
