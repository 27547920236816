import { ClaimObjectDetailsInterface, ClaimObjectSpecificationInterface } from '../interfaces';

export function mergeObjectDetailsUtility(
    currentDetails: ClaimObjectDetailsInterface[],
    newDetails: ClaimObjectDetailsInterface[],
): ClaimObjectDetailsInterface[] {
    // We check if currentDetails has any details; if not, we just push the new  in there (nothing to merge)
    if (currentDetails?.length === 0) {
        return [...currentDetails, ...newDetails];
    }
    // We use the currentDetails as a base
    let claimObjectDetailsList = [...currentDetails];

    for (let newDetail of newDetails) {
        // Per item, we check similarities between the state and newState by checking the claimCode
        let similarDetailIndex = claimObjectDetailsList.findIndex((detail) => detail.claimObjectCode === newDetail.claimObjectCode);

        if (similarDetailIndex >= 0) {
            // If we found similarity, then we overwrite the slot with the newDetails
            claimObjectDetailsList[similarDetailIndex] = {
                ...claimObjectDetailsList[similarDetailIndex],
                claimObjectSpecifications: mergeClaimObjectDetailsSpecificationsUtility(claimObjectDetailsList[similarDetailIndex].claimObjectSpecifications, newDetail.claimObjectSpecifications),
            };
        }
        else {
            claimObjectDetailsList.push(newDetail)
        }
    }

    return claimObjectDetailsList;
}


function mergeClaimObjectDetailsSpecificationsUtility(currentSpecifications: ClaimObjectSpecificationInterface[], newSpecifications: ClaimObjectSpecificationInterface[]): ClaimObjectSpecificationInterface[] {
    if (currentSpecifications?.length === 0) {
        return [...currentSpecifications, ...newSpecifications];
    }

    let claimObjectSpecificationsList = [...currentSpecifications];

    for (let newSpecification of newSpecifications) {
        let similarDetailIndex = claimObjectSpecificationsList.findIndex((specification) => specification.elementId === newSpecification.elementId);

        if (similarDetailIndex >= 0) {
            claimObjectSpecificationsList[similarDetailIndex] = newSpecification;
        }
        else {
            claimObjectSpecificationsList.push(newSpecification);
        }
    }

    return claimObjectSpecificationsList;
}
