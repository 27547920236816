import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorInterface } from '../../core';
import { PdfInternationalMotorInsuranceCardInterface } from '../interfaces';
import { InternationalMotorInsuranceCardApiService } from '../services';
import { InternationalMotorInsuranceCardEventService } from '../services/international-motor-insurance-card-event.service';
import {
    InternationalMotorInsuranceCardActionsEnum,
    LoadEmailInternationalMotorInsuranceCardAction,
    LoadEmailInternationalMotorInsuranceCardErrorAction,
    LoadEmailInternationalMotorInsuranceCardSuccessAction,
    LoadEmailNotInsuredInternationalMotorInsuranceCardAction,
    LoadEmailNotInsuredInternationalMotorInsuranceCardErrorAction,
    LoadEmailNotInsuredInternationalMotorInsuranceCardSuccessAction,
    LoadPdfInternationalMotorInsuranceCardAction,
    LoadPdfInternationalMotorInsuranceCardErrorAction,
    LoadPdfInternationalMotorInsuranceCardSuccessAction,
    LoadPdfNotInsuredInternationalMotorInsuranceCardAction,
    LoadPdfNotInsuredInternationalMotorInsuranceCardErrorAction,
    LoadPdfNotInsuredInternationalMotorInsuranceCardSuccessAction,
} from './international-motor-insurance-card.actions';

@Injectable()
export class InternationalMotorInsuranceCardEffects {
    public getEmailInternationalMotorInsuranceCard$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCard),
            map((action: LoadEmailInternationalMotorInsuranceCardAction) => action.payload),
            switchMap((payload) =>
                this.internationalMotorInsuranceCarApiService
                    .getEmailInternationalMotorInsuranceCard$(payload.productSequenceNumber)
                    .pipe(
                        map(
                            (emailInsuranceCard: string) =>
                                new LoadEmailInternationalMotorInsuranceCardSuccessAction({ email: emailInsuranceCard })
                        ),
                        catchError((errors: ErrorInterface[]) =>
                            of(new LoadEmailInternationalMotorInsuranceCardErrorAction({ errors }))
                        )
                    )
            )
        );
    });

    public getEmailInternationalMotorInsuranceCardSuccess$: Observable<string> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCardSuccess),
                tap((message) =>
                    this.internationalMotorInsuranceCardEventService.onLoadEmailInternationalMotorInsuranceCardSuccess$(
                        message
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getEmailInternationalMotorInsuranceCardError$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InternationalMotorInsuranceCardActionsEnum.LoadEmailInternationalMotorInsuranceCardError),
                map((action: LoadEmailInternationalMotorInsuranceCardErrorAction) =>
                    this.internationalMotorInsuranceCardEventService.onLoadEmailInternationalMotorInsuranceCarError$(
                        action.payload.errors
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getEmailNotInsuredInternationalMotorInsuranceCard$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(InternationalMotorInsuranceCardActionsEnum.LoadEmailNotInsuredInternationalMotorInsuranceCard),
            map((action: LoadEmailNotInsuredInternationalMotorInsuranceCardAction) => action.payload),
            switchMap((payload) =>
                this.internationalMotorInsuranceCarApiService
                    .getEmailNotInsuredInternationalMotorInsuranceCard$(
                        payload.productSequenceNumber,
                        payload.caravanBrand,
                        payload.licensePlate
                    )
                    .pipe(
                        map(
                            (emailInsuranceCard: string) =>
                                new LoadEmailNotInsuredInternationalMotorInsuranceCardSuccessAction({
                                    email: emailInsuranceCard,
                                })
                        ),
                        catchError((errors: ErrorInterface[]) =>
                            of(new LoadEmailNotInsuredInternationalMotorInsuranceCardErrorAction({ errors }))
                        )
                    )
            )
        );
    });

    public getEmailNotInsuredInternationalMotorInsuranceCardSuccess$: Observable<string> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    InternationalMotorInsuranceCardActionsEnum.LoadEmailNotInsuredInternationalMotorInsuranceCardSuccess
                ),
                tap((message) =>
                    this.internationalMotorInsuranceCardEventService.onLoadEmailNotInsuredInternationalMotorInsuranceCardSuccess$(
                        message
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getEmailNotInsuredInternationalMotorInsuranceCardError$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    InternationalMotorInsuranceCardActionsEnum.LoadEmailNotInsuredInternationalMotorInsuranceCardError
                ),
                map((action: LoadEmailNotInsuredInternationalMotorInsuranceCardErrorAction) =>
                    this.internationalMotorInsuranceCardEventService.onLoadEmailNotInsuredInternationalMotorInsuranceCarError$(
                        action.payload.errors
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getPdfInternationalMotorInsuranceCard$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCard),
            map((action: LoadPdfInternationalMotorInsuranceCardAction) => action.payload),
            switchMap((payload) =>
                this.internationalMotorInsuranceCarApiService
                    .getPdfInternationalMotorInsuranceCard$(payload.productSequenceNumber)
                    .pipe(
                        map(
                            (pdfInsuranceCard: PdfInternationalMotorInsuranceCardInterface) =>
                                new LoadPdfInternationalMotorInsuranceCardSuccessAction({ pdf: pdfInsuranceCard })
                        ),
                        catchError((errors: ErrorInterface[]) =>
                            of(new LoadPdfInternationalMotorInsuranceCardErrorAction({ errors }))
                        )
                    )
            )
        );
    });

    public getPdfInternationalMotorInsuranceCardSuccess$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCardSuccess),
                tap((action: LoadPdfInternationalMotorInsuranceCardSuccessAction) =>
                    this.internationalMotorInsuranceCardEventService.onLoadPdfInternationalMotorInsuranceCardSuccess$(
                        action.payload.pdf
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getPdfInternationalMotorInsuranceCardError$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(InternationalMotorInsuranceCardActionsEnum.LoadPdfInternationalMotorInsuranceCardError),
                map((action: LoadPdfInternationalMotorInsuranceCardErrorAction) =>
                    this.internationalMotorInsuranceCardEventService.onLoadPdfInternationalMotorInsuranceCarError$(
                        action.payload.errors
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getPdfNotInsuredInternationalMotorInsuranceCard$: Observable<Action> = createEffect(() => {
        return this.actions$.pipe(
            ofType(InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCard),
            map((action: LoadPdfNotInsuredInternationalMotorInsuranceCardAction) => action.payload),
            switchMap((payload) =>
                this.internationalMotorInsuranceCarApiService
                    .getPdfNotInsuredInternationalMotorInsuranceCard$(
                        payload.productSequenceNumber,
                        payload.caravanBrand,
                        payload.licensePlate
                    )
                    .pipe(
                        map(
                            (pdfInsuranceCard: PdfInternationalMotorInsuranceCardInterface) =>
                                new LoadPdfNotInsuredInternationalMotorInsuranceCardSuccessAction({
                                    pdf: pdfInsuranceCard,
                                })
                        ),
                        catchError((errors: ErrorInterface[]) =>
                            of(new LoadPdfNotInsuredInternationalMotorInsuranceCardErrorAction({ errors }))
                        )
                    )
            )
        );
    });

    public getPdfNotInsuredInternationalMotorInsuranceCardSuccess$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCardSuccess
                ),
                tap((action: LoadPdfNotInsuredInternationalMotorInsuranceCardSuccessAction) =>
                    this.internationalMotorInsuranceCardEventService.onLoadPdfNotInsuredInternationalMotorInsuranceCardSuccess$(
                        action.payload.pdf
                    )
                )
            );
        },
        { dispatch: false }
    );

    public getPdfNotInsuredInternationalMotorInsuranceCardError$: Observable<void> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    InternationalMotorInsuranceCardActionsEnum.LoadPdfNotInsuredInternationalMotorInsuranceCardError
                ),
                map((action: LoadPdfInternationalMotorInsuranceCardErrorAction) =>
                    this.internationalMotorInsuranceCardEventService.onLoadPdfNotInsuredInternationalMotorInsuranceCarError$(
                        action.payload.errors
                    )
                )
            );
        },
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private internationalMotorInsuranceCarApiService: InternationalMotorInsuranceCardApiService,
        private internationalMotorInsuranceCardEventService: InternationalMotorInsuranceCardEventService
    ) {}
}
