import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class AddressEventService {
    private _fetchAddress$: Subject<void>;

    public fetchAddress$(): Observable<void> {
        this._fetchAddress$ = new Subject();
        return this._fetchAddress$.asObservable();
    }

    public onFetchAddressSuccess(): void {
        this._fetchAddress$.next();
        this._fetchAddress$.complete();
    }

    public onFetchAddressError(errors: ErrorInterface[]): void {
        this._fetchAddress$.error(errors);
    }
}
