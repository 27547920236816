import { ClaimObjectDetailsInterface, ClaimObjectSpecificationInterface } from '../interfaces';

export function mergeClaimObjectDetailsUtility(
    stateClaimObjectDetails: ClaimObjectDetailsInterface[],
    responseClaimObjectDetails: ClaimObjectDetailsInterface[]
): ClaimObjectDetailsInterface[] {
    const claimObjectDetailsFromState = Object.assign([], stateClaimObjectDetails);
    // Get claim object details from response
    const futureClaimObjectDetails: ClaimObjectDetailsInterface = responseClaimObjectDetails[0];
    // Get claim object details from state
    const currentClaimObjectDetails = claimObjectDetailsFromState.find(
        (claimObjectDetails) => claimObjectDetails.claimObjectCode === futureClaimObjectDetails.claimObjectCode
    );
    // New claim object details object
    let claimObjectDetails: ClaimObjectDetailsInterface = null;

    // Check if specification has changed
    if (futureClaimObjectDetails && futureClaimObjectDetails.claimObjectSpecifications) {
        const claimObjectSpecifications: ClaimObjectSpecificationInterface[] = [];

        for (const currentSpecification of currentClaimObjectDetails.claimObjectSpecifications) {
            // Try to find new specification in newSpecification
            const futureSpecification = futureClaimObjectDetails.claimObjectSpecifications.find(
                (futuristic) => futuristic.elementId === currentSpecification.elementId
            );

            if (futureSpecification) {
                claimObjectSpecifications.push(futureSpecification);
            } else {
                claimObjectSpecifications.push(currentSpecification);
            }
        }

        // Create new specification from currentClaimObjectDetails
        claimObjectDetails = {
            ...currentClaimObjectDetails,
            ...futureClaimObjectDetails,
            claimObjectSpecifications,
        };
    } else {
        return claimObjectDetailsFromState;
    }

    // Add new
    claimObjectDetailsFromState.push(claimObjectDetails);

    // Filter duplicates
    return [...new Set(claimObjectDetailsFromState)];
}
