import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { CarouselItemComponent } from './carousel-item';
import { ButtonNextDirective, ButtonPreviousDirective, CarouselComponent } from './carousel.component';

// eslint-disable-next-line no-var
declare var Hammer: any;

@Injectable({
    providedIn: 'root',
})
export class HammerConfig extends HammerGestureConfig {
    public buildHammer(element: HTMLElement): any {
        return new Hammer(element, {
            touchAction: 'pan-y',
        });
    }
}

@NgModule({
    imports: [CommonModule, HammerModule],
    exports: [CarouselComponent, CarouselItemComponent, ButtonNextDirective, ButtonPreviousDirective],
    declarations: [CarouselComponent, CarouselItemComponent, ButtonNextDirective, ButtonPreviousDirective],
    providers: [{ provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig }],
})
export class CarouselModule {}
