import { PurchasedProductInterface } from '../interfaces';

export function removeProductUtility(
    stateProducts: PurchasedProductInterface[],
    productSequenceNumber: number
): PurchasedProductInterface[] {
    const newProducts = stateProducts.filter(
        (stateProduct) => stateProduct.productSequenceNumber !== productSequenceNumber
    );

    return newProducts;
}
