import { ApiErrorLevelEnum, ErrorLevelEnum } from '../enums';
import { ApiError, ApiLegacyError, ErrorInterface } from '../interfaces';

const LEGACY_DISCRIMINATOR = 'message_content';

function mapErrorLevel(errorLevel: ApiErrorLevelEnum): ErrorLevelEnum {
    switch (errorLevel) {
    case ApiErrorLevelEnum.Error:
        return ErrorLevelEnum.Error;
    case ApiErrorLevelEnum.Warning:
        return ErrorLevelEnum.Warning;
    case ApiErrorLevelEnum.Info:
        return ErrorLevelEnum.Info;
    case ApiErrorLevelEnum.Choose:
        return ErrorLevelEnum.Choose;
    default:
        throw new Error('Unsupported error');
    }
}

/** Needed because API will sometimes return different error types */
function errorIsLegacy(error: ApiError | ApiLegacyError): error is ApiLegacyError {
    return error.hasOwnProperty(LEGACY_DISCRIMINATOR);
}

function mapApiError(error: ApiError): ErrorInterface {
    return {
        id: `${error.element_id}-${error.notification_sequence_number}`,
        level: mapErrorLevel(error.notification_code),
        message: error.notification_text,
        element: error.element_name,
    };
}

function mapLegacyError(error: ApiLegacyError): ErrorInterface {
    return {
        id: error.message_code,
        level: mapErrorLevel(error.notification_type),
        message: error.message_content,
        element: error.item,
    };
}

function mapError(error: ApiError | ApiLegacyError): ErrorInterface {
    return errorIsLegacy(error) ? mapLegacyError(error) : mapApiError(error);
}

export function mapErrors(errors: (ApiError | ApiLegacyError)[]): ErrorInterface[] {
    return errors.map(mapError);
}