import { ApiSpecificationGroupInterface, SpecificationGroupInterface } from '../interfaces';
import { mapSpecificationGroupSpecResponse } from './specification-group-spec.mapper';

export function mapSpecificationGroupResponse(
    response: ApiSpecificationGroupInterface[]
): SpecificationGroupInterface[] {
    if (!response) {
        return [];
    }

    return response.map((item: ApiSpecificationGroupInterface) => {
        return {
            specification: mapSpecificationGroupSpecResponse(item.specification),
            specificationGroupCode: item.specification_group_code,
            specificationGroupDescription: item.specification_group_description,
            visible: item.visible,
        };
    });
}
