import { createReducer, on } from '@ngrx/store';
import * as PaymentProcessActions from './payment-process.actions';
import { INITIAL_PAYMENT_PROCESS_STATE } from './payment-process.state';

export const paymentProcessReducers = createReducer(
    INITIAL_PAYMENT_PROCESS_STATE,
    on(PaymentProcessActions.getPaymentProcessAction, (state, action) => ({
        ...state,
        paymentRequest: {
            relationId: action.relationId,
            amount: action.amount,
        },
    })),
    on(PaymentProcessActions.getPaymentProcessSuccessAction, (state, action) => ({
        ...state,
        paymentResponse: {
            paymentSequenceNumber: action.paymentSequenceNumber,
            externalTransactionId: action.externalTransactionId,
            externalReferenceId: action.externalReferenceId,
            internalTransactionId: action.internalTransactionId,
        },
    }))
);
