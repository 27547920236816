import { mapSequenceNumber } from '../../core';
import { ApiGetClaimCompensationRequestInterface, GetClaimCompensationRequestInterface } from '../interfaces';

export function mapGetClaimCompensationRequest(
    request: GetClaimCompensationRequestInterface
): ApiGetClaimCompensationRequestInterface {
    return {
        case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
    };
}
