import { Customer } from './../../interfaces/customer';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SalesStorageService } from '../../services/sales-storage.service';
import * as customerActions from './customer.actions';

@Injectable()
export class CustomerEffects {
    public storeReturningCustomer$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(customerActions.RETURNING_CUSTOMER),
                tap(() => this.storageService.setItem('isReturningCustomer', true))
            );
        },
        { dispatch: false }
    );

    public updateCustomerData$: Observable<Customer> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(customerActions.STORE_CUSTOMER_DATA),
                map((type: customerActions.StoreCustomerData) => type),
                switchMap((type) => this.storageService.getUpdatedCustomer$(type.payload.customer)),
                tap((customer) => this.storageService.setItem('customer', customer))
            );
        },
        { dispatch: false }
    );

    public storeNewCustomer$: Observable<Action> = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(customerActions.NEW_CUSTOMER),
                tap(() => this.storageService.setItem('isReturningCustomer', false))
            );
        },
        { dispatch: false }
    );

    constructor(private actions$: Actions, private storageService: SalesStorageService) {}
}
