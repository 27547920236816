import { ApiUpdateFamilyCompositionRequest, ModifyFamilyCompositionInterface } from '../interfaces';

export function mapUpdateFamilyCompositionRequest(
    request: ModifyFamilyCompositionInterface
): ApiUpdateFamilyCompositionRequest {
    return {
        family_composition: {
            start_date: request.startDate,
            family_composition_code: request.familyCompositionCode, // TODO : Map
        },
    };
}
