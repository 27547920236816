import { mapSuccessIndication } from '../../core';
import { ApiCategoriesRequest, ApiCategoriesResponse, ApiCategoryInterface, CategoryInterface } from '../interfaces';

export function mapCategoriesResponse(response: ApiCategoriesResponse): CategoryInterface[] {
    return response.main_categories.map(mapCategory);
}

function mapCategory(apiCategory: ApiCategoryInterface): CategoryInterface {
    return apiCategory; // TODO : Actually map after new interface is updated
}

export function mapCategoriesRequest(fromContact: boolean): ApiCategoriesRequest {
    return {
        selection: {
            contact_page_indication: mapSuccessIndication(fromContact),
        },
    };
}
