import { mapFromSuccessIndication, mapMandatoryCode, mapSequenceNumber } from '../../../core';
import {
    ApiCoverageRequest,
    ApiCoverageResponse,
    ApiCoverageSpecificationInterface,
    ApiPurchasedCoverageInterface,
    ApiPurchasedCoveragePremiumInterface,
    CoverageRequestInterface,
    CoverageResponseInterface,
    CoverageSpecificationInterface,
    PurchasedCoverageInterface,
    PurchasedCoveragePremiumInterface,
} from '../../interfaces';
import { mapCoverageIdResponse } from '../product/coverage-id.mapper';
import { mapValidityStatusCodeResponse } from '../product/validity-status-code.mapper';

export function mapCoverageRequest(request: CoverageRequestInterface): ApiCoverageRequest {
    return {
        purchased_product: {
            purchased_product_sequence_number: mapSequenceNumber(request.productSequenceNumber),
        },
    };
}

export function mapCoverageResponse(
    response: ApiCoverageResponse,
    productSequenceNumber: number
): CoverageResponseInterface {
    if (!response) {
        return;
    }

    return {
        productSequenceNumber: productSequenceNumber,
        coverage: {
            additionalCoverages: mapCoverages(response.product.additional_coverage),
            baseCoverages: mapCoverages(response.product.base_coverages),
            coveragesAdjustmentPresentIndication: mapFromSuccessIndication(
                response.product.coverages_adjustment_present_indication
            ),
            coveragesAdjustmentStartDate: response.product.coverages_adjustment_start_date,
            offer: response.product.offer?.description,
            rewardPoints: response.product.reward_points,
            totalDiscount: mapPurchasedCoveragePremium(response.product.total_discount),
            totalDiscountIncludingStackDiscount: mapPurchasedCoveragePremium(
                response.product.total_discount_including_stack_discount
            ),
            totalPremium: mapPurchasedCoveragePremium(response.product.total_premium),
            totalPremiumIncludingDiscount: mapPurchasedCoveragePremium(
                response.product.total_premium_including_discount
            ),
            totalPremiumIncludingStackDiscount: mapPurchasedCoveragePremium(
                response.product.total_premium_including_stack_discount
            ),
            xsell: response.product.xsell,
            productDescription: response.product.product_description,
            collectivityDescription: response.product.collectivity_description,
            stackDiscountDescription: response.product.stack_discount_description,
        },
    };
}

function mapCoverages(request: ApiCoverageSpecificationInterface[]): CoverageSpecificationInterface[] {
    if (!request) {
        return [];
    }

    return request.map((coverage) => ({
        baseCoverageIndication: mapFromSuccessIndication(coverage.base_coverage_indication),
        baseCoverageIndicationDescription: coverage.base_coverage_indication_description,
        coverageDescription: coverage.coverage_description,
        coverageId: mapCoverageIdResponse(coverage.coverage_id),
        purchasedCoverage: mapPurchasedCoverage(coverage.purchased_coverage),
        purchasedCoverageFuture: mapPurchasedCoverage(coverage.purchased_coverage_future),
        collectivityDiscount: mapPurchasedCoveragePremium(coverage.collectivity_discount),
        discount: mapPurchasedCoveragePremium(coverage.discount),
        acceptedIndication: mapFromSuccessIndication(coverage.eligibility?.accepted_indication),
        notification: coverage.eligibility?.notification,
        mandatoryOptionalCode: mapMandatoryCode(coverage.mandatory_optional_code),
        mandatoryOptionalDescription: coverage.mandatory_optional_description,
        premium: mapPurchasedCoveragePremium(coverage.premium),
        premiumIncludingDiscount: mapPurchasedCoveragePremium(coverage.premium_including_discount),
        premiumIncludingStackDiscount: mapPurchasedCoveragePremium(coverage.premium_including_stack_discount),
        stackDiscount: mapPurchasedCoveragePremium(coverage.stack_discount),
    }));
}

function mapPurchasedCoverage(request: ApiPurchasedCoverageInterface): PurchasedCoverageInterface {
    if (!request) {
        return null;
    }

    return {
        contractExpirationDate: request.contract_expiration_date,
        coverageDescription: request.coverage_description,
        coverageId: mapCoverageIdResponse(request.coverage_id),
        endDate: request.end_date,
        firstInvoiceDate: request.first_invoice_date,
        purchasedCoverageSequenceNumber: mapSequenceNumber(request.purchased_coverage_sequence_number),
        startDate: request.start_date,
        validityStatusCode: mapValidityStatusCodeResponse(request.validity_status_code),
    };
}

function mapPurchasedCoveragePremium(request: ApiPurchasedCoveragePremiumInterface): PurchasedCoveragePremiumInterface {
    if (!request) {
        return null;
    }

    return {
        basicPremium: mapPrice(request.basic_premium),
        premium: mapPrice(request.premium),
        premiumIncludingPremiumTax: mapPrice(request.premium_including_premium_tax),
        premiumTax: mapPrice(request.premium_tax),
    };
}

function mapPrice(request: number | null): number {
    if (!request) {
        return 0;
    }

    return request;
}
