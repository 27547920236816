import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorInterface } from '../../core';
import { ValidateElementRequestInterface } from '../interfaces';
import { ValidationApiService } from '../services/validation.api.service';
import { ValidationEventService } from '../services/validation.event.service';
import {
    ValidateElementsAction,
    ValidateElementsErrorAction,
    ValidateElementSuccessAction,
    ValidationActions,
} from './validation.actions';

@Injectable()
export class ValidationEffects {
    public validation$: Observable<Action> = createEffect(() => {
        return this.action$.pipe(
            ofType(ValidationActions.ValidateElements),
            switchMap((action: ValidateElementsAction) => {
                return this.apiService.validate$(action.payload.request).pipe(
                    map(() => new ValidateElementSuccessAction({ name: action.payload.request.name })),
                    catchError((errors: ErrorInterface[]) => {
                        const request: ValidateElementRequestInterface = action.payload.request;
                        return of(new ValidateElementsErrorAction({ name: request.name, errors }));
                    })
                );
            })
        );
    }
    );

    public validationSuccess$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ValidationActions.ValidateElementsSuccess),
                map((action: ValidateElementSuccessAction) => this.eventService.onValidateSuccess(action.payload.name))
            );
        },
        { dispatch: false }
    );

    public validationError$: Observable<void> = createEffect(
        () => {
            return this.action$.pipe(
                ofType(ValidationActions.ValidateElementsError),
                map((action: ValidateElementsErrorAction) => {
                    return this.eventService.onValidateError(action.payload.name, action.payload.errors);
                })
            );
        },
        { dispatch: false }
    );

    constructor(
        private action$: Actions,
        private eventService: ValidationEventService,
        private apiService: ValidationApiService
    ) {}
}
