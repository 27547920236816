import { mapEnumApiRequest, mapEnumApiResponse } from '../../core';
import { ApiClaimObjectTypeCodeEnum, ClaimObjectTypeCodeEnum } from '../enums';

export function mapClaimObjectTypeCodeResponse(response: ApiClaimObjectTypeCodeEnum): ClaimObjectTypeCodeEnum {
    return mapEnumApiResponse(response, ApiClaimObjectTypeCodeEnum, ClaimObjectTypeCodeEnum);
}

export function mapClaimObjectTypeCodeRequest(request: ClaimObjectTypeCodeEnum): ApiClaimObjectTypeCodeEnum {
    return mapEnumApiRequest(request, ApiClaimObjectTypeCodeEnum, ClaimObjectTypeCodeEnum);
}
