import { mapSequenceNumber } from '../../core';
import { ApiSpecificationCaseInterface, SpecificationCaseInterface } from '../interfaces';

export function mapSpecificationCaseResponse(response: ApiSpecificationCaseInterface): SpecificationCaseInterface {
    if (!response) {
        return;
    }

    return {
        caseSequenceNumber: mapSequenceNumber(response.case_sequence_number),
        caseSequenceNumberDescription: response.case_sequence_number_description,
    };
}
