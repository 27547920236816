import { ApiRelationCodeEnum, RelationCodeEnum } from '../enums';

export function mapRelationCodeResponse(response: ApiRelationCodeEnum): RelationCodeEnum {
    if (!response) {
        return null;
    }

    switch (response) {
    case ApiRelationCodeEnum.Child:
        return RelationCodeEnum.Child;
    case ApiRelationCodeEnum.Owner:
        return RelationCodeEnum.Owner;
    case ApiRelationCodeEnum.Partner:
        return RelationCodeEnum.Partner;
    }
}

export function mapRelationCodeRequest(request: RelationCodeEnum): ApiRelationCodeEnum {
    if (!request) {
        return null;
    }

    switch (request) {
    case RelationCodeEnum.Child:
        return ApiRelationCodeEnum.Child;
    case RelationCodeEnum.Owner:
        return ApiRelationCodeEnum.Owner;
    case RelationCodeEnum.Partner:
        return ApiRelationCodeEnum.Partner;
    }
}
