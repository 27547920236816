import { AcceptanceContainerInterface, AcceptanceElementInterface } from '../interfaces';

export function acceptanceClassicMapper(payload: AcceptanceContainerInterface[]): any[] {
    const classicPayload = [];

    payload.forEach((context: AcceptanceContainerInterface) => {
        if (context.container) {
            context.container.forEach((containerElement: AcceptanceContainerInterface) => {
                const contextContainerElementName = containerElement.context_element_name;
                containerElement.element.forEach((element: AcceptanceElementInterface) => {
                    const elementItem = {
                        element_name: element.element_name,
                        value: element.value,
                        context_element_name: contextContainerElementName,
                    };
                    classicPayload.push(elementItem);
                });
            });
        }

        if (context.element) {
            const contextElementName = context.context_element_name;
            context.element.forEach((element: AcceptanceElementInterface) => {
                const elementItem = {
                    element_name: element.element_name,
                    value: element.value,
                    context_element_name: contextElementName,
                };
                classicPayload.push(elementItem);
            });
        }
    });

    return classicPayload;
}
