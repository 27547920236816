import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { API_CONFIGURATION, SEO_CONFIGURATION } from './configuration';
import { windowProvider } from './configuration/window.provider';
import { ResetInterceptor } from './interceptors';
import { ApiConfigurationInterface, SeoConfigurationInterface } from './interfaces';

@NgModule({
    imports: [HttpClientModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ResetInterceptor, multi: true }],
})
export class OutCoreModule {
    public static forRoot(
        apiConfiguration: ApiConfigurationInterface,
        seoConfiguration: SeoConfigurationInterface
    ): ModuleWithProviders<OutCoreModule> {
        return {
            ngModule: OutCoreModule,
            providers: [
                windowProvider,
                { provide: API_CONFIGURATION, useValue: apiConfiguration },
                { provide: SEO_CONFIGURATION, useValue: seoConfiguration },
            ],
        };
    }
}
