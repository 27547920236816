import {
    ApiCreateMailingAddressRequest,
    CreateDutchMailingAddressRequestInterface,
    CreateMailingAddressRequestInterface,
    ApiCreateDutchMailingAddressRequest,
    ApiTerminateActiveMailingAddressRequest,
    TerminateActiveMailingAddressRequestInterface,
} from '../interfaces';

export function mapCreateMailingAddressRequest(
    request: CreateMailingAddressRequestInterface
): ApiCreateMailingAddressRequest {
    return {
        mailing_address: {
            start_date: request.startDate,
            international_address_id: request.internationalAddressId,
        },
    };
}

export function mapCreateDutchMailingAddressRequest(
    request: CreateDutchMailingAddressRequestInterface
): ApiCreateDutchMailingAddressRequest {
    return {
        mailing_address: {
            civic_number: request.civicNumber,
            civic_number_suffix: request.civicNumberSuffix,
            country_id: request.countryId,
            postal_code: request.postalCode,
            start_date: request.startDate,
        },
    };
}

export function mapTerminateActiveMailingAddressRequest(
    request: TerminateActiveMailingAddressRequestInterface
): ApiTerminateActiveMailingAddressRequest {
    return {
        end_date: request.endDate,
    };
}
