import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, ErrorInterface, ErrorLevelEnum } from '../../core';
import { PolicyProductIdEnum } from '../enums';
import {
    AddCoverageRequestInterface,
    AddCoverageResponseInterface,
    AddPremiumDeterminingFactorRequestInterface,
    AddPremiumDeterminingFactorResponseInterface,
    AddRegularDriverRequestInterface,
    AddRegularDriverResponseInterface,
    AllCoveragesRequestInterface,
    AllCoveragesResponseInterface,
    AllProductDetailsRequestInterface,
    AllProductDetailsResponseInterface,
    ApiAddCoverageRequest,
    ApiAddCoverageResponse,
    ApiAddPremiumDeterminingFactorRequest,
    ApiAddPremiumDeterminingFactorResponse,
    ApiAddRegularDriverRequest,
    ApiAddRegularDriverResponse,
    ApiCancelAddedCoverageRequest,
    ApiCancelAddedCoverageResponse,
    ApiCancelAddedPremiumDeterminingFactorRequest,
    ApiCancelAddedPremiumDeterminingFactorResponse,
    ApiCancelAddedProductRequest,
    ApiCancelAddedProductResponse,
    ApiCancelAddedRegularDriverRequest,
    ApiCancelAddedRegularDriverResponse,
    ApiCoverageRequest,
    ApiCoverageResponse,
    ApiEndDateProductRequest,
    ApiEndDateProductResponse,
    ApiEndProductRequest,
    ApiEndProductResponse,
    ApiEndRegularDriverRequest,
    ApiEndRegularDriverResponse,
    ApiPolicyResponse,
    ApiProductDetailsAccidentsResponse,
    ApiProductDetailsBicycleResponse,
    ApiProductDetailsCaravanResponse,
    ApiProductDetailsCarResponse,
    ApiProductDetailsHomeResponse,
    ApiProductDetailsLegalAssistanceResponse,
    ApiProductDetailsLiabilityResponse,
    ApiProductDetailsMopedResponse,
    ApiProductDetailsMotorcycleResponse,
    ApiProductDetailsPetResponse,
    ApiProductDetailsRequest,
    ApiProductDetailsTravelResponse,
    ApiReviveProductRequest,
    ApiReviveProductResponse,
    ApiStartDateProductRequest,
    ApiStartDateProductResponse,
    CancelAddedCoverageRequestInterface,
    CancelAddedCoverageResponseInterface,
    CancelAddedPremiumDeterminingFactorRequestInterface,
    CancelAddedPremiumDeterminingFactorResponseInterface,
    CancelAddedProductRequestInterface,
    CancelAddedProductResponseInterface,
    CancelAddedRegularDriverRequestInterface,
    CancelAddedRegularDriverResponseInterface,
    CoverageRequestInterface,
    CoverageResponseInterface,
    EndDateProductRequestInterface,
    EndDateProductResponseInterface,
    EndProductRequestInterface,
    EndProductResponseInterface,
    EndRegularDriverRequestInterface,
    EndRegularDriverResponseInterface, mapPolicyDownloadResponse,
    PolicyResponseInterface,
    ProductDetailsAccidentsResponseInterface,
    ProductDetailsBicycleResponseInterface,
    ProductDetailsCaravanResponseInterface,
    ProductDetailsCarResponseInterface,
    ProductDetailsHomeResponseInterface,
    ProductDetailsLegalAssistanceResponseInterface,
    ProductDetailsLiabilityResponseInterface,
    ProductDetailsMopedResponseInterface,
    ProductDetailsMotorcycleResponseInterface,
    ProductDetailsPetResponseInterface,
    ProductDetailsRequestInterface,
    ProductDetailsResponseInterface,
    ProductDetailsTravelResponseInterface,
    ProductRequestInterface,
    ProductResponseInterface,
    ReviveProductRequestInterface,
    ReviveProductResponseInterface,
    StartDateProductRequestInterface,
    StartDateProductResponseInterface,
    UpdatePurchasedProductRequestInterface,
    UpdatePurchasedProductResponseInterface,
} from '../interfaces';
import { mapAddCoverageRequest } from '../mappers/add-coverage';
import { mapAddPremiumDeterminingFactorRequest } from '../mappers/add-premium-determining-factor';
import { mapAddRegularDriverRequest } from '../mappers/add-regular-driver';
import { mapCancelAddedCoverageRequest } from '../mappers/cancel-added-coverage';
import { mapCancelAddedPremiumDeterminingFactorRequest } from '../mappers/cancel-added-premium-determining-factor';
import { mapCancelAddedProductRequest, mapCancelAddedProductResponse } from '../mappers/cancel-added-product';
import { mapCancelAddedRegularDriverRequest } from '../mappers/cancel-added-regular-driver';
import { mapCoverageRequest, mapCoverageResponse } from '../mappers/coverage';
import { mapEndDateProductRequest } from '../mappers/end-date-product';
import { mapEndProductRequest } from '../mappers/end-product';
import { mapEndRegularDriverRequest } from '../mappers/end-regular-driver';
import { mapPolicyResponse } from '../mappers/policy';
import { mapProductResponse } from '../mappers/product';
import {
    mapProductDetailsAccidentsResponse,
    mapProductDetailsBicycleResponse,
    mapProductDetailsCaravanResponse,
    mapProductDetailsCarResponse,
    mapProductDetailsHomeResponse,
    mapProductDetailsLegalAssistanceResponse,
    mapProductDetailsLiabilityResponse,
    mapProductDetailsMopedResponse,
    mapProductDetailsMotorCycleResponse,
    mapProductDetailsPetResponse,
    mapProductDetailsRequest,
    mapProductDetailsTravelResponse,
} from '../mappers/product-details';
import { mapReviveProductRequest } from '../mappers/revive-product';
import { mapStartDateProductRequest } from '../mappers/start-date-product';
import { ApiPolicyDownloadRequest } from '../interfaces/policy/api.policy-download.request';
import { PolicyDownloadResponse } from '../interfaces/policy/policy-download.response';
import { ApiPolicyDownloadResponse } from '../interfaces/policy/api.policy-download.response';

const RESOURCES = {
    POLICY: 'en-gb/relation-general/request/policy',
    POLICY_DOWNLOAD: 'en-gb/relation-general/provide/policy',
    PRODUCT_DETAILS_CAR: 'en-gb/insurance-product/request/purchased_product-auto',
    PRODUCT_DETAILS_LIABILITY: 'en-gb/insurance-product/request/purchased_product-liability',
    PRODUCT_DETAILS_MOPED: 'en-gb/insurance-product/request/purchased_product-limited_speed_motorcycle',
    PRODUCT_DETAILS_PET: 'en-gb/insurance-product/request/purchased_product-pet',
    PRODUCT_DETAILS_MOTORCYCLE: 'en-gb/insurance-product/request/purchased_product-motorcycle',
    PRODUCT_DETAILS_ACCIDENTS: 'en-gb/insurance-product/request/purchased_product-accidents',
    PRODUCT_DETAILS_LEGAL_ASSISTANCE: 'en-gb/insurance-product/request/purchased_product-legal_assistance',
    PRODUCT_DETAILS_TRAVEL: 'en-gb/insurance-product/request/purchased_product-travel',
    PRODUCT_DETAILS_HOME: 'en-gb/insurance-product/request/purchased_product-home',
    PRODUCT_DETAILS_BICYCLE: 'en-gb/insurance-product/request/purchased_product-bicycle',
    PRODUCT_DETAILS_CARAVAN: 'en-gb/insurance-product/request/purchased_product-caravan',
    COVERAGE: 'en-gb/insurance-coverage/calculate/coverage-premium-purchased_product',
    REVIVE_PRODUCT: 'en-gb/insurance-product/revive/terminated-purchased_product',
    START_DATE_PRODUCT: 'en-gb/insurance-product/modify/future-purchased_product-start_date',
    END_PRODUCT: 'en-gb/insurance-product/terminate/active-purchased_product',
    END_DATE_PRODUCT: 'en-gb/insurance-product/modify/new_termination_date_for_future-purchased_product-end_date',
    CANCEL_ADDED_PRODUCT: 'en-gb/insurance-product/cancel/future-purchased_product',
    ADD_PREMIUM_DETERMINING_FACTOR: 'en-gb/insurance-product/modify/active-modifiable_premium_determining_factor',
    CANCEL_ADDED_PREMIUM_DETERMINING_FACTOR:
        'en-gb/insurance-product/cancel/future-modifiable_premium_determining_factor',
    ADD_REGULAR_DRIVER: 'en-gb/insurance-product/store/non_regular_driver',
    END_REGULAR_DRIVER: 'en-gb/insurance-product/terminate/active-non_regular_driver',
    CANCEL_ADDED_REGULAR_DRIVER: 'en-gb/insurance-product/cancel/future-non_regular_driver',
    ADD_COVERAGE: 'en-gb/insurance-coverage/change_generic/purchasedcoverages',
    CANCEL_ADDED_COVERAGE: 'en-gb/insurance-coverage/cancel/future-purchased_coverage',
};

@Injectable({
    providedIn: 'root',
})
export class InsurancePolicyApiService {
    constructor(private apiService: ApiService) {}

    public policy$(): Observable<PolicyResponseInterface> {
        return this.apiService.post$<ApiPolicyResponse>(RESOURCES.POLICY).pipe(map(mapPolicyResponse));
    }

    public product$(payload: ProductRequestInterface): Observable<ProductResponseInterface> {
        return this.policy$().pipe(map((response) => mapProductResponse(payload, response)));
    }

    public productDetails$(payload: ProductDetailsRequestInterface): Observable<ProductDetailsResponseInterface> {
        const mappedRequest: ApiProductDetailsRequest = mapProductDetailsRequest(payload);

        switch (payload.productId) {
            case PolicyProductIdEnum.Accidents:
                return this.productDetailsAccidents$(mappedRequest);
            case PolicyProductIdEnum.Bicycle:
                return this.productDetailsBicycle$(mappedRequest);
            case PolicyProductIdEnum.Car:
                return this.productDetailsCar$(mappedRequest);
            case PolicyProductIdEnum.Caravan:
                return this.productDetailsCaravan$(mappedRequest);
            case PolicyProductIdEnum.Home:
                return this.productDetailsHome$(mappedRequest);
            case PolicyProductIdEnum.LegalAssistance:
                return this.productDetailsLegalAssistance$(mappedRequest);
            case PolicyProductIdEnum.Liability:
                return this.productDetailsLiability$(mappedRequest);
            case PolicyProductIdEnum.Moped:
                return this.productDetailsMoped$(mappedRequest);
            case PolicyProductIdEnum.Motorcycle:
                return this.productDetailsMotorcycle$(mappedRequest);
            case PolicyProductIdEnum.Pet:
                return this.productDetailsPet$(mappedRequest);
            case PolicyProductIdEnum.Travel:
                return this.productDetailsTravel$(mappedRequest);
        }
    }

    public allProductDetails$(
        payload: AllProductDetailsRequestInterface
    ): Observable<AllProductDetailsResponseInterface> {
        const allProductDetails: ProductDetailsResponseInterface[] = [];
        const allProductDetails$ = new Subject<AllProductDetailsResponseInterface>();
        const errors: ErrorInterface[] = [
            {
                element: '',
                id: '',
                level: ErrorLevelEnum.Error,
                message: 'Loading All Product Details Failed',
            },
        ];

        payload.request.forEach(({ productSequenceNumber, productId }) => {
            this.productDetails$({ productSequenceNumber, productId }).subscribe(
                (response) => {
                    allProductDetails.push(response);

                    if (allProductDetails.length === payload.request.length) {
                        allProductDetails$.next({ purchasedProducts: allProductDetails });
                        allProductDetails$.complete();
                    }
                },
                (error) => {
                    console.error(error);
                    allProductDetails$.error(errors);
                }
            );
        });

        return allProductDetails$.asObservable();
    }

    public coverage$(payload: CoverageRequestInterface): Observable<CoverageResponseInterface> {
        const mappedRequest: ApiCoverageRequest = mapCoverageRequest(payload);
        return this.apiService
            //Backend may throw an error, but we want to continue a succesful response
            .postWithoutErrorHandling$<ApiCoverageResponse>(RESOURCES.COVERAGE, mappedRequest)
            .pipe(map((response) => mapCoverageResponse(response, payload.productSequenceNumber)));
    }

    public allCoverages$(payload: AllCoveragesRequestInterface): Observable<AllCoveragesResponseInterface> {
        const allCoverages: CoverageResponseInterface[] = [];
        const allCoverages$ = new Subject<AllCoveragesResponseInterface>();
        const errors: ErrorInterface[] = [
            {
                element: '',
                id: '',
                level: ErrorLevelEnum.Error,
                message: 'Loading All Coverages Failed',
            },
        ];

        payload.request.forEach(({ productSequenceNumber }) => {
            this.coverage$({ productSequenceNumber }).subscribe(
                (response) => {
                    allCoverages.push(response);

                    if (allCoverages.length === payload.request.length) {
                        allCoverages$.next({ purchasedProducts: allCoverages });
                        allCoverages$.complete();
                    }
                },
                (error) => {
                    console.error(error);
                    allCoverages$.error(errors);
                }
            );
        });

        return allCoverages$.asObservable();
    }

    public reviveProduct$(payload: ReviveProductRequestInterface): Observable<ReviveProductResponseInterface> {
        const mappedRequest: ApiReviveProductRequest = mapReviveProductRequest(payload);
        return this.apiService
            .post$<ApiReviveProductResponse>(RESOURCES.REVIVE_PRODUCT, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public startDateProduct$(payload: StartDateProductRequestInterface): Observable<StartDateProductResponseInterface> {
        const mappedRequest: ApiStartDateProductRequest = mapStartDateProductRequest(payload);
        return this.apiService
            .post$<ApiStartDateProductResponse>(RESOURCES.START_DATE_PRODUCT, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public endProduct$(payload: EndProductRequestInterface): Observable<EndProductResponseInterface> {
        const mappedRequest: ApiEndProductRequest = mapEndProductRequest(payload);
        return this.apiService
            .post$<ApiEndProductResponse>(RESOURCES.END_PRODUCT, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public endDateProduct$(payload: EndDateProductRequestInterface): Observable<EndDateProductResponseInterface> {
        const mappedRequest: ApiEndDateProductRequest = mapEndDateProductRequest(payload);
        return this.apiService
            .post$<ApiEndDateProductResponse>(RESOURCES.END_DATE_PRODUCT, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public cancelAddedProduct$(
        payload: CancelAddedProductRequestInterface
    ): Observable<CancelAddedProductResponseInterface> {
        const mappedRequest: ApiCancelAddedProductRequest = mapCancelAddedProductRequest(payload);
        return (
            this.apiService
                .post$<ApiCancelAddedProductResponse>(RESOURCES.CANCEL_ADDED_PRODUCT, mappedRequest)
                // map payload directly to response so product can be deleted from store.
                .pipe(map(() => mapCancelAddedProductResponse(payload)))
        );
    }

    public addPremiumDeterminingFactor$(
        payload: AddPremiumDeterminingFactorRequestInterface
    ): Observable<AddPremiumDeterminingFactorResponseInterface> {
        const mappedRequest: ApiAddPremiumDeterminingFactorRequest = mapAddPremiumDeterminingFactorRequest(payload);
        return this.apiService
            .post$<ApiAddPremiumDeterminingFactorResponse>(RESOURCES.ADD_PREMIUM_DETERMINING_FACTOR, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public cancelAddedPremiumDeterminingFactor$(
        payload: CancelAddedPremiumDeterminingFactorRequestInterface
    ): Observable<CancelAddedPremiumDeterminingFactorResponseInterface> {
        const mappedRequest: ApiCancelAddedPremiumDeterminingFactorRequest =
            mapCancelAddedPremiumDeterminingFactorRequest(payload);
        return this.apiService
            .post$<ApiCancelAddedPremiumDeterminingFactorResponse>(
                RESOURCES.CANCEL_ADDED_PREMIUM_DETERMINING_FACTOR,
                mappedRequest
            )
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public addRegularDriver$(payload: AddRegularDriverRequestInterface): Observable<AddRegularDriverResponseInterface> {
        const mappedRequest: ApiAddRegularDriverRequest = mapAddRegularDriverRequest(payload);
        return this.apiService
            .post$<ApiAddRegularDriverResponse>(RESOURCES.ADD_REGULAR_DRIVER, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public endRegularDriver$(payload: EndRegularDriverRequestInterface): Observable<EndRegularDriverResponseInterface> {
        const mappedRequest: ApiEndRegularDriverRequest = mapEndRegularDriverRequest(payload);
        return this.apiService
            .post$<ApiEndRegularDriverResponse>(RESOURCES.END_REGULAR_DRIVER, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public cancelAddedRegularDriver$(
        payload: CancelAddedRegularDriverRequestInterface
    ): Observable<CancelAddedRegularDriverResponseInterface> {
        const mappedRequest: ApiCancelAddedRegularDriverRequest = mapCancelAddedRegularDriverRequest(payload);
        return this.apiService
            .post$<ApiCancelAddedRegularDriverResponse>(RESOURCES.CANCEL_ADDED_REGULAR_DRIVER, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public addCoverage$(payload: AddCoverageRequestInterface): Observable<AddCoverageResponseInterface> {
        const mappedRequest: ApiAddCoverageRequest = mapAddCoverageRequest(payload);
        return this.apiService
            .post$<ApiAddCoverageResponse>(RESOURCES.ADD_COVERAGE, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public cancelAddedCoverage$(
        payload: CancelAddedCoverageRequestInterface
    ): Observable<CancelAddedCoverageResponseInterface> {
        const mappedRequest: ApiCancelAddedCoverageRequest = mapCancelAddedCoverageRequest(payload);
        return this.apiService
            .post$<ApiCancelAddedCoverageResponse>(RESOURCES.CANCEL_ADDED_COVERAGE, mappedRequest)
            .pipe(switchMap(() => this.updatePurchasedProduct$(payload)));
    }

    public policyDownload$(
        payload: ApiPolicyDownloadRequest
    ): Observable<PolicyDownloadResponse> {
        return this.apiService
            .post$<ApiPolicyDownloadResponse>(RESOURCES.POLICY_DOWNLOAD, payload)
            .pipe(map(mapPolicyDownloadResponse));
    }

    private productDetailsAccidents$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsAccidentsResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsAccidentsResponse>(RESOURCES.PRODUCT_DETAILS_ACCIDENTS, payload)
            .pipe(map(mapProductDetailsAccidentsResponse));
    }

    private productDetailsCar$(payload: ApiProductDetailsRequest): Observable<ProductDetailsCarResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsCarResponse>(RESOURCES.PRODUCT_DETAILS_CAR, payload)
            .pipe(map(mapProductDetailsCarResponse));
    }

    private productDetailsLiability$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsLiabilityResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsLiabilityResponse>(RESOURCES.PRODUCT_DETAILS_LIABILITY, payload)
            .pipe(map(mapProductDetailsLiabilityResponse));
    }

    private productDetailsMoped$(payload: ApiProductDetailsRequest): Observable<ProductDetailsMopedResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsMopedResponse>(RESOURCES.PRODUCT_DETAILS_MOPED, payload)
            .pipe(map(mapProductDetailsMopedResponse));
    }

    private productDetailsPet$(payload: ApiProductDetailsRequest): Observable<ProductDetailsPetResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsPetResponse>(RESOURCES.PRODUCT_DETAILS_PET, payload)
            .pipe(map(mapProductDetailsPetResponse));
    }

    private productDetailsMotorcycle$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsMotorcycleResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsMotorcycleResponse>(RESOURCES.PRODUCT_DETAILS_MOTORCYCLE, payload)
            .pipe(map(mapProductDetailsMotorCycleResponse));
    }

    private productDetailsLegalAssistance$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsLegalAssistanceResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsLegalAssistanceResponse>(RESOURCES.PRODUCT_DETAILS_LEGAL_ASSISTANCE, payload)
            .pipe(map(mapProductDetailsLegalAssistanceResponse));
    }

    private productDetailsTravel$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsTravelResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsTravelResponse>(RESOURCES.PRODUCT_DETAILS_TRAVEL, payload)
            .pipe(map(mapProductDetailsTravelResponse));
    }

    private productDetailsHome$(payload: ApiProductDetailsRequest): Observable<ProductDetailsHomeResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsHomeResponse>(RESOURCES.PRODUCT_DETAILS_HOME, payload)
            .pipe(map(mapProductDetailsHomeResponse));
    }

    private productDetailsBicycle$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsBicycleResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsBicycleResponse>(RESOURCES.PRODUCT_DETAILS_BICYCLE, payload)
            .pipe(map(mapProductDetailsBicycleResponse));
    }

    private productDetailsCaravan$(
        payload: ApiProductDetailsRequest
    ): Observable<ProductDetailsCaravanResponseInterface> {
        return this.apiService
            .post$<ApiProductDetailsCaravanResponse>(RESOURCES.PRODUCT_DETAILS_CARAVAN, payload)
            .pipe(map(mapProductDetailsCaravanResponse));
    }

    private updatePurchasedProduct$(
        payload: UpdatePurchasedProductRequestInterface
    ): Observable<UpdatePurchasedProductResponseInterface> {
        return combineLatest([this.product$(payload), this.productDetails$(payload), this.coverage$(payload)]).pipe(
            map(([product, productDetails, coverage]) => ({
                coverage: coverage.coverage,
                productId: payload.productId,
                productSequenceNumber: payload.productSequenceNumber,
                product: product.product,
                productDetails: productDetails.productDetails,
            }))
        );
    }

}
