import { ClaimStepResponseInterface } from '../interfaces';

export interface ClaimStepsState {
    claimSteps: ClaimStepResponseInterface[];
}

export const CLAIM_STEPS_FEATURE = 'claim-steps';

export const INITIAL_CLAIM_STEPS_STATE: ClaimStepsState = {
    claimSteps: [],
};
