export enum ApiUrlResourcesNetherlandsEnum {
    // Claim Funnel
    ClaimCategories = 'en-gb/damage-damage_report/request/kind_of_loss-collection',
    ClaimCompensation = 'en-gb/claim-pets/determine/reimbursement',
    ClaimCoverage = 'en-gb/damage-damage_report/determine/coverage',
    ClaimCoverageCustomerResponse = 'en-gb/damage-damage_report/store/customerresponse',
    ClaimDeleteExhibits = 'en-gb/damage-correspondence/delete/exhibit',
    ClaimLoadExhibits = 'en-gb/claim-case/request/exhibits',
    ClaimObjectDetails = 'en-gb/damage-damage_report/request/specification-damage-object',
    ClaimObjects = 'en-gb/damage-report/provide/claimobjects',
    ClaimOverview = 'en-gb/damage-damage_report/request/overview-damage-dialog',
    ClaimProcessSteps = 'en-gb/damage-damage_report/request/complete-damage-report-process-steps',
    ClaimSaveExhibits = 'en-gb/damage-correspondence/upload/exhibit',
    ClaimSubmitExhibits = 'en-gb/damage-correspondence/submit/exhibit',
    GetAppointments = 'en-gb/damage-appointment-calendar/determine/appointments',
    GetClaimNotification = 'en-gb/damage-correspondence/request/case-notification',
    GetLocations = 'en-gb/damage-appointment-calendar/request/locations',
    GetParties = 'en-gb/claim-case/request/parties',
    GetTimeslots = 'en-gb/damage-appointment-calendar/request/timeslots',
    RemoveClaimObject = 'en-gb/damage-damage_report/delete/sustained_damage',
    RemoveSpecification = 'en-gb/damage-report/cancel/case',
    RequestClaimSpecification = 'en-gb/damage-damage_report/store/specification-kind_of_loss',
    SaveAppointment = 'en-gb/damage-appointment-calendar/store/reservation',
    SaveClaimNotification = 'en-gb/damage-correspondence/modify/case-notification',
    SaveClaimObject = 'en-gb/damage-damage_report/store/specification-damage-object',
    SaveContact = 'en-gb/damage-report/store/contactperson',
    SaveCounterParty = 'en-gb/damage-damage_report/store/counterparty',
    SaveWitness = 'en-gb/damage-report/store/witness',
}
