import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../core';
import { ApiUrlResourcesEnum } from '../../core/enums/resources/resources';
import { EnvironmentService } from '../../core/services/environment.service';
import {
    ApiGetClaimNotificationInterface,
    ApiGetClaimNotificationPayloadInterface,
    ApiSaveClaimNotificationPayloadInterface,
    ClaimNotificationInterface,
    GetClaimNotificationPayloadInterface,
    SaveClaimNotificationPayloadInterface,
} from '../interfaces';
import { getClaimNotificationMapper, saveClaimNotificationPayloadMapper } from '../mappers';

@Injectable({ providedIn: 'root' })
export class ClaimNotificationApiService {
    constructor(private apiService: ApiService, private environmentService: EnvironmentService) {}

    public getClaimNotification$(
        payload: GetClaimNotificationPayloadInterface
    ): Observable<ClaimNotificationInterface> {
        const request: ApiGetClaimNotificationPayloadInterface = {
            notificatie: {
                case_sequence_number: payload.caseSequenceNumber,
            },
        };

        return this.apiService
            .post$<ApiGetClaimNotificationInterface>(
                this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.GetClaimNotification),
                request
            )
            .pipe(map(getClaimNotificationMapper));
    }

    public saveClaimNotification$(
        payload: SaveClaimNotificationPayloadInterface
    ): Observable<ClaimNotificationInterface> {
        const request: ApiSaveClaimNotificationPayloadInterface = saveClaimNotificationPayloadMapper(payload);

        return this.apiService
            .post$<boolean>(
                this.environmentService.getEnvironmentResourceUrl(ApiUrlResourcesEnum.SaveClaimNotification),
                request
            )
            .pipe(switchMap(() => this.getClaimNotification$({ caseSequenceNumber: payload.caseSequenceNumber })));
    }
}
