import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { NotificationInterface, RequestService } from '../../../core';
import { Address, AddressData } from '../../interfaces';
import * as fromSales from '../sales.reducers';
import { GetAddressData, GetAddressDataError, GetAddressDataSuccess } from './address.actions';
import * as fromAddress from './address.selectors';

@Injectable({
    providedIn: 'root',
})
export class AddressService {
    public addressData$: Observable<AddressData> = this.store$.select(fromAddress.getAddressData);

    public errors$: Observable<NotificationInterface[]> = this.store$.select(fromAddress.getErrors);

    private cached: { [cacheId: string]: Observable<AddressData> } = {};

    constructor(private store$: Store<fromSales.State>, private requestService: RequestService) {}

    public getAddressData(address: Address): Observable<AddressData> {
        const cacheId = `address_details_${address.address.postal_code}_${address.address.civic_number}`;
        if (!this.cached[cacheId]) {
            this.cached[cacheId] = this.requestService
                .post('en-gb/address-general/request/dutch-address-postcode_address', address)
                .pipe(
                    map((response) => {
                        if (response.data.success === false) {
                            throw response.messages;
                        }
                        return response.data;
                    }),
                    shareReplay({ bufferSize: 1, refCount: true })
                );
        }

        return this.cached[cacheId];
    }

    public validate(address: Address): Observable<AddressData> {
        this.store$.dispatch(new GetAddressData(address));

        return this.getAddressData(address).pipe(
            map((result: AddressData) => {
                this.store$.dispatch(new GetAddressDataSuccess({ result, address }));
                return result;
            }),
            catchError((errors) => {
                this.store$.dispatch(new GetAddressDataError({ errors }));
                return throwError(errors);
            })
        );
    }
}
