import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AggregateEffects } from './store/aggregate.effects';
import { aggregateReducers } from './store/aggregate.reducers';
import { AGGREGATE_FEATURE } from './store/aggregate.state';

@NgModule({
    imports: [
        StoreModule.forFeature(AGGREGATE_FEATURE, aggregateReducers),
        EffectsModule.forFeature([AggregateEffects]),
    ],
})
export class OutAggregateModule {}
