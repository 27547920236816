import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({
    providedIn: 'root',
})
export class AggregateEventService {
    private _getAggregate$: Subject<void>;

    public getAggregate$(): Observable<void> {
        this._getAggregate$ = new Subject();
        return this._getAggregate$.asObservable();
    }

    public onGetAggregateSuccess(): void {
        this._getAggregate$.next();
        this._getAggregate$.complete();
    }

    public onGetAggregateError(errors: ErrorInterface[]): void {
        if (this._getAggregate$) {
            this._getAggregate$.error(errors);
        }
    }
}
