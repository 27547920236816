import { mapFromGenderCode, mapPhone, mapSequenceNumber } from '../../core/mappers';
import { ApiSaveContactRequestInterface, SaveContactRequestInterface } from '../interfaces';

export function mapSaveContactRequest(request: SaveContactRequestInterface): ApiSaveContactRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.claimSequenceNumber),
        },
        contact_person: {
            relation_to_insured_person_code: request.contact.relationToInsuredPersonCode,
            gender_code: mapFromGenderCode(request.contact.genderCode),
            pre_nominal_letters: request.contact.preNominalLetters,
            initials: request.contact.initials,
            prefix_lastname: request.contact.prefixLastname,
            first_name: request.contact.firstName,
            surname: request.contact.surname,
            birthdate: request.contact.birthdate,
            email_address: request.contact.emailAddress,
            telephone_country_code: request.contact.telephoneCountryCode,
            telephone_number: mapPhone(request.contact.telephoneNumber),
            external_reference_id: request.contact.externalReferenceId,
            soort_extern_referentie_code: request.contact.typeExternalReferenenceCode,
            account_number: request.contact.accountNumber,
            account_holder_name: request.contact.accountHolderName,
            postal_code: request.contact.postalCode,
            civic_number: request.contact.civicNumber,
            civic_number_suffix: request.contact.civicNumberSuffix,
            country_id: request.contact.countryId,
            street_name: request.contact.streetName,
            city: request.contact.city,
            province_code: request.contact.provinceCode,
            post_office_box: request.contact.postOfficeBox,
            additional_addressing_explanation: request.contact.additionalAddressingExplanation,
        },
    };
}
