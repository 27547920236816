import { mapSequenceNumber } from '../../core';
import { ApiGetClaimCoverageRequestInterface, GetClaimCoverageRequestInterface } from '../interfaces';

export function mapGetClaimCoverageRequest(
    request: GetClaimCoverageRequestInterface
): ApiGetClaimCoverageRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
    };
}
