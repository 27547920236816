import { ClaimAppointmentInterface } from '../interfaces';
import { ClaimLocationInterface } from '../interfaces/get-locations.interface';
import { ClaimTimeslotInterface } from '../interfaces/get-timeslots.interface';

export interface ClaimAppointment {
    claimAppointment: ClaimAppointmentState;
}

export interface ClaimAppointmentState {
    appointments: ClaimAppointmentInterface[];
    locations: ClaimLocationInterface[];
    timeslots: ClaimTimeslotInterface[];
}

export const INITIAL_CLAIM_APPOINTMENT_STATE: ClaimAppointmentState = {
    appointments: [],
    locations: [],
    timeslots: [],
};

export const CLAIM_APPOINTMENT_FEATURE = 'claimAppointment';
