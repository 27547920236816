import { ErrorLevelEnum } from '../../core';
import { ApiGetSpecificationResponseInterface, GetSpecificationResponseInterface } from '../interfaces';
import { mapSpecificationResponse } from './specification.mapper';

export function mapGetSpecifcationResponse(
    response: ApiGetSpecificationResponseInterface
): GetSpecificationResponseInterface {
    if (!response) {
        return null;
    }

    // If a damage claim allready exists an error is thrown
    response.errors.forEach((item) => {
        if (item.level !== ErrorLevelEnum.Choose && item.level !== ErrorLevelEnum.Info) {
            throw response.errors;
        }
    });

    return {
        notifications: response.errors,
        specification: mapSpecificationResponse(response.data),
    };
}
