import { Action } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import { ValidateElementRequestInterface } from '../interfaces';

export enum ValidationActions {
    ValidateElements = '[Validation] Validate elements',
    ValidateElementsSuccess = '[Validation] Validate elements success',
    ValidateElementsError = '[Validation] Validate elements error',
}

export class ValidateElementsAction implements Action {
    public readonly type = ValidationActions.ValidateElements;

    constructor(public payload: { request: ValidateElementRequestInterface }) {}
}

export class ValidateElementSuccessAction implements Action {
    public readonly type = ValidationActions.ValidateElementsSuccess;

    constructor(public payload: { name: string }) {}
}

export class ValidateElementsErrorAction implements Action {
    public readonly type = ValidationActions.ValidateElementsError;

    constructor(public payload: { name: string; errors: ErrorInterface[] }) {}
}

export type ValidationActionType = ValidateElementsAction | ValidateElementSuccessAction | ValidateElementsErrorAction;
