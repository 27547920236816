import { ObjectValues } from '../../core';

export const apiInvalidatedReasonCodeEnum = {
    Invalid: 'ONGE',
    Reopened: 'CLAF',
    Rejected: 'AFGW',
    RetracedNotification: 'IMLD',
    IncorrectNotification: 'TOG',
    DoubleReporting: 'DMEL',
    Archived: 'ARCH',
} as const;

export const invalidatedReasonCodeEnum = {
    Invalid: 'Invalid',
    Reopened: 'Reopened',
    Rejected: 'Rejected',
    RetracedNotification: 'RetracedNotification',
    IncorrectNotification: 'IncorrectNotification',
    DoubleReporting: 'DoubleReporting',
    Archived: 'Archived',
} as const;

export type ApiInvalidatedReasonCodeType = ObjectValues<typeof apiInvalidatedReasonCodeEnum>;
export type InvalidatedReasonCodeType = ObjectValues<typeof invalidatedReasonCodeEnum>;
