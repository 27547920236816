import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({ providedIn: 'root' })
export class ClaimObjectsEventService {
    private _getClaimObjects$: Subject<void>;
    private _getClaimObjectDetails$: Subject<void>;
    private _updateClaimObjectDetails$: Subject<void>;
    private _reloadClaimObjectDetails$: Subject<void>;
    private _saveClaimObject$: Subject<void>;
    private _removeClaimObject$: Subject<void>;

    public getClaimObjects$(): Observable<void> {
        this._getClaimObjects$ = new Subject();
        return this._getClaimObjects$.asObservable();
    }

    public onGetClaimObjectsSuccess(): void {
        this._getClaimObjects$.next();
        this._getClaimObjects$.complete();
    }

    public onGetClaimObjectsError(errors: ErrorInterface[]): void {
        this._getClaimObjects$.error(errors);
    }

    public getClaimObjectDetails$(): Observable<void> {
        this._getClaimObjectDetails$ = new Subject();
        return this._getClaimObjectDetails$.asObservable();
    }

    public onGetClaimObjectDetailsSuccess(): void {
        this._getClaimObjectDetails$.next();
        this._getClaimObjectDetails$.complete();
    }

    public onGetClaimObjectDetailsError(errors: ErrorInterface[]): void {
        this._getClaimObjectDetails$.error(errors);
    }

    public updateClaimObjectDetails$(): Observable<void> {
        this._updateClaimObjectDetails$ = new Subject();
        return this._updateClaimObjectDetails$.asObservable();
    }

    public onUpdateClaimObjectDetailsSuccess(): void {
        this._updateClaimObjectDetails$.next();
        this._updateClaimObjectDetails$.complete();
    }

    public onUpdateClaimObjectDetailsError(errors: ErrorInterface[]): void {
        this._updateClaimObjectDetails$.error(errors);
    }

    public reloadClaimObjectDetails$(): Observable<void> {
        this._reloadClaimObjectDetails$ = new Subject();
        return this._reloadClaimObjectDetails$.asObservable();
    }

    public onReloadClaimObjectDetailsSuccess(): void {
        this._reloadClaimObjectDetails$.next();
        this._reloadClaimObjectDetails$.complete();
    }

    public onReloadClaimObjectDetailsError(errors: ErrorInterface[]): void {
        this._reloadClaimObjectDetails$.error(errors);
    }

    public saveClaimObject$(): Observable<void> {
        this._saveClaimObject$ = new Subject();
        return this._saveClaimObject$.asObservable();
    }

    public onSaveClaimObjectSuccess(): void {
        this._saveClaimObject$.next();
        this._saveClaimObject$.complete();
    }

    public onSaveClaimObjectError(errors: ErrorInterface[]): void {
        this._saveClaimObject$.error(errors);
    }

    public removeClaimObject$(): Observable<void> {
        this._removeClaimObject$ = new Subject();
        return this._removeClaimObject$.asObservable();
    }

    public onRemoveClaimObjectsSuccess(): void {
        this._removeClaimObject$.next();
        this._removeClaimObject$.complete();
    }

    public onRemoveClaimObjectsError(errors: ErrorInterface[]): void {
        this._removeClaimObject$.error(errors);
    }
}
