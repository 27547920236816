import { ApiCoverageEnGbInterface } from '../interfaces/product/api-coverage-en-gb.interface';
import { ApiProductEnGbInterface } from '../interfaces/product/api-product-en-gb.interface';
import { CoverageEnGbInterface } from '../interfaces/product/coverage-en-gb.interface';
import { ProductEnGbInterface } from '../interfaces/product/product-en-gb.interface';


function purchasedCoveragesMapper(coverages: ApiCoverageEnGbInterface[]): CoverageEnGbInterface[] {
    return coverages.map((coverage: ApiCoverageEnGbInterface) => {
        return {
            coverageDescription: coverage.coverage_description,
            coverageId: coverage.coverage_id,
        };
    });
}

/** @deprecated use OutInsurancePolicyModule store */
export function purchasedProductsMapper(response: ApiProductEnGbInterface): ProductEnGbInterface[] {
    if (!response) {
        return [];
    }

    return response.purchased_products.map((item) => {
        return {
            purchasedProductSequenceNumber: item.purchased_product_sequence_number,
            premiumCurrent: item.premium_current,
            premiumReferenceDate: item.premium_reference_date,
            productDescription: item.product_description,
            productId: item.product_id,
            purchasedCoveragesCurrent: purchasedCoveragesMapper(item.purchased_coverages_current),
            purchasedCoveragesReferenceDate: purchasedCoveragesMapper(item.purchased_coverages_reference_date),
            reference: item.reference,
        };
    });
}

