import { mapEnumApiResponse, mapFromSuccessIndication, mapSequenceNumber } from '../../core';
import { ApiClaimProcessIdEnum } from '../enums';
import { ClaimProcessIdEnum } from '../enums/claim-process-id.enum';
import {
    ApiGetClaimProcessStepsRequestInterface,
    ApiGetClaimProcessStepsResponseInterface,
    GetClaimProcessStepsRequestInterface,
    GetClaimProcessStepsResponseInterface,
} from '../interfaces';

export function mapClaimProcessStepsRequest(
    request: GetClaimProcessStepsRequestInterface
): ApiGetClaimProcessStepsRequestInterface {
    return {
        case: {
            case_sequence_number: mapSequenceNumber(request.caseSequenceNumber),
        },
    };
}

export function mapClaimProcessStepsResponse(
    response: ApiGetClaimProcessStepsResponseInterface
): GetClaimProcessStepsResponseInterface {
    return {
        processSteps: response.process_steps.map((processStep) => ({
            processId: mapEnumApiResponse(processStep.proces_id, ApiClaimProcessIdEnum, ClaimProcessIdEnum),
            processDescription: processStep.proces_omschrijving,
            processIdDescription: processStep.proces_id_omschrijving,
            visibleIndication: mapFromSuccessIndication(processStep.visible_indication),
            visibleIndicationDescription: processStep.visible_indication_description,
            amount: processStep.amount,
        })),
    };
}
