import { createAction, props } from '@ngrx/store';
import { ErrorInterface } from '../../core';
import {
    GetSpecificationRequestInterface,
    GetSpecificationResponseInterface,
    ResumeSpecificationRequestInterface,
    ResumeSpecificationResponseInterface,
    UpdateSpecificationRequestInterface,
    UpdateSpecificationResponseInterface,
    RemoveSpecificationRequestInterface,
    RemoveSpecificationResponseInterface,
} from '../interfaces';

export const getSpecification = createAction(
    '[claim specification] get specification',
    props<{ request: GetSpecificationRequestInterface }>()
);

export const getSpecificationSuccess = createAction(
    '[claim specification] get specification success',
    props<{ response: GetSpecificationResponseInterface }>()
);

export const getSpecificationFailure = createAction(
    '[claim specification] get specification failure',
    props<{ errors: ErrorInterface[] }>()
);

export const resumeSpecification = createAction(
    '[claim specification] resume specification',
    props<{ request: ResumeSpecificationRequestInterface }>()
);

export const resumeSpecificationSuccess = createAction(
    '[claim specification] resume specification success',
    props<{ response: ResumeSpecificationResponseInterface }>()
);

export const resumeSpecificationFailure = createAction(
    '[claim specification] resume specification failure',
    props<{ errors: ErrorInterface[] }>()
);

export const updateSpecification = createAction(
    '[claim specification] update specification',
    props<{ request: UpdateSpecificationRequestInterface }>()
);

export const updateSpecificationSuccess = createAction(
    '[claim specification] update specification success',
    props<{ response: UpdateSpecificationResponseInterface }>()
);

export const updateSpecificationFailure = createAction(
    '[claim specification] update specification failure',
    props<{ errors: ErrorInterface[] }>()
);

export const removeSpecification = createAction(
    '[claim specification] remove specification',
    props<{ request: RemoveSpecificationRequestInterface }>()
);

export const removeSpecificationSuccess = createAction(
    '[claim specification] remove specification success',
    props<{ response: RemoveSpecificationResponseInterface }>()
);

export const removeSpecificationFailure = createAction(
    '[claim specification] remove specification failure',
    props<{ errors: ErrorInterface[] }>()
);

export const resetClaimSpecification = createAction('[claim specification] reset claim specification');
