import { NotificationInterface, VEHICLE } from '../../../core';
import { Customer, Insurance } from '../../interfaces';
import * as calculationActions from '../calculation/calculation.actions';
import * as coverageActions from '../coverage/coverage.actions';
import * as customerActions from '../customer/customer.actions';
import * as progressActions from '../progress/progress.actions';
import * as vehicleReplaceActions from '../vehicle-replace/vehicle-replace.actions';
import * as insuranceActions from './insurance.actions';

export interface MergedEntities {
    state: State;
    payload: {
        productId: string;
        customer?: Partial<Customer>;
        insurance?: Partial<Insurance>;
    };
}
export interface State {
    entities: MergedEntities;
    ids: string[];
    loading: boolean;
    errors: NotificationInterface[];
    currentProduct: string;
}

const initialState: State = {
    entities: {} as MergedEntities,
    ids: [],
    loading: false,
    errors: [],
    currentProduct: null,
};

const mergeEntities = (
    state: State,
    payload: {
        productId: string;
        customer?: Partial<Customer>;
        insurance?: Partial<Insurance>;
    }
): MergedEntities => {
    const entity = state.entities[payload.productId];

    if (!payload.insurance.non_regular_driver) {
        return {
            ...state.entities,
            [payload.productId]: {
                ...entity,
                ...payload.insurance,
                non_regular_driver: null,
            },
        };
    }

    return {
        ...state.entities,
        [payload.productId]: {
            ...entity,
            ...payload.insurance,
            non_regular_driver: {
                ...(entity ? entity.non_regular_driver : {}),
                ...(payload.insurance ? payload.insurance.non_regular_driver : {}),
            },
        },
    };
};

export function reducer(
    state: State = initialState,
    action:
        | insuranceActions.InsuranceActionUnion
        | calculationActions.CalculationActionUnion
        | customerActions.CustomerActionUnion
        | coverageActions.CoverageActionUnion
        | vehicleReplaceActions.VehicleReplaceActionUnion
        | progressActions.ProgressActionsUnion
): State {
    switch (action.type) {
        case progressActions.INIT: {
            return {
                ...state,
                currentProduct: action.payload.productId,
            };
        }

        case progressActions.INIT_SUCCESS: {
            const payload = action.payload;

            return {
                ...state,
                currentProduct: payload.productId,
                ids: [...new Set([...state.ids, payload.productId])],
                entities: mergeEntities(state, { productId: payload.productId, insurance: payload.insurance }),
            };
        }

        case calculationActions.CALCULATE_CAR_PREMIUM:
        case calculationActions.CALCULATE_BICYCLE_PREMIUM:
        case calculationActions.CALCULATE_HOME_PREMIUM:
        case coverageActions.CHECK_COVERAGE:
        case insuranceActions.SAVE_CAR_INSURANCE_DATA:
        case insuranceActions.SAVE_BICYCLE_INSURANCE_DATA:
        case insuranceActions.SAVE_HOME_INSURANCE_DATA:
        case insuranceActions.VALIDATE_ACCEPTANCE:
        case customerActions.SAVE_PERSONAL_DATA:
        case insuranceActions.INSURE_CAR:
        case insuranceActions.INSURE_EXTRA_CAR:
        case insuranceActions.INSURE_BICYCLE:
        case insuranceActions.INSURE_EXTRA_BICYCLE:
        case insuranceActions.REQUEST_INSURANCE: {
            return {
                ...state,
                loading: true,
                errors: [],
            };
        }

        case calculationActions.CALCULATE_PREMIUM_ERROR: {
            const errors = action.payload.errors;

            if (errors.find((error) => error.message_code === VEHICLE.duplicate)) {
                const insurance = action.payload.data.insurance || {};
                return {
                    ...state,
                    loading: false,
                    errors,
                    entities: mergeEntities(state, {
                        productId: state.currentProduct,
                        insurance: {
                            license_plate: insurance.license_plate,
                            style_trim_id: insurance.style_trim_id,
                            build_year: insurance.build_year,
                            build_month: insurance.build_month,
                        },
                    }),
                };
            }

            return {
                ...state,
                loading: false,
                errors,
            };
        }

        case calculationActions.CALCULATE_PREMIUM_SUCCESS:
        case calculationActions.CALCULATE_SUCCESS:
        case calculationActions.UPDATE_SUCCESS:
        case insuranceActions.VALIDATE_ELIGIBILITY_SUCCESS:
        case insuranceActions.PREFILL_CAR_INSURANCE_DATA: {
            const payload = action.payload;

            if (payload.productId === null) {
                return { ...state };
            }

            return {
                ...state,
                ids: [...new Set([...state.ids, payload.productId])],
                entities: mergeEntities(state, payload),
                loading: false,
                errors: [],
            };
        }

        case insuranceActions.INSURE_SUCCESS:
        case insuranceActions.REQUEST_INSURANCE_SUCCESS: {
            const payload = action.payload;
            const filteredIds: string[] = state.ids.filter((id) => id !== payload.productId);

            return {
                ...state,
                ids: [...filteredIds],
                loading: false,
                errors: [],
            };
        }

        case insuranceActions.VALIDATE_ELIGIBILITY_ERROR:
        case insuranceActions.INSURE_ERROR: {
            return {
                ...state,
                loading: false,
                errors: action.payload.errors,
            };
        }

        case calculationActions.CAMPAIGN_CODE_CHANGED_SUCCESS:
        case calculationActions.PREMIUM_UPDATED_SUCCESS: {
            const payload = action.payload;

            return {
                ...state,
                ids: [...new Set([...state.ids, payload.productId])],
                entities: mergeEntities(state, payload),
                loading: false,
                errors: [],
            };
        }

        case vehicleReplaceActions.SET_USER_CHOICE:
        case vehicleReplaceActions.CHOOSE_REPLACE_YES: {
            const payload = action.payload;

            if (!payload.replacement) {
                return state;
            }

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [state.currentProduct]: {
                        ...state.entities[state.currentProduct],
                        related_vehicle_purchased_product_sequence_number:
                            payload.replacement.purchased_product_sequence_number,
                    },
                },
            };
        }

        case vehicleReplaceActions.CHOOSE_REPLACE_NO:
        case vehicleReplaceActions.RESET_VEHICLE_REPLACEMENT: {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [state.currentProduct]: {
                        ...state.entities[state.currentProduct],
                        related_vehicle_purchased_product_sequence_number: null,
                        non_regular_driver_transfer_indication: null,
                    },
                },
            };
        }

        case insuranceActions.STORE_INSURANCE_DATA: {
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.payload.productId]: {
                        ...state.entities[action.payload.productId],
                        ...action.payload.insurance,
                    },
                },
                currentProduct: action.payload.productId,
            };
        }

        case insuranceActions.SET_INSURANCE_CAMPAIGN_CODE: {
            const payload = action.payload;
            const currentEntity = state.entities[payload.productId];
            const campaignCode = payload.campaignCode;

            const entity = {
                ...currentEntity,
                campaign_code: campaignCode,
            };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [payload.productId]: entity,
                },
            };
        }

        case insuranceActions.CLEAR_INSURANCE_CAMPAIGN_CODE: {
            const payload = action.payload;
            const currentEntity = state.entities[payload.productId];
            const entity = {
                ...currentEntity,
                campaign_code: null,
            };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [payload.productId]: entity,
                },
            };
        }

        case insuranceActions.TOGGLE_PAYMENT_PERIOD: {
            const payload = action.payload;
            const currentEntity = state.entities[payload.productId];
            const entity = {
                ...currentEntity,
                premium_period_amount: payload.paymentPeriod,
            };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    [payload.productId]: entity,
                },
            };
        }

        case insuranceActions.CLEAR_INSURANCE_DATA: {
            return {
                entities: {} as MergedEntities,
                ids: [],
                loading: false,
                errors: [],
                currentProduct: null,
            };
        }

        case insuranceActions.RESET_ERRORS: {
            return {
                ...state,
                errors: [],
            };
        }

        default: {
            return state;
        }
    }
}

export const getEntities = (state: State): State['entities'] => state.entities;
export const getIds = (state: State): State['ids'] => state.ids;
export const isLoading = (state: State): State['loading'] => state.loading;
export const getErrors = (state: State): State['errors'] => state.errors;
