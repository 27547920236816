import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';

@Injectable({ providedIn: 'root' })
export class ClaimNotificationEventService {
    private _getClaimNotification$: Subject<void>;
    private _saveClaimNotification$: Subject<void>;

    // Get Claim Notification
    public getClaimNotification$(): Observable<void> {
        this._getClaimNotification$ = new Subject();
        return this._getClaimNotification$.asObservable();
    }

    public onClaimNotificationSuccess(): void {
        this._getClaimNotification$.next();
        this._getClaimNotification$.complete();
    }

    public onClaimNotificationError(errors: ErrorInterface[]): void {
        this._getClaimNotification$.error(errors);
    }

    // Save claim notification
    public saveClaimNotification$(): Observable<void> {
        this._saveClaimNotification$ = new Subject();
        return this._saveClaimNotification$.asObservable();
    }

    public onSaveClaimNotificationSuccess(): void {
        this._saveClaimNotification$.next();
        this._saveClaimNotification$.complete();
    }

    public onSaveClaimNotificationError(errors: ErrorInterface[]): void {
        this._saveClaimNotification$.error(errors);
    }
}
