import { Action } from '@ngrx/store';
import { NotificationInterface, type } from '../../../core';
import { Customer, Insurance, Premium } from '../../interfaces';

export const CALCULATE_CAR_PREMIUM = type('[Car Salesfunnel] Calculate premium');
export const CALCULATE_HOME_PREMIUM = type('[Home Salesfunnel] Calculate premium');
export const CALCULATE_BICYCLE_PREMIUM = type('[Bicycle Salesfunnel] Calculate premium');
export const CALCULATE_PREMIUM_SUCCESS = type('[Calculation API] Calculate premium success');
export const CALCULATE_PREMIUM_ERROR = type('[Calculation API] Calculate premium error');
export const RESET_CALCULATION = type('[Calculation API] Reset calculation');

export const CALCULATE = type('[Salesfunnel] Calculate');
export const CALCULATE_SUCCESS = type('[Calculation API] Calculate success');
export const CALCULATE_ERROR = type('[Calculation API] Calculate error');
export const RESET_CALCULATE_ERRORS = type('[Calculation API] Reset Calculate errors');
export const CALCULATE_CAMPAIGNCODE_ERROR = type('[Calculation API] Calculate Campaign Code error');

export const CAMPAIGN_CODE_CHANGED = type('[Salesfunnel] Promotional Code Changed');
export const CAMPAIGN_CODE_CHANGED_SUCCESS = type('[Calculation API] Promotional Code Changed Success');
export const CAMPAIGN_CODE_CHANGED_ERROR = type('[Calculation API] Promotional Code Changed Error');

export const PREMIUM_FACTOR_UPDATE = type('[Salesfunnel] Premium Factor Updated');

export const PREMIUM_UPDATED_SUCCESS = type('[Calculation API] Premium Updated Success');
export const PREMIUM_UPDATED_ERROR = type('[Calculation API] Premium Updated Error');

export const UPDATE = type('[Salesfunnel] Update');
export const UPDATE_SUCCESS = type('[Calculation API] Update Success');
export const UPDATE_ERROR = type('[Calculation API] Update Error');
export const UPDATE_CAMPAIGNCODE_ERROR = type('[Calculation API] Update Campaign Code Error');

export const EMAIL_PREMIUM_CALCULATION = type('[Calculation API] Email Premium Calculation');
export const EMAIL_PREMIUM_CALCULATION_SUCCESS = type('[Calculation API] Email Premium Calculation Success');
export const EMAIL_PREMIUM_CALCULATION_ERROR = type('[Calculation API] Email Premium Calculation Error');

// Todo: Need to use the new Calculation Email function and delete these actions below
export const EMAIL_CAR_PREMIUM = type('[Car Salesfunnel] E-mail premium');
export const EMAIL_HOME_PREMIUM = type('[Home Salesfunnel] E-mail premium');
export const EMAIL_BICYCLE_PREMIUM = type('[Bicycle Salesfunnel] E-mail premium');
export const EMAIL_SENT = type('[E-mail API] E-mail sent');
export const EMAIL_FAILED = type('[E-mail API] E-mail failed');

interface CalculationData {
    productId: string;
    premium: Premium;
    insurance: Partial<Insurance>;
    customer: Partial<Customer>;
    authenticated?: boolean;
}

export class CalculatePremium implements Action {
    public readonly type: string; // TODO : initialize value (invalid action dispatched)

    constructor(public payload: { insurance?: Partial<Insurance>; customer?: Partial<Customer> }) {}
}

export class CalculateCarPremium extends CalculatePremium {
    public readonly type = CALCULATE_CAR_PREMIUM;
}

export class CalculateHomePremium extends CalculatePremium {
    public readonly type = CALCULATE_HOME_PREMIUM;
}

export class CalculateBicyclePremium extends CalculatePremium {
    public readonly type = CALCULATE_BICYCLE_PREMIUM;
}

export class CalculatePremiumSuccess implements Action {
    public readonly type = CALCULATE_PREMIUM_SUCCESS;

    constructor(public payload: CalculationData) {}
}

export class CalculatePremiumError implements Action {
    public readonly type = CALCULATE_PREMIUM_ERROR;

    constructor(public payload: { data: CalculationData; errors: NotificationInterface[] }) {}
}

export class Calculate implements Action {
    public readonly type = CALCULATE;

    constructor(public payload: { insurance?: Partial<Insurance>; customer?: Partial<Customer> }) {}
}

export class CalculateSuccess implements Action {
    public readonly type = CALCULATE_SUCCESS;

    constructor(public payload: CalculationData) {}
}

export class CalculateError implements Action {
    public readonly type = CALCULATE_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class ResetCalculateErrors implements Action {
    public readonly type = RESET_CALCULATE_ERRORS;
}

export class CalculateCampaignCodeError implements Action {
    public readonly type = CALCULATE_CAMPAIGNCODE_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class ResetCalculation implements Action {
    public readonly type = RESET_CALCULATION;
}

export class CampaignCodeChanged implements Action {
    public readonly type = CAMPAIGN_CODE_CHANGED;

    constructor(public payload: { campaignCode: string }) {}
}

export class CampaignCodeChangedSuccess implements Action {
    public readonly type = CAMPAIGN_CODE_CHANGED_SUCCESS;

    constructor(public payload: CalculationData) {}
}

export class CampaignCodeChangedError implements Action {
    public readonly type = CAMPAIGN_CODE_CHANGED_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class PremiumFactorUpdated implements Action {
    public readonly type = PREMIUM_FACTOR_UPDATE;

    constructor(
        public payload: { insurance?: Partial<Insurance>; customer?: Partial<Customer>; coverage?: string[] }
    ) {}
}

export class PremiumUpdatedSuccess implements Action {
    public readonly type = PREMIUM_UPDATED_SUCCESS;

    constructor(public payload: CalculationData) {}
}

export class PremiumUpdatedError implements Action {
    public readonly type = PREMIUM_UPDATED_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class Update implements Action {
    public readonly type = UPDATE;

    constructor(public payload: { insurance?: Partial<Insurance>; customer?: Partial<Customer> }) {}
}

export class UpdateSuccess implements Action {
    public readonly type = UPDATE_SUCCESS;

    constructor(public payload: CalculationData) {}
}

export class UpdateError implements Action {
    public readonly type = UPDATE_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class UpdateCampaignCodeError implements Action {
    public readonly type = UPDATE_CAMPAIGNCODE_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class EmailPremiumCalculation implements Action {
    public readonly type = EMAIL_PREMIUM_CALCULATION;

    constructor(public payload: { url: string; email: string; payload: any }) {}
}

export class EmailPremiumCalculationSuccess implements Action {
    public readonly type = EMAIL_PREMIUM_CALCULATION_SUCCESS;
}

export class EmailPremiumCalculationError implements Action {
    public readonly type = EMAIL_PREMIUM_CALCULATION_ERROR;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export class EmailPremium implements Action {
    public readonly type: string; // TODO : initialize value (invalid action dispatched)

    constructor(public payload: { emailAddress: string }) {}
}

export class EmailCarPremium extends EmailPremium {
    public readonly type = EMAIL_CAR_PREMIUM;
}

export class EmailHomePremium extends EmailPremium {
    public readonly type = EMAIL_HOME_PREMIUM;
}

export class EmailBicyclePremium extends EmailPremium {
    public readonly type = EMAIL_BICYCLE_PREMIUM;
}

export class EmailSent implements Action {
    public readonly type = EMAIL_SENT;
}

export class EmailFailed implements Action {
    public readonly type = EMAIL_FAILED;

    constructor(public payload: { errors: NotificationInterface[] }) {}
}

export type CalculationActionUnion =
    | CalculateCarPremium
    | CalculateHomePremium
    | CalculateBicyclePremium
    | CalculatePremiumSuccess
    | CalculatePremiumError
    | Calculate
    | CalculateSuccess
    | CalculateError
    | ResetCalculateErrors
    | CalculateCampaignCodeError
    | ResetCalculation
    | CampaignCodeChanged
    | CampaignCodeChangedSuccess
    | CampaignCodeChangedError
    | PremiumFactorUpdated
    | PremiumUpdatedSuccess
    | PremiumUpdatedError
    | Update
    | UpdateSuccess
    | UpdateError
    | UpdateCampaignCodeError
    | EmailCarPremium
    | EmailHomePremium
    | EmailBicyclePremium
    | EmailSent
    | EmailFailed
    | EmailPremiumCalculation
    | EmailPremiumCalculationSuccess
    | EmailPremiumCalculationError;
