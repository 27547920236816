import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    GetClaimObjectDetailsRequestInterface,
    GetClaimObjectsRequestInterface,
    ReloadClaimObjectDetailsRequestInterface,
    RemoveClaimObjectRequestInterface,
    SaveClaimObjectRequestInterface,
    UpdateClaimObjectDetailsRequestInterface,
} from '../interfaces';
import * as ClaimObjectActions from '../store/claim-objects.actions';
import { ClaimObjectsState } from '../store';
import { ClaimObjectsEventService } from './claim-objects.event.service';

@Injectable({ providedIn: 'root' })
export class ClaimObjectsActionService {
    constructor(private store$: Store<ClaimObjectsState>, private claimObjectsEventService: ClaimObjectsEventService) {}

    public getClaimObjects$(request: GetClaimObjectsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimObjectActions.getClaimObjects({ request }));
        return this.claimObjectsEventService.getClaimObjects$();
    }

    public getClaimObjectDetails$(request: GetClaimObjectDetailsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimObjectActions.getClaimObjectDetails({ request }));
        return this.claimObjectsEventService.getClaimObjectDetails$();
    }

    public updateClaimObjectDetails$(request: UpdateClaimObjectDetailsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimObjectActions.updateClaimObjectDetails({ request }));
        return this.claimObjectsEventService.updateClaimObjectDetails$();
    }

    public reloadClaimObjectDetails$(request: ReloadClaimObjectDetailsRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimObjectActions.reloadClaimObjectDetails({ request }));
        return this.claimObjectsEventService.reloadClaimObjectDetails$();
    }

    public saveClaimObject$(request: SaveClaimObjectRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimObjectActions.saveClaimObject({ request }));
        return this.claimObjectsEventService.saveClaimObject$();
    }

    public removeClaimObject$(request: RemoveClaimObjectRequestInterface): Observable<void> {
        this.store$.dispatch(ClaimObjectActions.removeClaimObject({ request }));
        return this.claimObjectsEventService.removeClaimObject$();
    }

    public resetClaimObjects(): void {
        this.store$.dispatch(ClaimObjectActions.resetClaimObjects());
    }
}
