import { Action } from '@ngrx/store';
import { ErrorInterface, ResponseInterface } from '../../core';
import {
    CredentialsInterface,
    LoginRequestInterface,
    SessionInterface,
    SessionResponseInterface,
    LoginTokenRequestInterface,
} from '../interfaces';

export enum AuthenticationActionEnum {
    // Triggers
    Initialize = '[Authentication] Initialize',
    SessionExpired = '[Authentication] Session Expired',

    // Session Logic
    LoadSession = '[Authentication] Load Session',
    LoadSessionError = '[Authentication] Load Session failed',
    LoadSessionWithToken = '[Authentication] Load Session with Token',

    // Calling API
    RequestSession = '[Authentication] Request Session',
    ExtendSession = '[Authentication] Extend Session',
    ExtendSessionWithToken = '[Authentication] Extend Session With Token',

    // Session Success
    SessionExtended = '[Authentication] Session Extended',
    AuthenticatedSession = '[Authentication] Authenticated Session',
    TemporaryPasswordSession = '[Authentication] Temporary Password Session',
    GeneratedAccountSession = '[Authentication] Generated Account Session',
    ChangedEmailSession = '[Authentication] Changed Email Session',

    // Session End
    SessionAvailable = '[Authentication] Session Available',
    SessionUnavailable = '[Authentication] Session Unavailable',
    SessionTerminated = '[Authentication] Session Terminated',
    TemporarySessionAvailable = '[Authentication] Temporary Session Available',

    // User Actions
    Login = '[Authentication] Login',
    LoginSuccess = '[Authentication] Login Success',
    LoginError = '[Authentication] Login Error',

    LoginToken = '[Authentication] Login Token',
    LoginTokenSuccess = '[Authentication] Login Token Success',
    LoginTokenError = '[Authentication] Login Token Error',

    TemporaryPasswordLoginSuccess = '[Authentication] Temporary Password Login Success',
    GeneratedAccountLoginSuccess = '[Authentication] Generated Account Login Success',
    ChangedEmailLoginSuccess = '[Authentication] Changed Email Login Success',

    Logout = '[Authentication] Logout',
    LogoutSuccess = '[Authentication] Logout success',
    LogoutError = '[Authentication] Logout error',

    ValidateCredentials = '[Authentication] Validate Credentials',
    ValidateCredentialsSuccess = '[Authentication] Validate Credentials Success',
    ValidateCredentialsError = '[Authentication] Validate Credentials Error',

    ValidateEmail = '[Authentication] Validate Email',
    ValidateEmailSuccess = '[Authentication] Validate Email Success',
    ValidateEmailError = '[Authentication] Validate Email Error',
}

/** Initial Triggers */
export class InitializeAction implements Action {
    public readonly type = AuthenticationActionEnum.Initialize;
}

export class SessionExpiredAction implements Action {
    public readonly type = AuthenticationActionEnum.SessionExpired;
}

/** Session Logic */
export class LoadSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.LoadSession;

    constructor(public payload: { session: SessionInterface }) {}
}

export class LoadSessionErrorAction implements Action {
    public readonly type = AuthenticationActionEnum.LoadSessionError;
}

export class LoadSessionWithTokenAction implements Action {
    public readonly type = AuthenticationActionEnum.LoadSessionWithToken;

    constructor(public payload: { session: SessionInterface }) {}
}

/** Session API */
export class RequestSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.RequestSession;
}

export class ExtendSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.ExtendSession;

    constructor(public payload: { session: SessionInterface }) {}
}

export class ExtendSessionWithTokenAction implements Action {
    public readonly type = AuthenticationActionEnum.ExtendSessionWithToken;

    constructor(public payload: { token: string }) {}
}

/** Session Success */
export class SessionExtendedAction implements Action {
    public readonly type = AuthenticationActionEnum.SessionExtended;

    constructor(public payload: { response: ResponseInterface }) {}
}

export class AuthenticatedSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.AuthenticatedSession;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

export class TemporaryPasswordSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.TemporaryPasswordSession;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

export class GeneratedAccountSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.GeneratedAccountSession;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

export class ChangedEmailSessionAction implements Action {
    public readonly type = AuthenticationActionEnum.ChangedEmailSession;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

/** Session End */
export class SessionAvailableAction implements Action {
    public readonly type = AuthenticationActionEnum.SessionAvailable;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

export class SessionTerminatedAction implements Action {
    public readonly type = AuthenticationActionEnum.SessionTerminated;
}

export class SessionUnavailableAction implements Action {
    public readonly type = AuthenticationActionEnum.SessionUnavailable;
}

export class TemporarySessionAvailableAction implements Action {
    public readonly type = AuthenticationActionEnum.TemporarySessionAvailable;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

/** Login */
export class LoginAction implements Action {
    public readonly type = AuthenticationActionEnum.Login;

    constructor(public payload: { request: LoginRequestInterface }) {}
}

export class LoginSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.LoginSuccess;

    constructor(public payload: { session: SessionResponseInterface }) {}
}

/** Login Token */
export class LoginTokenAction implements Action {
    public readonly type = AuthenticationActionEnum.LoginToken;

    constructor(public payload: { request: LoginTokenRequestInterface }) {}
}

export class LoginTokenSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.LoginTokenSuccess;
}

export class LoginTokenErrorAction implements Action {
    public readonly type = AuthenticationActionEnum.LoginTokenError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export class TemporaryPasswordLoginSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.TemporaryPasswordLoginSuccess;

    constructor(public payload: { response: ResponseInterface }) {}
}

export class GeneratedAccountLoginSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.GeneratedAccountLoginSuccess;

    constructor(public payload: { response: ResponseInterface }) {}
}

export class ChangedEmailLoginSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.ChangedEmailLoginSuccess;

    constructor(public payload: { response: ResponseInterface }) {}
}

export class LoginErrorAction implements Action {
    public readonly type = AuthenticationActionEnum.LoginError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Logout */
export class LogoutAction implements Action {
    public readonly type = AuthenticationActionEnum.Logout;
}

export class LogoutSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.LogoutSuccess;
}

export class LogoutErrorAction implements Action {
    public readonly type = AuthenticationActionEnum.LogoutError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Validate Credentials */
export class ValidateCredentialsAction implements Action {
    public readonly type = AuthenticationActionEnum.ValidateCredentials;

    constructor(public payload: { request: CredentialsInterface }) {}
}

export class ValidateCredentialsSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.ValidateCredentialsSuccess;
}

export class ValidateCredentialsErrorAction implements Action {
    public readonly type = AuthenticationActionEnum.ValidateCredentialsError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

/** Validate Email */
export class ValidateEmailAction implements Action {
    public readonly type = AuthenticationActionEnum.ValidateEmail;

    constructor(public payload: { code: string }) {}
}

export class ValidateEmailSuccessAction implements Action {
    public readonly type = AuthenticationActionEnum.ValidateEmailSuccess;
}

export class ValidateEmailErrorAction implements Action {
    public readonly type = AuthenticationActionEnum.ValidateEmailError;

    constructor(public payload: { errors: ErrorInterface[] }) {}
}

export type AuthenticationActionType =
    | AuthenticatedSessionAction
    | ExtendSessionAction
    | ExtendSessionWithTokenAction
    | GeneratedAccountLoginSuccessAction
    | GeneratedAccountSessionAction
    | ChangedEmailLoginSuccessAction
    | ChangedEmailSessionAction
    | InitializeAction
    | LoadSessionAction
    | LoadSessionErrorAction
    | LoadSessionWithTokenAction
    | LoginAction
    | LoginErrorAction
    | LoginSuccessAction
    | LoginTokenAction
    | LoginTokenErrorAction
    | LoginTokenSuccessAction
    | LogoutAction
    | LogoutErrorAction
    | LogoutSuccessAction
    | RequestSessionAction
    | SessionAvailableAction
    | SessionExpiredAction
    | SessionExtendedAction
    | SessionTerminatedAction
    | SessionUnavailableAction
    | TemporaryPasswordLoginSuccessAction
    | TemporaryPasswordSessionAction
    | TemporarySessionAvailableAction
    | ValidateEmailAction
    | ValidateEmailSuccessAction
    | ValidateEmailErrorAction;
