import { GetInsuranceInfoCheckResponseInterface } from '../interfaces';

export interface InsuranceInfoCheckState {
    insuranceInfoCheck: GetInsuranceInfoCheckResponseInterface;
}

export const INSURANCE_INFO_CHECK_INITIAL_STATE: InsuranceInfoCheckState = {
    insuranceInfoCheck: null,
};

export const INSURANCE_INFO_CHECK_FEATURE = 'insuranceInfoCheck';
