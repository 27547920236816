import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ApiRelationCodeEnum, NotificationInterface, RequestResponse, RequestService } from '../../../core';
import {
    Coverage,
    Customer,
    Insurance,
    MapperInputInterface,
    PayloadInterface,
    Premium,
    PremiumOffer,
    PremiumPurchasedProduct,
    StackDiscountDescription,
} from '../../interfaces';
import { SalesStorageService } from '../../services/sales-storage.service';
import { CoverageService } from '../coverage/coverage.service';
import { CustomerService } from '../customer/customer.service';
import { InsuranceService } from '../insurance/insurance.service';
import { ProgressService } from '../progress/progress.service';
import * as fromSales from '../sales.reducers';
import { CalculationPayload } from './calculation-payload.helper';
import {
    Calculate,
    CalculateCampaignCodeError,
    CalculateError,
    CalculateSuccess,
    Update,
    UpdateCampaignCodeError,
    UpdateError,
    UpdateSuccess,
} from './calculation.actions';
import * as fromCalculation from './calculation.selectors';
import { ClearInsuranceCampaignCode } from '../insurance/insurance.actions';

interface CalculationInput {
    productId: string;
    insurance: Insurance;
    customer: Customer;
    coverage?: string[];
}

interface EmailInput extends CalculationInput {
    coverages: Coverage[];
    emailAddress: string;
}

interface RawDataInterface {
    insurance: Insurance;
    customer: Customer;
    coverages: { coverage_id: string }[];
    authenticated?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CalculationService {
    public campaignCode$: Observable<string> = this.store$.select(fromCalculation.getCampaignCode);

    public calculation$: Observable<Premium> = this.store$.select(fromCalculation.getCurrent);

    public loading$: Observable<boolean> = this.store$.select(fromCalculation.isLoading);

    public errors$: Observable<NotificationInterface[]> = this.store$.select(fromCalculation.getErrors);

    public notifications$: Observable<NotificationInterface[]> = this.store$.select(fromCalculation.getNotifications);

    public hasCalculation$: Observable<boolean> = this.calculation$.pipe(map((calculation) => !!calculation));

    public offer$: Observable<PremiumOffer> = this.calculation$.pipe(
        filter((calculation) => !!calculation),
        map((calculation) => calculation.product.offer)
    );

    public collectivityDescription$: Observable<string> = this.calculation$.pipe(
        filter((calculation) => !!calculation),
        map((calculation) => calculation.product.collectivity_description)
    );

    public stackDiscountDescription$: Observable<StackDiscountDescription> = this.calculation$.pipe(
        filter((calculation) => !!calculation),
        map((calculation) => calculation.product.stack_discount_description)
    );

    constructor(
        private store$: Store<fromSales.State>,
        private requestService: RequestService,
        private insuranceService: InsuranceService,
        private customerService: CustomerService,
        private progressService: ProgressService,
        private storageService: SalesStorageService,
        private coverageService: CoverageService
    ) {}

    public calculate$({
        url,
        data,
        mapper,
        useCoverage,
    }: PayloadInterface<MapperInputInterface>): Observable<RequestResponse> {
        this.store$.dispatch(new Calculate(data));

        return forkJoin(
            this.insuranceService.currentInsuranceData$.pipe(take(1)),
            this.customerService.currentCustomerData$.pipe(take(1)),
            this.coverageService.coverages$.pipe(take(1)),
            this.progressService.productId$.pipe(take(1))
        ).pipe(
            switchMap(([insurance, customer, modules, productId]) => {
                const newData: RawDataInterface = {
                    insurance: { ...insurance, ...data.insurance },
                    customer: { ...customer, ...data.customer },
                    coverages: [],
                    authenticated: data.authenticated || false,
                };

                if (useCoverage && modules) {
                    newData.coverages = modules;
                }

                const payload = mapper(newData);

                return this.requestService.post(url, payload).pipe(
                    take(1),
                    map((response) => response.data),
                    tap((premium) => this.store$.dispatch(new CalculateSuccess({ premium, ...newData, productId }))),
                    tap(() => this.storageService.save(productId, newData.insurance, newData.customer)),
                    catchError((errors: NotificationInterface[]) => this.handleCalculateErrors(errors, productId))
                );
            })
        );
    }

    public update$({ url, data, mapper }: PayloadInterface<MapperInputInterface>): Observable<RequestResponse> {
        this.store$.dispatch(new Update(data));

        return forkJoin(
            this.insuranceService.currentInsuranceData$.pipe(take(1)),
            this.customerService.currentCustomerData$.pipe(take(1)),
            this.coverageService.coverages$.pipe(take(1)),
            this.progressService.productId$.pipe(take(1))
        ).pipe(
            switchMap(([insurance, customer, modules, productId]) => {
                const newData: RawDataInterface = {
                    insurance: { ...insurance, ...data.insurance },
                    customer: { ...customer, ...data.customer },
                    coverages: data.coverage ? [...data.coverage] : [...modules] || [],
                    authenticated: data.authenticated || false,
                };

                const payload = mapper(newData);

                return this.requestService.post(url, payload).pipe(
                    take(1),
                    map((response) => response.data),
                    tap((premium) => this.store$.dispatch(new UpdateSuccess({ premium, ...newData, productId }))),
                    tap(() => this.storageService.save(productId, newData.insurance, newData.customer)),
                    catchError((errors: NotificationInterface[]) => this.handleUpdateErrors(errors, productId))
                );
            })
        );
    }

    public effectCalculate$({ productId, insurance, customer, coverage = [] }: CalculationInput): Observable<Premium> {
        const services = {
            AUTO: () => this.calculatePremiumAuto$(insurance, customer),
            FIET: () => this.calculatePremiumBicycle$(insurance, customer),
            WOON: () => this.calculatePremiumHome$(insurance, customer, coverage),
            BROM: () => this.calculatePremiumMoped$(insurance, customer, coverage),
            CVAN: () => this.calculatePremiumCaravan$(insurance, customer, coverage),
        };

        if (!services.hasOwnProperty(productId)) {
            return of();
        }

        return (services[productId]() as Observable<RequestResponse>).pipe(map((response) => response.data));
    }

    public email$({ coverages, productId, insurance, customer, emailAddress }: EmailInput): Observable<unknown> {
        const services = {
            AUTO: () => this.emailPremiumAuto$(coverages, insurance, customer, emailAddress),
            FIET: () => this.emailPremiumBicycle$(coverages, insurance, customer, emailAddress),
            WOON: () => this.emailPremiumHome$(coverages, insurance, customer, emailAddress),
        };

        if (!services.hasOwnProperty(productId)) {
            return of(false);
        }

        return (services[productId]() as Observable<RequestResponse>).pipe(map((response) => response.data));
    }

    public emailPremiumCalculation$(url: string, email: string, payload: any): Observable<RequestResponse> {
        const requestPayload = {
            email_address: email,
            ...payload,
        };
        return this.requestService.post(url, requestPayload);
    }

    // car / auto
    public calculatePremiumAuto$(insurance: Insurance, customer: Customer): Observable<RequestResponse> {
        const { url, payload } = CalculationPayload.calculatePremiumAuto(insurance, customer);
        return this.requestService.post(url, payload);
    }

    public emailPremiumAuto$(
        coverages: Coverage[],
        insurance: Insurance,
        customer: Customer,
        emailAddress: string
    ): Observable<RequestResponse> {
        const { url, payload } = CalculationPayload.emailPremiumAuto(coverages, insurance, customer, emailAddress);
        return this.requestService.post(url, payload);
    }

    // fiets / bicycle
    public calculatePremiumBicycle$(insurance: Insurance, customer: Customer): Observable<RequestResponse> {
        const { url, payload } = CalculationPayload.calculatePremiumBicycle(insurance, customer);
        return this.requestService.post(url, payload);
    }

    public emailPremiumBicycle$(
        coverages: Coverage[],
        insurance: Insurance,
        customer: Customer,
        emailAddress: string
    ): Observable<RequestResponse> {
        const { url, payload } = CalculationPayload.emailPremiumBicycle(coverages, insurance, customer, emailAddress);
        return this.requestService.post(url, payload);
    }

    // woonhuis / home
    public calculatePremiumHome$(
        insurance: Insurance,
        customer: Customer,
        coverage?: string[]
    ): Observable<RequestResponse> {
        const payload = {
            relation: {
                general: {
                    birthdate: customer.birthdate,
                },
                residential_address: {
                    postal_code: customer.postal_code,
                },
            },
            non_regular_driver_transfer_indication: 'N',
            premium_factor: {
                tenant_owner_code: insurance.tenant_owner_code,
                security_type_code: insurance.security_type_code,
                family_composition_code: customer.family_composition_code,
                choice_deductible_excess_amount: insurance.choice_deductible_excess_amount,
                straw_roofing_indication: insurance.straw_roofing_indication,
                campaign_code: insurance.campaign_code,
                partner_id: insurance.partner_id,
                partner_component_code: insurance.partner_component_code,
                property_type: insurance.property_type,
                external_reference_id: insurance.external_reference_id,
            },
            object: {
                postal_code: insurance.postal_code,
                civic_number: insurance.civic_number,
                civic_number_suffix: insurance.civic_number_suffix,
            },
            coverages: coverage ? coverage.map((coverageId) => ({ coverage_id: coverageId })) : undefined,
        };

        return this.requestService.post('en-gb/insurance-coverage/calculate/coverage-premium-home', payload);
    }

    public calculatePremiumMoped$(
        insurance: Insurance,
        customer: Customer,
        coverage?: string[]
    ): Observable<RequestResponse> {
        const payload = {
            relation: {
                general: {
                    birthdate: customer.birthdate,
                },
                residential_address: {
                    postal_code: customer.postal_code,
                },
            },
            non_regular_driver:
                insurance.relation_code === ApiRelationCodeEnum.Owner
                    ? undefined
                    : {
                          birthdate: insurance?.non_regular_driver?.birthdate,
                          relation_code: insurance?.relation_code,
                          postal_code: insurance?.non_regular_driver?.postal_code,
                      },
            premium_factor: {
                campaign_code: insurance?.campaign_code,
                no_claims_years_amount: insurance.no_claims_years_amount,
                family_composition_code: customer.family_composition_code,
                partner_id: insurance.partner_id,
                partner_component_code: insurance.partner_component_code,
                external_reference_id: insurance.external_reference_id || null,
            },
            object: {
                license_plate: insurance.license_plate,
                style_trim_id: insurance.style_trim_id,
            },
            coverages: { ...coverage },
        };

        return this.requestService.post(
            'en-gb/insurance-coverage/calculate/coverage-premium-limited_speed_motorcycle',
            payload
        );
    }

    public calculatePremiumCaravan$(
        insurance: Insurance,
        customer: Customer,
        coverage?: string[]
    ): Observable<RequestResponse> {
        if (!insurance.campaign_code) {
            return;
        }
        const payload = {
            relation: {
                general: {
                    birthdate: customer.birthdate,
                },
                residential_address: {
                    postal_code: customer.postal_code,
                },
            },
            premium_factor: {
                catalog_value_amount: insurance.catalog_value_amount,
                caravan_code: insurance.caravan_code,
                hail_roof_indication: insurance.hail_roof_indication,
                family_composition_code: customer.family_composition_code,
                campaign_code: insurance.campaign_code,
                partner_id: insurance.partner_id,
                partner_component_code: insurance.partner_component_code,
                external_reference_id: insurance.external_reference_id || null,
            },
            object: {
                license_plate: insurance.license_plate,
            },
            coverages: { ...coverage },
        };

        return this.requestService.post('en-gb/insurance-coverage/calculate/coverage-premium-caravan', payload);
    }

    public emailPremiumHome$(
        coverages: Coverage[],
        insurance: Insurance,
        customer: Customer,
        emailAddress: string
    ): Observable<RequestResponse> {
        const { url, payload } = CalculationPayload.emailPremiumHome(coverages, insurance, customer, emailAddress);
        return this.requestService.post(url, payload);
    }

    // reeds afgesloten verzekering
    public calculatePremiumPurchasedProduct$(
        purchasedProductSequenceNumber: number
    ): Observable<PremiumPurchasedProduct> {
        const url = 'en-gb/insurance-coverage/calculate/coverage-premium-purchased_product';
        const payload = {
            purchased_product: {
                purchased_product_sequence_number: purchasedProductSequenceNumber,
            },
        };
        return this.requestService.post(url, payload).pipe(map((response) => response.data));
    }

    private handleCalculateErrors(errors: NotificationInterface[], productId: string): Observable<RequestResponse> {
        if (this.hasCampaignCodeErrors(errors)) {
            this.store$.dispatch(new CalculateCampaignCodeError({ errors: this.checkErrors(errors) }));
            this.store$.dispatch(new ClearInsuranceCampaignCode({ productId }));
        } else {
            this.store$.dispatch(new CalculateError({ errors: this.checkErrors(errors) }));
        }
        return EMPTY;
    }

    private handleUpdateErrors(errors: NotificationInterface[], productId: string): Observable<RequestResponse> {
        if (this.hasCampaignCodeErrors(errors)) {
            this.store$.dispatch(new UpdateCampaignCodeError({ errors: this.checkErrors(errors) }));
            this.store$.dispatch(new ClearInsuranceCampaignCode({ productId }));
        } else {
            this.store$.dispatch(new UpdateError({ errors: this.checkErrors(errors) }));
        }
        return EMPTY;
    }

    private hasCampaignCodeErrors = (errors: NotificationInterface[]): boolean =>
        errors.some((e) => e.message_code === '11012-1' || e.message_code === '11012-5');

    private checkErrors = (errors: NotificationInterface[]): NotificationInterface[] => {
        if (Array.isArray(errors) && errors.length > 0) {
            return errors;
        }
        return this.requestService.createError('1234');
    };
}
