import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorInterface } from '../../core';
import { DownloadTerminationLetterResponseInterface, GetInsuranceCompaniesResponseInterface } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ContactEventService {
    private _createEmail$?: Subject<void>;
    private _loadCategories$?: Subject<void>;
    private _sendEmail$?: Subject<void>;
    private _sendComplaint$?: Subject<void>;
    private _addAttachment$?: Subject<void>;
    private _getInsuranceCompanies$?: Subject<GetInsuranceCompaniesResponseInterface[]>;
    private _downloadTerminationLetter$?: Subject<DownloadTerminationLetterResponseInterface>;
    private _emailTerminationLetter$?: Subject<void>;

    public getCreateEmail$(): Observable<void> {
        this._createEmail$ = new Subject();

        return this._createEmail$.asObservable();
    }

    public onCreateEmailSuccess(): void {
        this._createEmail$.next();
        this._createEmail$.complete();
    }

    public onCreateEmailError(errors: ErrorInterface[]): void {
        this._createEmail$.error(errors);
    }

    public loadCategories$(): Observable<void> {
        this._loadCategories$ = new Subject();

        return this._loadCategories$.asObservable();
    }

    public onLoadCategoriesSuccess(): void {
        this._loadCategories$.next();
        this._loadCategories$.complete();
    }

    public onLoadCategoriesError(errors: ErrorInterface[]): void {
        this._loadCategories$.error(errors);
    }

    public getSendEmail$(): Observable<void> {
        this._sendEmail$ = new Subject();

        return this._sendEmail$.asObservable();
    }

    public onSendEmailSuccess(): void {
        this._sendEmail$.next();
        this._sendEmail$.complete();
    }

    public onSendEmailError(errors: ErrorInterface[]): void {
        this._sendEmail$.error(errors);
    }

    public getSendComplaint$(): Observable<void> {
        this._sendComplaint$ = new Subject();

        return this._sendComplaint$.asObservable();
    }

    public onSendComplaintSuccess(): void {
        this._sendComplaint$.next();
        this._sendComplaint$.complete();
    }

    public onSendComplaintError(errors: ErrorInterface[]): void {
        this._sendComplaint$.error(errors);
    }

    public getAddAttachment$(): Observable<void> {
        this._addAttachment$ = new Subject();

        return this._addAttachment$.asObservable();
    }

    public onAddAttachmentSuccess(): void {
        this._addAttachment$.next();
        this._addAttachment$.complete();
    }

    public onAddAttachmentError(errors: ErrorInterface[]): void {
        this._addAttachment$.error(errors);
    }

    public getGetInsuranceCompanies$(): Observable<GetInsuranceCompaniesResponseInterface[]> {
        this._getInsuranceCompanies$ = new Subject();
        return this._getInsuranceCompanies$.asObservable();
    }

    public onGetInsuranceCompaniesSuccess(response: GetInsuranceCompaniesResponseInterface[]): void {
        this._getInsuranceCompanies$.next(response);
        this._getInsuranceCompanies$.complete();
    }

    public onGetInsuranceCompaniesError(errors: ErrorInterface[]): void {
        this._getInsuranceCompanies$.error(errors);
    }

    public getDownloadTerminationLetter$(): Observable<DownloadTerminationLetterResponseInterface> {
        this._downloadTerminationLetter$ = new Subject();
        return this._downloadTerminationLetter$.asObservable();
    }

    public onDownloadTerminationLetterSuccess(response: DownloadTerminationLetterResponseInterface): void {
        this._downloadTerminationLetter$.next(response);
        this._downloadTerminationLetter$.complete();
    }

    public onDownloadTerminationLetterError(errors: ErrorInterface[]): void {
        this._downloadTerminationLetter$.error(errors);
    }

    public getEmailTerminationLetter$(): Observable<void> {
        this._emailTerminationLetter$ = new Subject();
        return this._emailTerminationLetter$.asObservable();
    }

    public onEmailTerminationLetterSuccess(): void {
        this._emailTerminationLetter$.next();
        this._emailTerminationLetter$.complete();
    }

    public onEmailTerminationLetterError(errors: ErrorInterface[]): void {
        this._emailTerminationLetter$.error(errors);
    }
}
